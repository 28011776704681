export class News {
id: number;
name: string;
roleId:number;
franchiseMaster: number[] = [];
franchise : number[] = [];
planTest: number[]= [];
planSelection:number[] = [];
isActive: boolean;
urlRedirect:string;
logFileId:number; 

thumbnailImage: string;
thumbnailImageUrl: string;
fileName: string;
isClassActivate: boolean;


franchisesMaster: string[] = [];
franchises : string[] = [];
plansTest: string[] = [];
plansSelection: string[] = []
}
