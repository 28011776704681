/// <reference types="aurelia-loader-webpack/src/webpack-hot-interface"/>
// css
// import 'font-awesome/css/font-awesome.css';
import 'nprogress/nprogress.css';
import 'toastr/build/toastr.min.css';
// intro.js
// import 'intro.js/minified/introjs.min.css';
// aurelia quill editor plug in
import 'quill/dist/quill.snow.css';
// aurelia bootstrap plugin
import 'bootstrap-tagsinput/dist/bootstrap-tagsinput.css';

// app style
import '../assets/styles/main.scss';

// js
import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import * as Bluebird from 'bluebird';
// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({ warnings: { wForgottenReturn: false } });

// import environment from "config/environment";npm 
import i18nConfig from 'config/i18n-config';

// config
import config from 'config.js';

// import oidcConfig from "config/open-id-connect-configuration";

// Authorization config
import { PermissionStore, Configuration } from 'aurelia-permission';
import openIdConfig from 'config/open-id-connect-configuration';
import { I18N } from 'aurelia-i18n';

// flatpickr es locale
// import "flatpickr/dist/l10n/es.js";

// intro.js
// import 'intro.js';

// quill options
import quillOptions from 'config/quill-config';

declare const IS_PRODUCTION: boolean; // The value is supplied by Webpack during the build

export async function configure(aurelia: Aurelia) {

  // if (environment.useHttps && location.protocol !== "https:") {
  //   // location.protocol is buggy in Firefox
  //   // see also http://stackoverflow.com/a/10036029
  //   location.href = "https:" + window.location.href.substring(window.location.protocol.length);
  // }
  // console.log("IS_PRODUCTION: " + IS_PRODUCTION);

  aurelia.use.standardConfiguration();

  if(!IS_PRODUCTION){
    aurelia.use.developmentLogging();
  }

  aurelia.use.feature(PLATFORM.moduleName('common/resources/index'));
  aurelia.use.feature(PLATFORM.moduleName('main/components/index'));

  // Uncomment the line below to enable animation.
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  // i18next
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), async (instance: I18N) => {

    let backendUrl = config.URLS_BACKEND;

    await i18nConfig(aurelia, instance, backendUrl, IS_PRODUCTION);

    return instance;
  });

  // Authentication
  aurelia.use.plugin(PLATFORM.moduleName("aurelia-open-id-connect"), () => {
    let backendUrl = config.URLS_BACKEND;
    let hostUrl = config.URLS_FRONTEND;
    return openIdConfig(backendUrl, hostUrl);
  });

  // Authorization
  aurelia.use.plugin(PLATFORM.moduleName("aurelia-permission"), (permissionStore: PermissionStore, configuration: Configuration) => { return null; });



  // aurelia-validation
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-validation'));

  // dialog
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-dialog'), config => {
    config.useDefaults();
    config.settings.lock = true;
    config.settings.centerHorizontalOnly = false;
    config.settings.startingZIndex = 5;
    config.settings.keyboard = true;
  });

  // virtual scrolling
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-ui-virtualization'));

  // navigation-menu
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-navigation-menu'));

  // datepicker
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-flatpickr'));

  // quill 
  aurelia.use.plugin(PLATFORM.moduleName('aurelia-quill-plugin'), quillOptions);

  // aurelia.use.plugin( PLATFORM.moduleName('aurelia-tags-input') );

  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-bootstrap-select'));

  aurelia.use.plugin(PLATFORM.moduleName('bcx-aurelia-reorderable-repeat'));


  aurelia.use.plugin(PLATFORM.moduleName('aurelia-bootstrap-tagsinput'));

  // google analitics
  /*aurelia.use.plugin(PLATFORM.moduleName('aurelia-google-analytics'), config => {
    config.init('UA-132482465-2');
    // config.init('G-Q7L2M47XY1');
    config.attach({
      logging: {
        // Set to `true` to have some log messages appear in the browser console.
        enabled: IS_PRODUCTION || false
      },
      pageTracking: {
        // Set to `false` to disable in non-production environments.
        enabled: IS_PRODUCTION || false,
      },
      clickTracking: {
        // Set to `false` to disable in non-production environments.
        enabled: IS_PRODUCTION || false,
      },
      exceptionTracking: {
        // Set to `false` to disable in non-production environments.
        enabled: IS_PRODUCTION || false
      }
    });
  });*/


  return await aurelia.start().then(async () => {
    return await aurelia.setRoot(PLATFORM.moduleName('app'));
  });
}
