import { ItemToQualify } from './itemToQualify';
import { QuestionaryType } from './questionaryType';
import { OrganizationType } from './organizationType';
import { NoDiscType } from './noDiscType';
import  {GameTest } from './gameTest';


export class Questionary {
  id: number;
  averageTime: number;
  order: number;
  isActive: boolean;
  name: string;
  languageId: number;
  languageName: string;
  questionaryType: QuestionaryType;
  itemToQualify: ItemToQualify;
  organizationType: OrganizationType;
  noDiscType: NoDiscType;
  hasEndButton: boolean;
  hasChronometer: boolean;
  programmingLanguageId: number;
  hasGameTest: boolean;
  isNewTest: boolean;
  isTranslatable: boolean;
  isOriginal?: boolean;
  stringId: string;
  gameTest: GameTest;
  companyId?: number;
}
