import { ItemToQualify } from "models/questionaries/itemToQualify";
import { OrganizationType } from "models/questionaries/organizationType";
import { Questionary } from "models/questionaries/questionary";
import { SheetLanguage } from './sheetLanguage';

export class Sheet {
  id: number;
  name: string;
  description: string;
  fullDescription: string;
  itemToQualify?: ItemToQualify;
  organizationType?: OrganizationType;
  isActive: boolean;
  catergoryId: number;
  thumbnailImageUrl: string;
  imageUrl: string;
  thumbnailImage: any;
  thumbnailImageFileName: string;
  image: any;
  imageFileName: string;

  questionaries: Questionary[] = [];

  sheetLanguages: SheetLanguage[] = [];

  talentsPrice: number;
  parameters: any[] = [];
  warning: boolean;
}
