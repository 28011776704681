import { Company } from './company';

export class CompanyDetailMaster {
  id: number;
  company: Company = new Company();
  companyId: number;
  economicSectorName: string;
  countryName: string;
  regionName: string;
  cityName: string;
  logoUrl: string;
  logo: string;
  logoFileName: string;
  userNumber: number;
  isDemo: boolean;
  planName: string;
}
