import { FileAcceptValidator } from 'common/utils/file-accept-validator';
import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { CustomCompanyQuestionService } from 'main/admin/services/questionaries/custom-company-question-service';
import { Question } from "models/questions/question";
import { QuestionType } from "models/questions/questionType";
import { QuestionUse } from "models/questions/questionUse";
import { CustomCompanyQuestionConfigModal } from './custom-company-question-config-modal';
import { ResponseOption } from "models/questions/response-option";
import { State } from 'common/store/store-model';
import { Company } from 'models/companies/company';
import { StoreService } from 'common/store/store-service';

@autoinject()
export class CustomCompanyQuestionEdit {

  private logger: Logger;
  private model: Question = new Question();

  private validationController: ValidationController;

  private params: any = {};
  public companyId: number;
  public company: Company;
  
  private state: State;
  public isVideoInterview: boolean = false;
  public isDisabled: boolean;
  private countOfQuestion: number = 1;
  constructor(
    private store: StoreService,
    private customCompanyQuestionService: CustomCompanyQuestionService,
    private dialogService: DialogService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router) {

    this.logger = LogManager.getLogger("CustomCompanyQuestionEdit");

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.state = this.store.state;
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("formulation").displayName("main.admin.pages.questionaries.questions.question").required()
      .on(this.model);
  }

  private async activate(params: any): Promise<void> {

    try {

      if (!isNaN(params.companyId)) {
        this.logger.info("Acticated for edit with id", params.questionId);
        this.companyId = +params.companyId;
        this.customCompanyQuestionService.client.currentCompanyId = this.companyId;
        this.company = this.state.companies.find(x => x.id == this.companyId);
        if (!isNaN(params.questionId)) {
          this.model = await this.customCompanyQuestionService.getById(+params.questionId);
        }
        else if (!isNaN(params.questionaryId) && !isNaN(params.questionTypeId)) {

          this.logger.debug("params.questionTypeId: ", params.questionTypeId);

          this.model.questionaryId = <number>params.questionaryId;
          this.model.questionType = <QuestionType>params.questionTypeId;

          this.logger.debug("this.model.questionType: ", QuestionType[this.model.questionType]);

          if (params.isVideoInterview != undefined) {
            this.isVideoInterview = JSON.parse(params.isVideoInterview);
          }                           
          this.countOfQuestion = Number(params.order);
          this.validateIsVideoInterview(this.isVideoInterview);


          if (this.model.questionType == QuestionType.DescriptiveText || this.model.questionType == QuestionType.OpenAnswerQuestion) {
            this.addResponseOption(true);
          }
        }
      }
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }
  public validateIsVideoInterview(isVideoInterview: boolean) {
    if (isVideoInterview) {
      this.model.questionUse = QuestionUse.Evaluation;
      this.isDisabled = true;
      this.model.order = this.countOfQuestion;
    }
    else
      this.isDisabled = false;
  }

  public async openProperties(): Promise<void> {
    this.logger.debug("openProperties");
    return await this.dialogService.open({
      viewModel: CustomCompanyQuestionConfigModal,
      model: this.model
    }).whenClosed(() => null);
  }

  public async CodeRandom(): Promise<string> {
    let length: number = 5;
    let result: string = '';
    let characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength: number = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.logger.debug("submit");
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.model.responseOptions.length > 0 && this.model.questionType !== QuestionType.DescriptiveText) { //this.questionType.getIdByCode("descriptive.text")
            let validDescriptiveText: boolean = true;
            // this.logger.debug("-->", validDescriptiveText);
            if (validDescriptiveText) {
              return await this.save(saveAndContinue);
            }
          } else {
            return await this.save(saveAndContinue);
          }
        }
      });
  }

  get canSave() {
    return !this.customCompanyQuestionService.client.isRequesting;
  }

  public async save(saveAndContinue: boolean): Promise<any> {
    this.logger.debug("save");
    if (!this.model.id) {

      this.model.code = await this.CodeRandom();
      this.model.order = this.model.order;
      this.model.questionUse = this.model.questionUse;
      this.model.allowOneResponseByColumn = this.model.questionType == QuestionType.Matrix ? true : null;
      this.model.allowOneResponseByRow = this.model.questionType == QuestionType.Matrix ? true : null;
      this.model.isTextSizeAvailable = (this.model.questionType == QuestionType.OpenAnswerQuestion || this.model.questionType == QuestionType.SingleChoiceListWithComments) ? true : null;
      this.model.textSizeMin = this.model.questionType == QuestionType.OpenAnswerQuestion ? 1 : null;
      this.model.textSizeMax = (this.model.questionType == QuestionType.OpenAnswerQuestion || this.model.questionType == QuestionType.SingleChoiceListWithComments) ? 5000 : null;
      this.model.textSizeMin = this.model.questionType == QuestionType.SingleChoiceListWithComments ? 0 : null;
      
      return await this.customCompanyQuestionService.create(this.model)
        .then(async (question: Question) => {
          // this.validationController.reset();
          this.notification.success("main.admin.pages.questionaries.questions.messages.question_added");

          if (!saveAndContinue) {
            return await this.router.navigateToRoute("custom-company-questions");
          }
          else if (this.isVideoInterview && saveAndContinue) {
            this.countOfQuestion = this.countOfQuestion + 1;
            this.model.order = this.countOfQuestion;
            this.model.formulation = "";
            if (this.countOfQuestion == 4) {
              return await this.router.navigateToRoute("custom-company-questionary-list");
            }
            return await this.router.navigateToRoute("custom-company-question-create", { questionTypeId: QuestionType.OpenAnswerQuestion, questionaryId: this.model.questionaryId, isVideoInterview: true, order: this.countOfQuestion });
          }
          else {
            return await this.router.navigateToRoute("custom-company-question-child-router", { questionId: question.id });
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.customCompanyQuestionService.update(this.model)
        .then(async (model: Question) => {
          // this.model = model;
          this.notification.success("main.admin.pages.questionaries.questions.messages.question_edited");

          if (!saveAndContinue) {
            return await this.router.navigateToRoute("custom-company-questions");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }

  }

  // public cancel(): void {
  //   this.logger.info("cancel", this.router);
  //   this.router.navigate(`questions`);
  // }

  public addResponseOption(isColumn: boolean): void {
    this.logger.info("addReponseOption");
    let responseOption: ResponseOption = new ResponseOption();
    responseOption.id = 0;
    responseOption.order = this.model.responseOptions.length;
    responseOption.isColumn = isColumn;
    responseOption.option = "";
    this.model.responseOptions.push(responseOption);
  }

  public deleteResponseOption(index: number) {
    let responseOption = this.model.responseOptions[index];
    //validation
    this.validationController.removeObject(responseOption);
    //delete
    this.model.responseOptions.splice(index, 1);
  }

  public setFile($event: any, item: ResponseOption): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];
    let fileValidator = FileAcceptValidator.parse("image/*", 1024 * 300);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64data: any = reader.result;
        item.thumbnailImage = base64data;
        item.option = file.name;
        item.thumbnailImageUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.questionaries.questions.errors.error_bad_image");

    }
  }

  public unSetFile(item: ResponseOption): void {
    item.option = null;
    item.thumbnailImage = null;
    item.thumbnailImageUrl = null;
  }

  public getQuestionUsesByQuestionType(): Array<any> {
    this.logger.debug("getQuestionUsesByQuestionType");
    let result: Array<any> = [];
    this.logger.debug("getQuestionUsesByQuestionType this.model.questionType", this.model.questionType);
    switch (+this.model.questionType) {
      case QuestionType.SingleChoiceList:
      case QuestionType.SingleImageChoiceList:
      case QuestionType.SingleChoiceListWithComments:
      case QuestionType.MultipleChoiceList:
      case QuestionType.MultipleImageChoiceList:
      case QuestionType.Matrix:
        this.logger.debug("QuestionType.All");
        result = Object.keys(QuestionUse)
          .filter(key => QuestionUse[key] === QuestionUse.Evaluation || QuestionUse[key] === QuestionUse.Example)
          .map(key => ({ id: QuestionUse[key], name: key }));
        break;
      case QuestionType.DescriptiveText:
        this.logger.debug("QuestionType.DescriptiveText");
        result = Object.keys(QuestionUse)
          .filter(key => QuestionUse[key] === QuestionUse.Instruction || QuestionUse[key] === QuestionUse.Warning || QuestionUse[key] === QuestionUse.Farewell)
          .map(key => ({ id: QuestionUse[key], name: key }));
        break;
      case QuestionType.OpenAnswerQuestion:
        this.logger.debug("QuestionType.OpenAnswerQuestion");
        result = Object.keys(QuestionUse)
          .filter(key => QuestionUse[key] === QuestionUse.Evaluation || QuestionUse[key] === QuestionUse.Example || QuestionUse[key] === QuestionUse.Warning)
          .map(key => ({ id: QuestionUse[key], name: key }));
        break;
      default:
        break;
    }
    this.logger.debug("getQuestionUsesByQuestionType result:", result);
    return result;
  }

  // public endDragAndDropResponseOption(customEvent: CustomEvent) {
  //   let event = customEvent.detail;

  //   this.logger.debug("endDragAndDropResponseOption event.oldIndex:", event.oldIndex);
  //   this.logger.debug("endDragAndDropResponseOption event.newIndex:", event.newIndex);

  // let movedItem = this.model.responseOptions.find((item, index) => index === event.oldIndex);
  // let remainingItems = this.model.responseOptions.filter((item, index) => index !== event.oldIndex);

  // let j: number = 0;
  // for (let i: number = 0; i < remainingItems.length; i++) {
  //   if (i === event.newIndex) {
  //     movedItem.order = j;
  //     j++;
  //     remainingItems[i].order = j;
  //   } else {
  //     remainingItems[i].order = j;
  //   }
  //   this.logger.debug("endDragAndDropResponseOption i, j:", i, j);
  //   j++;      
  // }

  // if (event.newIndex === remainingItems.length) {
  //   this.logger.debug("endDragAndDropResponseOption last:");
  //   movedItem.order = event.newIndex;
  // }
  // }

}
