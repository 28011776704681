
// quill
import Quill from 'quill';
// import { ImageDrop } from 'quill-image-drop-module';

// Quill.register('modules/imageDrop', ImageDrop);

const quillOptions = {
  // debug: 'info',
  modules: {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'script': 'super' }, { 'script': 'sub' }],
      [{ 'header': '1' }, { 'header': '2' }, 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['direction', { 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    // imageDrop: true,
  },
  theme: 'snow'
};

export default quillOptions;
