export class FilterValueConverter {
  toView(array, propertyName, filter_on) {
    return array.filter(i => i[propertyName] == filter_on);
  }
}

export class FilterOnPropertyValueConverter {
  toView(array: {}[], property: string, exp: string) {

    if (array === undefined || array === null || property === undefined || exp === undefined) {
      console.debug("Error");
      return array;
    }
    return array.filter((item) => item[property].toLowerCase().indexOf(exp.toLowerCase()) > -1);
  }
}

export class HasPropertyValueValueConverter {
  toView(array: {}[], property: string, exp: string) {

    if (array === undefined || array === null || property === undefined || exp === undefined) {
      return false;
    }
    return array.filter((item) => item[property].toLowerCase().indexOf(exp.toLowerCase()) > -1).length > 0;
  }
}

export class GenericFilterValueConverter {
  toView(array, value, cols, showResults = false) {
    if (!value) { return showResults ? array : []; };

    var arrayOut = array.filter(
      function (arrayIn) {
        for (var col in arrayIn) {
          if (arrayIn.hasOwnProperty(col)) {
            if (cols.indexOf(col) != -1 && arrayIn[col].toLowerCase()
              .indexOf(value.toLowerCase()) != -1) {
              return true;
            }
          }
        };
        return false;
      });
    return arrayOut;
  }
}
