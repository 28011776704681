import { MessagePeriod } from './message-period';

export class MessageSummaryReport {  
  companyId: number;
  isConsumption: boolean;
  consumptionPeriod:  MessagePeriod;
  isPending:  boolean;
  pendingPeriod:  MessagePeriod;
  isActiveUser: boolean;
  activeUserPeriod:  MessagePeriod;
  isActivityDelivery: boolean;
  activityDeliveryPeriod: MessagePeriod;

}
