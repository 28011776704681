import { Wallet } from 'models/talents/wallet';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { User } from "models/security/user";

import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Transaction } from "models/talents/transaction";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";


@autoinject
export class TransactionService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("TalentTransactionService");
  }

  public async recordList(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("transaction/record-list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async transferList(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("transaction/transfer-list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async balancesList(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("transaction/balances-list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async consolidatedList(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("transaction/consolidated-list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async remove(parameters: Wallet[]): Promise<UxDatatableResponse> {
    return await this.client.post("transaction/remove", parameters)
    .then(() => null);
  }
  
  public async transfer(parameters: Transaction[]): Promise<UxDatatableResponse> {
    return await this.client.post("transaction/transfer", parameters)
    .then(() => null);
  }
  

  public async getById(id: number): Promise<Transaction> {
    return await this.client.getById("transaction", id)
      .then((response: Transaction) => {
        return response;
      });
  }

  public async create(model: Transaction): Promise<Transaction> {
    return await this.client.create("transaction", model)
      .then((response: Transaction) => {
        return response;
      });
  }

  public async assign(model: Transaction): Promise<Transaction> {
    return await this.client.create("transaction/assign", model)
      .then((response: Transaction) => {
        return response;
      });
  }

  public async getUserByCompany(): Promise<User[]> {
    return await this.client.get("transaction/get-users-by-company")
      .then((response: User[]) => {
        return response;
      });
  }

  public async hasPredictorByPerson(personId: number): Promise<boolean> {
    return await this.client.get(`transaction/has-predictor-by-person/${personId}`)
      .then((response: boolean) => {
        return response;
      });
  }
}