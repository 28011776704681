
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class ObservationChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("ObservationChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./observation-edit"),
        name: "observation-edit",
        nav: true,
        title: this.i18n.tr("main.testperson.pages.observation_activation.title"),
      },

      {
        route: "observers/:activationId",
        moduleId: PLATFORM.moduleName("./observers/observer-list-child-router"),
        name: "observer-list-child-router"
      },
      
    ]);
  }
}
