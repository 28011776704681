import { CompanyCompetence } from 'models/test-core/competences/company-competence';
import { autoinject, LogManager, bindable, bindingMode } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { StoreService } from "common/store/store-service";
import { State } from "common/store/store-model";
import { Notification } from "common/services/notification";
import { CompanyCompetenceService } from 'main/admin/services/test-management/company-competence-service';
import { Tests } from 'models/test-person/tests';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
@autoinject()
export class CompetenceActivationSelector {
  private logger: Logger;

  public companyId: number;

  private state: State;

  private companyCompetences: CompanyCompetence[] = [];
  private questionaryId: number;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  private companyCompetenceIds: number[] = [];

  constructor(
    private store: StoreService,
    private companyCompetenceService: CompanyCompetenceService,
    private notification: Notification,
    private coreCompanyService: CoreCompanyService) {

    this.logger = LogManager.getLogger("CompetenceActivationSelector");

    this.state = this.store.state;
  }

  public async bind(): Promise<any> {
    this.logger.debug("CompetenceActivationSelector");
    this.companyId = this.state.currentCompany.id;

    if (!isNaN(this.companyId)) {

      this.companyCompetenceService.client.currentCompanyId = this.companyId;

      try {
        let settings = await this.coreCompanyService.getSettingsById(this.companyId);
        this.questionaryId = settings.competencesQuestionaryId;

        if (this.questionaryId == 0)
          this.questionaryId = Tests['Competences'];
        this.companyCompetences = await this.companyCompetenceService.getAll(this.questionaryId);

      } catch (error) {
        this.notification.error(error);
      }

    }
  }
}
