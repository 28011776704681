
import { Sheet } from './sheets/sheet';
import { QuestionaryType } from 'models/questionaries/questionaryType';
import { ItemToQualify } from 'models/questionaries/itemToQualify';
import { OrganizationType } from 'models/questionaries/organizationType';
import { NoDiscType } from 'models/questionaries/noDiscType';
import  {GameTest } from 'models/questionaries/gameTest';

export class TestQuestionary {
  id: number;
  averageTime: number;
  order: number;
  isActive: boolean;
  name: string;
  languageId: number;
  languageName: string;
  questionaryType: QuestionaryType;
  itemToQualify: ItemToQualify;
  organizationType: OrganizationType;
  noDiscType: NoDiscType;
  hasEndButton: boolean;
  hasChronometer: boolean;
  hasGameTest: boolean;  
  isNewTest: boolean;
  programmingLanguageId: number;
  sheets: Sheet[] = [];
  isTranslatable: boolean;
  stringId: string;
  gameTest: GameTest;
}
