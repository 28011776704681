import { InboxByUserStatus } from "./inboxByUserStatus";


export class Inbox {
  id: number;
  subject: string;
  roleId:number;
  franchiseMaster: number[] = [];
  franchise : number[] = [];
  planTest: number[]= [];
  planSelection:number[] = [];
  company:number[] = [];

  isActive: boolean;
  body:string; 
    
  franchisesMaster: string[] = [];
  franchises : string[] = [];
  plansTest: string[] = [];
  plansSelection: string[] = [];
  companiesName: string[] = [];

  inboxByUserStatus : InboxByUserStatus;
  }
  