import { UserState } from "./userState";

export class User {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  userName: string;
  email: string;
  userState: UserState;
  groupId: number;
  companyId: number;
  roleId: number;
  profileId: number;
}
