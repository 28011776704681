import { AgreementStatus } from 'models/agreements/agreementStatus';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { ValidationController, ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { ValidationControllerFactory } from 'aurelia-validation';
import { Notification } from 'common/services/notification';
import { AgreementService } from '../../../services/agreements/agreement-service';
import { Agreement } from 'models/agreements/agreement';
import { Logger } from 'oidc-client';
import { autoinject, LogManager } from "aurelia-framework";
import { Router } from 'aurelia-router';

@autoinject()
export class TermsConditionsEdit {
  private logger: Logger;
  private agreementId: number;
  private companyId: number;

  private model: Agreement = new Agreement();
  private validationController: ValidationController;
  
  private disable: boolean = false;
  private params: any = {};

  constructor(
    private agreementService: AgreementService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("TermsConditionsEdit");
  }

  public async activate(params: any): Promise<void> {
    this.logger.info("activate");
    this.params = params;
  }

  public async bind(): Promise<any> {
    if (!isNaN(this.params.agreementId)) {

      this.agreementId = this.params.agreementId;

      if (!isNaN(this.params.companyGroupId)) {
        this.companyId = +this.params.companyGroupId;
      }

      if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.agreementId && this.companyId) {

        this.agreementService.client.currentCompanyId = this.companyId;

        try {
          this.model = await this.agreementService.getById(+this.params.agreementId);

          if (this.model.agreementStatus !== AgreementStatus.InternalDraft) {
            this.disable = true;
          }

          this.configureValidationRules();
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    }
  }

  private configureValidationRules(): void {
    // this.logger.info("configureValidationRules");
    this.logger.debug("model", this.model);
    ValidationRules
      .ensure("termsAndConditions").displayName("main.admin.pages.agreements.description").required()
      .on(this.model);
  }


  public async submit(keepEditing: boolean): Promise<void> {
    // this.saveAndContinue = keepEditing;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.agreementService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    try {
      await this.agreementService.update(this.model);
      this.notification.success("notifications.item_edited");
      if (!keepEditing) {
        this.router.parent.navigateToRoute("readonly-child-router", { agreementId: this.agreementId });
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }
}
