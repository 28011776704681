import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Questionary } from "models/questionaries/questionary";
import { TestQuestionary } from "models/test-core/test-questionary";

@autoinject()
export class CompanyQuestionaryService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CompanyQuestionaryService");
  }

  public async list(search: string): Promise<TestQuestionary[]> {
    return await this.client.post("companyquestionary/list", { search })
      .then((response: any) => {
        return <TestQuestionary[]>response;
      });
  }

  public async getAll(): Promise<TestQuestionary[]> {
    return await this.client.get("companyquestionary")
      .then((response: TestQuestionary[]) => {
        return response;
      });
  }
}
