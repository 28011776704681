export class Localization {
  id: number;
  languageId: number;
  labelId: number;
  label:  string;
  referenceLanguage: string;
  maxSize: number;
  translation: string; 
  comments: string;
}
