export class Profile {
  id: number;
  name: string;
  description: string;
  shareWithPeers: boolean;
  companyId: number;
  parentId: number;
  parentName: string;
  isDefault: boolean;
}
