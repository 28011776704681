import { Company } from "../companies/company";

export class Team {
  id: number;
  companyId: number;
  company: Company;
  name: string;
  tags: string;

  peopleWithDiscQuantity?: number;
  peopleQuantity?: number;

  createdOn: Date;
  createdByName: string;
}
