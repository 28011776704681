import { I18N } from 'aurelia-i18n';
import { TestPlanItem } from 'models/plans/test-plan-item';
import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { TestPlanItemModal } from "./test-plan-item-modal";
import { PlanService } from "../../../services/plans/plan-service";
import { Plan } from "models/plans/plan";
import { TestPlanItemsService } from "../../../services/plans/test-plan-item-service";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { DeleteConfirmation } from "../../../../components/delete-confirmation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { SpendMode } from 'models/plans/spendMode';

@autoinject()
export class TestPlanItemList {

  private planId: number;
  private plan: Plan = new Plan();
  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private customReportsSpendMode: SpendMode;
  private isRobotHunter: boolean;

  constructor(
    private i18n: I18N,
    private dialogService: DialogService,
    private planService: PlanService,
    private planTestModuleService: TestPlanItemsService,
    private router: Router,
    private notification: Notification) {

    this.logger = LogManager.getLogger("TestPlanItemList");
  }

  private async activate(params: any): Promise<void> {
    if (!isNaN(params.planId)) {
      this.planId = +params.planId;      
      if (params.isRobotHunter) {
        this.isRobotHunter = JSON.parse(params.isRobotHunter);
      }       
    }
  }

  private async bind(): Promise<any> {
    return await this.databind();
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async databind(): Promise<void> {
    this.logger.info("databind");
    if (this.planId) {
      try {
        this.plan = await this.planService.getById(this.planId);
        this.customReportsSpendMode = this.plan.customReportsSpendMode;
        if (this.plan.isTestItemsActive) {
          let response = await this.planTestModuleService.list(this.planId, this.parameters);
          this.parameters.tableData = response.data;
          this.parameters.totalRecords = response.totalRecords;
        }
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    return this.planTestModuleService.list(this.planId, this.parameters);
  }

  private async create(): Promise<void> {
    let item: TestPlanItem = new TestPlanItem();
    item.planId = this.plan.id;
    return await this.dialogService.open({
      viewModel: TestPlanItemModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.planTestModuleService.create(item)
          .then(async (model: TestPlanItem) => {
            item.id = model.id;
            item.questionaryName = model.questionaryName;
            item.reportTemplateName = model.reportTemplateName;
            item.sheetName = model.sheetName;
            this.parameters.tableData.unshift(item);
            this.parameters.totalRecords++;
            this.notification.success("notifications.item_added");
            // return await this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async edit(item: TestPlanItem): Promise<void> {
    item.planId = this.plan.id;
    return await this.dialogService.open({
      viewModel: TestPlanItemModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.planTestModuleService.update(item)
          .then(async () => {
            // var name = this.model.name;
            this.notification.success("notifications.item_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async delete(item: TestPlanItem): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.questionaryId ? item.questionaryName : item.reportTemplateId ? this.i18n.tr(item.reportTemplateName) : this.i18n.tr(item.sheetName)
    })
      .whenClosed((response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          this.planTestModuleService.delete(item.id)
            .then(() => {
              let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === item.id);
              this.parameters.tableData.splice(indexOfEditedModel, 1);
              this.parameters.totalRecords--;
              this.notification.success("notifications.item_deleted");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      });
  }

  private async changeTestItemsState(): Promise<void> {
    return await this.planService.changeTestItemsState(this.plan.id)
      .then(() => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");
        this.bind();
      })
      .catch(error => {
        this.notification.error(error);
      });
      
  }
  private async changeResultsExport(): Promise<void> {
    return await this.planService.changeResultsExport(this.plan.id)
      .then(() => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async changeCandidateReport(): Promise<void> {
    return await this.planService.changeCandidateReport(this.plan.id)
      .then(() => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  
  private async changeVideoInterview(state: boolean): Promise<void> {
    return await this.planService.changeVideoInterview(this.plan.id, this.plan.videoInterviewTalents, state)
      .then(() => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  // private async changeRobotHunter(state: boolean): Promise<void>{
  //   return await this.planService.changeRobotHunter(this.plan.id, this.plan.predictorTalents, state)
  //     .then(() => {
  //       this.notification.success("main.admin.pages.plans.messages.plan_edited");
  //     })
  //     .catch(error => {
  //       this.notification.error(error);
  //     });
  // }
  
  private async changeViewWallets(): Promise<void> {
    return await this.planService.changeViewWallets(this.plan.id)
      .then(() => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
}
