import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { TagManagment } from "models/tag/tag-managment";

@autoinject()
export class TagManagmentService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("TagManagmentService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("tagmanagment/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getByTagName(itemTag: TagManagment): Promise<TagManagment> {
    return await this.client.post("tagmanagment/get-by-name", itemTag)
      .then((response: TagManagment) => {
        return response;
      });
  }

  public async updateOrDelete(model: TagManagment): Promise<void> {
    return await this.client.updateById("tagmanagment", model.tagId, null, model)
      .then(() => null);
  }
}
