import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { ResetSecondPasswordModal } from "./reset-second-password-modal";

@autoinject()
export class ResetSecondPasswordCard {

  private logger: Logger;

  constructor(
    private dialogService: DialogService) {

    this.logger = LogManager.getLogger("ResetSecondPasswordCard");
  }

  public async resetSecondPassword(): Promise<void> {
    this.logger.debug("resetSecondPassword");

    return await this.dialogService.open({
      viewModel: ResetSecondPasswordModal
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (response.output) {
          let result: boolean = <boolean>response.output;
          this.logger.debug("Reset ok: ", result);
        }
      }
    });
  }
}
