import { ActivationQuestionary } from 'models/test-person/activationQuestionary';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
@autoinject()
export class ActivationQuestionaryService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("ActivationQuestionaryService");
  }

  public async list(parameters: UxDatatableParameters, personId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`activationquestionary/list/${personId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getquestionnaires(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`activationquestionary/getquestionnaires`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  

  public async getAll(personId: number): Promise<ActivationQuestionary[]> {
    return await this.client.get(`activationquestionary/get-all/${personId}`)
      .then((response: any) => {
        return <ActivationQuestionary[]>response;
      });
  }

  public async getObserved(personId: number): Promise<ActivationQuestionary[]> {
    return await this.client.get(`activationquestionary/get-observed/${personId}`)
      .then((response: any) => {
        return <ActivationQuestionary[]>response;
      });
  }

  public async getById(id: number): Promise<ActivationQuestionary> {
    return await this.client.get(`activationquestionary/${id}`)
      .then((response: ActivationQuestionary) => {
        return response;
      });
  }

  public async create(model: ActivationQuestionary, personId: number): Promise<ActivationQuestionary> {
    return await this.client.create(`activationquestionary/${personId}`, model)
      .then((response: any) => {
        return <ActivationQuestionary>response;
      });
  }

  // public async update(model: ActivationQuestionary, personId: number): Promise<void> {
  //   return await this.client.updateById(`activationquestionary/${personId}`, model.id, null, model)
  //     .then(() => null);
  // }

  public async getCompanyCompetenceById(id: number): Promise<ActivationQuestionary> {
    return await this.client.get(`activationquestionary/company-competence-by-id/${id}`)
      .then((response: ActivationQuestionary) => {
        return <ActivationQuestionary>response
      });
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`activationquestionary`, id)
      .then(() => null);
  }
  public async listpreviewresults(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("activationquestionary/listpreviewresults", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  public async getLastActivationVideoInterview(personId: number): Promise<ActivationQuestionary> {
    return await this.client.get(`activationquestionary/get-last-activation-videointerview/${personId}`)
      .then((response: ActivationQuestionary) => {
        return response;
      });
  }

  public async listActivationVideoInterview(personId: number): Promise<ActivationQuestionary[]> {
    return await this.client.post(`activationquestionary/list-activation-videointerview/${personId}`)
      .then((response: any) => {
        return <ActivationQuestionary[]>response;
      });
  }
  public async getByActivationQuestionaryId(id: number): Promise<ActivationQuestionary> {
    return await this.client.get(`activationquestionary/${id}`)
      .then((response: ActivationQuestionary) => {
        return response;
      });
  }
  public async requalifyById(id: number): Promise<void> {
    return await this.client.post(`activationquestionary/requalify/${id}`)
      .then(() => null);
  }

}
