import { SpendMode } from './spendMode';
import { DemoLimitTime } from './demoLimitTime';
import { TalentChargeFrequency } from './talentChargeFrequency';
import { PlanType } from './planType';
import { Company } from 'models/companies/company';

export class Plan {
  id: number;
  name: string;
  planType: PlanType;
  description: string;
  isActive: boolean;

  talents: number;
  talentChargeFrequency: TalentChargeFrequency;
  demoLimitTime: DemoLimitTime;

  accumulateTalents: boolean;
  isTestItemsActive: boolean;
  isCrossItemsActive: boolean;
  isOtherItemsActive: boolean;

  company: Company;
  companyId: number;
  cloneFromPlanId: number;

  hasTheMethod: boolean;
  hasHelp: boolean;
  hasWebChat: boolean;
  hasMicrosite: boolean;

  customReportsSpendMode: SpendMode;

  isResultsExport: boolean;
  isCandidateResult: boolean;

  isVideoInterview: boolean;
  videoInterviewTalents: number;

  isRobotHunter: boolean;
  predictorTalents: number;
  dailyConsult: number;

  isViewWallet: boolean;

  hasWebBrowser:boolean;
  hasEmploymentPortal: boolean;
  isDataBasePublic: boolean;
  hasChatBot: boolean;
  hasMutichanel: boolean;
  consultationPerYear: number;
  consultationPerYearTalents: number;
  publishedVacancies:number;
  publishedVacanciesTalents:number;
  additionalTalentsSpendMode: SpendMode;
}
