
import { LogManager } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";

export class QuestionaryGroupListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor() {
    this.logger = LogManager.getLogger("QuestionaryGroupListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./questionary-group-list"),
        name: "questionary-groups",

      },

      {
        route: "questionary-group/:questionaryGroupId",
        moduleId: PLATFORM.moduleName("./questionary-group-child-router"),
        name: "questionary-group-child-router",

      },
    ]);
  }
}
