import { inject, newInstance, LogManager, autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Scale } from 'models/scales/scale';

@autoinject()
export class ScaleService {
    private logger: Logger;

    constructor(
        private client: AppHttpClient) {

        this.logger = LogManager.getLogger('QuestionariesService');
    }

    async list(thematicVariableId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
        return await this.client.post(`scale/list/${thematicVariableId}`, parameters)
            .then(response => {
                return <UxDatatableResponse>response;
            });
    }

    async getById(id: number): Promise<Scale> {
        return await this.client.getById("scale", id)
            .then(response => {
                return <Scale>response;
            });
    }

    async create(model: Scale): Promise<Scale> {
        return await this.client.create('scale', model)
            .then(response => {
                return <Scale>response;
            });
    }

    async update(model: Scale): Promise<Scale> {
        return await this.client.updateById('scale', model.id, null, model)
            .then(response => {
                return <Scale>response;
            });
    }

    async delete(id: number): Promise<void> {
        return await this.client.deleteById('scale', id)
            .then(() => null);
    }

}
