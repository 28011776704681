import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { QuestionaryService } from "../../services/questionaries/questionary-service";
import { Questionary } from "models/questionaries/questionary";
import { ItemToQualify } from "models/questionaries/itemToQualify";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { Language } from "models/localizations/language";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { PerfilticService } from 'main/admin/services/perfiltic/perfiltic-service';
import { QuestionaryLocalization } from 'models/questionaries/questionaryLocalization';
import { LocalizationType } from 'models/questionaries/localizationType';

@autoinject()
export class QuestionaryCreateTranslation {

  private logger: Logger;

  private model: Questionary = new Questionary();
  private translatedQuestionnaries: Questionary[] = [];
  private translatedmodel: QuestionaryLocalization = new QuestionaryLocalization();
  private languages: Language[] = [];
  private questionaryLanguageId: number = 0;
  private validationController: ValidationController;

  constructor(
    private questionariesService: QuestionaryService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    public languagesService: CoreLanguageService,
    public questionaryType: QuestionaryType,
    public itemToQualify: ItemToQualify,
    public perfilticservice: PerfilticService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("QuestionaryCreateTranslation");

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("organizationType").displayName("main.admin.pages.questionaries.organization_type").required()      
      .ensure("languageId").displayName("main.admin.pages.questionaries.language").required()
      .on(this.model)
      .ensure("translation").displayName("main.admin.pages.questionaries.translated_name").required()
      .on(this.translatedmodel);
  }

  private async activate(params: any): Promise<void> {
    try {
      this.languages = await this.languagesService.getAll();    
      if (!isNaN(params.questionaryId)) {
        this.model = await this.questionariesService.getById(+params.questionaryId);
        this.translatedQuestionnaries = await this.questionariesService.getAllTranslationsByQuestionary(this.model);
        if(this.model && this.translatedQuestionnaries)
        {
          if(isNaN(params.languageId)){
            this.languages = this.languages.filter(x => x.id !== this.model.languageId);
            this.languages = this.languages.filter(language => {
              return !this.translatedQuestionnaries.some(translation => translation.languageId === language.id);
            });
          }
          else{
            this.questionaryLanguageId = params.languageId;
            this.translatedmodel.translation = this.translatedQuestionnaries.find(x => x.languageId == this.questionaryLanguageId).name;
            this.translatedmodel.languageId = this.questionaryLanguageId;
            this.languages = this.languages.filter(x => x.id == this.questionaryLanguageId);
          }
        }
      }
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.questionariesService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    try {
      if (this.translatedmodel.translation.toLowerCase() != this.model.name.toLowerCase()) {
        this.translatedmodel.labelId = this.model.id;
        this.translatedmodel.localizationTypeId = LocalizationType.Questionary;
        if(this.questionaryLanguageId <= 0){
          let response: QuestionaryLocalization = await this.questionariesService.createLocalization(this.translatedmodel);
          this.notification.success("main.admin.pages.questionaries.messages.questionary_added");
        }
        else{
          let response: QuestionaryLocalization = await this.questionariesService.updateLocalization(this.translatedmodel);
          this.notification.success("main.admin.pages.questionaries.messages.questionary_update");
        }

        if (!keepEditing) {
          return await this.router.navigateToRoute("questionaries");
        } else {
          return await this.router.navigateToRoute("questionary-translate-child-router", { questionaryId: this.model.id });
        }

      } else {

        this.notification.warning("main.admin.pages.questionaries.questions.errors.error_bad_option");

      }

    } catch (error) {
      this.notification.error(error);
    }
  }

}
