
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class ThematicGroupListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("ThematicGroupListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./thematic-group-list"),
        name: "thematic-groups",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.thematic_groups.title"),
      },

      {
        route: "thematic-group/:thematicGroupId",
        moduleId: PLATFORM.moduleName("./thematic-group-child-router"),
        name: "thematic-group-child-router",
        // nav: false,
        // title: this.i18n.tr("main.admin.pages.thematic_groups.thematic_group"),
      },
      
    ]);
  }
}
