import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { PersonObserver } from "models/test-person/personObserver";
import { BulkActivation } from "models/test-person/bulkActivation";

@autoinject()
export class PersonObserverService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("PersonObserverService");
  }

  public async list(observedId: number, activationId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`personobserver/list/${observedId}/${activationId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(observedId: number): Promise<PersonObserver[]> {
    return await this.client.get(`personobserver/get-all/${observedId}`)
      .then((response: any) => {
        return <PersonObserver[]>response;
      });
  }

  public async getById(id: number): Promise<PersonObserver> {
    return await this.client.getById("personobserver", id)
      .then((response: PersonObserver) => {
        return response;
      });
  }

  public async create(model: PersonObserver): Promise<PersonObserver> {
    return await this.client.create(`personobserver/${model.observedId}`, model)
      .then((response: any) => {
        return <PersonObserver>response;
      });
  }

  public async update(model: PersonObserver): Promise<any> {
    return await this.client.updateById("personobserver", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<any> {
    return await this.client.deleteById(`personobserver`, id)
      .then(() => null);
  }

  public async getWeightAllEvaluatorsByActivation(activationId: number, observedId: number): Promise<number> {
    return await this.client.get(`personobserver/get-weight-all-observers-by-activation/${activationId}/${observedId}`)
      .then((response: any) => {
        return <number>response;
      });
  }


  public async createBulkActivation(model: BulkActivation): Promise<BulkActivation> {

    let formData = new FormData();

    let filedata = null;
    if (model.filedata) {
      filedata = model.filedata[0];
    }

    formData.append('filedata', filedata);
    formData.append('filename', model.fileName);
    formData.append('name', model.name);
    return await this.client.requestFormData("PUT", `personobserver/bulk-activation/${model.observedId}/${model.activationId}`, formData)
      .then((response: any) => {
        return <BulkActivation>response;
      });
  }
}
