import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { MessageTalent } from "models/messages/message-talent";

@autoinject
export class MessageTalentService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("MessageTalentService");
  }

  public async getById(id: number): Promise<MessageTalent> {
    return await this.client.getById("messagetalent", id)
      .then((response: any) => {
        return <MessageTalent>response;
      });
  }

  public async create(model: MessageTalent): Promise<MessageTalent> {
    return await this.client.create("messagetalent", model)
      .then((response: any) => {
        return <MessageTalent>response;
      });
  }

  public async update(model: MessageTalent): Promise<void> {
    return await this.client.updateById("messagetalent", model.franchiseId, null, model)
      .then(() => null);
  }
}
