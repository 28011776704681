import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { QuestionaryConfiguration } from "models/test-management/questionaries-configuration/questionary-configuration";

@autoinject()
export class QuestionaryConfigurationService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("QuestionaryConfigurationService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("questionaryconfiguration/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async update(model: QuestionaryConfiguration): Promise<QuestionaryConfiguration> {
    return await this.client.updateById("questionaryconfiguration", model.id, null, model)
      .then((response) => { return <QuestionaryConfiguration>response; });
  }

}
