import { Agreement } from '../../../../models/agreements/agreement';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AgreementMasterService } from "main/admin/services/agreements/agreement-master-service";
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { AgreementReadOnlyModeModal } from './agreement-readonly-mode-modal';

@autoinject()
export class AgreementsToApprove {

  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private dialogService: DialogService,
    private agreementMasterService: AgreementMasterService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("AgreementsToApprove");
  }

  public async attached(): Promise<any> {
    this.logger.debug("attached");
    try {
      let response: UxDatatableResponse = await this.agreementMasterService.listToApprove(this.parameters);
      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;
    }
    catch (error) {
      this.notification.error(error);
    }
  }


  public async view(item: Agreement): Promise<any> {
    this.logger.info("view");

    let params: any = {
      companyGroupId: item.company.parentId,
      companyDetailId: item.companyId,
      agreementId: item.id,
    };

    return await this.dialogService.open({
      viewModel: AgreementReadOnlyModeModal,
      model: params
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return null;
      }
    });

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = async (): Promise<UxDatatableResponse> => {
    return this.agreementMasterService.listToApprove(this.parameters);
  }
}
