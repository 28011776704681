import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { OrganizationService } from "main/test/services/organization/organization-service";
import { Report } from "models/test-reports/reports/report";
import { Parameter } from "models/test-reports/reports/parameter";
import { Organization } from "models/test-organization/organization";

interface ItemAvailable {
  [id: number]: boolean;
}
@autoinject()
export class SelectOrganizationList {

  private logger: Logger;

  @bindable
  private report: Report;

  @bindable
  private parameterCurrent: Parameter;

  @bindable
  private selectedAmount: number;

  private selectedCurrent: number;

  private banCheck: boolean;

  private banIds: number[] = [];

  private itemAvailable: ItemAvailable = {};

  private items: any[] = [];

  private auxItems: any[] = [];

  private selectionLabel: string;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.organizationService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        // response.data.forEach(x => {
        //   this.itemAvailable[x.id] = false;
        // });

        this.checkAmount();

        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private organizationService: OrganizationService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("SelectOrganizationList");
    this.selectedCurrent = 0;
    this.banCheck = false;

    this.parameters.pageSize = 50;
  }

  private async attached(): Promise<any> {

    if (this.report.companyId) {
      this.organizationService.client.currentCompanyId = this.report.companyId;
      return await this.databind();
    }
  }

  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private getSelectionLabel(): void {
    this.selectionLabel = '';
    this.items.forEach(x => {
      if (this.selectionLabel !== '') {
        this.selectionLabel += ', ';
      }
      this.selectionLabel += x.name;
    });
  }

  public async selectParameter(item: Organization): Promise<void> {

    let banGeneral = this.banIds.findIndex(y => y === item.id);
    if (banGeneral == -1) {
      this.banIds.push(item.id);
      this.selectedCurrent = ++this.selectedCurrent;
      this.items.push(item);
    } else {
      this.banIds.splice(banGeneral, 1);
      this.selectedCurrent = --this.selectedCurrent;
      this.items.splice(banGeneral, 1);
    }

    this.checkAmount();

    switch (this.parameterCurrent) {
      case Parameter.Organization: {
        this.report.organizationId = this.items.map(x => x.id)[0];
        break;
      }
      case Parameter.CompareToOrganization: {
        this.report.compateToOrganizationId = this.items.map(x => x.id)[0];
        break;
      }
      default: {
        break;
      }
    }

    this.getSelectionLabel();

  }

  private checkAmount() {
    if (this.selectedCurrent < +this.selectedAmount) {
      this.banCheck = false;
      this.parameters.tableData.forEach(x => {
        this.itemAvailable[x.id] = false;
      });

    } else {
      this.banCheck = true;
      this.parameters.tableData.forEach(x => {
        let ban = this.banIds.findIndex(y => y === x.id);
        if (ban === -1)
          this.itemAvailable[x.id] = true;
        else
          this.itemAvailable[x.id] = false;
      });
    }
  }

}
