import { Plan } from "models/plans/plan";
import { Notification } from "common/services/notification";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult, DialogController } from "aurelia-dialog";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AgreementPlanService } from "main/admin/services/agreements/agreement-plan-service";

@autoinject()
export class TestPlanItemListModal {
  private planId: number;
  private plan: Plan = new Plan();
  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private isRobotHunter: boolean;
  constructor(
    private agreementPlanService: AgreementPlanService,
    private dialogController: DialogController,
    private notification: Notification) {

    this.logger = LogManager.getLogger("TestPlanItemListModal");
  }

  public async activate(planId: number): Promise<void> {

    this.planId = planId;
    return await this.databind();

  }

  public async databind(): Promise<void> {
    this.logger.info("databind");
    if (this.planId) {
      try {
        this.plan = await this.agreementPlanService.getById(this.planId);        
        this.isRobotHunter = this.plan.isRobotHunter;
        let response = await this.agreementPlanService.getTestPlanItemsByPlanId(this.planId, this.parameters)

        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;

      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private refresh = async (parameters: UxDatatableParameters): Promise<UxDatatableResponse> => {
    return await this.agreementPlanService.getTestPlanItemsByPlanId(this.planId, parameters);
  }

}
