
import { bindable, customElement, customAttribute, LogManager, inject, bindingMode, inlineView, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Disposable } from "aurelia-binding";
import { BindingEngine } from "aurelia-binding";
import { I18N } from "aurelia-i18n";

export class UxDatatableParameters {
  search?: any = {};
  skip?: number = 0;
  pageSize?: number = 20;
  totalRecords?: number = 0;
  tableData?: Array<any> = [];
  currentPage?: number = 1;

  totalPages?: number=0; 
  canGetMoreItems?: boolean = false; 
  pageForItems?: number = 5;
  pagesNumber? = [];
}

export class UxDatatableResponse {
  data: Array<any> = [];
  totalRecords: number = 0;
  totalPages?:number =0; 
}


interface ScrollContext {
  topIndex: number;
  isAtBottom: boolean;
  isAtTop: boolean;
}

@customAttribute("ux-datatable-pagination")
@inject(BindingEngine)
export class UxDatatableCustomAttribute {

  private logger: Logger;

  private refreshing: boolean = false;
  private totalPages: number = 0;
  private canGetMoreItems: boolean = true;

  @bindable
  public parameters: UxDatatableParameters;

  @bindable
  public onRefresh: Function;

  private subscriptions: Array<Disposable> = [];

  constructor(
    private bindingEngine: BindingEngine) {
    this.logger = LogManager.getLogger("AuDatatableCustomAttribute");
  }

  public attached(): void {
    this.subscriptions.push(this.bindingEngine
      .propertyObserver(this.parameters, "tableData")
      .subscribe(() => this.updateRecordInfo()));
  }

  public detached(): void {
    this.subscriptions.forEach(x => x.dispose());
  }

  private updateRecordInfo(): void {
    this.totalPages = Math.ceil(parseInt(this.parameters.totalRecords.toString(), null) / this.parameters.pageSize);
    // this.logger.debug("totalPages:", this.totalPages);
    this.canGetMoreItems = this.totalPages > this.parameters.currentPage;

    this.parameters.totalPages = this.totalPages; 
    this.parameters.canGetMoreItems = this.canGetMoreItems;
    this.parameters.pagesNumber = [];
    var initialPage = 1;
    var iniNextPage = initialPage;
    if(this.parameters.currentPage>1 && ( (this.parameters.currentPage-1) %  this.parameters.pageForItems) == 0){
      var totalpagesheet = this.parameters.pageForItems;
      iniNextPage = (this.parameters.currentPage /this.parameters.pageForItems)*this.parameters.pageForItems;
      initialPage = iniNextPage;
    }else{
      if(this.parameters.currentPage > this.parameters.pageForItems){
        initialPage = ((Math.trunc(this.parameters.currentPage /this.parameters.pageForItems) ) * this.parameters.pageForItems )+1;
        console.log((Math.trunc(this.parameters.currentPage /this.parameters.pageForItems)  ) );
      }
    }
    totalpagesheet = (initialPage-1) + this.parameters.pageForItems;
 
    for(var i=initialPage; i<=totalpagesheet ; i++ ){
      this.parameters.pagesNumber.push(i)
    }
    this.parameters.pagesNumber.push('...')
   
    let a = this.parameters.pagesNumber;
  }

  public async getMoreItems(scrollContext: ScrollContext): Promise<any> {
    this.logger.debug("getMoreItems");
    if (scrollContext
      && scrollContext.isAtBottom
      && !this.refreshing
      && this.canGetMoreItems) {
      // this.logger.debug("getting more items...");
      await this.changePage();
    }
  }

  public async changePage(): Promise<any> {
    if (typeof this.onRefresh !== "function") {
      throw new Error("[ux-datatable-pagination:changePage] No onChangePage() callback has been set");
    }
    // this.logger.debug(`this.parameters.currentPage: ${this.parameters.currentPage}, this.totalPages: ${this.totalPages}`);

    // if (this.totalPages && this.totalPages < this.parameters.currentPage) return;

    this.refreshing = true;    
    this.parameters.skip = this.parameters.currentPage * this.parameters.pageSize;
    this.parameters.currentPage = this.parameters.currentPage + 1;

    try {
      let response: UxDatatableResponse = await this.onRefresh();
      this.parameters.totalRecords = response.totalRecords;
      // this.parameters.tableData = this.parameters.tableData.concat(response.data);
      this.parameters.totalPages = response.totalPages; 
      Array.prototype.push.apply(this.parameters.tableData, response.data);

    }
    catch (error) {
      this.logger.error(error);
    }

    this.refreshing = false;
  }
}
@inlineView(`

<template>
  <div class="row">
    <div class="col-md-6">
      <nav aria-label="Page navigation example">
        <ul class="pagination">          
          <li class.bind="parameters.currentPage == 1 ? 'page-item disabled' : 'page-item'">
            <a href="#" class="page-link" click.delegate="getFirstPage()" 
              role="button">
              <i class="material-icons" style="font-size: 12px">keyboard_double_arrow_left</i>
            </a>
          </li>
          <li class.bind="parameters.currentPage == 1 ? 'page-item disabled' : 'page-item'">
            <a href="#" class="page-link" click.delegate="getPrevPage()" 
              role="button">
              <i class="material-icons" style="font-size: 10px">arrow_back_ios</i>
            </a>
          </li>
          <li virtual-repeat.for="item of parameters.pagesNumber"
            class.bind="parameters.currentPage == item ? 'page-item active' : 'page-item'">
            <a class="page-link" click.delegate="getPage(item)">\${ item }</a>
          </li>
          <li class.bind="parameters.currentPage == parameters.totalPages ? 'page-item disabled' : 'page-item'">
            <a href="#" class="page-link" click.delegate="getNextPage()" 
              role="button">
              <i class="material-icons" style="font-size: 10px">arrow_forward_ios</i>
            </a>
          </li>
          <li class.bind="parameters.currentPage == parameters.totalPages ? 'page-item disabled' : 'page-item'">
            <a href="#" class="page-link" click.delegate="getLastPage()" 
              role="button">
              <i class="material-icons" style="font-size: 12px">keyboard_double_arrow_right</i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-3">
      <div class="row">
        <div class="col-12 col-md-6">
           <select style="width: 70px" class="form-control" value.bind="parameters.pageSize" change.delegate="getFirstPage()">
              <option repeat.for="option of itemsByPage" model.bind="option">\${option}</option>
          </select>
        </div> 
        <div class="col-12 col-md-6">
          <p>\${'main.admin.pages.pagination.items_page' & t}</p>
        </div>
      </div>      
    </div>   
    <div class="col-md-3">
      <h6>\${message}</h6>    
    </div>
  </div>
  <template>

`)
@customElement("ux-datatable-pagination-selector")
export class UxDatatablePagination {
  private logger: Logger;

  @bindable
  public parameters: UxDatatableParameters;

  @bindable
  public onRefresh: Function;

  @bindable
  public inputClasses: string;

  private refreshing: boolean = false;
  public totalPage: number = 0
  public message: string;

  private subscriptions: Array<Disposable> = [];
  public  itemsByPage: Array<Number> = [5,10,20,50,100];
  constructor(private bindingEngine: BindingEngine,
    private i18n: I18N,) {
    this.logger = LogManager.getLogger("UxDatatableInfoCustomElement");
  }
  public attached(): void {
    this.subscriptions.push(this.bindingEngine
      .propertyObserver(this.parameters, "tableData")
      .subscribe(() => this.updateRecordInfo()));       
  }       
  private  updateRecordInfo(): void {  
    this.validateIsaFunction();
    this.totalPage = Math.ceil(parseInt(this.parameters.totalRecords.toString(), null) / this.parameters.pageSize);
    this.parameters.totalPages = this.totalPage;    
    if (this.parameters.pagesNumber.length == 0){
      this.pushPagesNumber(1,this.parameters.pageForItems)      
    }     
    this.message = this.i18n.tr("main.admin.pages.pagination.current_page").replace('{0}', this.parameters.currentPage.toString())
                    .replace('{1}' ,this.parameters.totalPages.toString())
                    .replace('{2}', this.parameters.totalRecords.toString())         
  }
  public pushPagesNumber(min: number, max:number){
    
    for (let i = min; i <= max; i++) {
      this.parameters.pagesNumber.push(i);
     }
  }
  public calculateSkip(min:number, max:number){
    for (let i = min; i < max; i++) {
      this.parameters.skip = this.parameters.skip + parseInt(this.parameters.pageSize.toString());
    }  
  }
  public validateIsaFunction(){
    if (typeof this.onRefresh !== "function") {
      throw new Error("[ux-datatable-search:search] No onSearchChange() callback has been set");
    }
  }
  public async refreshResponse(){    
    try {   
      let response: UxDatatableResponse = await this.onRefresh() as UxDatatableResponse;
      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;
      this.parameters.totalPages = response.totalPages; 
     
    } catch (error) {
      this.logger.error(error);
    }
  }
  public async getFirstPage(): Promise<void> {    
    this.parameters.skip = 0;
    this.parameters.currentPage = 1;
    this.parameters.pagesNumber = [];
    this.pushPagesNumber(this.parameters.currentPage,this.parameters.pageForItems);    
    await this.refreshResponse();
  }
  public async getLastPage(): Promise<void> {    
    this.parameters.skip = 0;     
    this.parameters.pagesNumber = [];

    if ((this.parameters.totalPages % this.parameters.pageForItems) != 0){
     this.pushPagesNumber((this.parameters.totalPages - ((this.parameters.totalPages % this.parameters.pageForItems)-1)),this.parameters.totalPages);
    }else{
      this.pushPagesNumber((this.parameters.totalPages - this.parameters.pageForItems),this.parameters.totalPages);
    }

   
    this.calculateSkip(1,this.parameters.totalPages);
    // for (let i = 1; i < this.parameters.totalPages; i++) {
    //   this.parameters.skip = this.parameters.skip + parseInt(this.parameters.pageSize.toString());
    // }
    this.parameters.currentPage = this.parameters.totalPages;
    await this.refreshResponse();
  }
  public async getPage(page: number): Promise<void>{
    this.parameters.skip = 0;
    this.calculateSkip(1,page);
    // for (let i = 1; i < page; i++) {
    //   this.parameters.skip = this.parameters.skip + parseInt(this.parameters.pageSize.toString());
    // }    
    this.parameters.currentPage = page;
    await this.refreshResponse();
  }
  public async getNextPage(): Promise<void>{
   
    if ((this.parameters.currentPage % this.parameters.pageForItems) == 0){      
      this.parameters.pagesNumber = [];
      this.pushPagesNumber((this.parameters.currentPage + 1),(this.parameters.currentPage + this.parameters.pageForItems));
    }
    this.parameters.skip = 0;
    this.parameters.currentPage = this.parameters.currentPage + 1;
    this.calculateSkip(1,this.parameters.currentPage);     
    await this.refreshResponse();
  }
  public async getPrevPage(): Promise<void>{    

    if (((this.parameters.currentPage - 1) % this.parameters.pageForItems) == 0){      
      this.parameters.pagesNumber = [];
      this.pushPagesNumber((this.parameters.currentPage - this.parameters.pageForItems),(this.parameters.currentPage - 1));
    }   
    
    this.parameters.skip = 0;
    this.parameters.currentPage = this.parameters.currentPage - 1;
    this.calculateSkip(1,this.parameters.currentPage);     
    await this.refreshResponse();
  }
}

@inlineView(`
    <template>
        <div class="au-table-search d-flex">
            <input keyup.delegate="search() & debounce:500"
                value.bind="parameters.search.Query"
                type="text"
                placeholder.bind="placeholder"
                class.bind="inputClasses" />
            <button class="btn btn-link ml-2 p-0" click.delegate="search()" title="Refresh">
              <i class="material-icons">
                refresh
              </i>
            </button>
        </div>
    </template>
`)

@customElement("ux-datatable-pagination-search")
export class UxDatatableSearchCustomElement {
  private logger: Logger;

  @bindable
  public placeholder: string;

  @bindable
  public inputClasses: string;

  @bindable
  public onSearchChange: Function;

  @bindable
  public parameters: UxDatatableParameters;

  constructor() {
    this.logger = LogManager.getLogger("UxDatatableInfoCustomElement");
  }

  public async search(): Promise<void> {
    if (typeof this.onSearchChange !== "function") {
      throw new Error("[ux-datatable-search:search] No onSearchChange() callback has been set");
    }

    try {
      this.reset();
      let response: UxDatatableResponse = await this.onSearchChange() as UxDatatableResponse;
      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;
      this.reset();
      let res: UxDatatablePagination;     
     
    } catch (error) {
      this.logger.error(error);
    }
  }

  private reset(): void {
    this.parameters.currentPage = this.parameters.totalRecords > 0 ? 1 : 1;
    this.parameters.skip = 0;
    this.parameters.pagesNumber = [];
    
    for (let i = this.parameters.currentPage; i <= this.parameters.pageForItems; i++) {
      this.parameters.pagesNumber.push(i);
     }
    
  }
}


@inlineView(`
    <template>
        <div class="au-table-info">
            \${ info }
            <span if.bind="parameters.search.Query.length > 0 && parameters.tableData.length > 0">&nbsp;(\${ labelFiltered })</span>
        </div>
    </template>
`)

@customElement("ux-datatable-pagination-info")
@inject(BindingEngine)
export class UxDatatableInfoCustomElement {
  private logger: Logger;

  @bindable
  public message: string;

  @bindable
  public labelFiltered: string;

  @bindable
  public parameters: UxDatatableParameters;

  private info: string;
  private subscriptions: Array<Disposable> = [];

  constructor(
    private bindingEngine: BindingEngine) {
    this.logger = LogManager.getLogger("UxDatatableInfoCustomElement");
  }

  public attached(): void {
    if (!this.message) {
      this.message = "TOTAL_RECORDS records";
    }
    if (!this.labelFiltered) {
      this.labelFiltered = "filtered";
    }

    // this.subscriptions.push(this.bindingEngine
    //   .propertyObserver(this.parameters, "tableData")
    //   .subscribe(() => this.updateRecordInfo()));

    this.subscriptions.push(this.bindingEngine
      .propertyObserver(this.parameters, "totalRecords")
      .subscribe(() => this.updateRecordInfo()));
  }

  public detached(): void {
    this.subscriptions.forEach(x => x.dispose());
  }

  private updateRecordInfo(): void {
    this.info = this.message.replace("TOTAL_RECORDS", this.parameters.totalRecords.toString());
    // this.logger.debug("info", this.info);
  }

}

