import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

@autoinject
export class ScaleThematicGroups {

  private logger: Logger; 
  private questionaryId: number; 

  constructor() {
    this.logger = LogManager.getLogger("ScaleThematicGroups");
  }

  private async activate(params: any): Promise<void> {
    this.logger.info("activated");

    if (!isNaN(params.questionaryId)) {
      this.questionaryId = +params.questionaryId;
    }

  }

}
