
import { autoinject, bindable, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { RouteConfig } from "aurelia-router";
import { CompanyDetailService } from "main/admin/services/companies/company-detail-service";
import { CompanyDetail } from "models/companies/company-detail";

@autoinject()
export class UserMasterList {

  private logger: Logger;
  @bindable
  private params: any = {};
  private companyId: number;
  private companyGroupId: number;
  private companyDetail: CompanyDetail;

  constructor(
    private companyDetailService: CompanyDetailService) {
    this.logger = LogManager.getLogger("UserMasterList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;

    if (!isNaN(this.params.companyId)) {
        this.companyId = +this.params.companyId;
        this.companyDetail = await this.companyDetailService.getById(this.companyId);
        this.companyGroupId = this.companyDetail.company.parentId;
        this.params.companyGroupId = this.companyGroupId;
    }
  }
}
