import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { OtherPlanItem } from "models/plans/other-plan-item";

@autoinject()
export class OtherPlanItemsService {

  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("OtherPlanItemsService");
  }

  async list(planId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`otherplanitem/list/${planId}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

  async getById(id: number): Promise<OtherPlanItem> {
    return await this.client.getById('otherplanitem', id)
      .then(response => {
        return <OtherPlanItem>response;
      });
  }

  async create(model: OtherPlanItem): Promise<OtherPlanItem> {
    return await this.client.post('otherplanitem', model)
      .then(response => {
        return <OtherPlanItem>response;
      });
  }

  async update(model: OtherPlanItem): Promise<OtherPlanItem> {
    return await this.client.updateById('otherplanitem', model.id, null, model)
      .then(() => null);
  }

  async delete(id: number): Promise<void> {
    return await this.client.delete('otherplanitem', id)
      .then(() => null);
  }

}
