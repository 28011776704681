import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { ArchiveService } from "main/test/services/archive/archive-service";

import { Archive } from "models/test-archive/archive";
import { RestoreConfirmation } from "../../../components/restore-confirmation";

@autoinject()
export class TeamList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private router: Router,
    private archiveService: ArchiveService,
    private notification: Notification,
    private dialogService: DialogService,
  ) {

    this.logger = LogManager.getLogger("ArchiveList");
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('archiveListSearch', search == null ? '' : search);

    return await this.archiveService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.parameters.search.Query = sessionStorage.getItem('archiveListSearch');
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.logger.debug("activate companyId:", this.params.companyId);
      this.companyId = +this.params.companyId;
      this.archiveService.client.currentCompanyId = this.companyId;
      return await this.databind();
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async clearFilters() {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
    return await this.databind();
  }

  public async update(item: Archive): Promise<void> {
    return await this.dialogService.open({
      viewModel: RestoreConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        try {
          await this.archiveService.changeState(item);

          let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === item.id);
          this.parameters.tableData.splice(indexOfEditedModel, 1);
          this.parameters.totalRecords--;

          this.notification.success("main.admin.pages.archive.notifications.change_state");
        } catch (error) {
          this.notification.error(error);
        }
      }
    });
  }

}
