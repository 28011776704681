import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Activity } from 'models/agreements/activity';

@autoinject()
export class ActivityService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("Activity");
  }

  public async list(agreementId:number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`agreementactivity/list/${agreementId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  // public async getAll(agreementId:number): Promise<Activity[]> {
  //   return await this.client.get(`activity/get-all/${agreementId}`)
  //     .then((response: any) => {
  //       return <Activity[]>response;
  //     });
  // }

  public async getById(id: number): Promise<Activity> {
    return await this.client.getById("agreementactivity", id)
      .then((response: any) => {
        return <Activity>response;
      });
  }

  public async create(model: Activity): Promise<Activity> {
    return await this.client.create("agreementactivity", model)
      .then((response: any) => {
        return <Activity>response;
      });
  }

  public async update(model: Activity): Promise<void> {
    return await this.client.updateById("agreementactivity", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("agreementactivity", id)
      .then(() => null);
  }
}
