import { TransactionType } from './transaction-type';
import { Wallet } from './wallet';
import { Questionary } from '../questionaries/questionary';
import { User } from '../security/user';

export class Transaction {
  id: number;  
  transactionTypeId:  TransactionType;
  walletId:  number;
  wallet: Wallet = new Wallet();
  talentsAfter:  number;
  talentsBefore:  number;
  observation:  string;
  sourceUserId:  number;
  sourceUser: User = new User();
  targetUserId:  number;
  targetUser: User = new User();
  debit:  number;
  credit:  number;
  reportId:  number;
  questionary: Questionary = new Questionary();
  questionaryId: number;
  createdOn: Date;
}
