import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { PositionService } from '../../services/position/position-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { PositionEvaluatorService } from "../../services/position/position-evaluator-service";
import { Position } from "models/test-position/position";
import { PositionEvaluator } from 'models/test-position/positionEvaluator';
import { PositionEvaluatorEditModal } from './position-evaluator-edit-modal';
import { RouteConfig } from 'aurelia-router';
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { DeactivateConfirmation } from 'main/components/deactivate-confirmation';
import { I18N } from 'aurelia-i18n';
import { PositionActivationType } from 'models/test-position/positionActivationType';
import { BulkActivation } from 'models/test-person/bulkActivation';
import { PositionEvaluatorBulkActivationModal } from './position-evaluator-bulk-activation-modal';
import { PersonLoginInfoModal } from '../people/person-login-info-modal';
import { Person } from 'models/test-person/person';
import { PersonService } from 'main/test/services/people/person-service';
import config from 'config.js';

@autoinject()
export class PositionEvaluatorList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;

  private positionId: number;
  private model: Position = new Position();
  private total: number = 0;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private csvExampleUrl: string = "";
  private xlsxExampleUrl: string = "";

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    try {
      let response: UxDatatableResponse = await this.positionEvaluatorService.list(this.parameters, this.positionId);
      this.total = await this.positionEvaluatorService.getWeightAllEvaluators(this.positionId);
      return response;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  constructor(
    private i18n: I18N,
    private positionService: PositionService,
    private positionEvaluatorService: PositionEvaluatorService,
    private personService: PersonService,
    private notification: Notification,
    private dialogService: DialogService
  ) {

    this.logger = LogManager.getLogger("PositionEvaluatorList");

    let backendUrl = config.URLS_BACKEND;
    this.csvExampleUrl = backendUrl + "/person-content/bulk-evaluator-example.csv";
    this.xlsxExampleUrl = backendUrl + "/person-content/bulk-evaluator-example.xlsx";
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      if (!isNaN(this.params.positionId)) {
        this.positionId = this.params.positionId;

        this.positionService.client.currentCompanyId = this.companyId;
        this.positionEvaluatorService.client.currentCompanyId = this.companyId;

        this.model = await this.getItem();

        return await this.databind();
      }
    }
  }

  private async getItem(): Promise<Position> {
    return await this.positionService.getById(this.positionId);
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async create(): Promise<any> {
    let item = new PositionEvaluator();
    item.positionId = this.model.id;

    return await this.dialogService.open({
      viewModel: PositionEvaluatorEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.positionEvaluatorService.create(item)
          .then(async () => {

            this.notification.success("notifications.item_added");

            await this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async update(item: PositionEvaluator): Promise<any> {

    return await this.dialogService.open({
      viewModel: PositionEvaluatorEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.positionEvaluatorService.update(item)
          .then(async () => {

            this.notification.success("notifications.item_added");

            await this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async delete(item: PositionEvaluator): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.evaluator.fullName
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.positionEvaluatorService.delete(item.id)
          .then(async () => {

            this.notification.success("notifications.item_deleted");

            await this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });

      }
    });
  }

  private async createBulkActivation(): Promise<any> {
    let item = new BulkActivation();
    item.positionId = this.model.id;
    item.name = this.model.name;

    return await this.dialogService.open({
      viewModel: PositionEvaluatorBulkActivationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.positionEvaluatorService.createBulkActivation(item)
          .then(async () => {

            this.notification.success("notifications.item_added");

            await this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async sendIndividualReminder(id: number): Promise<boolean> {
    try {
      await this.personService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async viewLoginInfo(personId: number): Promise<any> {

    return await this.dialogService.open({
      viewModel: PersonLoginInfoModal,
      model: personId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.sendIndividualReminder(personId);
      }
    });
  }

  private async canDeactivate(): Promise<boolean> {

    if (this.total === 100 || this.model.positionActivationType !== PositionActivationType.Concerted) {
      return true;
    }

    return await this.dialogService.open({
      viewModel: DeactivateConfirmation,
      model: this.i18n.tr("main.test.pages.positions.position_evaluator_list.can_deactivate")
    }).whenClosed(async (response: DialogCloseResult) => {
      return !response.wasCancelled;
    });
  }


}
