import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { User } from "models/security/user";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { PasswordValidation } from "models/security/password-validation";
import { UserLoginInfo } from "models/security/user-login-info";

@autoinject
export class UserService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("UserService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("user/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async listArchive(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("user/list-archive", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<User[]> {
    return await this.client.get("user")
      .then((response: User[]) => {
        return response;
      });
  }

  public async getById(id: number): Promise<User> {
    return await this.client.getById("user", id)
      .then((response: User) => {
        return response;
      });
  }


  public async create(model: User): Promise<User> {
    return await this.client.create("user", model)
      .then((response: User) => {
        return response;
      });
  }

  public async update(model: User): Promise<void> {
    return await this.client.updateById("user", model.id, null, model)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {
    return await this.client.post(`user/change-state/${id}`)
      .then(() => null);
  }

  public async resendActivationMessage(id: number): Promise<void> {
    return await this.client.post(`user/resend-activation-message/${id}`)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("user", id)
      .then(() => null);
  }  

  public async getLoginInfoById(id: number): Promise<UserLoginInfo> {
    return await this.client.get(`user/login-info/${id}`)
      .then((response: UserLoginInfo) => {
        return response;
      });
  }

  public async validate(model: PasswordValidation): Promise<boolean> {
    return await this.client.post("user/validate", model)
      .then((response: boolean) => {
        return response;
      });
  }
  public async userRestore(model: User): Promise<void> {
    return await this.client.updateById("user/archive", model.id, null)
      .then(() => null);
  }

}
