import { Activation } from 'models/test-person/activation';
import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Person } from "models/test-person/person";
import { PersonService } from "../../test/services/people/person-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Report } from "models/test-reports/reports/report";
import { Parameter } from "models/test-reports/reports/parameter";
import { ActivationService } from 'main/test/services/people/activation-service';
import { PersonObserver } from 'models/test-person/personObserver';

interface ItemAvailable {
  [id: number]: boolean;
}
@autoinject()
export class SelectObserverList {

  private logger: Logger;

  @bindable
  private model: Person = new Person();

  @bindable
  private report: Report;

  @bindable
  private parameterCurrent: Parameter;

  @bindable
  private selectedAmount: number;

  private selectedCurrent: number;

  private banCheck: boolean;

  private banIds: number[] = [];

  private itemAvailable: ItemAvailable = {};

  private items: any[] = [];

  private auxItems: any[] = [];

  private selectionLabel: string;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private personId: number;
  private validationController: ValidationController;

  constructor(
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private activationService: ActivationService,
    private personService: PersonService) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ObservationEdit");

    this.selectedCurrent = 0;
    this.banCheck = false;

    this.parameters.pageSize = 50;

  }

  private async attached(): Promise<any> {

    if (this.report.companyId) {

      this.personService.client.currentCompanyId = this.report.companyId;

      if (!isNaN(this.report.personId)) {
        this.personId = this.report.personId;
        await this.databind();
      }

      return await this.databind();
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {

        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
     this.logger.debug("refreshing");
    //  return await this.personService.getByObservatedId(this.parameters, this.personId)
    //    .then((response: UxDatatableResponse) => {
    //      response.data.forEach(x => {
    //        /// if(!this.observerAvailable[x.id])
    //       //  this.observerAvailable[x.id] = false;
    //     });
    //     this.checkAmount();
    
    //      return response;
    //    })
    //    .catch(error => {
    //      this.notification.error(error);
    //    });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private getSelectionLabel(): void {
    this.selectionLabel = '';
    this.items.forEach(x => {
      if (this.selectionLabel !== '') {
        this.selectionLabel += ', ';
      }
      this.selectionLabel += `${x.observer.firstName} ${x.observer.lastName}`;
    });
  }

  public async selectParameter(item: PersonObserver): Promise<void> {

    let banGeneral = this.banIds.findIndex(y => y === item.id);

    if (banGeneral == -1) {
      this.banIds.push(item.id);
      this.selectedCurrent = ++this.selectedCurrent;
      this.items.push(item);
    } else {
      this.banIds.splice(banGeneral, 1);
      this.selectedCurrent = --this.selectedCurrent;
      this.items.splice(banGeneral, 1);
    }

    this.checkAmount();

    // this.report.parameterSelect[this.parameterCurrent] =  this.banIds;   
    switch (this.parameterCurrent) {
      case Parameter.Observers: {
        this.report.observerIds = this.items.map(x => x.id);
        break;
      }
      default: {
        break;
      }
    }

    this.getSelectionLabel();

  }

  private checkAmount(){
    if (this.selectedCurrent < +this.selectedAmount) {
      this.banCheck = false;
      this.parameters.tableData.forEach(x => {
        this.itemAvailable[x.id] = false;
      });

    } else {
      this.banCheck = true;
      this.parameters.tableData.forEach(x => {
        let ban = this.banIds.findIndex(y => y === x.id);
        if (ban === -1)
          this.itemAvailable[x.id] = true;
        else
          this.itemAvailable[x.id] = false;
      });
    }
  }

}
