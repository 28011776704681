import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";

@autoinject()
export class CompanyTestPlanService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CompanyTestPlanService");
  }

  public async isResultsExport(): Promise<boolean> {
    return await this.client.get("companytestplan/is-results-export")
      .then((response: boolean) => {
        return response;
      });
  }
}
