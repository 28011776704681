import { Activity } from './activity';
import { AgreementStatus } from './agreementStatus';
import { AgreementDuration } from './agreementDuration';
import { Company } from 'models/companies/company';

export class Agreement {
  id: number;
  description: string;
  planId: number;
  talents: string;
  numApprovedUsers: number;
  whoApproveId: number;
  WhoApprove: string;
  agreementDuration: AgreementDuration;
  startDate: Date = new Date();
  endDate: Date = new Date();
  officialInterlocutorId: number;
  officialInterlocutor: string;
  legalRepresentativeId: number;
  legalRepresentative: string;
  agreementStatus: AgreementStatus;
  companyId: number;
  termsAndConditions: string;
  operationCountries: number[] = [];
  operationCountriesName: string;
  activities: Array<Activity> = new Array<Activity>();
  agreementBody: string;
  isComplete: boolean;
  corporateID: string;
  address: string;
  phone: string;
  phoneNumber: string;
  numberOfEmployees: number;
  company: Company = new Company();
  selectionPlanId: number;
  selectionTalents: string;
}
