import { StoreService } from 'common/store/store-service';
import { QuestionaryConfigurationModal } from './questionary-configuration-modal';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";

import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { State } from 'common/store/store-model';
import { Company } from 'models/companies/company';
import { QuestionaryConfigurationService } from '../../../services/test-management/questionary-configuration-service';
import { QuestionaryConfiguration } from 'models/test-management/questionaries-configuration/questionary-configuration';


@autoinject()
export class QuestionaryConfigurationList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private questionaryConfigurationService: QuestionaryConfigurationService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("QuestionaryConfigurationList");

    this.state = this.store.state;

  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.questionaryConfigurationService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any): Promise<void> {

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }


  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.questionaryConfigurationService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  // private async edit(item: QuestionaryConfiguration): Promise<void> {
  //   let orginialItem = JSON.parse(JSON.stringify(item));
  //   return await this.dialogService.open({
  //     viewModel: QuestionaryConfigurationModal,
  //     model: item
  //   }).whenClosed(async (response: DialogCloseResult) => {
  //     if (!response.wasCancelled) {
  //       return await this.questionaryConfigurationService.update(item)
  //         .then(async (model: QuestionaryConfiguration) => {

  //           this.logger.debug("model.id", model.id);
  //           item.id = model.id;

  //           this.notification.success("main.admin.pages.test_management.disc_styles.notifications.edited");
  //         })
  //         .catch(error => {
  //           this.notification.error(error);
  //         });
  //     }
  //     else {
  //       Object.assign(item, orginialItem);
  //     }
  //   });
  // }

  private async save(item: QuestionaryConfiguration): Promise<void> {

    if (this.isDaysBetween0And365(item.daysForDeadline)) {

      return await this.questionaryConfigurationService.update(item)
        .then(async (model: QuestionaryConfiguration) => {

          this.logger.debug("model.id", model.id);
          item.id = model.id;

          this.notification.success("main.admin.pages.test_management.questionaries_configuration.notifications.edited");
        })
        .catch(error => {
          this.notification.error(error);
        });

    }
    else {
      this.notification.warning("main.admin.pages.test_management.questionaries_configuration.notifications.days_for_deadline_wrong");
    }
  }


  private isDaysBetween0And365(n) {
    return /^(?:36[0-5]|3[0-5]\d|[12]\d{2}|[1-9]\d?)$/.test(n);
  }
}
