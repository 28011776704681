
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";

@autoinject
export class ReportListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {

    this.logger = LogManager.getLogger("ReportListChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./report-list"),
        name: "reports",
      },

      {
        route: "report/:itemToQualify?",
        moduleId: PLATFORM.moduleName("./report-child-router"),
        name: "report-child-router",
      },


    ]);
  }
}
