import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Permission } from "models/groups/permission";

@autoinject
export class PermissionService {

  private logger: Logger;

  constructor(
    private client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger('PermissionService');
  }

  public async getAll(): Promise<Permission[]> {
    return await this.client.get('permission')
      .then((response: Permission[]) => {
        return response;
      });
  }
}
