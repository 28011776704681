import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Organization } from "models/test-organization/organization";

@autoinject()
export class OrganizationService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("OrganizationService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("organization/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<Organization[]> {
    return await this.client.get("organization")
      .then((response: any) => {
        return <Organization[]>response;
      });
  }

  public async getById(id: number): Promise<Organization> {
    return await this.client.getById("organization", id)
      .then((response: Organization) => {
        return response;
      });
  }

  public async create(model: Organization): Promise<Organization> {
    return await this.client.create("organization", model)
      .then((response: any) => {
        return <Organization>response;
      });
  }

  public async update(model: Organization): Promise<void> {
    return await this.client.updateById("organization", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`organization`, id)
      .then(() => null);
  }

  public async sendRemindersByFilter(parameters: UxDatatableParameters): Promise<boolean> {
    return await this.client.post("organization/list-send-reminder/", parameters)
      .then((response: any) => {
        return true;
      });
  }

  public async sendReminder(id: number): Promise<boolean> {
    return await this.client.post(`organization/send-reminder/${id}`)
      .then((response: any) => {
        return true;
      });
  }

}
