import { addSlash } from 'common/utils/utility';
import { AgreementService } from '../../../admin/services/agreements/agreement-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable-pagination";
import { PersonService } from "../../services/people/person-service";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { PersonDeleteModal } from "./person-delete-modal";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { PersonLoginInfoModal } from "./person-login-info-modal";
import { FilteredReminderConfirmationModal } from "./filtered-reminder-confirmation-modal";
import { CompanyTestPlanService } from "main/admin/services/test-management/company-test-plan-service";
import { Wootric } from '../../../components/wootric';
import { OpenIdProfile } from 'models/security/open-id-profile';
import { SessionService } from 'common/services/session-service';
import { WootricComponentName } from "models/wootric/WootricComponentName";
import { Plan } from 'models/plans/plan';
import { PersonPredictorConfirmationModal } from './person-predictor-confirmation-modal'
import { TransactionService } from '../../../admin/services/talents/transaction-service';
import { SpendMode } from '../../../../models/plans/spendMode';
import { TransactionType } from 'models/talents/transaction-type';
import { Person } from 'models/test-person/person';
import config from 'config.js';

@autoinject()
export class PersonList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private profile: OpenIdProfile;
  private company: Company;
  private plan: Plan;
  private hasPredictorByPerson: boolean = false;

  // private ActivationMode = ActivationMode;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private parametersAgreemnt: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;
  private isResultsExport: boolean = false;
  // Network
  private imageAddr = "https://s3.us-east-1.amazonaws.com/pro-s3-objectstore/488c1078-ace4-42c6-a5a5-c07b1955173f.png" + "?n=" + Math.random();
  private startTime: number;
  private endTime: number;
  // kbps
  private downloadSize = 1000000; 
  private download = new Image();
  private random : number;


  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('personListSearch', search == null ? '' : search); 
    return await this.personService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private store: StoreService,
    private personService: PersonService,
    private agreementService: AgreementService,
    private companyTestPlanService: CompanyTestPlanService,
    private dialogService: DialogService,
    private notification: Notification,
    private sessionService: SessionService,
    private transactionService: TransactionService,
    private wootric: Wootric) {

    this.logger = LogManager.getLogger("PersonList");

    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");
    this.parameters.search.Query = sessionStorage.getItem('personListSearch');

    this.profile = await this.sessionService.getProfile();
    this.wootric.attached(this.profile, WootricComponentName.personList);

    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }   
  }

  private async bind(): Promise<any> {
    this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {
      this.logger.debug("activate companyId:", this.params.companyId);
      this.companyId = +this.params.companyId;
      if (this.companyId) {

        this.personService.client.currentCompanyId = this.companyId;
        this.companyTestPlanService.client.currentCompanyId = this.companyId;
        this.company = this.state.companies.find(x => x.id == this.companyId);

        await this.planByCompanyId(this.companyId);

        this.validateIsResultExport();

        this.isLoaded = true;

        this.random = Math.floor(Math.random() * 10);
        // Random para que valide el internet
        if (this.random == 2)
          this.networkInfo();       

        return await this.databind();
      }
    }
  }


  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {

        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async delete(item): Promise<void> {
    return await this.dialogService.open({
      viewModel: PersonDeleteModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.personService.delete(item.id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.notification.success("notifications.item_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });

      }
    });
  }

  private async sendIndividualReminder(id: number): Promise<boolean> {
    try {
      await this.personService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }

  }

  private async sendFilteredReminder(): Promise<any> {
    let personList = await this.personService.getListByFilter(this.parameters);
    return await this.dialogService.open({
      viewModel: FilteredReminderConfirmationModal,
      model: personList.length
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.personService.sendRemindersByFilter(personList)
          .then(() => {
            this.notification.success("main.test.pages.person.send_reminder_list")
            return true;
          });
      }
    });
  }

  private async viewLoginInfo(personId: number): Promise<any> {

    return await this.dialogService.open({
      viewModel: PersonLoginInfoModal,
      model: personId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        // this.logger.debug("response.output", response.output);
        return await this.sendIndividualReminder(personId);
      }
    });
  }

  private async clearFilters(): Promise<any> {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
    await this.databind();
  }

  private async validateIsResultExport() {
    try {
      this.isResultsExport = await this.companyTestPlanService.isResultsExport();
    } catch (error) {
      this.notification.error(error);
    }
  }

  private async planByCompanyId(companyId: number) {
    try {      
      let plan =  await this.agreementService.robothunterByCompany(companyId);
      
      if(plan != null)
        this.plan = plan;
    } catch (error) {
      this.notification.error(error);
    }
  }

  private async predictor(personId: number): Promise<any> {
    let predictorTalents = await this.personService.getPredictorTalentsConsumption(personId,TransactionType.Predictor);   

    if(predictorTalents > 0) {
      return await this.dialogService.open({
        viewModel: PersonPredictorConfirmationModal,
        model: predictorTalents
      }).whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) 
          await this.toPredictor(personId);
      });
    }

    await this.toPredictor(personId);
  }

  private async toPredictor(personId: number): Promise<void> {
    try {
    //  await this.personService.predictorConsumption(personId,TransactionType.Predictor);
      let testReportsUrl = config.URLS_PREDICTOR;
      const url = addSlash(testReportsUrl) + `dashboard/${this.companyId}/${personId}`;
      window.open(url, "_blank");      
    } catch (error) {
      this.notification.error(error.error);
    }
  }
  public networkInfo(){
    this.download.onload = () =>{
      this.endTime = (new Date()).getTime();
      this.showNetworkResults();
    }     
    this.startTime = (new Date()).getTime();
    this.download.src = this.imageAddr;     
  }
    public showNetworkResults(): any{  
        let speedBps: number;
        let speedKbps: number;
        let duration = (this.endTime - this.startTime) / 1000;
        let bitsLoaded = this.downloadSize * 8;
        // Valores de carga de la imagen
        speedBps = Number((bitsLoaded / duration).toFixed(2));
        speedKbps = Number((speedBps / 1024).toFixed(2));
        let speedMbps = Number((speedKbps / 1024).toFixed(2));
        // Validacion a 0.5 Mbps de navegacion.
       if (speedMbps < 5)     
        this.notification.warning("main.test.pages.person.network_speed"); 
    }


}
