import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { Question } from "models/questions/question";

@autoinject()
export class QuestionConfigModal {

  private model: Question = new Question();
  private textSizeOptions: boolean;
  private textSizeOptionSelected: number;

  constructor(
    private dialogController: DialogController) {
  }

  public activate(model: Question): void {
    this.model = model;
    this.toggleTextSize();
  }

  public async submit(): Promise<any> {
    return await this.dialogController.ok();
  }

  public toggleTextSize(): void {
    if (this.model.isTextSizeAvailable) {
      this.textSizeOptions = true;
      if (this.model.textSizeMin && this.model.textSizeMax) {
        this.textSizeOptionSelected = 2;
      } else {
        this.textSizeOptionSelected = 1;
      }
    } else {
      this.textSizeOptions = false;
      this.textSizeOptionSelected = null;
      this.model.textSize = null;
      this.model.textSizeMin = null;
      this.model.textSizeMax = null;
    }
  }

  public toggleTextSizeDetail(): void {
    if (this.textSizeOptionSelected === 1) {
      this.model.textSizeMin = null;
      this.model.textSizeMax = null;
    } else {
      this.model.textSize = null;
    }
  }

}
