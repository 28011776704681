
import { autoinject, bindable, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig, RoutableComponentDetermineActivationStrategy, activationStrategy } from "aurelia-router";

import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

import { Notification } from "common/services/notification";
import { User } from "models/security/user";
import { UserState } from "models/security/userState";
import { MasterUserService } from "../../services/users/master-user-service";
import { StoreService } from "common/store/store-service";
import { State } from "common/store/store-model";
import { Company } from "models/companies/company";

@autoinject()
export class MasterUserEdit implements RoutableComponentDetermineActivationStrategy {

  private logger: Logger;
  private params: any = {};

  private companyId: number;
  private company: Company;
  @bindable
  public currentCompanyGroup: number;


  private model: User = new User();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private masterUserService: MasterUserService
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("MasterUserEdit");

    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<void> {
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyGroupId)) {
      this.companyId = +this.params.companyGroupId;
    }

    this.masterUserService.client.currentCompanyId = this.companyId;

    this.company = this.state.companies.find(x => x.id === this.companyId);

    try {
      if (!isNaN(this.params.masterUserId)) {
        this.model = await this.masterUserService.getById(+this.params.masterUserId);
      } 
      else {
        this.model.userState = UserState.Pending;
      }
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("firstName").displayName("main.admin.pages.users.first_name").required()
      .ensure("lastName").displayName("main.admin.pages.users.last_name").required()
      .ensure("email").displayName("main.admin.pages.users.email").email().required()
      .on(this.model);
  }

  private async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.masterUserService.client.isRequesting;
  }

  private async save(): Promise<any> {
    if (!this.model.id) {
      return await this.masterUserService.create(this.model)
        .then(async (model: User) => {
          this.notification.success("notifications.item_added");

          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("master-users");
          } else {
            if(this.params.franchiseId){
              return await this.router.navigateToRoute("master-user-child-router", { masterUserId: model.id });
            }
            else{
              return await this.router.navigateToRoute("master-user-child-router", { masterUserId: model.id , companyGroupId: this.currentCompanyGroup});
            }
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.masterUserService.update(this.model)
        .then(async () => {

          this.notification.success("notifications.item_edited");

          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("master-users");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  public determineActivationStrategy(): any {
    return activationStrategy.replace;
  }

}
