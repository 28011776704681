import { CompanyInfoService } from './../../../services/companies/company-info-service';
import { CompanyDetail } from './../../../../models/companies/company-detail';

import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Position } from "models/test-position/position";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ItemToQualify } from "models/questionaries/itemToQualify";
import { CompanyQuestionaryService } from 'main/admin/services/test-management/company-questionary-service';
import { EducationalLevelCountry } from "models/educational-levels-by-country/educational-level-country";
import { EducationalLevelCountryService } from "main/admin/services/educational-levels-by-country/educational-level-country-service";
import { StoreService } from 'common/store/store-service';
import { State } from 'common/store/store-model';
import { PositionActivationType } from 'models/test-position/positionActivationType';
import { PositionService } from 'main/test/services/position/position-service';

@autoinject()
export class PositionReferenceEdit {

  private logger: Logger;
  public companyId: number;
  public companyDetail: CompanyDetail;

  private positionId: number;

  private model: Position = new Position();
  private validationController: ValidationController;

  private positionDiscQuestionaries: Questionary[] = [];
  private educationalLevelCountries: EducationalLevelCountry[] = [];
  // THT Company
  private positionReference: number = 0;
  private params: any = {};

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private router: Router,    
    private notification: Notification,
    private educationalLevelCountryService: EducationalLevelCountryService,
    private validationControllerFactory: ValidationControllerFactory,
    private positionService: PositionService,
    private companyInfoService: CompanyInfoService,
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PositionEdit");

    this.state = this.store.state;
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.test.pages.position.position_edit.name").required()
      .ensure("positionActivationType").displayName("main.test.pages.position.position_edit.activation_type.title").required()     
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {
    this.model.companyId = this.positionReference;
    this.model.positionActivationType = PositionActivationType.Reference
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      await this.bind();
    }
    else {
      this.params = params;
    }

    if (!isNaN(params.companyId)) {
      try {
        this.companyId = +params.companyId;
        this.positionService.client.currentCompanyId = this.companyId;       

        if (!isNaN(params.positionId)) {
          this.positionId = params.positionId;
          this.model = await this.positionService.getById(this.positionId);
        }
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }

  }

  public async bind() {
    // this.logger.debug("bind");
    if (!isNaN(this.params.companyId)) {
      try {
        this.isLoaded = true;
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }


  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.positionId) {
            return await this.update(keepEditing);
          } else {
            return await this.create(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.positionService.client.isRequesting;
  }

  private async create(keepEditing: boolean): Promise<any> {
    
    return await this.positionService.create(this.model)
      .then(async (result: Position) => {
        this.notification.success("notifications.item_edited");
        if (keepEditing) {        
            return await this.router.parent.navigate(`position-reference/${result.id}/disc`);          
        }
        else {
          return await this.router.navigateToRoute("positions-reference");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async update(keepEditing: boolean): Promise<any> {    
    return await this.positionService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("positions-reference");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  public notificationInfo(message:string){
    this.notification.info(message);
  }
}
