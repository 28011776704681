import { autoinject, valueConverter } from 'aurelia-framework';
import { DialogController } from "aurelia-dialog";
import { State } from 'common/store/store-model';
import { Company } from 'models/companies/company';
import { idText } from 'typescript';
@autoinject()
export class ConvertUserConfirmation {
  private companyId: number;
  private model: Company[];

  constructor(private dialogController: DialogController) {
  }

  public activate(model: Company[]) {
    this.model = model;    
    this.companyId = this.model[0].id   
  } 
  private async save(): Promise<any> {

    return await this.dialogController.ok(this.companyId);

  }
}
