import {autoinject, bindable, bindingMode, observable, TaskQueue} from 'aurelia-framework';
import { News } from 'models/test-core/news/news';
import $ from "jquery";
@autoinject()
export class CarouselNewsCustomElement {
  // @bindable({ defaultBindingMode: bindingMode.twoWay }) value = [];
  @bindable newsList: News[] = []; 

  constructor() {   
  } 
  public attached() {   
   
  }
}
