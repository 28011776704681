import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { TeamEvaluator } from "models/team/teamEvaluator";


@autoinject()
export class TeamEvaluatorService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("TeamEvaluatorService");
  }

  public async list(parameters: UxDatatableParameters, activationId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`teamevaluator/list/${activationId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(teamId: number): Promise<TeamEvaluator[]> {
    return await this.client.get(`teamevaluator/get-all/${teamId}`)
      .then((response: any) => {
        return <TeamEvaluator[]>response;
      });
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`teamevaluator`, id)
      .then(() => null);
  }


}
