import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { PositionEvaluator } from "../../../../models/test-position/positionEvaluator";
import { BulkActivation } from "models/test-person/bulkActivation";

@autoinject()
export class PositionEvaluatorService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("PositionEvaluatorService");
  }

  public async list(parameters: UxDatatableParameters, positionId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`positionevaluator/list/${positionId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(positionId: number): Promise<PositionEvaluator[]> {
    return await this.client.get(`positionevaluator/get-all/${positionId}`)
      .then((response: any) => {
        return <PositionEvaluator[]>response;
      });
  }

  public async getById(id: number): Promise<PositionEvaluator> {
    return await this.client.getById(`positionevaluator`, id)
      .then((response: PositionEvaluator) => {
        return response;
      });
  }

  public async create(model: PositionEvaluator): Promise<PositionEvaluator> {
    return await this.client.create(`positionevaluator/${model.positionId}`, model)
      .then((response: any) => {
        return <PositionEvaluator>response;
      });
  }

  public async update(model: PositionEvaluator): Promise<void> {
    return await this.client.updateById(`positionevaluator`, model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`positionevaluator`, id)
      .then(() => null);
  }

  public async getWeightAllEvaluators(positionId: number): Promise<number> {
    return await this.client.get(`positionevaluator/get-weight-all-evaluators/${positionId}`)
      .then((response: any) => {
        return <number>response;
      });
  }

  public async createBulkActivation(model: BulkActivation): Promise<BulkActivation> {

    let formData = new FormData();

    let filedata = null;
    if (model.filedata) {
      filedata = model.filedata[0];
    }

    formData.append('filedata', filedata);
    formData.append('filename', model.fileName);
    formData.append('name', model.name);
    return await this.client.requestFormData("PUT", `positionevaluator/bulk-activation/${model.positionId}`, formData)
      .then((response: any) => {
        return <BulkActivation>response;
      });
  }

}
