import { OrganizationService } from 'main/test/services/organization/organization-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { OrganizationEvaluatorService } from "main/test/services/organization/organization-evaluator-service";
import { Organization } from "models/test-organization/organization";
import { OrganizationEvaluator } from 'models/test-organization/organizationEvaluator';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { OrganizationEvaluatorEditModal } from './organization-evaluator-edit-modal';
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { I18N } from 'aurelia-i18n';
import { DeactivateConfirmation } from 'main/components/deactivate-confirmation';
import { OrganizationActivationType } from 'models/test-organization/organizationActivationType';
import { BulkActivation } from 'models/test-person/bulkActivation';
import { OrganizationEvaluatorBulkActivationModal } from './organization-evaluator-bulk-activation-modal';
import { Person } from 'models/test-person/person';
import { PersonLoginInfoModal } from '../people/person-login-info-modal';
import { PersonService } from 'main/test/services/people/person-service';
import config from 'config.js';

@autoinject()
export class OrganizationEvaluatorList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;

  private organizationId: number;
  private model: Organization = new Organization();
  private total: number = 0;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private csvExampleUrl: string = "";
  private xlsxExampleUrl: string = "";

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    try {
      let response: UxDatatableResponse = await this.organizationEvaluatorService.list(this.parameters, this.organizationId);
      this.total = await this.organizationEvaluatorService.getWeightAllEvaluators(this.organizationId);
      return response;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  constructor(
    private i18n: I18N,
    private organizationService: OrganizationService,
    private organizationEvaluatorService: OrganizationEvaluatorService,
    private personService: PersonService,
    private notification: Notification,
    private dialogService: DialogService
  ) {

    this.logger = LogManager.getLogger("OrganizationEvaluatorList");

    let backendUrl = config.URLS_BACKEND;
    this.csvExampleUrl = backendUrl + "/person-content/bulk-evaluator-example.csv";
    this.xlsxExampleUrl = backendUrl + "/person-content/bulk-evaluator-example.xlsx";
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      if (!isNaN(this.params.organizationId)) {
        this.organizationId = this.params.organizationId;

        this.organizationService.client.currentCompanyId = this.companyId;
        this.organizationEvaluatorService.client.currentCompanyId = this.companyId;

        this.model = await this.organizationService.getById(this.organizationId);

        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then(async (response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }


  private async create(): Promise<any> {
    let item = new OrganizationEvaluator();
    item.organizationId = this.model.id;

    return await this.dialogService.open({
      viewModel: OrganizationEvaluatorEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.organizationEvaluatorService.create(item)
          .then(async () => {

            this.notification.success("notifications.item_added");

            await this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }


  private async update(item: OrganizationEvaluator): Promise<any> {

    return await this.dialogService.open({
      viewModel: OrganizationEvaluatorEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.organizationEvaluatorService.update(item)
          .then(async () => {

            this.notification.success("notifications.item_added");

            await this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async delete(item: OrganizationEvaluator): Promise<void> {
    
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.evaluator.fullName
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.organizationEvaluatorService.delete(item.id)
          .then(async () => {

            this.notification.success("notifications.item_deleted");

            await this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });

      }
    });
  }

  private async createBulkActivation(): Promise<any> {
    let item = new BulkActivation();
    item.organizationId = this.model.id;
    item.name = this.model.name;

    return await this.dialogService.open({
      viewModel: OrganizationEvaluatorBulkActivationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.organizationEvaluatorService.createBulkActivation(item)
          .then(async () => {

            this.notification.success("notifications.item_added");

            await this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async sendIndividualReminder(id: number): Promise<boolean> {
    try {
      await this.personService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async viewLoginInfo(personId: number): Promise<any> {
    return await this.dialogService.open({
      viewModel: PersonLoginInfoModal,
      model: personId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.sendIndividualReminder(personId);
      }
    });
  }


  private async canDeactivate(): Promise<boolean> {

    if (this.total === 100 || this.model.organizationActivationType !== OrganizationActivationType.Concerted) {
      return true;
    }

    return await this.dialogService.open({
      viewModel: DeactivateConfirmation,
      model: this.i18n.tr("main.test.pages.organizations.organization_evaluator_list.can_deactivate")
    }).whenClosed(async (response: DialogCloseResult) => {
      return !response.wasCancelled;
    });
  }

}
