import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { TeamService } from "main/test/services/team/team-service";
import { Team } from "models/team/team";

@autoinject()
export class TeamEdit {

  private logger: Logger;
  public companyId: number;

  private teamId: number;

  private model: Team = new Team();
  private validationController: ValidationController;


  constructor(
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private teamService: TeamService
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("TeamEdit");
    this.model.tags = "";
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.test.pages.position.position_edit.name").required()
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {
    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      this.teamService.client.currentCompanyId = this.companyId;

      if (!isNaN(params.teamId)) {
        this.teamId = params.teamId;
        this.model = await this.getItem();
        this.configureValidationRules();
      } else {
        this.configureValidationRules();
      }
    }
  }

  private async getItem(): Promise<Team> {
    return await this.teamService.getById(this.teamId)
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.teamId) {
            return await this.update(keepEditing);
          } else {
            return await this.create(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.teamService.client.isRequesting;
  }

  private async create(keepEditing: boolean): Promise<any> {
    return await this.teamService.create(this.model)
      .then(async (result: Team) => {
        this.notification.success("notifications.item_edited");
        if (keepEditing) {
          return await this.router.parent.navigate(`team/${result.id}/team-person`);
        }
        else {
          return await this.router.navigateToRoute("team-list");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async update(keepEditing: boolean): Promise<any> {
    return await this.teamService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (keepEditing) {
          return await this.router.navigateToRoute("team-child-router", { teamId: this.teamId });
        }
        else {
          return await this.router.navigateToRoute("team-list");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  public notificationInfo(message:string){
    this.notification.info(message);
  }
}
