import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Comment } from "models/test-person/comment";
import { DialogController } from "aurelia-dialog";

@autoinject()
export class PeopleCommentEditModal {

  private logger: Logger;
  public companyId: number;

  private model: Comment = new Comment();
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PeopleCommentEditModal");
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("commentText").displayName("main.test.pages.people.people_comment_edit.comment_text").required()
      .on(this.model);
  }

  public async activate(model: Comment): Promise<any> {
    this.model = model;
    this.configureValidationRules();
  }

  public async submit(): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {

          return await this.save();

        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async save(): Promise<any> {

    return await this.dialogController.ok();
  }

}
