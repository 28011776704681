import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable-pagination";
import { Notification } from "common/services/notification";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { TagManagmentService } from "main/test/services/tags/tags-managment-service";
import { Tag } from "models/tag/tag";
import { TagManagment } from "models/tag/tag-managment";
import { TagsModal } from "./tags-modal";

@autoinject()
export class TagsList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;
  public modal: TagManagment = {
    tagId:0,
    tagName:'',
    tagNewName:'',
    tagCount: 0,
    personCount: 0,
    positionCount: 0,
    teamCount: 0,
    organizationCount: 0,
    bulkActivationCount: 0,
    bulkResultCount: 0,
    bulkDownloadCount: 0,
    isUpdate: true
  };

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private router: Router,
    private tagManagmentService: TagManagmentService,
    private notification: Notification,
    private dialogService: DialogService,
  ) {

    this.logger = LogManager.getLogger("TagsList");
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('tagsListSearch', search == null ? '' : search);

    return await this.tagManagmentService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.parameters.search.Query = sessionStorage.getItem('tagsListSearch');
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.logger.debug("activate companyId:", this.params.companyId);
      this.companyId = +this.params.companyId;
      this.tagManagmentService.client.currentCompanyId = this.companyId;
      return await this.databind();
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async clearFilters() {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
    return await this.databind();
  }

  public async editOrDelete(item: TagManagment,isUpdate: boolean): Promise<void> {
    let tagInfo = await this.tagManagmentService.getByTagName(item);
    let oldTagName = item.tagName;
    tagInfo.isUpdate = isUpdate;
    return await this.dialogService.open({
      viewModel: TagsModal,
      model: tagInfo
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled && oldTagName!=tagInfo.tagName) {
        this.modal = tagInfo;
        this.modal.tagNewName = tagInfo.tagName;
        this.modal.tagName = oldTagName;
        return await this.tagManagmentService.updateOrDelete(this.modal)
          .then(async () => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(({tagId}) => tagId === this.modal.tagId);
            this.parameters.tableData[indexOfEditedModel] = this.modal;
            this.notification.warning("main.admin.pages.tags.notifications.edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else if(!response.wasCancelled) {
        this.modal = tagInfo;
        return await this.tagManagmentService.updateOrDelete(this.modal)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(({tagId}) => tagId === this.modal.tagId);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.warning("main.admin.pages.tags.notifications.deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }
}
