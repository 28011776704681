import { autoinject, LogManager, bindable, Disposable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { CoreCompanyService } from "../../services/companies/core-company-service";
import { Company } from "models/companies/company";
import { Role } from "models/security/role";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { EventAggregator } from "aurelia-event-aggregator";
import { CompaniesLoadedMessage } from "messages/companiesLoadedMessage";

@autoinject()
export class FranchiseDropdownSelector {

  // @bindable
  // private baseUrl: string;

  @bindable
  private routeName: string;

  @bindable
  private isRobotHunter: boolean;

  private logger: Logger;
  private companies: Company[] = [];
  private masterFranchises: Company[] = [];
  private franchises: Company[] = [];

  private state: State;

  private subscriptions: Disposable[] = [];

  constructor(
    private store: StoreService,
    private ea: EventAggregator,
    private coreCompanyService: CoreCompanyService,
    private router: Router) {

    this.logger = LogManager.getLogger("FranchiseDropDownSelector");

    this.state = this.store.state;

    this.subscriptions.push(this.ea.subscribe(CompaniesLoadedMessage, this.databind.bind(this)));
  }

  public async attached(): Promise<void> {
    this.logger.debug("attached");
    return await this.databind();

  }

  public async databind(): Promise<void> {
    try {
      // this.companies = await this.coreCompanyService.getAll();

      this.companies = this.state.companies;

      this.franchises = this.companies.filter(x => x.roleId === Role.Franchise);

      if (this.franchises.length === 1) {
        this.select(this.franchises[0].id);
      }
      else if (this.franchises.length === 0) {
        const companyGroups = this.companies.filter(x => x.roleId === Role.Master);
        if (companyGroups.length > 0) {
          const franchiseId = companyGroups[0].parentId;

          return this.select(franchiseId);
        }
      }

      this.masterFranchises = this.companies.filter(x => x.roleId === Role.MasterFranchise);

    }
    catch (error) {
      this.logger.debug("error");
    }

  }

  public async select(franchiseId: number): Promise<any> {
    // this.logger.debug("navigating to franchiseId:", franchiseId);    
    return await this.router.navigateToRoute(this.routeName, { franchiseId: franchiseId , isRobotHunter: this.isRobotHunter });
  }

}
