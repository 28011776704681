import { autoinject, LogManager, bindable, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Report } from "models/test-reports/reports/report";
import { CompanyDiscProfileService } from 'main/admin/services/test-management/company-disc-profile-service';
import { CompanyDiscProfile } from 'models/test-management/disc-profiles/company-disc-profile';
import { Disc } from "models/questionaries/disc";

@autoinject()
export class SelectAdjustmentList {

  private logger: Logger;
  private companyDisc: CompanyDiscProfile = new CompanyDiscProfile();

  @bindable
  @observable
  private report: Report;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private companyDiscProfileService: CompanyDiscProfileService,
  ) {
    this.logger = LogManager.getLogger("SelectAdjustmentList");
    this.parameters.pageSize = 50;
  }

  private async bind(): Promise<any> {

    this.companyDisc = await this.companyDiscProfileService.get()
    if(this.report){

      this.report.adjustments[0].value = null;
      this.report.adjustments[1].value = null;
      this.report.adjustments[2].value = null;
      this.report.adjustments[3].value = null;

      this.report.adjustments[0].operator = null;
      this.report.adjustments[1].operator = null;
      this.report.adjustments[2].operator = null;
      this.report.adjustments[3].operator = null;

      this.report.adjustments[0].disc = Disc.D;
      this.report.adjustments[1].disc = Disc.I;
      this.report.adjustments[2].disc = Disc.S;
      this.report.adjustments[3].disc = Disc.C;

    }
    

  }

}
