import { Question } from 'models/questions/question';
import { ResponseQuestion } from "./responseQuestion";

export class ResponseQuestionary {
  id: number;
  activationQuestionaryId: number;
  currentQuestionId: number;
  currentQuestion: Question;
  questionaryName: string;
  averageTime: number;
  questionsAnswered: number;
  questionsToAnswer: number;
  responseQuestions: ResponseQuestion[];

  personId: number;
  activationId: number;
}
