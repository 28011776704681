import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { BulkActivationService } from "../../services/people/bulk-activation-service";
import { DialogService, DialogCloseResult } from 'aurelia-dialog';

@autoinject()
export class BulkActivationList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('bulkActivationListSearch', search == null ? '' : search);

    return await this.bulkActivationService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private bulkActivationService: BulkActivationService,
    private notification: Notification,
    private dialogService: DialogService) {

    this.logger = LogManager.getLogger("BulkActivationList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.parameters.search.Query = sessionStorage.getItem('bulkActivationListSearch');
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      this.bulkActivationService.client.currentCompanyId = this.companyId;

      return await this.databind();

    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async sendReminder(id: number): Promise<boolean> {
    try {
      await this.bulkActivationService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private clearFilters() {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
  }

  private async getPrivateMedia(id: number) {
    try {
      let privateUrl = await this.bulkActivationService.getMediaByBulkActivationId(id);
      if (privateUrl != null) {
        return this.router.navigate(privateUrl);
      }
    } catch (error){
      this.notification.error(error);
    }
  }

}
