// export class Genre{
//     items = [
//         {"id":1,"name": "main.admin.pages.scales.genre_enum.male", "code": "male"},
//         {"id":2,"name": "main.admin.pages.scales.genre_enum.female", "code": "female"}
//     ];

//     getNameById(id: number): string{
//         return this.items.find(x => x.id == id)['name'];
//     }

//     getIdByCode(code: string): number{
//         return this.items.find(x => x.code == code)['id'];
//     }
// }
export enum Genre {
  Male,
  Female,
  Undefined
}
