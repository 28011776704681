
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class ThematicGroupQuestionaryListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("ThematicGroupQuestionaryListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./thematic-group-questionaries"),
        name: "select-questionary",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.thematic_groups.questionary_select_title"),
      },

      {
        route: "questionary/:questionaryId",
        moduleId: PLATFORM.moduleName("./thematic-group-list-child-router"),
        name: "thematic-group-list-child-router",
        nav: false,
        title: this.i18n.tr("main.admin.pages.thematic_groups.title"),
      },
      
    ]);
  }
}
