import { Company } from './company';

export class CompanySettings {
  companyId: number;
  hasUserManagement: boolean;
  hasGroupManagement: boolean;
  hasProfileManagement: boolean;
  hasPreferenceManagement: boolean;
  hasNotificationManagement: boolean;
  hasTalentManagement: boolean;
  isResultsExport: boolean;
  hasPredictor: boolean;
  competencesQuestionaryId: number;
  hasSuccessFactorsConnection: boolean;
  successFactorsTokenURl: string;
  successFactorsClientId: string;
  successFactorsClientSecret: string;
  successFactorsEndPoint: string;

  // company: Company;
}
