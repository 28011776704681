import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { BulkActivation } from "models/test-person/bulkActivation";
import * as moment from "moment";

import * as objectToFormData from 'object-to-formdata';

@autoinject()
export class BulkActivationService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient,
  ) {

    this.logger = LogManager.getLogger("BulkActivationService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("bulkactivation/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }



  public async listItemsById(id: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`bulkactivation/list-items/${id}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<BulkActivation> {
    return await this.client.getById(`bulkactivation`, id)
      .then((response: BulkActivation) => {
        return response;
      });
  }

  // public async getAll(): Promise<BulkActivation[]> {
  //   return await this.client.get("bulkactivation")
  //     .then((response: any) => {
  //       return <BulkActivation[]>response;
  //     });
  // }

  public async sendReminder(id: number): Promise<boolean> {
    return await this.client.post("bulkactivation/send-reminder/" + id)
      .then((response: any) => {
        return true
      });
  }

  public async createLogFile(id: number): Promise<boolean> {
    return await this.client.post("bulkactivation/create-logfile/" + id)
      .then((response: any) => {
        return true
      });
  }

  public async exportItemsById(id: number, name: string): Promise<any> {
    let filename: string = name + "_" + moment().format() + ".csv";
    return await this.client.download("GET", `bulkactivation/export-items/${id}`, null, filename, "text/csv", null)
      .then(() => null);
  }

  public async create(model: BulkActivation): Promise<BulkActivation> {

    // let formData = new FormData();

    // let filedata = null;
    // if (model.filedata) {
    //   filedata = model.filedata[0];
    // }

    // // this.logger.debug("filedata", filedata);

    // formData.append('filedata', filedata);
    // formData.append('filename', model.fileName);
    // formData.append('name', model.name);
    // formData.append('personMode', model.personMode.toString());
    // formData.append('tags', (model.tags || ""));
    // formData.append('hasMicroSite', model.hasMicroSite || false as any);
    // formData.append('isConfidentialTest', model.isConfidentialTest || false as any);
    // formData.append('questionaryWay', model.questionaryWay.toString());
    // formData.append('whoCanSeeReport', model.whoCanSeeReport.toString());
    // // this.logger.debug("date", model.invitationExpireDate.toISOString());
    // if (model.invitationExpireDate) {
    //   formData.append('invitationExpireDate', model.invitationExpireDate ? model.invitationExpireDate.toISOString() : "");
    // }
    // formData.append('languageId', model.languageId.toString());
    // formData.append('additionalText', model.additionalText || "");
    // formData.append('reportTemplateId', model.reportTemplateId ? model.reportTemplateId.toString() : "");
    // formData.append('questionaryGroupId', model.questionaryGroupId ? model.questionaryGroupId.toString() : "");
    // formData.append('companyCompetenceIds', model.companyCompetenceIds.length > 0 ? JSON.stringify(model.companyCompetenceIds) : "");
    // // competences

    if (model.filedata) {
      model.filedata = model.filedata[0];
    }

    let formData = objectToFormData(model);

    if(model.questionaryIds){
      model.questionaryIds.forEach((questionary, index) => {
        formData.append(`questionaryIds[${index}].languageId`, questionary.languageId.toString());
        formData.append(`questionaryIds[${index}].questionaryId`, questionary.questionaryId.toString());
        formData.append(`questionaryIds[${index}].stringId`, questionary.stringId.toString());
     });
    }

    if (model.engineersTests) {
      model.engineersTests.forEach((test, index) => {
        formData.append(`engineersTests[${index}].difficulty`, test.difficulty.toString());
        formData.append(`engineersTests[${index}].questionaryId`, test.questionaryId.toString());
      });
    }
    
    return await this.client.requestFormData("PUT", `bulkactivation`, formData)
      .then((response: any) => {
        return <BulkActivation>response;
      });

  }

  public async getMediaByBulkActivationId(id: number): Promise<string> {
    return await this.client.get(`bulkActivation/get-media-by-bulkActivationId/${id}`)
      .then((response: any) => {
        return <string>response;
      });
  }

}
