import { inject } from 'aurelia-dependency-injection';
import CryptoJS from 'crypto-js';
import { Notification } from "common/services/notification";


@inject()
export class CryptoService {

  constructor(
    private notification: Notification,){
  }
  key = "ssadwawdthsdfgndfg3453453df";

  asignarKey(key): Promise<void> {
    return new Promise<void>((resolve) => {
      this.key = key;
      resolve();
    });
  }  

  async encode(data: string, key: string) {
    try{
      const strData = JSON.stringify(data);
      return CryptoJS.AES.encrypt(strData, key).toString();
    }
    catch(error){
      this.notification.error(error.message);
    }
  }

  decode(data) {
    try {
      var bytes = CryptoJS.AES.decrypt(data, this.key);
      var str = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(str);
    } catch(error) {
      this.notification.error(error.message);
    }
  }

  encodeSHA256(data) {
    const strData = JSON.stringify(data);
    return CryptoJS.SHA256(strData).toString();
  }

}
