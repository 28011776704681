import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { DialogController } from "aurelia-dialog";
import { BulkActivation } from '../../../../models/test-person/bulkActivation';
import { FileAcceptValidator } from "common/utils/file-accept-validator";
import { Language } from 'models/localizations/language';
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';
import config from 'config.js';

@autoinject()
export class OrganizationEvaluatorBulkActivationModal {

  private logger: Logger;

  public companyId: number;

  private state: State;

  private model: BulkActivation = new BulkActivation();

  private validationController: ValidationController;

  private csvExampleUrl: string = "";
  private xlsxExampleUrl: string = "";

  private languages: Language[];

  constructor(
    private dialogController: DialogController,
    private store: StoreService,
    private languageService: CoreLanguageService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory
    ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("OrganizationEvaluatorBulkActivationModal");

    this.state = this.store.state;

    let backendUrl = config.URLS_BACKEND;
    this.csvExampleUrl = backendUrl + "/person-content/bulk-evaluator-example.csv";
    this.xlsxExampleUrl = backendUrl + "/person-content/bulk-evaluator-example.xlsx";

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("fileName").displayName("main.testperson.pages.masive_activation.field_file").required()
      .on(this.model);
  }

  public async activate(model: BulkActivation): Promise<any> {
    this.logger.debug("activate");

    this.model = model;

    this.configureValidationRules();

  }

  private async bind(): Promise<any> {
    // this.logger.debug("bind");
    try {
      this.languages = await this.languageService.getAll();
      this.setUserLanguage();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find(lg => lg.culture === activeCulture);
      if (activeLang) {
        this.model.languageId = activeLang.id;
      }
    }
  }

  public async submit(): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if(!this.model.filedata){
            this.notification.warning("main.test.pages.people.errors.file_is_missing");
          } else {
            return await this.save();
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async save(): Promise<any> {
    return await this.dialogController.ok();
  }

  public setFile($event: any): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];

    let fileValidator = FileAcceptValidator.parse("csv, xls, xlsx", 1024 * 500); // 500kb size
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.model.fileName = file.name;
      };
    }
    else {
      this.notification.error("main.test.pages.organization.organization_evaluator_list.file_upload.errors.error_bad_file");

    }
  }
}
