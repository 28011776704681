// export class QuestionUse{
//     items = [
//         {"id":1,"name":"main.admin.pages.questionaries.questions.question_use.example", "code":"example"},
//         {"id":2,"name":"main.admin.pages.questionaries.questions.question_use.evaluation", "code":"evaluation"},
//         {"id":3,"name":"main.admin.pages.questionaries.questions.question_use.instruction", "code":"instruction"},
//         {"id":4,"name":"main.admin.pages.questionaries.questions.question_use.warning", "code": "warning"},
//         {"id":5,"name":"main.admin.pages.questionaries.questions.question_use.farewell", "code": "farewell"}
//     ];

//     getNameById(id: number): string{
//         return this.items.find(x => x.id == id)['code'];
//     }

//     getList(questionTypeId: number): Array<any>{
//         if(questionTypeId==1 || questionTypeId==2 ||
//             questionTypeId==3 || questionTypeId==4 ||
//             questionTypeId==5 ){
//                 return this.items.filter(x => x.code == "example" || x.code == "evaluation");
//             }

//         if(questionTypeId==6){
//                 return this.items.filter(x => x.code == "instruction" || x.code == "warning" || x.code == "farewell");
//         }

//         if(questionTypeId==7){
//             return this.items.filter(x => x.code == "evaluation");
//         }

//     }
// }

export enum QuestionUse {
  Instruction,
  Example,
  Warning,
  Evaluation,
  Farewell
}
