import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { ResponseQuestionary } from "models/microsite/responseQuestionary";
import { ResponseEvaluation } from "models/videointerview/responseEvaluation";
import { Result } from "models/test-result/result";
import { RequestQualificatoniVideoInterview } from "models/test-person/requestQualificationVideoInterview";

@autoinject()
export class ResponseQuestionaryVideoInterviewService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("PersonTagService");
  }

  public async getByActivationQuestionaryId(activationQuestionaryId: number): Promise<ResponseQuestionary> {
    return await this.client.get(`responsequestionaryvideointerview/get-activation-questionaryId/${activationQuestionaryId}`)
      .then((response: any) => {
        return <ResponseQuestionary>response;
      });
  }

  public async getResponsesToRateByQuestionaryId(responseQuestionary: ResponseQuestionary): Promise<ResponseEvaluation> {
    return await this.client.post("responsequestionaryvideointerview/get-responses-toRate-questionaryId", responseQuestionary)
      .then((response: any) => {
        return <ResponseEvaluation>response;
      });
  }

  public async save(result: Result): Promise<Result> {
    return await this.client.post("responsequestionaryvideointerview/save", result)
      .then((response: any) => {
        return <Result>response;
      });
  }

  public async requestQualificationVideoInterview(data: RequestQualificatoniVideoInterview): Promise<boolean> {
    return await this.client.post("responsequestionaryvideointerview/send-request-qualification-video-interview/", data)
      .then((response: any) => {
        return true;
      });
  }

}
