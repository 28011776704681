import { Router, RouterConfiguration } from "aurelia-router";
import { PLATFORM } from "aurelia-pal";

import PredefinedPermissions from "config/predefined-permissions";
import { Logger } from "aurelia-logging";
import { I18N } from "aurelia-i18n";
import { LogManager, autoinject } from "aurelia-framework";

@autoinject
export class LanguageChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("LanguageChildRouter");
  }


  configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      // {
      //   route: "create",
      //   moduleId: PLATFORM.moduleName("./language-edit"),
      //   name: "language-create",
      //   nav: false,
      //   title: this.i18n.tr("main.admin.pages.languages.title"),
      //   settings: {
      //     permission: {
      //       only: [PredefinedPermissions.LanguageUpdate]
      //     }
      //   }
      // },

      {
        route: "",
        moduleId: PLATFORM.moduleName("./language-edit"),
        name: "language-edit",
        nav: true,
        title: this.i18n.tr("main.admin.pages.languages.language"),
        settings: {
          permission: {
            only: [PredefinedPermissions.LanguageUpdate]
          }
        }
      },
      {
        route: "localizations",
        moduleId: PLATFORM.moduleName("./localizations/localization-list"),
        name: "localizations",
        nav: true,
        title: this.i18n.tr("main.admin.pages.localizations.title"),
        settings: {
          permission: {
            only: [PredefinedPermissions.LocalizationUpdate]
          }
        }
      }
    ]);

    this.router = router;
  }
}
