import { Notification } from 'common/services/notification';

import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';

import { User } from "oidc-client";
import { SessionService } from 'common/services/session-service';

import { Router, RouteConfig } from 'aurelia-router';
import { OpenIdProfile } from 'models/security/open-id-profile';
import { PermissionStore } from 'aurelia-permission';
import { StoreService } from 'common/store/store-service';
import { State } from 'common/store/store-model';

@autoinject()
export class Home {

  private logger: Logger;
  private user: User;

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private sessionService: SessionService,
    private permissionStore: PermissionStore,
    private notification: Notification,
    private window: Window) {

    this.logger = LogManager.getLogger('Home');

    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");
    if(params){
      this.window.history.pushState({}, document.title, "/");
    }
  }
}
