import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { DialogService } from 'aurelia-dialog';
import { Router, RouteConfig } from 'aurelia-router';
import { Notification } from "common/services/notification";
import { BulkTalentsExportService } from "main/admin/services/talents/bulk-talents-export-service";
@autoinject
export class BulkTalentsExportList {
  private logger: Logger;
  private params: any = {};

  public companyId: number;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.bulkTalentsExportService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  constructor(
    private router: Router,
    private bulkTalentsExportService: BulkTalentsExportService,
    private notification: Notification,
    private dialogService: DialogService) {

    this.logger = LogManager.getLogger("BulkTalentsExportList");
  }
  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
  }
  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      this.bulkTalentsExportService.client.currentCompanyId = this.companyId;

      return await this.databind();

    }
  }
  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async getPrivateMedia(id: number) {
    try {
      let privateUrl = await this.bulkTalentsExportService.getMediaByBulkActivationId(id);
      if (privateUrl != null) {
        return this.router.navigate(privateUrl);
      }
    } catch (error){
      this.notification.error(error);
    }
  }
}
