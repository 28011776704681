import { customAttribute, inject, bindable } from 'aurelia-framework';
import * as $ from 'jquery';

@customAttribute('ux-bootstrap-tooltip')
@inject(Element)
export class BootstrapTooltipCustomAttribute {
  $element: any;
  @bindable title: string = "";

  constructor(element) {
    this.$element = $(element);
  }

  bind() {
    this.$element.tooltip({ title: this.title || "" });
  }

  unbind() {
    this.$element.tooltip('dispose');
  }

  titleChanged() {
    // this.$element.data('bs.tooltip', false);
    this.$element.tooltip({ title: this.title || "" });
  }
}
