import { I18N } from 'aurelia-i18n';
import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";

import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { State } from 'common/store/store-model';
import { Company } from 'models/companies/company';
import { CompanyCompetence } from 'models/test-core/competences/company-competence';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { CompanyCompetenceService } from 'main/admin/services/test-management/company-competence-service';
import { DichotomousCoupleListModal } from '../../test-core/company-competences/dichotomous-couple-list-modal';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
import { QuestionaryService } from 'main/admin/services/questionaries/questionary-service';
import { Questionary } from 'models/questionaries/questionary';
import { Tests } from 'models/test-person/tests';


@autoinject()
export class CompanyCompetenceList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;
  private questionaryId: number;
  private competenceQuestionary: Questionary;
  constructor(
    private i18n: I18N,
    private dialogService: DialogService,
    private store: StoreService,
    private companyCompetenceService: CompanyCompetenceService,
    private notification: Notification,
    private coreCompanyService: CoreCompanyService,
    private questionaryService: QuestionaryService) {

    this.logger = LogManager.getLogger("CompanyCompetenceList");

    this.state = this.store.state;

  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.companyCompetenceService.list(this.parameters, this.questionaryId)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any): Promise<void> {
    this.logger.debug("activate");
    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }


  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.companyCompetenceService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);        
        let settings = await this.coreCompanyService.getSettingsById(this.companyId);
        this.questionaryId = settings.competencesQuestionaryId;

        if (this.questionaryId == 0)
          this.questionaryId = Tests['Competences'];

        this.competenceQuestionary = await this.questionaryService.getById(this.questionaryId);

        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async save(item: CompanyCompetence): Promise<void> {
    return await this.companyCompetenceService.update(item)
      .then(() => {
        this.notification.success("main.admin.pages.test_management.company_competences.notifications.edited");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  public async view(item: CompanyCompetence): Promise<void> {
    this.logger.debug("viewDichotomousCouples");
    return await this.dialogService.open({
      viewModel: DichotomousCoupleListModal,
      model: item
    }).whenClosed(() => null);
  }

}
