import { Company } from 'models/companies/company';
import { CoreCompanyService } from '../../services/companies/core-company-service';

import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";

import { ContactService } from "../../services/contacts/contact-service";
import { Contact } from "models/contacts/contact";
import { ContactModal } from "./contact-modal";

import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';

@autoinject()
export class ContactList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private state: State;
  private isLoaded: boolean = false;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private store: StoreService,
    private coreCompanyService: CoreCompanyService,
    private contactService: ContactService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("ContactList");

    this.state = this.store.state;

  }

  private async activate(params: any): Promise<void> {
    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyGroupId)) {
      this.companyId = +this.params.companyGroupId;
    }

    if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
      this.companyId = null;
    }

    if (!isNaN(this.params.companyDetailId)) {
      this.companyId = +this.params.companyDetailId;
    }

    if (this.companyId) {
      this.company = this.state.companies.find(x => x.id == this.companyId);
      this.isLoaded = true;
      return await this.databind();
    }
  }

  // public async attached(): Promise<void> {
  //   this.logger.info("attached ");
  //   this.logger.debug("attached companyId:", this.companyId);
  //   if (this.companyId) {
  //     return await this.coreCompanyService.getById(this.companyId)
  //       .then((company: Company) => {
  //         this.company = company;
  //         return this.databind();
  //       })
  //       .catch(error => {
  //         this.notification.error(error);
  //       });
  //   }
  // }

  public async databind(): Promise<void> {
    // this.logger.info("databind");
    return await this.contactService.list(this.companyId, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    return this.contactService.list(this.companyId, this.parameters);
  }


  private async create(): Promise<void> {
    let item: Contact = new Contact();
    item.companyId = this.companyId;
    return await this.dialogService.open({
      viewModel: ContactModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        try {
          let contact: Contact = await this.contactService.create(item);
          item.id = contact.id;
          
          this.parameters.tableData.unshift(item);
          this.parameters.totalRecords++;

          this.notification.success("notifications.item_added");
          // return await this.databind();
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    });
  }

  private async edit(item: Contact): Promise<void> {
    let orginialItem = JSON.parse(JSON.stringify(item));
    return await this.dialogService.open({
      viewModel: ContactModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.contactService.update(item)
          .then(async () => {
            // var name = this.model.name;
            this.notification.success("notifications.item_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        Object.assign(item, orginialItem);
      }
    });
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed((response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.contactService.delete(id)
          .then(() => {

            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;

            this.notification.success("notifications.item_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }
}
