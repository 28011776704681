import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AppHttpClient } from "common/services/app-http-client";
import { Inbox } from "models/test-core/inbox/inbox";

@autoinject()
export class InboxService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("InboxService");
  }
  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`inbox/list`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  public async changeState(id: number): Promise<void> {
    return await this.client.post(`inbox/change-state/${id}`)
      .then(() => null);
  }

  public async create(model: Inbox): Promise<Inbox> {
    return await this.client.create("inbox", model)
      .then((response: Inbox) => {
        return response;
      });
  }

  public async getAllByCompany( companyId: Number,inbox: Inbox): Promise<Inbox[]> {
    return await this.client.post(`inbox/get-all-by-company/${companyId}`,inbox )
      .then((response: Inbox[]) => {
        return response;
      });
  }

  public async update(model: Inbox): Promise<void> {
    return await this.client.updateById("inbox", model.id, null, model)
      .then(() => null);
  }
  public async getById(id: number): Promise<Inbox> {
    return await this.client.getById("inbox", id)
      .then((response: Inbox) => {
        return response;
      });
  }

}
