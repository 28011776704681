
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";

@autoinject
export class InboxListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor() {
    this.logger = LogManager.getLogger("InboxListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./inbox-list"),
        name: "inbox-list",
      },

      {
        route: "inbox/:inboxId",
        moduleId: PLATFORM.moduleName("./inbox-child-router"),
        name: "inbox-child-router",        
      },     
    ]);
  }
}
