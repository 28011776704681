import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult } from "aurelia-dialog";
import { ThematicVariableModal } from "./thematic-variable-modal";
import { ThematicVariablesService } from "../../../services/thematic-groups/thematic-variables-service";
import { ThematicGroupService } from "../../../services/thematic-groups/thematic-group-service";
import { ThematicGroup } from "models/thematic-groups/thematic-group";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { ThematicVariable } from "models/thematic-groups/thematic-variable";
import { DeleteConfirmation } from "../../../../components/delete-confirmation";
import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class ThematicVariableList {

  private logger: Logger;

  @bindable
  private routeName: string;

  @bindable
  private thematicGroupId: number;

  @bindable
  private isAttached: boolean = false;

  private thematicGroup: ThematicGroup = new ThematicGroup();

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private dialogService: DialogService,
    private thematicVariablesService: ThematicVariablesService,
    private thematicGroupService: ThematicGroupService,
    private router: Router,
    private notification: Notification) {

    this.logger = LogManager.getLogger("ThematicVariableList");
  }

  private async activate(params: any): Promise<void> {
    if (!isNaN(params.thematicGroupId)) {
      this.thematicGroupId = +params.thematicGroupId;
    }
  }

  private async bind(): Promise<any> {
    return await this.databind();
  }

  private async databind(): Promise<any> {
    if (this.thematicGroupId) {
      try {
        this.thematicGroup = await this.thematicGroupService.getById(this.thematicGroupId);
        let response = await this.thematicVariablesService.list(this.thematicGroupId, this.parameters);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    this.logger.info("refreshing");
    return this.thematicVariablesService.list(this.thematicGroupId, this.parameters);
  }

  private async edit(item: ThematicVariable): Promise<void> {
    return await this.dialogService.open({
      viewModel: ThematicVariableModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        // this.databind();
        return await this.thematicVariablesService.update(item)
          .then(async () => {
            this.notification.success("notifications.item_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async create(): Promise<void> {
    let item: ThematicVariable = new ThematicVariable();
    item.thematicGroupId = this.thematicGroupId;

    return await this.dialogService.open({
      viewModel: ThematicVariableModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.thematicVariablesService.create(item)
          .then(async (model: ThematicVariable) => {
            item.id = model.id;
            this.parameters.tableData.unshift(item);
            this.parameters.totalRecords++;
            this.notification.success("notifications.item_added");
            // return this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async select(thematicVariableId: number): Promise<any> {
    // this.logger.debug("navigating to thematicVariableId:", thematicVariableId);
    return await this.router.navigateToRoute(this.routeName, { thematicVariableId: thematicVariableId });
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.thematicVariablesService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("notifications.item_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }
}
