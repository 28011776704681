const PredefinedPermission = {

  LanguageView: "admin_localization_language_view",
  LanguageCreate: "admin_localization_language_create",
  LanguageUpdate: "admin_localization_language_update",
  LanguageDelete: "admin_localization_language_delete",
  LanguageActivate: "admin_localization_language_activate",

  LabelView: "admin_localization_label_view",
  LabelCreate: "admin_localization_label_create",
  LabelUpdate: "admin_localization_label_update",
  LabelDelete: "admin_localization_label_delete",

  LocalizationView: "admin_localization_localization_view",
  LocalizationCreate: "admin_localization_localization_create",
  LocalizationUpdate: "admin_localization_localization_update",
  LocalizationDelete: "admin_localization_localization_delete",

  //gestion de niveles educativos
  EducationalLevelCountryView: "admin_localization_educationallevelcountry_view",
  EducationalLevelCountryUpdate: "admin_localization_educationallevelcountry_update",
  EducationalLevelCountryActivate: "admin_localization_educationallevelcountry_activate",

  QuestionaryView: "admin_questionary_questionary_view",
  QuestionaryCreate: "admin_questionary_questionary_create",
  QuestionaryUpdate: "admin_questionary_questionary_update",
  QuestionaryDelete: "admin_questionary_questionary_delete",
  QuestionaryActivate: "admin_questionary_questionary_activate",

  ScaleView: "admin_questionary_scale_view",
  ScaleCreate: "admin_questionary_scale_create",
  ScaleUpdate: "admin_questionary_scale_update",
  ScaleDelete: "admin_questionary_scale_delete",

  ReportLabelsView: "admin_testcore_reportlabels_view",
  ReportLabelsCreate: "admin_testcore_reportlabels_create",
  ReportLabelsUpdate: "admin_testcore_reportlabels_update",
  ReportLabelsDelete: "admin_testcore_reportlabels_delete",
  ReportLabelsActivate: "admin_testcore_reportlabels_activate",

  ArchetypeView: "admin_archetype_archetype_view",
  ArchetypeCreate: "admin_archetype_archetype_create",
  ArchetypeUpdate: "admin_archetype_archetype_update",
  ArchetypeDelete: "admin_archetype_archetype_delete",
  ArchetypeActivate: "admin_archetype_archetype_activate",

  TestCoreCompetencesView: "admin_testcore_competences_view",
  TestCoreCompetencesUpdate: "admin_testcore_competences_update",
  TestCoreCompetencesActivate: "admin_testcore_competences_activate",

  TestCoreNewsView: "admin_testcore_news_view",
  TestCoreNewsUpdate: "admin_testcore_news_update",
  TestCoreNewsCreate: "admin_testcore_news_create",
  TestCoreNewsActivate: "admin_testcore_news_activate",

  SheetView: "admin_testcore_sheet_view",
  SheetUpdate: "admin_testcore_sheet_update",
  SheetActivate: "admin_testcore_sheet_activate",

  FranchiseView: "admin_franchise_franchise_view",
  FranchiseCreate: "admin_franchise_franchise_create",
  FranchiseUpdate: "admin_franchise_franchise_update",
  FranchiseDelete: "admin_franchise_franchise_delete",
  FranchiseActivate: "admin_franchise_franchise_activate",

  TestCoreInboxView: "admin_testcore_inbox_view",
  TestCoreInboxUpdate: "admin_testcore_inbox_update",
  TestCoreInboxCreate: "admin_testcore_inbox_create",
  TestCoreInboxActivate: "admin_testcore_inbox_activate",
  // Franchise
  PlanView: "admin_plan_plan_view",
  PlanCreate: "admin_plan_plan_create",
  PlanUpdate: "admin_plan_plan_update",
  PlanDelete: "admin_plan_plan_delete",
  PlanActivate: "admin_plan_plan_activate",

  CoreGroupView: "admin_coregroup_coregroup_view",
  CoreGroupCreate: "admin_coregroup_coregroup_create",
  CoreGroupUpdate: "admin_coregroup_coregroup_update",
  CoreGroupDelete: "admin_coregroup_coregroup_delete",

  CoreUserView: "admin_coregroup_coreuser_view",
  CoreUserCreate: "admin_coregroup_coreuser_create",
  CoreUserUpdate: "admin_coregroup_coreuser_update",
  CoreUserDelete: "admin_coregroup_coreuser_delete",
  CoreUserActivate: "admin_coregroup_coreuser_activate",

  MessageView: "admin_message_message_view",
  MessageCreate: "admin_message_message_create",
  MessageUpdate: "admin_message_message_update",
  MessageDelete: "admin_message_message_delete",
  MessageActivate: "admin_message_message_activate",

  TestManagementCompetencesView: "admin_testmanagement_competences_view",
  TestManagementCompetencesUpdate: "admin_testmanagement_competences_update",

  CompanyGroupView: "admin_company_companygroup_view",
  CompanyGroupCreate: "admin_company_companygroup_create",
  CompanyGroupUpdate: "admin_company_companygroup_update",
  CompanyGroupDelete: "admin_company_companygroup_delete",
  CompanyGroupActivate: "admin_company_companygroup_activate",

  CompanyView: "admin_company_company_view",
  CompanyCreate: "admin_company_company_create",
  CompanyUpdate: "admin_company_company_update",
  CompanyDelete: "admin_company_company_delete",
  CompanyActivate: "admin_company_company_activate",

  // Master permissions
  CompanyMasterView: "admin_company_master_view",
  CompanyMasterUpdate: "admin_company_master_update",

  PasswordPolicyView: "admin_company_passwordpolicy_view",
  PasswordPolicyUpdate: "admin_company_passwordpolicy_update",

  GroupView: "admin_company_group_view",
  GroupCreate: "admin_company_group_create",
  GroupUpdate: "admin_company_group_update",
  GroupDelete: "admin_company_group_delete",

  MasterUserView: "admin_company_masteruser_view",
  MasterUserCreate: "admin_company_masteruser_create",
  MasterUserUpdate: "admin_company_masteruser_update",
  MasterUserDelete: "admin_company_masteruser_delete",
  MasterUserActivate: "admin_company_masteruser_activate",

  UserView: "admin_company_user_view",
  UserCreate: "admin_company_user_create",
  UserUpdate: "admin_company_user_update",
  UserDelete: "admin_company_user_delete",
  UserActivate: "admin_company_user_activate",
  UserConvert: "admin_company_user_convert",
  UserSwap: "admin_company_user_swap",

  ProfileView: "admin_company_profile_view",
  ProfileCreate: "admin_company_profile_create",
  ProfileUpdate: "admin_company_profile_update",
  ProfileDelete: "admin_company_profile_delete",

  ServiceAgreementView: "admin_serviceagreement_serviceagreement_view",
  ServiceAgreementCreate: "admin_serviceagreement_serviceagreement_create",
  ServiceAgreementUpdate: "admin_serviceagreement_serviceagreement_update",
  ServiceAgreementDelete: "admin_serviceagreement_serviceagreement_delete",
  ServiceAgreementActivate: "admin_serviceagreement_serviceagreement_activate",

  ServiceAgreementStateChange: "admin_serviceagreement_state_view",
  ServiceAgreementStateDraft: "admin_serviceagreement_state_draft",
  ServiceAgreementStateInReview: "admin_serviceagreement_state_inreview",
  ServiceAgreementStateForwarded: "admin_serviceagreement_state_forwarded",
  ServiceAgreementStateApproved: "admin_serviceagreement_state_approved",
  ServiceAgreementStateCancelled: "admin_serviceagreement_state_cancelled",
  ServiceAgreementStateValid: "admin_serviceagreement_state_valid",

  ServiceAgreementPrivateNoteView: "admin_serviceagreement_privatenote_view",
  ServiceAgreementPrivateNoteCreate: "admin_serviceagreement_privatenote_create",
  ServiceAgreementPrivateNoteUpdate: "admin_serviceagreement_privatenote_update",
  ServiceAgreementPrivateNoteDelete: "admin_serviceagreement_privatenote_delete",

  ServiceAgreementPublicNoteView: "admin_serviceagreement_publicnote_view",
  ServiceAgreementPublicNoteCreate: "admin_serviceagreement_publicnote_create",
  ServiceAgreementPublicNoteUpdate: "admin_serviceagreement_publicnote_update",
  ServiceAgreementPublicNoteDelete: "admin_serviceagreement_publicnote_delete",

  TagsManagementView: "admin_tagsmanagement_tags_view",
  TagsManagementCreate: "admin_tagsmanagement_tags_create",
  TagsManagementUpdate: "admin_tagsmanagement_tags_update",
  TagsManagementDelete: "admin_tagsmanagement_tags_delete",

  TestManagementQuestionaryGroupView: "admin_testmanagement_questionarygroup_view",
  TestManagementQuestionaryGroupCreate: "admin_testmanagement_questionarygroup_create",
  TestManagementQuestionaryGroupUpdate: "admin_testmanagement_questionarygroup_update",
  TestManagementQuestionaryGroupDelete: "admin_testmanagement_questionarygroup_delete",

  TestManagementReportView: "admin_testmanagement_report_view",
  TestManagementReportCreate: "admin_testmanagement_report_create",
  TestManagementReportUpdate: "admin_testmanagement_report_update",
  TestManagementReportDelete: "admin_testmanagement_report_delete",

  TestManagementReportPredictorView: "admin_testmanagement_reportpredictor_view",
  TestManagementReportPredictorCreate: "admin_testmanagement_reportpredictor_create",
  TestManagementReportPredictorUpdate: "admin_testmanagement_reportpredictor_update",
  TestManagementReportPredictorDelete: "admin_testmanagement_reportpredictor_delete",

  TestManagementDiscStyleView: "admin_testmanagement_discstyles_view",
  TestManagementDiscStyleUpdate: "admin_testmanagement_discstyles_update",

  TestManagementDiscProfileView: "admin_testmanagement_discprofile_view",
  TestManagementDiscProfileUpdate: "admin_testmanagement_discprofile_update",

  TestManagementQuestionaryConfigurationView: "admin_testmanagement_questionaryconfiguration_view",
  TestManagementQuestionaryConfigurationUpdate: "admin_testmanagement_questionaryconfiguration_update",

  TestManagementCustomCompanyQuestionaryView: "admin_testmanagement_customcompanyquestionary_view",
  TestManagementCustomCompanyQuestionaryCreate: "admin_testmanagement_customcompanyquestionary_create",
  TestManagementCustomCompanyQuestionaryUpdate: "admin_testmanagement_customcompanyquestionary_update",
  TestManagementCustomCompanyQuestionaryDelete: "admin_testmanagement_customcompanyquestionary_delete",
  TestManagementCustomCompanyQuestionaryActivate: "admin_testmanagement_customcompanyquestionary_activate",

  TalentView: "admin_company_talent_view",
  TalentAsign: "admin_company_talent_asign",
  TalentRemove: "admin_company_talent_remove",
  TalentTransfer: "admin_company_talent_transfer",

  UserApiTokenView: "admin_company_userapitoken_view",
  UserApiTokenGeneration: "admin_company_userapitoken_generation",

  PreferenceView: "admin_preference_preference_view",
  PreferenceUpdate: "admin_preference_preference_update",

  //test module

  // person

  TestPersonView: "test_person_person_view",
  TestPersonCreate: "test_person_person_create",
  TestPersonUpdate: "test_person_person_update",
  TestPersonDelete: "test_person_person_delete",

  TestPersonObservationView: "test_person_observation_view",
  TestPersonObservationCreate: "test_person_observation_create",
  TestPersonObservationUpdate: "test_person_observation_update",
  TestPersonObservationDelete: "test_person_observation_delete",

  TestPersonHomologationView: "test_person_homologation_view",
  TestPersonHomologationCreate: "test_person_homologation_create",
  TestPersonHomologationUpdate: "test_person_homologation_update",
  TestPersonHomologationDelete: "test_person_homologation_delete",

  TestPersonBulkView: "test_person_bulkactivation_view",
  TestPersonBulkCreate: "test_person_bulkactivation_create",
  TestPersonBulkUpdate: "test_person_bulkactivation_update",
  TestPersonBulkDelete: "test_person_bulkactivation_delete",

  TestPersonBulkDownloadView: "test_person_bulkdownload_view",
  TestPersonBulkDownloadCreate: "test_person_bulkdownload_create",
  TestPersonBulkDownloadUpdate: "test_person_bulkdownload_update",
  TestPersonBulkDownloadDelete: "test_person_bulkdownload_delete",

  TestPersonBulkResultsExportView: "test_person_bulkresultsexport_view",
  TestPersonBulkResultsExportCreate: "test_person_bulkresultsexport_create",
  TestPersonBulkResultsExportUpdate: "test_person_bulkresultsexport_update",
  TestPersonBulkResultsExportDelete: "test_person_bulkresultsexport_delete",

  // position

  TestPositionView: "test_position_position_view",
  TestPositionCreate: "test_position_position_create",
  TestPositionUpdate: "test_position_position_update",
  TestPositionDelete: "test_position_position_delete",

  TestPositionDirectView: "test_position_direct_view",
  TestPositionDirectCreate: "test_position_direct_create",
  TestPositionDirectUpdate: "test_position_direct_update",
  TestPositionDirectDelete: "test_position_direct_delete",

  TestPositionConcertedView: "test_position_concerted_view",
  TestPositionConcertedCreate: "test_position_concerted_create",
  TestPositionConcertedUpdate: "test_position_concerted_update",
  TestPositionConcertedDelete: "test_position_concerted_delete",

  // company

  TestCompanyView: "test_company_company_view",
  TestCompanyCreate: "test_company_company_create",
  TestCompanyUpdate: "test_company_company_update",
  TestCompanyDelete: "test_company_company_delete",

  TestCompanyDirectView: "test_company_direct_view",
  TestCompanyDirectCreate: "test_company_direct_create",
  TestCompanyDirectUpdate: "test_company_direct_update",
  TestCompanyDirectDelete: "test_company_direct_delete",

  TestCompanyConcertedView: "test_company_concerted_view",
  TestCompanyConcertedCreate: "test_company_concerted_create",
  TestCompanyConcertedUpdate: "test_company_concerted_update",
  TestCompanyConcertedDelete: "test_company_concerted_delete",

  // team

  TestTeamView: "test_team_team_view",
  TestTeamCreate: "test_team_team_create",
  TestTeamUpdate: "test_team_team_update",
  TestTeamDelete: "test_team_team_delete",

  TestTeamActivationView: "test_team_activation_view",
  TestTeamActivationCreate: "test_team_activation_create",


  // archive
  TestArchiveView: "test_archive_archive_view",
  TestArchiveUpdate: "test_archive_archive_update",


  // recruiting



  // cross functionalities

  CrossFunctionalityTheMethod: "cross_themethod_themethod_view",
  CrossFunctionalityHelp: "cross_help_help_view",
  CrossFunctionalityChat: "cross_chat_chat_view",
  CrossFunctionalityMicrosite: "cross_microsite_microsite_view",

  //admin selection

  //company
  AdminSelectionAdminCompanyView: "AdminSelection_admin_company_view",
  AdminSelectionAdminCompanyUpdate: "AdminSelection_admin_company_update",
  AdminSelectionAdminCompanyInactivate: "AdminSelection_admin_company_inactivate",
  AdminSelectionAdminCompanyCreate: "AdminSelection_admin_company_create",

  //catalogs
  AdminSelectionAdminCatalogsView: "AdminSelection_admin_catalogs_view",
  AdminSelectionAdminCatalogsUpdate: "AdminSelection_admin_catalogs_update",
  AdminSelectionAdminCatalogsInactivate: "AdminSelection_admin_catalogs_inactivate",
  AdminSelectionAdminCatalogsCreate: "AdminSelection_admin_catalogs_create",

  //traductions
  AdminSelectionAdminTraductionsView: "AdminSelection_admin_traductions_view",
  AdminSelectionAdminTraductionsUpdate: "AdminSelection_admin_traductions_update",
  AdminSelectionAdminTraductionsInactivate: "AdminSelection_admin_traductions_inactivate",
  AdminSelectionAdminTraductionsCreate: "AdminSelection_admin_traductions_create",

  //portal
  AdminSelectionAdminPortalEdit: "AdminSelection_admin_portal_edit",
  AdminSelectionAdminPortalChangelogo: "AdminSelection_admin_portal_changelogo",

  //theme
  AdminSelectionAdminThemeCreate: "AdminSelection_admin_theme_create",
  AdminSelectionAdminThemeView: "AdminSelection_admin_theme_view",
  AdminSelectionAdminThemeChangetheme: "AdminSelection_admin_theme_changetheme",
  AdminSelectionAdminThemeUpdate: "AdminSelection_admin_theme_update",
  AdminSelectionAdminThemeDelete: "AdminSelection_admin_theme_delete",

  //external integration
  AdminSelectionAdminExternalintegrationCreate: "AdminSelection_admin_externalintegration_create",
  AdminSelectionAdminExternalintegrationManage: "AdminSelection_admin_externalintegration_manage",
  AdminSelectionAdminExternalintegrationUpdate: "AdminSelection_admin_externalintegration_update",
  AdminSelectionAdminExternalintegrationAccess: "AdminSelection_admin_externalintegration_access",
  AdminSelectionAdminExternalintegrationDelete : "AdminSelection_admin_externalintegration_delete ",

  //selection

  //trayectory
  SelectionPredictorTrayectoryView: "selection_predictor_trayectory_view",
  SelectionPredictorTrayectoryUpdate: "selection_predictor_trayectory_update",
  SelectionPredictorTrayectoryInactivate: "selection_predictor_trayectory_inactivate",
  SelectionPredictorTrayectoryRequestreference: "selection_predictor_trayectory_requestreference",
  SelectionPredictorTrayectoryDownloadcvfile: "selection_predictor_trayectory_downloadcvfile",
  SelectionPredictorTrayectoryCreate: "selection_predictor_trayectory_create",

  //internal
  SelectionPredictorInternalnotesView: "selection_predictor_internalnotes_view",
  SelectionPredictorInternalnotesUpdate: "selection_predictor_internalnotes_update",
  SelectionPredictorInternalnotesInactivate: "selection_predictor_internalnotes_inactivate",
  SelectionPredictorInternalnotesCreate: "selection_predictor_internalnotes_create",

  //tasks
  SelectionPredictorTasksView: "selection_predictor_tasks_view",
  SelectionPredictorTasksUpdate: "selection_predictor_tasks_update",
  SelectionPredictorTasksInactivate: "selection_predictor_tasks_inactivate",
  SelectionPredictorTasksCreate: "selection_predictor_tasks_create",

  //reports
  SelectionPredictorReportsView: "selection_predictor_reports_view",
  SelectionPredictorReportsDownload: "selection_predictor_reports_download",

  //persons
  SelectionPredictorPersonsView: "selection_predictor_persons_view",
  SelectionPredictorPersonsUpdate: "selection_predictor_persons_update",
  SelectionPredictorPersonsDelete: "selection_predictor_persons_delete",
  SelectionPredictorPersonsSearch: "selection_predictor_persons_search",

  //complete info
  SelectionPredictorCompleteinfoView: "selection_predictor_completeinfo_view",
  SelectionPredictorCompleteinfoRequest: "selection_predictor_completeinfo_request",

  //contract
  SelectionPredictorContractManagment: "selection_predictor_contract_managment",

  //reserved info
  SelectionPredictorReservedinfoView: "selection_predictor_reservedinfo_view",
  SelectionPredictorReservedinfoCreate: "selection_predictor_reservedinfo_create",
  SelectionPredictorReservedinfoDelete: "selection_predictor_reservedinfo_delete",
  SelectionPredictorReservedinfoUpdate: "selection_predictor_reservedinfo_update",

  //procces
  SelectionProcessProcessPublish: "selection_process_process_publish",
  SelectionProcessProcessCreate: "selection_process_process_create",
  SelectionProcessProcessShare: "selection_process_process_share",
  SelectionProcessProcessEdit: "selection_process_process_edit",

  //offer
  SelectionProcessOfferCreate: "selection_process_offer_create",
  SelectionProcessOfferEdit: "selection_process_offer_edit",
  SelectionProcessOfferUpdate: "selection_process_offer_update",
  SelectionProcessOfferDelete: "selection_process_offer_delete",
};

export default PredefinedPermission;
