import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { BulkResultsExport } from "models/test-person/bulkResultsExport";

@autoinject()
export class BulkResultsExportService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient,
  ) {

    this.logger = LogManager.getLogger("BulkResultsExportService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("bulkresultsexport/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  public async create(model: BulkResultsExport): Promise<BulkResultsExport> {
    return await this.client.create("bulkresultsexport", model)
      .then((response: BulkResultsExport) => {
        return response;
      });
  }
  public async getMediaByBulkActivationId(id: number): Promise<string> {
    return await this.client.get(`bulkresultsexport/get-media-by-bulkResultExportId/${id}`)
      .then((response: any) => {
        return <string>response;
      });
  }


  
}
