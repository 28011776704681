import { TransactionService } from '../../services/talents/transaction-service';
import { Transaction } from 'models/talents/transaction';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { TalentModal } from "./talent-modal";

import { Notification } from "common/services/notification";
import { EventAggregator } from 'aurelia-event-aggregator';
import { TalentAssignmentMessage } from 'messages/talentAssigmentMessage';


@autoinject()
export class TalentAssignmentCard {

  private logger: Logger;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private transactionService: TransactionService,
    private notification: Notification,
    private ea: EventAggregator,) {

    this.logger = LogManager.getLogger("TalentAssignmentCard");
  }

  private async create(): Promise<any> {
    let item: Transaction = new Transaction();
    return await this.dialogService.open({
      viewModel: TalentModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        // this.logger.debug("response.output", response.output);
        return await this.transactionService.assign(item)
          .then(async() => {                       

            this.ea.publish(new TalentAssignmentMessage());

            this.notification.success("notifications.item_added");
            // return await this.router.navigateToRoute("talent-main");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

 
}
