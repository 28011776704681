import { AgreementStatus } from 'models/agreements/agreementStatus';
import { ActivityStatus } from 'models/agreements/activityStatus';
import { ActivityModal } from './activity-modal';
import { DeleteConfirmation } from '../../../../components/delete-confirmation';
import { UxDatatableResponse } from 'common/resources/elements/ux/datatable/ux-datatable';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { ActivityService } from '../../../services/agreements/activity-service';
import { AgreementService } from '../../../services/agreements/agreement-service';
import { UxDatatableCustomAttribute } from 'common/resources/elements/ux/datatable/ux-datatable';
import { UxDatatableParameters } from 'common/resources/elements/ux/datatable/ux-datatable';
import { Agreement } from 'models/agreements/agreement';
import { Logger } from 'aurelia-logging';
import { Activity } from 'models/agreements/activity';
import { Notification } from "common/services/notification";
import { autoinject, LogManager } from "aurelia-framework";

@autoinject()
export class ActivityList {
  
  private logger: Logger;
  public agreementId: number;
  private companyId: number;
  private agreement: Agreement;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private disable: boolean = false;
  private params: any = {};

  constructor(
    private agreementService: AgreementService,
    private activityService: ActivityService,
    private dialogService: DialogService,
    private notification: Notification) {
    this.logger = LogManager.getLogger("ActivityList");
  }

  public async activate(params: any): Promise<void> {
    this.params = params;
  }

  public async bind(): Promise<any> {
    if (!isNaN(this.params.agreementId)) {
      this.agreementId = +this.params.agreementId;
      
      if (!isNaN(this.params.companyGroupId)) {
        this.companyId = +this.params.companyGroupId;
      }
      
      if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.agreementId && this.companyId) {
        this.agreementService.client.currentCompanyId = this.params.companyId;
        this.activityService.client.currentCompanyId = this.params.companyId;

        try {
          this.agreement = await this.agreementService.getById(this.agreementId);
          
          if (this.agreement.agreementStatus !== AgreementStatus.InternalDraft) {
            this.disable = true;
          }

          return this.databind();
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    }
  }

  public async databind(): Promise<void> {
    this.logger.info("databind");
    return await this.activityService.list(this.agreementId, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    return this.activityService.list(this.agreementId, this.parameters);
  }

  private async create(): Promise<void> {
    let item: Activity = new Activity();
    item.agreementId = this.agreementId;
    return await this.dialogService.open({
      viewModel: ActivityModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        item.activityStatus = ActivityStatus.Pending;
        return await this.activityService.create(item)
          .then(async (activity: Activity) => {
            item.id = activity.id;
            this.parameters.tableData.unshift(item);
            this.notification.success("notifications.item_added");
            // return await this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async edit(item: Activity): Promise<void> {
    return await this.dialogService.open({
      viewModel: ActivityModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.activityService.update(item)
          .then(async () => {
            this.notification.success("notifications.item_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      } else {
        this.databind();
      }
    });
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed((response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.activityService.delete(id)
          .then(() => {
            // this.databind();
            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);

            this.notification.success("notifications.item_deleted");
            
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

}
