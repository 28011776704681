
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { Logger } from "aurelia-logging";
import { I18N } from "aurelia-i18n";
import { Notification } from "common/services/notification";
@autoinject
export class PlanChildRouter {

  private logger: Logger;
  private router: Router;
  private isRobotHunter: boolean = false;
  constructor(
    private i18n: I18N,
    private notification: Notification) {

    this.logger = LogManager.getLogger("GroupChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router, params: any): void {
    this.router = router;    
    try {  
      if (params.isRobotHunter) {
        this.isRobotHunter = JSON.parse(params.isRobotHunter);
      }        
   
    config.map([
      {
        route: [""],
        moduleId: PLATFORM.moduleName("./plan-edit"),
        name: "plan-edit",
        nav: true,
        title: this.i18n.tr("main.admin.pages.plans.plan.title"),
      },

      {
        route: "test-plan-items",
        moduleId: PLATFORM.moduleName("./test-plan-items/test-plan-item-list"),
        name: "test-plan-items",
        nav: !this.isRobotHunter,
        title: this.i18n.tr("main.admin.pages.plans.test_plan_items.title")
      },


      {
        route: "cross-plan-items",
        moduleId: PLATFORM.moduleName("./cross-plan-items/cross-plan-items-edit"),
        name: "cross-plan-items",
        nav: !this.isRobotHunter,
        title: this.i18n.tr("main.admin.pages.plans.cross_plan_items.title"),
      },

      {
        route: "other-plan-items",
        moduleId: PLATFORM.moduleName("./other-plan-items/other-plan-item-list"),
        name: "other-plan-items",
        nav: !this.isRobotHunter,
        title: this.i18n.tr("main.admin.pages.plans.other_plan_items.title"),
      },

      {
        route: "selection-plan-items",
        moduleId: PLATFORM.moduleName("./test-plan-items/test-plan-item-list"),
        name: "selection-plan-items",
        nav: this.isRobotHunter,
        title: this.i18n.tr("main.admin.pages.plans.selection_plan_items.title")
      },

    ]);
  }
  catch (error) {
    this.notification.error(error);
  }
}
}
