import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { RouteConfig } from "aurelia-router";
import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { State } from "common/store/store-model";
import { QuestionaryGroup } from 'models/test-management/questionary-groups/questionaryGroup';
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { ReportTemplatePredictorService } from '../../../services/test-management/report-template-predictor-service';
import { ReportTemplate } from 'models/test-core/report-templates/report-template';
import { CompanySettings } from 'models/companies/company-settings';
import { CompanySettingsService } from 'main/admin/services/companies/company-settings-service';
import { Language } from 'models/localizations/language';
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';

@autoinject()
export class ReportPredictorList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private languageId: number;
  private languages: Language[];
  private toggle: HTMLInputElement;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;
  private companySettings: CompanySettings = new CompanySettings();

  constructor(
    private store: StoreService,
    private languageService: CoreLanguageService,
    private companySettingsService: CompanySettingsService,
    private reportTemplatePredictorService: ReportTemplatePredictorService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("ReportPredictorList");

    this.state = this.store.state;
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('reportTemplatePredictorListSearch', search == null ? '' : search);

    return await this.reportTemplatePredictorService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // this.logger.debug("activate");
    this.parameters.search.Query = sessionStorage.getItem('reportTemplatePredictorListSearch');

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {


        this.languages = await this.languageService.getAll();
        this.setUserLanguage();

        this.reportTemplatePredictorService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.companySettings = await this.companySettingsService.getById(this.companyId);

        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async clearFilters(): Promise<any> {
    this.parameters.search.Query = null;
    await this.databind();
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async delete(item: ReportTemplate): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.reportTemplatePredictorService.delete(item.id)
          .then(() => {

            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("notifications.item_deleted");

          })
          .catch(error => {
            this.notification.error(error);
          });

      }
    });
  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find((lg: { culture: string; }) => lg.culture === activeCulture);
      if (activeLang) {
        this.languageId = activeLang.id;
      }
    }
  }

  public async updateCompanySettings(item: CompanySettings): Promise<any> {
    this.toggle.disabled = true;
    await this.companySettingsService.update(item)
      .then(async () => {
        return await this.change(item);
      })
      .catch((error) => {
        this.notification.error(error);
      });
  }

  public async change(item: CompanySettings): Promise<any> {
    await this.reportTemplatePredictorService.changeState(this.languageId, item.hasPredictor)
      .then(async () => {
        this.notification.success("main.admin.pages.test_management.questionaries_configuration.notifications.edited");
        this.toggle.disabled = false;
        return await this.databind();
      })
      .catch((error) => {
        this.notification.error(error);
      });
  }

}
