import { ItemToQualify } from './itemToQualify';
import { QuestionaryType } from './questionaryType';
import { OrganizationType } from './organizationType';
import { NoDiscType } from './noDiscType';

export class QuestionaryLocalization {
  labelId: number;
  languageId: number;
  localizationTypeId: number;
  translation: string;
}
