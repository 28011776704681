import { autoinject, LogManager, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { LanguageService } from "../../services/localizations/language-service";
import { Language } from "models/localizations/language";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { ChangeStateConfirmation } from "../../../components/change-state-confirmation";
import { UxDatatableParameters, UxDatatableResponse, UxDatatableCustomAttribute } from "common/resources/elements/ux/datatable/ux-datatable";
import config from 'config.js';

@autoinject()
export class LanguageList {

  private logger: Logger;
  private parameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private host: string;

  private refresh = async (): Promise<void | UxDatatableParameters> => {
    this.logger.debug("refreshing");
    try {
      const response = await this.languageService.list(this.parameters);
      return response;
    }
    catch (error) {
      return this.notification.error(error);
    }
  }

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private notification: Notification
  ) {

    let backendUrl = config.URLS_BACKEND;

    this.host = backendUrl + "/modules/localization/assets/images/flags/";
    this.logger = LogManager.getLogger("LanguageList");
  }

  private async bind(): Promise<any> {
    this.logger.debug("databind");
    return await this.languageService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.totalRecords = response.totalRecords;
        this.parameters.tableData = response.data;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async changeState(item: Language): Promise<void> {
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.languageService.changeState(item.id)
          .then(() => {
            // this.databind();
            // item.isActive = !item.isActive;
            this.notification.success("notifications.item_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        item.isActive = !item.isActive;
      }
    });
  }

  public async delete(id: number, language: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.languageService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("notifications.item_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }
}
