import { Quota } from './quota';
import { Agreement } from "./agreement";

export class AgreementTermsOfPay {
  id: number;
  agreementId: number;
  agreement: Agreement = new Agreement();
  purchaseValue: number;
  currencyTypeId: number;
  hasAutomaticRenewal: boolean;
  invoicePersonId: number;
  invoicePerson: string;
  
  quotas: Quota[] = [];
}
