import { ViewEngineHooks, View } from 'aurelia-framework';
import { PositionActivationType } from './positionActivationType';

export class PositionActivationTypeViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['PositionActivationType'] = PositionActivationType;

    view.overrideContext['PositionActivationTypes'] = 
      Object.keys(PositionActivationType)
        .filter((key) => typeof PositionActivationType[key] === 'number');
  }
}
