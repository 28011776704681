import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { OrganizationEvaluator } from "models/test-organization/organizationEvaluator";
import { BulkActivation } from "models/test-person/bulkActivation";

@autoinject()
export class OrganizationEvaluatorService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("OrganizationEvaluatorService");
  }

  public async list(parameters: UxDatatableParameters, organizationId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`organizationevaluator/list/${organizationId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(organizationId: number): Promise<OrganizationEvaluator[]> {
    return await this.client.get(`organizationevaluator/get-all/${organizationId}`)
      .then((response: any) => {
        return <OrganizationEvaluator[]>response;
      });
  }

  public async getById(id: number): Promise<OrganizationEvaluator> {
    return await this.client.getById(`organizationevaluator`, id)
      .then((response: OrganizationEvaluator) => {
        return response;
      });
  }

  public async create(model: OrganizationEvaluator): Promise<OrganizationEvaluator> {
    return await this.client.create(`organizationevaluator/${model.organizationId}`, model)
      .then((response: any) => {
        return <OrganizationEvaluator>response;
      });
  }

  public async update(model: OrganizationEvaluator): Promise<void> {
    return await this.client.updateById(`organizationevaluator`, model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`organizationevaluator`, id)
      .then(() => null);
  }  

  public async getWeightAllEvaluators(organizationId: number): Promise<number> {
    return await this.client.get(`organizationevaluator/get-weight-all-evaluators/${organizationId}`)
      .then((response: any) => {
        return <number>response;
      });
  }

    
  public async createBulkActivation(model: BulkActivation): Promise<BulkActivation> {

    let formData = new FormData();

    let filedata = null;
    if (model.filedata) {
      filedata = model.filedata[0];
    }

    formData.append('filedata', filedata);
    formData.append('filename', model.fileName);
    formData.append('name', model.name);

    return await this.client.requestFormData("PUT", `organizationevaluator/bulk-activation/${model.organizationId}`, formData)
      .then((response: any) => {
        return <BulkActivation>response;
      });
  }

}
