import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { Notification } from "common/services/notification";
import { QuestionType } from "models/questions/questionType";

@autoinject()
export class CustomCompanyQuestionTypeSelection {

  private logger: Logger;
  private questionaryId: number;
  private tabItem: string;

  constructor(
    private router: Router,
    private notification: Notification) {

    this.logger = LogManager.getLogger("CustomCompanyQuestionTypeSelection");
    this.tabItem = "selectionList";

  }

  public activate(params: any): any {
    this.logger.info("Activated with questionaryId", params.questionaryId);
    if (!isNaN(params.questionaryId)) {
      this.questionaryId = +params.questionaryId;
    } else {
      this.router.navigate("questionaries");
    }
  }

  public createQuestion(type: QuestionType): any {
    this.logger.info("create question with type:", type);
    this.router.navigate(`create/${type}`);
  }

}
