import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { User } from "models/security/user";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";

@autoinject
export class MasterUserService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("MasterUserService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("masteruser/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<User[]> {
    return await this.client.get("masteruser")
      .then((response: User[]) => {
        return response;
      });
  }

  public async getById(id: number): Promise<User> {
    return await this.client.getById("masteruser", id)
      .then((response: User) => {
        return response;
      });
  }

  public async create(model: User): Promise<User> {
    return await this.client.create("masteruser", model)
      .then((response: User) => {
        return response;
      });
  }

  public async convert(model: User): Promise<User> {
    return await this.client.create("masteruser/convert", model)
      .then((response: User) => {
        return response;
      });
  }

  public async changeUserCompany(model: User): Promise<User> {
    return await this.client.update(`masteruser/change-user/${model.id}`, null, model)
    .then((response: User) => {
      return response;
    });
  }

  public async update(model: User): Promise<void> {
    return await this.client.updateById("masteruser", model.id, null, model)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {
    return await this.client.post(`masteruser/change-state/${id}`)
      .then(() => null);
  }

  public async resendActivationMessage(id: number): Promise<void> {
    return await this.client.post(`masteruser/resend-activation-message/${id}`)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("masteruser", id)
      .then(() => null);
  }
  public async convertToUser(model: User, id: number): Promise<User> {
    return await this.client.post(`masteruser/convert-user/${id}`, model)
      .then((response: User) => {
        return response;
      });
  }

}
