import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Position } from "models/test-position/position";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Disc } from "models/questionaries/disc";
import { DiscAdjective } from "models/test-core/discAdjective";
import { Result } from "models/test-result/result";
import { PositionService } from "main/test/services/position/position-service";
import { ResultService } from "main/test/services/result/result-service";
import { DiscAdjectiveService } from "main/test/services/core/disc-adjective-service";


@autoinject()
export class PositionDiscEdit {

  private logger: Logger;
  public companyId: number;

  private positionId: number;
  private model: Position = new Position();


  private validationController: ValidationController;
  private positionDiscList: Result[] = [];
  private discAdjetives: DiscAdjective[] = [];

  constructor(
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private positionService: PositionService,
    private resultService: ResultService,
    private discAdjectiveService: DiscAdjectiveService
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PositionDiscEdit");
  }

  public async activate(params: any): Promise<any> {
    try {
      if (!isNaN(params.companyId)) {
        this.companyId = +params.companyId;

        this.positionService.client.currentCompanyId = this.companyId;
        this.resultService.client.currentCompanyId = this.companyId;


        if (!isNaN(params.positionId)) {
          this.positionId = +params.positionId;
          this.model = await this.getItem(this.positionId);         

          this.positionDiscList = await this.getPositionDisc(this.positionId);
        }

        const adjectives = await this.getDiscAdjetives();

        this.discAdjetives = adjectives.map(itm => {
          let range = itm.scoreRange.split('-');
          let minRange = parseInt((range[0] || '0').trim());
          let maxRange = parseInt((range[1] || '0').trim());
          itm['min'] = minRange;
          itm['max'] = maxRange;
          return itm;
        });
      }
    } catch (error) {
      this.notification.error(error);
    }
  }

  private async getItem(itemId: number): Promise<Position> {
    return await this.positionService.getById(itemId);
  }

  private async getPositions(): Promise<Position[]> {
    return await this.positionService.getAll();
  }

  private async getDiscAdjetives(): Promise<DiscAdjective[]> {
    return await this.discAdjectiveService.getAll();
  }

  private async getPositionDisc(positionId: number): Promise<Result[]> {

    let allPositionDisc = await this.resultService.getAllByPositionId(positionId);
    let discIds = Object.keys(Disc).filter(k => typeof Disc[k] !== 'number');

    let positionDiscList: Result[] = [];

    discIds.forEach(itm => {
      let filteredItems = allPositionDisc.filter(p => Disc[p.disc] === Disc[itm]);

      if (filteredItems.length) {
        positionDiscList.push({
          positionId: this.positionId,
          disc: Disc[itm],
          value: filteredItems[0].value
        })
      } else {
        positionDiscList.push({
          positionId: this.positionId,
          disc: Disc[itm],
          value: 50
        })
      }
    })

    return positionDiscList;
  }

  private calculateLabel(value: number, disc: Disc): string {
    // this.logger.debug("calculateLabel: " + disc + value);
    const itm = this.discAdjetives.find((itm: any) => value >= itm.min && value <= itm.max && itm.disc === Disc[disc]);
    return itm ? itm.name : '';
  }

  private async changeItem(item): Promise<any> {
    if (item) {
      this.positionDiscList = await this.getPositionDisc(item);
    }
  }

  public async submit(): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }


  get canSave() {
    return !this.resultService.client.isRequesting;
  }

  private async save(): Promise<any> {

    let promises = [];
    // Person Data
    // create stamp
    this.model.stamp = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);

    // get highest disc
    let maxItem: any = { value: 0 };
    this.positionDiscList.forEach(itm => {
      if (maxItem.value < itm.value) {
        maxItem = itm;
      }
    });

    this.model.highestDisc = maxItem.disc;

    this.positionDiscList.forEach(itm => {
      itm.stamp = this.model.stamp;
      promises.push(this.resultService.createToPosition(itm));
    });

    Promise.all(promises)
      .then(async () => this.positionService.update(this.model))
      .then(async () => this.notification.success("notifications.item_edited"))
      .catch(error => {
        this.notification.error(error);
      });
  }
}
