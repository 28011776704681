import { DichotomousCouple } from "../test-core/competences/dichotomous-couple";

export class PositionCompetence {
  id: number;
  companyId: number;
  positionId: number;
  companyCompetenceId: number;
  complementaryName: string;
  isToAllPositions: boolean;
  dichotomousCouples: DichotomousCouple[] = [];
  directCompetenceValue: number;
}
