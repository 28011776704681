import { ViewEngineHooks, View } from 'aurelia-framework';
import { OtherPlanItemType } from './otherPlanItemType';

export class OtherPlanItemTypeViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['OtherPlanItemType'] = OtherPlanItemType;

    view.overrideContext['OtherPlanItemTypes'] =
      Object.keys(OtherPlanItemType)
        .filter((key) => typeof OtherPlanItemType[key] === 'number');
  }
}
