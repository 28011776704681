export enum AgreementStatus {
  InternalDraft,
  ManagementReview,
  ForwardedClient,
  Approved,
  Valid,
  InWarranty,
  Expired,
  Cancelled
}
