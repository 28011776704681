import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";

@autoinject
export class AgreementChildRouter {
  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N){
    this.logger = LogManager.getLogger("AgreementChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./agreement-list"),
        name: "agreements"
      },
      {
        route: "agreement/:agreementId",
        moduleId: PLATFORM.moduleName("./readonly/readonly-child-router"),
        name: "readonly-child-router",
      },
      {
        route: "agreement/:agreementId/edit",
        moduleId: PLATFORM.moduleName("./edition/edition-child-router"),
        name: "edition-child-router"
      }  
    ]);
  }
}
