import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyDiscStyle } from "models/test-management/disc-styles/company-disc-style";

@autoinject()
export class CompanyDiscStyleService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CompanyDiscStyleService");
  }

  public async list(languageId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`companydiscstyle/language/${languageId}/list`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async update( model: CompanyDiscStyle): Promise<CompanyDiscStyle> {
    return await this.client.updateById("companydiscstyle", model.id, null, model)
      .then((response) => { return <CompanyDiscStyle>response; });
  }

}
