import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";

import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Notification } from "common/services/notification";
import { ResetSecondPassword } from "models/second-password/reset-second-password";
import { SecondPasswordService } from "../../services/groups/second-password-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class ResetSecondPasswordModal {

  private logger: Logger;
  private model: ResetSecondPassword;
  private validationController: ValidationController;
  private errorMessages: string[];

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private secondPasswordService: SecondPasswordService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ResetSecondPasswordModal");

  }

  public activate(): void {
    this.model = new ResetSecondPassword();
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("currentPassword").displayName("main.admin.pages.core_groups.second_password.current_password").required()
      .ensure("newPassword").displayName("main.admin.pages.core_groups.second_password.new_password").required()
      .ensure("confirmNewPassword").displayName("main.admin.pages.core_groups.second_password.confirm_new_password").required()
      .satisfiesRule("matchesProperty", "newPassword")
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave(){
    return !this.secondPasswordService.client.isRequesting;
  }

  public async save(): Promise<any> {
    return await this.secondPasswordService.create(this.model)
      .then(async (model: boolean) => {
        this.validationController.reset();
        if (model) {
          this.notification.success("main.admin.pages.core_groups.second_password.notifications.success_reset");
        } else {
          this.notification.error("main.admin.pages.core_groups.second_password.notifications.error_reset");
        }
        return await this.dialogController.ok(model);
      })
      .catch(error => {
        this.logger.debug("error: ", error);
        this.notification.error(error);
      });
  }
}

ValidationRules.customRule(
  "matchesProperty",
  (value, obj, otherPropertyName) =>
    value === null
    || value === undefined
    || value === ""
    || obj[otherPropertyName] === null
    || obj[otherPropertyName] === undefined
    || obj[otherPropertyName] === ""
    || value === obj[otherPropertyName],
  "errorMessages.matchesProperty", otherPropertyName => ({ otherPropertyName })
);
