import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Notification } from "common/services/notification";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { PersonService } from "main/test/services/people/person-service";
import { Person } from "models/test-person/person";


@autoinject()
export class PersonDeleteModal {

  private logger: Logger;
  private personToDelete: Person;
  private groupIdToTransferUsers: number;
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private personService: PersonService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PersonDeleteModal");
    this.configureValidationRules();
  }

  private async activate(item: Person): Promise<void> {
    this.personToDelete = Object.assign(new Person(), item);  }

  private configureValidationRules(): void {
    // ValidationRules
    //   .ensure("groupIdToTransferUsers").displayName("main.admin.pages.groups.group_to_transfer").required()
    //   .on(this);
  }

  public async submit(): Promise<any> {
    return await this.dialogController.ok();
  }
}


