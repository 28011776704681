import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Person } from "models/test-person/person";
import { PersonService } from "../../services/people/person-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Activation } from "models/test-person/activation";
import { ActivationService } from "main/test/services/people/activation-service";
import { CompanyQuestionaryService } from "main/admin/services/test-management/company-questionary-service";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ItemToQualify } from "models/questionaries/itemToQualify";
import { PersonActivationType } from "models/test-person/personActivationType";
import { Language } from "models/localizations/language";
import { StoreService } from "common/store/store-service";
import { State } from "common/store/store-model";
import { UserInfoLoadedMessage } from "messages/userInfoLoadedMessage";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";

@autoinject()
export class ObservationEdit {

  private logger: Logger;
  public companyId: number;

  private state: State;

  private personId: number;
  private model: Person = new Person();
  private validationController: ValidationController;

  private observationQuestionaries: Questionary[] = [];
  private languages: Language[];

  private subscription: Subscription;

  constructor(
    private router: Router,
    private store: StoreService,
    private companyQuestionaryService: CompanyQuestionaryService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private personService: PersonService,
    private activationService: ActivationService,
    private languageService: CoreLanguageService,
    protected ea: EventAggregator
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ObservationEdit");
    this.model.tags = "";

    this.state = this.store.state;

    this.subscription = this.ea.subscribe(UserInfoLoadedMessage, (message: UserInfoLoadedMessage) => this.setUserLanguage.bind(this));
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("firstName").displayName("main.admin.pages.users.first_name").required()
      .ensure("lastName").displayName("main.admin.pages.users.last_name").required()
      .on(this.model);

    ValidationRules
      .ensure("questionaryId").displayName("main.test.pages.people.observation.questionaryId").required()
      .on(this.model.activation);

  }

  public async activate(params: any): Promise<any> {
    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      this.companyQuestionaryService.client.currentCompanyId = this.companyId;

      const questionaries = await this.getQuestionaries();

      this.observationQuestionaries = questionaries.filter(itm => (itm.questionaryType != QuestionaryType.Competences &&  itm.itemToQualify === ItemToQualify.Observed));

      let activation = new Activation();
      activation.personActivationType = PersonActivationType.Observed;
      this.model.activation = activation;
      this.configureValidationRules();
    }

  }

  public detached() {
    this.subscription.dispose();
  }

  private async bind(): Promise<any> {
    // this.logger.debug("bind");
    try {
      this.languages = await this.languageService.getAll();
      this.setUserLanguage();
    }
    catch (error) {
      this.notification.error(error);
    }

  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find(lg => lg.culture === activeCulture);
      if (activeLang) {
        this.model.activation.languageId = activeLang.id;
      }
    }
  }

  private async getPerson(): Promise<Person> {
    return await this.personService.getById(this.personId)
  }

  private async getQuestionaries(): Promise<Questionary[]> {
    return await this.companyQuestionaryService.getAll();
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.create(keepEditing);
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.personService.client.isRequesting;
  }

  private async create(keepEditing: boolean): Promise<any> {
    return await this.personService.create(this.model)
      .then(async (result: Person) => {

        this.notification.success("notifications.item_edited");

        if(keepEditing){
          // return await this.router.navigateToRoute("observers", { observedId: result.id, activationId: result.activation.id });
          return await this.router.navigate(`/company/${this.companyId}/test/people/person-observation/${result.id}/observers/${result.activation.id}`);
        }
        else{
          return await this.router.navigateToRoute("people");
        }
      })
      .catch(error => {
        this.notification.error(error.error);
      });
  }



}
