import { autoinject } from "aurelia-framework";

@autoinject
export class SalesIq {

  private setupScript: HTMLScriptElement;
  private beacon: HTMLScriptElement;

  constructor() {
  }

  public async attached(src: string) {
    this.setupScript = document.createElement('script');
    this.setupScript.innerHTML = src;

    document.body.appendChild(this.setupScript);

    this.beacon = document.createElement('script');

    this.beacon.onload = () => {
    if (window['salesiq_modal_loaded']) {
        window['salesiq_modal_loaded'] = false;
        window['SalesiqChat'].stop();
    }
    window['SalesiqChat'].run();
    };
    document.body.appendChild(this.beacon);
  }
}

