import { ViewEngineHooks, View } from 'aurelia-framework';
import { PositionLevel } from './positionLevel';

export class PositionLevelViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['PositionLevel'] = PositionLevel;

    view.overrideContext['PositionLevels'] = 
      Object.keys(PositionLevel)
        .filter((key) => typeof PositionLevel[key] === 'number');
  }
}
