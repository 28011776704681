
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject
export class AgreementReadOnlyChildRouter {

  private logger: Logger;

  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("AgreementReadOnlyChildRouter");    
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: ["","readonly"],
        moduleId: PLATFORM.moduleName("./agreement-readonly-mode"),
        name: "agreement-readonly-mode",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ServiceAgreementView),
        title: this.i18n.tr("main.admin.pages.agreements.initialAgreement")
      },

      {
        route: 'public-notes',
        moduleId: PLATFORM.moduleName('./public-notes-list'),
        name: 'public-notes',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ServiceAgreementPublicNoteView),
        title: this.i18n.tr("main.admin.pages.agreements.publicnotes.title"),
      },

      {
        route: 'internal-notes',
        moduleId: PLATFORM.moduleName('./internal-notes-list'),
        name: 'internal-notes',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ServiceAgreementPrivateNoteView),
        title: this.i18n.tr("main.admin.pages.agreements.internalnotes.title"),
      },

      {
        route: 'addendums',
        moduleId: PLATFORM.moduleName('./addendum-list'),
        name: 'addendums',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ServiceAgreementView),
        title: this.i18n.tr("main.admin.pages.agreements.addendum.title"),
      },

      {
        route: "edit",
        moduleId: PLATFORM.moduleName("../edition/edition-child-router"),
        name: "edition-child-router",
        nav: false,
        title: this.i18n.tr("navigations.basic_info")
      }

    ]);
  }
}
