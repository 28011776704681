import { bindable, LogManager, bindingMode, autoinject, Disposable, BindingEngine } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class MasterUserCreateCard {

  protected logger: Logger;

  @bindable
  public companyGroup: number;
  private params: any = {};

  constructor(
    public element: Element,
    private ea: EventAggregator,
    public bindingEngine: BindingEngine) {
    this.logger = LogManager.getLogger("MasterUserCreateCard");

  }

  public async attached(): Promise<any> {
}
}
