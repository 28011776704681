import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { Person } from "models/test-person/person";

@autoinject()
export class PersonTagsModal {

  public person: Person;

  constructor(
    private dialogController: DialogController,
  ) { }

  public activate(person: Person) {
    this.person = person;
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();
  }
}
