import { ResponseOption } from './response-option';
import { QuestionType } from './questionType';
import { QuestionUse } from './questionUse';

export class Question {
  id: number;
  questionType: QuestionType;
  questionUse: QuestionUse;
  order: number;
  code: string;
  formulation: string;
  isMandatory: boolean = true;  
  isDisable: boolean = false;
  languageId?: number;
  hasRandomResponses?: boolean;
  minNumberOfAnswers?: number;
  maxNumberOfAnswers?: number;
  allowOneResponseByColumn?: boolean;
  allowOneResponseByRow?: boolean;
  allowRandomColumns?: boolean;
  isTextSizeAvailable?: boolean ;
  textSize?: number;
  textSizeMin?: number;
  textSizeMax?: number;
  questionaryId: number;
  isOriginal: boolean;
  responseOptions: Array<ResponseOption> = new Array<ResponseOption>();
}
