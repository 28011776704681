
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class UserListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("UserListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./question-list"),
        name: "questions"
      },

      {
        route: ":questionId/:isTranslation/:languageId",
        moduleId: PLATFORM.moduleName("./question-child-router"),
        name: "question-child-router",
      },

      {
        route: "create",
        name: "question-create-type-selection",
        moduleId: PLATFORM.moduleName("./question-type-selection"),
        nav: false,
        title: this.i18n.tr("main.admin.pages.questionaries.questions.create_info"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.QuestionaryUpdate]
          }
        }
      },
      
      {
        route: ["create/:questionTypeId"],
        name: "question-create",
        moduleId: PLATFORM.moduleName("./question-child-router"),
        nav: false,
        title: this.i18n.tr("main.admin.pages.questionaries.questions.create"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.QuestionaryUpdate]
          }
        }
      },

    ]);
  }
}
