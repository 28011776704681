
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";

@autoinject
export class NewsListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor() {
    this.logger = LogManager.getLogger("ServiceRatingListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./service-rating-list"),
        name: "service-rating-list",
      },

      {
        route: "service-rating",
        moduleId: PLATFORM.moduleName("./service-rating-child-router"),
        name: "service-rating-child-router",
      },

    ]);
  }
}
