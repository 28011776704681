
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class CompanyGroupFranchiseListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("CompanyGroupFranchiseListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./company-group-franchises"),
        name: "select-franchise",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.company_groups.select_franchise"),
      },

      {
        route: "franchise/:franchiseId",
        moduleId: PLATFORM.moduleName("./company-group-list-child-router"),
        name: "company-group-list-child-router",
        nav: false,
        title: this.i18n.tr("main.admin.pages.company_groups.title"),
      },
      
    ]);
  }
}
