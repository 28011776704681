import { Language } from './../../../../../models/localizations/language';
import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { State } from 'common/store/store-model';
import { Company } from 'models/companies/company';
import { CompanyDiscProfileService } from 'main/admin/services/test-management/company-disc-profile-service';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, ValidationController, ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { CompanyDiscProfile } from 'models/test-management/disc-profiles/company-disc-profile';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';


@autoinject()
export class CompanyDiscProfileList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  public languageList: Language[] = [];
  public selectedLanguageId: number;
  public languageId: number;
  
  private state: State;
  private isLoaded: boolean = false;

  private model: CompanyDiscProfile = new CompanyDiscProfile();
  private validationController: ValidationController;

  constructor(
    private store: StoreService,
    private companyDiscProfileService: CompanyDiscProfileService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    private coreLanguageService: CoreLanguageService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CompanyDiscProfileList");

    this.state = this.store.state;
 

    
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("d").displayName("main.admin.pages.test_management.disc_profiles.names.D").required()
      .ensure("i").displayName("main.admin.pages.test_management.disc_profiles.names.I").required()
      .ensure("s").displayName("main.admin.pages.test_management.disc_profiles.names.S").required()
      .ensure("c").displayName("main.admin.pages.test_management.disc_profiles.names.C").required()
      .on(this.model);
  }

  private async activate(params: any): Promise<void> {

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
            return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  public setUserLanguage() {    
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {      
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languageList.find((lg: { culture: string; }) => lg.culture === activeCulture);
      if (activeLang) {
        this.languageId = activeLang.id;       
      }
    }
  }

  public async changeLanguage (){        
    this.model = await this.companyDiscProfileService.getDiscByLanguage(this.languageId);
  }

  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    try {

      if (!isNaN(this.params.companyId)) {

        this.companyId = +this.params.companyId;
        this.languageList = await this.coreLanguageService.getAll();
        this.setUserLanguage();
        if (this.companyId) {

          this.companyDiscProfileService.client.currentCompanyId = this.companyId;

          this.company = this.state.companies.find(x => x.id == this.companyId);

          this.isLoaded = true;


          // this.model = await this.companyDiscProfileService.get();

          this.model = await this.companyDiscProfileService.getDiscByLanguage(this.languageId);
          this.configureValidationRules();
        }

      }
    }
    catch (error) {
      this.notification.error(error);
    };
  }
  

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.companyDiscProfileService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");

    return await this.companyDiscProfileService.update(this.languageId, this.model)
      .then(async () => {
        this.notification.success("main.admin.pages.test_management.disc_profiles.messages.edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("test-management-home");
        }

      })
      .catch((error) => {
        this.notification.error(error);
      });

  }

}
