
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";

@autoinject
export class NewsListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor() {
    this.logger = LogManager.getLogger("NewsListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./news-list"),
        name: "news-list",
      },

      {
        route: "news/:newsId",
        moduleId: PLATFORM.moduleName("./news-child-router"),
        name: "news-child-router",        
      },

    ]);
  }
}
