import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration, RouteConfig } from "aurelia-router";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult } from "aurelia-dialog";
import { ArchetypeService } from "../../services/archetypes/archetype-service";
import { Archetype } from "models/archetypes/archetype";
import { Notification } from "common/services/notification";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { ArchetypeModal } from './archetype-modal';
import { ArchetypeEdit } from './archetype-edit';
import { archetypeType } from "models/archetypes/archetypeType";
import { ChangeStateConfirmation } from "../../../components/change-state-confirmation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject
export class ArchetypeList {
  private logger: Logger;
  private parameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableParameters> => {
    this.logger.debug("refreshing");
    try {
      const response = await this.archetypeService.list(this.parameters);
      return response;
    }
    catch (error) {
      return this.notification.error(error);
    }
  }

  constructor(
    private router: Router,
    private archetypeService: ArchetypeService,
    private dialogService: DialogService,
    private notification: Notification) {
    this.logger = LogManager.getLogger("ArchetypeList");
  }

  private async bind(): Promise<any> {
    this.logger.debug("databind");
    // return await this.archetypeService.getAll();
    return await this.archetypeService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.totalRecords = response.totalRecords;
        this.parameters.tableData = response.data;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async databind(): Promise<any> {
    this.logger.debug("bind");
    return await this.archetypeService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async create(): Promise<any> {
    let item: Archetype = new Archetype();

    return await this.dialogService.open({
      viewModel: ArchetypeModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        // this.logger.debug("response.output", response.output);
        try {
          let model: Archetype = await this.archetypeService.create(item);
          item.id = model.id;
          this.parameters.tableData.unshift(model);
          this.parameters.totalRecords++;
          this.notification.success("main.admin.pages.archetypes.notifications.added");

        } catch (error) {
          this.notification.error(error);

        }
      }
    });
  }

  public async edit(item: Archetype): Promise<void> {
    return await this.dialogService.open({
      viewModel: ArchetypeEdit,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        try {
          await this.archetypeService.update(item);
          this.notification.success("main.admin.pages.archetypes.notifications.edited");
        } catch (error) {
          this.notification.error(error);
          // await this.databind();
        }
      }
      // return await this.databind();
    });
  }
  public async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.archetypeService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.archetypes.notifications.deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  public async changeState(item: Archetype): Promise<void> {
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.archetypeService.changeState(item.id)
          .then(() => {
            // this.databind();
            // item.isActive = !item.isActive;
            this.notification.success("main.admin.pages.archetypes.notifications.edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        item.isActive = !item.isActive;
      }
    });
  }

}
