import { MessageAgreement } from 'models/messages/message-agreement';
import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";


import { MessageAgreementService } from "../../services/messages/message-agreement-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";


import { Subscription } from "aurelia-event-aggregator";

@autoinject()
export class MessageFranchiseAgreement {

  private logger: Logger;
  private model: MessageAgreement;
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;
  private expreRegular: RegExp;

  constructor(
    private router: Router,
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private messageAgreementService: MessageAgreementService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("MessageFranchiseAgreement");
    this.expreRegular = /^[a-zA-Z0-9_\-\.~]{2,}@[a-zA-Z0-9_\-\.~]{2,}\.[a-zA-Z]{2,15}((;\s?){1}[a-zA-Z0-9_\-\.~]{2,}@[a-zA-Z0-9_\-\.~]{2,}\.[a-zA-Z]{2,15})*?$/;
  }


  public async activate(params: any, routeConfig: RouteConfig): Promise<void> {
    // this.logger.debug("Activate franchiseId:", params.franchiseId);
    if (params.franchiseId) {
      try {
        this.model = await this.messageAgreementService.getById(+params.franchiseId);
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("draft").displayName("main.admin.pages.messages.franchise.draft").matches(this.expreRegular).required()
      .ensure("review").displayName("main.admin.pages.messages.franchise.review").matches(this.expreRegular).required()
      .ensure("forwarded").displayName("main.admin.pages.messages.franchise.forwarded").matches(this.expreRegular).required()
      .ensure("cancelled").displayName("main.admin.pages.messages.franchise.cancelled").matches(this.expreRegular).required()
      .ensure("valid").displayName("main.admin.pages.messages.franchise.valid").matches(this.expreRegular).required()
      .ensure("inWarranty").displayName("main.admin.pages.messages.franchise.inWarranty").matches(this.expreRegular).required()
      .ensure("datesModification").displayName("main.admin.pages.messages.franchise.datesModification").matches(this.expreRegular).required()
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.messageAgreementService.client.isRequesting;
  }

  public async save(): Promise<any> {
    return await this.messageAgreementService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!this.saveAndContinue) {
          // return this.router.parent.navigate(`${this.model.franchiseId}`);
          return await this.router.navigateToRoute("messages-franchise-main");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
}
