import { ViewEngineHooks, View } from 'aurelia-framework';
import { PositionQuestionaryType } from './positionQuestionaryType';

export class PositionQuestionaryTypeViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['PositionQuestionaryType'] = PositionQuestionaryType;

    view.overrideContext['PositionQuestionaryTypes'] = 
      Object.keys(PositionQuestionaryType)
        .filter((key) => typeof PositionQuestionaryType[key] === 'number');
  }
}
