import { ViewEngineHooks, View } from 'aurelia-framework';
import { ObserverRole } from './observerRole';

export class ObserverRoleViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['ObserverRole'] = ObserverRole;

    view.overrideContext['ObserverRoles'] = 
      Object.keys(ObserverRole)
        .filter((key) => typeof ObserverRole[key] === 'number');
  }
}
