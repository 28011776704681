
import { LogManager, autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Logger } from 'aurelia-logging';
import { Router, RouterConfiguration } from 'aurelia-router';

import { AuthorizationService } from 'aurelia-permission';
import { I18N } from 'aurelia-i18n';
import PredefinedPermissions from 'config/predefined-permissions';

@autoinject
export class CompanyChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("CompanyChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map([
      {
        route: '',
        moduleId: PLATFORM.moduleName('./company-edit'),
        name: 'company-edit',
        nav: true,
        title: this.i18n.tr("main.admin.pages.companies.company"),
      },

      {
        route: 'agreements',
        moduleId: PLATFORM.moduleName('../agreements/agreement-list-child-router'),
        name: 'company-agreements',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ServiceAgreementView),
        title: this.i18n.tr("main.admin.pages.agreements.title"),
      },

      {
        route: 'contacts',
        moduleId: PLATFORM.moduleName('../contacts/contact-list'),
        name: 'company-contacts',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CompanyUpdate),
        title: this.i18n.tr("main.admin.pages.contacts.title"),
      },

      {
        route: 'password-policy',
        moduleId: PLATFORM.moduleName('./company-password-policy-edit'),
        name: 'company-password-policy',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.PasswordPolicyView),
        title: this.i18n.tr("main.admin.pages.companies.password_policy.title"),
      },

      {
        route: 'settings',
        moduleId: PLATFORM.moduleName('./company-settings-edit'),
        name: 'company-settings',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CompanyUpdate),
        title: this.i18n.tr("main.admin.pages.companies.company_settings.title"),
      },

      {
        route: 'external-activations',
        moduleId: PLATFORM.moduleName('./company-external-activations-edit'),
        name: 'external-activations',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CompanyUpdate),
        title: this.i18n.tr("main.admin.pages.companies.external_activations.title"),
      },

      // {
      //   route: "groups",
      //   moduleId: PLATFORM.moduleName("../groups/group-list-child-router"),
      //   name: "company-user-groups",
      //   nav: true,
      //   title: this.i18n.tr("main.admin.pages.groups.title"),
      // },

      // {
      //   route: "profiles",
      //   moduleId: PLATFORM.moduleName("../profiles/profile-list-child-router"),
      //   name: "company-profiles",
      //   title: this.i18n.tr("main.admin.pages.profiles.title"),
      //   nav: true,
      // },

      // {
      //   route: "users",
      //   moduleId: PLATFORM.moduleName("../users/user-list-child-router"),
      //   name: "company-users",
      //   title: this.i18n.tr("main.admin.pages.users.title"),
      //   nav: true,
      // },

    ]);
  }
}
