import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { QuestionarySheet } from "models/test-management/questionary-groups/questionary-sheet";
import { Sheet } from "models/test-core/sheets/sheet";

@autoinject()
export class QuestionarySheetService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("QuestionarySheetService");
  }

  public async list(search: string): Promise<QuestionarySheet[]> {
    return await this.client.post("questionarysheet/list", { search })
      .then((response: any) => {
        return <QuestionarySheet[]>response;
      });
  }

  public async getSheetsByQuestionary(questionaryId: number): Promise<Sheet[]> {
    return await this.client.getById("questionarysheet", questionaryId)
      .then((response: any) => {
        return <Sheet[]>response;
      });
  }

}
