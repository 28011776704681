import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

@autoinject()
export class RestoreConfirmation {
  private name: string;

  constructor(private dialogController: DialogController) {
    this.name = null;
  }

  public activate(name: string) {
    this.name = name;
  }
}
