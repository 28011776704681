import config from 'config.js';
import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { State } from 'common/store/store-model';

@autoinject()
export class NavBar {

  private logger: Logger;

  private manageUserUrl: string = "";
  private micrositeUrl: string = "";

  @bindable()
  public state: State;

  constructor() {

    this.logger = LogManager.getLogger('NavBar');

    let backendUrl = config.URLS_BACKEND;
    this.manageUserUrl = backendUrl + "/user";

    let micrositeUrl = config.URLS_MICROSITE;
    this.micrositeUrl = micrositeUrl;
  }

  public async attached() {
    // set an event when the user click the button logout and delete sessionStorage
    let buttons = document.getElementsByTagName('button')
    // TODO - best way to get that button
    buttons[2].addEventListener('click', () => sessionStorage.clear())


    // this.logger.debug("attached");
    // return await this.sessionService.getUser()
    //   .then(async (user: User) => {
    //     this.user = user;
    //     this.displayName = this.user.profile.basic_info.fullName;
    //   });
  }

  // public async switchLanguage(language: Language): Promise<any> {
  //   this.selectedLanguage = language;
  //   return await this.i18n
  //     .setLocale(this.selectedLanguage.culture);
  // }

  // selectModule(moduleName: string){
  //   this.router.navigate(moduleName);
  // }

}
