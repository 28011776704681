import { Router } from "aurelia-router";
import { bindable } from "aurelia-framework";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { CustomCompanyQuestionService } from "main/admin/services/questionaries/custom-company-question-service";
import { QuestionType } from "models/questions/questionType";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { DeleteConfirmation } from "../../../../../components/delete-confirmation";
import { State } from "common/store/store-model";
import { Company } from "models/companies/company";
import { StoreService } from "common/store/store-service";

@autoinject()
export class QuestionList {

  @bindable public questionaryId: number;

  private logger: Logger;
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  
  private params: any = {};
  public companyId: number;
  public company: Company;
  
  private isLoaded: boolean = false;
  private state: State;

  constructor(
    private store: StoreService,
    private dialogService: DialogService,
    private customCompanyQuestionService: CustomCompanyQuestionService,
    private router: Router,
    private notification: Notification,
    public questionType: QuestionType) {

    this.logger = LogManager.getLogger("QuestionList");

    this.state = this.store.state;

  }

  private async activate(params: any): Promise<void> {

    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }



    this.logger.debug("activated this.questionaryId", params.questionaryId);
    if (!isNaN(params.questionaryId)) {
      this.questionaryId = +params.questionaryId;
    }
  }

  private async bind(): Promise<any> {

    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;
      if (this.companyId) {
        this.customCompanyQuestionService.client.currentCompanyId = this.companyId;
        this.company = this.state.companies.find(x => x.id == this.companyId);
        this.isLoaded = true;
        return await this.databind();
      }
    }


  }

  public async databind(): Promise<void> {

    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    return this.customCompanyQuestionService.list(this.questionaryId, this.parameters);
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.customCompanyQuestionService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.questionaries.questions.question_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

}
