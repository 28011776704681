import { AuthorizationService } from 'aurelia-permission';

import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class ArchetypeChildRouteer {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {

    this.logger = LogManager.getLogger("ArchetypeChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./localizations/archetype-localization-list"),       
        name: "archetype-localization-list",
        title: this.i18n.tr("main.admin.pages.archetypes.localization_card_title"),
      },      

    ]);
    this.router = router;
  }
}
