
import { View } from 'aurelia-framework';
import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { MessageSummaryReport } from "models/messages/message-summary-report";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { MessageSummaryService } from '../../services/messages/message-summary-service';
import { Company } from 'models/companies/company';
import { StoreService } from 'common/store/store-service';
import { State } from 'common/store/store-model';


@autoinject()
export class MessageSummary {

  private logger: Logger;
  private params: any = {};

  private companyId: number;
  private company: Company;

  private model: MessageSummaryReport = new MessageSummaryReport();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private messageSummaryService: MessageSummaryService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("MessageSummary");

    this.state = this.store.state;
  }


  public async activate(params: any, routeConfig: RouteConfig, view: View): Promise<void> {
    this.params = params;
  }

  public async bind(): Promise<any> {
    try {
      this.companyId =  +this.params.companyId;

      this.messageSummaryService.client.currentCompanyId = +this.params.companyId;
      this.company = this.state.companies.find(x => x.id == this.companyId);

      this.model = await this.messageSummaryService.get();
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("isConsumption").displayName("main.admin.pages.messages.isConsumption").required()
      .ensure("consumptionPeriod").displayName("main.admin.pages.messages.messagePeriod").required().when(x => this.model.isConsumption)
      .ensure("isPending").displayName("main.admin.pages.messages.isConsumption").required()
      .ensure("pendingPeriod").displayName("main.admin.pages.messages.messagePeriod").required().when(x => this.model.isPending)
      .ensure("isActiveUser").displayName("main.admin.pages.messages.isConsumption").required()
      .ensure("activeUserPeriod").displayName("main.admin.pages.messages.messagePeriod").required().when(x => this.model.isActiveUser)
      .ensure("isActivityDelivery").displayName("main.admin.pages.messages.isConsumption").required()
      .ensure("activityDeliveryPeriod").displayName("main.admin.pages.messages.messagePeriod").required().when(x => this.model.isActivityDelivery)
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.messageSummaryService.client.isRequesting;
  }

  public async save(): Promise<any> {
    try {
      await this.messageSummaryService.update(this.model)

      this.notification.success("notifications.item_edited");

      if (!this.saveAndContinue) {
        return await this.router.navigateToRoute("messages");
      }
    }
    catch (error) {
      this.notification.error(error);
    }

  }

  public filter(hasChanged: boolean, ban: number): void {
    if (!hasChanged) {
      switch (ban) {
        case 1:
          this.model.consumptionPeriod = null;
          break;
        case 2:
          this.model.pendingPeriod = null;
          break;
        case 3:
          this.model.activeUserPeriod = null;
          break;
        case 4:
          this.model.activityDeliveryPeriod = null;
          break;
      }
    }
  }
}
