import { CompanyAppHttpClient } from 'common/services/company-app-http-client';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AgreementTermsOfPay } from 'models/agreements/agreementTermsOfPay';

@autoinject()
export class AgreementTermsOfPayService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("AgreementTermsOfPay");
  }

  public async getById(agreementId: number): Promise<AgreementTermsOfPay> {
    return await this.client.getById("agreementTermsOfPay", agreementId)
      .then((response: any) => {
        return <AgreementTermsOfPay>response;
      });
  }

  public async create(model: AgreementTermsOfPay): Promise<AgreementTermsOfPay> {
    return await this.client.create("agreementTermsOfPay", model)
      .then((response: any) => {
        return <AgreementTermsOfPay>response;
      });
  }

  public async update(model: AgreementTermsOfPay): Promise<void> {
    return await this.client.updateById("agreementTermsOfPay", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("agreementTermsOfPay", id)
      .then(() => null);
  }
}
