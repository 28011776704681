import { ViewEngineHooks, View } from 'aurelia-framework';
import { DiscType } from './discType';

export class DiscTypeModeViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['DiscType'] = DiscType;

    view.overrideContext['DiscTypes'] = 
      Object.keys(DiscType)
        .filter((key) => typeof DiscType[key] === 'number');
  }
}
