import { TestPlanItemType } from './../../../../../models/plans/testPlanItemType';
import { TestPlanItem } from 'models/plans/test-plan-item';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Questionary } from "models/questionaries/questionary";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { ReportTemplate } from 'models/test-core/report-templates/report-template';
import { PlanQuestionaryService } from '../../../services/plans/plan-questionary-service';
import { PlanReportService } from '../../../services/plans/plan-report-service';
import { PlanSheetService } from '../../../services/plans/plan-sheet-service';
import { Sheet } from 'models/test-core/sheets/sheet';
import { PlanService } from 'main/admin/services/plans/plan-service';
import { Plan } from 'models/plans/plan';

@autoinject()
export class TestPlanItemModal {

  private model: TestPlanItem = new TestPlanItem();
  private questionaries: Questionary[] = [];
  private reports: ReportTemplate[] = [];
  private sheets: Sheet[] = [];
  private plan: Plan;
  private questionariesFilter: String;
  private searchQuestionaries: Questionary[];

  private validationController: ValidationController;
  private logger: Logger;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private planQuestionaryService: PlanQuestionaryService,
    private planReportService: PlanReportService,
    private planSheetService: PlanSheetService,
    private planService: PlanService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("TestPlanItemModal");
  }

  private async activate(model: TestPlanItem): Promise<any> {
    this.logger.debug("model", model);
    this.model = model;
    this.configureValidationRules();   
    this.plan = await this.planService.getById(this.model.planId)
    
  }

  configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("testPlanItemType").displayName("main.admin.pages.plans.test_plan_items.test_plan_item_type").required()
      .ensure("questionaryId").displayName("main.admin.pages.plans.test_plan_items.questionary_report_sheet").required()
      .ensure("reportId").displayName("main.admin.pages.plans.test_plan_items.questionary_report_sheet").required()
      .ensure("sheetId").displayName("main.admin.pages.plans.test_plan_items.questionary_report_sheet").required()
      .ensure("spendMode").displayName("main.admin.pages.plans.test_plan_items.spend_mode").required()
      .ensure("visualizationType").displayName("main.admin.pages.plans.selection_plan_items.visualization_type").required()
      .ensure("talentsPrice").displayName("main.admin.pages.plans.test_plan_items.talentsPrice").required().matches(/^[0-9]*$/)
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    // this.validationController.reset();
    return await this.dialogController.ok();
  }

  async GetItemTypeInfo(item: TestPlanItemType){
    try {  
      if (!this.plan.isRobotHunter) {
        if (item === TestPlanItemType.Report) {
          this.reports = await this.planReportService.getMissingByPlan(this.model.planId);
        } else if (item === TestPlanItemType.Questionary) {
          this.questionaries = await this.planQuestionaryService.getMissingByPlan(this.model.planId);
          this.searchQuestionaries = this.questionaries;
        } else if (item === TestPlanItemType.Sheet) {
          this.sheets = await this.planSheetService.getMissingByPlan(this.model.planId);
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  }

  private async OnChangeQuestionariesGroup() {
    if (this.questionariesFilter.length > 0) {
      this.searchQuestionaries = this.questionaries.filter(q => q.name.toLowerCase().includes(this.questionariesFilter.toLowerCase()));
      this.model.questionaryId = this.searchQuestionaries[0].id;
    }
    else if (this.questionariesFilter == null || this.questionariesFilter.length == 0) {
      this.searchQuestionaries = [];
      this.searchQuestionaries = this.questionaries;
    }
  }
}
