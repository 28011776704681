import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Group } from "models/groups/group";
import { CoreGroupService } from "../../services/groups/core-group-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject
export class CoreGroupModal {

  private logger: Logger;
  private saveAndContinue: boolean = false;
  private model: Group;
  private validationController: ValidationController;
  private coreGroups: Group[];

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory,
    private coreGroupService: CoreGroupService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CoreGroupModal");

  }

  public bind(): void {
    this.coreGroupService.getAll().then(coreGroups => this.coreGroups = coreGroups);
  }

  public activate(model: Group): void {
    this.model = model;
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.core_groups.name").required()
      .ensure("auxGroupId").displayName("main.admin.pages.core_groups.core_group_to_clone").required()
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    // this.saveAndContinue = saveAndContinue;
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(saveAndContinue);
        }
      });
  }

  public async save(saveAndContinue: boolean): Promise<any> {
    this.logger.debug("save:", saveAndContinue);
    this.validationController.reset();
    return await this.dialogController.ok(saveAndContinue);
  }
}
