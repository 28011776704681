import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable-pagination";
import { Notification } from "common/services/notification";
import { PositionService } from "../../test/services/position/position-service";
import { Report } from "models/test-reports/reports/report";
import { Parameter } from "models/test-reports/reports/parameter";
import { Position } from "models/test-position/position";

interface ItemAvailable {
  [id: number]: boolean;
}
@autoinject()
export class SelectPositionList {

  private logger: Logger;

  @bindable
  private report: Report;

  @bindable
  private parameterCurrent: Parameter;

  @bindable
  private selectedAmount: number;

  private selectedCurrent: number;
  private banCheck: boolean;
  private banIds: number[] = [];

  private itemAvailable: ItemAvailable = {};

  private items: any[] = [];

  private auxItems: any[] = [];

  private selectionLabel: string;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private isReferencePosition: boolean = false;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    
    if (!this.isReferencePosition){
      return await this.positionService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.checkAmount();        
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
    }
    else{
      return await this.positionService.listPositionReference(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.checkAmount();        
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
    }
  }

  constructor(
    private positionService: PositionService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("SelectPositionList");
    this.selectedCurrent = 0;
    this.banCheck = false;

    this.parameters.pageSize = 20;
  }

  private async attached(): Promise<any> {

    if (this.report.companyId) {
      this.positionService.client.currentCompanyId = this.report.companyId;
      return await this.databind();
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }


  private getSelectionLabel(): void {
    this.selectionLabel = '';
    this.items.forEach(x => {
      if(this.selectionLabel !== ''){
        this.selectionLabel += ', ';
      }
      this.selectionLabel += x.name;
    });
  }

  public async selectParameter(item: Position): Promise<void> {

    let banGeneral = this.banIds.findIndex(y => y === item.id);
    
    if (banGeneral == -1) {
      this.banIds.push(item.id);
      this.selectedCurrent = ++this.selectedCurrent;
      this.items.push(item);
    }
    else {
      this.banIds.splice(banGeneral, 1);
      this.selectedCurrent = --this.selectedCurrent;
      this.items.splice(banGeneral, 1);
    }

    this.checkAmount();


    switch (this.parameterCurrent) {
      case Parameter.Position:
      case Parameter.ZeroOrOnePosition: {
        this.report.positionId = this.items.map(x => x.id)[0];
        break;
      }
      case Parameter.Positions: {
        this.report.positionIds = this.items.map(x => x.id);
        break;
      }
      case Parameter.CompareToPosition: {
        this.report.compareToPositionId = this.items.map(x => x.id)[0];
        break;
      }
      default: {
        break;
      }
    }
    this.getSelectionLabel();

  }

  private checkAmount(){
    if (this.selectedCurrent < +this.selectedAmount) {
      this.banCheck = false;
      this.parameters.tableData.forEach(x => {
        this.itemAvailable[x.id] = false;
      });

    } else {
      this.banCheck = true;
      this.parameters.tableData.forEach(x => {
        let ban = this.banIds.findIndex(y => y === x.id);
        if (ban === -1)
          this.itemAvailable[x.id] = true;
        else
          this.itemAvailable[x.id] = false;
      });
    }
  }
  public async changeList(isCompany: boolean) {

  if (!isCompany)  
      this.isReferencePosition =true;
  else
      this.isReferencePosition = false;
  
   return await this.databind();
  }
}
