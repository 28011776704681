import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { BulkTalentsExport } from "models/talents/bulkTalentsExport";

@autoinject()
export class BulkTalentsExportService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient,
  ) {

    this.logger = LogManager.getLogger("BulkResultsExportService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("bulktalentsexport/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  public async create(model: BulkTalentsExport): Promise<BulkTalentsExport> {
    return await this.client.create("bulktalentsexport", model)
      .then((response: BulkTalentsExport) => {
        return response;
      });
  }
  public async getMediaByBulkActivationId(id: number): Promise<string> {
    return await this.client.get(`bulktalentsexport/get-media-by-bulkTalentExportId/${id}`)
      .then((response: any) => {
        return <string>response;
      });
  }


  
}
