import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Category } from "models/test-core/sheets/category";

@autoinject()
export class CategorySheetService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CategorySheetService");
  }

  public async list(reportTemplateId: number, languageId: number, search: string): Promise<Category[]> {
    return await this.client.post(`categorysheet/list/${reportTemplateId}/${languageId}`, { search })
      .then((response: any) => {
        return <Category[]>response;
      });
  }
}
