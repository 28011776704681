import { ViewEngineHooks, View } from 'aurelia-framework';
// import { viewEngineHooks } from 'aurelia-binding';
import { WhoCanSeeReport } from './whoCanSeeReport';

export class WhoCanSeeReportViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['WhoCanSeeReport'] = WhoCanSeeReport;

    view.overrideContext['WhoCanSeeReports'] = 
      Object.keys(WhoCanSeeReport)
        .filter((key) => typeof WhoCanSeeReport[key] === 'number');
  }
}
