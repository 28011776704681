import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { TeamService } from "main/test/services/team/team-service";
import { TeamActivationService } from "main/test/services/team/team-activation-service";
import { Team } from "models/team/team";
import { Activation } from 'models/test-person/activation';
import { QuestionaryService } from 'main/admin/services/questionaries/questionary-service';
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ItemToQualify } from "models/questionaries/itemToQualify";

@autoinject()
export class TeamActivationCreate {

  private logger: Logger;
  public companyId: number;

  private teamId: number;

  private model: Activation = new Activation();
  private modelTeam: Team = new Team();
  private validationController: ValidationController;
  private questionaries: Questionary[] = [];

  constructor(
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private teamService: TeamService,
    private companyQuestionaryService: QuestionaryService,
    private teamActivation: TeamActivationService

  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("TeamActivationCreate");
    //this.model.tags = "";
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.test.pages.position.position_edit.name").required()
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {
    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      this.teamService.client.currentCompanyId = this.companyId;
      if (!isNaN(params.teamId)) {
        this.teamId = params.teamId;
        this.modelTeam = await this.teamService.getById(this.teamId);

        const questionaries = await this.getQuestionaries();
        this.questionaries = questionaries.filter(itm => (itm.questionaryType === QuestionaryType.NoDisc && itm.itemToQualify === ItemToQualify.Team && itm.isActive === true));
        this.configureValidationRules();
      } else {
        this.configureValidationRules();
      }
    }
  }
  private async getQuestionaries(): Promise<Questionary[]> {
    return await this.companyQuestionaryService.getAll();
  }

  public async submit(keepEditing: boolean, teamId: number): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.create(keepEditing, teamId);
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.teamService.client.isRequesting;
  }

  private async create(keepEditing: boolean, teamId: number): Promise<any> {
    return await this.teamActivation.create(this.model, teamId)
      .then(async (result: Activation) => {
        this.notification.success("notifications.item_edited");
        if (keepEditing) {
          return await this.router.navigateToRoute("team-child-router", { teamId: result.id });
        }
        else {
          return await this.router.navigateToRoute("team-activation-list");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

}
