import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';

import { autoinject, bindable, LogManager, Disposable } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router, NavigationInstruction } from 'aurelia-router';
import { Company } from 'models/companies/company';
import { Role } from 'models/security/role';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { CompaniesLoadedMessage } from 'messages/companiesLoadedMessage';

@autoinject()
export class CompanySelector {

  private logger: Logger;

  @bindable()
  public state: State;

  private rootCompany: Company;

  private level1: string;
  private level2: string;
  private level3: string;
  private level4: string;

  private subscriptions: Disposable[] = [];

  constructor(
    private store: StoreService,
    private router: Router,
    private ea: EventAggregator,
    private coreCompanyService: CoreCompanyService) {

    this.logger = LogManager.getLogger('CompanySelector');

    // this.state = this.store.state;

    // event when router navigation is success
    this.subscriptions.push(this.ea.subscribe('router:navigation:success', this.refresh.bind(this)));

    this.subscriptions.push(this.ea.subscribe(CompaniesLoadedMessage, this.init.bind(this)));
  }

  public async attached(): Promise<any> {

  }

  public unbind() {
    this.subscriptions.forEach(x => x.dispose());
  }

  private async init() {
    // this.logger.debug("init");
    await this.setRootCompany();
    await this.refresh();
  }

  private async refresh() {

    let currentCompanyId: number;
    let instruction: NavigationInstruction = this.router.currentInstruction;

    if (instruction.params && instruction.params.companyId) {
      currentCompanyId = +instruction.params.companyId;
    }

    if (this.state.currentCompany && this.state.currentCompany.id == currentCompanyId) {
      return;
    }

    // this.logger.debug("refresh currentCompanyId:", currentCompanyId);
    return await this.loadCurrentCompany(currentCompanyId);

  }

  private setRootCompany() {
    if (this.state && this.state.userBasicInfo && this.state.companies.length > 0) {
      this.rootCompany = this.state.companies.find(x => x.id === this.state.userBasicInfo.companyId);
      // this.logger.debug("rootCompany", this.rootCompany);
    }
  }

  private async loadCurrentCompany(currentCompanyId: number): Promise<any> {

    if (currentCompanyId && this.state.companies.length > 0) {

      let selectedCompany: Company;

      if (currentCompanyId && this.state.companies.map(x => x.id.toString()).indexOf(currentCompanyId.toString()) < 0) {

        this.logger.debug("companyId does not belong to user");
        // this.switchCompany(this.rootCompany);
        selectedCompany = this.state.companies.filter(x => x.roleId == Role.Customer)[0];

      } else {
        // this.logger.debug("switchCompany to selected by url:", currentCompanyId);
        selectedCompany = this.state.companies.filter(x => x.id == currentCompanyId)[0];
      }

      // validate if selectedCompany is Customer level. User can only select a company with customer role.
      if (selectedCompany.roleId != Role.Customer || !selectedCompany.isActive) {
        selectedCompany = this.state.companies.filter(x => x.roleId == Role.Customer)[0];
      }

      if (selectedCompany) {
        return await this.switchCompany(selectedCompany);
      }
    } else if (!currentCompanyId && this.state.currentCompany) {
      return await this.switchCompany(null);
    }

  }

  private async switchCompany(company: Company): Promise<any> {
    // this.logger.debug("switchCompany company:", company);

    if (!company) {
      return await this.store.setCurrentCompanyState(null);
    }

    if (this.state.currentCompany && this.state.currentCompany.id == company.id) {
      return;
    }

    let settings = await this.coreCompanyService.getSettingsById(company.id);

    // this.logger.debug("settings", settings);

    company.companySettings = settings;

    await this.store.setCurrentCompanyState(company);

    //update router url
    let fragment = this.router.currentInstruction.fragment;

    // this.logger.debug("fragment", fragment);

    if (fragment.length > 1 && fragment.startsWith("/company")) {
      let fragmentParts: string[] = fragment.split("/");
      fragmentParts.splice(2, 1, this.state.currentCompany.id.toString());
      let newFragment: string = fragmentParts.join("/");

      if (fragment !== newFragment) {
        this.router.navigate(newFragment);
      }

    } else {
      return await this.router.navigateToRoute("main-child-router", { companyId: this.state.currentCompany.id });
    }

  }

  // private filterByParent(parentId: number, query: string): Company[] {
  //   // this.logger.debug("filterByParent", parentId);
  //   let result: Company[] = [];
  //   if (parentId) {
  //     if (query) {
  //       result = this.state.companies.filter(x => x.parentId == parentId && x.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 && x.isActive);

  //     } else {
  //       result = this.state.companies.filter(x => x.parentId == parentId && x.isActive);
  //       // this.logger.debug("result:", result);
  //     }
  //   }

  //   // this.logger.debug("filterByParent result", result);
  //   return result;

  // }

}
