
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject
export class CompanyGroupMasterChildRouter {

  private logger: Logger;

  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("CompanyGroupMasterChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./company-group-master-view"),
        name: "company-group-master-view",
        nav: true,
        title: this.i18n.tr("main.admin.pages.company_groups.company_group"),
      },

      {
        route: "agreements",
        moduleId: PLATFORM.moduleName("../agreements/agreement-list-child-router"),
        name: "company-groups-master-agreements",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ServiceAgreementView),
        title: this.i18n.tr("main.admin.pages.agreements.title"),
      },

      // {
      //   route: "contacts",
      //   moduleId: PLATFORM.moduleName("../contacts/contact-list"),
      //   name: "company-group-contacts",
      //   nav: this.authorizationService.isAuthorized(PredefinedPermissions.CoreGroupUpdate),
      //   title: this.i18n.tr("main.admin.pages.contacts.title"),
      // },

      // {
      //   route: "master-users",
      //   moduleId: PLATFORM.moduleName("../master-users/master-user-list-child-router"),
      //   name: "company-groups-master-users",
      //   title: this.i18n.tr("main.admin.pages.master_users.title"),
      //   nav: this.authorizationService.isAuthorized(PredefinedPermissions.MasterUserView),
      // },
    ]);
  }
}
