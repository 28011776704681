import { NoteModal } from './note-modal';
import { ObservationType } from 'models/agreements/observationType';
import { UxDatatableResponse } from 'common/resources/elements/ux/datatable/ux-datatable';
import { AgreementService } from '../../../services/agreements/agreement-service';
import { Notification } from 'common/services/notification';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { ObservationService } from '../../../services/agreements/observation-service';
import { UxDatatableCustomAttribute } from 'common/resources/elements/ux/datatable/ux-datatable';
import { UxDatatableParameters } from 'common/resources/elements/ux/datatable/ux-datatable';
import { Agreement } from 'models/agreements/agreement';

import { Logger } from 'aurelia-logging';
import { autoinject, LogManager } from "aurelia-framework";
import { Observation } from 'models/agreements/observation';

interface SearchParams {
  ObservationType: ObservationType
}

@autoinject()
export class PublicNotesList {
  private logger: Logger;
  public agreementId: number;
  private companyId: number;
  private agreement: Agreement;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private params: any = {};

  private refresh = (): Promise<UxDatatableResponse> => {
    return this.observationService.publicNoteList(this.agreementId, this.parameters);
  }

  constructor(
    private agreementService: AgreementService,
    private observationService: ObservationService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("PublicNoteList");

  }

  public async activate(params: any): Promise<void> {
    this.params = params;
  }

  public async bind(): Promise<any> {
    if (!isNaN(this.params.agreementId)) {
      this.agreementId = +this.params.agreementId;

      if (!isNaN(this.params.companyGroupId)) {
        this.companyId = +this.params.companyGroupId;
      }
      else if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.companyId && this.agreementId) {
        this.agreementService.client.currentCompanyId = this.companyId;
        try {
          this.agreement = await this.agreementService.getById(this.agreementId);
          this.parameters.search = <SearchParams>{ ObservationType: ObservationType.PublicNote };
          return this.databind();
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    }
  }

  public async databind(): Promise<void> {
    this.logger.info("databind");
    return await this.observationService.publicNoteList(this.agreementId, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }



  private async create(): Promise<void> {
    let item: Observation = new Observation();
    item.agreementId = this.agreementId;

    return await this.dialogService.open({
      viewModel: NoteModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        try {
          item = await this.observationService.createPublicNote(item);
          this.parameters.tableData.unshift(item);
          this.parameters.totalRecords++;

          this.notification.success("main.admin.pages.agreements.notes.notifications.added");
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    });
  }

}
