import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { Logger } from "aurelia-logging";
import { I18N } from "aurelia-i18n";
import { LogManager, autoinject } from "aurelia-framework";

@autoinject
export class QuestionaryChild {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("GroupChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([

      // {
      //   route: "create",
      //   name: "questionary-create",
      //   moduleId: PLATFORM.moduleName("./questionary-edit"),
      //   nav: false,
      //   title: this.i18n.tr("main.admin.pages.questionaries.create_card.title"),
      //   settings: {
      //     Permission: {
      //       only: [PredefinedPermissions.QuestionaryCreate]
      //     }
      //   }
      // },

      {
        route: "",
        name: "questionary-edit",
        moduleId: PLATFORM.moduleName("./questionary-edit"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.questionaries.questionary"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.QuestionaryUpdate]
          }
        }
      },

      {
        route: "questions",
        name: "question-list-child-router",
        moduleId: PLATFORM.moduleName("./questions/question-list-child-router"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.questionaries.questions.title"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.QuestionaryUpdate]
          }
        }
      },

      // {
      //   route: "question/create",
      //   name: "question-create",
      //   moduleId: PLATFORM.moduleName("./questions/question-type-selection"),
      //   nav: false,
      //   title: this.i18n.tr("main.admin.pages.questionaries.questions.create"),
      //   settings: {
      //     Permission: {
      //       only: [PredefinedPermissions.QuestionaryUpdate]
      //     }
      //   }
      // },
      // {
      //   route: ["question/create/:questionTypeId", "question/:questionId"],
      //   name: "question-edit",
      //   moduleId: PLATFORM.moduleName("./questions/question-edit"),
      //   nav: false,
      //   title: this.i18n.tr("main.admin.pages.questionaries.questions.edit"),
      //   settings: {
      //     Permission: {
      //       only: [PredefinedPermissions.QuestionaryUpdate]
      //     }
      //   }
      // }

    ]);

    this.router = router;
  }

}
