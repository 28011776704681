import { BulkDownload } from 'models/test-person/bulkDownload';
import { Report } from 'models/test-reports/reports/report';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class BulkDownloadService{
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("BulkDownloadService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`bulkdownload/list`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async create(model: BulkDownload): Promise<BulkDownload> {
      return await this.client.create(`bulkdownload`, model)
      .then((response: any) => {
        return <BulkDownload>response;
      });
    }
  
  public async convertBulkActivation(id: number, model: Report): Promise<boolean> {
      return await this.client.post(`bulkdownload/convert-bulkactivation/${id}`, model)
      .then(() => null);
    }

    public async getGenerationReportCost(model: Report): Promise<number> {
      return await this.client.post(`bulkdownload/get-report-cost`, model)
      .then((response: any) => {
        return <number> response;
      });
    }

    public async regenerateBulkDownload(id: number): Promise<boolean> {
      return await this.client.post(`bulkdownload/re-generate-bulkdownload/${id}`)
      .then(() => null);
    }
}

