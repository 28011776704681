import { Person } from "./person";

export class PersonResume {
  id: number;
  personId: number;
  // person: Person;

  summary: string;
  headline: string;
  location: string;
  positions: string;
  educations: string;
  skills: string;
  honors: string;
  languages: string;
  observations: string;
  linkedInProfileUrl: string;
}
