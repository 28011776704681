import { Router, RouterConfiguration, RouteConfig, NavModel } from 'aurelia-router';
import { relativeToFile } from 'aurelia-path';
import { CompositionEngine, CompositionContext } from 'aurelia-templating';
import { autoinject, PLATFORM, LogManager, Disposable, observable } from 'aurelia-framework';
import { Origin } from 'aurelia-metadata';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Logger } from 'aurelia-logging';
import { I18N } from 'aurelia-i18n';
import { CurrentCompanyChangeMessage } from 'messages/currentCompanyChangeMessage';
import { StoreService } from 'common/store/store-service';

@autoinject()
export class MainChildRouter {

  private router: Router;
  private logger: Logger;

  private navigationMenu: any;

  private subscriptions: Array<Disposable> = [];

  @observable()
  public currentCompanyId: number;

  constructor(
    private store: StoreService,
    private i18n: I18N,
    private compositionEngine: CompositionEngine,
    private ea: EventAggregator
  ) {

    this.logger = LogManager.getLogger('MainChildRouter');

    // this.currentCompanyId = this.store.state.currentCompany.id;

    this.subscriptions.push(this.ea.subscribe('router:navigation:complete', response => { this.updateNavigationMenu(response.instruction, 0) }));

    this.subscriptions.push(this.ea.subscribe(CurrentCompanyChangeMessage, (message: CurrentCompanyChangeMessage) => {
      // this.logger.debug("event companySwitcher: ", message.currentCompanyId);
      this.currentCompanyId = message.currentCompanyId;
    }));

  }

  public async attached() {

    await this.refresh();

  }

  public configureRouter(config: RouterConfiguration, router: Router) {

    this.router = router;

    config.map([
      {
        route: ["", "main-home"],
        moduleId: PLATFORM.moduleName("./pages/home/main-home"),
        name: "main-home",
        nav: true,
        title: this.i18n.tr("main.pages.home.title"),
        settings: {
          icon: "ticon-home"
        }
      },

      {
        route: "test",
        moduleId: PLATFORM.moduleName("./test/test-child-router"),
        name: "test-child-router",
        nav: true,
        title: this.i18n.tr("main.test.pages.home.title"),
        settings: {
          icon: "ticon-modulo-test",
          isModuleTitle: true
        }
      },

      {
        route: "test-reports",
        moduleId: PLATFORM.moduleName("./test-reports/test-report-child-router"),
        name: "test-report-child-router",
        nav: false,
        title: this.i18n.tr("main.test_reports.pages.home.title"),
        settings: {
          icon: "radio_button_checked",
          isModuleTitle: true
        }
      },

      {
        route: "admin",
        moduleId: PLATFORM.moduleName("./admin/admin-child-router"),
        name: "admin-child-router",
        nav: true,
        title: this.i18n.tr("main.admin.pages.home.title"),
        settings: {
          icon: "ticon-modulo-admin",
          isModuleTitle: true
        }
      },

      // {
      //   route: "user-info",
      //   moduleId: PLATFORM.moduleName("./pages/user-info/user-info"),
      //   name: "userInfo",
      //   title: "User Info",
      //   nav: true,
      //   settings: {
      //     t: "main.pages.user_info.title"
      //   }
      // },
    ]);

  }

  public async refresh() {
    this.logger.debug("refreshing...");

    this.navigationMenu = await this.mapNavigation(this.router);
    await this.updateNavigationMenu(this.router.currentInstruction, 0);
  }

  // sidebar 
  public mapNavigation(router: Router, config?: RouteConfig) {
    const promises = [];
    const c = config ? config : { route: null };
    router.navigation.forEach(nav => {
      if (c.route !== nav.config.route) {
        promises.push(this.mapNavigationItem(nav, router));
      } else {
        promises.push(Promise.resolve(nav));
      }

    })
    return Promise.all(promises)
  }

  public async mapNavigationItem(nav: NavModel, router: Router) {

    const config = <any>nav.config
    const navModel: any = Object.assign({}, nav)

    if (config.moduleId) {

      const childContainer = router.container.createChild();
      const instruction = {
        viewModel: relativeToFile(config.moduleId, Origin.get((<any>router.container).viewModel.constructor).moduleId),
        childContainer: childContainer,
        view: config.view || config.viewStrategy,
      };

      let context: CompositionContext = await this.compositionEngine.ensureViewModel(<any>instruction);

      if ('configureRouter' in context.viewModel) {

        const childRouter = new Router(childContainer, router.history);
        const childConfig = new RouterConfiguration();

        context.viewModel.configureRouter(childConfig, childRouter);
        childConfig.exportToRouter(childRouter);

        childRouter.navigation.forEach(nav => {

          let href = `${navModel.href}/${nav.config.href ? nav.config.href : nav.config.route}`;

          // if (this.currentCompanyId) {
          //   let fragmentParts: string[] = href.split("/");
          //   fragmentParts.splice(2, 1, this.currentCompanyId.toString());
          //   href = fragmentParts.join("/");
          // }

          // this.logger.debug("navModel.href", navModel.href);

          nav.href = href; //`${navModel.href}/${nav.config.href ? nav.config.href : nav.config.route}`;

        });

        navModel.navigation = await this.mapNavigation(childRouter, config);

        return navModel;
      }

      return navModel;

    }
    return Promise.resolve(navModel);
  }

  public updateNavModels(navModels, instruction, instructionDepth, resetDepth) {
    if (navModels) {
      navModels.forEach(navModel => {
        if (resetDepth >= instructionDepth)
          navModel.isActive = false;
        if (navModel.href === instruction.config.navModel.href)
          navModel.isActive = true;
        if (navModel.navigation) {
          this.updateNavModels(navModel.navigation, instruction, instructionDepth, resetDepth + 1)
        }
      });
    }
  }

  public updateNavigationMenu(instruction, depth) {
    this.updateNavModels(this.navigationMenu, instruction, depth, 0)
    if ('childNavigationInstruction' in instruction.viewPortInstructions.default) {
      this.updateNavigationMenu(instruction.viewPortInstructions.default.childNavigationInstruction, depth + 1)
    }
  }

  public async currentCompanyIdChanged(newValue, oldValue) {
    // this.logger.debug("currentCompanyIdChanged:", newValue);
    if (newValue) {
      this.currentCompanyId = newValue;
      //base api
      await this.refresh();
    }
  }

  public detached() {
    this.subscriptions.forEach(x => x.dispose());
  }

}
