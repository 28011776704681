import { Logger } from 'aurelia-logging';
import { autoinject, bindable, LogManager, observable } from 'aurelia-framework';
import { Company } from 'models/companies/company';
import { CompanyInfoService } from '../services/companies/company-info-service';
import { Person } from 'models/test-person/person';

@autoinject()
export class CompanyLogo {
  private logger: Logger;

  @bindable
  @observable
  private currentCompany: Company;

  @bindable
  @observable
  private currentPerson: Person;

  private logoUrl: string;


  constructor(
    private companyInfoService: CompanyInfoService,
  ) {

    this.logger = LogManager.getLogger("CompanyLogo");
  }

  public async currentCompanyChanged() {
    if (this.currentCompany) {
      this.logoUrl = await this.companyInfoService.getLogoUrl(this.currentCompany.id);
    }
  }

  public async currentPersonChanged() {
    if (this.currentPerson) {
      this.logoUrl = await this.companyInfoService.getLogoUrl(this.currentPerson.companyId);
    }
  }
}
