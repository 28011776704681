
import { Router, RouteConfig } from "aurelia-router";
import { bindable } from "aurelia-framework";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { QuestionaryService } from '../../services/questionaries/questionary-service';
import { ThematicGroupService } from "../../services/thematic-groups/thematic-group-service";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Questionary } from "models/questionaries/questionary";

@autoinject()
export class ThematicGroupList {

  @bindable
  private questionaryId: number;

  @bindable
  private routeName: string;

  @bindable
  private isAttached: boolean = false;

  private logger: Logger;
  private questionary: Questionary;
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.thematicGroupService.list(this.questionaryId, this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private questionaryService: QuestionaryService,
    private thematicGroupService: ThematicGroupService,
    private dialogService: DialogService,
    private router: Router,
    private notification: Notification) {

    this.logger = LogManager.getLogger("ThematicGroupList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<void> {
    this.logger.info("activate");
    if (!isNaN(params.questionaryId)) {
      this.questionaryId = +params.questionaryId;
    }
  }

  private async bind(): Promise<any> {
    try {
      this.questionary = await this.questionaryService.getById(this.questionaryId);
      return await this.databind();
    }
    catch (error) {
      return this.notification.error(error);
    }
  }

  public async databind(): Promise<void> {
    this.logger.info("databind");
    return await this.thematicGroupService.list(this.questionaryId, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async select(thematicGroupId: number): Promise<any> {
    this.logger.debug("navigating to thematicGroupId:", thematicGroupId);
    return await this.router.navigateToRoute(this.routeName, { thematicGroupId: thematicGroupId });
  }

  public async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.thematicGroupService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.thematic_groups.messages.thematic_group_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

}
