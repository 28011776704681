import { autoinject, bindable, LogManager } from "aurelia-framework";
import { Person } from "models/test-person/person";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { PersonTagsModal } from "./person-tags-modal";
import { Logger } from "aurelia-logging";
import { PersonService } from "main/test/services/people/person-service";
import { Notification } from "common/services/notification";

@autoinject()
export class PersonTags {
  private logger: Logger;

  @bindable()
  public person: Person;

  constructor(
    private dialogService: DialogService,
    private notification: Notification,
    private personService: PersonService,

  ) {
    this.logger = LogManager.getLogger("PersonCreate");
  }

  private async edit(): Promise<any> {
    return await this.dialogService.open({
      viewModel: PersonTagsModal,
      model: this.person
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        await this.personService.updateTags(this.person.id, this.person.tags);
        this.notification.success("notifications.item_edited");
      }
    }).catch(error => {
        this.notification.error(error);
      });
  }

}
