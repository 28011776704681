import { DialogController, DialogService, DialogCloseResult } from 'aurelia-dialog';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { OrganizationService } from "main/test/services/organization/organization-service";
import { Organization } from "models/test-organization/organization";
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { FilteredReminderConfirmationModal } from '../people/filtered-reminder-confirmation-modal';

@autoinject()
export class OrganizationList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('organizationListSearch', search == null ? '' : search);

    return await this.organizationService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private organizationService: OrganizationService,
    private notification: Notification
  ) {

    this.logger = LogManager.getLogger("OrganizationList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.parameters.search.Query = sessionStorage.getItem('organizationListSearch');
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.logger.debug("activate companyId:", this.params.companyId);
      this.companyId = +this.params.companyId;

      this.organizationService.client.currentCompanyId = this.companyId;

      return await this.databind();

    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async delete(item: Organization): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.organizationService.delete(item.id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.notification.success("notifications.item_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });

      }
    });
  }

  private async clearFilters() : Promise<any> {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
    await this.databind();
  }

  private async sendFilteredReminder(): Promise<boolean| DialogCloseResult> {
    return await this.dialogService.open({
      viewModel: FilteredReminderConfirmationModal,
      model: this.parameters.tableData.length
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.organizationService.sendRemindersByFilter(this.parameters)
          .then(() => {
            this.notification.success("main.test.pages.person.send_reminder_list")
            return true;
          });
      }
    });
  }

  private async sendReminder(id: number): Promise<boolean> {
    try {
      await this.organizationService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

}
