import { Tests } from './tests';

export class BulkResultsExport {
 resultsExportQuestionary: Tests;
 questionaryByCompanyName: string;
 questionaryByCompanyId: number;
 totalResults:number;
 logFileId:number; 
 tags: string;  
 startDate?: Date;
 endDate?: Date;
 companyId: number;  
 usersIds : number[];
}
