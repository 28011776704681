
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult } from "aurelia-dialog";
import { LocalizationModal } from "./localization-modal";
import { LocalizationService } from "../../../services/localizations/localization-service";
import { LanguageService } from "../../../services/localizations/language-service";
import { Localization } from "models/localizations/localization";
import { Notification } from "common/services/notification";
import { UxDatatableResponse, UxDatatableCustomAttribute, UxDatatableParameters } from "common/resources/elements/ux/datatable/ux-datatable";
import { Language } from "models/localizations/language";

@autoinject()
export class LocalizationList {

  private logger: Logger;
  private params: any;

  private parameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private languageId: number;
  private language: Language;

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    return this.localizationService.list(this.languageId, this.parameters);
  }

  constructor(
    private localizationService: LocalizationService,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("LocalizationList");
  }

  private async activate(params: any): Promise<any> {
    this.params = params;
  }

  private async bind(): Promise<any> {

    if (!isNaN(this.params.languageId)) {
      this.languageId = +this.params.languageId;
      
      try {
        this.language = await this.languageService.getById(+this.params.languageId);
        return await this.databind();
      }
      catch (error) {
        this.notification.error(error);
      }
    }

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async databind(): Promise<void> {
    this.logger.info("databind");

    return await this.localizationService.list(this.languageId, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.totalRecords = response.totalRecords;
        this.parameters.tableData = response.data;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  public async edit(item: Localization): Promise<void> {
    this.logger.info("edit");

    return await this.dialogService.open({
      viewModel: LocalizationModal,
      model: item
    }).whenClosed(async (response) => {
      if (!response.wasCancelled && response.output) {
        if (item.id !== 0) {
          return await this.localizationService.update(item)
            .then(() => {
              this.notification.success("main.admin.pages.localizations.notifications.edited");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
        else {
          return await this.localizationService.create(item)
            .then(() => {
              this.notification.success("main.admin.pages.localizations.notifications.edited");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      }
    });
  }
}
