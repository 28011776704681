
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class BulkResultsExportChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("BulkResultsExportChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./bulk-results-export-list"),
        name: "bulk-results-export-list",
        nav: true,
        title: this.i18n.tr("main.test.pages.people.person"),
      },
      {
        route: "create",
        moduleId: PLATFORM.moduleName("./bulk-results-export-create"),
        name: "bulk-results-export-create",
         nav: true,
        title: this.i18n.tr("main.test.pages.bulk_activation.page_title"),
      },
      
    ]);
  }
}
