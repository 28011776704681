import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { Questionary } from 'models/questionaries/questionary';
import { Sheet } from "models/test-core/sheets/sheet";

@autoinject()
export class PlanSheetService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("PlanSheetService");
  }

  public async getMissingByPlan(planId: number): Promise<Sheet[]> {
    return await this.client.get(`plansheet/get-missing-by-plan/${planId}`)
      .then((response: Sheet[]) => {
        return response;
      })
  }
}
