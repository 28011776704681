import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { autoinject, LogManager, bindable, Disposable, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableCustomAttribute, UxDatatableParameters, UxDatatableResponse } from 'common/resources/elements/ux/datatable/ux-datatable';
import { Notification } from "common/services/notification";
import { forEach } from 'lodash';
import { ActivationQuestionaryService } from 'main/test/services/people/activation-questionary-service';
import { TeamPersonService } from 'main/test/services/team/team-person-service';
import { Parameter } from 'models/test-reports/reports/parameter';
import { Report } from "models/test-reports/reports/report";
import { ReportQuestionaryTeam } from 'models/test-reports/reports/reportQuestionaryTeam';


@autoinject()
export class SelectQuestionaryTeamList {

  private logger: Logger;
  private params: any = {};

  @bindable
  @observable
  private report: Report;

  @bindable
  private parameterCurrent: Parameter;

  private sumWeight: number;

  private teamId: number;

  //private items: ReportQuestionaryTeam[] = [];

  private teamParameters: UxDatatableParameters = new UxDatatableParameters();

  private parameters: UxDatatableParameters = new UxDatatableParameters();


  constructor(
    private notification: Notification,
    private activationQuestionaryService: ActivationQuestionaryService,
    private teamPersonService: TeamPersonService,
  ) {
    this.logger = LogManager.getLogger("SelectQuestionaryTeamList");
    this.sumWeight = 0;
    this.parameters.search.PersonIds = [];
  }

  private teamRefresh = async (): Promise<UxDatatableResponse> => {
    this.logger.debug("refreshing team");
    return await this.teamPersonService.list(this.teamParameters, this.teamId);
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.activationQuestionaryService.getquestionnaires(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async bind(): Promise<any> {
    if (this.report.teamId) {
      this.teamId = this.report.teamId
      this.activationQuestionaryService.client.currentCompanyId = this.report.companyId;
      this.teamPersonService.client.currentCompanyId = this.report.companyId;

      await this.teamDatabind();

      await this.databind();

      this.updateTotal();

      this.notification.warning('main.test_reports.pages.reports.parameters.ReportQuestionaryTeam_notification');
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        if (response) {
          this.parameters.tableData = response.data;
          this.parameters.totalRecords = response.totalRecords;
          this.parameters.tableData.forEach(questionary => {
            let newReportQuestionaryTeam: ReportQuestionaryTeam = {
              reportId: Number(this.report.questionariesTeam.length),
              questionaryId: questionary.id,
              questionary: questionary,
              weight: 0,
              isAvailable: true,
            };
            this.report.questionariesTeam.push(newReportQuestionaryTeam);
          });
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async teamDatabind(): Promise<void> {
    this.logger.debug("teamDatabind");
    try {
      let response: UxDatatableResponse = await this.teamRefresh();
      this.teamParameters.tableData = response.data;
      this.teamParameters.totalRecords = response.totalRecords;
      this.teamParameters.tableData.forEach(x => {
        this.parameters.search.PersonIds.push(x.personId);
      });
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async updateTotal(): Promise<any> {
    this.sumWeight = 0;
    this.report.questionariesTeam.forEach((reportQuestionaryTeam) => {
      this.sumWeight += Number(reportQuestionaryTeam.weight);
    });

    if (this.sumWeight > 100) {
      this.notification.error('main.test_reports.pages.reports.parameters.Activation_weight_required');
    }
  }

  public async selectParameter(reportQuestionaryTeam: ReportQuestionaryTeam): Promise<void> {
    this.logger.debug("selectParameter");
    let index = this.report.questionariesTeam.findIndex(x => x.questionaryId == reportQuestionaryTeam.questionaryId);

    if (!reportQuestionaryTeam.isAvailable) {
      reportQuestionaryTeam.isAvailable = false;
      reportQuestionaryTeam.weight = 0;
    } else {
      reportQuestionaryTeam.isAvailable = true;
    }
    this.report.questionariesTeam[index] = reportQuestionaryTeam;
    this.updateTotal();
  }

  public notificationInfo(message: string) {
    this.notification.info(message);
  }

}
