import { TransactionService } from '../../services/talents/transaction-service';
import { Wallet } from 'models/talents/wallet';
import { WalletService } from '../../services/talents/wallet-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { Notification } from "common/services/notification";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Company } from "models/companies/company";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';


@autoinject()
export class TalentRemove {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;
  private validationController: ValidationController;
  private model: Wallet[] = [];
  public current_balance: number;
  public remaining_balance: number;
  public balance_removed: number;
  public observationRemove: string;
  public ban: boolean;
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private router: Router,
    // private coreCompanyService: CoreCompanyService,
    private walletService: WalletService,
    private transactionService: TransactionService,
    // private dialogService: DialogService,
    private validationControllerFactory: ValidationControllerFactory,
    private notification: Notification) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.logger = LogManager.getLogger("TalentRemove");

    this.state = this.store.state;


    this.current_balance = 0;
    this.remaining_balance = 0;
    this.balance_removed = 0;
    this.observationRemove = "";
    this.ban = false;


  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("amount").displayName("main.admin.pages.talents.amount").matches(/^[0-9]*$/).required()
      .on(this.model);
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.walletService.balancesList(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.current_balance = 0;
        this.remaining_balance = 0;
        this.balance_removed = 0;
        this.ban = false;
        response.data.forEach(x => this.current_balance += x.talent);
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    //this.params = params;

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async getSum(total, num) {
    return total + num;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.walletService.client.currentCompanyId = this.companyId;
        this.transactionService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  public async calcular(item: Wallet): Promise<void> {

    if (item.canDiscountGeneralWallet) {
      if (item.talent >= item.amount)
        this.ban = true;
      else this.ban = false;

      this.balance_removed = 0;
      this.parameters.tableData.forEach(x => this.balance_removed += parseInt((x.amount == '' || x.amount == null) ? "0" : x.amount));
      this.remaining_balance = this.current_balance - this.balance_removed;

    }
  }

  public async filterLockout(item: Wallet): Promise<void> {

    if (!item.canDiscountGeneralWallet) {
      item.amount = 0;
      this.balance_removed = 0;
      item.observation = "";
      this.parameters.tableData.forEach(x => this.balance_removed += parseInt((x.amount == '' || x.amount == null) ? "0" : x.amount));
      this.remaining_balance = this.current_balance - this.balance_removed;

    }
  }

  get canSave() {
    return !this.transactionService.client.isRequesting;
  }

  public async save(): Promise<any> {
    var list = [];
    var obs = this.observationRemove;
    this.parameters.tableData.forEach(function (item: Wallet) {
      if (item.canDiscountGeneralWallet) {
        item.observation = obs;
        list.push(item);
      }
    });

    return await this.transactionService.remove(list)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        return await this.router.navigateToRoute("talent-main");

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

}
