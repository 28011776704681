import { autoinject, LogManager } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
// import { NotificationService, NotificationLevel } from "aurelia-notify";
import { Logger } from 'aurelia-logging';

import * as toastr from 'toastr';

@autoinject
export class Notification {

  private logger: Logger;

  constructor(
    private i18n: I18N) {

    this.logger = LogManager.getLogger('Notification');

  }

  public info(message?: string) {
    toastr.info(this.i18n.tr(message));
  }

  public success(message?: string) {
    toastr.success(this.i18n.tr(message));
  }

  public warning(message?: string) {
    toastr.warning(this.i18n.tr(message));
  }

  public error(message?: any) {
    this.logger.error("Error:", message);
    if (message) {
      if (typeof message === 'string') {
        toastr.error(this.i18n.tr(message));
      }
      else if (message instanceof Error) {
        toastr.error(this.i18n.tr(message.message));
      }
      else {
        for (let item in message) {
          if (message.hasOwnProperty(item)) {
            let val = message[item];
            if (Array.isArray(val)) {
              for (let i = 0; i < val.length; i++) {
                toastr.error(this.i18n.tr(val[i]));
              }
            }
            else {
              toastr.error(this.i18n.tr(val));
            }

          }
        }
      }
    }
    else {
      toastr.error(this.i18n.tr("error.unknow_error"));
    }
  }
}
