import { ActivationQuestionary } from 'models/test-person/activationQuestionary';
import { Person } from './../test-person/person';
import { ProgrammingLanguage } from './programmingLanguage'
import { Difficulty } from './difficulty'
export class Test {
  id: number;
  programmingLanguage: ProgrammingLanguage;
  difficulty: Difficulty;
  updatedAt: Date;
  problemDescription: string;
  questionaryName: string;
  questionaryId: number;
  activationQuestionary: ActivationQuestionary
}
