import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { ReportService } from "../../services/report-service";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { addSlash } from "common/utils/utility";
import { ItemToQualify } from "models/questionaries/itemToQualify";

import { Person } from "models/test-person/person";
import { PersonService } from 'main/test/services/people/person-service';
import { Position } from "models/test-position/position";
import { PositionService } from 'main/test/services/position/position-service';
import { Organization } from './../../../../models/test-organization/organization';
import { OrganizationService } from 'main/test/services/organization/organization-service';
import { Team } from 'models/team/team';
import { TeamService } from 'main/test/services/team/team-service';
import config from 'config.js';

@autoinject()
export class ReportList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private state: State;

  private isLoaded: boolean = false;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private person: Person;
  private position: Position;
  private organization: Organization;
  private team: Team;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.reportService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private store: StoreService,
    private reportService: ReportService,
    private notification: Notification,
    private personService: PersonService,
    private positionService: PositionService,
    private organizationService: OrganizationService,
    private teamService: TeamService,
  ) {

    this.logger = LogManager.getLogger("ProfileList");

    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {

    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.reportService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        // filter by person
        if (!isNaN(this.params.personId)) {
          this.parameters.search.PersonId = +this.params.personId;
          this.person = await this.personService.getById(+this.params.personId);
        }

        // filter by position
        if (!isNaN(this.params.positionId)) {
          this.parameters.search.PositionId = +this.params.positionId;
          this.position = await this.positionService.getById(+this.params.positionId);
        }

        // filter by organization
        if (!isNaN(this.params.organizationId)) {
          this.parameters.search.OrganizationId = +this.params.organizationId;
          this.organization = await this.organizationService.getById(+this.params.organizationId);
        }

        // filter by team
        if (!isNaN(this.params.teamId)) {
          this.parameters.search.TeamId = +this.params.teamId;
          this.team = await this.teamService.getById(+this.params.teamId);
        }

        this.isLoaded = true;

        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    // this.logger.debug("parameters", this.parameters);
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private viewReport(id: number): void {

    let testReportsUrl = config.URLS_REPORTS;

    const url = addSlash(testReportsUrl) + "test-reports/" + id;

    window.open(url, "_blank");
  }

  private async generate(): Promise<any> {

    let params: any = {};

    // filter by person
    if (!isNaN(this.params.personId)) {

      params.personId = this.params.personId;
      params.itemToQualify = ItemToQualify.Person;

    }
    else if (!isNaN(this.params.positionId)) {

      params.positionId = this.params.positionId;
      params.itemToQualify = ItemToQualify.Position;

    }
    else if (!isNaN(this.params.teamId)) {

      params.teamId = this.params.teamId;
      params.itemToQualify = ItemToQualify.Team;

    }
    else if (!isNaN(this.params.organizationId)) {

      params.organizationId = this.params.organizationId;
      params.itemToQualify = ItemToQualify.Organization;

    }

    return await this.router.navigateToRoute("report-child-router", params);
  }

}
