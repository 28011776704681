import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { Questionary } from "models/questionaries/questionary";

import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { QuestionaryLocalization } from "models/questionaries/questionaryLocalization";

@autoinject()
export class QuestionaryService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient,
    public currentQuestionary: Questionary) {

    this.logger = LogManager.getLogger("QuestionariesService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("questionary/list", parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<Questionary[]> {
    return await this.client.get("questionary")
      .then(response => {
        return <Questionary[]>response;
      });
  }

  public async getById(id: number): Promise<Questionary> {
    // this.logger.info("id: " +  id); 
    return await this.client.getById("questionary",id)
      .then(response => {
        return <Questionary>response;
      });
  }

  public async create(model: Questionary): Promise<Questionary> {
    return await this.client.create("questionary", model)
      .then(response => {
        return <Questionary>response;
      });
  }

  public async getAllTranslationsByQuestionary(model: Questionary): Promise<Questionary[]> {
    return await this.client.post("questionary/get-all-translations-by-questionary", model)
      .then((response: Questionary[]) => {
        return response;
      });
  }

  public async createLocalization(model: QuestionaryLocalization): Promise<QuestionaryLocalization> {
    return await this.client.post("questionary/createLocalization", model)
      .then(() => null);
  }

  public async update(model: Questionary): Promise<Questionary> {
    return await this.client.updateById("questionary",model.id, null, model)
      .then(response => {
        return <Questionary>response;
      });
  }

  public async updateLocalization(model: QuestionaryLocalization): Promise<QuestionaryLocalization> {
    return await this.client.update(`questionary/update-localization`, null, model)
      .then(response => {
        return <QuestionaryLocalization>response;
      });
  }

  public async delete(id: number): Promise<void> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.deleteById("questionary",id)
      .then(() => null);
  }

  public async deleteLocalization(id: string): Promise<void> {
    
    return await this.client.delete(`questionary/delete-localization/${id}`)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.post(`questionary/change-state/${id}`)
      .then(() => null);
  }

  public async getCompetencesQuestionaries(): Promise<Questionary[]> {
    return await this.client.get("questionary/get-competences-questionaries")
      .then((response: Questionary[]) => {
        return response;
      });
  }

}
