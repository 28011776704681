import { PositionActivationType } from "./positionActivationType";
import { PositionLevel } from "./positionLevel";
import { EducationalLevel } from "../catalogs/educationalLevel";
import { Disc } from "models/questionaries/disc";

export class Position {
  id: number;
  companyId: number;
  name: string;
  tags: string;
  positionActivationType: PositionActivationType;
  questionaryId: string;

  mission: string;
  positionLevel: PositionLevel;
  subPositions: string;
  
  educationalLevel: EducationalLevel;
  functions: string;
  salary: string;
  requiredStudies: string;
  dominatedLanguages: string;
  others: string;
  profilePositionId: number; // used to storage related position for positionDisc Form

  highestDisc: Disc;
  stamp: string;

  evaluatorsQuantity: number;
  finalizedEvaluatorsQuantity: number;
  
  createdOn: Date;
  createdByName: string;
}
