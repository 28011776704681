import { I18N } from 'aurelia-i18n';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { autoinject, LogManager, bindable, Disposable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { CommentService } from "../../services/people/comment-service";
import { Person } from "models/test-person/person";
import { EventAggregator } from "aurelia-event-aggregator";
import { PersonCommentEditMessage } from "main/test/messages/personCommentEditMessage";
import { DeleteConfirmation } from 'main/components/delete-confirmation';

@autoinject()
export class PeopleCommentList {

  private logger: Logger;

  @bindable
  private person: Person;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private subscriptions: Disposable[] = [];

  constructor(
    private commentService: CommentService,
    private notification: Notification,
    private ea: EventAggregator,
    private dialogService: DialogService,
    private i18n: I18N
  ) {

    this.logger = LogManager.getLogger("PeopleCommentList");

    // event when new activation is created
    this.subscriptions.push(this.ea.subscribe(PersonCommentEditMessage, this.databind.bind(this)));
  }

  public unbind() {
    this.subscriptions.forEach(x => x.dispose());
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.commentService.list(this.parameters, this.person.id)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  // private async delete(itm) {
  //   await this.commentService.delete(itm.id);
  //   this.databind();
  //   this.notification.success("notifications.item_deleted");
  // }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: this.i18n.tr("main.test.pages.people.comments.delete_message") + " " + name
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.commentService.delete(id)
            .then(() => {

              this.notification.success("notifications.item_deleted");
              this.databind();

            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      });
  }

  private async bind(): Promise<any> {

    if (this.person) {

      this.commentService.client.currentCompanyId = this.person.companyId;

      return await this.databind();
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

}
