import { autoinject } from "aurelia-framework";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Team } from "models/team/team";

@autoinject()
export class TeamService {


  constructor (
    public client: CompanyAppHttpClient ) {
  }

  public async list ( parameters: UxDatatableParameters ): Promise<UxDatatableResponse> {
    return await this.client.post( "team/list", parameters )
      .then( ( response: any ) => {
        return <UxDatatableResponse> response;
      } );
  }

  public async getAll (): Promise<Team[]> {
    return await this.client.get( "team" )
      .then( ( response: any ) => {
        return <Team[]> response;
      } );
  }

  public async getById ( id: number ): Promise<Team> {
    return await this.client.getById( "team", id )
      .then( ( response: Team ) => {
        return response;
      } );
  }

  public async create ( model: Team ): Promise<Team> {
    return await this.client.create( "team", model )
      .then( ( response: any ) => {
        return <Team> response;
      } );
  }

  public async update ( model: Team ): Promise<void> {
    return await this.client.updateById( "team", model.id, null, model )
      .then( () => null );
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`team`, id)
      .then(() => null);
  }

  public async sendRemindersByFilter(parameters: UxDatatableParameters): Promise<boolean> {
    return await this.client.post("team/list-send-reminder/", parameters)
      .then((response: any) => {
        return true;
      });
  }

  public async sendReminder(id: number): Promise<boolean> {
    return await this.client.post(`team/send-reminder/${id}`)
      .then((response: any) => {
        return true;
      });
  }
}
