
import { bindable, customElement, customAttribute, LogManager, inject, bindingMode, inlineView, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Disposable } from "aurelia-binding";
import { BindingEngine } from "aurelia-binding";

export class UxDatatableParameters {
  search?: any = {};
  skip?: number = 0;
  pageSize?: number = 50;
  totalRecords?: number = 0;
  tableData?: Array<any> = [];
  currentPage?: number = 1;
}

export class UxDatatableResponse {
  data: Array<any> = [];
  totalRecords: number = 0;
}


interface ScrollContext {
  topIndex: number;
  isAtBottom: boolean;
  isAtTop: boolean;
}

@customAttribute("ux-datatable")
@inject(BindingEngine)
export class UxDatatableCustomAttribute {

  private logger: Logger;

  private refreshing: boolean = false;
  private totalPages: number = 0;
  private canGetMoreItems: boolean = true;

  @bindable
  public parameters: UxDatatableParameters;

  @bindable
  public onRefresh: Function;

  private subscriptions: Array<Disposable> = [];

  constructor(
    private bindingEngine: BindingEngine) {
    this.logger = LogManager.getLogger("AuDatatableCustomAttribute");
  }

  public attached(): void {
    this.subscriptions.push(this.bindingEngine
      .propertyObserver(this.parameters, "tableData")
      .subscribe(() => this.updateRecordInfo()));
  }

  public detached(): void {
    this.subscriptions.forEach(x => x.dispose());
  }

  private updateRecordInfo(): void {
    this.totalPages = Math.ceil(parseInt(this.parameters.totalRecords.toString(), null) / this.parameters.pageSize);
    // this.logger.debug("totalPages:", this.totalPages);
    this.canGetMoreItems = this.totalPages > this.parameters.currentPage;
  }

  public async getMoreItems(scrollContext: ScrollContext): Promise<any> {
    this.logger.debug("getMoreItems");
    if (scrollContext
      && scrollContext.isAtBottom
      && !this.refreshing
      && this.canGetMoreItems) {
      // this.logger.debug("getting more items...");
      await this.changePage();
    }
  }

  public async changePage(): Promise<any> {
    if (typeof this.onRefresh !== "function") {
      throw new Error("[ux-datatable-pagination:changePage] No onChangePage() callback has been set");
    }
    // this.logger.debug(`this.parameters.currentPage: ${this.parameters.currentPage}, this.totalPages: ${this.totalPages}`);

    // if (this.totalPages && this.totalPages < this.parameters.currentPage) return;

    this.refreshing = true;
    this.parameters.skip = this.parameters.currentPage * this.parameters.pageSize;
    this.parameters.currentPage = this.parameters.currentPage + 1;

    try {
      let response: UxDatatableResponse = await this.onRefresh();
      this.parameters.totalRecords = response.totalRecords;
      // this.parameters.tableData = this.parameters.tableData.concat(response.data);
      Array.prototype.push.apply(this.parameters.tableData, response.data);

    }
    catch (error) {
      this.logger.error(error);
    }

    this.refreshing = false;
  }
}


@inlineView(`
    <template>
        <div class="au-table-search d-flex">
            <input keyup.delegate="search() & debounce:500"
                value.bind="parameters.search.Query"
                type="text"
                placeholder.bind="placeholder"
                class.bind="inputClasses" />
            <button class="btn btn-link ml-2 p-0" click.delegate="search()" title="Refresh">
              <i class="material-icons">
                refresh
              </i>
            </button>
        </div>
    </template>
`)

@customElement("ux-datatable-search")
export class UxDatatableSearchCustomElement {
  private logger: Logger;

  @bindable
  public placeholder: string;

  @bindable
  public inputClasses: string;

  @bindable
  public onSearchChange: Function;

  @bindable
  public parameters: UxDatatableParameters;

  constructor() {
    this.logger = LogManager.getLogger("UxDatatableInfoCustomElement");
  }

  public async search(): Promise<void> {
    if (typeof this.onSearchChange !== "function") {
      throw new Error("[ux-datatable-search:search] No onSearchChange() callback has been set");
    }

    try {
      this.reset();
      let response: UxDatatableResponse = await this.onSearchChange() as UxDatatableResponse;
      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;
      this.reset();
    } catch (error) {
      this.logger.error(error);
    }
  }

  private reset(): void {
    this.parameters.currentPage = this.parameters.totalRecords > 0 ? 1 : 0;
    this.parameters.skip = 0;
  }
}


@inlineView(`
    <template>
        <div class="au-table-info">
            \${ info }
            <span if.bind="parameters.search.Query.length > 0 && parameters.tableData.length > 0">&nbsp;(\${ labelFiltered })</span>
        </div>
    </template>
`)

@customElement("ux-datatable-info")
@inject(BindingEngine)
export class UxDatatableInfoCustomElement {
  private logger: Logger;

  @bindable
  public message: string;

  @bindable
  public labelFiltered: string;

  @bindable
  public parameters: UxDatatableParameters;

  private info: string;
  private subscriptions: Array<Disposable> = [];

  constructor(
    private bindingEngine: BindingEngine) {
    this.logger = LogManager.getLogger("UxDatatableInfoCustomElement");
  }

  public attached(): void {
    if (!this.message) {
      this.message = "TOTAL_RECORDS records";
    }
    if (!this.labelFiltered) {
      this.labelFiltered = "filtered";
    }

    // this.subscriptions.push(this.bindingEngine
    //   .propertyObserver(this.parameters, "tableData")
    //   .subscribe(() => this.updateRecordInfo()));

    this.subscriptions.push(this.bindingEngine
      .propertyObserver(this.parameters, "totalRecords")
      .subscribe(() => this.updateRecordInfo()));
  }

  public detached(): void {
    this.subscriptions.forEach(x => x.dispose());
  }

  private updateRecordInfo(): void {
    this.info = this.message.replace("TOTAL_RECORDS", this.parameters.totalRecords.toString());
    // this.logger.debug("info", this.info);
  }

}

