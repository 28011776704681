import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { Profile } from "models/profiles/profile";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class ProfileService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("ProfileService");
  }
  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("profile/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<Profile[]> {
    return await this.client.get("profile")
      .then((response: any) => {
        return <Profile[]>response;
      });
  }

  public async getById(id: number): Promise<Profile> {
    return await this.client.getById("profile", id)
      .then((response: any) => {
        return <Profile>response;
      });
  }

  public async create(model: Profile): Promise<Profile> {
    return await this.client.create("profile", model)
      .then((response: any) => {
        return <Profile>response;
      });
  }

  public async update(model: Profile): Promise<void> {
    return await this.client.updateById("profile", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number, profileIdToTransferUsers: number): Promise<void> {
    return await this.client.deleteById("profile", id, { profileIdToTransferUsers: profileIdToTransferUsers} )
      .then(() => null);
  }
}
