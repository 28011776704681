import { Person } from 'models/test-person/person';
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { PersonService } from 'main/test/services/people/person-service';

@autoinject()
export class PersonActivationConfirmationModal {
  private talentsPrice: number;
  constructor(private dialogController: DialogController) {
  }
  public async activate(talentsPrice: number) {        
    this.talentsPrice = talentsPrice;  
  }
}
