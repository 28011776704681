import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class UserListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {
    this.logger = LogManager.getLogger("UserListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./user-list"),
        name: "users",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.UserView),
        title: this.i18n.tr("main.admin.pages.users.title"),
      },

      {
        route: "user/:userId",
        moduleId: PLATFORM.moduleName("./user-child-router"),
        name: "user-child-router",
        // nav: false,
        // title: this.i18n.tr("main.admin.pages.users.user"),
      },

      {
        route: "user-archive",
        moduleId: PLATFORM.moduleName("./user-archive-list"),
        name: "user-archive-router",
        nav: false,
        title: this.i18n.tr("main.test.pages.archive.title"),
      },
      {
        route: "master-users",
        moduleId: PLATFORM.moduleName("./user-master-list"),
        name: "master-users",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CompanyGroupView),
        title: this.i18n.tr("main.admin.pages.master_users.title"),
      },
      {
        route: "master-users/:masterUserId",
        moduleId: PLATFORM.moduleName("../master-users/master-user-child-router"),
        name: "master-user-child-router",
      },
    ]);
  }
}
