import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';

import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { CompanySettingsService } from '../../services/companies/company-settings-service';
import { CompanySettings } from 'models/companies/company-settings';
import { Company } from "models/companies/company";
import { QuestionaryService } from 'main/admin/services/questionaries/questionary-service';
import { Questionary } from 'models/questionaries/questionary';

@autoinject()
export class CompanySettingsEdit {

  private logger: Logger;

  private franchiseId: number;
  private companyGroupId: number;

  private company: Company;

  private model: CompanySettings = new CompanySettings();
  private validationController: ValidationController;

  private competencesQuestionaries: Questionary[] = [];

  constructor(
    private companySettingsService: CompanySettingsService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    private coreCompanyService: CoreCompanyService,
    private companyQuestionaryService: QuestionaryService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CompanySettingsEdit");

  }

  private async activate(params: any): Promise<void> {
    this.logger.info("activate");

    if (!isNaN(params.companyDetailId) && !isNaN(params.franchiseId) && !isNaN(params.companyGroupId)) {
      this.franchiseId = +params.franchiseId;
      this.companyGroupId = +params.comapnyGroupId;

      try {

        this.company = await this.coreCompanyService.getById(+params.companyDetailId);

        this.model = await this.companySettingsService.getById(+params.companyDetailId);

        this.competencesQuestionaries = await this.companyQuestionaryService.getCompetencesQuestionaries();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  // public cancel(): void {
  //   this.logger.info("cancel");
  //   this.router.parent.navigate(`companies/franchise/${this.franchiseId}/company-groups`);
  // }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.companySettingsService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");

    return await this.companySettingsService.update(this.model)
      .then(async () => {
        this.notification.success("main.admin.pages.companies.messages.company_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("company-groups");
        }

      })
      .catch((error) => {
        this.notification.error(error);
      });

  }
}

