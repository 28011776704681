
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class PositionReferenceListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("PositionReferenceListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./position-reference-list"),
        name: "positions-reference",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.languages.title"),
      },

      {
        route: "position-reference/:positionId",
        moduleId: PLATFORM.moduleName("./position-reference-child-router"),
        name: "position-reference-child-router",
      },
      
      {
        route: "localization",
        moduleId: PLATFORM.moduleName("./localization/position-reference-localization-list"),
        name: "position-reference-localization",        
        title: this.i18n.tr("main.test.pages.position.position_tabs.tab_competences"),
      },
    ]);
  }
}
