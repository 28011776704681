import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

@autoinject()
export class AgreementReadOnlyModeModal {

  private logger: Logger;

  private params: any = {};

  constructor(
    private dialogController: DialogController) {
    this.logger = LogManager.getLogger("AgreementReadOnlyModeModal");
  }

  public async activate(params: any): Promise<void> {
    this.logger.debug("activate", params);
    this.params = params;

  }
}
