import { bindable, inject, LogManager, customElement, observable, bindingMode } from "aurelia-framework";
import * as $ from "jquery";
import { Logger } from "aurelia-logging";

@customElement("ux-range-input")

@inject(Element) // Inject the instance of this element
export class RangeInput {

  private logger: Logger;

  @bindable
  public name: string = "range-input";

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  @observable
  public value: number;

  @bindable
  public min: number = 1;

  @bindable
  public max: number = 99;

  @bindable
  public minRange: number = 1;

  @bindable
  public maxRange: number = 99;

  public inputElement: HTMLInputElement;

  constructor(private element: Element) {

    this.logger = LogManager.getLogger("RangeInput");

  }

  public attached() {

    // this.logger.debug("attached");

  }


  // private onChangeVal(itm) {
  //   if (itm.value < 60) return 60;
  //   if (itm.value > 100) return 100;
  //   return itm.value;
  // }

  public valueChanged() {

    let elMax = parseFloat(this.max.toString()); // Range default max
    let elMin = parseFloat(this.min.toString()); // Range default min
    let val = parseFloat(this.value.toString());

    let elMinRange = parseFloat(this.minRange.toString());
    let elMaxRange = parseFloat(this.maxRange.toString());

    if (val < elMinRange) {
      // this.value = elMinRange;
      return;
    }

    if (val > elMaxRange) {
      // this.value = elMaxRange;
      return;
    }

    // this.logger.debug("thumbRange");
    let el = $(this.inputElement);
    // var val = el.val();
    let wrapper = $(this.element);
    let elWidth = el.width() - 15;
    let thumb = wrapper.find('.thumb');
    let thumbPercent = (val - elMin) / (elMax - elMin) * elWidth;

    // extra validation to exist or create thumb
    if (thumb.length) {
      thumb.find('.thumb-value').html(this.value);
      thumb.css('left', thumbPercent + 'px');
    } else {
      el.after('<div class="thumb" style="left: ' + thumbPercent + 'px"><span class="thumb-value">' + this.value + '</span></div>');
    }


  }
}
