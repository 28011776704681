
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class QuestionaryListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("QuestionaryListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./questionary-list"),
        name: "questionaries",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.questionaries.title"),
      },

      {
        route: "questionary/:questionaryId",
        moduleId: PLATFORM.moduleName("./questionary-child-router"),
        name: "questionary-child-router",
        // nav: false,
        // title: this.i18n.tr("main.admin.pages.questionaries.questionary"),
      },

      {
        route: "questionary-localization/:questionaryId",
        moduleId: PLATFORM.moduleName("./questionary-translate-child-router"),
        name: "questionary-translate-child-router"
      },

    ]);
  }
}
