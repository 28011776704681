// export class QuestionType {
//   items = [
//     { "id": 1, "name": "main.admin.pages.questionaries.questions.question_type.single_choice_list", "code": "single.choice.list" },
//     { "id": 2, "name": "main.admin.pages.questionaries.questions.question_type.single_choice_images", "code": "single.choice.image" },
//     { "id": 3, "name": "main.admin.pages.questionaries.questions.question_type.multiple_choice_list", "code": "multiple.choice.list" },
//     { "id": 4, "name": "main.admin.pages.questionaries.questions.question_type.multiple_choice_images", "code": "multiple.choice.image" },
//     { "id": 5, "name": "main.admin.pages.questionaries.questions.question_type.matrix", "code": "matrix" },
//     { "id": 6, "name": "main.admin.pages.questionaries.questions.question_type.descriptive_text", "code": "descriptive.text" },
//     { "id": 7, "name": "main.admin.pages.questionaries.questions.question_type.open_answer_questions", "code": "open.answer.question" }
//   ];

//   public getNameById(id: number): string {
//     return this.items.find(x => x.id == id)["name"];
//   }

//   public getIdByCode(code: string): number {
//     return this.items.find(x => x.code == code)["id"];
//   }
// }

export enum QuestionType {
  SingleChoiceList,
  SingleImageChoiceList,
  MultipleChoiceList,
  MultipleImageChoiceList,
  Matrix,
  DescriptiveText,
  OpenAnswerQuestion,
  SingleChoiceListWithComments
}
