import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";

import { Language } from "models/localizations/language";
import { Culture } from "models/localizations/culture";
import { Flag } from "models/localizations/flag";

import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class LanguageService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("LanguageService");
  }


  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("language/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<Language[]> {
    return await this.client.get("language")
      .then((response: any) => {
        return <Language[]>response;
      });
  }

  async changeState(id: number): Promise<void> {    
    return await this.client.post(`language/change-state/${id}`)
      .then(() => null);
  }
  
  public async getLanguageName(id: number): Promise<string> {
    return await this.client.get(`language/getLanguageName/${id}`)
      .then((response: any) => {
        return <string>response;
      });
  }
  public async cultures(): Promise<Array<Culture>> {
    return await this.client.get("language/cultures", null)
      .then(response => {
        return <Array<Culture>>response;
      })
      .catch(error => {
        this.logger.error(error.message);
        throw error;
      });
  }

  public async flags(): Promise<Array<Flag>> {
    return await this.client.get("language/flags", null)
      .then(response => {
        return <Array<Flag>>response;
      })
      .catch(error => {
        this.logger.error(error.message);
        throw error;
      });
  }

  public async getById(id: number): Promise<Language> {
    return await this.client.getById("language", id)
      .then((response: any) => {
        return <Language>response;
      })
      .catch(error => {
        this.logger.error(error.message);
        throw error;
      });
  }

  public async create(model: Language): Promise<Language> {
    return await this.client.create("language", model)
      .then((response: any) => {
        return <Language>response;
      });
  }  

  public async update(model: Language): Promise<Language> {
    return await this.client.updateById("language", model.id, null, model)
      .then(() => null);
  }  

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("language", id)
      .then(() => null);
  }
}
