import {
  FrameworkConfiguration,
  PLATFORM
} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./elements/ux/bootstrap-tooltip/bootstrap-tooltip'),
    PLATFORM.moduleName('./elements/ux/back-to-top/back-to-top'),
    PLATFORM.moduleName('./elements/ux/datatable/ux-datatable'),
    PLATFORM.moduleName('./elements/ux/datatable/ux-datatable-pagination'),
    PLATFORM.moduleName('./elements/ux/clipboard/ux-clipboard'),
    PLATFORM.moduleName('./elements/ux/well/ux-well.html'),
    PLATFORM.moduleName('./elements/ux/side-well/ux-side-well.html'),
    PLATFORM.moduleName('./elements/ux/validation-summary/ux-validation-summary.html'),
    PLATFORM.moduleName('./elements/ux/carousel/carousel'),
    PLATFORM.moduleName('./elements/ux/range-input/range-input'),
    PLATFORM.moduleName('./elements/ux/date-picker/ux-date-picker'),
    // PLATFORM.moduleName('./elements/ux/tags-input/ux-tags-input'),

    // PLATFORM.moduleName('./value-converters/blob-to-url'),
    // PLATFORM.moduleName('./value-converters/files-to-array'),
    PLATFORM.moduleName('./value-converters/filter'),
    PLATFORM.moduleName('./value-converters/date-format'),
    PLATFORM.moduleName('./value-converters/date-time-format'),
    PLATFORM.moduleName('./value-converters/currency-format'),
  ]);
}
