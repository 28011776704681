import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { ValidationController } from 'aurelia-validation';
import { ValidationRules } from 'aurelia-validation';
import { ControllerValidateResult } from 'aurelia-validation';
import { ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { Notification } from 'common/services/notification';
import { CatalogService } from 'main/services/catalogs/catalog-service';
import { CatalogBase } from 'models/catalogs/catalog-base';
import { CatalogChild } from 'models/catalogs/catalog-child';
import { EducationalLevelCountry } from 'models/educational-levels-by-country/educational-level-country';
import { DemographicData } from 'models/microsite/demographicData';
import { Person } from 'models/test-person/person';
import { StoreService } from "common/store/store-service";
import { State } from "common/store/store-model";
import { calculateAge } from "common/utils/utility";
import { EducationalLevelCountryService } from "main/admin/services/educational-levels-by-country/educational-level-country-service";
import { DialogController } from "aurelia-dialog";
import { PersonDemographicDataService } from "main/test/services/people/person-demographic-data-service";

@autoinject()
export class PersonDemographicDataModal {
  private logger: Logger;
  private companyId: number;
  private model: DemographicData = new DemographicData();
  private tempModel: DemographicData;
  // private person: Person;
  private personId: number;
  private validationController: ValidationController;

  private countries: CatalogBase[] = [];
  private regions: CatalogChild[] = [];
  private cities: CatalogChild[] = [];
  private professions: CatalogBase[] = [];
  private educationalLevelCountries: EducationalLevelCountry[] = [];

  private params: any;
  private maxAge: number = 6;

  private state: State;

  constructor(

    private dialogController: DialogController,
    private catalogService: CatalogService,
    private educationalLevelCountryService: EducationalLevelCountryService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private personDemographicDataService: PersonDemographicDataService,
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("DemographicDataInsert");
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");

    ValidationRules
      .ensure("genre").displayName("main.microsite.pages.demographicdata.genre").required()
      .ensure("birthday").displayName("main.microsite.pages.demographicdata.birthdate").required()
      .ensure("countryId").displayName("catalogs.countries.title").required()
      .ensure("regionId").displayName("catalogs.regions.title").required()
      .ensure("cityId").displayName("catalogs.cities.title").required()
      .ensure("educationalLevel").displayName("main.microsite.pages.demographicdata.educationallevel").required()
      .ensure("professionId").displayName("main.microsite.pages.demographicdata.profession").required()
      .on(this.model);
  }

  public async activate(model: DemographicData): Promise<void> {
    // this.logger.info("activate personId", personId);
    // this.model = model;
    // this.personId = personId;

    this.tempModel = model;

  }

  public async bind(): Promise<any> {
    try {

      this.countries = await this.catalogService.getCountryAll();

      this.professions = await this.catalogService.getProfessionAll();

      this.educationalLevelCountries = await this.educationalLevelCountryService.getAll();

      // this.model = await this.personDemographicDataService.get(this.personId);

      this.model = this.tempModel;

      this.regions = await this.catalogService.getRegionsByCountryId(this.model.countryId);

      this.cities = await this.catalogService.getCitiesByRegionId(this.model.regionId);

      this.configureValidationRules();

    }
    catch (error) {
      this.notification.error(error);
    };
  }

  public async submit(): Promise<void> {
    if (!this.isValidAge(this.model.birthday)) {
      this.notification.warning("main.microsite.pages.demographicdata.validation_age");
    }
    else {
      // this.logger.debug("model", this.model);
      return await this.validationController.validate()
        .then(async (result: ControllerValidateResult) => {
          if (result.valid) {
            return await this.save();
          }
        });
    }
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();
  }

  public async filterRegionAndEducation(countryId: number): Promise<void> {
    this.model.regionId = null;
    this.model.cityId = null;
    this.model.educationalLevel = null;
    // this.educationalLevelCountries = await this.educationalLevelCountryService.getByCountry(countryId);

    this.regions = await this.catalogService.getRegionsByCountryId(countryId);
  }

  public async filterCity(regionId: number): Promise<void> {
    this.model.cityId = null;

    this.cities = await this.catalogService.getCitiesByRegionId(regionId);
  }

  public isValidAge(birthday: Date): boolean {
    let result: boolean = false;

    if (this.model.birthday != null) {

      let birthday = new Date(this.model.birthday);

      let age = calculateAge(birthday);

      // this.model.age = age;
      if (age < this.maxAge) {
        // this.logger.debug("Birthday:", birthday)
        this.logger.debug("AgeCalculate:", age);
        // this.model.age = null;

      } else {
        result = true;
      }

    }

    return result;
  }

}
