import { Disc } from "../questionaries/disc";
import { DiscType } from "models/questionaries/discType";

export class Result {
  positionId?: number;
  organizationId?: number;
  personId?: number;
  activationId?: number;
  disc: Disc;
  discType?: DiscType;
  // thematicVariableId
  value: number;
  stamp?: string;
  // VideoInterview
  observation? :string;
  topic? : number;
  questionaryId?: number;
}
