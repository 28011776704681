
import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Localization } from "models/localizations/localization";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject
export class LocalizationModal {

  private logger: Logger;
  private model: Localization = new Localization();
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.logger = LogManager.getLogger("LocalizationModal");
  }

  private async activate(model: Localization): Promise<void> {
    this.model = model;
    return this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("translation").displayName("main.admin.pages.localizations.translation").required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }


  public async save(): Promise<any> {
    // this.logger.debug("save:");
    // this.validationController.reset();
    return await this.dialogController.ok(this.model);
  }

}
