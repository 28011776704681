import { CompanyInfoService } from '../../../services/companies/company-info-service';
import { CompanyDetail } from '../../../../models/companies/company-detail';
import { I18N } from 'aurelia-i18n';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Position } from "models/test-position/position";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PositionService } from "../../services/position/position-service";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ItemToQualify } from "models/questionaries/itemToQualify";
import { CompanyQuestionaryService } from 'main/admin/services/test-management/company-questionary-service';
import { EducationalLevelCountry } from "models/educational-levels-by-country/educational-level-country";
import { EducationalLevelCountryService } from "main/admin/services/educational-levels-by-country/educational-level-country-service";
import { StoreService } from 'common/store/store-service';
import { State } from 'common/store/store-model';
import { PositionActivationType } from 'models/test-position/positionActivationType';
import { EducationalLevel } from 'models/catalogs/educationalLevel';

@autoinject()
export class PositionEdit {

  private logger: Logger;
  public companyId: number;
  public companyDetail: CompanyDetail;

  private positionId: number;

  private model: Position = new Position();
  private validationController: ValidationController;

  private positionDiscQuestionaries: Questionary[] = [];
  private educationalLevelCountries: EducationalLevelCountry[] = [];

  private params: any = {};

  private state: State;
  private isLoaded: boolean = false;
  
  constructor(
    private store: StoreService,
    private router: Router,
    private companyQuestionaryService: CompanyQuestionaryService,
    private notification: Notification,
    private educationalLevelCountryService: EducationalLevelCountryService,
    private validationControllerFactory: ValidationControllerFactory,
    private positionService: PositionService,
    private companyInfoService: CompanyInfoService,
    private i18n: I18N
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PositionEdit");

    this.state = this.store.state;
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.test.pages.position.position_edit.name").required()
      .ensure("positionActivationType").displayName("main.test.pages.position.position_edit.activation_type.title").required()
      .ensure("questionaryId").displayName("main.test.pages.position.position_edit.questionary_type").required()
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {

    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      
    }
    else {
      this.params = params;
    }


    if (!isNaN(params.companyId)) {
      try {
        this.companyId = +params.companyId;
        this.positionService.client.currentCompanyId = this.companyId;
        this.companyQuestionaryService.client.currentCompanyId = this.companyId;

        if (!isNaN(params.positionId)) {
          this.positionId = params.positionId;
          this.model = await this.positionService.getById(this.positionId);
         
        }
       
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }

  }
  public  getEducationalLevelName(code: EducationalLevel) : string {  
    const educationalLevel = this.educationalLevelCountries.find(x=> x.educationalLevel == code);    
    let educationalLevelName = this.i18n.tr('main.catalog.educational_level.' + EducationalLevel[code]);
    if (educationalLevel != undefined)
      if (educationalLevel.id > 0)      
        educationalLevelName = educationalLevel.name;      
    return educationalLevelName;
  }
  public async bind() {
    // this.logger.debug("bind");
    if (!isNaN(this.params.companyId)) {
      try {

        this.companyDetail = await this.companyInfoService.getById(this.params.companyId);


        this.educationalLevelCountries = await this.educationalLevelCountryService.getAll();    
        this.educationalLevelCountries = this.educationalLevelCountries.filter(x => x.countryId === this.companyDetail.countryId)
        const questionaries = await this.companyQuestionaryService.getAll();
        this.positionDiscQuestionaries = questionaries.filter(itm => (itm.questionaryType === QuestionaryType.Disc && itm.itemToQualify === ItemToQualify.Position));

        this.isLoaded = true;
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }


  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.positionId) {
            return await this.update(keepEditing);
          } else {
            return await this.create(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.positionService.client.isRequesting;
  }

  private async create(keepEditing: boolean): Promise<any> {
    return await this.positionService.create(this.model)
      .then(async (result: Position) => {
        this.notification.success("notifications.item_edited");
        if (keepEditing) {
          if(this.model.positionActivationType === PositionActivationType.Individual) {
            this.notification.info("main.test.pages.position.position_edit.email_sent"); 
            return await this.router.navigateToRoute("position-child-router", { positionId: result.id });
          }
          else if(this.model.positionActivationType === PositionActivationType.Concerted){
            return await this.router.parent.navigate(`position/${result.id}/evaluators`);
          } else{// se va por directa
            return await this.router.parent.navigate(`position/${result.id}/disc`);            
          }
          // return await this.router.navigateToRoute("position-child-router", { positionId: result.id });

        }
        else {
          return await this.router.navigateToRoute("positions");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async update(keepEditing: boolean): Promise<any> {    
    return await this.positionService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("positions");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  public notificationInfo(message:string){
    this.notification.info(message);
  }
}
