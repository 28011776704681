
export enum Parameter {
  ReportTemplate = 1,
  Sheets = 2,
  Language = 3,
  DiscType = 4,
  Person = 5,
  Position = 6,
  Team = 7,
  Organization = 8,
  TwoPeople = 9,
  Observers = 10,
  Positions = 11,
  CompareToPerson = 12,
  CompareToPosition = 13,
  CompareToTeam = 14,
  CompareToOrganization = 15,
  Questionary = 16,
  Competences = 17,
  People = 18,
  Leader = 19,
  ThreePeople = 20,
  Adjustment = 21,
  OneOrTwoPeople = 22,
  ZeroOrOnePosition = 23,
  Activation = 24,
  QuestionaryTeam = 25,
  TwoObservers = 26
}
