import { Plan } from './../../../../models/plans/plan';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Agreement } from 'models/agreements/agreement';
import config from 'config.js';
@autoinject()
export class AgreementService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("Agreement");
  }

  public async getAll(companyId: number): Promise<Agreement[]> {    
    let backendUrl = config.URLS_BACKEND;
    this.client.baseUrl =  `${backendUrl}/api/v1/company/${companyId}/`;
    return await this.client.get("agreement")
      .then((response: any) => {
        return <Agreement[]>response;
      });
  }

  public async getLastAgreementByCompany(companyId: number): Promise<Agreement> {        
    return await this.client.get(`agreement/get-last-agreement-by-company`)
      .then((response: any) => {
        return <Agreement>response;
      });
  }

  public async listToApprove(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("agreement/list-to-approve", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("agreement/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async robothunterByCompany(companyId: number): Promise<Plan> {
    return await this.client.get("agreement/robothunter-by-company")
      .then((response: any) => {
        return <Plan>response;
      });
  }

  public async getAgreementsByCompanyId(companyId: number,isRobotHunter: boolean): Promise<Plan[]> {
    let backendUrl = config.URLS_BACKEND;
    this.client.baseUrl =  `${backendUrl}/api/v1/company/${companyId}/`;
    return await this.client.get(`agreement/get-agreement-by-company/${isRobotHunter}`)
      .then((response: Plan[]) => {
        return <Plan[]>response;
      })
  }
  public async getReadOnlyById(id: number): Promise<Agreement> {
    return await this.client.get(`agreement/get-readonly-mode/${id}`)
      .then((response: any) => {
        return <Agreement>response;
      });
  }  

  public async getById(id: number): Promise<Agreement> {
    return await this.client.getById("agreement", id)
      .then((response: any) => {
        return <Agreement>response;
      });
  }

  public async create(model: Agreement): Promise<Agreement> {
    return await this.client.create("agreement", model)
      .then((response: any) => {
        return <Agreement>response;
      });
  }

  public async update(model: Agreement): Promise<void> {
    return await this.client.updateById("agreement", model.id, null, model)
      .then(() => null);
  }

  public async changeStatusToDraft(model: Agreement): Promise<void> {
    return await this.client.updateById("agreement/change-status-to-draft", model.id, null, model)
      .then(() => null);
  }

  public async changeStatusToReview(model: Agreement): Promise<void> {
    return await this.client.updateById("agreement/change-status-to-review", model.id, null, model)
      .then(() => null);
  }

  public async changeStatusToForwarded(model: Agreement): Promise<void> {
    return await this.client.updateById("agreement/change-status-to-forwarded", model.id, null, model)
      .then(() => null);
  }

  public async changeStatusToValid(model: Agreement): Promise<void> {
    return await this.client.updateById("agreement/change-status-to-valid", model.id, null, model)
      .then(() => null);
  }

  public async changeStatusToCancelled(model: Agreement): Promise<void> {
    return await this.client.updateById("agreement/change-status-to-cancelled", model.id, null, model)
      .then(() => null);
  }

  public async resendToApprove(model: Agreement): Promise<void> {
    return await this.client.updateById("agreement/resend-to-approve", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("agreement", id)
      .then(() => null);
  }
}
