import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

@autoinject()
export class PersonPredictorConfirmationModal {

  private predictorTalents: number;

  constructor(private dialogController: DialogController) {
  }

  public async activate(predictorTalents: number) { 
    this.predictorTalents = predictorTalents;  
  }

}
