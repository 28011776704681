export enum NoDiscType {
  General = 0,
  ExpectationsTeam = 1,
  Performance = 2,
  Curriculum = 3,
  JobProspects = 4,
  VideoInterview = 5,
  EngineersTest = 6,
  CareerPath = 7,
  Potential = 8
}
