import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

@autoinject
export class ScaleThematicVariables {

  private logger: Logger; 
  private thematicGroupId: number; 

  constructor() {
    this.logger = LogManager.getLogger("ScaleThematicGroups");
  }

  private async activate(params: any): Promise<void> {
    this.logger.info("activated");

    if (!isNaN(params.thematicGroupId)) {
      this.thematicGroupId = +params.thematicGroupId;
    }

  }

}
