import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Group } from "models/groups/group";
import { GroupService } from "../../services/groups/group-service";
import { Notification } from "common/services/notification";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class GroupModal {

  private logger: Logger;
  private saveAndContinue: boolean = false;
  private model: Group;
  private validationController: ValidationController;
  private groups: Group[];

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private groupService: GroupService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("GroupModal");

  }

  public bind(): void {
    this.groupService.getAll().then(groups => {
      this.groups = groups;
    });
  }

  public activate(model: Group): void {
    this.model = model;
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.groups.name").required()
      .ensure("auxGroupId").displayName("main.admin.pages.groups.group_to_clone").required()
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    // this.logger.debug("save:");
    // this.validationController.reset();
    return await this.dialogController.ok(this.saveAndContinue);
  }
}
