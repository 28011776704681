import { Router } from 'aurelia-router';
import { autoinject } from "aurelia-framework";

@autoinject
export class Home {

  constructor(private router: Router) {

  }


}
