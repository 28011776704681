import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { Group } from "models/groups/group";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class GroupService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("GroupsService");
  }
  
  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("group/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<Group[]> {
    return await this.client.get("group")
      .then((response: any) => {
        return <Group[]>response;
      });
  }

  public async getByCompanyId(customerCompanyId: number): Promise<Group[]> {
    return await this.client.get(`group/${customerCompanyId}/get-by-company`)
      .then((response: any) => {
        return <Group[]>response;
      });
  }

  public async getById(id: number): Promise<Group> {
    return await this.client.getById("group", id)
      .then((response: any) => {
        return <Group>response;
      });
  }

  public async create(model: Group): Promise<Group> {
    return await this.client.create("group", model)
      .then((response: any) => {
        return <Group>response;
      });
  }

  public async update(model: Group): Promise<void> {
    return await this.client.updateById("group", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number, groupIdToTransferUsers: number): Promise<void> {
    return await this.client.deleteById("group", id, { groupIdToTransferUser: groupIdToTransferUsers} )
      .then(() => null);
  }

  public async getPermissionNamesById(id: number): Promise<Array<string>> {
    return await this.client.get(`group/${id}/permission-name`)
      .then((response: any) => {
        return <Array<string>>response;
      });
  }

  public async updatePermissionsNamesById(id: number, permissions: Array<string>): Promise<void> {
    return await this.client.update(`group/${id}/permission-name`, null, permissions)
      .then(() => null);
  }
}
