import { ItemToQualify } from 'models/questionaries/itemToQualify';
import { Sheet } from '../sheets/sheet';
import { Questionary } from 'models/questionaries/questionary';
import { OrganizationType } from 'models/questionaries/organizationType';
import { ActivationQuestionary } from 'models/test-person/activationQuestionary';

export class ReportTemplate {
  id: number;
  name: string;
  description: string;
  itemToQualify: ItemToQualify;
  organizationType?: OrganizationType;
  isActive: boolean;
  isDefault: boolean;
  createdOn: Date;
  createdBy: string;
  languageId: number;
  LanguageName: string;
  isPredictor: boolean;

  questionaries: Questionary[] = [];
  questionaryIds: number[] = [];

  sheetIds: number[] = [];
  sheets: Sheet[] = [];

  companyCompetenceIds: number[] = [];
}
