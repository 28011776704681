import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { CompanyDetail } from "models/companies/company-detail";
import { Plan } from "models/plans/plan";

@autoinject
export class CompanyDetailService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CompanyService");
  }

  public async getDemoPlansByFranchiseId(franchiseId: number): Promise<Plan[]> {
    return await this.client.get(`companydetail/get-demo-plans/${franchiseId}`)
      .then((response: Plan[]) => {
        return response;
      })
  }

  public async getById(id: number): Promise<CompanyDetail> {
    return await this.client.getById("companydetail", id)
      .then((response: any) => {
        return <CompanyDetail>response;
      });
  }

  public async create(model: CompanyDetail): Promise<CompanyDetail> {
    return await this.client.create("companydetail", model)
      .then((response: any) => {
        return <CompanyDetail>response;
      });
  }

  public async update(model: CompanyDetail): Promise<CompanyDetail> {
    return await this.client.updateById("companydetail", model.id, null, model)
    .then((response: any) => {
      return <CompanyDetail>response;
    });
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("companydetail", id)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {    
    return await this.client.post(`companydetail/change-state/${id}`)
      .then(() => null);
  }

}
