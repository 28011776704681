
import { LogManager } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";

export class ReportListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor() {
    this.logger = LogManager.getLogger("ReportListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./report-template-list"),
        name: "report-templates",

      },

      {
        route: "report-template/:reportTemplateId",
        moduleId: PLATFORM.moduleName("./report-template-child-router"),
        name: "report-template-child-router",
      },
    ]);
  }
}
