
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";

@autoinject
export class SheetListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor() {
    this.logger = LogManager.getLogger("SheetListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./company-competence-list"),
        name: "company-competence-list",
      },

    ]);
  }
}
