import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyGroupMaster } from "models/companies/company-group-master";

@autoinject()
export class CompanyGroupMasterService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("CompanyGroupMasterService");
  }

  public async getById(id: number): Promise<CompanyGroupMaster> {
    return await this.client.getById("companygroupmaster", id)
      .then((response: any) => {
        return <CompanyGroupMaster>response;
      });
  }

  public async list(franchiseId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`companygroupmaster/list/${franchiseId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }


}
