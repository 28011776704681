import { CompanyQuestionaryService } from 'main/admin/services/test-management/company-questionary-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ItemToQualify } from "models/questionaries/itemToQualify";
import { OrganizationService } from "main/test/services/organization/organization-service";
import { Organization } from "models/test-organization/organization";
import { OrganizationActivationType } from 'models/test-organization/organizationActivationType';


@autoinject()
export class OrganizationEdit {

  private logger: Logger;
  public companyId: number;

  private organizationId: number;

  private model: Organization = new Organization();
  private validationController: ValidationController;

  private orgDiscQuestionaries: Questionary[] = [];

  constructor(
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private organizationService: OrganizationService,
    private companyQuestionaryService: CompanyQuestionaryService,
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("OrganizationEdit");
    this.model.tags = "";
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.test.pages.organizations.name").required()
      .ensure("organizationType").displayName("main.test.pages.organizations.organization_types.title").required()
      .ensure("organizationActivationType").displayName("main.test.pages.organizations.activation_types.title").required()
      .ensure("questionaryId").displayName("main.test.pages.organizations.questionaryId").required()
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {
    if (!isNaN(params.companyId)) {
      try {
        this.companyId = +params.companyId;
        this.organizationService.client.currentCompanyId = this.companyId;
        this.companyQuestionaryService.client.currentCompanyId = this.companyId;
        const questionaries = await this.getQuestionaries();
        this.orgDiscQuestionaries = questionaries.filter(itm => (itm.questionaryType === QuestionaryType.Disc && itm.itemToQualify === ItemToQualify.Organization))
        if (!isNaN(params.organizationId)) {
          this.organizationId = params.organizationId;
          this.model = await this.getItem();
        }
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private async getItem(): Promise<Organization> {
    return await this.organizationService.getById(this.organizationId)
  }

  private async getQuestionaries(): Promise<Questionary[]> {
    return await this.companyQuestionaryService.getAll();
  }

  get canSave() {
    return !this.organizationService.client.isRequesting;
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.organizationId) {
            return await this.update(keepEditing);
          } else {
            return await this.create(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async create(keepEditing: boolean): Promise<any> {
    return await this.organizationService.create(this.model)
      .then(async (result: Organization) => {
        this.notification.success("notifications.item_edited");
        if (keepEditing) {
          if (this.model.organizationActivationType == OrganizationActivationType.Direct) {
            return await this.router.parent.navigate(`organization/${result.id}/disc`);
          }
          else if (this.model.organizationActivationType == OrganizationActivationType.Concerted) {
            return await this.router.parent.navigate(`organization/${result.id}/evaluators`);
          }
          else if (this.model.organizationActivationType == OrganizationActivationType.Individual) {
            this.notification.info("notifications.email_sent")
          }

          else {
            return await this.router.navigateToRoute("organizations");
          }
        }
        })

      .catch(error => {
        this.notification.error(error);
      });
  }

  private async update(keepEditing: boolean): Promise<any> {
    return await this.organizationService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("organizations");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
}
