import { BulkActivationService } from 'main/test/services/people/bulk-activation-service';
import { Parameter } from '../../../../models/test-reports/reports/parameter';
import { SelectSheetModal } from '../../../test-reports/pages/reports/select-sheet-modal';
import { NoDiscType } from '../../../../models/questionaries/noDiscType';
import { QuestionaryType } from '../../../../models/questionaries/questionaryType';
import { Questionary } from '../../../../models/questionaries/questionary';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Language } from "models/localizations/language";
import { ReportTemplate } from "models/test-core/report-templates/report-template";
import { ReportTemplateService } from "../../../admin/services/test-management/report-template-service";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { Router } from "aurelia-router";
import { BulkDownloadService } from "main/test/services/people/bulk-download-service";
import { UserInfoLoadedMessage } from 'messages/userInfoLoadedMessage';
import { Subscription, EventAggregator } from 'aurelia-event-aggregator';
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Report } from 'models/test-reports/reports/report';
import { BulkDownloadConfirmationModal } from './bulk-download-confirmation-modal';
import { ItemToQualify } from 'models/questionaries/itemToQualify';

@autoinject()
export class BulkActivationConverted {

  private logger: Logger;

  public companyId: number;
  public bulkActivationId: number;

  private state: State;

  private reportTemplates: ReportTemplate[];
  private reportTemplate: ReportTemplate = new ReportTemplate();
  private languages: Language[];

  //private model: BulkDownload = new BulkDownload();
  private validationController: ValidationController;

  private subscription: Subscription;
  private isInvalid: boolean;
  private isDisable: boolean = false;
  private showDisableParameter: boolean = false;
  private report: Report = new Report();
  private reportToPeople: Report = new Report();
  private parPeople: Parameter; 
  constructor(
    private router: Router,
    private bulkDownloadService: BulkDownloadService,
    private bulkActivationService: BulkActivationService,
    private store: StoreService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private reportTemplateService: ReportTemplateService,
    private languageService: CoreLanguageService,
    protected ea: EventAggregator,
    private dialogService: DialogService,
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("BulkActivationConverted");

    this.state = this.store.state;

    this.subscription = this.ea.subscribe(UserInfoLoadedMessage, (message: UserInfoLoadedMessage) => this.setUserLanguage.bind(this));
  }

  public detached() {
    this.subscription.dispose();
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
      ValidationRules
      .ensure("reportTemplateId").displayName("main.test_reports.pages.reports.report_template").required()
      .ensure("discType").displayName("main.test_reports.pages.reports.discType.title").required()
      .ensure("zeroOrOnepositionId").displayName("main.test_reports.pages.reports.parameters.ZeroOrOnePosition").maxItems(1)
      .ensure("positionId").displayName("main.test_reports.pages.reports.parameters.Position").required()
      .ensure("teamId").displayName("main.test_reports.pages.reports.parameters.Team").required()
      .ensure("organizationId").displayName("main.test_reports.pages.reports.parameters.Organization").required()
      .ensure("positionIds").displayName("main.test_reports.pages.reports.parameters.Positions").required().minItems(1).maxItems(1000)
      .ensure("compareToPositionId").displayName("main.test_reports.pages.reports.parameters.ComparePosition").required()
      .ensure("compareToTeamId").displayName("main.test_reports.pages.reports.parameters.CompareTeam").required()
      .ensure("compateToOrganizationId").displayName("main.test_reports.pages.reports.parameters.CompareOrganization").required()
      .ensure("compareToPersonId").displayName("main.test_reports.pages.reports.parameters.ComparePerson").required()
      .ensure("personId").displayName("main.test_reports.pages.reports.parameters.Person").required()
      .ensure("personId").displayName("main.test_reports.pages.reports.parameters.Leader").required()
      .ensure("peopleIds").displayName("main.test_reports.pages.reports.parameters.People").required().minItems(1)
      .ensure("oneOrTwoPeopleIds").displayName("main.test_reports.pages.reports.parameters.OneOrTwoPeople").required().minItems(1).maxItems(2)
      .ensure("twoPeopleIds").displayName("main.test_reports.pages.reports.parameters.TwoPeople").required().minItems(2)
      .ensure("threePeopleIds").displayName("main.test_reports.pages.reports.parameters.ThreePeople").required().minItems(1).maxItems(3)
      .ensure("observerIds").displayName("main.test_reports.pages.reports.parameters.Observers").required().minItems(1).maxItems(1000)
      .on(this.report);
  }

  public async activate(params: any): Promise<any> {
    this.logger.debug("activate");

    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      //this.model.companyId = this.companyId;
      this.report.companyId = this.companyId;
      this.report.personId = 0;
      
      this.reportTemplateService.client.currentCompanyId = this.companyId;
      this.bulkDownloadService.client.currentCompanyId = this.companyId;

      this.reportTemplates = await this.getReportTemplates();

      if (!isNaN(params.bulkActivationId)) {
        this.configureValidationRules();
        this.bulkActivationId = +params.bulkActivationId;
        this.bulkActivationService.client.currentCompanyId = this.companyId;
        
        let bulkActivation = await this.bulkActivationService.getById(this.bulkActivationId);
        
        if (bulkActivation.reportTemplateId) {
          this.report.reportTemplateId = bulkActivation.reportTemplateId;
          this.changeReport();
        }
      }
    
    }

  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find(lg => lg.culture === activeCulture);
      if (activeLang) {
        this.report.languageId = activeLang.id;
      }
    }
  }

  private async bind(): Promise<any> {
    this.logger.debug("bind");
    try {
      this.languages = await this.languageService.getAll();
      this.setUserLanguage();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async getReportTemplates(): Promise<ReportTemplate[]> {     
    let reportTemplateList = this.reportTemplateService.getAll();
    let filteredReportTemplates = (await reportTemplateList).filter(x => x.itemToQualify === ItemToQualify.Person);
    return filteredReportTemplates
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    //this.model.report = this.report;
    //this.model.peopleWithReportIds = this.reportToPeople.peopleIds
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (!this.report.reportTemplateId) {
            this.notification.warning("main.test.pages.people.errors.reports_is_missing");
          }
          else {
            let talentsPrice = await this.bulkDownloadService.getGenerationReportCost(this.report);
            return await this.dialogService.open({
                  viewModel: BulkDownloadConfirmationModal,
                  model: talentsPrice
                }).whenClosed(async (response: DialogCloseResult) => {
                  if (!response.wasCancelled) {
                    return await this.save();
                    }
                });
              }
            }
          })
      .catch(error => {
        this.notification.error(error);
      });
    }

  get canSave() {
    return !this.bulkDownloadService.client.isRequesting;
  }

  private async save(): Promise<any> {
    this.logger.info("submit");
    try {
      await this.bulkDownloadService.convertBulkActivation(this.bulkActivationId, this.report);

      this.notification.success("notifications.item_added");

      //view new bulkdownload
      return await this.router.navigateToRoute("bulk-download-list");
    }
    catch (error) {
      this.notification.error(error);
    };
  }

  public async changeReport(): Promise<any> {

    this.logger.debug("changeReport");

    try {
      this.reportTemplate = await this.reportTemplateService.getById(this.report.reportTemplateId);

      // add language
      this.report.languageId = this.reportTemplate.languageId;

      //let personActivationQuestionaries: ActivationQuestionary[] = await this.activationQuestionaryService.getAll(this.model.personId);

      let questionaries: Questionary[] = [];
      Object.assign(questionaries, this.reportTemplate.questionaries);

      questionaries.forEach((item, index) => {

        //curriculum
        if (item.questionaryType === QuestionaryType.NoDisc && item.noDiscType === NoDiscType.Curriculum) {
          let qIndex = this.reportTemplate.questionaries.findIndex(x => x.id === item.id);
          this.reportTemplate.questionaries.splice(qIndex, 1);
          // return;
        }

        // delete all questionaries selector when only have 1 or none
        //let questionaryActivations = personActivationQuestionaries.filter(x => x.questionaryId === item.id
        //  && x.activationQuestionaryStatus === ActivationQuestionaryStatus.Finalized);

        // if (questionaryActivations.length < 2) {
        //   let qIndex = this.reportTemplate.questionaries.findIndex(x => x.id === item.id);
        //   this.reportTemplate.questionaries.splice(qIndex, 1);
        // }
      });

      this.report.sheets = this.reportTemplate.sheets;
      this.setParameters(this.report.sheets.map(x => x.id));
    } catch (error) {
      this.notification.error(error);
    }
  }

  public async sheetSelect(): Promise<any> {
    return await this.dialogService.open({
      viewModel: SelectSheetModal, 
      model: this.report
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.setParameters(this.report.sheetIds);
      } else {
        this.report.sheetIds = [];
      }
    });
  }

  private setParameters(sheetIds: number[]) {
    // reset variable
    this.report.parameters = [];

    this.report.sheets.forEach(sheet => {
      const index = sheetIds.findIndex(x => x == sheet.id);
      if (index !== -1) {
        for (let par of sheet.parameters) {
          const index1 = this.report.parameters.findIndex(x => x === par.parameter);
          if (index1 === -1) {
            this.report.parameters.push(par.parameter);
          }
        }
      }
    });

    this.logger.debug("Parameters: ", this.report.parameters);
    }

    public async showParameters(): Promise<any> {
      return await this.validationController.validate()
        .then(async (result: ControllerValidateResult) => {
          if (result.valid)
            this.isDisable = true;
        });  
    }

    public async hideParameters(): Promise<any> {
      this.isDisable = false;
    }

 }

