import { ResponseRatedQuestion } from '../../../../models/videointerview/responseRatedQuestion';
import { ResponseQuestionary } from '../../../../models/microsite/responseQuestionary';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Notification } from "common/services/notification";
import { PersonService } from "main/test/services/people/person-service";
import { Person } from "models/test-person/person";
import { ActivationQuestionaryService } from 'main/test/services/people/activation-questionary-service';
import { ActivationQuestionary } from 'models/test-person/activationQuestionary';
import { ResponseQuestionaryVideoInterviewService } from 'main/test/services/people/response-questionary-videointerview-service';
import { Result } from 'models/test-result/result';
import { Topic } from 'models/questions/topic';
import { CryptoService } from 'main/test/services/people/crypto-service';
import config from 'config.js';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { RequestQualificatoniVideoInterview } from 'models/test-person/requestQualificationVideoInterview';
import { DialogCloseResult, DialogService } from 'aurelia-dialog';
import { RequestQualificationVideoInterviewModal } from './request-qualification-video-interview-modal';
import { RequestVideoInterviewQualification } from 'models/videointerview/requestVideoInterviewQualification';


@autoinject()
export class PeopleVideoInterviewEdit {

  private logger: Logger;
  public companyId: number;
  public state: State;

  public generalTopic: number = Topic.General;

  private personId: number;
  private person: Person;
  private validationController: ValidationController;
  private numQuestion: string;
  private question: string;
  private exampleFormControlTextarea1: string;
  private hideDiv: boolean;
  private vToken: string;

  private vValidate: boolean;
  private imagenHide: boolean;
  private backendToken: string;
  private backendRating: string;
  private backendResponseQuestionary: string;
  private idPregunta: number;
  private Questions: string;
  private videoSrc: string;

  private stars: number;

  private options = [];
  private selectedOption = {};

  //parameters for save/update qualification

  private pCompanyId: number;
  private pPersonId: number;
  private pActivationQuestionaryId: number;
  private pResponseQuestionaryId: number;
  private pActivationId: number;
  private pTopic: number;
  private pQuestionaryId: number;
  private responseRatedQuestionModel: ResponseRatedQuestion[];
  private activationQuestionaryId: number;
  private hasListActivation: boolean = false;
  private noActivation: boolean = false;
  selectedActivationQuestionaryId: number;
  constructor(
    private router: Router,
    private personService: PersonService,
    private responseQuestionaryVideoInterviewService: ResponseQuestionaryVideoInterviewService,
    private store: StoreService,
    private notification: Notification,
    private activationQuestionaryService: ActivationQuestionaryService,
    private activationQuestionary: ActivationQuestionary,
    private cryptoService: CryptoService,
    private dialogService: DialogService,
    private activations: ActivationQuestionary[],
    private testQuestionService: ResponseQuestionaryVideoInterviewService,
    private responseQuestionary: ResponseQuestionary,
    private result: Result


  ) {
    this.options = [{ id: 1, value: "10", selected: false }, { id: 2, value: "20", selected: false }, { id: 3, value: "30", selected: false }, { id: 4, value: "40", selected: false }, { id: 5, value: "50", selected: false }, { id: 6, value: "60", selected: false }, { id: 7, value: "70", selected: false }, { id: 8, value: "80", selected: false }, { id: 9, value: "90", selected: false }, { id: 10, value: "100", selected: false }];
    this.vValidate = false;
    this.imagenHide = false;

    this.logger = LogManager.getLogger("PeopleResumeEdit");
  }

  public async activate(params: any): Promise<any> {
   
      if (!isNaN(params.companyId)) {
        this.state = this.store.state;
        this.companyId = +params.companyId;
        this.personService.client.currentCompanyId = this.companyId;
        this.pActivationQuestionaryId = params.activationQuestionaryId;
        if (!isNaN(params.personId)) {
          this.personId = params.personId;
          
          this.activations = await this.activationQuestionaryService.listActivationVideoInterview(this.personId);
          if (this.activations.length == 0) {
            this.noActivation = true;
            this.hasListActivation = false;
          }
          else if(this.activations.length > 0 && !isNaN(this.pActivationQuestionaryId)){
            this.activationQuestionary = await this.activationQuestionaryService.getById(this.pActivationQuestionaryId);
            this.noActivation = false;
            this.hasListActivation = false;
            this.person = await this.getPerson();
            this.pActivationQuestionaryId = this.activationQuestionary.id;
            this.pActivationId = this.activationQuestionary.activationId;
            var responseQuestionary = await this.testQuestionService.getByActivationQuestionaryId(this.pActivationQuestionaryId);
            this.pResponseQuestionaryId = responseQuestionary.id;
            this.getNewToken(106);
          }
          else if (this.activations.length == 1) {
            this.activationQuestionary = await this.activationQuestionaryService.getLastActivationVideoInterview(this.personId);
            this.noActivation = false;
            this.hasListActivation = false;
            this.person = await this.getPerson();
            this.pActivationQuestionaryId = this.activationQuestionary.id;
            this.pActivationId = this.activationQuestionary.activationId;
            var responseQuestionary = await this.testQuestionService.getByActivationQuestionaryId(this.pActivationQuestionaryId);
            this.pResponseQuestionaryId = responseQuestionary.id;
            this.getNewToken(106);
          } else {
            this.hasListActivation = true;
          }
        }
        
      }
  }
  private async viewResponse(activationQuestionaryId: number) {
    try {
      this.person = await this.getPerson();
      this.activationQuestionary = await this.activationQuestionaryService.getByActivationQuestionaryId(activationQuestionaryId);
      this.pActivationQuestionaryId = activationQuestionaryId;
      this.pActivationId = this.activationQuestionary.activationId;
      var responseQuestionary = await this.testQuestionService.getByActivationQuestionaryId(this.pActivationQuestionaryId);
      this.pResponseQuestionaryId = responseQuestionary.id;
      this.getNewToken(106);
      this.hasListActivation = false;
    }
    catch (error) {
      this.notification.error(error);
    }

  }
  getNewToken(num) {
    this.vValidate = false;
    this.loadQuestions(num);
  }

  private async loadQuestions(num) {

    this.logger.info("loadQuestions");
    try {
      this.responseQuestionary.id = this.pResponseQuestionaryId;
      this.responseQuestionary.personId = this.personId;
      this.responseQuestionary.activationQuestionaryId = this.pActivationQuestionaryId;
      this.responseQuestionary.activationId = this.pActivationId;

      let obj = await this.testQuestionService.getResponsesToRateByQuestionaryId(this.responseQuestionary);
      let activationQuestionary = obj.activationQuestionary;
      this.pQuestionaryId = activationQuestionary['questionaryId'];

      let responseRatedQuestionary = obj.responseRatedQuestionary;

      this.responseRatedQuestionModel = responseRatedQuestionary.responseRatedQuestions;

      let count = 0
      for (let y = 0; y < this.responseRatedQuestionModel.length; y++) {

        if (this.responseRatedQuestionModel[y]['topic'] == num) {
          this.question = this.responseRatedQuestionModel[y]['question']['formulation'];
          this.idPregunta = this.responseRatedQuestionModel[y]['order'];
        }

        count++;
      }
      //clear variables qualification and observation
      this.exampleFormControlTextarea1 = "";
      let i = 0;



      for (let x = 0; x < this.responseRatedQuestionModel.length; x++) {

        //charge question selected
        if (this.responseRatedQuestionModel[x]['topic'] == num) {

          //nombre pregunta          
          let answer = this.responseRatedQuestionModel[x]['answer'];
          this.logger.debug("validation", answer);          
          //topic
          this.pTopic = this.responseRatedQuestionModel[x]['topic'];

          //video url
          if (this.responseRatedQuestionModel[x]['topic'] == this.generalTopic){
            this.videoSrc = '';
          }
          else{
            let videoUrl = answer['openResponseText'];
            this.videoSrc = videoUrl;
          }

          //qualification

          let ratedAnswer = this.responseRatedQuestionModel[x]['ratedAnswer'];
          this.logger.debug("validation", ratedAnswer);
          if (ratedAnswer == null) {
            //value                    
            let value = 0;

            let stats = (110 - value) / 110;
            //this.selectedProductId=true;
            this.logger.debug("validation", stats); 
            let i = 1;
            for (let option of this.options) {
              option.selected = false;
              this.selectedOption = option;
              if (i === stats) {
                option.selected = false;
                this.selectedOption = option;
              }
              i++;

            }

          }
          if (ratedAnswer !== null) {
            //observation con bind
            this.exampleFormControlTextarea1 = ratedAnswer['observation'];

            //value            
            let value = ratedAnswer['value'];
            if (value !== 0) {
              let stats = (110 - value) / 10;
              //this.selectedProductId=true;
              this.logger.debug("validation", stats); 
              let i = 1;
              for (let option of this.options) {
                option.selected = false;
                if (i === stats) {
                  option.selected = true;
                  this.selectedOption = option;
                }
                i++;

              }
            }

            return false;
          }

        }//i

        i++;

      }

    }
    catch (error) {
      this.notification.error(error);
    }



  }

  private async getPerson(): Promise<Person> {
    return this.personService.getById(this.personId);
  }

  private async sendQualification() {
    this.logger.info("sendQualification");

    let a: any;
    //validate field observation
    if (this.exampleFormControlTextarea1 === "") {
      this.vValidate = true;
      return false;
    }
    this.vValidate = false;
    // value stars   
    this.stars = this.selectedOption['id'];
    this.imagenHide = true;
    this.logger.debug("validation", this.stars);     
    if (this.stars !== undefined) {
    } else {
      this.stars = 0;
    }

    //create parameters save/update qualification    
    let pStamp = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
    this.stars = this.stars * 10;
    this.stars = 110 - this.stars;
    if (this.stars >= 110) { this.stars = 0; }

    this.result.personId = this.personId,
      this.result.activationId = this.pActivationId,
      this.result.value = this.stars,
      this.result.stamp = pStamp,
      this.result.observation = this.exampleFormControlTextarea1,
      this.result.topic = this.pTopic
    this.result.questionaryId = this.pQuestionaryId;
    try {
      let obj = await this.testQuestionService.save(this.result);

      this.notification.success("main.test.pages.people.people_videointerview_edit.success_message");
    } catch (error) {
      this.notification.error(error);
    }

    this.imagenHide = false;


  }

  public async shareVideoInterview(): Promise<void> {
    let data: RequestQualificatoniVideoInterview = {
      companyId: this.companyId,
      personId: this.person.id,
      url: "",
      destinationEmail: ""
    }
  
    try {
      const response = await this.dialogService.open({
        viewModel: RequestQualificationVideoInterviewModal,
        model: data
      }).whenClosed();
  
      if (!response.wasCancelled) {
        let date = new Date();
        let body: RequestVideoInterviewQualification = {
          activationQuestionaryId: this.activationQuestionary.id,
          activationId: this.activationQuestionary.activationId,
          personId: this.person.id,
          date: date.getTime(),
          companyId: this.person.companyId,
          email: data.destinationEmail
        };
        let encode = await this.cryptoService.encode(JSON.stringify(body), config.KEY_CRYPTO);
        let reference = encode.replace(/\+/g, config.KEY_PLUS).replace(/\//g, config.KEY_SLASH).replace(/=/g, config.KEY_EQUAL);
        let notManualUrl = config.URLS_ROBOTHUNTER + "RequestVideoInterview/" + reference;
        data.url = notManualUrl;
        await this.responseQuestionaryVideoInterviewService.requestQualificationVideoInterview(data)
        .then(() => {
          this.notification.success("main.admin.pages.people-videointerview.request-qualification-video-interview-modal.notifications.succes_send_email");
        })
        .catch(error => {
          this.notification.error(error);
        });
      }
    } catch (error) {
      this.notification.error(error.message);
    }
  }  
}
