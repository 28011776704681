import { I18N } from 'aurelia-i18n';
import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration, RouteConfig } from "aurelia-router";
import { PLATFORM } from "aurelia-pal";

import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject()
export class TestChildRouter {

  private logger: Logger;

  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("TestChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map([
      {
        route: ["", "test-home"],
        moduleId: PLATFORM.moduleName("./pages/home/home"),
        name: "test-home",
        nav: true,
        title: this.i18n.tr("main.test.pages.home.title"),
        settings: {
          icon: "ticon-modulo-test",
          isHome: true
        }
      },

      {
        route: "people",
        moduleId: PLATFORM.moduleName("./pages/people/person-list-child-router"),
        name: "person-list-child-router",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestPersonView),
        title: this.i18n.tr("main.test.pages.people.title"),
        settings: {
          icon: "ticon-persona"
        }
      },

      {
        route: "positions",
        moduleId: PLATFORM.moduleName("./pages/position/position-list-child-router"),
        name: "position-list-child-router",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestPositionView),
        title: this.i18n.tr("main.test.pages.positions.title"),
        settings: {
          icon: "ticon-puesto"
        }
      },

      {
        route: "teams",
        moduleId: PLATFORM.moduleName("./pages/team/team-list-child-router"),
        name: "team-list-child-router",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestPositionView),
        title: this.i18n.tr("main.test.pages.teams.title"),
        settings: {
          icon: "ticon-equipo"
        }
      },
      
      {
        route: "organizations",
        moduleId: PLATFORM.moduleName("./pages/organization/organization-list-child-router"),
        name: "organization-list-child-router",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestPositionView),
        title: this.i18n.tr("main.test.pages.organizations.title"),
        settings: {
          icon: "ticon-organizacion"
        }
      },

      {
        route: "tags",
        moduleId: PLATFORM.moduleName("./pages/tags/tags-list-child-router"),
        name: "tags-list-child-router",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestPersonView),
        title: this.i18n.tr("main.test.pages.tags.title"),
        settings: {
          icon: "bookmarks"
        }
      },

      {
        route: "archives",
        moduleId: PLATFORM.moduleName("./pages/archive/archive-list-child-router"),
        name: "archive-list-child-router",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestArchiveView),
        title: this.i18n.tr("main.test.pages.archive.title"),
        settings: {
          icon: "archive"
        }
      },

    ]);

  }

}
