import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Wallet } from "models/talents/wallet";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import config from 'config.js';

@autoinject
export class WalletService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("TalentWalletService");
  }

  public async getAll(): Promise<Wallet[]> {
    return await this.client.get("wallet")
      .then((response: Wallet[]) => {
        return response;
      });
  }

  public async getByUserId(id: number): Promise<Wallet> {
    return await this.client.get(`wallet`, id)
      .then((response: Wallet) => {
        return response;
      });
  }

  public async balancesList(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("wallet/balances-list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getGeneralWallet(companyId: number): Promise<Wallet> {
    let backendUrl = config.URLS_BACKEND;
    this.client.baseUrl =  `${backendUrl}/api/v1/company/${companyId}/`;
    return await this.client.get("wallet/general-wallet")
      .then((response: Wallet) => {
        return response;
      });
  }

  public async getUserWallet(id: number, companyId: number): Promise<Wallet> {
    let backendUrl = config.URLS_BACKEND;
    this.client.baseUrl =  `${backendUrl}/api/v1/company/${companyId}/`;
    return await this.client.get(`wallet/user-wallet/${id}`)
      .then((response: Wallet) => {
        return response;
      });
  }
}
