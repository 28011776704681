import { Logger } from 'aurelia-logging';
import { autoinject, bindable, LogManager, observable } from 'aurelia-framework';
import { Company } from 'models/companies/company';
import { CompanyInfoService } from '../services/companies/company-info-service';
import { Person } from 'models/test-person/person';
import { Router } from 'aurelia-router';
import { InboxByUserStatus } from 'models/test-core/inbox/inboxByUserStatus';
import { Inbox } from 'models/test-core/inbox/inbox';
import { AgreementService } from 'main/admin/services/agreements/agreement-service';
import { InboxService } from 'main/admin/services/test-core/inbox-service';
import { Notification } from "common/services/notification";
@autoinject()
export class InboxNotification {
  private logger: Logger;

  @bindable
  @observable
  private currentCompany: Company;

  @bindable
  @observable
  private currentPerson: Person;

  private logoUrl: string;

  public isDisable: boolean = false;
  public currentCompanyId: number;
  private inbox: Inbox = new Inbox();
  private inboxList: Inbox[] = [];
  private unRead: number;
  private buttonDisable: boolean = true;
  constructor(
    private companyInfoService: CompanyInfoService,
    private agreementService: AgreementService,
    private inboxService: InboxService,
    private router: Router,
    private notification: Notification,
  ) {

    this.logger = LogManager.getLogger("CompanyLogo");
  }

  public async currentCompanyChanged() {
    
    if (this.currentCompany != null) {  
      this.buttonDisable = false;   
      this.currentCompanyId = this.currentCompany.id;
      await this.getInboxByCurrentCompany(this.currentCompanyId);
    } 
    else 
      this.buttonDisable = true;   
  }

  public async currentPersonChanged() {
  
    if (this.currentPerson != null) {
      this.buttonDisable = false;
      this.currentCompanyId = this.currentPerson.companyId;
      await this.getInboxByCurrentCompany(this.currentCompanyId);
    }
    else 
      this.buttonDisable = true;  
  }
  
  public submit(){
    this.isDisable = true;
    this.router.navigate(`company/${this.currentCompanyId}/admin/inbox`);
  }


  private async getInboxByCurrentCompany(companyId: number){
    try {
      
      let planIdsTest = (await this.agreementService.getAgreementsByCompanyId(companyId,false)).map(x=> x.id);
       let planIdsSelection = (await this.agreementService.getAgreementsByCompanyId(companyId,true)).map(x=> x.id);
  
      if (planIdsTest.length > 0 )
        this.inbox.planTest = planIdsTest;
      if (planIdsSelection.length > 0)
        this.inbox.planSelection = planIdsSelection;  
  
        this.unRead = (await this.inboxService.getAllByCompany(companyId,this.inbox)).filter(x=> x.inboxByUserStatus == InboxByUserStatus.Received).length;
       
        if (this.unRead > 99)       
        this.unRead = 99;  

        if (this.unRead == 0)
        this.isDisable = true;

    } catch (error) { 
      this.notification.error(error);
    }
  }
}
