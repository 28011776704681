import { LogManager, autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { AppHttpClient } from "common/services/app-http-client";
import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from 'common/resources/elements/ux/datatable/ux-datatable';
import { QuestionAssociation } from 'models/thematic-groups/question-association';

@autoinject()
export class ThematicAssociationService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger('ThematicAssociationService');
  }

  public async list(thematicGroupId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`thematicassociation/list/${thematicGroupId}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

  public async update(model: QuestionAssociation): Promise<QuestionAssociation> {
    return await this.client.updateById('thematicassociation', model.id, null, model)
      .then(response => {
        return <QuestionAssociation>response;
      });
  }
}
