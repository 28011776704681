import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";

import { Label } from "models/localizations/label";
import { UxDatatableResponse, UxDatatableParameters } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class LabelService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("LabelService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("label/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<Label> {
    return await this.client.getById("label", id)
      .then((response: any) => {
        return <Label>response;
      });
  }

  public async update(model: Label): Promise<void> {
    return await this.client.updateById("label", model.id, null, model)
      .then(() => null);
  }

  public async getAll(): Promise<Label[]> {
    return await this.client.get("label")
      .then((response: any) => {
        return <Label[]>response;
      });
  }


  public async create(model: Label): Promise<Label> {
    return await this.client.create("label", model)
      .then((response: any) => {
        return <Label>response;
      });
  }

  public async delete(id: number): Promise<void> {    
    return await this.client.deleteById("label", id)
      .then(() => null);
  } 
}
