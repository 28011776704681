import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { User } from "models/security/user";

@autoinject
export class CoreUserService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("CoreGroupService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("coreuser/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<User[]> {
    return await this.client.get("coreuser")
      .then((response: User[]) => {
        return response;
      });
  }

  public async getByRole(roleId: number): Promise<User[]> {
    return await this.client.get(`coreuser/role/${roleId}`)
      .then((response: User[]) => {
        return response;
      });
  }

  public async getById(id: number): Promise<User> {
    return await this.client.getById("coreuser", id)
      .then((response: User) => {
        return response;
      });
  }

  public async create(model: User): Promise<User> {
    return await this.client.create("coreuser", model)
      .then((response: User) => {
        return response;
      });
  }

  public async update(model: User): Promise<void> {
    return await this.client.updateById("coreuser", model.id, null, model)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {
    return await this.client.post(`coreuser/change-state/${id}`)
      .then(() => null);
  }

  public async resendActivationMessage(id: number): Promise<void> {
    return await this.client.post(`coreuser/resend-activation-message/${id}`)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("coreuser", id)
      .then(() => null);
  }
}
