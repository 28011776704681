
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject
export class BulkActivationItemListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {

    this.logger = LogManager.getLogger("BulkActivationItemListChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./bulk-activation-item-list"),
        nav: true,        
        name: "bulk-activation-item-list",
        title: this.i18n.tr("main.test.pages.bulk_activations.bulk_activation"),
      },
      // {
      //   route: "evaluator/:evaluator_id",
      //   moduleId: PLATFORM.moduleName("./organization-evaluator-edit"),
      //   name: "organization-evaluator-edit",
      // },
    ]);
  }
}
