import { ActivationService } from 'main/test/services/people/activation-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Person } from "models/test-person/person";
import { PersonService } from "../../services/people/person-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PersonActivationType } from "models/test-person/personActivationType";
import { Result } from "models/test-result/result";
import { ResultService } from "main/test/services/result/result-service";
import { DiscAdjectiveService } from "main/test/services/core/disc-adjective-service";
import { DiscAdjective } from "models/test-core/discAdjective";
import { Disc } from "models/questionaries/disc";
import { Activation } from "models/test-person/activation";
import { DiscType } from "models/questionaries/discType";
import { WhoCanSeeReport } from "models/test-person/whoCanSeeReport";

@autoinject()
export class HomologationEdit {

  private logger: Logger;

  private companyId: number;

  private model: Person = new Person();

  private resultDiscNaturalList: Result[] = [];
  private resultDiscAdaptedList: Result[] = [];
  private discAdjetives: DiscAdjective[] = [];

  private validationController: ValidationController;

  constructor(
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private personService: PersonService,
    private resultService: ResultService,
    private discAdjectiveService: DiscAdjectiveService,
    private activationService: ActivationService
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("HomologationEdit");
    this.model.tags = "";
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("firstName").displayName("main.test.pages.people.first_name").required()
      .ensure("lastName").displayName("main.test.pages.people.last_name").required()
      .ensure("email").displayName("main.test.pages.people.email").email().required()
      .ensure("document").displayName("main.test.pages.people.document").required()
      .ensure("activation.applicationDate").displayName("main.test.pages.people.homolotation.activation_date").required()
      .on(this.model);

    ValidationRules
      .ensure("applicationDate").displayName("main.test.pages.people.homolotation.activation_date").required()
      .on(this.model.activation);
  }

  public async activate(params: any): Promise<any> {
    this.logger.debug("activate");

    try {

      if (!isNaN(params.companyId)) {
        this.companyId = +params.companyId;

        this.personService.client.currentCompanyId = this.companyId;

        if (params.personId == 'create') {
          this.resultDiscNaturalList = await this.getDiscData();
          this.resultDiscAdaptedList = await this.getDiscData();

          let activation = new Activation();
          activation.personActivationType = PersonActivationType.Homologation;
          activation.whoCanSeeReport = WhoCanSeeReport.OnlyOrganization;
          this.model.activation = activation;
        }
        else if (!isNaN(params.personId) && !isNaN(params.activationId)) {

          this.model = await this.personService.getById(params.personId);

          this.model.activation = await this.activationService.getById(params.activationId);

          this.resultDiscNaturalList = await this.getDiscDataFromActivation(params.personId, params.activationId, DiscType.Natural);
          this.resultDiscAdaptedList = await this.getDiscDataFromActivation(params.personId, params.activationId, DiscType.Adapted);
        }


        const adjectives = await this.getDiscAdjetives();

        this.discAdjetives = adjectives.map(itm => {
          let range = itm.scoreRange.split('-');
          let minRange = parseInt((range[0] || '0').trim());
          let maxRange = parseInt((range[1] || '0').trim());
          itm['min'] = minRange;
          itm['max'] = maxRange;
          return itm;
        });

        this.configureValidationRules();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public async submit(keepEditing: boolean): Promise<void> {

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {

          if (this.resultDiscNaturalList.map(x => x.value).every(x => x == 0)
            && this.resultDiscAdaptedList.map(x => x.value).every(x => x == 0)) {
            this.notification.warning("main.test.pages.people.homologation.errors.values_are_zeros");
          }
          else {
            return await this.save(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.resultService.client.isRequesting;
  }

  private async save(keepEditing: boolean): Promise<any> {

    try {

      this.model.stamp = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
      // get highest disc
      let maxItem: any = { value: 0 };
      this.resultDiscNaturalList.forEach(itm => {
        if (maxItem.value < itm.value) {
          maxItem = itm;
        }
      });

      this.model.highestDisc = maxItem.disc;
      this.model.lastDiscApplicationDate = this.model.activation.applicationDate;

      // create person and activation
      if (!this.model.id) {

        this.model = await this.personService.create(this.model);

        await this.saveActivation(this.model.id, this.model.activation.id);
      }
      else {
        await this.personService.update(this.model);

        await this.activationService.update(this.model.activation);

        await this.saveActivation(this.model.id, this.model.activation.id);
      }

      if (!keepEditing) {
        return await this.router.navigateToRoute("people");
      }
    }
    catch (error) {
      this.notification.error(error.error);
    };
  }


  private async getDiscData(): Promise<Result[]> {

    let discIds = Object.keys(Disc).filter(k => typeof Disc[k] !== 'number');

    let resultDiscList: Result[] = [];

    discIds.forEach(itm => {
      resultDiscList.push({
        disc: Disc[itm],
        value: 50
      });

    });

    return resultDiscList;
  }

  private async getDiscDataFromActivation(personId: number, activationId: number, discType: DiscType): Promise<Result[]> {

    let allPersonDisc = await this.resultService.getAllByPersonId(personId, activationId);

    let discIds = Object.keys(Disc).filter(k => typeof Disc[k] !== 'number');

    let resultDiscList: Result[] = [];

    discIds.forEach(itm => {
      let filteredItems = allPersonDisc.filter(p => Disc[p.disc] === Disc[itm] && p.discType == discType);

      if (filteredItems.length) {
        resultDiscList.push({
          personId: personId,
          activationId: personId,
          disc: Disc[itm],
          value: filteredItems[0].value
        })
      } else {
        resultDiscList.push({
          personId: personId,
          disc: Disc[itm],
          value: 50
        })
      }
    })

    return resultDiscList;
  }

  private calculateLabel(value: number, disc: Disc): string {
    const itm = this.discAdjetives.find((itm: any) => value >= itm.min && value <= itm.max && itm.disc === Disc[disc]);
    return itm ? itm.name : '';
  }

  private async getDiscAdjetives(): Promise<DiscAdjective[]> {
    return await this.discAdjectiveService.getAll();
  }

  private async saveActivation(personId: number, activationId: number): Promise<any> {

    let promises = [];

    this.resultDiscNaturalList.forEach(itm => {
      itm.discType = DiscType.Natural;
      itm.personId = personId;
      itm.activationId = activationId;
      itm.stamp = this.model.stamp;
      promises.push(this.resultService.createToPerson(itm));
    });

    this.resultDiscAdaptedList.forEach(itm => {
      itm.discType = DiscType.Adapted;
      itm.personId = personId;
      itm.activationId = activationId;
      itm.stamp = this.model.stamp;
      promises.push(this.resultService.createToPerson(itm));
    });

    Promise.all(promises)
      .then(async () => {
        this.notification.success("notifications.item_edited");
        // return await this.router.navigateToRoute("people");
      })
      .catch(error => {
        this.notification.error(error);
      });

    return true;
  }

}
