import { inject, newInstance, LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { MessageTemplate } from "models/messages/message-template";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";

@autoinject
export class MessageTemplateService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("MessageTemplate");
  }

  public async getById(id: number): Promise<MessageTemplate> {
    return await this.client.getById("messagetemplate", id)
      .then((response: any) => {
        return <MessageTemplate>response;
      });
  }

  public async getMessageByTemplateName(templateName: string): Promise<MessageTemplate> {
    return await this.client.get(`messagetemplate/template/${templateName}`)
      .then((response: any) => {
        return <MessageTemplate>response;
      });
  }

  // public async getTemplatesByIdCompany(CompanyId: number): Promise<Array<MessageTemplate>> {
  //   return await this.client.get(`messagetemplate/getTemplatesByIdCompany/${CompanyId}`)
  //     .then(response => {
  //       return <Array<MessageTemplate>>response;
  //     })
  //     .catch(error => {
  //       this.logger.error(error.message);
  //       throw error;
  //     });
  // }

  public async getAll(): Promise<Array<MessageTemplate>> {
    return await this.client.get("messagetemplate/get-all")
      .then(response => {
        return <Array<MessageTemplate>>response;
      })
      .catch(error => {
        this.logger.error(error.message);
        throw error;
      });
  }


  public async update(model: MessageTemplate): Promise<void> {
    return await this.client.updateById("messagetemplate", model.id, null, model)
      .then(() => null);
  }

  public async create(model: MessageTemplate): Promise<MessageTemplate> {
    return await this.client.create("messagetemplate", model)
      .then((response: any) => {
        return <MessageTemplate>response;
      });
  }

  /* public async update(model: CompanyDetail): Promise<CompanyDetail> {
     return await this.client.updateById("companydetail", model.id, null, model)
     .then((response: any) => {
       return <CompanyDetail>response;
     });
   }
 
   public async delete(id: number): Promise<void> {
     return await this.client.deleteById("companydetail", id)
       .then(() => null);
   }
 
   public async changeState(id: number): Promise<void> {    
     return await this.client.post(`companydetail/change-state/${id}`)
     //return await this.client.post("companydetail/change-state",id)
       .then(() => null);
   }*/

}
