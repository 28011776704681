import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { LogManager, autoinject } from 'aurelia-framework';
import { ActivationQuestionaryService } from 'main/test/services/people/activation-questionary-service';
import { ActivationQuestionary } from 'models/test-person/activationQuestionary';
import { CompanyCompetence } from 'models/test-core/competences/company-competence';

@autoinject()
export class CompetenceActivatedModal {

  private logger: Logger;
  private activationQuestionary: ActivationQuestionary;
  private companyCompetences: CompanyCompetence[] = [];

  constructor(private dialogController: DialogController,
    private activationQuestionaryService: ActivationQuestionaryService) {
  }

  public async activate(param: number | CompanyCompetence[]) {
    if (typeof param === "number")
      await this.getByActivationQuestionary(param)
    else
      this.companyCompetences = param;

    this.logger = LogManager.getLogger("CompetenceActivationModal")
  }

  private async getByActivationQuestionary(activationQuestionary: number) {
    this.activationQuestionary = await this.activationQuestionaryService.getCompanyCompetenceById(activationQuestionary);
    if (this.activationQuestionary.activation.companyCompetences.length > 0)
      this.companyCompetences = this.activationQuestionary.activation.companyCompetences
  }

}