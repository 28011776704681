import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Plan } from "models/plans/plan";

@autoinject()
export class AgreementPlanService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("AgreementPlanService");
  }

  public async getBillablePlansByFranchiseId(franchiseId: number): Promise<Plan[]> {
    return await this.client.get(`agreementplan/get-billable-plans/${franchiseId}`)
      .then((response: Plan[]) => {
        return response;
      })
  }

  public async getById(planId: number): Promise<Plan> {
    return await this.client.getById("agreementplan", planId)
      .then((response: any) => {
        return <Plan>response;
      });
  }

  public async getTestPlanItemsByPlanId(planId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`agreementplan/items/${planId}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }
}
