import { Activation } from '../../../../models/test-person/activation';
import { TeamPerson } from 'models/team/teamPerson';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { TeamPersonActivationService } from 'main/test/services/team/team-person-activation-service';

@autoinject()
export class TeamPersonActivationListModal {

  private model: TeamPerson = new TeamPerson();

  private activations: Array<Activation> = [];

  private logger: Logger;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private teamPersonActivationService: TeamPersonActivationService,
  ) {

    this.logger = LogManager.getLogger("TeamPersonActivationListModal");

  }

  public async activate(model: TeamPerson): Promise<void> {
    this.logger.debug("activate");
    this.model = model;

    try {
      this.activations = await this.teamPersonActivationService.getByPersonId(this.model.personId);
    }
    catch (error) {
      this.notification.error(error);
    }

  }

  private async submit(): Promise<any> {
    return await this.dialogController.ok();
  }

}
