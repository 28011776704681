import { PersonActivationType } from '../../../../models/test-person/personActivationType';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { autoinject, LogManager, bindable, Disposable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { Person } from "models/test-person/person";
import { ActivationService } from 'main/test/services/people/activation-service';

@autoinject()
export class ObservationList {

  private logger: Logger;
  private params: any = {};

  @bindable
  public companyId: number;

  @bindable
  private person: Person;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;


  constructor(
    private activationService: ActivationService,
    private notification: Notification
  ) {
    this.logger = LogManager.getLogger("ObservationList");
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.activationService.list(this.parameters, this.person.id)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async bind(): Promise<any> {
    if (this.person) {

      this.activationService.client.currentCompanyId = this.person.companyId;

      // filter activation by homologation
      this.parameters.search.PersonActivationType = PersonActivationType.Observed;

      return await this.databind();
    }

  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<any> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

}
