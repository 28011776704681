import { DialogCloseResult, DialogService } from "aurelia-dialog";
import { autoinject, bindable, LogManager } from "aurelia-framework";
import { I18N } from 'aurelia-i18n';
import { Logger } from "aurelia-logging";
import { UxDatatableCustomAttribute, UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { LanguageService } from 'main/admin/services/localizations/language-service';
import { Sheet } from 'models/test-core/sheets/sheet';
import { SheetLanguage } from 'models/test-core/sheets/sheetLanguage';
import { ChangeStateConfirmation } from "../../../../components/change-state-confirmation";
import { CoreSheetLanguageService } from '../../../services/test-core/core-sheet-language-service';
import { CoreSheetService } from '../../../services/test-core/core-sheet-service';
import { SheetLanguageModal } from "./sheet-language-modal";


@autoinject()
export class SheetList {

  private logger: Logger;
  private languages;

  @bindable
  public languageId;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  public state: State;

  constructor(
    private store: StoreService,
    private languageService: LanguageService,
    private i18n: I18N,
    private coreSheetService: CoreSheetService,
    private coreSheetLanguageService: CoreSheetLanguageService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("SheetList");

    this.state = this.store.state;

  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    return await this.coreSheetService.list(this.languageId, this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any): Promise<void> {


  }


  private async bind(): Promise<any> {
    this.logger.debug("bind");

    this.languages = await this.languageService.getAll();
    this.setUserLanguage();

    return await this.databind();

  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find((lg: { culture: string; }) => lg.culture === activeCulture);
      if (activeLang) {
        this.languageId = activeLang.id;
      }
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async edit(item: Sheet): Promise<void> {
    let orginialItem = JSON.parse(JSON.stringify(item));

    return await this.dialogService.open({
      viewModel: SheetLanguageModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.coreSheetLanguageService.update(item, this.languageId)
          .then(async (model: SheetLanguage) => {

            this.logger.debug("model.id", model.id);
            // item.id = model.id;
            item.sheetLanguages[0].thumbnailImageUrl = model.thumbnailImageUrl;
            this.notification.success("main.admin.pages.test_core.sheets.notifications.edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        Object.assign(item, orginialItem);
      }
    });
  }

  private async changeState(item: Sheet): Promise<void> {

    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: this.i18n.tr(item.name)
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.coreSheetService.changeState(item.id)
          .then(() => {

            // sheet.isActive = !sheet.isActive;
            this.notification.success("main.admin.pages.test_core.sheets.notifications.state_changed");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        item.isActive = !item.isActive;
      }
    });
  }

}
