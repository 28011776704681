import { EmailHistoryDetails } from '../../../../models/emails-history/email-history-details';
import { DialogController } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from 'common/services/notification';
import { EmailHistoryService } from "main/test/services/people/email-history-service";

@autoinject()
export class PeopleHistoryEmailDetailsModal {
  private logger: Logger;
  private model: EmailHistoryDetails

  constructor(
    private dialogController: DialogController,
    private historyEmailService: EmailHistoryService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("PeopleHistoryEmailDetailsModal");
  }

  public async activate(msgId: string): Promise<any> {
    this.logger.debug("activate", msgId);
    try {
      this.model = await this.historyEmailService.getById(msgId);
    } catch (error) {
      this.notification.error(error);
    }
  }
}
