import { CompanySettings } from 'models/companies/company-settings';
import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { AppHttpClient } from "common/services/app-http-client";
import { Company } from "models/companies/company";

@autoinject
export class CoreCompanyService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger('CoreCompanyService');
  }

  public async getAll(): Promise<Company[]> {
    return await this.client.get("corecompany")
      .then((response: Company[]) => {
        return response;
      });
  }

  public async getById(id: number): Promise<Company> {
    return await this.client.getById("corecompany", id)
      .then((response: Company) => {
        return response;
      });
  }

  public async getSettingsById(id: number): Promise<CompanySettings> {
    return await this.client.get(`corecompany/${id}/settings`)
      .then((response: CompanySettings) => {
        return response;
      });
  }

  public async getByRoleId(roleId: number): Promise<Company[]> {
    return await this.client.get(`corecompany/role/${roleId}`)
      .then((response: Company[]) => {
        return response;
      });
  }

}
