import { AgreementStatus } from 'models/agreements/agreementStatus';
import { Notification } from 'common/services/notification';
import { AgreementCustomerReqService } from '../../../services/agreements/agreement-customer-req-service';
import { AgreementCustomerReq } from 'models/agreements/agreementCustomerReq';
import { Agreement } from 'models/agreements/agreement';
import { Router } from 'aurelia-router';
import { ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { AgreementService } from '../../../services/agreements/agreement-service';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { ValidationControllerFactory } from 'aurelia-validation';
import { autoinject, LogManager } from "aurelia-framework";
import { ValidationController } from 'aurelia-validation';
import { Logger } from 'aurelia-logging';
import { BusinessCompanyService } from 'main/admin/services/franchises/business-company-service';
import { BankInformationService } from 'main/admin/services/franchises/bank-information-service';
import { BusinessCompany } from 'models/franchises/businessCompany';
import { BankInformation } from 'models/franchises/bankInformation';

@autoinject()
export class AgreementCustomerReqEdit {

  private logger: Logger;
  private agreement: Agreement;
  private agreementId: number;
  private companyId: number;
  private franchiseId: number;

  private businessCompanies: BusinessCompany[] = [];
  private bankInformations: BankInformation[] = [];

  private model: AgreementCustomerReq = new AgreementCustomerReq();
  private validationController: ValidationController;

  private disable: boolean = false;

  private params: any = {};

  constructor(
    private agreementService: AgreementService,
    private agreementCustomerReqService: AgreementCustomerReqService,
    private businessCompanyService: BusinessCompanyService,
    private bankInformationService: BankInformationService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("AgreementCustomerReqEdit")
  }

  public async activate(params: any): Promise<void> {
    this.logger.info("activate");
    this.params = params;
  }

  public async bind(): Promise<any> {
    if (!isNaN(this.params.agreementId) && !isNaN(this.params.franchiseId)) {
      this.franchiseId = this.params.franchiseId;
      this.agreementId = this.params.agreementId;

      if (!isNaN(this.params.companyGroupId)) {
        this.companyId = +this.params.companyGroupId;
      }

      if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.agreementId && this.companyId) {
        this.agreementService.client.currentCompanyId = this.companyId;

        try {

          this.businessCompanies = await this.businessCompanyService.getAll(this.franchiseId);

          this.agreement = await this.agreementService.getById(+this.params.agreementId);

          if (this.agreement.agreementStatus !== AgreementStatus.InternalDraft) {
            this.disable = true;
          }

          this.agreementCustomerReqService.client.currentCompanyId = this.companyId;
          this.model = await this.agreementCustomerReqService.getById(+this.params.agreementId);

          if (this.model.id === 0) {
            this.model.agreementId = this.agreementId;
            this.model.agreement = this.agreement;
          }else{
            this.bankInformations = await this.bankInformationService.getAll(this.model.businessCompanyId);
          }

          this.configureValidationRules();
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    }
  }

  private configureValidationRules(): void {
    // this.logger.info("configureValidationRules");
    // this.logger.debug("model", this.model);
    ValidationRules
      .ensure("purchaseOrder").displayName("main.admin.pages.agreements.agreementcustomerreq.purchaseOrder").required()
      .ensure("nameToInvoice").displayName("main.admin.pages.agreements.agreementcustomerreq.nameToInvoice").required()
      .ensure("billingClosingDate").displayName("main.admin.pages.agreements.agreementcustomerreq.billingClosingDate").required()
      .ensure("documentsToRegisterAsProvider").displayName("main.admin.pages.agreements.agreementcustomerreq.documentsToRegisterAsProvider").required()
      .ensure("businessCompanyId").displayName("main.admin.pages.agreements.agreementcustomerreq.companyToDeliverInvoice").required()
      .ensure("bankInformationId").displayName("main.admin.pages.agreements.agreementcustomerreq.bankInformation").required()
      .on(this.model);

  }

  public async submit(keepEditing: boolean): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.agreementCustomerReqService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");

    // TO-DO por default colombia
    // this.model.bankInformationId = 1;
    // this.model.companyToDeliverInvoiceId = 5;

    try {

      if (!this.model.id) {

        this.model = await this.agreementCustomerReqService.create(this.model)

        this.notification.success("notifications.item_added");

        if (!keepEditing) {
          this.router.parent.navigateToRoute("readonly-child-router", { agreementId: this.agreementId });
        }
        else {
          // this.logger.debug("agrrementId: ", this.model.id);
          return await this.router.navigateToRoute("agreements-customer-req");
        }

      }
      else {
        await this.agreementCustomerReqService.update(this.model);

        this.notification.success("notifications.item_edited");

        if (!keepEditing) {
          this.router.parent.navigateToRoute("readonly-child-router", { agreementId: this.agreementId });
        }
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public async filterBank(businessCompanyId: number): Promise<void> {
    this.model.bankInformationId = null;

    this.bankInformations = await this.bankInformationService.getAll(businessCompanyId);
  }
}
