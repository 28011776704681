
import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ReportTemplate } from 'models/test-core/report-templates/report-template';
import { Report } from 'models/test-reports/reports/report';

@autoinject()
export class ReportParameter {

  private logger: Logger;
  private params: any = {};

  @bindable
  private model: Report = new Report();

  @bindable
  private isDisable: boolean;

  @bindable
  private isReportActivation: boolean;

  @bindable
  private reportTemplate: ReportTemplate;

  @bindable
  private showActivationParameter: boolean;
  
  public companyId: number;  

  constructor(  
  ) {  
    this.logger = LogManager.getLogger("ReportParameter");
  
  }

}
