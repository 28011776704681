import { LogManager, autoinject } from "aurelia-framework";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Archetype } from "models/archetypes/archetype";
import { AppHttpClient } from "common/services/app-http-client";

@autoinject()
export class ArchetypeService{
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("ArchetypeService");
  }

  public async getAll(): Promise<Archetype[]> {
    return await this.client.get("archetype")
      .then((response: any) => {
        return <Archetype[]>response;
      });
  }

  public async getById(id: number): Promise<Archetype> {
    return await this.client.getById("archetype", id)
      .then((response: any) => {
        return <Archetype>response;
      });
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("archetype/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async create(model: Archetype): Promise<Archetype> {
    return await this.client.create("archetype", model)
      .then((response: Archetype) => {
        return response;
      });
  }

  public async update(model: Archetype): Promise<void> {
    return await this.client.updateById("archetype", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("archetype", id)
      .then(() => null);
  }
  async changeState(id: number): Promise<void> {    
    return await this.client.post(`archetype/change-state/${id}`)
      .then(() => null);
  }

  
}
