import { ActivationQuestionary } from 'models/test-person/activationQuestionary';
import { ReportActivation } from './../../../../models/test-reports/reports/reportActivation';
import { QuestionaryType } from './../../../../models/questionaries/questionaryType';
import { TestReportChildRouter } from './../../test-report-child-router';
import { OrganizationType } from './../../../../models/questionaries/organizationType';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { RouteConfig, Router } from "aurelia-router";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { ReportTemplateService } from '../../../admin/services/test-management/report-template-service';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { ReportTemplate } from 'models/test-core/report-templates/report-template';
import { Person } from "models/test-person/person";
import { PersonService } from "../../../test/services/people/person-service";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { Report } from 'models/test-reports/reports/report';
import { ReportService } from '../../services/report-service';
import { ItemToQualify } from 'models/questionaries/itemToQualify';
import { addSlash } from 'common/utils/utility';
import { SelectSheetModal } from './select-sheet-modal';
import { Organization } from 'models/test-organization/organization';
import { Team } from 'models/team/team';
import { PositionService } from 'main/test/services/position/position-service';
import { OrganizationService } from 'main/test/services/organization/organization-service';
import { TeamService } from 'main/test/services/team/team-service';
import { Position } from "models/test-position/position";
import { Sheet } from 'models/test-core/sheets/sheet';
import { Parameter } from 'models/test-reports/reports/parameter';
import { NoDiscType } from 'models/questionaries/noDiscType';
import { ActivationQuestionaryService } from 'main/test/services/people/activation-questionary-service';
import { Questionary } from 'models/questionaries/questionary';
import { ActivationQuestionaryStatus } from '../../../../models/test-person/activationQuestionaryStatus';
import config from 'config.js';
import { ActivationService } from 'main/test/services/people/activation-service';
import { ActivationType } from 'models/test-person/activationType';
import { Activation } from 'models/test-person/activation';
@autoinject()
export class ReportEdit {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private sheets: Sheet[];

  private reportTemplates: ReportTemplate[];

  private reportTemplate: ReportTemplate;

  private state: State;
  private isDisable: boolean;
  private isReport: boolean = true;

  private model: Report = new Report();

  private person: Person;
  private position: Position;
  private organization: Organization;
  private team: Team;
  private showActivationParameter: boolean = false;
  private personActivationQuestionaries: ActivationQuestionary[];

  private validationController: ValidationController;

  constructor(
    private router: Router,
    private store: StoreService,
    private reportTemplateService: ReportTemplateService,
    private reportService: ReportService,
    private personService: PersonService,
    private positionService: PositionService,
    private organizationService: OrganizationService,
    private activationQuestionaryService: ActivationQuestionaryService,
    private activationService: ActivationService,
    private teamService: TeamService,
    private dialogService: DialogService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ReportEdit");

    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {

    // this.logger.debug("activate");

    this.params = params;

    this.configureValidationRules();

    if (!isNaN(params.companyId)) {

      this.companyId = +params.companyId;

      if (this.companyId) {
        this.model.companyId = this.companyId;
        this.reportTemplateService.client.currentCompanyId = this.companyId;
        this.personService.client.currentCompanyId = this.companyId;
        this.activationQuestionaryService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        try {
          this.reportTemplates = await this.reportTemplateService.getAll();

          if (!isNaN(params.personId)) {
            this.model.personId = +params.personId;
            this.person = await this.personService.getById(this.model.personId);
          }

          // filter by position
          if (!isNaN(params.positionId)) {
            this.model.positionId = +params.positionId;
            this.position = await this.positionService.getById(+this.params.positionId);
          }

          // filter by organization
          if (!isNaN(params.organizationId)) {
            this.model.organizationId = +params.organizationId;
            this.organization = await this.organizationService.getById(+this.params.organizationId);
          }

          // filter by team
          if (!isNaN(params.teamId)) {
            this.model.teamId = +params.teamId;
            this.team = await this.teamService.getById(+this.params.teamId);
          }

          if (!this.organization) {
            this.filterReportTemplates(params.itemToQualify);
          } else {
            this.logger.debug("filtering by organization type:", this.organization.organizationType);
            this.filterReportTemplates(params.itemToQualify, this.organization.organizationType);
          }

        } catch (error) {
          this.notification.error(error);
        }
      }
    }

  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("reportTemplateId").displayName("main.test_reports.pages.reports.report_template").required()
      .ensure("discType").displayName("main.test_reports.pages.reports.discType.title").required()
      .ensure("zeroOrOnePositionId").displayName("main.test_reports.pages.reports.parameters.ZeroOrOnePosition").maxItems(1)
      .ensure("positionId").displayName("main.test_reports.pages.reports.parameters.Position").required()
      .ensure("teamId").displayName("main.test_reports.pages.reports.parameters.Team").required()
      .ensure("organizationId").displayName("main.test_reports.pages.reports.parameters.Organization").required()
      .ensure("positionIds").displayName("main.test_reports.pages.reports.parameters.Positions").required().minItems(1).maxItems(1000)
      .ensure("compareToPositionId").displayName("main.test_reports.pages.reports.parameters.ComparePosition").required()
      .ensure("compareToTeamId").displayName("main.test_reports.pages.reports.parameters.CompareTeam").required()
      .ensure("compateToOrganizationId").displayName("main.test_reports.pages.reports.parameters.CompareOrganization").required()
      .ensure("compareToPersonId").displayName("main.test_reports.pages.reports.parameters.ComparePerson").required()
      .ensure("personId").displayName("main.test_reports.pages.reports.parameters.Person").required()
      .ensure("personId").displayName("main.test_reports.pages.reports.parameters.Leader").required()
      .ensure("peopleIds").displayName("main.test_reports.pages.reports.parameters.People").required().minItems(1)
      .ensure("oneOrTwoPeopleIds").displayName("main.test_reports.pages.reports.parameters.OneOrTwoPeople").required().minItems(1).maxItems(2)
      .ensure("twoPeopleIds").displayName("main.test_reports.pages.reports.parameters.TwoPeople").required().minItems(2)
      .ensure("threePeopleIds").displayName("main.test_reports.pages.reports.parameters.ThreePeople").required().minItems(1).maxItems(3)
      .ensure("observerIds").displayName("main.test_reports.pages.reports.parameters.Observers").required().minItems(1).maxItems(1000)
      .on(this.model);
  }

  private filterReportTemplates(itemToQualify: ItemToQualify, organizationType?: OrganizationType) {
    if (!isNaN(itemToQualify)) {
      this.reportTemplates = this.reportTemplates.filter(x => x.itemToQualify == itemToQualify);
    }

    if (!isNaN(organizationType)) {
      this.logger.debug("filtering by organization type");
      this.reportTemplates = this.reportTemplates.filter(x => x.organizationType == organizationType || x.organizationType == null);
    }
  }

  public async showParameters(): Promise<any> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid)
          this.isDisable = true;
      });

  }

  public async hideParameters(): Promise<any> {
    this.isDisable = false;
  }

  public async changeReport(): Promise<any> {

    this.logger.debug("changeReport");

    try {
      this.reportTemplate = await this.reportTemplateService.getById(this.model.reportTemplateId);

      // add language
      this.model.languageId = this.reportTemplate.languageId;

      this.personActivationQuestionaries = await this.activationQuestionaryService.getAll(this.model.personId);

      this.model.sheets = this.reportTemplate.sheets;
      this.setParameters(this.model.sheets.map(x => x.id));

      //Include all questionaries by questionaryActivation when Parameter is Activation 
      const activationParameterIndex = this.model.parameters.findIndex(x => x === Parameter.Activation);
      const questionariesByActivations: Questionary[] = [];

      if (activationParameterIndex !== -1) {
        const finalizedActivationQuestionaries = this.personActivationQuestionaries.filter(
          x => x.activationQuestionaryStatus === ActivationQuestionaryStatus.Finalized && x.questionary.itemToQualify !== ItemToQualify.Observed
        );

        finalizedActivationQuestionaries.forEach((activationQuestionaryItem) => {
          const { questionary } = activationQuestionaryItem;
          const existingIndex = questionariesByActivations.findIndex(x => x.id === questionary.id);

          if (existingIndex === -1) {
            questionariesByActivations.push(questionary);
          }

          //Remove Questionary By Company
          if (questionary.companyId !== null) {
            this.removeQuestionaryById(questionariesByActivations, questionary.id);
          }

          // Remove Observed
          if (questionary.itemToQualify === ItemToQualify.Observed) {
            this.removeQuestionaryById(questionariesByActivations, questionary.id);
          }

          // Remove Questionaries based on conditions
          if ([NoDiscType.EngineersTest, NoDiscType.ExpectationsTeam, NoDiscType.Potential, NoDiscType.Performance, NoDiscType.VideoInterview].includes(questionary.noDiscType)) {
            this.removeQuestionaryById(questionariesByActivations, questionary.id);
          }

        });
     
        this.reportTemplate.questionaries = questionariesByActivations;
      }


      let questionaries: Questionary[] = [];
      Object.assign(questionaries, this.reportTemplate.questionaries);

      let discTypeCount: number = 0;
      let indexDisc: number[] = [];
      questionaries.forEach((item, index) => {

        //curriculum
        if (item.questionaryType === QuestionaryType.NoDisc && item.noDiscType === NoDiscType.Curriculum) {
          let qIndex = this.reportTemplate.questionaries.findIndex(x => x.id === item.id);
          this.reportTemplate.questionaries.splice(qIndex, 1);
          // return;
        }

        if (item.questionaryType !== QuestionaryType.Disc) {
          // delete all questionaries selector when only have 1 or none
          let questionaryActivations = this.personActivationQuestionaries.filter(x => x.questionaryId === item.id
            && x.activationQuestionaryStatus === ActivationQuestionaryStatus.Finalized);

          if (questionaryActivations.length < 2) {
            let qIndex = this.reportTemplate.questionaries.findIndex(x => x.id === item.id);
            this.reportTemplate.questionaries.splice(qIndex, 1);
          }
        }
        else {
          // push every one questionary type DISC
          discTypeCount = discTypeCount + this.personActivationQuestionaries.filter(x => x.questionary.questionaryType === QuestionaryType.Disc
            && x.activationQuestionaryStatus === ActivationQuestionaryStatus.Finalized).length;
          indexDisc.push(this.reportTemplate.questionaries.findIndex(x => x.id === item.id));
        }
      });

      if (questionaries.find(x => x.questionaryType === QuestionaryType.Disc)) {
        if (discTypeCount < 2) {
          // delete one questionary type DISC
          this.reportTemplate.questionaries.splice(indexDisc[0].valueOf(), 1);
        }
      }

    } catch (error) {
      this.notification.error(error);
    }
  }

  private setParameters(sheetIds: number[]) {
    // reset variable
    this.model.parameters = [];

    this.model.sheets.forEach(sheet => {
      const index = sheetIds.findIndex(x => x == sheet.id);
      if (index !== -1) {
        for (let par of sheet.parameters) {
          const index1 = this.model.parameters.findIndex(x => x === par.parameter);
          if (index1 === -1) {
            this.model.parameters.push(par.parameter);
          }
        }
      }
    });

    this.logger.debug("Parameters: ", this.model.parameters);
  }

  public async sheetSelect(): Promise<any> {
    return await this.dialogService.open({
      viewModel: SelectSheetModal, //SheetSelectModal,
      model: this.model
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.setParameters(this.model.sheetIds);
      } else {
        this.model.sheetIds = [];
      }
    });
  }

  private async submit(): Promise<any> {

    const indexQuestionary = this.model.parameters.findIndex(x => x == Parameter.Questionary);
    
    if (indexQuestionary !== -1) {
      let questioesDisc = this.reportTemplate.questionaries.filter(x => x.questionaryType === QuestionaryType.Disc);
      let questioesNoDisc = this.reportTemplate.questionaries.filter(x => x.questionaryType !== QuestionaryType.Disc);
      let numberQuestionaries = questioesNoDisc.length;
      if (questioesDisc.length > 0) {
        numberQuestionaries++;
      }
      
      if (numberQuestionaries > this.model.activationQuestionaryIds.length) {
        this.notification.error('main.test_reports.pages.reports.parameters.Questionary_required');
        return;
      }
    }

    const indexObservers = this.model.parameters.findIndex(x => x == Parameter.Observers);

    if (indexObservers !== -1) {

      if (this.model.observerIds.length <= 0) {
        this.notification.error('main.test_reports.pages.reports.parameters.Observer_required');
        return;
      }
    }

    const indexTwoObservers = this.model.parameters.findIndex(x => x == Parameter.TwoObservers);

    if (indexTwoObservers !== -1) {

      if (this.model.observerIds.length <= 1) {
        this.notification.error('main.test_reports.pages.reports.parameters.Observer_required');
        return;
      }
    }

    //evaluate the activation parameter
    const indexActivation = this.model.parameters.findIndex(x => x == Parameter.Activation);

    if (indexActivation !== -1) {
      if (this.model.activations.length <= 0) {
        this.showActivationParameter = true;
        await this.processActivationQuestionaries();
        await this.processObserverActivations();

        this.notification.warning('main.test_reports.pages.reports.parameters.Activation_required');
        this.showParameters();
        return;
      }

      if (this.model.activations.length > 0) {
        let sumWeight = 0;
        let isThereDisc = false;

        for (const reportActivation of this.model.activations) {
          sumWeight += Number(reportActivation.weight);
          const activationQuestionary = reportActivation.activationQuestionary;
          if (activationQuestionary && reportActivation.weight > 0) {
            const isInActivationQuestionaryIds = this.model.activationQuestionaryIds.includes(activationQuestionary.id);
            if (!isInActivationQuestionaryIds) {
              this.model.activationQuestionaryIds.push(activationQuestionary.id);
            }
            if (activationQuestionary.questionary.questionaryType === QuestionaryType.Disc) {
              isThereDisc = true;
            }
          }
        }

        if (sumWeight !== 100) {
          this.notification.error('main.test_reports.pages.reports.parameters.Activation_weight_required');
          return;
        }

        if (isThereDisc && this.model.positionId === undefined) {
          this.notification.error('main.test_reports.pages.reports.parameters.Position_required');
          return;
        }
      }

    }

    const indexQuestionaryTeam = this.model.parameters.findIndex(x => x == Parameter.QuestionaryTeam);

    if (indexQuestionaryTeam !== -1) {

      if (this.model.questionariesTeam.length < 1) {
        this.notification.error('main.test_reports.pages.reports.parameters.Questionary_required');
        return;
      }

      let sumWeight = 0;
      this.model.questionariesTeam.forEach((reportQuestionaryTeam) => {
        sumWeight += Number(reportQuestionaryTeam.weight);
      });

      if (sumWeight < 100 || sumWeight > 100) {
        this.notification.error('main.test_reports.pages.reports.parameters.Activation_weight_required');
        return;
      }

    }

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {

        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.reportService.client.isRequesting;
  }

  private async save(): Promise<any> {
    try {
      let response: Report = await this.reportService.create(this.model);

      let testReportsUrl = config.URLS_REPORTS;

      const url = addSlash(testReportsUrl) + "test-reports/" + response.id;

      window.open(url, "_blank");

      return await this.router.navigateToRoute("reports", this.params);

    } catch (error) {
      this.notification.error(error);
    }

  }

  private filterByQuestionary(activationQuestionaryList: ActivationQuestionary[], activationQuestionary: ActivationQuestionary): ActivationQuestionary[] {
    try {
      let actQuesFiltered = activationQuestionaryList.filter(x => x.questionaryId !== activationQuestionary.questionaryId);
      return actQuesFiltered;
    } catch (error) {
      return activationQuestionaryList;
    }
  }

  private filterByDISC(activationQuestionaryList: ActivationQuestionary[]): ActivationQuestionary[] {
    try {
      let actQuesFiltered = activationQuestionaryList.filter(x => x.questionary.questionaryType !== QuestionaryType.Disc);
      return actQuesFiltered;
    } catch (error) {
      return activationQuestionaryList;
    }
  }

  private removeQuestionaryById(questionaries: Questionary[], id: number): void {
    const index = questionaries.findIndex(x => x.id === id);
    if (index !== -1) {
      questionaries.splice(index, 1);
    }
  }

  private async processActivationQuestionaries(this: ReportEdit) {
    let actQuestionarySelected: ActivationQuestionary[] = [];

    for (const activationQuestionaryId of this.model.activationQuestionaryIds) {
      const activationQuestionarie = this.personActivationQuestionaries.find(x => x.id === activationQuestionaryId)
      if (!activationQuestionarie) continue;

      const activation = await this.activationService.getById(activationQuestionarie.activationId)
      if (!activation) continue;

      const newReportActivation = this.buildNewReportActivation(activationQuestionarie, activation);

      actQuestionarySelected.push(activationQuestionarie);
      this.model.activations.push(newReportActivation);
    }

    //Filter ActivationQuestionnaire with 1 existence but not included on model.activationQuestionaryIds
    const finalizedActivationQuestionaries = this.personActivationQuestionaries.filter(
      x => x.activationQuestionaryStatus === ActivationQuestionaryStatus.Finalized && x.questionary.itemToQualify !== ItemToQualify.Observed
    );

    const generalQuestionariesActivation = finalizedActivationQuestionaries.filter(x => x.questionary.companyId === null);

    let filteredQuestionaryActivation = generalQuestionariesActivation.filter(x => ![NoDiscType.EngineersTest, NoDiscType.ExpectationsTeam,
    NoDiscType.Potential, NoDiscType.Performance, NoDiscType.VideoInterview].includes(x.questionary.noDiscType))

    const hasDiscQuestionary = actQuestionarySelected.some((itemActQuestionary) => {
      return itemActQuestionary.questionary.questionaryType === QuestionaryType.Disc;
    });

    if (hasDiscQuestionary) {
      filteredQuestionaryActivation = this.filterByDISC(filteredQuestionaryActivation);
    }

    actQuestionarySelected.forEach((itemActQuestionary) => {
      filteredQuestionaryActivation = this.filterByQuestionary(filteredQuestionaryActivation, itemActQuestionary);
    });

    for (const activationQuestionaryItem of filteredQuestionaryActivation) {
      const activation = await this.activationService.getById(activationQuestionaryItem.activationId);
      if (!activation) continue;

      const newReportActivation = this.buildNewReportActivation(activationQuestionaryItem, activation);

      this.model.activations.push(newReportActivation);
    }
  }

  private async processObserverActivations(this: ReportEdit) {
    // Process observer activations...
    for (const observerActivationId of this.model.observerIds) {
      const activation = await this.activationService.getById(observerActivationId);
      if (!activation) continue;
      
      const newObserverReportActivation: ReportActivation = {
        reportId: Number(this.model.activations.length),
        activationId: activation.id,
        activation: activation,
        weight: 0,
        isAvailable: true,
      };

      this.model.activations.push(newObserverReportActivation);
    }
  }

  private buildNewReportActivation(activationQuestionarie: ActivationQuestionary, activation: Activation): ReportActivation {
    const newReportActivation: ReportActivation = {
      reportId: Number(this.model.activations.length),
      activationId: activationQuestionarie.activationId,
      activation: activation,
      activationQuestionaryId: activationQuestionarie.id,
      activationQuestionary: activationQuestionarie,
      weight: 0,
      isAvailable: true,
    };

    return newReportActivation;
  }

}
