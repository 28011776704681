import { ViewEngineHooks, View } from 'aurelia-framework';
import { NoDiscType } from './noDiscType';

export class NoDiscTypeViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['NoDiscType'] = NoDiscType;

    view.overrideContext['NoDiscTypes'] = 
      Object.keys(NoDiscType)
        .filter((key) => typeof NoDiscType[key] === 'number');
  }
}
