
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class MessageListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
      
    this.logger = LogManager.getLogger("MessageListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./message-main"),
        name: "messages",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.messages.title"),
      },

      {
        route: "message/:messageId",
        moduleId: PLATFORM.moduleName("./message-child-router"),
        name: "message-child-router",
        //nav: false
      },

      {
        route: 'summary-reports',
        moduleId: PLATFORM.moduleName("./message-summary"),
        name: "message-summary-reports",
        //nav: true,
        title: this.i18n.tr("main.admin.pages.messages.summaryreport"),
        // settings: {
        //   permission: {
        //     only: [PredefinedPermissions.MessageUpdate]
        //   },
        //   t: "main.admin.pages.messages.summaryreport"
        // }
      },

    ]);
  }
}
