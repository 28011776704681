import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyDiscStyle } from "models/test-management/disc-styles/company-disc-style";
import { CompanyDiscProfile } from "models/test-management/disc-profiles/company-disc-profile";

@autoinject()
export class CompanyDiscProfileService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CompanyDiscProfileService");
  }

  public async get(): Promise<CompanyDiscProfile> {
    return await this.client.get("companydiscprofile")
      .then((response: any) => {
        return <CompanyDiscProfile>response;
      });
  }

  public async getDiscByLanguage(languageId: number): Promise<CompanyDiscProfile> {
    return await this.client.get(`companydiscprofile/language/${languageId}`)
      .then((response: any) => {
        return <CompanyDiscProfile>response;
      });
  }

  public async update(languageId: number, model: CompanyDiscProfile): Promise<any> {
    return await this.client.update(`companydiscprofile/language/${languageId}`, null, model)
      .then(() => null);
  }

}
