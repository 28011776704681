import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { QuestionaryService } from "../../services/questionaries/questionary-service";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { ChangeStateConfirmation } from "../../../components/change-state-confirmation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { LanguageService } from "main/admin/services/localizations/language-service";
import { Language } from "models/localizations/language";
import { SearchParams } from "models/questionaries/searchParams";

@autoinject()
export class QuestionaryList {

  private logger: Logger;
  private languages: Language[];
  private selectedLanguageId: number;

  private parameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private languageService: LanguageService,
    private questionaryService: QuestionaryService,
    private dialogService: DialogService,
    private notification: Notification,
    public questionaryType: QuestionaryType) {

    this.logger = LogManager.getLogger("QuestionaryList");
  }

  private refresh = (): Promise<UxDatatableResponse> => {
      return this.questionaryService.list(this.parameters);
  }

  private async bind(): Promise<any> {
    this.languages = await this.languageService.getAll();
    const response: UxDatatableResponse = await this.questionaryService.list(this.parameters);
    this.parameters.tableData = response.data;
    this.parameters.totalRecords = response.totalRecords;
  }
  
  public async selectLanguage() {
    if (this.selectedLanguageId) {
      const language = this.languages.find(x => x.id === this.selectedLanguageId);
      this.parameters.search = <SearchParams>{ LanguageId: language.id };
      await this.bind();
    }
  }
  

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async deletemanager(model: Questionary ): Promise<any> {

    if(model.isOriginal){
     return await this.delete(model.id, model.name);
    }
    else {
      return await this.deleteLoca(model.stringId, model.name);
    }
  }

  public async delete(id: number, name: string) {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.questionaryService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.questionaries.messages.questionary_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  public async deleteLoca(id: string, name: string) {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.questionaryService.deleteLocalization(id)
          .then(() => {
            //this.logger = LogManager.getLogger("deleteLoca;" + id);
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.stringId == id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.questionaries.messages.questionary_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  public async changeState(item: Questionary): Promise<void> {
    this.logger.debug("item.isActive", item.isActive);
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.questionaryService.changeState(item.id)
          .then(() => {
            this.notification.success("main.admin.pages.questionaries.messages.questionary_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else{
        item.isActive = !item.isActive;
      }
    });
  }

}
