import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { CompanyDetail } from "models/companies/company-detail";

@autoinject()
export class CompanyInfoService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("CatalogService");
  }

  
  public async getById(id: number): Promise<CompanyDetail> {
    return await this.client.getById("companyinfo", id)
      .then((response: any) => {
        return <CompanyDetail>response;
      });
  }

  public async getLogoUrl(id: number): Promise<string> {
    return await this.client.get(`companyinfo/logo-url/${id}`)
      .then((response: any) => {
        return <string>response;
      });
  }


}
