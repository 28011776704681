import * as numeral from 'numeral';
import 'numeral/locales';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class CurrencyFormatValueConverter {

  public state: State;

  constructor(
    private store: StoreService,
  ) {
    this.state = this.store.state;
  }

  toView(value) {

    if (this.state && this.state.userBasicInfo && this.state.userBasicInfo.culture) {
      var genericCulture = this.state.userBasicInfo.culture.split("-")[0];

      // console.log("genericCulture" + genericCulture);

      numeral.locale(genericCulture);

      return numeral(value).format('($0,0.00)');
    }
    else {
      return numeral(value).format('($0,0.00)');
    }
  }

  fromView(value) {
    var myNumeral = numeral(value);
    return myNumeral.value();
  }
}
