// import * as introJs from 'intro.js';
import { autoinject, LogManager } from 'aurelia-framework';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { Logger } from 'aurelia-logging';
import { Wootric } from '../../components/wootric';
import { SessionService } from 'common/services/session-service';
import { OpenIdProfile } from 'models/security/open-id-profile';
import { WootricComponentName } from "models/wootric/WootricComponentName";
import { SalesIq } from '../../components/sales-iq'
import { Notification } from 'common/services/notification';
import { NewsService } from 'main/admin/services/test-core/news-service';
import { News } from 'models/test-core/news/news';
import { AgreementService } from 'main/admin/services/agreements/agreement-service';
import { ServiceRatingService } from 'main/admin/services/service-rating/service-rating-service';
import { ServiceRating } from 'models/service-rating/service-rating';
import { SalesIqModel } from 'models/sales-iq/sales-iq-model';
@autoinject
export class MainHome {

  private logger: Logger;
  private state: State;
  // Network
  private imageAddr = "https://s3.us-east-1.amazonaws.com/pro-s3-objectstore/488c1078-ace4-42c6-a5a5-c07b1955173f.png" + "?n=" + Math.random();
  private startTime: number;
  private endTime: number;
  private downloadSize = 1000000; 
  private download = new Image();
  private newsList: Array<News> = [];
  private newsNumber: number = 0;
  private params: any = {};
  private companyId: number;
  private news: News = new News();
  private urlServiceEvaluation: string = "";
  private serviceRating: ServiceRating;
  private salesIqSettings: SalesIqModel;
  public constructor(
    private store: StoreService,
    private salesIq: SalesIq,
    private notification: Notification,
    private agreementService: AgreementService,
    private newsService: NewsService,
    private serviceRatingService: ServiceRatingService,
    
  ) {
    this.logger = LogManager.getLogger('Home');

    this.state = this.store.state;

  }

  public async activate(params: any) {
    
    this.serviceRating = await this.serviceRatingService.getServiceRatingSettings();
    this.salesIqSettings = await this.serviceRatingService.getSalesIqSettings();
    this.urlServiceEvaluation = this.serviceRating.value;

    if(this.salesIqSettings.isVisibleInCommonSettingPage){
      this.salesIq.attached(this.salesIqSettings.value);
    }
    this.params = params;

    if (!isNaN(this.params.companyId)) {
      this.companyId = this.params.companyId;     
    }
    else{
      this.companyId = this.state.currentCompany.id;
    }
    this.getNewsByCurrentCompany(this.companyId)

    this.networkInfo(); 
    
  }

  private async getNewsByCurrentCompany(companyId: number){
    try {
      
      let planIdsTest = (await this.agreementService.getAgreementsByCompanyId(companyId,false)).map(x=> x.id);
       let planIdsSelection = (await this.agreementService.getAgreementsByCompanyId(companyId,true)).map(x=> x.id);
  
      if (planIdsTest.length > 0 )
        this.news.planTest = planIdsTest;
      if (planIdsSelection.length > 0)
        this.news.planSelection = planIdsSelection;
      

      this.newsList = await this.newsService.getAllByCompany(companyId,this.news);
      if(this.newsList){
        this.newsNumber = Math.floor(Math.random() * this.newsList.length);
        this.newsList.forEach((x,index)=>{
          if (index == this.newsNumber)
            x.isClassActivate =true
          else
            x.isClassActivate = false
        });
      }
    } catch (error) {
      
    }
  }
  public networkInfo(){
    this.download.onload = () =>{
      this.endTime = (new Date()).getTime();
      this.showNetworkResults();
    }     
    this.startTime = (new Date()).getTime();
    this.download.src = this.imageAddr;     
  }
    public showNetworkResults(): any{  
        let speedBps: number;
        let speedKbps: number;
        let duration = (this.endTime - this.startTime) / 1000;
        let bitsLoaded = this.downloadSize * 8;
        // Valores de carga de la imagen
        speedBps = Number((bitsLoaded / duration).toFixed(2));
        speedKbps = Number((speedBps / 1024).toFixed(2));
        let speedMbps = Number((speedKbps / 1024).toFixed(2));
        // Validacion a 0.5 Mbps de navegacion.
       if (speedMbps < 5)     
        this.notification.warning("main.test.pages.person.network_speed"); 
    }

  // public introStart() {
  //   // intro.js
  //   introJs().start();
  // }

}
