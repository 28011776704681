import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from "models/companies/company";
import { QuestionaryGroup } from "models/test-management/questionary-groups/questionaryGroup";
import { QuestionaryGroupService } from "main/admin/services/test-management/questionary-group-service";

@autoinject()
export class QuestionaryGroupEdit {

  private logger: Logger;

  public companyId: number;
  private company: Company;

  private model: QuestionaryGroup = new QuestionaryGroup();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private questionaryGroupService: QuestionaryGroupService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("QuestionaryGroupEdit");

    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");

    try {

      if (!isNaN(params.companyId)) {

        this.companyId = +params.companyId;

        this.questionaryGroupService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        if (!isNaN(params.questionaryGroupId)) {

          this.model = await this.questionaryGroupService.getById(+params.questionaryGroupId);
        }

        this.configureValidationRules();

      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.test_management.questionary_groups.name").required()
      .on(this.model);
  }

  private async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.questionaryGroupService.client.isRequesting;
  }

  private async save(): Promise<any> {
    if (!this.model.id) {
      return await this.questionaryGroupService.create(this.model)
        .then(async (model: QuestionaryGroup) => {
          this.notification.success("notifications.item_added");

          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("questionary-groups");
          } else {
            return await this.router.parent.navigate(`questionary-group/${model.id}/questionaries`);
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.questionaryGroupService.update(this.model)
        .then(async () => {
          this.notification.success("notifications.item_edited");

          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("questionary-groups");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

}
