import { autoinject, LogManager, observable } from 'aurelia-framework';
import { buildQueryString, join } from 'aurelia-path';
import { Logger } from 'aurelia-logging';
import { HttpClient } from 'aurelia-fetch-client';

import { User, UserManager } from "oidc-client";

// import environment from "config/environment";
import { SessionService } from "./session-service";
import extend from 'extend';

import FileSaver from 'file-saver';
import { EventAggregator } from 'aurelia-event-aggregator';
import config from 'config.js';

@autoinject()
export class AppHttpClient extends HttpClient {

  private logger: Logger;
  private useTraditionalUriTemplates: boolean = false;
  // private user: User;

  public isRequesting = false;

  constructor(
    protected sessionService: SessionService,
    protected ea: EventAggregator) {

    super();

    this.logger = LogManager.getLogger('AppHttpClient');

    //base api
    let backendUrl = config.URLS_BACKEND;
    this.baseUrl = `${backendUrl}/api/v1/`;
  }

  private async configuteClient(isFormData?: boolean): Promise<any> {
    // configure client
    // this.logger.debug("configuteClient");
    const user = await this.sessionService.getUser();
    if (user) {
      let headers: any = {
        'Accept': 'application/json',
        'X-Requested-With': 'Fetch',
        'Authorization': `Bearer ${user.access_token}`
      };

      if (!isFormData) {
        headers['Content-Type'] = 'application/json';
      }

      this.configure(x => {
        x.withDefaults({
          credentials: 'same-origin',
          headers
        });
      });
    }
  }

  /**
* Make a request to the server.
*
* @param {string}          method     The fetch method
* @param {string}          path       Path to the resource
* @param {{}}              [body]     The body to send if applicable
* @param {{}}              [options]  Fetch request options overwrites
*
* @return {Promise<*>|Promise<Error>} Server response as Object
*/
  public async request(method: string, path: string, body?: {}, options?: {}, isBlob?: boolean): Promise<any | Error> {

    this.isRequesting = true;
    this.ea.publish("app:http_client:requesting");

    await this.configuteClient();

    let requestOptions = extend(true, { headers: {} }, this.defaults, options || {}, { method, body });
    let contentType = requestOptions.headers['Content-Type'] || requestOptions.headers['content-type'];
    if (typeof body === 'object' && body !== null && contentType) {
      requestOptions.body = (/^application\/json/).test(contentType.toLowerCase()) ? JSON.stringify(body) : buildQueryString(body);
    }
    const response = await this.fetch(path, requestOptions);

    this.isRequesting = false;
    this.ea.publish("app:http_client:request_complete");

    if (response.status >= 200 && response.status < 400) {
      if (!isBlob) {
        return await response.json().catch(() => null);
      }
      else {
        return await response.blob().catch(() => null);
      }
    }
    else if (response.status === 401) {
      // this.sessionService.logout();
      throw new Error("commons.unauthorized");
    }
    else if (response.status === 403) {
      throw new Error("commons.forbidden");
    }
    else if (response.status === 404) {
      throw new Error("commons.invalid_endpoint");
    }
    else {
      // if we are here something goes wrong
      const responseBody = await response.json();
      throw responseBody;
    }
  }

  public async requestFormData(method: string, path: string, body?: any, options?: {}, isBlob?: boolean): Promise<any | Error> {

    this.isRequesting = true;
    this.ea.publish("app:http_client:requesting");

    await this.configuteClient(true);

    const response = await this.fetch(path, {
      method: method,
      body: body,
    });

    this.isRequesting = false;
    this.ea.publish("app:http_client:request_complete");

    if (response.status >= 200 && response.status < 400) {
      if (!isBlob) {
        return await response.json().catch(() => null);
      }
      else {
        return await response.blob().catch(() => null);
      }
    }
    else if (response.status === 401) {
      throw new Error("commons.unauthorized");
    }
    else if (response.status === 403) {
      throw new Error("commons.forbidden");
    }
    else {
      const responseBody = await response.json();
      throw responseBody;
    }
  }

  public async download(method: string, resource: string, idOrCriteria?: string | number | {}, filename?: string, mimetype?: string, options?: {}): Promise<any | Error> {
    const blob = await this.request(method, this.getRequestPath(resource, this.useTraditionalUriTemplates, idOrCriteria), undefined, options, true);
    var file = new File([blob], filename || 'download', { type: mimetype || 'text/plain' });
    FileSaver.saveAs(file);
  }

  /**
 * Find a resource.
 *
 * @param {string}                    resource  Resource to find in
 * @param {string|number|{}}          idOrCriteria  Object for where clause, string / number for id.
 * @param {{}}                        [options] Extra request options.
 *
 * @return {Promise<*>|Promise<Error>} Server response as Object
 */
  public get(resource: string, idOrCriteria?: string | number | {}, options?: {}): Promise<any | Error> {
    return this.request('GET', this.getRequestPath(resource, this.useTraditionalUriTemplates, idOrCriteria), undefined, options);
  }

  /**
   * Find a resource.
   *
   * @param {string}           resource    Resource to find in
   * @param {string|number}    id          String / number for id to be added to the path.
   * @param {{}}               [criteria]  Object for where clause
   * @param {{}}               [options]   Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public getById(resource: string, id: string | number, criteria?: {}, options?: {}): Promise<any | Error> {
    return this.request('GET', this.getRequestPath(resource, this.useTraditionalUriTemplates, id, criteria), undefined, options);
  }

  /**
   * Create a new instance for resource.
   *
   * @param {string}           resource  Resource to create
   * @param {{}}               [body]    The data to post (as Object)
   * @param {{}}               [options] Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public post(resource: string, body?: {}, options?: {}): Promise<any | Error> {
    return this.request('POST', resource, body, options);
  }

  /**
   * Update a resource.
   *
   * @param {string}           resource  Resource to update
   * @param {string|number|{}} idOrCriteria  Object for where clause, string / number for id.
   * @param {{}}               [body]    New data for provided idOrCriteria.
   * @param {{}}               [options] Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public update(resource: string, idOrCriteria?: string | number | {}, body?: {}, options?: {}): Promise<any | Error> {
    return this.request('PUT', this.getRequestPath(resource, this.useTraditionalUriTemplates, idOrCriteria), body, options);
  }

  /**
   * Update a resource.
   *
   * @param {string}           resource   Resource to update
   * @param {string|number}    id         String / number for id to be added to the path.
   * @param {{}}               [criteria] Object for where clause
   * @param {{}}               [body]     New data for provided criteria.
   * @param {{}}               [options]  Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public updateById(resource: string, id: string | number, criteria?: {}, body?: {}, options?: {}): Promise<any | Error> {
    return this.request('PUT', this.getRequestPath(resource, this.useTraditionalUriTemplates, id, criteria), body, options);
  }

  /**
   * Patch a resource.
  *
   * @param {string}           resource   Resource to patch
   * @param {string|number|{}} [idOrCriteria] Object for where clause, string / number for id.
   * @param {{}}               [body]     Data to patch for provided idOrCriteria.
   * @param {{}}               [options]  Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public patch(resource: string, idOrCriteria?: string | number | {}, body?: {}, options?: {}): Promise<any | Error> {
    return this.request('PATCH', this.getRequestPath(resource, this.useTraditionalUriTemplates, idOrCriteria), body, options);
  }

  /**
   * Patch a resource.
   *
   * @param {string}           resource   Resource to patch
   * @param {string|number}    id         String / number for id to be added to the path.
   * @param {{}}               [criteria] Object for where clause
   * @param {{}}               [body]     Data to patch for provided criteria.
   * @param {{}}               [options]  Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public patchOne(resource: string, id: string | number, criteria?: {}, body?: {}, options?: {}): Promise<any | Error> {
    return this.request('PATCH', this.getRequestPath(resource, this.useTraditionalUriTemplates, id, criteria), body, options);
  }

  /**
   * Delete a resource.
   *
   * @param {string}           resource   The resource to delete
   * @param {string|number|{}} [idOrCriteria] Object for where clause, string / number for id.
   * @param {{}}               [options]  Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public delete(resource: string, idOrCriteria?: string | number | {}, options?: {}): Promise<any | Error> {
    return this.request('DELETE', this.getRequestPath(resource, this.useTraditionalUriTemplates, idOrCriteria), undefined, options);
  }

  /**
   * Delete a resource.
   *
   * @param {string}           resource   The resource to delete
   * @param {string|number}    id         String / number for id to be added to the path.
   * @param {{}}               [criteria] Object for where clause
   * @param {{}}               [options]  Extra request options.
   *
   * @return {Promise<*>|Promise<Error>} Server response as Object
   */
  public deleteById(resource: string, id: string | number, criteria?: {}, options?: {}): Promise<any | Error> {
    return this.request('DELETE', this.getRequestPath(resource, this.useTraditionalUriTemplates, id, criteria), undefined, options);
  }

  /**
   * Create a new instance for resource.
   *
   * @param {string}           resource  The resource to create
   * @param {{}}               [body]    The data to post (as Object)
   * @param {{}}               [options] Extra request options.
   *
   * @return {Promise<*>} Server response as Object
   */
  public create(resource: string, body?: {}, options?: {}): Promise<any | Error> {
    return this.post(resource, body, options);
  }



  /**
   * getRequestPath
   *
   * @param {string} resource
   * @param {boolean} traditional
   * @param {(string|number|{})} [idOrCriteria]
   * @param {{}} [criteria]
   * @returns {string}
   */
  private getRequestPath(resource: string, traditional: boolean, idOrCriteria?: string | number | {}, criteria?: {}) {
    let hasSlash = resource.slice(-1) === '/';

    if (typeof idOrCriteria === 'string' || typeof idOrCriteria === 'number') {
      resource = `${join(resource, String(idOrCriteria))}${hasSlash ? '/' : ''}`;
    } else {
      criteria = idOrCriteria;
    }

    if (typeof criteria === 'object' && criteria !== null) {
      resource += `?${buildQueryString(criteria, traditional)}`;
    } else if (criteria) {
      resource += `${hasSlash ? '' : '/'}${criteria}${hasSlash ? '/' : ''}`;
    }

    return resource;
  }
}
