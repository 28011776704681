import { RequestQualificatoniVideoInterview } from './../../../../models/test-person/requestQualificationVideoInterview';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { Person } from "models/test-person/person";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { PersonLoginInfo } from "models/test-person/person-login-info";
import { TransactionType } from "models/talents/transaction-type";

@autoinject()
export class PersonService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("PersonService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("person/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  } 
  public async getAll(): Promise<Person[]> {
    return await this.client.get("person")
      .then((response: any) => {
        return <Person[]>response;
      });
  }
  public async getById(id: number): Promise<Person> {
    return await this.client.getById("person", id)
      .then((response: Person) => {
        return response;
      });
  }

  public async getLoginInfoById(id: number): Promise<PersonLoginInfo> {
    return await this.client.get(`person/login-info/${id}`)
      .then((response: PersonLoginInfo) => {
        return response;
      });
  }

  public async predictorConsumption(id: number,transactionType: TransactionType): Promise<boolean> {
    return await this.client.post(`person/predictor-consumption/${id}/transaction-type/${transactionType}`)
      .then((response: boolean) => {
        return response;
      });
  }

  public async getPredictorTalentsConsumption(id: number, transactionType: TransactionType): Promise<number> {
    return await this.client.get(`person/get-predictor-talents-consumption/${id}/transaction-type/${transactionType}`)
      .then((response: number) => {
        return response;
      });
  }


  //  public async getByObservatedId(parameters: UxDatatableParameters, id: number): Promise<UxDatatableResponse> {
  //    return await this.client.post("person/get-by-observated/" + id, parameters)
  //      .then((response: any) => {
  //        return <UxDatatableResponse>response;
  //      });
  //  }

  public async create(model: Person): Promise<Person> {
    return await this.client.create("person", model)
      .then((response: any) => {
        return <Person>response;
      });
  }

  public async update(model: Person): Promise<void> {
    return await this.client.updateById("person", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`person`, id)
      .then(() => null);
  }

  public async sendRemindersByFilter(personList: Person[]): Promise<boolean> {
    return await this.client.post("person/list-send-reminder/", personList)
      .then((response: any) => {
        return true;
      });
  }

  public async getListByFilter(parameters: UxDatatableParameters): Promise<Person[]> {
    return await this.client.post("person/list-by-filter/", parameters)
      .then((response: Person[]) => {
        return response;
      });
  }

  public async requestQualificationVideoInterview(data: RequestQualificatoniVideoInterview): Promise<boolean> {
    return await this.client.post("person/send-request-qualification-video-interview/", data)
      .then((response: any) => {
        return true;
      });
  }

  public async sendReminder(id: number): Promise<boolean> {
    return await this.client.post("person/send-reminder/" + id)
      .then((response: any) => {
        return true
      });
  }

  public async updateTags(id: number, tags: string): Promise<boolean> {
    return await this.client.post("person/update-tags/" + id, { tags: tags })
      .then((response: any) => {
        return true
      });
  }

  public async existsEmail(email: string): Promise<number> {
    return await this.client.get("person/exists-email/", email)
      .then((response: number) => {
        return response;
      });
  }

}
