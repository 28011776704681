import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";

@autoinject()
export class TalentPlanService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("TalentPlanService");
  }

  public async getTestPlanItemsByPlanId(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`talentplan/items`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }
}
