import { CompanyCompetence } from 'models/test-core/competences/company-competence';
import { CompanyCompetenceService } from '../../../admin/services/test-management/company-competence-service';
import { State } from 'common/store/store-model';
import { LogManager, autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { DialogController } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { StoreService } from 'common/store/store-service';
import { Tests } from 'models/test-person/tests';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
@autoinject()
export class CompanyCompetenceModal {
  private companyCompetenceIds: number[] = [];
  private companyCompetences: CompanyCompetence[] = [];
  private logger: Logger;
  public companyId: number;
  private state: State;
  private selectionLabel: string[] = [];
  private definedColumns: string[] = [];
  private questionaryId: number;

  constructor(private dialogController: DialogController,
    private store: StoreService,
    private companyCompetenceService: CompanyCompetenceService,
    private notification: Notification,
    private coreCompanyService: CoreCompanyService) {
    this.logger = LogManager.getLogger("CompanyCompetenceModal");
    this.state = this.store.state;
    this.definedColumns = ["competenceName"];
  }

  public activate(companyCompetenceIds: number[]) {
    this.logger.debug("CompanyCompetenceModal")
    this.companyCompetenceIds = companyCompetenceIds;
  }

  private getSelectionLabel(): void {
    this.selectionLabel = [];
    this.companyCompetenceIds.forEach(y => {
      var selectedCompetence = this.companyCompetences.find(x => x.id === y)
      if (selectedCompetence !== undefined) {        
        this.selectionLabel.push(selectedCompetence.competenceName);
      }
    });

  }

  public async bind(): Promise<any> {
    this.logger.debug("CompetenceActivationSelector");
    this.companyId = this.state.currentCompany.id;

    if (!isNaN(this.companyId)) {
      this.companyCompetenceService.client.currentCompanyId = this.companyId;
      try {
         let settings = await this.coreCompanyService.getSettingsById(this.companyId);
        this.questionaryId = settings.competencesQuestionaryId;
        
        if (this.questionaryId == 0)
          this.questionaryId = Tests['Competences'];

        this.companyCompetences = await this.companyCompetenceService.getAll(this.questionaryId);
        this.getSelectionLabel();
      } catch (error) {
        this.notification.error(error);
      }

    }
  }
}
