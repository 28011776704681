import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { MessageAgreement } from "models/messages/message-agreement";

@autoinject
export class MessageAgreementService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("MessageAgreementService");
  }

  public async getById(id: number): Promise<MessageAgreement> {
    return await this.client.getById("messageagreement", id)
      .then((response: any) => {
        return <MessageAgreement>response;
      });
  }

  public async create(model: MessageAgreement): Promise<MessageAgreement> {
    return await this.client.create("messageagreement", model)
      .then((response: any) => {
        return <MessageAgreement>response;
      });
  }

  public async update(model: MessageAgreement): Promise<void> {
    return await this.client.updateById("messageagreement", model.franchiseId, null, model)
      .then(() => null);
  }
}
