import { ActivationQuestionaryStatus } from './activationQuestionaryStatus';
import { NoDiscType } from './../questionaries/noDiscType';
import { ActivationType } from './activationType';
import { PositionActivationType } from './../test-position/positionActivationType';
import { QuestionaryWay } from 'models/test-person/questionaryWay';
import { PersonActivationType } from "./personActivationType";
import { WhoCanSeeReport } from "./whoCanSeeReport";
import { CompanyCompetence } from 'models/test-core/competences/company-competence';
import { Test } from './../test-perfiltic/test';
import { QuestionaryLanguage } from 'models/questionaries/questionaryLanguage';


export class Activation {
  id: number;
  companyId: number;

  activationType: ActivationType;

  personId: number;
  personActivationType: PersonActivationType;

  positionId: number;
  positionActivationType: PositionActivationType;

  organizationId: number;
  organizationActivationType: PersonActivationType;

  questionaryWay: QuestionaryWay;
  isConfidentialTest?: boolean;
  isAnonymous?: boolean;
  whoCanSeeReport: WhoCanSeeReport;
  invitationExpireDate: Date;
  additionalText: string;
  applicationDate: Date;

  questionaryGroupId: number;
  reportTemplateId: number;

  questionaryId: number;
  questionaryName: string;
  languageId: number;

  companyCompetenceIds: number[] = [];
  companyCompetences: CompanyCompetence[] = [];

  teamId: number;
  noDiscType: NoDiscType;
  reportId: number;
  engineersTests: Test[] = [];
  questionaryIds: QuestionaryLanguage[] = [];

  activationQuestionaryStatus: ActivationQuestionaryStatus;
  isSelectable?: boolean;
}
