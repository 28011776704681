import { CoreCompanyService } from '../../services/companies/core-company-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { Company } from 'models/companies/company';
import { CompanyGroupMasterService } from 'main/admin/services/companies/company-group-master-service';
import { CompanyGroupMaster } from 'models/companies/company-group-master';


@autoinject()
export class CompanyGroupMasterView {

  private logger: Logger;
  private model: CompanyGroupMaster = new CompanyGroupMaster();
  private franchise: Company;

  constructor(
    private coreCompanyService: CoreCompanyService,
    private companyGroupMasterService: CompanyGroupMasterService,
    private notification: Notification) {


    this.logger = LogManager.getLogger("CompanyGroupMasterView");

  }

  private async activate(params: any): Promise<void> {
    this.logger.info("activate");

    try {

      if (!isNaN(params.companyGroupId)) {
        this.model = await this.companyGroupMasterService.getById(+params.companyGroupId);
      }

    }
    catch (error) {
      this.notification.error(error);
    }
  }

}
