import { NoDiscType } from 'models/questionaries/noDiscType';
import { Questionary } from 'models/questionaries/questionary';
import { CustomCompanyQuestionaryService } from 'main/admin/services/questionaries/custom-company-questionary-service';
import { UxDatatableSearchCustomElement } from '../../../../common/resources/elements/ux/datatable/ux-datatable';
import { BulkResultsExportService } from '../../services/people/bulk-results-export-service';
import { ActivationQuestionaryService } from 'main/test/services/people/activation-questionary-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Company } from "models/companies/company";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { State } from "aurelia-route-recognizer";
import { StoreService } from "common/store/store-service";
import { Router, RouteConfig } from "aurelia-router";
import { PersonService } from "main/test/services/people/person-service";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { CoreUserService } from "main/admin/services/users/core-user-service";
import { EvaluatorsModal } from "./evaluators-modal";
import { User } from "models/security/user";
import { BulkResultsExport } from 'models/test-person/bulkResultsExport';
import { Tests } from 'models/test-person/tests';
import { UserService } from 'main/admin/services/users/user-service';
@autoinject()
export class BulkResultsExportCreate {
  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;
  private definedColumns: string[] = [];
  // private ActivationMode = ActivationMode;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private model: BulkResultsExport = new BulkResultsExport();
  private state: State;
  private isLoaded: boolean = false;
  private isResultsExport: boolean = false;
  private selectionLabel: string;
  // private coreUsersIds: number[] = [];
  private questionaries: Questionary[] = [];
  private generalQuestionaries: Questionary[] = [];
  private users: User[] = [];

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.activationQuestionaryService.listpreviewresults(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private store: StoreService,
    private router: Router,
    // private coreCompanyService: CoreCompanyService,
    private activationQuestionaryService: ActivationQuestionaryService,
    private questionaryByCompanyService: CustomCompanyQuestionaryService,
    private coreUserService: CoreUserService,
    private userService: UserService,
    private bulkResultsExportService: BulkResultsExportService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("PersonList");

    // this.state = this.store.state;
    this.definedColumns = ["fullName", "email"];
    this.parameters.search.UsersIds = [];
  }
  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {

    this.logger.debug("activate");

    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async bind(): Promise<any> {
    this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {
      this.logger.debug("activate companyId:", this.params.companyId);
      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.activationQuestionaryService.client.currentCompanyId = this.companyId;

        //this.company = this.state.companies.find(x => x.id == this.companyId);        
        this.users = await this.userService.getAll();
        this.generalQuestionaries = await this.questionaryByCompanyService.getAll();
        this.questionaries = this.generalQuestionaries.filter(x => x.noDiscType === NoDiscType.General || x.noDiscType === NoDiscType.Performance);
        this.isLoaded = true;
        return await this.databind();
      }
    }
  }


  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {

        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
        if (this.parameters.search.ResultsExportQuestionary === Tests.Performance) {
          this.questionaries = this.generalQuestionaries.filter(x => x.noDiscType === NoDiscType.Performance);
        }
        else {
          this.questionaries = this.generalQuestionaries.filter(x => x.noDiscType === NoDiscType.General);
        }

      })
      .catch(error => {
        this.notification.error(error);
      });


  }

  private async getSelectionLabel(): Promise<any> {
    this.selectionLabel = '';
    this.parameters.search.UsersIds.forEach(y => {
      var selectedUser = this.users.find(x => x.id === y)
      if (selectedUser !== undefined) {
        if (this.selectionLabel !== '') {
          this.selectionLabel += ', ';
        }
        this.selectionLabel += selectedUser.fullName;
      }

    });

    return await this.databind();

  }

  private async setEvaluators(): Promise<any> {
    return await this.databind();
  }

  private async save(): Promise<any> {
    try {
      if (this.parameters.totalRecords > 1000) {
        this.notification.warning("main.test.pages.people.errors.total_records");
      }
      else {
        this.model.resultsExportQuestionary = this.parameters.search.ResultsExportQuestionary;
        if (this.parameters.search.ResultsExportQuestionary === Tests.QuestionaryByCompany || this.parameters.search.ResultsExportQuestionary === Tests.Performance)
          this.model.questionaryByCompanyId = this.parameters.search.ResultsExportQuestionaryByCompany;
        this.model.startDate = this.parameters.search.StartDate;
        this.model.endDate = this.parameters.search.EndDate;
        this.model.tags = this.parameters.search.Query;
        this.model.totalResults = this.parameters.totalRecords;
        this.model.usersIds = this.parameters.search.UsersIds;
        this.model = await this.bulkResultsExportService.create(this.model);
        this.notification.success("notifications.item_added");
        return await this.router.navigateToRoute("bulk-results-export-child-router");
      }

    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async clearFilters(): Promise<any> {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
    await this.databind();
  }

}
