import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { ArchetypeLocalization } from "models/archetypes/archetype-localization";
// import { ArchetypeLocalizationService } from "main/admin/services/archetypes/archetype-localization-service";

@autoinject
export class ArchetypeLocalizationModal{
  private logger: Logger;
  private model: ArchetypeLocalization;
  private validationController: ValidationController;

  // private archetypeslocalization: ArchetypeLocalization[];

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory,
    // private archetypelocalizationservice: ArchetypeLocalizationService
    ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ArchetypeModal");
  }

  // public async bind(): Promise<any> {
  //   return await this.archetypelocalizationservice.getAll().then(archetypeslocalization => this.archetypeslocalization = archetypeslocalization);
  // }

  public activate(model: ArchetypeLocalization): void {
    this.model = model;
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules    
    .ensure("descriptionTraduction").displayName("main.admin.pages.archetypes.description_traduction").required()
    .ensure("traduction").displayName("main.admin.pages.archetypes.kind").required()    
    .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(saveAndContinue);
        }
      });
  }

  public async save(saveAndContinue: boolean): Promise<any> {
    this.logger.debug("save:", saveAndContinue);
    this.validationController.reset();
    return await this.dialogController.ok(saveAndContinue);
  }

}
