import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Result } from "models/test-result/result";
import { ResultService } from "main/test/services/result/result-service";
import { DiscAdjectiveService } from "main/test/services/core/disc-adjective-service";
import { DiscAdjective } from "models/test-core/discAdjective";
import { Disc } from "models/questionaries/disc";
import { Activation } from "models/test-person/activation";
import { DiscType } from "models/questionaries/discType";
import { DialogController } from "aurelia-dialog";

@autoinject()
export class HomologationModal {

  private logger: Logger;

  public companyId: number;

  private resultDiscNaturalList: Result[] = [];
  private resultDiscAdaptedList: Result[] = [];
  private discAdjetives: DiscAdjective[] = [];

  constructor(
    private dialogController: DialogController,
    private resultService: ResultService,
    private discAdjectiveService: DiscAdjectiveService,
  ) {
    this.logger = LogManager.getLogger("HomologationModal");

  }

  public async activate(activation: Activation): Promise<any> {

    this.companyId = activation.companyId;

    this.resultService.client.currentCompanyId = this.companyId;

    this.resultDiscNaturalList = await this.getDiscData(activation.personId, activation.id, DiscType.Natural);
    this.resultDiscAdaptedList = await this.getDiscData(activation.personId, activation.id, DiscType.Adapted);

    const adjectives = await this.getDiscAdjetives();

    this.discAdjetives = adjectives.map(itm => {
      let range = itm.scoreRange.split('-');
      let minRange = parseInt((range[0] || '0').trim());
      let maxRange = parseInt((range[1] || '0').trim());
      itm['min'] = minRange;
      itm['max'] = maxRange;
      return itm;
    });

  }


  private async getDiscData(personId: number, activationId: number, discType: DiscType): Promise<Result[]> {

    let allPersonDisc = await this.resultService.getAllByPersonId(personId, activationId);

    let discIds = Object.keys(Disc).filter(k => typeof Disc[k] !== 'number');

    let resultDiscList: Result[] = [];

    discIds.forEach(itm => {
      let filteredItems = allPersonDisc.filter(p => Disc[p.disc] === Disc[itm] && p.discType == discType);

      if (filteredItems.length) {
        resultDiscList.push({
          personId: personId,
          activationId: personId,
          disc: Disc[itm],
          value: filteredItems[0].value
        })
      } else {
        resultDiscList.push({
          personId: personId,
          disc: Disc[itm],
          value: 50
        })
      }
    })

    return resultDiscList;
  }

  private calculateLabel(value: number, disc: Disc): string {
    const itm = this.discAdjetives.find((itm: any) => value >= itm.min && value <= itm.max && itm.disc === Disc[disc]);
    return itm ? itm.name : '';
  }

  private async getDiscAdjetives(): Promise<DiscAdjective[]> {
    return await this.discAdjectiveService.getAll();
  }

}
