export class AgreementFile {
  id: number;

  file: any;
  fileName: string;
  agreementId: number;
  fileUrl: string;

  createdOn: Date;
  createdByName: string;
}
