import { Quota } from 'models/agreements/quota';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class QuotaModal {

  private logger: Logger;

  private validationController: ValidationController;

  private model: Quota;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("QuotaModal");
  }

  async activate(model: Quota): Promise<void> {
    this.logger.debug("activate");
    this.model = model;

    this.configureValidationRules();
  }

  configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("payMode").displayName("main.admin.pages.agreemenst.agreementtermspay.quotas.payMode").required()
      .ensure("percentage").displayName("main.admin.pages.agreemenst.agreementtermspay.quotas.percentage").required().matches(/^[0-9]*$/)
      .ensure("paymentDate").displayName("main.admin.pages.agreemenst.agreementtermspay.quotas.paymentDate").required()
      .ensure("subtotal").displayName("main.admin.pages.agreemenst.agreementtermspay.quotas.subtotal").required().matches(/^[0-9]*$/)
      .ensure("tax").displayName("main.admin.pages.agreemenst.agreementtermspay.quotas.tax").required().matches(/^[0-9]*$/)
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();
  }

  private getTotal(item1, item2){
    return +item1 + +item2;
  }

}
