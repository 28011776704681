
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class LanguageListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("LanguageListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./language-list"),
        name: "languages",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.languages.title"),
      },

      {
        route: "language/:languageId",
        moduleId: PLATFORM.moduleName("./language-child-router"),
        name: "language-child-router",
        // nav: false,
        // title: this.i18n.tr("main.admin.pages.languages.language"),
      },
    ]);
  }
}
