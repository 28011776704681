import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { LogManager, autoinject } from 'aurelia-framework';
import { ActivationQuestionaryService } from 'main/test/services/people/activation-questionary-service';
import { ActivationQuestionary } from 'models/test-person/activationQuestionary';
import { CompanyCompetence } from 'models/test-core/competences/company-competence';
import { ReportCompetence } from 'models/test-reports/reports/reportCompetence';
import { Report } from "models/test-reports/reports/report";
import { ReportActivation } from 'models/test-reports/reports/reportActivation';

@autoinject()
export class CompetenceActivatedWeightModal {

  private logger: Logger;
  private activationQuestionary: ActivationQuestionary;
  private reportActivation: ReportActivation;
  private sumWeight: number = 0;
  private items: ReportCompetence[] = [];
  private report: Report;

  constructor(
    private dialogController: DialogController,
    private activationQuestionaryService: ActivationQuestionaryService
  ) {
  }

  public async activate(param: any) {
    this.logger = LogManager.getLogger("CompetenceActivatedWeightModal")
    this.report = param.report;
    this.reportActivation = param.reportActivation;
    this.items = this.report.competences;

    if (this.items.length < 1) {
      await this.getByActivationQuestionary(param.reportActivation.activationQuestionaryId)
    }

    if (this.reportActivation.isAvailable) {
      this.updateTotal();
    } else {
      this.updateTotal(this.reportActivation.weight);
    }
  }

  private async getByActivationQuestionary(activationQuestionaryId: number) {
    this.activationQuestionary = await this.activationQuestionaryService.getCompanyCompetenceById(activationQuestionaryId);
    if (this.activationQuestionary.activation.companyCompetences.length > 0) {
      this.activationQuestionary.activation.companyCompetences.forEach((companyCompetences) => {
        const newReportCompetence: ReportCompetence = {
          reportId: Number(this.report.competences.length),
          companyCompetenceId: companyCompetences.id,
          companyCompetence: companyCompetences,
          weight: 0,
          isAvailable: true,
        };

        this.report.competences.push(newReportCompetence);
        this.report.competenceIds.push(companyCompetences.id);
      });
    }
  }

  private async updateTotal(initValue?: number): Promise<void> {
    if (initValue) {
        this.report.competences.forEach((reportCompetence) => {
            reportCompetence.weight = initValue;
        });
    }

    this.sumWeight = this.report.competences.reduce((sum, reportCompetence) => sum + Number(reportCompetence.weight), 0);

    const index = this.report.activations.findIndex(x => x.activationId === this.reportActivation.activationId && x.activationQuestionaryId === this.reportActivation.activationQuestionaryId);

    if (index !== -1) {
        this.report.activations[index].weight = this.sumWeight;
        this.report.activations[index].isAvailable = this.sumWeight > 0;
    }
}

  public async selectParameter(reportCompetence: ReportCompetence): Promise<void> {
    this.logger.debug("selectParameter");
    let index = this.report.competences.findIndex(x => x.companyCompetenceId == reportCompetence.companyCompetenceId);

    if (!reportCompetence.isAvailable) {
      reportCompetence.isAvailable = false;
      reportCompetence.weight = 0;
    } else {
      reportCompetence.isAvailable = true;
    }
    
    this.report.competences[index] = reportCompetence;
    this.updateTotal();
  }

}
