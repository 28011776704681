import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AppHttpClient } from "common/services/app-http-client";
import { Inbox } from "models/test-core/inbox/inbox";
import { InboxByUser } from "models/test-core/inbox/inboxByUser";

@autoinject()
export class InboxByUserService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("InboxByUserService");
  }

  public async create(model: InboxByUser): Promise<InboxByUser> {
    return await this.client.create("inboxByUser", model)
      .then((response: InboxByUser) => {
        return response;
      });
  }

  

}
