
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class ThematicVariableListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("ThematicVariableListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./scale-thematic-variables"),
        name: "select-thematic-variables",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.thematic_groups.thematic_variables.title"),
      },

      {
        route: "thematic-variable/:thematicVariableId",
        moduleId: PLATFORM.moduleName("./scale-list-child-router"),
        name: "scale-list-child-router",
        nav: false,
        title: this.i18n.tr("main.admin.pages.scales.scale"),
      },
      
    ]);
  }
}
