export enum TransactionType {
  Assignment = 1,
  Transfer = 2,
  Query = 3,
  Activation = 4,
  Removal = 5,
  ReQuery = 6,
  Predictor = 7,
  AvancedFilters = 8,
  SelectionProcess = 9,
  ChatBotAnalizer = 10
}
