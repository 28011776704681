import { UserLoginInfo } from '../../../../models/security/user-login-info';
import { Notification } from 'common/services/notification';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { UserService } from 'main/admin/services/users/user-service';

@autoinject()
export class UserLoginInfoModal {

  private logger: Logger;

  private loginInfo: UserLoginInfo;
  private directLoginUrlElement: HTMLElement;

  constructor(
    private userService: UserService,
    private dialogController: DialogController,
    private notification: Notification) {

    this.logger = LogManager.getLogger("UserLoginInfoModal");
    
  }

  public async activate(userId: number): Promise<any> {
    this.logger.debug("activate", userId);

    try {
      this.loginInfo = await this.userService.getLoginInfoById(userId);
    } catch (error) {
      this.notification.error(error);
    }
  }

  private focus() {
    if (this.directLoginUrlElement) { 
      this.directLoginUrlElement.focus();
    }
  }


}
