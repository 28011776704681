
import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable-pagination";
import { PersonService } from "../../test/services/people/person-service";
import { Notification } from "common/services/notification";
// import { PersonActivationSource } from "models/test-person/activationMode";
import { Report } from "models/test-reports/reports/report";
import { Parameter } from 'models/test-reports/reports/parameter';
import { Person } from "models/test-person/person";

interface ItemAvailable {
  [id: number]: boolean;
}

@autoinject()
export class SelectPersonList {

  private logger: Logger;

  @bindable
  private report: Report;

  @bindable
  private parameterCurrent: Parameter;

  @bindable
  private selectedAmount: number;

  private selectedCurrent: number;

  private banCheck: boolean;

  private banIds: number[] = [];

  private items: any[] = [];

  private auxItems: any[] = [];

  private itemAvailable: ItemAvailable = {};

  private selectionLabel: string;

  // private ActivationSource = PersonActivationSource;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.personService.list(this.parameters)
      .then((response: UxDatatableResponse) => {

        this.checkAmount();

        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private personService: PersonService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("SelectPersonList");
    this.selectedCurrent = 0;
    this.banCheck = false;

    this.parameters.pageSize = 20;
  }

  private async attached(): Promise<any> {

    if (this.report.companyId) {
      this.personService.client.currentCompanyId = this.report.companyId;
      return await this.databind();
    }
  }


  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {

        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private getSelectionLabel(): void {
    this.selectionLabel = '';
    this.items.forEach(x => {
      if (this.selectionLabel !== '') {
        this.selectionLabel += ', ';
      }
      this.selectionLabel += x.fullName;
    });
  }

  public async selectParameter(item: Person): Promise<void> {

    let banGeneral = this.banIds.findIndex(y => y === item.id);

    if (banGeneral == -1) {
      this.banIds.push(item.id);
      this.selectedCurrent = ++this.selectedCurrent;
      this.items.push(item);
    } else {
      this.banIds.splice(banGeneral, 1);
      this.selectedCurrent = --this.selectedCurrent;
      this.items.splice(banGeneral, 1);
    }


    this.checkAmount();

    switch (this.parameterCurrent) {
      case Parameter.OneOrTwoPeople: {
        this.report.oneOrTwoPeopleIds = this.items.map(x => x.id);
        break;
      }
      case Parameter.TwoPeople: {
        this.report.twoPeopleIds = this.items.map(x => x.id);
        break;
      }
      case Parameter.ThreePeople: {
        this.report.threePeopleIds = this.items.map(x => x.id);
        break;
      }
      case Parameter.Person: {
        this.report.personId = this.items.map(x => x.id)[0];
        break;
      }
      case Parameter.CompareToPerson: {
        this.report.compareToPersonId = this.items.map(x => x.id)[0];
        break;
      }
      case Parameter.People: {
        this.report.peopleIds = this.items.map(x => x.id);
        break;
      }
      case Parameter.Leader: {
        this.report.personId = this.items.map(x => x.id)[0];
        break;
      }
      default: {
        break;
      }
    }

    this.getSelectionLabel();

  }

  private checkAmount() {
    if (this.selectedCurrent < +this.selectedAmount) {
      this.banCheck = false;
      this.parameters.tableData.forEach(x => {
        this.itemAvailable[x.id] = false;
      });

    } else {
      this.banCheck = true;
      this.parameters.tableData.forEach(x => {
        let ban = this.banIds.findIndex(y => y === x.id);
        if (ban === -1)
          this.itemAvailable[x.id] = true;
        else
          this.itemAvailable[x.id] = false;
      });
    }
  }


}
