
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class TeamChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("TeamChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./team-edit"),
        name: "team-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.teams.team_edit.title"),
      },

      {
        route: "team-person",
        moduleId: PLATFORM.moduleName("../team-person/team-person-list-child-router"),
        name: "team-person-list-child-router",
        nav: true,
        title: this.i18n.tr("main.test.pages.teams.team_edit.members.title"),
      },

      {
        route: "team-activation",
        moduleId: PLATFORM.moduleName("../team-activation/team-activation-list-child-router"),
        name: "team-activation-list-child-router",
        nav: true,
        title: this.i18n.tr("main.test.pages.teams.team_activations.title"),
      },
    ]);
  }
}
