import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { Sheet } from "models/test-core/sheets/sheet";
import { Notification } from "common/services/notification";
import { CoreSheetService } from "main/admin/services/test-core/core-sheet-service";

@autoinject()
export class SheetFullDescriptionModal {

  private model: Sheet = new Sheet();
  private logger: Logger;

  constructor(
    private coreSheetService: CoreSheetService,
    private notification: Notification,
    private dialogController: DialogController) {

    this.logger = LogManager.getLogger("SheetFullDescriptionModal");

  }

  private async activate(sheet: any): Promise<void> {
    this.logger.debug("activate");
    this.model = await this.getItem(sheet.id);
    this.model.sheetLanguages = sheet.sheetLanguages;
  }

  private async getItem(id: number): Promise<Sheet> {
    return await this.coreSheetService.getById(id);
  }

}
