import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { Questionary } from 'models/questionaries/questionary';

@autoinject()
export class PlanQuestionaryService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("PlanQuestionaryService");
  }

  public async getMissingByPlan(planId: number): Promise<Questionary[]> {
    return await this.client.get(`planquestionary/get-missing-by-plan/${planId}`)
      .then((response: Questionary[]) => {
        return response;
      })
  }
}
