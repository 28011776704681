import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Position } from "models/test-position/position";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { PositionLocalization } from "models/test-position/positionLocalization";

@autoinject()
export class PositionLocalizationService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("PositionLocalizationService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("positionLocalization/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  
  public async create(model: PositionLocalization): Promise<PositionLocalization> {
    return await this.client.create("positionLocalization", model)
      .then((response: any) => {
        return <PositionLocalization>response;
      });
  }

  public async update(model: PositionLocalization): Promise<void> {
    return await this.client.updateById("positionLocalization", model.id, null, model)
      .then(() => null);
  }
}
