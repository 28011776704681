import { SpendMode } from './spendMode';
import { TestPlanItemType } from './testPlanItemType';
import { VisualizationType } from './visualizationType';

export class TestPlanItem {
  id: number;
  testPlanItemType: TestPlanItemType;
  questionaryId: number;
  questionaryName: string;
  reportTemplateId: number;
  reportTemplateName: string;
  sheetName: string;
  spendMode: SpendMode;
  talentsPrice: number;
  planId: number;
  warning: boolean;
  selectionPlanItemId:number;
  visualizationType: VisualizationType;
  talentsAdditionalPrice: number;
  sheetId: number;
}
