import { Notify } from './notify';
import { ActivityStatus } from './activityStatus';

export class Activity {
  id:number;
  name: string;
  responsible: string;
  date: Date;
  hasAlarm: boolean;
  agreementId: number;
  activityStatus: ActivityStatus;
  email: string;
  notify: Notify;
}
