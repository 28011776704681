import { autoinject } from "aurelia-framework";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AppHttpClient } from "common/services/app-http-client";
import { Archive } from "models/test-archive/archive";


@autoinject()
export class ArchiveService {


  constructor (
    public client: CompanyAppHttpClient ) {
  }

  public async list ( parameters: UxDatatableParameters ): Promise<UxDatatableResponse> {
    return await this.client.post( "archive/list", parameters )
      .then( ( response: any ) => {
        return <UxDatatableResponse> response;
      } );
  }
  
  public async changeState(model: Archive): Promise<void> {
    return await this.client.updateById("archive", model.id, null, model)
      .then(() => null);
  }
  
}
