import { AppHttpClient } from 'common/services/app-http-client';
import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ServiceRating } from 'models/service-rating/service-rating';
import { SalesIqModel } from '../../../../models/sales-iq/sales-iq-model';

@autoinject
export class ServiceRatingService {
  private logger: Logger;

  constructor(public client: AppHttpClient) {
    this.logger = LogManager.getLogger('ServiceRatingService');
  }

  public async getServiceRatingSettings(): Promise<ServiceRating> {
    return await this.client.get("servicerating")
      .then((response) => {
        return <ServiceRating>response;
      });
  }

  public async getSalesIqSettings(): Promise<SalesIqModel> {
    return await this.client.get("servicerating/sales-iq")
      .then((response) => {
        return <SalesIqModel>response;
      });
  }

  public async update(model: ServiceRating): Promise<void> {
    return await this.client.update("servicerating", null,model)
      .then(() => null);
  }

}
