import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { Sheet } from "models/test-core/sheets/sheet";
import { bindable, LogManager, bindingMode, autoinject, Disposable, BindingEngine } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { SheetFullDescriptionModal } from './sheet-full-description-modal';

@autoinject
export class SheetCard {

  protected logger: Logger;

  @bindable
  public sheet: Sheet;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public checked: any[] = undefined; // public: any[]

  @bindable
  public value: any = null;    // public: any

  @bindable
  public disabled: boolean = false;// public: boolean

  @bindable
  public selectable: boolean = true;

  // @bindable tabIndex = 0;    // public: number
  // element;                   // private: Element
  // bindingEngine;             // private: BindingEngine
  protected subscription: Disposable = null;       // private: Disposable|null
  protected state: boolean = false;             // private: boolean
  styleString: string = '';


  constructor(

    private dialogService: DialogService,
    public element: Element,
    public bindingEngine: BindingEngine) {
    this.logger = LogManager.getLogger("SheetCard");

    // this.element = element;
    // this.bindingEngine = bindingEngine;
  }

  public checkedChanged() { // public: Array|undefined, Array|undefined
    // unsubscribe from the previous array's mutation (eg push/pop/splice)
    this.disposeSubscription();
    // subscribe to the current array's mutation
    if (Array.isArray(this.checked)) {
      this.subscription = this.bindingEngine.collectionObserver(this.checked)
        .subscribe(() => this.synchronizeView());
    }
    this.synchronizeView();

    // this.logger.debug("checkedChanged checked", this.checked);
  }

  public valueChanged() {
    this.synchronizeView();
  }

  // clicked() {
  //   if (this.disabled) {
  //     return;
  //   }
  //   this.state = !this.state;

  //   // this.logger.debug("this.state", this.state);
  //   this.synchronizeModel();
  // }

  // keypress(event) { // private event: KeyboardEvent
  //   if (this.disabled || event.ctrlKey || event.altKey || event.shiftKey || event.metaKey || event.which !== 32) {
  //     return;
  //   }

  //   // space key was pressed...
  //   this.state = !this.state;
  //   this.synchronizeModel();
  // }

  public synchronizeView() { // private
    if (Array.isArray(this.checked)) {
      // this.logger.debug("synchronizeView value", this.value);
      // this.logger.debug("synchronizeView checked", this.checked);
      this.state = this.checked.indexOf(this.value) !== -1;
    } else {
      this.state = false;
    }
    if (this.sheet.warning === true) {
      this.styleString = 'opacity: 0.3;';
    }

  }

  public synchronizeModel() {
    // this.logger.debug("synchronizeModel this.checked", this.checked);
    if (!Array.isArray(this.checked)) {
      return;
    }
    if (this.state && this.checked.indexOf(this.value) === -1) {
      this.checked.push(this.value);
    } else if (!this.state) {
      const index = this.checked.indexOf(this.value);
      if (index !== -1) {
        this.checked.splice(index, 1);
      }
    }
    // this.logger.debug("this.checked", this.checked);
  }

  public disposeSubscription() {
    if (this.subscription !== null) {
      this.subscription.dispose();
      this.subscription = null;
    }
  }

  public unbind() {
    this.disposeSubscription();
  }

  private async viewFullDescription(): Promise<any> {
    return await this.dialogService.open({
      viewModel: SheetFullDescriptionModal,
      model: this.sheet
    }).whenClosed(async (response: DialogCloseResult) => {
      return true;
    });
  }
}
