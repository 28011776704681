import { EventAggregator } from 'aurelia-event-aggregator';
import { Disposable } from 'aurelia-binding';
import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { RouteConfig } from "aurelia-router";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { UserService } from "../../services/users/user-service";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from 'models/companies/company';

@autoinject()
export class UserApiApplicationUserList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  
  private isLoaded: boolean = false;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.userService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private store: StoreService,
    private ea: EventAggregator,
    private userService: UserService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("UserApiApplicationUserList");

    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.companyId) {

        this.userService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded;

        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

}
