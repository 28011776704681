import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { autoinject, LogManager, bindable, Disposable, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { Report } from "models/test-reports/reports/report";
import { ReportActivation } from 'models/test-reports/reports/reportActivation';
import { CompetenceActivatedWeightModal } from "main/test/pages/people-competence/competence-activated-weight-modal";

@autoinject()
export class SelectActivationList {

  private logger: Logger;
  private params: any = {};

  @bindable
  @observable
  private report: Report;

  private sumWeight: number;

  private items: ReportActivation[] = [];

  constructor(
    private notification: Notification,
    private dialogService: DialogService) {
    this.logger = LogManager.getLogger("SelectActivationList");
    this.sumWeight = 0;

  }

  private async bind(): Promise<any> {
    this.logger.debug("bind");
    if (this.report) {
      this.items = this.report.activations;
      this.updateTotal();
    }
  }

  private async updateTotal(): Promise<any> {
    this.sumWeight = 0;
    this.report.activations.forEach((reportactivation) => {
      this.sumWeight += Number(reportactivation.weight);
    });

    if (this.sumWeight > 100) {
      this.notification.error('main.test_reports.pages.reports.parameters.Activation_weight_required');
    }
  }

  public async selectParameter(reportActivation: ReportActivation): Promise<void> {
    this.logger.debug("selectParameter");
    let index = this.report.activations.findIndex(x => x.activationId == reportActivation.activationId && x.activationQuestionaryId == reportActivation.activationQuestionaryId);

    if (!reportActivation.isAvailable) {
      reportActivation.isAvailable = false;
      reportActivation.weight = 0;
    } else {
      reportActivation.isAvailable = true;
    }

    this.report.activations[index] = reportActivation;
    this.updateTotal();
  }

  public notificationInfo(message: string) {
    this.notification.info(message);
  }

  private async seeActivatedCompetences(reportActivation: ReportActivation): Promise<void> {
    let params: any = {
      reportActivation: reportActivation,
      report: this.report,
    };
    
    await this.dialogService.open({
      viewModel: CompetenceActivatedWeightModal,
      model: params
    }).whenClosed((response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.updateTotal();
      }
    });
  }
}
