import { DialogController } from "aurelia-dialog";
import { LogManager, autoinject } from "aurelia-framework";
import { ControllerValidateResult, ValidationController, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { RequestQualificatoniVideoInterview } from "models/test-person/requestQualificationVideoInterview";
import { Logger } from "oidc-client";

@autoinject()
export class RequestQualificationVideoInterviewModal{
  private logger: Logger;
  private model: RequestQualificatoniVideoInterview;
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory){
      this.validationController = this.validationControllerFactory.createForCurrentScope();
      this.validationController.addRenderer(new BootstrapFormRenderer());
      this.logger = LogManager.getLogger("RequestQualificatoniVideoInterview");
    }

    private async activate(model: RequestQualificatoniVideoInterview): Promise<any> {
      this.model = model;
      // this.configureValidationRules();
    }
    
    private configureValidationRules(): void {
      ValidationRules
        .ensure("destinationEmail").displayName("main.admin.pages.labels.name").required()
        .on(this.model);
    }

    public async submit(): Promise<void> {
      return await this.validationController.validate()
        .then(async (result: ControllerValidateResult) => {
          if (result.valid) {
            return await this.save();
          }
        });
    }
  
    public async save(): Promise<any> {
      return await this.dialogController.ok();
  
    }
}
