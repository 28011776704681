import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { Sheet } from "models/test-core/sheets/sheet";
import { SheetLanguage } from "models/test-core/sheets/sheetLanguage";


@autoinject()
export class CoreSheetLanguageService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CoreSheetService");
  }

  // public async getById(id: number): Promise<SheetLocalization> {
  //   return await this.client.getById("coresheet", id)
  //     .then((response: Sheet) => {
  //       return response;
  //     });
  // }


  // public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
  //   return await this.client.post(`coresheet/list`, parameters)
  //     .then((response: any) => {
  //       return <UxDatatableResponse>response;
  //     });
  // }

  public async update(model: Sheet, languageId: number): Promise<SheetLanguage> {
    let formData = new FormData();

    let thumbnailImage = null;
    if (model.sheetLanguages[0].thumbnailImage) {
      thumbnailImage = model.sheetLanguages[0].thumbnailImage[0];
    }

    let image = null;
    if (model.sheetLanguages[0].image) {
      image = model.sheetLanguages[0].image[0];
    }

    formData.append('thumbnailImage', thumbnailImage);
    formData.append('image', image);
    // formData.append('fullDescription', model.fullDescription);

    return await this.client.requestFormData("PUT", `coresheetlanguage/${model.id}/${languageId}`, formData) //.updateById("coresheetlanguage", model.id, null, model)
      .then((response) => { return <SheetLanguage>response; });
  }

  // public async changeState(id: number): Promise<void> {
  //   return await this.client.post(`coresheet/change-state/${id}`)
  //     .then(() => null);
  // }
}

