import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Group } from "models/groups/group";
import { Notification } from "common/services/notification";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { GroupService } from "../../services/groups/group-service";

@autoinject()
export class GroupDeleteModal {

  private logger: Logger;
  private groupToDelete: Group;
  private groupIdToTransferUsers: number;
  private validationController: ValidationController;
  private groups: Group[];

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private groupService: GroupService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("GroupDeleteModal");
    this.configureValidationRules();
  }

  private async activate(item: Group): Promise<void> {
    this.groupToDelete = Object.assign(new Group(), item);
    return await this.groupService.getAll()
      .then(groups => this.groups = groups)
      .then(() => {
        // remuevo de la lista de grupos a transferir usuarios el grupo a eliminar
        this.groups.splice(this.groups.map(x => x.id.toString()).indexOf(item.id.toString()), 1);
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("groupIdToTransferUsers").displayName("main.admin.pages.groups.group_to_transfer").required()
      .on(this);
  }

  public async submit(): Promise<any> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.dialogController.ok(this.groupIdToTransferUsers);
        }
      });
  }
}


