import { bindable, LogManager, observable, bindingMode, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";

// import { es } from "flatpickr/dist/l10n/es.js";

@autoinject()
export class UxDatePickerCustomElement {

  private logger: Logger;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  @observable
  public value: Date;

  @bindable
  public enableTime: boolean = false;

  @bindable
  public altFormat: string = 'm/d/Y';

  // public locale: any;

  public state: State;


  constructor(
    private store: StoreService,
  ) {

    this.logger = LogManager.getLogger("UxDatePickerCustomElement");

    this.state = this.store.state;
  }

  public bind() {
    if (this.state && this.state.userBasicInfo && this.state.userBasicInfo.culture) {
      let culture = this.state.userBasicInfo.culture.split("-")[0];

      switch (culture) {
        case "es":
          // this.logger.debug("genericCulture: ", culture);

          // this.locale = es;

          if (this.enableTime) {
            this.altFormat = `d/m/Y h:i K`;
          }
          else {
            this.altFormat = `d/m/Y`;
          }
          break;
        default:
          if (this.enableTime) {
            this.altFormat = `m/d/Y h:i K`;
          }
          else {
            this.altFormat = `m/d/Y`;
          }
          break;
      }
    }
  }

  // public attached() {

  //   this.logger.debug("attached");


  // }

  get config() {
    // let c = {};
    // if (this.locale) {
    //   c = {
    //     enableTime: this.enableTime,
    //     altFormat: `${this.altFormat}`,
    //     locale: this.locale
    //   };
    // }
    // else {
    //   c = {
    //     enableTime: this.enableTime,
    //     altFormat: `${this.altFormat}`
    //   };
    // }

    let c = {
      enableTime: this.enableTime,
      altFormat: `${this.altFormat}`
    };

    // this.logger.debug("config:", c);
    return c;
  }

}
