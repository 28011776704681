export enum ActivationQuestionaryStatus {
  Created,
  Sent,
  Processed,
  Delivered,
  NotDelivered,
  Open,
  Clicked,
  ByFillOut,
  InQualification,
  Finalized,
  Expired
}
