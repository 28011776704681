import { Parameter } from 'models/test-reports/reports/parameter';
import { DialogService } from 'aurelia-dialog';
import { DialogCloseResult } from 'aurelia-dialog';
import { CompanyCompetenceModal } from '../../../../test/pages/people-competence/company-competence-modal';
import { ItemToQualify } from './../../../../../models/questionaries/itemToQualify';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { LanguageService } from "../../../services/localizations/language-service";
import { Language } from "models/localizations/language";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from 'models/companies/company';
import { Questionary } from 'models/questionaries/questionary';
import { QuestionaryGroup } from 'models/test-management/questionary-groups/questionaryGroup';
import { QuestionaryGroupService } from '../../../services/test-management/questionary-group-service';
import { CompanyQuestionaryService } from 'main/admin/services/test-management/company-questionary-service';
import { QuestionaryType } from 'models/questionaries/questionaryType';
import { QuestionarySheetService } from 'main/admin/services/test-management/questionary-sheet-service';
import { QuestionarySheet } from 'models/test-management/questionary-groups/questionary-sheet';
import { CarouselConfig } from 'common/resources/elements/ux/carousel/carousel';
import { I18N } from 'aurelia-i18n';
import { TestQuestionary } from 'models/test-core/test-questionary';
import { Test } from './../../../../../models/test-perfiltic/test';
import { NoDiscType } from 'models/questionaries/noDiscType';
import { UxDatatableParameters, UxDatatableResponse, UxDatatableCustomAttribute } from "common/resources/elements/ux/datatable/ux-datatable";
import { QuestionaryLanguage } from 'models/questionaries/questionaryLanguage';
@autoinject()
export class QuestionaryListEdit {

  private logger: Logger;
  private parameters = new UxDatatableParameters();

  public companyId: number;
  private company: Company;


  public languageList: Language[] = [];
  public selectedLanguageId: number;
  private validationController: ValidationController;
  public languageId;
  private questionaryGroupId: number;
  private model: QuestionaryGroup = new QuestionaryGroup();

  private questionaries: TestQuestionary[] = [];
  private questionaries2: TestQuestionary[] = [];

  private discs: TestQuestionary[] = [];
  private noDiscs: TestQuestionary[] = [];
  private engineersTest: TestQuestionary[] = [];

  private state: State;

  private search: string = null;

  private duration: number = 0;

  private isCompetenceActive: boolean = false;

  private options = <CarouselConfig>{
    duration: 600,
    perPage: {
      640: 2,
      768: 3,
      1080: 4
    },
    draggable: true,
    loop: false,
    autoPlay: true,
    autoPlayInterval: 2500
  };

  constructor(
    private i18n: I18N,
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private dialogService: DialogService,
    private validationControllerFactory: ValidationControllerFactory,
    private questionaryGroupService: QuestionaryGroupService,
    private companyQuestionaryService: CompanyQuestionaryService,
    private questionarySheetService: QuestionarySheetService,
    private languageService: CoreLanguageService,
  ) {


    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("QuestionaryListEdit");
    this.state = this.store.state;
  }



  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");

    try {

      if (!isNaN(params.companyId)) {

        this.companyId = +params.companyId;

        this.questionaryGroupService.client.currentCompanyId = +params.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.questionaries = await this.companyQuestionaryService.getAll();
        this.questionaries2 = this.questionaries;        
        this.languageList = await this.languageService.getAll();
       
        this.setUserLanguage();
        this.filter();


        if (!isNaN(params.questionaryGroupId)) {
          this.questionaryGroupId = +params.questionaryGroupId;

          this.model = await this.questionaryGroupService.getById(this.questionaryGroupId);

          this.setDuration(false);     
        }
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public setUserLanguage() {    
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languageList.find((lg: { culture: string; }) => lg.culture === activeCulture);
      if (activeLang) {
        this.selectedLanguageId = activeLang.id;
        //this.filter();
      }
    }
  }

  get canSave() {
    return !this.questionaryGroupService.client.isRequesting;
  }

  public async save(saveAndContinue: boolean): Promise<any> {
    try {     
      await this.questionaryGroupService.update(this.model);
      this.notification.success("notifications.item_edited");

      if (!saveAndContinue) {
        return await this.router.navigateToRoute("questionary-groups");
      }

    } catch (error) {
      this.notification.error(error);
    }
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.isCompetenceActive && this.model.companyCompetenceIds.length == 0) {
            this.notification.warning("main.test.pages.people.errors.no_competences_selected");
          }
          else {
            if (!this.isCompetenceActive) {
              this.model.companyCompetenceIds = [];
            }
            return await this.save(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private filter(search?: string) {

    if (search) {
      this.questionaries = this.questionaries2.filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    } else {
      this.questionaries = this.questionaries2;
    }
  
    if (this.selectedLanguageId == 0 || this.selectedLanguageId == null) {

      this.discs = this.questionaries
        .filter(x => x.questionaryType === QuestionaryType.Disc &&
          x.itemToQualify === ItemToQualify.Person)
        .sort((x, y) => x.order - y.order);

      this.noDiscs = this.questionaries
        .filter(x => x.questionaryType !== QuestionaryType.Disc &&
          x.noDiscType !== NoDiscType.EngineersTest)
        .sort((x, y) => x.order - y.order);

      this.engineersTest = this.questionaries
        .filter(x => x.questionaryType !== QuestionaryType.Disc
          && x.noDiscType == NoDiscType.EngineersTest)
        .sort((x, y) => x.order - y.order);      
    }
    else {
      this.discs = this.questionaries
        .filter(x => x.questionaryType === QuestionaryType.Disc && x.languageId === this.selectedLanguageId
          && x.itemToQualify === ItemToQualify.Person)
        .sort((x, y) => x.order - y.order);

      this.noDiscs = this.questionaries
        .filter(x => x.questionaryType !== QuestionaryType.Disc &&
          x.noDiscType !== NoDiscType.EngineersTest && x.languageId === this.selectedLanguageId)
        .sort((x, y) => x.order - y.order);

      this.engineersTest = this.questionaries
        .filter(x => x.questionaryType !== QuestionaryType.Disc && x.languageId === this.selectedLanguageId
          && x.noDiscType == NoDiscType.EngineersTest)
        .sort((x, y) => x.order - y.order);
    }
  }

  private setDuration(showCompetences: boolean) {
    this.logger.debug("ShowCompetence:", showCompetences);
    let aux = 0;
    this.isCompetenceActive = false;
    this.model.questionaryIds.forEach(x => {
      let q = this.questionaries2.find(y => y.stringId == x.stringId);
      if (q) {
        aux += +q.averageTime;
        if (q.questionaryType == QuestionaryType.Competences) {
          this.isCompetenceActive = true;
          if (showCompetences) {
            this.selectCompanyCompetence();
          }
        }
      }
    });

    this.duration = aux;

    this.logger.debug("duration", this.duration);
  }

  private async getSheets(questionary: TestQuestionary): Promise<void> {
    this.logger.debug("Getting sheets", questionary.id);
    try {
      if (!questionary.sheets) {
        questionary.sheets = await this.questionarySheetService.getSheetsByQuestionary(questionary.id);
      }
    } catch (error) {
      this.notification.error(error);
    }

  }

  private async selectCompanyCompetence(): Promise<void> {
    this.logger.debug("model.companyCompetenceIds")
    return await this.dialogService.open({
      viewModel: CompanyCompetenceModal,
      model: this.model.companyCompetenceIds
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (this.isCompetenceActive && this.model.companyCompetenceIds.length == 0) {
          this.notification.warning("main.test.pages.people.errors.no_competences_selected");
        }
      }
    });
  }

  private isSelected(questionary: TestQuestionary): boolean {   
   
    const filteredQuestionaries = this.model.questionaryIds.filter(x => x.stringId == questionary.stringId);    
    return filteredQuestionaries.length > 0;
    
  }

  private addRemoveQuestionary(showCompetences: boolean, questionary: TestQuestionary)
  {
    const index = this.model.questionaryIds.findIndex(x => x.stringId == questionary.stringId);
    if(index !== -1)
    {     
        this.model.questionaryIds.splice(index, 1);
    }
    else{
      let questionaryLanguage: QuestionaryLanguage = new QuestionaryLanguage();
      questionaryLanguage.languageId = questionary.languageId;
      questionaryLanguage.questionaryId = questionary.id;
      questionaryLanguage.stringId = questionary.stringId;
      this.model.questionaryIds.push(questionaryLanguage);
    }

    this.setDuration(showCompetences);
  }
}
