import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { CompanyDetail } from "models/companies/company-detail";
import { CompanyDetailMaster } from "models/companies/company-detail-master";

@autoinject()
export class CompanyDetailMasterService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CompanyDetailMasterService");
  }

  public async getById(id: number): Promise<CompanyDetailMaster> {
    return await this.client.getById("companydetailmaster", id)
      .then((response: any) => {
        return <CompanyDetailMaster>response;
      });
  }

  public async update(model: CompanyDetailMaster): Promise<CompanyDetailMaster> {
    return await this.client.updateById("companydetailmaster", model.id, null, model)
    .then((response: any) => {
      return <CompanyDetailMaster>response;
    });
  }

}
