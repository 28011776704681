import { AgeRange } from '../catalogs/ageRange';
import { EducationalLevel } from '../catalogs/educationalLevel';
import { Genre } from '../catalogs/genre';


export class Scale {
    id: number;
    genre?: Genre;
    educationalLevel?: EducationalLevel;
    ageRange?: AgeRange;
    rawValuesRange: string;
    equivalentValue: number;
    thematicVariableId: number;
}
