import { StoreService } from 'common/store/store-service';
import { Language } from './../../../../../models/localizations/language';
import { CompanyDiscStyleModal } from './disc-style-modal';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";

import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyDiscStyleService } from '../../../services/test-management/company-disc-style-service';
import { State } from 'common/store/store-model';
import { Company } from 'models/companies/company';
import { CompanyDiscStyle } from 'models/test-management/disc-styles/company-disc-style';
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';
import { LanguageService } from "../../../services/localizations/language-service";


@autoinject()
export class CompanyDiscStyleList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private model1: CompanyDiscStyle = new CompanyDiscStyle();

  public languageList: Language[] = [];
  public selectedLanguageId: number;
  public languageId: number;

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private companyDiscStyleService: CompanyDiscStyleService,
    private dialogService: DialogService,
    private coreLanguageService: CoreLanguageService,
    private languageService: CoreLanguageService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("CompanyDiscStyleList");

    this.state = this.store.state;

  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.companyDiscStyleService.list(this.languageId, this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any): Promise<void> {

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  public setUserLanguage() {    
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {      
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languageList.find((lg: { culture: string; }) => lg.culture === activeCulture);
      if (activeLang) {
        this.languageId = activeLang.id;             
      }
    }
  }

   public async changeLanguage (){       
    // this.parameters = await this.companyDiscStyleService.list(this.languageId, this.parameters);
    await this.databind();
    }
  
  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    
    if (!isNaN(this.params.companyId)) {

      this.languageList = await this.languageService.getAll();
      this.setUserLanguage();
      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.companyDiscStyleService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);
        // this.model1  = await this.companyDiscStyleService.list(this.languageId, this.parameters);
        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async edit( item: CompanyDiscStyle): Promise<void> {
    let orginialItem = JSON.parse(JSON.stringify(item));
    return await this.dialogService.open({
      viewModel: CompanyDiscStyleModal,
      model: item
      
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        item.languageId = this.languageId;
        return await this.companyDiscStyleService.update( item )
          .then(async (model: CompanyDiscStyle) => {

            this.logger.debug("model.id", model.id);
            item.id = model.id;

            this.notification.success("main.admin.pages.test_management.disc_styles.notifications.edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        Object.assign(item, orginialItem);
      }
    });
  }


}
