import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AppHttpClient } from "common/services/app-http-client";
import { News } from "models/test-core/news/news";

@autoinject()
export class NewsService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("NewsService");
  }
  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`news/list`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  public async changeState(id: number): Promise<void> {
    return await this.client.post(`news/change-state/${id}`)
      .then(() => null);
  }

  public async create(model: News): Promise<News> {
    return await this.client.create("news", model)
      .then((response: News) => {
        return response;
      });
  }

  public async getAllByCompany( companyId: Number,news: News): Promise<News[]> {
    return await this.client.post(`news/get-all-by-company/${companyId}`,news )
      .then((response: News[]) => {
        return response;
      });
  }

  public async update(model: News): Promise<void> {
    return await this.client.updateById("news", model.id, null, model)
      .then(() => null);
  }
  public async getById(id: number): Promise<News> {
    return await this.client.getById("news", id)
      .then((response: News) => {
        return response;
      });
  }

}
