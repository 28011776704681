import { View } from 'aurelia-framework';
import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { MessageTemplateService } from "../../services/messages/message-template-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { MessageTemplate } from 'models/messages/message-template';

@autoinject()
export class MessageFranchiseMain {

  private logger: Logger;

  // private validationController: ValidationController;
  // private messagesTemplates: Array<MessageTemplate>;

  constructor(
    // private router: Router,
    // private dialogController: DialogController,
    // private notification: Notification,
    // private validationControllerFactory: ValidationControllerFactory,
    // private messageTemplateService: MessageTemplateService
  ) {

        // this.validationController = this.validationControllerFactory.createForCurrentScope();
        // this.validationController.addRenderer(new BootstrapFormRenderer());
        this.logger = LogManager.getLogger("MessageFranchiseMain");

  }

  // public async activate(params: any, routeConfig: RouteConfig, view: View): Promise<void> {

  //   this.logger.debug("Activate companyId:", params.companyId);
  //   if (!params.companyId) {
  //     return;
  //   }    
  // }

  // public toConfig(type: string): boolean {   
  //     return    this.router.navigate(`${encodeURIComponent(type)}`);
  // }
       

}
