import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { MessageTemplate } from "models/messages/message-template";
import { MessageTemplateService } from "../../services/messages/message-template-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";

import { Language } from "models/localizations/language";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { Company } from "models/companies/company";

@autoinject()
export class MessageEdit {

  private logger: Logger;
  private params: any = {};

  private companyId: number;
  private company: Company;
  
  private model: MessageTemplate;
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;
  private languages: Array<Language>;

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    public languagesService: CoreLanguageService,
    private validationControllerFactory: ValidationControllerFactory,
    private messageTemplateService: MessageTemplateService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("MessageEdit");

    this.state = this.store.state;

  }

  public async activate(params: any): Promise<void> {
    this.params = params;
  }

  public async bind(): Promise<any> {
    
    try {
      this.languages = await this.languagesService.getAll();

      this.companyId =  +this.params.companyId;
      this.messageTemplateService.client.currentCompanyId = this.companyId;
      this.company = this.state.companies.find(x => x.id == this.companyId);
      
      this.model = await this.messageTemplateService.getMessageByTemplateName(this.params.messageId);
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("languageId").displayName("main.admin.pages.messages.language").required()
      .ensure("subject").displayName("main.admin.pages.messages.subject").required()
      .ensure("emailBody").displayName("main.admin.pages.messages.emailBody").required()
      .ensure("smsBody").displayName("main.admin.pages.messages.smsBody").required()
      .ensure("messagePeriod").displayName("main.admin.pages.messages.messagePeriod").required().when(x => this.model.name == 'Test.Reminder')
      .ensure("hasReceiveCopy").displayName("main.admin.pages.messages.hasReceiveCopy").required().when(x => this.model.name == 'Test.Activations')
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.messageTemplateService.client.isRequesting;
  }

  public async save(): Promise<any> {
    if (this.model.id === undefined || this.model.id === 0) {
      return await this.messageTemplateService.create(this.model)
        .then(async (response: MessageTemplate) => {
          this.model = response;
          this.notification.success("notifications.item_added");
          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("messages");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.messageTemplateService.update(this.model)
        .then(async () => {
          this.notification.success("notifications.item_edited");
          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("messages");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }

  }
}
