import { ActivationQuestionaryStatus } from "models/test-person/activationQuestionaryStatus";

export class EmailHistory {
  fromEmail: string; 
  msgId: string;
  subject: string;
  toEmail: string;
  status: string;
  activationQuestionaryStatus: ActivationQuestionaryStatus;
  opensCount: number;
  clicksCount: number;
  lastEventTime: Date;
}
