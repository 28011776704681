import {
  inject,
  newInstance,
  LogManager,
  autoinject
} from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Question } from 'models/questions/question';
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class CustomCompanyQuestionService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient
  ) {

    this.logger = LogManager.getLogger('CustomCompanyQuestionsService');
  }

  // public async getAll(questionaryId: number): Promise<Question[]> {
  //   return await this.client.get(`customcompanyquestion/questionary/${questionaryId}`)
  //     .then(response => {
  //       return <Question[]>response;
  //     });
  // }

  public async list(questionaryId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`customcompanyquestion/list/${questionaryId}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<Question> {
    // this.logger.info("id: " +  id); 
    return await this.client.getById("customcompanyquestion", id)
      .then(response => {
        return <Question>response;
      });
  }

  public async create(model: Question): Promise<Question> {
    return await this.client.post('customcompanyquestion', model)
      .then(response => {
        return <Question>response;
      });
  }

  public async update(model: Question): Promise<Question> {
    return await this.client.updateById('customcompanyquestion', model.id, null, model)
      .then(response => {
        return <Question>response;
      });
  }

  async delete(id: number): Promise<void> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.deleteById('customcompanyquestion', id)
      .then(() => null);
  }

  async uploadImage(image): Promise<any> {
    let formData = new FormData();
    formData.append('file', image);

    return await this.client.post('customcompanyquestion/upload', formData)
      .then(response => {
        return <any>response;
      });
  }

}
