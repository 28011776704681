import { ViewEngineHooks, View } from 'aurelia-framework';
import { GameTest } from './gameTest';

export class GameTestViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['GameTest'] = GameTest;

    view.overrideContext['GameTests'] = 
      Object.keys(GameTest)
        .filter((key) => typeof GameTest[key] === 'number');
  }
}
