
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";

import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class ProfileChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("GroupChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./profile-edit"),
        name: "profiles-edit",
        title: this.i18n.tr("main.admin.pages.profiles.profile"),
        nav: false,
        settings: {
          permission: {
            only: [PredefinedPermissions.ProfileUpdate]
          }
        }
      },
      
    ]);
  }
}
