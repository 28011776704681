import { observable, computedFrom } from 'aurelia-framework';
//import { computedFrom } from "aurelia-binding";

export class Quota {
  id: number;
  AgreementTermsOfPayId: number;
  payMode: string;
  paymentDate: Date;

  percentage: number = 0;
  subtotal: number = 0;
  tax: number = 0;
  // _total: number = 0;





  // @observable
  // osubtotal: number;

  // @observable
  // otax: number;

  // constructor() {
  //   this.subtotal = 0;
  //   this.tax = 0;
  //   this._total = 0;
  // }

  // private osubtotalChanged(newValue: number, oldValue: number): void {
  //   //console.log(newValue, oldValue);
  //   this.subtotal = +newValue;
  //   //this.osubtotal = newValue;
  //   this.total = +newValue + +this.otax;
  // }

  // private otaxChanged(newValue: number, oldValue: number): void {
  //   //console.log(newValue, oldValue);
  //   this.tax = +newValue;
  //   //this.otax = newValue;
  //   this.total = +newValue + +this.osubtotal;
  // }


  // @computedFrom('subtotal', 'tax')
  // get total(): number {
  //   // console.log("subtotal: " + this.subtotal);
  //   // console.log("tax: "+this.tax);
  //   this._total = +this.subtotal + +this.tax;
  //   return this._total;
  // }

  // set total(n: number) {
  //   console.log("set: " + n);
  //   this._total = +n;
  // }
}
