import { autoinject, bindable } from 'aurelia-framework';
import { DialogController } from "aurelia-dialog";
@autoinject()
export class TalentExportConfirmation {
  @bindable
  private totalResults : string = '0';
  constructor(private dialogController: DialogController) {
  }

  public activate(totalResults: number) {
    if(!isNaN(totalResults))
    this.totalResults = totalResults.toString();
  } 
  private async save(): Promise<any> {
    return await this.dialogController.ok();
  }
}
