import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Report } from "models/test-reports/reports/report";

@autoinject()
export class ReportService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("ReportService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("report/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<Report> {
    return await this.client.getById("report", id)
      .then((response: any) => {
        return <Report>response;
      });
  }

  public async create(model: Report): Promise<Report> {
    return await this.client.create("report", model)
      .then((response: any) => {
        return <Report>response;
      });
  }

}
