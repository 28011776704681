
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class CompanyGroupListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("CompanyGroupListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./company-group-list"),
        name: "company-groups",
      },

      {
        route: "company-group/:companyGroupId",
        moduleId: PLATFORM.moduleName("./company-group-child-router"),
        name: "company-group-child-router",
      },

      {
        route: "company-group/:companyGroupId/company/:companyDetailId",
        moduleId: PLATFORM.moduleName("../companies/company-child-router"),
        name: "company-child-router",
      },
      
    ]);
  }
}
