import { CompanyAppHttpClient } from 'common/services/company-app-http-client';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AgreementCustomerReq } from 'models/agreements/agreementCustomerReq';

@autoinject()
export class AgreementCustomerReqService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("AgreementCustomerReq");
  }

  public async getById(agreementId: number): Promise<AgreementCustomerReq> {
    return await this.client.getById("agreementCustomerReq", agreementId)
      .then((response: any) => {
        return <AgreementCustomerReq>response;
      });
  }

  public async create(model: AgreementCustomerReq): Promise<AgreementCustomerReq> {
    return await this.client.create("agreementCustomerReq", model)
      .then((response: any) => {
        return <AgreementCustomerReq>response;
      });
  }

  public async update(model: AgreementCustomerReq): Promise<void> {
    return await this.client.updateById("agreementCustomerReq", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("agreementCustomerReq", id)
      .then(() => null);
  }
}
