import { archetypeType } from './archetypeType';

export class Archetype {
  id: number;
  name: string;
  d : number;
  i : number;
  s : number;
  c : number;
  archetypeType: archetypeType;
  isActive: boolean = true;
  description: string;
}
