import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, LogManager, bindable, Disposable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { ActivationQuestionaryService } from "../../services/people/activation-questionary-service";
import { Person } from "models/test-person/person";
import { PersonQuestionaryActivationMessage } from 'main/test/messages/personActivationMessage';
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { CompetenceActivatedModal } from 'main/test/pages/people-competence/competence-activated-modal';

@autoinject()
export class PeopleQuestionaryList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;
  private company: Company;

  @bindable
  private person: Person;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private subscriptions: Disposable[] = [];

  constructor(
    private activationQuestionaryService: ActivationQuestionaryService,
    private notification: Notification,
    private ea: EventAggregator,
    private dialogService: DialogService
  ) {

    this.logger = LogManager.getLogger("PeopleQuestionaryList");

    // event when new activation is created
    this.subscriptions.push(this.ea.subscribe(PersonQuestionaryActivationMessage, this.databind.bind(this)));

    this.parameters.search.ActivationQuestionaryStatus = -1;
  }

  public unbind() {
    this.subscriptions.forEach(x => x.dispose());
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.activationQuestionaryService.list(this.parameters, this.person.id)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  private async requalify(id: number){
    await this.activationQuestionaryService.requalifyById(id);
    this.notification.success("notifications.item_edited");
  }
  private async bind(): Promise<any> {
    if (this.person) {

      this.activationQuestionaryService.client.currentCompanyId = this.person.companyId;

      return await this.databind();
    }

  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<any> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async delete(id: number, name: string): Promise<any> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.activationQuestionaryService.delete(id)
            .then(() => {

              this.notification.success("notifications.item_deleted");
              this.databind();
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      });
  }

  private clearFilters() {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
    this.parameters.search.ActivationQuestionaryStatus = -1;
  }

  private async seeActivatedCompetences(id: number): Promise<void> {
    await this.dialogService.open({
      viewModel: CompetenceActivatedModal,
      model: id
    })
  }

}
