import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PersonObserver } from 'models/test-person/personObserver';
import { PersonObserverService } from "main/test/services/people/person-observer-service";

@autoinject()
export class ObserverEditModal {

  private logger: Logger;

  public companyId: number;

  public totalWeight: number;

  private model: PersonObserver = new PersonObserver();
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private personObserverService: PersonObserverService
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ObservationEdit");

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("observerRole").displayName("main.testperson.pages.observation_activation.observator_fields.role").required()
      .ensure("weight").displayName("main.test.pages.position.position_evaluator_list.weight").required()
      .on(this.model);

    ValidationRules
      .ensure("firstName").displayName("main.admin.pages.users.first_name").required()
      .ensure("lastName").displayName("main.admin.pages.users.last_name").required()
      // .ensure("email").displayName("main.admin.pages.users.email").email()
      .on(this.model.observer);
  }
  
  public async activate(model): Promise<any> {
    this.model = model;
    this.totalWeight = 0;
    const ob = await this.personObserverService.getAll(this.model.observedId);
    ob.filter(e => e.activationId == this.model.activationId).forEach(e => this.totalWeight += +e.weight);
    
    this.configureValidationRules();
  }

  public async submit(): Promise<void> {
    if(Number(this.model.weight) + this.totalWeight <= 100) {
      return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
    } else {
      this.notification.error("main.testperson.pages.observation_activation.observator_fields.weight");
    }
  }

  private async save(): Promise<any> {
    return await this.dialogController.ok()
    .then(async () => {
      await this.dialogController.ok();
  })
  .catch(error => {
    this.notification.error(error);
  });
  }

  public notificationInfo(message:string){
    this.notification.info(message);
  }


}
