import { CompanyCompetenceService } from './../../../services/test-management/company-competence-service';
import { CompanyCompetence } from './../../../../../models/test-core/competences/company-competence';
import { DialogService } from 'aurelia-dialog';
import { DialogCloseResult } from 'aurelia-dialog';
import { CompanyCompetenceModal } from '../../../../test/pages/people-competence/company-competence-modal';
import { QuestionaryType } from './../../../../../models/questionaries/questionaryType';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from 'models/companies/company';
import { ReportTemplate } from 'models/test-core/report-templates/report-template';
import { ReportTemplateService } from '../../../services/test-management/report-template-service';
import { CompanySheetService } from 'main/admin/services/test-management/company-sheet-service';
import { Sheet } from 'models/test-core/sheets/sheet';
import { Category } from 'models/test-core/sheets/category';
import { CategorySheetService } from 'main/admin/services/test-management/category-sheet-service';
import { Questionary } from "models/questionaries/questionary";
import { CarouselConfig } from "common/resources/elements/ux/carousel/carousel";
import * as $ from "jquery";
import { isReturnStatement } from "typescript";
import { Tests } from 'models/test-person/tests';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';

interface SheetAvailable {
  [id: number]: boolean;
}

@autoinject()
export class SheetPredictorListEdit {

  private logger: Logger;

  public companyId: number;
  private company: Company;

  private validationController: ValidationController;

  private reportTemplateId: number;
  private languageId: number;
  private model: ReportTemplate = new ReportTemplate();

  private categories: Category[] = [];
  private sheets: Sheet[] = [];

  private state: State;

  private search: string = null;

  private questionariesCount: number = 0;
  private duration: number = 0;
  private price: number = 0;

  private sheetIds: number[] = [];
  private sheetIdsWithCompetencesEnabled: number[] = [];
  private sheetIdsWithCompetences: number[] = [];

  private sheetAvailable: SheetAvailable = {};
  private isCompetenceActive: boolean = false;
  private companyCompetences: CompanyCompetence[] = [];

  private options = <CarouselConfig>{
    duration: 600,
    perPage: {
      640: 1,
      768: 2,
      1080: 3
    },
    draggable: false,
    loop: false,
    autoPlay: true,
    autoPlayInterval: 2500
  };
  private questionaryId: number;
  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private dialogService: DialogService,
    private validationControllerFactory: ValidationControllerFactory,
    private reportTemplateService: ReportTemplateService,
    private companySheetService: CompanySheetService,
    private categorySheetService: CategorySheetService,
    private companyCompetenceService: CompanyCompetenceService,
    private coreCompanyService: CoreCompanyService
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("SheetPredictorListEdit");
    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");

    if (!isNaN(params.companyId)) {

      this.companyId = +params.companyId;
      this.reportTemplateService.client.currentCompanyId = +params.companyId;
      this.company = this.state.companies.find(x => x.id == this.companyId);

      this.companyCompetenceService.client.currentCompanyId = this.companyId;

      try {
        let settings = await this.coreCompanyService.getSettingsById(this.companyId);
        this.questionaryId = settings.competencesQuestionaryId;

        if (this.questionaryId == 0)
          this.questionaryId = Tests['Competences'];
        this.companyCompetences = await this.companyCompetenceService.getAll(this.questionaryId);
        this.sheets = await this.companySheetService.getAll();
        this.setSheetIdsWhitCompetences();

        if (!isNaN(params.reportTemplateId)) {
          this.reportTemplateId = +params.reportTemplateId;
          this.model = await this.reportTemplateService.getById(this.reportTemplateId);
          this.languageId = this.model.languageId;

          this.fillSheetIdsWithCompetencesEnabled();
          this.setDurationAndPrice();

          // update sheet available
          this.setSheetAvailable();

          return await this.databind();
        }

      } catch (error) {
        this.notification.error(error);
      }
    }
  }


  private async databind(): Promise<any> {
    this.logger.debug("databind");
    try {
      this.categories = await this.categorySheetService.list(this.reportTemplateId, this.languageId, this.search);
    } catch (error) {
      this.notification.error(error);
    }
  }

  get canSave() {
    return !this.reportTemplateService.client.isRequesting;
  }

  public async save(saveAndContinue: boolean): Promise<any> {
    this.logger.debug("sheetIds", this.model.sheetIds);

    return await this.reportTemplateService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!saveAndContinue) {
          return await this.router.navigateToRoute("report-templates-predictor");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    this.logger.debug("isCompetenceActive: ", this.isCompetenceActive);
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.companyCompetences.length > 0 && this.isCompetenceActive && this.model.companyCompetenceIds.length == 0) {
            this.notification.warning("main.test.pages.people.errors.no_competences_selected");
          }
          else {
            return await this.save(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private changeSheetState(sheet: Sheet) {
    const index = this.model.sheetIds.indexOf(sheet.id);

    if (index !== -1) {
      this.model.sheets.push(sheet);
    }
    else {
      const index2 = this.model.sheets.findIndex(x => x.id === sheet.id);
      if (index2 !== -1) {
        this.model.sheets.splice(index2, 1);

        // add sheets to general list
        // this.categories.find(x => x.id === sheet.catergoryId).sheets.push(sheet);

      }

    }

    if (this.companyCompetences.length > 0 && this.changeSheetIdsWithCompetencesEnabled(sheet.id) && this.isCompetenceActive) {
      this.selectCompanyCompetence();
    }

    // update duration label
    this.setDurationAndPrice();

    // update sheet available
    this.setSheetAvailable();
  }

  // private showSheet(id: number){
  //   const index = this.model.sheetIds.indexOf(id);
  //   return index === -1;
  // }

  private setDurationAndPrice() {
    let questionaries: Questionary[] = [];
    let duration = 0;
    let price = 0;
    this.model.sheetIds.forEach(x => {
      let sheet = this.sheets.find(y => y.id === x);
      if (sheet) {
        price += +sheet.talentsPrice;
        sheet.questionaries.forEach(q => {
          if (!questionaries.find(z => z.id === q.id)) {
            questionaries.push(q);
            duration += +q.averageTime;
          }
        });
      }
    });

    this.questionariesCount = questionaries.length;

    this.duration = duration;
    this.price = price;

  }

  private isCompetenceType(sheets: Sheet[]): boolean {
    let res: boolean = false;
    sheets.forEach(x => {
      let sheet = this.sheets.find(y => y.id === x.id);
      if (sheet) {
        let questionary = sheet.questionaries.find(q => q.questionaryType == QuestionaryType.Competences);
        if (questionary) {
          res = true;
        }
      }
    });
    return res;
  }

  private setSheetAvailable() {
    this.sheets.forEach(x => {
      if (this.model.sheetIds.indexOf(x.id) !== -1) {
        this.sheetAvailable[x.id] = false;
      }
      else {
        this.sheetAvailable[x.id] = true;
      }

    });
  }


  public orderChanged(orderedItems) {

    // this.logger.debug('Ordered sheet: ', orderedItems.map(x => x.id));
    this.model.sheets = orderedItems;
    this.model.sheetIds = orderedItems.map(x => x.id);

    // this.logger.debug('Model sheets: ', this.model.sheets.map(x => x.id));
    // this.logger.debug('Model sheetIds: ', this.model.sheetIds);

    // update duration label
    this.setDurationAndPrice();

    // update sheet available
    this.setSheetAvailable();

    //this.logger.debug('orderedItems: ', orderedItems);
    let hasAddsheetIds = false;

    orderedItems.forEach(x => {
      if (this.changeSheetIdsWithCompetencesEnabled(x.id)) {
        hasAddsheetIds = true;
      }
    });

    if (!hasAddsheetIds) {
      let copysheetIdsWithCompetencesEnabled: number[] = this.sheetIdsWithCompetencesEnabled;
      copysheetIdsWithCompetencesEnabled.forEach(x => {
        this.changeSheetIdsWithCompetencesEnabled(x);
      })
    }

    this.logger.debug('this.model.sheetIds: ', this.model.sheetIds);
    this.logger.debug('sheetIdsWithCompetencesEnabled: ', this.sheetIdsWithCompetencesEnabled);

    if (this.companyCompetences.length > 0 && hasAddsheetIds && this.isCompetenceActive) {
      this.selectCompanyCompetence();
    }
  }

  // public drawPreview(sheet) {
  //   this.logger.debug('Sheet: ', sheet);
  //   const jq = $('<div class="er4-drag-person"></div>');
  //   jq.text('sheet.name'); //
  //   return jq.get(0);
  // }

  private async selectCompanyCompetence(): Promise<void> {
    // this.logger.debug("model.companyCompetenceIds")
    return await this.dialogService.open({
      viewModel: CompanyCompetenceModal,
      model: this.model.companyCompetenceIds
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (this.companyCompetences.length > 0 && this.isCompetenceActive && this.model.companyCompetenceIds.length == 0) {
          this.notification.warning("main.test.pages.people.errors.no_competences_selected");
        }
      }
    });
  }

  private setSheetIdsWhitCompetences() {
    this.sheets.forEach(x => {
      x.questionaries.forEach(q => {
        if (q.questionaryType == QuestionaryType.Competences) {
          const index = this.sheetIdsWithCompetences.indexOf(x.id);
          if (index === -1) {
            this.sheetIdsWithCompetences.push(x.id);
          }
        }
      });
    });
  }

  private changeSheetIdsWithCompetencesEnabled(idSheet: number): boolean {
    let sheetIdsWithCompentenceChanged: boolean = false;
    const indexCompetenceSheetIds = this.sheetIdsWithCompetences.indexOf(idSheet);
    const indexModelSheetIds = this.model.sheetIds.indexOf(idSheet);

    if (indexCompetenceSheetIds !== -1) {
      const indexCompetenceEnabledSheetIds = this.sheetIdsWithCompetencesEnabled.indexOf(idSheet);
      if (indexModelSheetIds !== -1) {
        if (indexCompetenceEnabledSheetIds === -1) {
          this.sheetIdsWithCompetencesEnabled.push(idSheet);
          sheetIdsWithCompentenceChanged = true;
        }
      } else {
        if (indexCompetenceEnabledSheetIds !== -1) {
          this.sheetIdsWithCompetencesEnabled.splice(indexCompetenceEnabledSheetIds, 1);
          sheetIdsWithCompentenceChanged = true;
        }
      }
    }

    if (this.companyCompetences.length > 0 && this.sheetIdsWithCompetencesEnabled.length > 0) {
      this.isCompetenceActive = true;
    } else {
      this.isCompetenceActive = false;
      this.model.companyCompetenceIds = [];
    }

    return sheetIdsWithCompentenceChanged;
  }

  private fillSheetIdsWithCompetencesEnabled() {
    this.sheetIdsWithCompetencesEnabled = [];
    this.model.sheetIds.forEach(x => {
      const indexCompetenceSheetIds = this.sheetIdsWithCompetences.indexOf(x);

      if (indexCompetenceSheetIds !== -1) {
        const indexCompetenceEnabledSheetIds = this.sheetIdsWithCompetencesEnabled.indexOf(x);
        if (indexCompetenceEnabledSheetIds === -1) {
          this.sheetIdsWithCompetencesEnabled.push(x);
        }
      }
    });

    this.logger.debug("sheetIdsWithCompetencesEnabled.length: ", this.sheetIdsWithCompetencesEnabled.length);

    if (this.companyCompetences.length > 0 && this.sheetIdsWithCompetencesEnabled.length > 0) {
      this.isCompetenceActive = true;
    } else {
      this.isCompetenceActive = false;
      this.model.companyCompetenceIds = [];

    }

    this.logger.debug("isCompetenceActive: ", this.isCompetenceActive);

  }

}
