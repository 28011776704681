import { ViewEngineHooks, View } from 'aurelia-framework';
import { LabelModule } from './labelModule';

export class LabelModuleViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['LabelModule'] = LabelModule;

    view.overrideContext['LabelModules'] = 
      Object.keys(LabelModule)
        .filter((key) => typeof LabelModule[key] === 'number');
  }
}
