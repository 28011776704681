// export class AgeRange{
//     items = [
//         {"id":1,"name": "main.admin.pages.scales.age_range_enum.from_zero_to_five", "code": "from.zero.to.five"},
//         {"id":2,"name": "main.admin.pages.scales.age_range_enum.from_six_to_ten", "code": "from.six.to.ten"},
//         {"id":3,"name": "main.admin.pages.scales.age_range_enum.from_eleven_to_nineteen", "code": "from.eleven.to.nineteen"},
//         {"id":4,"name": "main.admin.pages.scales.age_range_enum.from_twenty_to_thirty_nine", "code": "from.twenty.to.thirty.nine"},
//         {"id":5,"name": "main.admin.pages.scales.age_range_enum.from_forty_to_sixty_four", "code": "from.forty.to.sixty.four"},
//         {"id":6,"name": "main.admin.pages.scales.age_range_enum.from_sixty_five_to_older", "code": "from.sixty.five.to.older"}
//     ];

//     getNameById(id: number): string{
//         return this.items.find(x => x.id == id)['name'];
//     }

//     getIdByCode(code: string): number{
//         return this.items.find(x => x.code == code)['id'];
//     }
// }

export enum AgeRange {
  FromZeroToFive,
  FromSixToTen,
  FromElevenToFourteen,
  FromFifteenToFifteen,
  FromSixteenToSixteen,
  FromSeventeenToSeventeen,
  FromEighteenToNineTeen,
  FromTwentyToTwentyNine,
  FromThirtyToThirtyNine,
  FromFortyToFortyNine,
  FromFiftyToFiftyNine,
  FromSixteenToOlder,
}
