import { DialogCloseResult, DialogService } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { UxDatatableCustomAttribute, UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { AgreementService } from "main/admin/services/agreements/agreement-service";
import { CoreCompanyService } from "main/admin/services/companies/core-company-service";
import { PlanService } from "main/admin/services/plans/plan-service";
import { InboxByUserService } from "main/admin/services/test-core/inbox-by-user-service";
import { InboxService } from "main/admin/services/test-core/inbox-service";
import { NewsService } from "main/admin/services/test-core/news-service";
import { Company } from "models/companies/company";
import { Plan } from "models/plans/plan";
import { Inbox } from "models/test-core/inbox/inbox";
import { InboxByUser } from "models/test-core/inbox/inboxByUser";
import { InboxByUserStatus } from "models/test-core/inbox/inboxByUserStatus";
import { News } from "models/test-core/news/news";
@autoinject()
export class InboxByUserList {

  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private inboxList: Inbox[] = [];
  private params: any = {};
  private companyId: number;
  private state: State;
  private inbox: Inbox = new Inbox();
  private inboxId: number;
  private bodyText: string;
  private userId: number;
  private company: Company;
  private unRead: number;
  public isDisable: boolean = false;
  public subjectText: string;
  constructor(
    private i18n: I18N,
    private router: Router,
    private store: StoreService,
    private inboxService: InboxService,
    private dialogService: DialogService,
    private notification: Notification,
    private planService: PlanService,
    private agreementService: AgreementService,
    private coreCompanyService: CoreCompanyService,
    private inboxByUserService: InboxByUserService) {

    this.logger = LogManager.getLogger("InboxByUserList");
    this.state = this.store.state;
  }
  

  public async activate(params: any) { 


    this.params = params;
    if (!isNaN(this.params.companyId)) {
      this.companyId = this.params.companyId;  

      if (this.companyId) {

        this.company = this.state.companies.find(x => x.id == this.companyId);        
        await this.getInboxByCurrentCompany(this.companyId)
      
      }  
    }
    else{
      this.companyId = this.state.currentCompany.id;
    }
  }
  private async getInboxByCurrentCompany(companyId: number){
    try {
      
      let planIdsTest = (await this.agreementService.getAgreementsByCompanyId(companyId,false)).map(x=> x.id);
       let planIdsSelection = (await this.agreementService.getAgreementsByCompanyId(companyId,true)).map(x=> x.id);
  
      if (planIdsTest.length > 0 )
        this.inbox.planTest = planIdsTest;
      if (planIdsSelection.length > 0)
        this.inbox.planSelection = planIdsSelection;
      

      this.inboxList = await this.inboxService.getAllByCompany(companyId,this.inbox);
     
      this.unRead =  this.inboxList.filter(x=> x.inboxByUserStatus == InboxByUserStatus.Received).length;
       
      if (this.unRead > 99)       
      this.unRead = 99;  

      if (this.unRead == 0)
      this.isDisable = true;
      
    } catch (error) {
    
      this.notification.error(error);
    }
  }
  private async selectInbox(item: Inbox): Promise<any> {
    // this.logger.debug("selectFranshise", franchiseId);
    this.inboxId = item.id;
    this.bodyText = item.body;
    this.subjectText = item.subject;
    let modelInboxByUser = new InboxByUser();
    modelInboxByUser.inboxId = item.id;
    modelInboxByUser.inboxByUserStatus = item.inboxByUserStatus;
  
    await this.inboxByUserService.create(modelInboxByUser)
    await this.getInboxByCurrentCompany(this.companyId)
   // return await this.databind();
  }
 
}
