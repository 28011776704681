import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { ActivationQuestionaryService } from "../../test/services/people/activation-questionary-service";
import { Report } from "models/test-reports/reports/report";
import { Parameter } from "models/test-reports/reports/parameter";
import { ActivationQuestionary } from "models/test-person/activationQuestionary";
import { DialogService } from "aurelia-dialog";
import { CompetenceActivatedModal } from "main/test/pages/people-competence/competence-activated-modal";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ActivationType } from "models/test-person/activationType";

interface ItemAvailable {
  [id: number]: boolean;
}
@autoinject()
export class SelectPersonQuestionaryList {

  private logger: Logger;

  @bindable
  private report: Report;

  @bindable
  private parameterCurrent: Parameter;

  @bindable
  private selectedAmount: number;

  @bindable
  public isDiscType: boolean;

  private selectedCurrent: number;
  private banCheck: boolean;
  private banIds: number[] = [];

  private itemAvailable: ItemAvailable = {};

  private items: any[] = [];

  private selectionLabel: string;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  @bindable
  private questionaryId: number;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing"); 
    return await this.activationQuestionaryService.list(this.parameters, this.report.personId)
      .then((response: UxDatatableResponse) => {
        if(!this.isDiscType)
        response.data = response.data.filter(x => x.questionaryId == this.questionaryId);
        else
        response.data = response.data.filter(x => x.questionaryType == QuestionaryType.Disc && x.activationType == ActivationType.Person);
        response.totalRecords = response.data.length;
        this.checkAmount();
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }


  constructor(
    private activationQuestionaryService: ActivationQuestionaryService,
    private notification: Notification,
    private dialogService: DialogService) {

    this.logger = LogManager.getLogger("SelectPersonQuestionaryList");
    this.selectedCurrent = 0;
    this.banCheck = false;
  }

  private async attached(): Promise<any> {

    if (this.report.companyId) {
      this.activationQuestionaryService.client.currentCompanyId = this.report.companyId;
      return await this.databind();
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private getSelectionLabel(): void {
    this.selectionLabel = '';
    this.items.forEach(x => {
      if (this.selectionLabel !== '') {
        this.selectionLabel += ', ';
      }
      this.selectionLabel += x.applicationDate;
    });
  }

  public async selectParameter(item: ActivationQuestionary): Promise<void> {
    let banGeneral = this.banIds.findIndex(y => y === item.id);

    if (banGeneral == -1) {
      this.banIds.push(item.id);
      this.selectedCurrent = ++this.selectedCurrent;
      this.items.push(item);
    }
    else {
      this.banIds.splice(banGeneral, 1);
      this.selectedCurrent = --this.selectedCurrent;
      this.items.splice(banGeneral, 1);
    }

    this.checkAmount();


    switch (this.parameterCurrent) {
      case Parameter.Questionary: {
        if(banGeneral == -1){
           this.report.activationQuestionaryIds.push(item.id);
        }else{
          this.report.activationQuestionaryIds.splice(banGeneral, 1);
        }
        break;
      }
      default: {
        break;
      }
    }
    
    this.getSelectionLabel();

  }

  private checkAmount() {
    if (this.selectedCurrent < +this.selectedAmount) {
      this.banCheck = false;
      this.parameters.tableData.forEach(x => {
        this.itemAvailable[x.id] = false;
      });

    } else {
      this.banCheck = true;
      this.parameters.tableData.forEach(x => {
        let ban = this.banIds.findIndex(y => y === x.id);
        if (ban === -1)
          this.itemAvailable[x.id] = true;
        else
          this.itemAvailable[x.id] = false;
      });
    }
  }
  

  private async seeActivatedCompetences(id: number): Promise<void> {
    await this.dialogService.open({
      viewModel: CompetenceActivatedModal,
      model: id
    })
  }

}
