import { AuthorizationService } from 'aurelia-permission';

import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class PeopleVideoInterviewChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {

    this.logger = LogManager.getLogger("PeopleVideoInterviewChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("../people-videointerview/people-videointerview-edit"),
        name: "people-videointerview",
      },

      {
        route: "/:activationQuestionaryId?",
        moduleId: PLATFORM.moduleName("../people-videointerview/people-videointerview-edit"),
        name: "people-videointerview-id",
      },

    ]);
  }
}
