import { autoinject } from 'aurelia-framework';
import { DialogController } from "aurelia-dialog";

@autoinject()
export class ConvertMasterConfirmation {
  private name: string;

  constructor(private dialogController: DialogController) {
  }

  public activate(name: string) {
    this.name = name;
  }
}
