
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class ProfileListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("ProfileListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./profile-list"),
        name: "profiles",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.profiles.title"),
      },

      {
        route: "profile/:profileId",
        moduleId: PLATFORM.moduleName("./profile-child-router"),
        name: "profile-child-router",
        // nav: false,
        // title: this.i18n.tr("main.admin.pages.profiles.profile"),
      },
    ]);
  }
}
