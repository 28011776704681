import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig, RoutableComponentDetermineActivationStrategy, activationStrategy } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { SessionService } from "common/services/session-service";
import { UserState } from "models/security/userState";
import { Role } from "models/security/role";
import { State } from "common/store/store-model";
import { ServiceRatingService } from 'main/admin/services/service-rating/service-rating-service';
import { ServiceRating } from 'models/service-rating/service-rating';
@autoinject()
// @connectTo()
export class UserSettingEdit {

  private logger: Logger;
  private model: ServiceRating;
  private validationController: ValidationController;
  public state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,    
    private i18n: I18N,
    private sessionService: SessionService,
    private serviceRatingService: ServiceRatingService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ServiceRatingEdit");
    this.state = this.store.state;

  }

  private async activate(): Promise<void> {
    // this.logger.debug("Activated id:", params.coreUserId);
    try {    
        this.model = await this.serviceRatingService.getServiceRatingSettings();     
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }
  private configureValidationRules(): void {
    ValidationRules
      .ensure("value").displayName("main.admin.pages.service-rating_value").required()
      .on(this.model);
  }

  private async submit(saveAndContinue: boolean): Promise<any> {
    let result = await this.validationController.validate();
    if (result.valid) {
      return await this.save(saveAndContinue);
    }

  }

  private async save(saveAndContinue: boolean): Promise<any> {
    return await this.serviceRatingService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");
        if (!saveAndContinue) {
          return await this.router.navigateToRoute("service-rating-list");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });

  }
}
