
import { autoinject, LogManager } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";

import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject()
export class GroupChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("GroupChildRouter");
  }


  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: [""],
        moduleId: PLATFORM.moduleName("./group-edit"),
        name: "groups-edit",
        nav: true,
        title: this.i18n.tr("main.admin.pages.groups.group"),
        settings: {
          permission: {
            only: [PredefinedPermissions.GroupUpdate]
          }
        }
      },
      {
        route: ["permissions"],
        moduleId: PLATFORM.moduleName("./permissions/permission-edit"),
        name: "groups-edit-permissions",
        nav: true,
        title: this.i18n.tr("main.admin.pages.permissions.title"),
        settings: {
          permission: {
            only: [PredefinedPermissions.GroupUpdate]
          }
        }
      }
    ]);
  }
}
