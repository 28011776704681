import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DiscAdjective } from "models/test-core/discAdjective";
import { AppHttpClient } from "common/services/app-http-client";

@autoinject()
export class DiscAdjectiveService {

  private logger: Logger;

  constructor(
    public client: AppHttpClient) {
    this.logger = LogManager.getLogger("DiscAdjectiveService");
  }

  public async getAll(): Promise<DiscAdjective[]> {
    return await this.client.get(`DiscAdjective`)
      .then((response: any) => {
        return <DiscAdjective[]>response;
      });
  }

}
