import { AuthorizationService } from 'aurelia-permission';

import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class PersonListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {

    this.logger = LogManager.getLogger("PersonListChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./person-list"),
        name: "people",
      },

      {
        route: "bulk-activations",
        moduleId: PLATFORM.moduleName("../bulk-activation/bulk-activation-list-child-router"),
        name: "bulk-activation-list-child-router",        
        nav: true,
        title: this.i18n.tr("main.test.pages.bulk_activations.bulks"),
      },

      {
        route: "create",
        moduleId: PLATFORM.moduleName("./person-activation-create"),
        name: "person-activation-create",
        nav: true,
        title: this.i18n.tr("main.testperson.pages.persons_activation.title"),
      },   

      {
        route: "person/:personId",
        moduleId: PLATFORM.moduleName("./person-child-router"),
        name: "person-child-router",
      },   

      {
        route: "person-observation/:observedId?",
        moduleId: PLATFORM.moduleName("../observation/observation-child-router"),
        name: "observation-child-router",
      },     

      {
        route: "homologation/:personId/:activationId?",
        moduleId: PLATFORM.moduleName("../homologation/homologation-child-router"),
        name: "homologation-child-router",
        title: this.i18n.tr("main.testperson.pages.homologation_activation.title")
      },
      {
        route: "bulk-results-export",
        moduleId: PLATFORM.moduleName("../bulk-results-export/bulk-results-export-child-router"),
        name: "bulk-results-export-child-router",
        title: this.i18n.tr("main.admin.pages.plans.test_plan_items.export_results_card.title")
      },

    ]);
  }
}
