import { autoinject, LogManager, observable, Disposable } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router, RouterConfiguration } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { User, UserManager } from "oidc-client";
import { OpenIdConnect, OpenIdConnectRoles } from "aurelia-open-id-connect";
import { PermissionStore, Configuration, AuthorizationService } from 'aurelia-permission';
import PredefinedPermissions from "config/predefined-permissions";
import { Notification } from 'common/services/notification';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
import { I18N } from 'aurelia-i18n';
import { SessionService } from 'common/services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';

import Flatpickr from "flatpickr";
import { es } from "flatpickr/dist/l10n/es.js";

import * as $ from 'jquery';

@autoinject()
export class App {

  // reference to the single state
  // this will be shared with all subcomponents
  public state: State;

  private logger: Logger;
  private router: Router;

  @observable
  private user: User = null;

  private subscriptions: Disposable[] = [];

  constructor(
    private i18n: I18N,
    private store: StoreService,
    private sessionService: SessionService,
    private permissionStore: PermissionStore,
    private coreCompanyService: CoreCompanyService,
    private openIdConnect: OpenIdConnect,
    private notification: Notification,
    private ea: EventAggregator    
  ) {

    this.logger = LogManager.getLogger('App');

    this.state = this.store.state;

    this.subscriptions.push(this.ea.subscribe('router:navigation:success', this.refresh.bind(this)));
  }

  public async attached(): Promise<any> {
    this.user = await this.sessionService.getUser();
  }

  private async userChanged(): Promise<any> {
    if (this.user) {
      await this.loadUserProfile();
    }
  }

  private async refresh(): Promise<any> {
    $('#navbarToggleSidebar').removeClass('show');

    if (!$('#navbarToggleSidebar').length) {
      $('.navbar-brand').removeClass('hide');
      $('.navbar-toggler').removeClass('show');
      $('#main-content, #main-footer').removeClass('content-sidebar--closed content-sidebar--open');
    }
  }

  private async loadUserProfile(): Promise<any> {

    try {
      const profile = await this.sessionService.getProfile();
      this.permissionStore.useDefaultDefinition(profile.permissions);
      this.permissionStore.definePermissionObject(PredefinedPermissions);
      await this.store.setUserBasicInfoState(profile.basic_info);

      await this.loadCompanies();

      if (profile.basic_info.culture) {
        // set locale
        await this.i18n.setLocale(profile.basic_info.culture);
        // set flatpickr locale
        this.setFlatpickrLocale();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }
  
  private async loadCompanies(): Promise<any> {
    try {
      const companies = await this.coreCompanyService.getAll();
      await this.store.setCompaniesState(companies);
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public setFlatpickrLocale(): void {

    if (this.state && this.state.userBasicInfo) {

      var genericCulture = this.state.userBasicInfo.culture.split("-")[0];

      switch (genericCulture) {
        case "es":
          // this.logger.debug("genericCulture: ", genericCulture);
          Flatpickr.localize(es);
          break;
        default:
          break;
      }

    }
  }

  public async configureRouter(routerConfiguration: RouterConfiguration, router: Router): Promise<any> {

    // switch from hash (#) to slash (/) navigation
    routerConfiguration.options.pushState = true;
    routerConfiguration.title = "Tht";

    // configure routes
    routerConfiguration.map([

      {
        route: ['', 'home'],
        moduleId: PLATFORM.moduleName('./home'),
        name: 'home',
        title: 'Home',
        nav: true,
        settings: {
          t: 'main.pages.home.title'
        }
      },

      {
        route: 'company/:companyId?',
        moduleId: PLATFORM.moduleName('main/main-child-router'),
        name: 'main-child-router',
        settings: {
          roles: [OpenIdConnectRoles.Authenticated],
        }
      },

      {
        route: 'gui',
        moduleId: PLATFORM.moduleName('./gui'),
        name: 'gui'
      },

    ]);

    var handleUnknownRoutes = (instruction: { fragment: string; }) => {
      let path = instruction.fragment.toLowerCase();

      if (path.includes("admin"))
        return PLATFORM.moduleName("main/admin/pages/errors/admin-unknown-route.html");

      return PLATFORM.moduleName("main/pages/errors/what-happened.html");
    };

    routerConfiguration.mapUnknownRoutes(handleUnknownRoutes);

    // aurelia-openid-connect plugin configuration
    this.openIdConnect.configure(routerConfiguration);
    this.router = router;

  }
}
