import { autoinject } from "aurelia-framework";
import { OpenIdProfile } from '../../models/security/open-id-profile';
import { WootricComponentName } from "models/wootric/WootricComponentName";
import { ServiceRatingService } from "main/admin/services/service-rating/service-rating-service";
import { ServiceRating } from "models/service-rating/service-rating";

@autoinject
export class Wootric {

  private setupScript: HTMLScriptElement;
  private beacon: HTMLScriptElement;
  private profile: OpenIdProfile;
  private timestamp: number;
  private serviceRating: any;
  private currentToken: string;

  constructor(
    private serviceRatingService: ServiceRatingService) {
  }

  public async attached(profile: OpenIdProfile, component: WootricComponentName) {

    this.profile = profile;
    this.setupScript = document.createElement('script');

    this.serviceRating = await this.serviceRatingService.getServiceRatingSettings();
    this.currentToken = await this.setToken(component, this.serviceRating);
    this.timestamp = await this.toTimestamp(this.profile.basic_info.createdOn);

    if (this.currentToken) {
      this.setupScript.innerHTML = `
      window.wootricSettings = {
        email: '${this.profile.basic_info.userName}',
        created_at: '${this.timestamp}',
      account_token: '${this.currentToken}', // This is your unique account token.
      language: 'ES',
      properties:{
        companyId: '${this.profile.basic_info.companyId}', 
        company: '${this.profile.basic_info.company}'
      }
    };
    `;

      document.body.appendChild(this.setupScript);

      this.beacon = document.createElement('script');
      this.beacon.src = this.serviceRating.sdk;

      this.beacon.onload = () => {
        if (window['wootric_modal_loaded']) {
          window['wootric_modal_loaded'] = false;
          window['WootricSurvey'].stop();
        }
        window['WootricSurvey'].run();
      };
      document.body.appendChild(this.beacon);
    }
  }


  private async setToken(component: WootricComponentName, serviceRating: ServiceRating): Promise<string> {
    let currentToken = '';

    switch (component) {
      case WootricComponentName.mainHome:
        currentToken = serviceRating.statusSurvey1 ? serviceRating.tokenSurvey1 : '';
        break;
      case WootricComponentName.personList:
        currentToken = serviceRating.statusSurvey2 ? serviceRating.tokenSurvey2 : '';
        break;
      case WootricComponentName.personActivationCreate:
        currentToken = serviceRating.statusSurvey3 ? serviceRating.tokenSurvey3 : '';
        break;
    }

    return currentToken;
  }

  private async toTimestamp(strDate: any): Promise<number> {
    let datum = Date.parse(strDate);
    return Math.round(datum / 1000);
  }
}

