import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult } from "aurelia-dialog";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { PlanService } from "../../services/plans/plan-service";
import { CoreCompanyService } from "../../services/companies/core-company-service";
import { Company } from "models/companies/company";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Role } from "models/security/role";
import { Plan } from "models/plans/plan";
import { ChangeStateConfirmation } from "../../../components/change-state-confirmation";

@autoinject()
export class PlanList {

  private logger: Logger;
  private params: any = {};

  private franchiseId: number;
  private isRobotHunter: boolean;
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private companies: Company[] = [];
  private franchises: Company[] = [];

  private isLoaded: boolean = false;

  constructor(
    private coreCompanyService: CoreCompanyService,
    private planService: PlanService,
    // private dialogController: DialogController,
    private dialogService: DialogService,
    private notification: Notification,
    private router: Router) {

    this.logger = LogManager.getLogger("PlanList");
  }

  private async activate(params: any): Promise<void> {

    if (this.params.franchiseId != params.franchiseId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.franchiseId)) {
      this.franchiseId = +this.params.franchiseId;
      this.isRobotHunter = this.params.isRobotHunter;
      try {
        this.companies = await this.coreCompanyService.getAll()
        this.franchises = this.companies.filter(x => x.roleId === Role.Franchise);
        this.isLoaded = true;
        return await this.databind();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  public async databind(): Promise<void> {
    this.logger.info("databind");
    return await this.planService.list(this.franchiseId,this.isRobotHunter, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    return this.planService.list(this.franchiseId,this.isRobotHunter, this.parameters);
  }

  private async create(): Promise<any> {
    this.logger.info("create");
    return await this.router.navigateToRoute("plan-child-router", { planId: 'create' , isRobotHunter: this.isRobotHunter });
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.planService.delete(id)
            .then(() => {
              let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
              this.parameters.tableData.splice(indexOfEditedModel, 1);
              this.parameters.totalRecords--;
              this.notification.success("main.admin.pages.plans.messages.plan_deleted");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      });
  }


  public async changeState(item: Plan): Promise<void> {
    this.logger.debug("item.isActive", item.isActive);
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.planService.changeState(item.id)
          .then(() => {
            // item.isActive = !item.isActive;
            this.notification.success("main.admin.pages.plans.messages.activation_changed");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        item.isActive = !item.isActive;
      }
    });
  }

}
