import { autoinject, LogManager, bindable } from "aurelia-framework";
import { PlanService } from "../../../services/plans/plan-service";

import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { SpendMode } from 'models/plans/spendMode';

@autoinject()
export class CustomReportSpendMode {

  private logger: Logger;

  @bindable
  private planId: number;

  @bindable
  private customReportsSpendMode: SpendMode;

  constructor(
    private planService: PlanService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("CustomReportSpendMode");
  }

  private async changeCustomReportSpendMode(): Promise<void> {

    if (this.planId && this.customReportsSpendMode) {
      this.logger.debug("changeCustomReportSpendMode planId", this.planId);
      this.logger.debug("changeCustomReportSpendMode customReportsSpendMode", this.customReportsSpendMode);

      return await this.planService.changeCustomReportSpendMode(this.planId, this.customReportsSpendMode)
        .then(() => {
          this.notification.success("main.admin.pages.plans.messages.plan_edited");
        })
        .catch(error => {
          this.notification.error(error);
        });

    }
  }
}
