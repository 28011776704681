import { Genre } from 'models/catalogs/genre';
import { EducationalLevel } from 'models/catalogs/educationalLevel';
export class DemographicData {
  id: number;
  personId: number;
  firstName: string;
  lastName: string;
  genre: Genre;
  birthday: Date;
  age: number;
  countryId: number;
  countryName: string;
  regionId: number;
  regionName: string;
  cityId: number;
  cityName: string;
  // educationalLevelCountryId: number;
  educationalLevel: EducationalLevel;
  professionId: number;
  professionName: string;
}
