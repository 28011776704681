import { MessageTalent } from 'models/messages/message-talent';
import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";


import { MessageTalentService } from "../../services/messages/message-talent-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";


import { Subscription } from "aurelia-event-aggregator";

@autoinject()
export class MessageFranchiseTalent {

  private logger: Logger;
  private model: MessageTalent;
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;
  private expreRegular: RegExp;

  constructor(
    private router: Router,
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private messageTalentService: MessageTalentService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.expreRegular = /^[a-zA-Z0-9_\-\.~]{2,}@[a-zA-Z0-9_\-\.~]{2,}\.[a-zA-Z]{2,15}((;\s?){1}[a-zA-Z0-9_\-\.~]{2,}@[a-zA-Z0-9_\-\.~]{2,}\.[a-zA-Z]{2,15})*?$/;
    this.logger = LogManager.getLogger("MessageFranchiseTalent");

  }


  public async activate(params: any, routeConfig: RouteConfig): Promise<void> {
    this.logger.debug("Activate franchiseId:", params.franchiseId);
    if (params.franchiseId) {
      try {
        this.model = await this.messageTalentService.getById(+params.franchiseId);
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("assignment").displayName("main.admin.pages.messages.franchise.assignment").matches(this.expreRegular).required()
      .ensure("removal").displayName("main.admin.pages.messages.franchise.removal").matches(this.expreRegular).required()
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.messageTalentService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!this.saveAndContinue) {
          // return this.router.parent.navigate(`${this.model.franchiseId}`);
          return await this.router.navigateToRoute("messages-franchise-main");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
}
