import { Team } from "./team";
import { Person } from "../test-person/person";

export class TeamPerson {
  id : number;
  teamId  :number;
  team : Team;
  personId : number;
  person : Person;
  activationId: number;
}
