import { Transaction } from 'models/talents/transaction';
import { TransactionService } from '../../services/talents/transaction-service';
import { Wallet } from 'models/talents/wallet';
import { WalletService } from '../../services/talents/wallet-service';
import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
// import { DialogService } from "aurelia-dialog";

import { Notification } from "common/services/notification";
import { ValidationController, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

import { Company } from "models/companies/company";
// import { CoreCompanyService } from '../../services/companies/core-company-service';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';


@autoinject()
export class TalentTransfer {

  private logger: Logger;
  private params: any = {};
  private model: Wallet[] = [];
  public companyId: number;
  private company: Company;
  private validationController: ValidationController;
  public transfer_balance: number;

  public observationRemove: string;

  private wallets: Wallet[];
  private parameters: Wallet[];
  private tempWallet: Wallet[];

  public selectWallet: number;
  public balance_user: number;

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private router: Router,
    private transactionService: TransactionService,
    private walletService: WalletService,
    private validationControllerFactory: ValidationControllerFactory,
    private notification: Notification) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.logger = LogManager.getLogger("TalentTransfer");
    this.transfer_balance = 0;
    this.observationRemove = "";
    this.selectWallet = null;
    this.balance_user = 0;

    this.state = this.store.state;
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("requiredLength").displayName("main.admin.pages.companies.password_policy.requiredLength").matches(/^[0-9]*$/).required()
      .ensure("lockoutMaxFailedAccessAttempts").displayName("main.admin.pages.companies.password_policy.lockoutMaxFailedAccessAttempts").matches(/^[0-9]*$/).required()

      .on(this.model);
  }

  public async activate(params: any, routeConfig: RouteConfig): Promise<any> {

    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  public async bind(): Promise<any> {

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.transactionService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded = true;

        try {
          let wallets: Wallet[] = await this.walletService.getAll();
          this.wallets = wallets.filter(x => x.id > 0);
          this.parameters = wallets;
          this.tempWallet = wallets;
        } catch (error) {
          this.notification.error(error);
        }
      }
    }
  }

  public async calcular(item: Wallet): Promise<void> {
    if (item.canDiscountGeneralWallet) {
      this.transfer_balance = 0;
      this.parameters.forEach(x => this.transfer_balance += parseInt((x.amount.toString() == '' || x.amount.toString() == null) ? "0" : x.amount.toString()));
    }
  }

  public async filterUser(item: number): Promise<void> {
    this.parameters = this.tempWallet.filter(x => x.id != item);
    this.parameters.forEach(x => x.amount = 0);
    this.parameters.forEach(x => x.canDiscountGeneralWallet = false);
    this.transfer_balance = 0;
    this.balance_user = ((item == 0) ? 0 : this.wallets.find(x => x.id == item).talent);
  }

  public async filterLockout(item: Wallet): Promise<void> {
    if (!item.canDiscountGeneralWallet) {
      this.transfer_balance = 0;
      this.parameters.forEach(x => this.transfer_balance += parseInt((x.amount.toString() == '' || x.amount.toString() == null) ? "0" : x.amount.toString()));
    }
  }

  get canSave() {
    return !this.transactionService.client.isRequesting;
  }

  public async save(): Promise<any> {
    var list = [];
    var tran = new Transaction();
    var obs = this.observationRemove;
    var sourceUserId = this.wallets.find(x => x.id == this.selectWallet).user.id;
    this.parameters.forEach(function (item: Wallet) {
      if (item.canDiscountGeneralWallet) {
        tran = new Transaction();
        tran.wallet = item;
        tran.sourceUserId = sourceUserId;
        tran.targetUserId = item.user.id;
        tran.observation = obs;
        list.push(tran);
      }
    });

    try {

      await this.transactionService.transfer(list);

      this.notification.success("notifications.item_edited");

      return await this.router.navigateToRoute("talent-main");

    } catch (error) {
      this.notification.error(error);
    }
  }
}
