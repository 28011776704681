import { I18N } from 'aurelia-i18n';
import { Person } from '../../../../../models/test-person/person';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { autoinject, Disposable, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { PersonService } from "../../../services/people/person-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PersonObserverService } from "main/test/services/people/person-observer-service";
import { ObserverEditModal } from './observer-edit-modal';
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { PersonObserver } from 'models/test-person/personObserver';
import { PersonDeleteModal } from '../../people/person-delete-modal';
import { PersonLoginInfoModal } from '../../people/person-login-info-modal';
import { DeactivateConfirmation } from 'main/components/deactivate-confirmation';
import { BulkActivation } from 'models/test-person/bulkActivation';
import { Activation } from 'models/test-person/activation';
import { ActivationService } from 'main/test/services/people/activation-service';
import { ObserverBulkActivationModal } from './observer-bulk-activation-modal';
import { QuestionaryService} from "../../../../admin/services/questionaries/questionary-service";
import { ActivationConfirmation } from 'main/components/activation-confirmation';
import { PeopleQuestionaryActivationModal } from '../../people-questionary/people-questionary-activation-modal';
import { ActivationType } from 'models/test-person/activationType';
import { PersonActivationType } from 'models/test-person/personActivationType';
import { Report } from 'models/test-reports/reports/report';
import { ReportService } from '../../../../test-reports/services/report-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PersonDemographicDataEditMessage } from 'main/test/messages/personDemographicDataEditMessage';
import { DemographicData } from 'models/microsite/demographicData';
import { PersonDemographicDataService } from 'main/test/services/people/person-demographic-data-service';
import { PersonQuestionaryActivationMessage } from 'main/test/messages/personActivationMessage';

@autoinject()
export class ObserverList {

  private logger: Logger;

  public companyId: number;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private observedId: number;
  private activationId: number;
  private activation: Activation;
  private getdemographicData: boolean = true;
  private demographicData: DemographicData;
  
  private person: Person = new Person();
  private model: PersonObserver = new PersonObserver();
  private total: number = 0;
  private validationController: ValidationController;

  private subscriptions: Disposable[] = [];

  constructor(
    private router: Router,
    private i18n: I18N,
    private dialogService: DialogService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private personService: PersonService,
    private personObserverService: PersonObserverService,
    private activationService: ActivationService,
    private questionariesService: QuestionaryService,
    private reportService: ReportService,
    private ea: EventAggregator,
    private personDemographicDataService: PersonDemographicDataService
    ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ObservationEdit");

    // event when new activation is created
    this.subscriptions.push(this.ea.subscribe(PersonDemographicDataEditMessage, this.getPersonDemographicData.bind(this, true)));


  }

  public async activate(params: any): Promise<any> {
    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      this.personObserverService.client.currentCompanyId = this.companyId;
      this.personService.client.currentCompanyId = this.companyId;

      if (!isNaN(params.observedId) && !isNaN(params.activationId)) {

        this.observedId = +params.observedId;
        this.activationId = +params.activationId;

        this.person = await this.getPerson();
        // await this.databind();
      }
    }
  }

  private async bind(): Promise<any> {
    return await this.databind();
  }

  private async getPerson(): Promise<Person> {
    return await this.personService.getById(this.observedId);
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then(async (response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
        if (this.total === 100)
        {
          await this.confirmMoreActivations();
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  
  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async getPersonDemographicData(getAgain: boolean = false): Promise<any> {
    if(this.getdemographicData || getAgain){
      this.demographicData = await this.personDemographicDataService.get(this.person.id);
      this.getdemographicData = false;
    }
  }

  public async create(): Promise<any> {
    this.logger.info("createObserver");

    let item = new PersonObserver();
    item.observedId = this.observedId;
    item.activationId = this.activationId;
    item.observer = new Person();

    return await this.dialogService.open({
      viewModel: ObserverEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.personObserverService.create(item)
          .then(() => {

            this.notification.success("notifications.item_added");
            this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  public async edit(item: PersonObserver): Promise<any> {
    this.logger.info("editObserver");

    return await this.dialogService.open({
      viewModel: ObserverEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.personObserverService.update(item)
          .then(() => {
            this.notification.success("notifications.item_edited");
            this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      } else {
        await this.router.navigate(`/company/${this.companyId}/test/people/person-observation/${item.observedId}/observers/${this.activationId}`);
      }
    });

  }

  private async delete(item: PersonObserver): Promise<void> {
    this.logger.info("deleteObserver");
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.observer.fullName
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
          
        return await this.personObserverService.delete(item.id)
          .then(async () => {

            this.notification.success("notifications.item_deleted");

            await this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async createBulkActivation(): Promise<any> {
    let item = new BulkActivation();
    item.observedId = this.observedId;
    item.activationId = this.activationId;
    item.name = this.person.fullName;

    return await this.dialogService.open({
      viewModel: ObserverBulkActivationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.personObserverService.createBulkActivation(item)
          .then(() => {

            this.notification.success("notifications.item_added");
            this.databind();

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    try {
      let response: UxDatatableResponse = await this.personObserverService.list(this.observedId, this.activationId, this.parameters);
      this.total = await this.personObserverService.getWeightAllEvaluatorsByActivation(this.activationId, this.observedId);
      this.activation = await this.activationService.getById(this.activationId);
      return response;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async sendIndividualReminder(id: number): Promise<boolean> {
    try {
      await this.personService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async viewLoginInfo(personId: number): Promise<any> {
    return await this.dialogService.open({
      viewModel: PersonLoginInfoModal,
      model: personId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.sendIndividualReminder(personId);
      }
    });
  }

  private async canDeactivate(): Promise<boolean> {
    if (this.total === 100) {
      return true;
    }
    else if (this.parameters.totalRecords && this.parameters.totalRecords > 0) {
      return true;
    }

    return await this.dialogService.open({
      viewModel: DeactivateConfirmation,
      model: this.i18n.tr("main.testperson.pages.observation_activation.can_deactivate")
    }).whenClosed(async (response: DialogCloseResult) => {
      return !response.wasCancelled;
    });
  }

  public async confirmMoreActivations(): Promise<any> {
    this.logger.info("activationConfirmation");
    return await this.dialogService.open({
      viewModel: ActivationConfirmation,
      model: this.person.id
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        
        try {
          await this.createActivation();
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    });

  }

  private async createActivation(): Promise<any> {
    let item: Activation = new Activation();
    item.companyId = this.companyId;
    item.activationType = ActivationType.Person;
    item.personActivationType = PersonActivationType.Normal;

    return await this.dialogService.open({
      viewModel: PeopleQuestionaryActivationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.logger.debug("response.output", response.output);

        return await this.activationService.create(item, this.person.id)
          .then(async (model: Activation) => {
            item.id = model.id;

            if(response.output){
              let report = new Report();
              report = response.output;
              report.activationId = model.id;
              report.personId = this.person.id;
              report = await this.reportService.create(report);
              }

            this.notification.success("notifications.item_added");            

            // new activation
            this.ea.publish(new PersonQuestionaryActivationMessage());

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

}
