import { ViewEngineHooks, View } from 'aurelia-framework';
import { OrganizationType } from './organizationType';

export class OrganizationActivationTypeViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['OrganizationType'] = OrganizationType;

    view.overrideContext['OrganizationTypes'] = 
      Object.keys(OrganizationType)
        .filter((key) => typeof OrganizationType[key] === 'number');
  }
}
