import { PersonAppHttpClient } from 'common/services/person-app-http-client';
import { Logger } from 'aurelia-logging';
import { autoinject, LogManager } from "aurelia-framework";
import { DemographicData } from 'models/microsite/demographicData';
import { CompanyAppHttpClient } from 'common/services/company-app-http-client';

@autoinject
export class PersonDemographicDataService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("DemographicDataService");
  }

  public async get(id: number): Promise<DemographicData> {
    return await this.client.get(`persondemographicdata/${id}`)
      .then((response: any) => {
        return <DemographicData>response;
      });
  }

  // public async create(model: DemographicData): Promise<DemographicData> {
  //   return await this.client.create("demographicdata", model)
  //     .then((response: any) => {
  //       return <DemographicData>response;
  //     });
  // }

  public async update(model: DemographicData): Promise<void> {
    return await this.client.updateById("persondemographicdata", model.id, null, model)
      .then(() => null);
  }

  // public async delete(id: number): Promise<void> {
  //   return await this.client.deleteById("demographicdata", id)
  //     .then(() => null);
  // }
}
