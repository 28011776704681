import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { PersonResume } from "models/test-person/personResume";

@autoinject()
export class PersonResumeService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("PersonResumeService");
  }

  // public async list ( parameters: UxDatatableParameters, personId : number ): Promise<UxDatatableResponse> {
  //   return await this.client.post( `PersonResume/list/${personId}`, parameters )
  //     .then( ( response: any ) => {
  //       return <UxDatatableResponse> response;
  //     } );
  // }

  // public async getAll ( personId : number ): Promise<ActivationQuestionary[]> {
  //   return await this.client.get( `PersonResume/${personId}` )
  //     .then( ( response: any ) => {
  //       return <ActivationQuestionary[]> response;
  //     } );
  // }

  public async getById(personId: number): Promise<PersonResume> {
    return await this.client.getById(`personresume/`, personId)
      .then((response: PersonResume) => {
        return response;
      });
  }

  public async create(model: PersonResume, personId: number): Promise<PersonResume> {
    return await this.client.create(`personresume/${personId}`, model)
      .then((response: any) => {
        return <PersonResume>response;
      });
  }

  public async update(model: PersonResume, personId: number): Promise<void> {
    return await this.client.updateById(`personresume`, model.id, null, model)
      .then(() => null);
  }

}
