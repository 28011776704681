import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { HabeasDataCountry } from "models/habeas-data-by-country/habeas-data-country";

@autoinject()
export class HabeasDataCountryService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("HabeasDataCountryService");
  }

  public async getAll(): Promise<HabeasDataCountry[]> {
    return await this.client.get("habeasdatacountry")
      .then((response: any) => {
        return <HabeasDataCountry[]>response;
      });
  }

  public async getByCountryAndLanguage(model: HabeasDataCountry): Promise<HabeasDataCountry> {
    return await this.client.post(`habeasdatacountry/country-and-language`, model)
      .then((response: any) => {
        return <HabeasDataCountry>response;
      });
  }

  public async getById(id: number): Promise<HabeasDataCountry> {
    return await this.client.get(`habeasdatacountry/${id}`)
      .then((response: any) => {
        return <HabeasDataCountry>response;
      });
  }

  public async updateOrCreate(model: HabeasDataCountry): Promise<HabeasDataCountry> {
    return await this.client.updateById('habeasdatacountry', model.id, null, model)
      .then(response => {
        return <HabeasDataCountry>response;
      });
  }
}
