import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { Language } from "models/localizations/language";

@autoinject()
export class CoreLanguageService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CoreLanguageService");
  }

  public async getAll(): Promise<Language[]> {
    return await this.client.get("corelanguage")
      .then((response: any) => {
        return <Language[]>response;
      });
  }

}
