import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig, RoutableComponentDetermineActivationStrategy, activationStrategy } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { CoreUserService } from "../../services/users/core-user-service";
import { CoreCompanyService } from "../../services/companies/core-company-service";
import { User } from "models/security/user";
import { CoreGroupService } from "../../services/groups/core-group-service";
import { Group } from "models/groups/group";
import { Company } from "models/companies/company";
import { SessionService } from "common/services/session-service";
import { UserState } from "models/security/userState";
import { Role } from "models/security/role";
import { State } from "common/store/store-model";

@autoinject()
// @connectTo()
export class CoreUserEdit implements RoutableComponentDetermineActivationStrategy {

  private logger: Logger;
  private model: User;
  private validationController: ValidationController;
  private coreGroups: Group[];

  public state: State;

  private masterFranchises: Company[];
  private franchises: Company[];
  // private selectedMasterFranchiseId: number = null;
  // private selectedFranchiseId: number = null;

  // private disableAdminRole: boolean = false;
  // private disableCoreRole: boolean = false;
  // private disableMasterFranchiseRole: boolean = false;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private coreUserService: CoreUserService,
    private coreGroupService: CoreGroupService,
    private coreCompanyService: CoreCompanyService,
    private i18n: I18N,
    private sessionService: SessionService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CoreUserEdit");

    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<void> {
    // this.logger.debug("Activated id:", params.coreUserId);
    try {
      this.masterFranchises = await this.coreCompanyService.getByRoleId(Role.MasterFranchise);
      this.franchises = await this.coreCompanyService.getByRoleId(Role.Franchise);
      this.coreGroups = await this.coreGroupService.getAll();

      // if (this.state && this.state.userBasicInfo) {
      //   this.initHieranchyLevelByCurrentUserRole(this.state.userBasicInfo.roleId);
      // }

      if (!isNaN(params.coreUserId)) {
        this.model = await this.coreUserService.getById(+params.coreUserId);
        // this.initHieranchyLevelCompanyIdByRole();        
      } else {
        this.model = new User();
        this.model.userState = UserState.Pending;
      }
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }

  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("firstName").displayName("main.admin.pages.users.first_name").required()
      .ensure("lastName").displayName("main.admin.pages.users.last_name").required()
      .ensure("email").displayName("main.admin.pages.users.email").email().required()
      .ensure("groupId").displayName("main.admin.pages.users.group_id").required()
      .ensure("roleId").displayName("main.admin.pages.users.role_id").required()
      .ensure("companyId").displayName("main.admin.pages.users.company_id").required()
      .on(this.model);
  }

  private async submit(saveAndContinue: boolean): Promise<any> {
    let result = await this.validationController.validate();
    if (result.valid) {
      if (!this.model.roleId) {
        this.notification.warning(this.i18n.tr("main.admin.pages.users.errors.role_is_required"));
      }
      else {
        return await this.save(saveAndContinue);
      }
    }

  }

  get canSave() {
    return !this.coreGroupService.client.isRequesting;
  }

  private async save(saveAndContinue: boolean): Promise<any> {
    if (!this.model.id) {
      return await this.coreUserService.create(this.model)
        .then(async (model: User) => {
          this.notification.success("notifications.item_added");
          if (!saveAndContinue) {
            return await this.router.navigateToRoute("core-users");
          } else {
            return await this.router.navigateToRoute("core-user-child-router", { coreUserId: model.id });
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.coreUserService.update(this.model)
        .then(async () => {
          this.notification.success("notifications.item_edited");
          if (!saveAndContinue) {
            return await this.router.navigateToRoute("core-users");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  // private setCompanyIdByRole(): void {
  //   switch (this.model.roleId) {
  //     case 1:
  //     case 2:
  //       this.model.companyId = null;//THT_CORE_COMPANY;
  //       break;
  //     case 3:
  //       this.model.companyId = this.selectedMasterFranchiseId;
  //       break;
  //     case 4:
  //       this.model.companyId = this.selectedFranchiseId;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // private initHieranchyLevelCompanyIdByRole(): void {
  //   switch (this.model.roleId) {
  //     case 1:
  //     case 2:
  //       break;
  //     case 3:
  //       this.selectedMasterFranchiseId = this.model.companyId;
  //       break;
  //     case 4:
  //       this.selectedFranchiseId = this.model.companyId;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // private initHieranchyLevelByCurrentUserRole(currentUserRole: number): void {
  //   switch (currentUserRole) {
  //     case 1:
  //       break;
  //     case 2:
  //       this.disableAdminRole = true;
  //       break;
  //     case 3:
  //       this.disableAdminRole = this.disableCoreRole = true;
  //       break;
  //     case 4:
  //       this.disableAdminRole = this.disableCoreRole = this.disableMasterFranchiseRole = true;
  //       break;
  //     default:
  //       break;
  //   }
  //   // this.logger.debug("disableCoreRole", this.disableCoreRole);
  // }

  public determineActivationStrategy(): any {
    return activationStrategy.replace;
  }
}
