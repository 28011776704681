import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
import { autoinject, bindable, LogManager, Disposable } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router, NavigationInstruction } from 'aurelia-router';
import { Company } from 'models/companies/company';
import { Role } from 'models/security/role';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { CompaniesLoadedMessage } from 'messages/companiesLoadedMessage';
import { Wallet } from 'models/talents/wallet';
import { Notification } from 'common/services/notification';
import { SessionService } from 'common/services/session-service';
import { AgreementService } from 'main/admin/services/agreements/agreement-service';
import { WalletService } from 'main/admin/services/talents/wallet-service';

@autoinject()
export class ViewWallet {

  private logger: Logger;

  @bindable()
  public state: State;
  public roleId: number;
  public userId: number;
  public wallet: Wallet;
  public planIsViewWallet: boolean[];
  public isViewWallet: boolean = false;
  public generalWallet: Wallet;

  private rootCompany: Company;

  private subscriptions: Disposable[] = [];

  constructor(
    private store: StoreService,
    private sessionService: SessionService,
    private walletService: WalletService,
    private agreementService: AgreementService,
    private router: Router,
    private ea: EventAggregator,
    private notification: Notification,
    private coreCompanyService: CoreCompanyService) {

    this.logger = LogManager.getLogger('CompanySelector');

    // event when router navigation is success
    this.subscriptions.push(this.ea.subscribe('router:navigation:success', this.refresh.bind(this)));

    this.subscriptions.push(this.ea.subscribe(CompaniesLoadedMessage, this.init.bind(this)));
  }

  public async attached(): Promise<any> {
  }

  public unbind() {
    this.subscriptions.forEach(x => x.dispose());
  }

  private async init() {
    await this.setRootCompany();
    await this.refresh();
  }

  private async refresh() {
    let currentCompanyId: number;
    let instruction: NavigationInstruction = this.router.currentInstruction;

    if (instruction.params && instruction.params.companyId) {
      currentCompanyId = +instruction.params.companyId;
    }

    if (this.state.currentCompany && this.state.currentCompany.id == currentCompanyId) {
      return;
    }
    await this.loadUserProfile();
    if(this.state){
    this.roleId = this.state.userBasicInfo.roleId;
    this.userId = this.state.userBasicInfo.id;
    }
    if(currentCompanyId && (this.roleId == Role.Customer || this.roleId == Role.Master))
    {
      this.planIsViewWallet = (await this.agreementService.getAgreementsByCompanyId(currentCompanyId,false)).map(x=> x.isViewWallet);
      this.isViewWallet = this.planIsViewWallet.find(element => element == true);

      if(this.isViewWallet)
      {
        await this.walletByUserId(currentCompanyId,this.roleId,this.userId);
        setInterval(async () => await this.walletByUserId(currentCompanyId,this.roleId,this.userId), 300000);
      }
    }
    return await this.loadCurrentCompany(currentCompanyId);
  }

  private setRootCompany() {
    if (this.state && this.state.userBasicInfo && this.state.companies.length > 0) {
      this.rootCompany = this.state.companies.find(x => x.id === this.state.userBasicInfo.companyId);
    }
  }

  private async loadUserProfile(): Promise<any> {
    try {
      const profile = await this.sessionService.getProfile();
      if(profile)
      await this.store.setUserBasicInfoState(profile.basic_info);
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async loadCurrentCompany(currentCompanyId: number): Promise<any> {

    if (currentCompanyId && this.state.companies.length > 0) {

      let selectedCompany: Company;

      if (currentCompanyId && this.state.companies.map(x => x.id.toString()).indexOf(currentCompanyId.toString()) < 0) {

        this.logger.debug("companyId does not belong to user");
        // this.switchCompany(this.rootCompany);
        selectedCompany = this.state.companies.filter(x => x.roleId == Role.Customer)[0];

      } else {
        // this.logger.debug("switchCompany to selected by url:", currentCompanyId);
        selectedCompany = this.state.companies.filter(x => x.id == currentCompanyId)[0];
      }

      // validate if selectedCompany is Customer level. User can only select a company with customer role.
      if (selectedCompany.roleId != Role.Customer || !selectedCompany.isActive) {
        selectedCompany = this.state.companies.filter(x => x.roleId == Role.Customer)[0];
      }

      if (selectedCompany) {
        return await this.switchCompany(selectedCompany);
      }
    } else if (!currentCompanyId && this.state.currentCompany) {
      return await this.switchCompany(null);
    }

  }

  private async switchCompany(company: Company): Promise<any> {

    if (!company) {
      return await this.store.setCurrentCompanyState(null);
    }

    if (this.state.currentCompany && this.state.currentCompany.id == company.id) {
      return;
    }

    let settings = await this.coreCompanyService.getSettingsById(company.id);

    company.companySettings = settings;

    await this.store.setCurrentCompanyState(company);

    //update router url
    let fragment = this.router.currentInstruction.fragment;

    if (fragment.length > 1 && fragment.startsWith("/company")) {
      let fragmentParts: string[] = fragment.split("/");
      fragmentParts.splice(2, 1, this.state.currentCompany.id.toString());
      let newFragment: string = fragmentParts.join("/");

      if (fragment !== newFragment) {
        this.router.navigate(newFragment);
      }

    } else {
      return await this.router.navigateToRoute("main-child-router", { companyId: this.state.currentCompany.id });
    }

  }
  
  public async walletByUserId(companyId: number, roleId: number, userId: number) {
    try {
      if(companyId != null && roleId != null && roleId == Role.Customer){
      this.wallet = await this.walletService.getUserWallet(userId,companyId);
      this.generalWallet = await this.walletService.getGeneralWallet(companyId);
      }
      else if(companyId != null && roleId != null && roleId == Role.Master){
      this.generalWallet = await this.walletService.getGeneralWallet(companyId);
      this.wallet = this.generalWallet;
      }
    } catch (error) {
      this.notification.error(error);
    }
  }
}
