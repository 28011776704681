
import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";

import { Notification } from "common/services/notification";

import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { User } from "models/security/user";

import { Company } from "models/companies/company";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { PositionService } from 'main/test/services/position/position-service';



@autoinject()
export class PositionReferenceList {

  private logger: Logger;
  private params: any = {};
  private users: User[];
  public companyId: number;
  private company: Company;


  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private router: Router,    
    private positionService: PositionService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("TalentConsolidatedList");

    this.state = this.store.state;

  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.positionService.listPositionReference(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
   
    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.positionService.client.currentCompanyId = this.companyId;
        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  // private async getMore(scrollContext: any): Promise<void> {
  //   return await this.uxDatatable.getMoreItems(scrollContext);
  // }

}
