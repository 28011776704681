import { ViewEngineHooks, View } from 'aurelia-framework';
import { SpendMode } from './spendMode';

export class SpendModeViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['SpendMode'] = SpendMode;

    view.overrideContext['SpendModes'] =
      Object.keys(SpendMode)
        .filter((key) => typeof SpendMode[key] === 'number');
  }
}
