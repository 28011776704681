import { EducationalLevelCountryModal } from './educational-level-country-modal';
import { EducationalLevelCountry } from 'models/educational-levels-by-country/educational-level-country';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { EducationalLevelCountryService } from '../../services/educational-levels-by-country/educational-level-country-service';
import { CatalogService } from "../../../services/catalogs/catalog-service";
import { CatalogBase } from "models/catalogs/catalog-base";
import { ChangeStateConfirmation } from '../../../components/change-state-confirmation';

@autoinject()
export class EducationallevelCountryList {

  private logger: Logger;

  private countries: CatalogBase[] = [];

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private catalogService: CatalogService,
    private educationalLevelCountryService: EducationalLevelCountryService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("EducationallevelCountryList");

    this.parameters.search = {};

  }

  private async activate(params: any): Promise<void> {
    try {
      this.countries = await this.catalogService.getCountryAll();
      if (this.countries.length > 0) {
        this.parameters.search.CountryId = this.countries[0].id;
        return await this.databind();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        // this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    return this.educationalLevelCountryService.list(this.parameters);
  }

  private async edit(item: EducationalLevelCountry): Promise<void> {
    let orginialItem = JSON.parse(JSON.stringify(item));
    return await this.dialogService.open({
      viewModel: EducationalLevelCountryModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.educationalLevelCountryService.update(item)
          .then((model: EducationalLevelCountry) => {

            this.logger.debug("model.id", model.id);
            item.id = model.id;

            this.notification.success("main.admin.pages.educational_levels_country.notifications.edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        Object.assign(item, orginialItem);
      }
    });
  }

  public async changeState(item: EducationalLevelCountry): Promise<void> {
    // this.logger.debug("item.isActive", item.isActive);
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.educationalLevelCountryService.changeState(item)
          .then((model: EducationalLevelCountry) => {
            // item.isActive = !item.isActive;
            if (item.id === 0) {
              item.id = model.id;
            }

            this.notification.success("main.admin.pages.educational_levels_country.notifications.activation_changed");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        item.isActive = !item.isActive;
      }
    });
  }


}
