import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Group } from "models/groups/group";
import { CoreGroupService } from "../../services/groups/core-group-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

import { Notification } from "common/services/notification";

@autoinject()
export class CoreGroupEdit {

  private logger: Logger;
  private model: Group = new Group();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;

  constructor(
    private router: Router,
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private coreGroupService: CoreGroupService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CoreGroupEdit");

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    if (!isNaN(params.coreGroupId)) {
      try {
        this.model = await this.coreGroupService.getById(+params.coreGroupId);
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.core_groups.name").required()
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.coreGroupService.client.isRequesting;
  }

  public async save(): Promise<any> {
    return await this.coreGroupService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!this.saveAndContinue) {
          return await this.router.navigateToRoute("core-groups");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

}
