import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UserApiApplication } from "models/security/userApiApplication";

@autoinject
export class UserApiApplicationService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("UserApiApplicationService");
  }

  public async getById(id: number): Promise<UserApiApplication> {
    return await this.client.getById("userapiapplication", id)
      .then((response: UserApiApplication) => {
        return response;
      });
  }

  public async generate(id: number): Promise<UserApiApplication> {
    return await this.client.post(`userapiapplication/generate/${id}`)
      .then((response: UserApiApplication) => {
        return response;
      });
  }

}
