import { Notification } from 'common/services/notification';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { Role } from "models/security/role";
import { Company } from 'models/companies/company';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
import { Group } from 'models/groups/group';
import { GroupService } from 'main/admin/services/groups/group-service';
import { User } from 'models/security/user';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { CompanyChange } from 'models/companies/company-change';

@autoinject()
export class UserChangeCompanyModal {

  private validationController: ValidationController;
  private logger: Logger;
  private newModelUser: User;
  private model: CompanyChange;
  private masterFranchises: Company[];
  private franchises: Company[];
  private masterCompanies: Company[];
  private companies: Company[];
  private groups: Group[];
  private response: Company[];

  constructor(
    private coreCompanyService: CoreCompanyService,
    private groupService: GroupService,
    private notification: Notification,
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("UserChangeCompanyModal");

  }

  public async activate(item: User): Promise<any> {
    this.logger.debug("activate", item);
    this.newModelUser = item;
    this.model = new CompanyChange();
    try {
      this.response = await this.coreCompanyService.getAll();

      this.masterFranchises = this.response.filter(x => x.roleId == Role.MasterFranchise);

      this.masterFranchises.sort((a, b) => a.name.localeCompare(b.name));
      this.configureValidationRules();
    } catch (error) {
      this.notification.error(error);
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("masterFranchiseId").displayName("main.admin.pages.users.master_franchise").required()
      .ensure("franchiseId").displayName("main.admin.pages.users.franchise").required()
      .ensure("masterCompanyId").displayName("main.admin.pages.users.parent_company").required()
      .ensure("companyId").displayName("main.admin.pages.users.company").required()
      .ensure("groupId").displayName("main.admin.pages.users.group_users").required()
      .on(this.model);
  }

  public async franchiseList() {
    this.franchises = this.response.filter(x => x.parentId == this.model.masterFranchiseId);
    this.franchises.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async masterCompanyList() {
    this.masterCompanies = this.response.filter(x => x.roleId == Role.Master && x.parentId == this.model.franchiseId);
    this.masterCompanies.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async companyList() {
    this.companies = this.response.filter(x => x.roleId == Role.Customer && x.parentId == this.model.masterCompanyId);
    this.companies.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async groupList() {
    this.groups = await this.groupService.getByCompanyId(this.model.companyId);
    this.groups.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          this.newModelUser.groupId = this.model.groupId;
          this.newModelUser.companyId = this.model.companyId;
          let companyName = this.companies.find(x => x.id == this.model.companyId);
          this.newModelUser.lastName = companyName.businessName;
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();
  }
}
