import { ViewEngineHooks, View } from 'aurelia-framework';
import { PasswordChangingPeriod } from './passwordChangingPeriod';

export class PasswordChangingPeriodViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['PasswordChangingPeriod'] = PasswordChangingPeriod;

    view.overrideContext['PasswordChangingPeriods'] = 
      Object.keys(PasswordChangingPeriod)
        .filter((key) => typeof PasswordChangingPeriod[key] === 'number');
  }
}
