import { ViewEngineHooks, View } from 'aurelia-framework';
import { Role } from './role';

export class RoleViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {
    view.overrideContext['Role'] = Role;
    view.overrideContext['Roles'] = 
      Object.keys(Role)
        .filter((key) => typeof Role[key] === 'number');
  }
}
