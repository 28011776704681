import { PositionCompetence } from 'models/test-position/positionCompetence';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { PositionService } from '../../services/position/position-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { PositionCompetenceService } from "../../services/position/position-competence-service";
import { Position } from "models/test-position/position";
import { PositionCompetenceEditModal } from './position-competence-edit-modal';
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { Tests } from 'models/test-person/tests';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
import { QuestionaryService } from 'main/admin/services/questionaries/questionary-service';
import { Questionary } from 'models/questionaries/questionary';
@autoinject()
export class PositionCompetenceList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;

  private positionId: number;
  private model: Position = new Position();

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private questionaryId: number;
  private competenceQuestionary: Questionary;
  private isEditable: boolean;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.positionCompetenceService.list(this.parameters, this.positionId, this.questionaryId);    
  }

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private positionService: PositionService,
    private positionCompetenceService: PositionCompetenceService,
    private notification: Notification,
    private questionaryService: QuestionaryService,
    private coreCompanyService: CoreCompanyService
  ) {

    this.logger = LogManager.getLogger("PositionCompetenceList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      this.positionCompetenceService.client.currentCompanyId = this.companyId;

      if (!isNaN(this.params.positionId)) {
        this.positionId = this.params.positionId;

        this.positionId = this.positionId;
        this.model = await this.getItem(this.positionId);

        let settings = await this.coreCompanyService.getSettingsById(this.companyId);
        this.questionaryId = settings.competencesQuestionaryId;
        
        if (this.questionaryId == 0)
          this.questionaryId = Tests['Competences'];

        this.competenceQuestionary  = await this.questionaryService.getById(this.questionaryId);
   
        return await this.databind();
      }
    }
  }

  private async getItem(itemId: number): Promise<Position> {
    return await this.positionService.getById(itemId);
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;        
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async create(): Promise<void> {
    // this.logger.info("createCompetence");

    return await this.dialogService.open({
      viewModel: PositionCompetenceEditModal,
      model: { id: null, companyId: this.companyId, positionId: this.positionId }
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        this.databind();
      }
    });

  }

  public async edit(item: PositionCompetence): Promise<void> {
    // this.logger.info("createCompetence");

    return await this.dialogService.open({
      viewModel: PositionCompetenceEditModal,
      model: { id: item.id, companyId: this.companyId }
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        // this.notification.success("notifications.item_edited");
        this.databind();

      }
    });

  }

  private async delete(item: PositionCompetence): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.complementaryName
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.positionCompetenceService.delete(item.id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.notification.success("notifications.item_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });

      }
    });
  }

}
