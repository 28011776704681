import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PersonResumeService } from "main/test/services/people/people-resume-service";
import { PersonResume } from "models/test-person/personResume";
import { PersonService } from "main/test/services/people/person-service";
import { Person } from "models/test-person/person";

@autoinject()
export class PeopleResumeEdit {

  private logger: Logger;
  public companyId: number;

  private personId: number;
  private person: Person;

  private model: PersonResume = new PersonResume();

  private validationController: ValidationController;

  constructor(
    private router: Router,
    private personResumeService: PersonResumeService,
    private personService: PersonService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PeopleResumeEdit");
  }

  public configureValidationRules(): void {
    ValidationRules
      .ensure("positions").displayName("main.test.pages.people.people_resume_edit.experience").maxLength(5000)
      .ensure("educations").displayName("main.test.pages.people.people_resume_edit.education").maxLength(5000)
      .ensure("skills").displayName("main.test.pages.people.people_resume_edit.skills").maxLength(5000)
      .ensure("honors").displayName("main.test.pages.people.people_resume_edit.achievement").maxLength(5000)
      .ensure("languages").displayName("main.test.pages.people.people_resume_edit.language").maxLength(5000)
      .ensure("observations").displayName("main.test.pages.people.people_resume_edit.observations").maxLength(5000)
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {

    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      this.personResumeService.client.currentCompanyId = this.companyId;
      this.personService.client.currentCompanyId = this.companyId;

      if (!isNaN(params.personId)) {
        this.personId = params.personId;
        this.model = await this.getItem();
        this.person = await this.getPerson();
      }

      this.configureValidationRules();
    }
  }

  private async getItem(): Promise<PersonResume> {
    return await this.personResumeService.getById(this.personId);
  }

  private async getPerson(): Promise<Person> {
    return this.personService.getById(this.personId);
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    this.model.personId = this.personId;

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.model.id) {
            return await this.update(keepEditing);
          } else {
            return await this.create(keepEditing);
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.personResumeService.client.isRequesting;
  }

  private async create(keepEditing: boolean): Promise<any> {
    try {
      this.model = await this.personResumeService.create(this.model, this.personId);
      this.notification.success("notifications.item_edited");
      if (!keepEditing) {
        return await this.router.navigateToRoute("people");
      }
    }
    catch (error) {
      this.notification.error(error);
    };
  }

  private async update(keepEditing: boolean): Promise<any> {
    try {

      await this.personResumeService.update(this.model, this.personId);
      this.notification.success("notifications.item_edited");

      if (!keepEditing) {
        return await this.router.navigateToRoute("people");
      }
    }
    catch (error) {
      this.notification.error(error);
    };
  }
}
