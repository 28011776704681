import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { PositionCompetence } from "models/test-position/positionCompetence";
import { CompanyCompetence } from "../../../../models/test-core/competences/company-competence";

@autoinject()
export class PositionCompetenceService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("PositionCompetenceService");
  }

  public async list(parameters: UxDatatableParameters, positionId: number, questionaryId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`positioncompetence/list/${positionId}/${questionaryId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  // public async getAll(positionId: number): Promise<PositionCompetence[]> {
  //   return await this.client.get(`positioncompetence/${positionId}`)
  //     .then((response: any) => {
  //       return <PositionCompetence[]>response;
  //     });
  // }

  public async getById(id: number): Promise<PositionCompetence> {
    return await this.client.getById(`positioncompetence`, id)
      .then((response: PositionCompetence) => {
        return response;
      });
  }

  public async create(model: PositionCompetence, positionId: number): Promise<PositionCompetence> {
    return await this.client.create(`positioncompetence/${positionId}`, model)
      .then((response: any) => {
        return <PositionCompetence>response;
      });
  }

  public async update(model: PositionCompetence): Promise<void> {
    return await this.client.updateById(`positioncompetence`, model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`positioncompetence`, id)
      .then(() => null);
  }

  public async getAllCompetences(): Promise<CompanyCompetence[]> {
    return await this.client.get("companycompetence")
      .then((response: any) => {
        return <CompanyCompetence[]>response;
      });
  }

}
