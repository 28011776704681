import { autoinject, observable, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "./app-http-client";
import { SessionService } from "./session-service";
import { Subscription, EventAggregator } from "aurelia-event-aggregator";
import config from 'config.js';
import { UserManager } from "oidc-client";

@autoinject()
export class CompanyAppHttpClient extends AppHttpClient {

  private companylogger: Logger;

  @observable() 
  public currentCompanyId: number;

  private companySwitcherSubscriber: Subscription;

  constructor(
    protected sessionService: SessionService,
    protected ea: EventAggregator) {

    super(sessionService, ea);

    this.companylogger = LogManager.getLogger("CompanyAppHttpClient");
   
    // this.companySwitcherSubscriber = this.ea.subscribe(CurrentCompanyChangeMessage, (message: CurrentCompanyChangeMessage) => {
    //   // this.companylogger.debug("event companySwitcher: ", message.currentCompanyId);
    //   this.currentCompanyId = message.currentCompanyId;
    // });
  }

  public detached() {
    this.companySwitcherSubscriber.dispose();
  }

  private currentCompanyIdChanged(newValue, oldValue) {
    // this.companylogger.debug("currentCompanyIdChanged:", newValue);
    if (newValue) {
      // this.currentCompanyId = newValue;
      //base api
      let backendUrl = config.URLS_BACKEND;
      this.baseUrl = `${backendUrl}/api/v1/company/${this.currentCompanyId}/`;
    }
  }
}
