import { autoinject, LogManager } from "aurelia-framework";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { PlanService } from "../../../services/plans/plan-service";
import { Plan } from "models/plans/plan";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { DeleteConfirmation } from "../../../../components/delete-confirmation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { OtherPlanItem } from "models/plans/other-plan-item";
import { OtherPlanItemModal } from "./other-plan-item-modal";
import { OtherPlanItemsService } from "main/admin/services/plans/other-plan-item-service";

@autoinject()
export class OtherPlanItemList {

  private planId: number;
  private plan: Plan = new Plan();
  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private dialogService: DialogService,
    private planService: PlanService,
    private otherTestModuleService: OtherPlanItemsService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("OtherPlanItemList");
  }

  private async activate(params: any): Promise<void> {
    if (!isNaN(params.planId)) {
      this.planId = +params.planId;
      return await this.databind();
    }
  }

  private async bind(): Promise<any> {
  }

  public async databind(): Promise<void> {
    // this.logger.info("databind");
    if (this.planId) {
      try {
        this.plan = await this.planService.getById(this.planId);
        if (this.plan.isTestItemsActive) {
          let response = await this.otherTestModuleService.list(this.planId, this.parameters);
          this.parameters.tableData = response.data;
          this.parameters.totalRecords = response.totalRecords;
        }
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private refresh = (parameters: UxDatatableParameters): Promise<void | UxDatatableResponse> => {
    this.logger.info("refreshing");
    return this.otherTestModuleService.list(this.planId, parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        return this.notification.error(error);
      });
  }

  private async create(): Promise<void> {
    let item: OtherPlanItem = new OtherPlanItem();
    item.planId = this.plan.id;
    return await this.dialogService.open({
      viewModel: OtherPlanItemModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.otherTestModuleService.create(item)
          .then(async (model: OtherPlanItem) => {
            item.id = model.id;
            this.parameters.tableData.unshift(item);
            this.parameters.totalRecords++;
            this.notification.success("notifications.item_added");
            // return await this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async edit(item: OtherPlanItem): Promise<void> {
    return await this.dialogService.open({
      viewModel: OtherPlanItemModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled && response.output) {
        return await this.otherTestModuleService.update(item)
          .then(async () => {
            // var name = this.model.name;
            this.notification.success("notifications.item_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    })
      .whenClosed((response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          this.otherTestModuleService.delete(id)
            .then(() => {
              let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
              this.parameters.tableData.splice(indexOfEditedModel, 1);
              this.parameters.totalRecords--;
              this.notification.success("notifications.item_deleted");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      });
  }

  private async changeOtherItemsState(): Promise<void> {
    return await this.planService.changeOtherItemsState(this.plan.id)
      .then(() => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
}
