export class PasswordPolicy{
  id: number;
  requiredLength: number;
  requireNonAlphanumeric: boolean ;
  requireDigit: boolean ;
  requireLowercase: boolean ;
  requireUppercase: boolean ;
  validateLastFivePasswords: boolean ;
  requirePasswordChanging: boolean ;
  passwordChangingPeriod: number ;
  lockoutEnabled: boolean ;
  lockoutMaxFailedAccessAttempts: number ;
  companyId: number ;    
}
