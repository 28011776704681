import { autoinject, LogManager, bindable, observable } from "aurelia-framework";
import { Router, NavigationInstruction } from "aurelia-router";
import { Logger } from "aurelia-logging";
import { CoreCompanyService } from "../../services/companies/core-company-service";
import { Company } from "models/companies/company";
import { Role } from "models/security/role";
import { Notification } from "common/services/notification";
@autoinject()
export class FranchiseSelector {

  @bindable
  private routeName: string;

  @bindable
  private franchiseId: number;

  @bindable
  private isRobotHunter: boolean;

  private logger: Logger;
  private companies: Company[] = [];
  private masterFranchises: Company[] = [];

  private masterFranchiseId: number;

  constructor(
    private coreCompanyService: CoreCompanyService,
    private router: Router,
    private notification: Notification) {

    this.logger = LogManager.getLogger("FranchiseSelector");
  }

  public async attached(): Promise<any> {
    // this.logger.debug("attached", this.franchiseId);
    return await this.databind();
  }

  public async databind(): Promise<any> {
    // this.logger.debug("databind");
    try {
      let response = await this.coreCompanyService.getAll();
      this.companies = response.filter(x => x.roleId == Role.Franchise);
      this.masterFranchises = response.filter(x => x.roleId == Role.MasterFranchise);
      // this.logger.debug("setMasterFranchiseId: ", this.masterFranchises);
      this.setMasterFranchiseId();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private setMasterFranchiseId() {
    if (this.franchiseId) {
      let franchise = this.companies.find(x => x.id == this.franchiseId);
      if (franchise) {
        this.masterFranchiseId = franchise.parentId;
        this.logger.debug("setMasterFranchiseId: ", this.masterFranchiseId);
      }
    }
  }

  public async select(franchiseId: number): Promise<any> {    
    return await this.router.navigateToRoute(this.routeName, { franchiseId: franchiseId , isRobotHunter: this.isRobotHunter });
  }

}
