
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { TalentPlanItemsModal } from "./talent-test-plan-items-modal";


@autoinject()
export class TalentPlansCard {

  private logger: Logger;
  public companyId: number;

  constructor(
    private dialogService: DialogService) {

    this.logger = LogManager.getLogger("TalentPlansCard");
  }

  private async bind(params: any): Promise<any> {
    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;
    }
  }

  private async viewTestPlanItems(): Promise<void> {
    this.logger.info("PlanDetailsPopup");

    return await this.dialogService.open({
      viewModel: TalentPlanItemsModal,
    }).whenClosed(async (response: DialogCloseResult) => null);
  }

}
