import { Router } from "aurelia-router";
import { bindable } from "aurelia-framework";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { QuestionService } from "../../../services/questionaries/question-service";
import { QuestionType } from "models/questions/questionType";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse} from "common/resources/elements/ux/datatable/ux-datatable";
import { DeleteConfirmation } from "../../../../components/delete-confirmation";
import { Language } from "models/localizations/language";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { QuestionaryService } from "main/admin/services/questionaries/questionary-service";
import { Questionary } from "models/questionaries/questionary";
import { SearchParams } from "models/questionaries/searchParams";

@autoinject()
export class QuestionList {

  @bindable public questionaryId: number;

  private logger: Logger;
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private languages: Language[] = [];
  private selectedLanguageId: number;
  private questionary: Questionary = new Questionary();

  constructor(
    private dialogService: DialogService,
    private questionService: QuestionService,
    private questionaryService: QuestionaryService,
    public languageService: CoreLanguageService,
    private notification: Notification,
    public questionType: QuestionType) {

    this.logger = LogManager.getLogger("QuestionList");

  }

  private async activate(params: any): Promise<void> {
    this.logger.debug("activated this.questionaryId", params  .questionaryId);
    if (!isNaN(params.questionaryId)) {
      this.questionaryId = +params.questionaryId;
      this.questionary = await this.questionaryService.getById(this.questionaryId);
      this.selectedLanguageId = this.questionary.languageId;
      this.languages = await this.languageService.getAll();
      this.selectLanguage();
    }
  }

  private async bind(): Promise<any> {
  }

  /*public async attached(): Promise<void> {
    this.logger.info("attached questionaryId", this.questionaryId);
    if (this.questionaryId) {
      return await this.databind();
    }
  }*/

  public async selectLanguage() {
    if (this.selectedLanguageId) {
      const language = this.languages.find(x => x.id === this.selectedLanguageId);
      this.parameters.search = <SearchParams>{ LanguageId: language.id };
      await this.databind();
      // Resto de tu lógica
    }
  }

  public async databind(): Promise<void> {
    this.languages = await this.languageService.getAll();
    this.logger.info("databind");
    return await this.questionService.list(this.questionaryId, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.totalRecords = response.totalRecords;
        this.parameters.tableData = response.data;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    return this.questionService.list(this.questionaryId, this.parameters);
  }

  private async delete(id: number, name: string, isOriginal: boolean, languageId: number): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled && isOriginal) {
        return await this.questionService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.questionaries.questions.question_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }else if (!response.wasCancelled && !isOriginal){
        return await this.questionService.deleteLocalization(id,languageId)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.questionaries.questions.question_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

}
