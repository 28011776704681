import { SecondPassword } from "models/second-password/second-password";
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { ResetSecondPassword } from "models/second-password/reset-second-password";

@autoinject()
export class SecondPasswordService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("SecondPasswordService");
  }

  public async create(model: ResetSecondPassword): Promise<boolean> {
    return await this.client.post("secondpassword", model)
      .then((response: boolean) => {
        return response;
      });
  }

  public async validate(model: SecondPassword): Promise<boolean> {
    return await this.client.post("secondpassword/validate", model)
      .then((response: boolean) => {
        return response;
      });
  }
}
