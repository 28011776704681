import { Agreement } from './agreement';

export class AgreementCustomerReq {
  id:number;
  agreementId: number;
  agreement: Agreement = new Agreement();
  purchaseOrder: string;
  billingClosingDate: Date = new Date();
  nameToInvoice: string;
  hasSupplierRegistration: boolean
  isRequiresContract: boolean
  documentsToRegisterAsProvider: string;

  businessCompanyId: number;
  businessCompanyName: string;
  
  bankInformationId: number;
  bankInformation: string;
}
