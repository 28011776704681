import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { autoinject, LogManager, bindable, Disposable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { ActivationService } from 'main/test/services/people/activation-service';
import { Activation } from 'models/test-person/activation';
import { PersonActivationType } from 'models/test-person/personActivationType';
import { Parameter } from 'models/test-reports/reports/parameter';
import { Report } from "models/test-reports/reports/report";
import moment from 'moment';

interface ItemAvailable {
  [id: number]: boolean;
}

@autoinject()
export class SelectObservationList {

  private logger: Logger;
  private params: any = {};

  @bindable
  private report: Report;

  @bindable
  private parameterCurrent: Parameter;

  @bindable
  private selectedAmount: number;

  private selectedCurrent: number;
  private banCheck: boolean;

  private selectionLabel: string;
  private items: any[] = [];
  private banIds: number[] = [];
  private itemAvailable: ItemAvailable = {};

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;


  constructor(
    private activationService: ActivationService,
    private notification: Notification
  ) {
    this.logger = LogManager.getLogger("SelectObservationList");
    this.selectedCurrent = 0;
    this.banCheck = false;

    this.parameters.pageSize = 30;
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.activationService.listObserved(this.parameters, this.report.personId)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async attached(): Promise<any> {
    if (this.report.companyId) {
      this.activationService.client.currentCompanyId = this.report.companyId;

      // filter activation by Observed
      //this.parameters.search.PersonActivationType = PersonActivationType.Observed;

      return await this.databind();
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<any> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private getSelectionLabel(): void {
    this.selectionLabel = '';
    this.items.forEach((x, index) => {
      if (index > 0) {
        this.selectionLabel += ', ';
      }

      const formattedDate = moment(x.createdOn).format('D/MM/YYYY h:mm a');
      this.selectionLabel += formattedDate;
    });
  }

  public async selectParameter(item: Activation): Promise<void> {
    let banGeneral = this.banIds.findIndex(y => y === item.id);

    if (banGeneral == -1) {
      this.banIds.push(item.id);
      this.selectedCurrent = ++this.selectedCurrent;
      this.items.push(item);
    }
    else {
      this.banIds.splice(banGeneral, 1);
      this.selectedCurrent = --this.selectedCurrent;
      this.items.splice(banGeneral, 1);
    }

    this.checkAmount();


    switch (this.parameterCurrent) {
      case Parameter.Observers:
      case Parameter.TwoObservers: {
        if (banGeneral == -1) {
          this.report.observerIds.push(item.id);
        } else {
          this.report.observerIds.splice(banGeneral, 1);
        }
        break;
      }
      default: {
        break;
      }
    }

    this.getSelectionLabel();

  }

  private checkAmount() {
    if (this.selectedCurrent < +this.selectedAmount) {
      this.banCheck = false;
      this.parameters.tableData.forEach(x => {
        this.itemAvailable[x.id] = false;
      });

    } else {
      this.banCheck = true;
      this.parameters.tableData.forEach(x => {
        let ban = this.banIds.findIndex(y => y === x.id);
        if (ban === -1)
          this.itemAvailable[x.id] = true;
        else
          this.itemAvailable[x.id] = false;
      });
    }
  }

}
