
import { bindable, bindingMode, customElement, inject, LogManager } from 'aurelia-framework';
import { DOM, PLATFORM } from 'aurelia-pal';
import { Logger } from 'aurelia-logging';


export interface CarouselConfig {

  duration?: number;
  easing?: string;
  perPage?: number | any;
  startIndex?: number;
  draggable?: boolean;
  threshold?: number;
  loop?: boolean;
  onInit?: () => {},
  onChange?: () => {},
}

export interface CarouselDrag {
  startX?: number;
  endX?: number;
  startY?: number;
  letItGo?: boolean;
}

@customElement("ux-carousel")
@inject(Element)
export class Carousel {

  logger: Logger;

  @bindable
  showNav: boolean = true;

  @bindable
  heading: string = '';

  @bindable({ defaultBindingMode: bindingMode.oneTime })
  options: any;

  config = <CarouselConfig>{
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: false,
    onInit: () => { },
    onChange: () => { },
  };

  carouselContainer;
  sliderFrame;
  sliderWidth: number = 0;
  sliderTransform: number = 0;
  carouselWidth: number;
  innerElements;
  currentSlide;
  transformProperty;
  transformDuration: number = 0;
  viewPortWidth;
  resizeAnimRunning: boolean = false;
  slideAnimRunning: boolean = false;
  initialized: boolean = false;
  pointerDown: boolean = false;
  drag = <CarouselDrag>{
    startX: 0,
    endX: 0,
    startY: 0,
    letItGo: true
  };
  perPage;

  constructor(private element: HTMLElement) {

    this.logger = LogManager.getLogger("Carousel");
  }

  attached() {
    // Merge defaults with user's settings
    this.mergeSettings();

    // Cache some stuff
    this.transformDuration = this.config.duration;
    this.currentSlide = this.config.startIndex;
    this.transformProperty = this.webkitOrNot();
    this.viewPortWidth = PLATFORM.global.innerWidth; //window.innerWidth;
    this.carouselWidth = this.carouselContainer.offsetWidth;
    this.innerElements = this.sliderFrame.children;

    // this.logger.debug("viewPortWidth:", this.viewPortWidth);
    // this.logger.debug("carouselWidth:", this.carouselWidth);
    // Initialize the carousel
    this.init();
  }

  detached() {
    PLATFORM.global.removeEventListener('resize', this.resizeHandler);

    this.carouselContainer.removeEventListener('touchstart', this.touchstartHandler);
    this.carouselContainer.removeEventListener('touchend', this.touchendHandler);
    this.carouselContainer.removeEventListener('touchmove', this.touchmoveHandler);
    this.carouselContainer.removeEventListener('mousedown', this.mousedownHandler);
    this.carouselContainer.removeEventListener('mouseup', this.mouseupHandler);
    this.carouselContainer.removeEventListener('mouseleave', this.mouseleaveHandler);
    this.carouselContainer.removeEventListener('mousemove', this.mousemoveHandler);
  }

  /**
   * Overrides default settings with custom ones.
   * @param {Object} options - Optional settings object.
   * @returns {Object} - Custom Siema settings.
   */
  mergeSettings = () => {
    for (const attrname in this.options) {
      this.config[attrname] = this.options[attrname];
    }
  }


  /**
   * Determine if browser supports unprefixed transform property.
   * @returns {string} - Transform property supported by client.
   */
  webkitOrNot = () => {
    // const style = document.documentElement.style;
    const style = this.element.style;

    if (typeof style.transform === 'string') {
      return 'transform';
    }
    return 'WebkitTransform';
  }


  /**
   * Builds the markup and attaches listeners to required events.
   */
  init = () => {
    // Resize element on window resize
    PLATFORM.global.addEventListener('resize', this.resizeHandler);

    // If element is draggable / swipable, add event handlers
    if (this.config.draggable) {

      // Touch events
      this.carouselContainer.addEventListener('touchstart', this.touchstartHandler, { passive: true });
      this.carouselContainer.addEventListener('touchend', this.touchendHandler);
      this.carouselContainer.addEventListener('touchmove', this.touchmoveHandler, { passive: true });

      // Mouse events
      this.carouselContainer.addEventListener('mousedown', this.mousedownHandler);
      this.carouselContainer.addEventListener('mouseup', this.mouseupHandler);
      this.carouselContainer.addEventListener('mouseleave', this.mouseleaveHandler);
      this.carouselContainer.addEventListener('mousemove', this.mousemoveHandler);
    }

    if (this.carouselContainer === null) {
      throw new Error('Something went wrong with the carousel ref binding 😭');
    }

    // carousel container is collapse then set carousel width equal to view port
    if (this.carouselWidth === 0) {
      this.carouselWidth = this.viewPortWidth;
    }

    // update perPage number dependable of user value
    this.resolveSlidesNumber();

    // Apply styling and stuff
    this.refresh();

    this.config.onInit.call(this);
    this.initialized = true;

  }

  /**
   * Determinates slides number accordingly to clients viewPort.
   */
  resolveSlidesNumber = () => {
    if (typeof this.config.perPage === 'number') {
      this.perPage = this.config.perPage;
    }
    else if (typeof this.config.perPage === 'object') {
      this.perPage = 1;
      for (const viewPort in this.config.perPage) {
        if (this.viewPortWidth >= viewPort) {
          this.perPage = this.config.perPage[viewPort];
        }
      }
    }
  }


  /**
   * Go to previous slide.
   * @param {number} [howManySlides=1] - How many items to slide backward.
   * @param {function} callback - Optional callback function.
   */
  prev = (howManySlides = 1, callback = null) => {
    if (this.innerElements.length <= this.perPage) {
      return;
    }
    const beforeChange = this.currentSlide;
    if (this.currentSlide === 0 && this.config.loop) {
      this.currentSlide = this.innerElements.length - this.perPage;
    }
    else {
      this.currentSlide = Math.max(this.currentSlide - howManySlides, 0);
    }
    if (beforeChange !== this.currentSlide) {
      this.slideToCurrent();
      this.config.onChange.call(this);
      if (callback) {
        callback.call(this);
      }
    }
  }


  /**
   * Go to next slide.
   * @param {number} [howManySlides=1] - How many items to slide forward.
   * @param {function} callback - Optional callback function.
   */
  next = (howManySlides = 1, callback = null) => {
    if (this.innerElements.length <= this.perPage) {
      return;
    }
    const beforeChange = this.currentSlide;
    if (this.currentSlide === this.innerElements.length - this.perPage && this.config.loop) {
      this.currentSlide = 0;
    }
    else {
      this.currentSlide = Math.min(this.currentSlide + howManySlides, this.innerElements.length - this.perPage);
    }
    if (beforeChange !== this.currentSlide) {
      this.slideToCurrent();
      this.config.onChange.call(this);
      if (callback) {
        callback.call(this);
      }
    }
  }


  /**
   * Go to slide with particular index
   * @param {number} index - Item index to slide to.
   * @param {function} callback - Optional callback function.
   */
  goTo = (index, callback = null) => {
    if (this.innerElements.length <= this.perPage) {
      return;
    }
    const beforeChange = this.currentSlide;
    this.currentSlide = Math.min(Math.max(index, 0), this.innerElements.length - this.perPage);
    if (beforeChange !== this.currentSlide) {
      this.slideToCurrent();
      this.config.onChange.call(this);
      if (callback) {
        callback.call(this);
      }
    }
  }


  /**
   * Moves sliders frame to position of currently active slide
   */
  slideToCurrent = () => {
    this.sliderTransform = (this.currentSlide * (this.carouselWidth / this.perPage)) * -1;
  }


  /**
   * Recalculate drag /swipe event and reposition the frame of a slider
   */
  updateAfterDrag = () => {
    const movement = this.drag.endX - this.drag.startX;
    const movementDistance = Math.abs(movement);
    const howManySliderToSlide = Math.ceil(movementDistance / (this.carouselWidth / this.perPage));

    if (movement > 0 && movementDistance > this.config.threshold && this.innerElements.length > this.perPage) {
      this.prev(howManySliderToSlide);
    }
    else if (movement < 0 && movementDistance > this.config.threshold && this.innerElements.length > this.perPage) {
      this.next(howManySliderToSlide);
    }
    this.slideToCurrent();
  }


  /**
   * When window resizes, resize slider components as well
   */
  resizeHandler = () => {
    // Only resize width changes when a resize is not already running
    if (this.resizeAnimRunning || this.viewPortWidth === PLATFORM.global.innerWidth) {
      return;
    }

    this.resizeAnimRunning = true;
    PLATFORM.global.requestAnimationFrame(this.resizeWorker);



  }

  resizeWorker = () => {
    this.viewPortWidth = PLATFORM.global.innerWidth;
    // update perPage number dependable of user value
    this.resolveSlidesNumber();

    this.carouselWidth = this.carouselContainer.offsetWidth;
    this.sliderWidth = (this.carouselWidth / this.perPage) * this.innerElements.length;

    this.slideToCurrent();
    this.resizeAnimRunning = false;
  }


  /**
   * Clear drag after touchend and mouseup event
   */
  clearDrag = () => {
    this.drag = {
      startX: 0,
      endX: 0,
      startY: 0,
      letItGo: null
    };
  }


  /**
   * touchstart event handler
   */
  touchstartHandler = (e) => {
    e.stopPropagation();
    this.pointerDown = true;
    this.drag.startX = e.touches[0].pageX;
    this.drag.startY = e.touches[0].pageY;
  }


  /**
   * touchend event handler
   */
  touchendHandler = (e) => {
    e.stopPropagation();
    this.pointerDown = false;
    if (this.drag.endX) {
      this.updateAfterDrag();
    }
    this.clearDrag();
  }


  /**
   * touchmove event handler
   */
  touchmoveHandler = (e) => {
    e.stopPropagation();

    if (this.drag.letItGo === null) {
      this.drag.letItGo = Math.abs(this.drag.startY - e.touches[0].pageY) < Math.abs(this.drag.startX - e.touches[0].pageX);
    }

    if (this.pointerDown && this.drag.letItGo) {
      if (this.transformDuration !== 0) {
        this.transformDuration = 0;
      }
      this.drag.endX = e.touches[0].pageX;
      if (!this.slideAnimRunning) {
        this.slideAnimRunning = true;
        requestAnimationFrame(() => this.touchmoveWorker(e));
      }
    }
  }

  touchmoveWorker = (e) => {
    this.sliderTransform = (this.currentSlide * (this.carouselWidth / this.perPage) + (this.drag.startX - this.drag.endX)) * -1;
    this.slideAnimRunning = false;
  }


  /**
   * mousedown event handler
   */
  mousedownHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.pointerDown = true;
    this.drag.startX = e.pageX;
  }


  /**
   * mouseup event handler
   */
  mouseupHandler = (e) => {
    e.stopPropagation();
    this.pointerDown = false;
    this.transformDuration = this.config.duration;
    if (this.drag.endX) {
      this.updateAfterDrag();
    }
    this.clearDrag();
  }


  /**
   * mousemove event handler
   */
  mousemoveHandler = (e) => {
    e.preventDefault();
    if (this.pointerDown) {
      this.drag.endX = e.pageX;
      if (!this.slideAnimRunning) {
        if (this.transformDuration !== 0) {
          this.transformDuration = 0;
        }
        this.slideAnimRunning = true;
        requestAnimationFrame(this.mousemoveWorker);
      }
    }
  }

  mousemoveWorker = () => {
    this.sliderTransform = (this.currentSlide * (this.carouselWidth / this.perPage) + (this.drag.startX - this.drag.endX)) * -1;
    this.slideAnimRunning = false;
  }


  /**
   * mouseleave event handler
   */
  mouseleaveHandler = (e) => {
    if (this.pointerDown) {
      this.pointerDown = false;
      this.drag.endX = e.pageX;
      this.transformDuration = this.config.duration;
      this.updateAfterDrag();
      this.clearDrag();
    }
  }


  /**
   * Update after removing, prepending or appending items.
   */
  refresh = () => {
    this.sliderWidth = (this.carouselWidth / this.perPage) * this.innerElements.length;

    // Loop through the slides and add styling 
    Array.prototype.forEach.call(this.innerElements, element => {
      element.style.width = `${100 / this.innerElements.length}%`;
    });

    // Go to currently active slide after initial build
    this.slideToCurrent();
  }

  preventClickOnDrag = (event) => { }
}
