import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CoreUserService } from "../../services/users/core-user-service";
import { ChangeStateConfirmation } from "../../../components/change-state-confirmation";
import { User } from "models/security/user";
import { UserState } from "models/security/userState";

@autoinject()
export class CoreUserList {

  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.coreUserService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private coreUserService: CoreUserService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("CoreUserList");

  }

  public async bind(): Promise<void> {
    // this.logger.debug("attached");
    return await this.databind();
  }


  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    // this.logger.debug("parameters", this.parameters);
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  // public async create(): Promise<any> {
  //   return await this.dialogService.open({
  //     viewModel: CoreGroupModal
  //   }).whenClosed(async (response: DialogCloseResult) => {
  //     if (!response.wasCancelled) {
  //       if (response.output) {
  //         let id: number = <number>response.output;
  //         this.logger.debug("Save and continue id: ", id);
  //         this.router.navigate(`core-group/${id}`);
  //       }
  //       else {
  //         return await this.databind();
  //       }
  //     }
  //   });
  // }

  public async changeState(item: User): Promise<void> {
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.fullName
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.coreUserService.changeState(item.id)
            .then(() => {
              // this.databind();
              switch (item.userState) {
                case UserState.Active:
                  item.userState = UserState.Inactive;
                  break;
                case UserState.Inactive:
                  item.userState = UserState.Active;
                  break;
                default:
                  break;
              }
              this.notification.success("notifications.item_edited");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
        else{
          this.logger.debug("response canceled")
        }
        
      });
  }

  public async resendActivationMessage(id: number): Promise<void> {
    return await this.coreUserService.resendActivationMessage(id)
      .then(() => {
        this.notification.success("main.admin.pages.users.resend_activation_completed");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  // public toCoreUser(id: number): boolean {
  //   this.router.navigate(`core-user/${id}`);
  // }

  public async delete(item): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.fullName
    }).whenClosed((response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.coreUserService.delete(item.id)
          .then(async () => {            
            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("notifications.item_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }
}
