export enum Tests {
  Performance = -1,
  QuestionaryByCompany = 0,
  DISC = 1,
  Competences = 8,
  Intelligence = 9,
  ValuesV2 = 10,
  EnglishV2 = 23,
  PurposeLife = 27,
  SalesCapacity = 43,
  Leadership = 44,
  EmotionalIntelligence = 63,
  DigitalSkills = 341,
  ValuesV3 = 345,
  ProfessionalIntelligence = 358,
  HealthcareIntelligence = 405,
  CompetencesV2 = 1302,
  LeadershipV3 = 1405,
  SalesCapacityV2 = 1412,
  Motivation = 2389,
  Neurogame = 3614,
  Potential = 3710,
}
