import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { ReportTemplate } from "models/test-core/report-templates/report-template";

@autoinject()
export class PlanReportService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("PlanReportService");
  }

  public async getMissingByPlan(planId: number): Promise<ReportTemplate[]> {
    return await this.client.get(`planreport/get-missing-by-plan/${planId}`)
      .then((response: ReportTemplate[]) => {
        return response;
      })
  }
}
