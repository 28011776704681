import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CompanyCompetence } from "models/test-core/competences/company-competence";

@autoinject()
export class CompanyCompetenceService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CompanyCompetenceService");
  }

  public async getAll(questionaryId: number): Promise<CompanyCompetence[]> {
    return await this.client.get(`companycompetence/${questionaryId}`)
      .then((response: any) => {
        return <CompanyCompetence[]>response;
      });
  }

  public async getById(id: number): Promise<CompanyCompetence> {
    return await this.client.get(`companycompetence/${id}`)
    .then((response: CompanyCompetence) => {
      return <CompanyCompetence>response;
    })
  }

  public async list(parameters: UxDatatableParameters, questionaryId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`companycompetence/list/${questionaryId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async update(model: CompanyCompetence): Promise<CompanyCompetence> {
    return await this.client.updateById("companycompetence", model.id, null, model)
      .then(() => null);
  }

}
