import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AppHttpClient } from "common/services/app-http-client";
import { Agreement } from "models/agreements/agreement";

@autoinject()
export class AgreementMasterService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {
    this.logger = LogManager.getLogger("AgreementMasterService");
  }

  public async listToApprove(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("agreementmaster/list-to-approve", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  
  public async changeStatusToApproved(model: Agreement): Promise<void> {
    return await this.client.updateById("agreementmaster/change-status-to-approved", model.id, null, model)
      .then(() => null);
  }
}
