import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { QuestionaryGroup } from "models/test-management/questionary-groups/questionaryGroup";

@autoinject()
export class QuestionaryGroupService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("QuestionaryGroupService");
  }

  public async getAll(): Promise<QuestionaryGroup[]> {
    return await this.client.get("questionarygroup")
      .then((response: QuestionaryGroup[]) => {
        return response;
      });
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("questionarygroup/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<QuestionaryGroup> {
    return await this.client.getById("questionarygroup", id)
      .then((response: any) => {
        return <QuestionaryGroup>response;
      });
  }

  public async create(model: QuestionaryGroup): Promise<QuestionaryGroup> {
    return await this.client.create("questionarygroup", model)
      .then((response: any) => {
        return <QuestionaryGroup>response;
      });
  }

  public async update(model: QuestionaryGroup): Promise<void> {
    return await this.client.updateById("questionarygroup", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("questionarygroup", id)
      .then(() => null);
  }
  public async getTalentsById(id: number): Promise<QuestionaryGroup> {
    return await this.client.get("questionarygroup/get-talents-by-id", id)
      .then((response: any) => {
        return <QuestionaryGroup>response;
      });
  }
  public async validateActivationVideoInterview(id: number): Promise<boolean> {
    return await this.client.post(`questionarygroup/validate-activation-videointerview/${id}`)
    .then((response: boolean) => {
      return response;
    });
  }

}
