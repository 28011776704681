
import { autoinject, bindable, bindingMode, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { RouteConfig } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";

import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { UserLoginInfoModal } from '../users/user-login-info-modal';

import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

import { ChangeStateConfirmation } from "../../../components/change-state-confirmation";
import { User } from "models/security/user";
import { UserState } from "models/security/userState";
import { MasterUserService } from "../../services/users/master-user-service";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from 'models/companies/company';
import { ConvertUserConfirmation } from "main/components/convert-user-confirmation";
import { CompanyGroupService } from "main/admin/services/companies/company-group-service";

@autoinject()
export class MasterUserList {

  private logger: Logger;
  private params: any = {};

  private companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;

  private isLoaded: boolean = false;

  private canDelete: boolean = false;

  @bindable
  private currentCompany: number;
  @bindable
  private currentCompanyGroup: number;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.masterUserService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private companyGroupService: CompanyGroupService,
    private store: StoreService,
    private masterUserService: MasterUserService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("MasterUserList");

    this.state = this.store.state;
  }

  private validateCanDeleted() {
    if (this.parameters.tableData.length > 1) {
      this.canDelete = true;
    }
    else {
      this.canDelete = false;
    }
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async bind() {
    try{
      this.refreshCompany();

      if (this.companyId) {
        this.logger.debug("companyId", this.companyId);
        this.masterUserService.client.currentCompanyId = this.companyId;
        this.company = this.state.companies.find(x => x.id == this.companyId);
        this.isLoaded = true;
        return await this.databind();
      }
    }catch{

    }
  }

  private async refreshCompany(): Promise<void> {
    if (this.params.companyGroupId && this.params.companyGroupId === 'create') {
      this.companyId = null;
    }
    else if (!isNaN(this.params.companyGroupId)) {
      this.logger.debug("companyGroupId", this.params.companyGroupId);
      this.companyId = +this.params.companyGroupId;
    }
    else if(this.currentCompany){
      this.companyId = +this.currentCompanyGroup;
    }
  }

  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
        this.validateCanDeleted();
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }


  private async changeState(item: User): Promise<void> {
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.fullName
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.masterUserService.changeState(item.id)
            .then(() => {
              // this.databind();
              switch (item.userState) {
                case UserState.Active:
                  item.userState = UserState.Inactive;
                  break;
                case UserState.Inactive:
                  item.userState = UserState.Active;
                  break;
                default:
                  break;
              }
              this.notification.success("notifications.item_edited");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      });
  }

  private async viewLoginInfo(userId: number): Promise<any> {
    return await this.dialogService.open({
      viewModel: UserLoginInfoModal,
      model: userId
    });
  }

  private async resendActivationMessage(id: number): Promise<void> {
    return await this.masterUserService.resendActivationMessage(id)
      .then(() => {
        this.notification.success("main.admin.pages.users.resend_activation_completed");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async delete(item: User): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.fullName
    }).whenClosed((response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.masterUserService.delete(item.id)
          .then(async () => {
            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("notifications.item_deleted");
            this.validateCanDeleted();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async convertToUser(item: User): Promise<void> {
    const companyGroup = await this.companyGroupService.getById(this.companyId)
    return await this.dialogService.open({
      viewModel: ConvertUserConfirmation,
      model: companyGroup.companies
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        try {

          let model: User = await this.masterUserService.convertToUser(item, response.output);
          let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
          this.parameters.tableData.splice(indexOfEditedModel, 1);
          this.parameters.totalRecords--;
          this.notification.success("notifications.item_added");
          this.validateCanDeleted();
        } catch (error) {
          this.notification.error(error);
        }
      }
    });
  }
}
