import { PersonService } from 'main/test/services/people/person-service';
import config from 'config.js';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { BulkActivationService } from "../../services/people/bulk-activation-service";
import { BulkActivation } from 'models/test-person/bulkActivation';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { PersonLoginInfoModal } from '../people/person-login-info-modal';

@autoinject()
export class BulkActivationItemList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;

  public model: BulkActivation = new BulkActivation;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private micrositeUrl: string = "";

  private micrositeUrlElement: HTMLElement;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.bulkActivationService.listItemsById(this.model.id, this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private personService: PersonService,
    private bulkActivationService: BulkActivationService,
    private notification: Notification,
    private dialogService: DialogService
  ) {

    this.logger = LogManager.getLogger("BulkActivationItemList");

    let micrositeUrl = config.URLS_MICROSITE;
    this.micrositeUrl = micrositeUrl;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      this.bulkActivationService.client.currentCompanyId = this.companyId;

      if (!isNaN(this.params.bulkActivationId)) {
        this.model = await this.bulkActivationService.getById(+this.params.bulkActivationId);

        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async exportItems(): Promise<void> {
    return await this.bulkActivationService.exportItemsById(this.model.id, this.model.name)
      .then(() => null)
      .catch(error => {
        this.notification.error(error);
      });
  }

  private focus() {
    if (this.micrositeUrlElement) {
      this.micrositeUrlElement.focus();
    }
  }

  private async viewLoginInfo(personId: number): Promise<any> {

    return await this.dialogService.open({
      viewModel: PersonLoginInfoModal,
      model: personId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.sendIndividualReminder(personId);
      }
    });
  }

  private async sendIndividualReminder(id: number): Promise<boolean> {
    try {
      await this.personService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async createLogFile(id: number): Promise<any> {
    try {
      await this.bulkActivationService.createLogFile(id);
      this.notification.success("notifications.item_added");
      return await this.router.navigateToRoute("bulk-activations");
    }
    catch (error) {
      this.notification.error(error);
    }
  }


}
