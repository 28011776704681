import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { Person } from 'models/test-person/person';
import { ValidationController, ValidationRules, ControllerValidateResult, ValidationControllerFactory } from 'aurelia-validation';
import { CompanyQuestionaryService } from 'main/admin/services/test-management/company-questionary-service';
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ItemToQualify } from "models/questionaries/itemToQualify";
import { Activation } from "models/test-person/activation";
import { PersonActivationType } from "models/test-person/personActivationType";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class TeamPersonDiscActivationModal {

  private logger: Logger;
  public companyId: number;

  private model: Person = new Person();
  private validationController: ValidationController;

  private questionaries: Questionary[] = [];

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory,
    private notification: Notification,
    private companyQuestionaryService: CompanyQuestionaryService,
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("TeamPersonDiscActivationModal");

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("firstName").displayName("main.test.pages.people.first_name").required()
      .ensure("lastName").displayName("main.test.pages.people.last_name").required()
      .ensure("activation.questionaryId").displayName("main.test.pages.people.questionary").required()
      .on(this.model);
  }

  public async activate(companyId: number): Promise<any> {
    if (!isNaN(companyId)) {
      try {
        this.companyId = companyId;
        this.companyQuestionaryService.client.currentCompanyId = this.companyId;

        // set new activation
        this.model.activation = new Activation();
        this.model.activation.personActivationType = PersonActivationType.Normal;

        const questionaries = await this.getQuestionaries();
        this.questionaries = questionaries.filter(itm => (itm.questionaryType === QuestionaryType.Disc && itm.itemToQualify === ItemToQualify.Person));

        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private async getQuestionaries(): Promise<Questionary[]> {
    return await this.companyQuestionaryService.getAll();
  }

  public async submit(): Promise<void> {
    this.logger.info("submit");

    try {
      let result: ControllerValidateResult = await this.validationController.validate();
      if (result.valid) {
        return await this.save();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async save(): Promise<any> {
    return await this.dialogController.ok(this.model);
  }

}
