
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig, activationStrategy } from "aurelia-router";

import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

import { Notification } from "common/services/notification";
import { UserService } from "../../services/users/user-service";
import { User } from "models/security/user";
import { GroupService } from "../../services/groups/group-service";
import { Group } from "models/groups/group";
import { UserState } from "models/security/userState";
import { Profile } from "models/profiles/profile";
import { ProfileService } from "../../services/profiles/profile-service";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { Company } from "models/companies/company";

@autoinject()
export class UserEdit {

  private logger: Logger;

  public companyId: number;
  private company: Company;

  private model: User = new User();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;

  private profiles: Profile[];
  private groups: Group[];

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private userService: UserService,
    private groupService: GroupService,
    private profileService: ProfileService
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("UserEdit");

    this.state = this.store.state;

  }

  public async activate(params: any, routeConfig: RouteConfig): Promise<void> {

    this.logger.debug("Activated id:", params.userId);

    try {

      if (!isNaN(params.companyId)) {

        this.companyId = +params.companyId;

        if (!isNaN(params.companyDetailId)) {
          this.companyId = +params.companyDetailId;
        }

        this.userService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.groups = await this.groupService.getAll();

        this.profiles = await this.profileService.getAll();

        if (!isNaN(params.userId)) {
          this.model = await this.userService.getById(+params.userId);
        } else {
          this.model.userState = UserState.Pending;          
        }
        this.configureValidationRules();
      }
    }
    catch (error) {
      this.notification.error(error);
    };
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("firstName").displayName("main.admin.pages.users.first_name").required()
      .ensure("lastName").displayName("main.admin.pages.users.last_name").required()
      .ensure("email").displayName("main.admin.pages.users.email").email().required()
      .ensure("groupId").displayName("main.admin.pages.users.group_id").required()
      .ensure("companyId").displayName("main.admin.pages.users.company_id").required()
      .on(this.model);
  }

  private async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.userService.client.isRequesting;
  }

  private async save(): Promise<any> {
    if (!this.model.id) {
      return await this.userService.create(this.model)
        .then(async (model: User) => {
          this.notification.success("notifications.item_added");

          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("users");
          } else {
            return await this.router.navigateToRoute("user-child-router", { userId: model.id });
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.userService.update(this.model)
        .then(async () => {
          this.notification.success("notifications.item_edited");
          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("users");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }


  determineActivationStrategy() {
    return activationStrategy.replace;
  }

}
