import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Group } from "models/groups/group";
import { GroupService } from "../../services/groups/group-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from "models/companies/company";

@autoinject()
export class GroupEdit {

  private logger: Logger;

  private companyId: number;
  private company: Company;

  private model: Group = new Group();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private groupService: GroupService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("GroupEdit");

    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // this.logger.debug("activate");
    if (!isNaN(params.companyId) && !isNaN(params.groupId)) {
      this.companyId = +params.companyId;
      if (!isNaN(params.companyDetailId)) {
        this.companyId = +params.companyDetailId;
      }
      this.groupService.client.currentCompanyId = this.companyId;
      this.company = this.state.companies.find(x => x.id == this.companyId);
      try {
        this.model = await this.groupService.getById(+params.groupId);
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.groups.name").required()
      .on(this.model);
  }

  private async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.groupService.client.isRequesting;
  }

  private async save(): Promise<any> {
    return await this.groupService.update(this.model)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!this.saveAndContinue) {
          return await this.router.navigateToRoute("groups");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

}
