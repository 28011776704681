import { SpendMode } from 'models/plans/spendMode';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { Plan } from "models/plans/plan";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject
export class PlanService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("PlanService");
  }

  public async list(franchiseId: number,isRobotHunter: boolean, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`plan/list/${franchiseId}/${isRobotHunter}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  // public async getAllDemoByCompany(companyId: number): Promise<Plan[]> {
  //   return await this.client.get(`plan/get-all-demo/${companyId}`)
  //     .then((response: Plan[]) => {
  //       return response;
  //     })
  // }

  public async getAllByCompany(companyId: number): Promise<Plan[]> {
    return await this.client.get(`plan/get-all/${companyId}`)
      .then((response: Plan[]) => {
        return response;
      })
  }
  
  public async getAll(isRobotHunter: boolean): Promise<Plan[]> {
    return await this.client.get(`plan/get-all-plan/${isRobotHunter}`)
      .then((response: any) => {
        return <Plan[]>response;
      });
  }

  public async getById(id: number): Promise<Plan> {
    return await this.client.getById("plan", id)
      .then((response: any) => {
        return <Plan>response;
      });
  }

  public async create(model: Plan): Promise<Plan> {
    return await this.client.create("plan", model)
      .then((response: any) => {
        return <Plan>response;
      });
  }

  public async update(model: Plan): Promise<void> {
    return await this.client.updateById("plan", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("plan", id)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {
    return await this.client.post(`plan/change-state/${id}`)
      .then(() => null);
  }

  public async changeTestItemsState(id: number): Promise<void> {
    return await this.client.post(`plan/change-test-items-state/${id}`)
      .then(() => null);
  }
  public async changeResultsExport(id: number): Promise<void> {
    return await this.client.post(`plan/change-results-export-state/${id}`)
      .then(() => null);
  }
  
  public async changeOtherItemsState(id: number): Promise<void> {
    return await this.client.post(`plan/change-other-items-state/${id}`)
      .then(() => null);
  }

  public async changeCrossItemsState(id: number): Promise<void> {
    return await this.client.post(`plan/change-cross-items-state/${id}`)
      .then(() => null);
  }

  public async changeCustomReportSpendMode(id: number, spendMode: SpendMode): Promise<void> {
    return await this.client.post(`plan/change-custom-report-spend-mode/${id}`, spendMode)
      .then(() => null);
  }

  public async changeCandidateReport(id: number): Promise<void> {
    return await this.client.post(`plan/change-candidate-report-state/${id}`)
      .then(() => null);
  }
  
  public async changeVideoInterview(id: number, talents: number, state: boolean): Promise<void> {
    return await this.client.post(`plan/change-videointerview-state/${id}/${talents}/${state}`)
      .then(() => null);
  }
  
  // public async changeRobotHunter(id: number, talents: number, state: boolean): Promise<void> {
  //   return await this.client.post(`plan/change-robothunter-state/${id}/${talents}/${state}`)
  //     .then(() => null);
  // }
  public async changeViewWallets(id: number): Promise<void> {
    return await this.client.post(`plan/change-view-wallet-state/${id}`)
      .then(() => null);
  }
}
