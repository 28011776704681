import { PersonService } from 'main/test/services/people/person-service';
import { TeamService } from '../../services/team/team-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { TeamPersonService } from "main/test/services/team/team-person-service";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { TeamPerson } from "models/team/teamPerson";
import { Team } from "models/team/team";
import { TeamPersonActivationListModal } from './team-person-activation-list-modal';
import { TeamPersonDiscActivationModal } from './team-person-disc-activation-modal';
import { Person } from 'models/test-person/person';

interface ItemSetMap {
  [id: number]: boolean;
}


@autoinject()
export class TeamPersonList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private teamId: number;
  private model: Team;

  private peopleToInclude: ItemSetMap = {};
  private peopleToExclude: ItemSetMap = {};

  private peopleParameters: UxDatatableParameters = new UxDatatableParameters();
  private peopleUxDatatable: UxDatatableCustomAttribute;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  // private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.teamPersonService.list(this.parameters, this.teamId);
  }

  private peopleRefresh = async (): Promise<UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.teamPersonService.peopleList(this.peopleParameters, this.teamId);
  }

  constructor(
    private router: Router,
    private personService: PersonService,
    private teamService: TeamService,
    private teamPersonService: TeamPersonService,
    private dialogService: DialogService,
    private notification: Notification
  ) {

    this.logger = LogManager.getLogger("TeamPersonList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      if (!isNaN(this.params.teamId)) {
        this.teamId = this.params.teamId;

        this.teamService.client.currentCompanyId = this.companyId;
        this.teamPersonService.client.currentCompanyId = this.companyId;

        this.model = await this.teamService.getById(this.teamId);

        await this.peopleDatabind();

        await this.databind();

      }
    }
  }

  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    try {
      let response: UxDatatableResponse = await this.refresh();
      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async peopleDatabind(): Promise<void> {
    // this.logger.debug("databind");
    try {
      let response: UxDatatableResponse = await this.peopleRefresh();
      this.peopleParameters.tableData = response.data;
      this.peopleParameters.totalRecords = response.totalRecords;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async getMorePeople(scrollContext: any): Promise<void> {
    return await this.peopleUxDatatable.getMoreItems(scrollContext);
  }

  private async addItem(teamPerson: TeamPerson): Promise<any> {

    teamPerson.teamId = this.teamId;

    try {

      teamPerson = await this.teamPersonService.create(teamPerson, this.teamId);
      // delete from people list
      let indexOfEditedModel: number = this.peopleParameters.tableData.findIndex(x => x.personId === teamPerson.personId);
      this.peopleParameters.tableData.splice(indexOfEditedModel, 1);
      this.peopleParameters.totalRecords--;

      // add to team people
      this.parameters.tableData.unshift(teamPerson);
      this.parameters.totalRecords++;

      this.notification.success("notifications.item_added");

    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async removeItem(teamPerson: TeamPerson) {
    try {

      await this.teamPersonService.delete(teamPerson.id);

      // await this.peopleDatabind();

      // await this.databind();

      // delete from team people list
      let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === teamPerson.id);
      this.parameters.tableData.splice(indexOfEditedModel, 1);
      this.parameters.totalRecords--;

      // add to team people
      this.peopleParameters.tableData.unshift(teamPerson);
      this.peopleParameters.totalRecords++;

      this.notification.success("notifications.item_deleted");
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async addWithCustomActivation(model: TeamPerson): Promise<void> {

    return await this.dialogService.open({
      viewModel: TeamPersonActivationListModal,
      model: model
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        await this.addItem(model);
      }
    });
  }

  private async addWithPersonActivation(): Promise<void> {

    return await this.dialogService.open({
      viewModel: TeamPersonDiscActivationModal,
      model: this.companyId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        try {
          let person: Person = await this.personService.create(response.output);

          let model: TeamPerson = new TeamPerson();
          model.teamId = this.teamId;
          model.personId = person.id;

          model = await this.teamPersonService.create(model, this.teamId);

          // add to team people
          this.parameters.tableData.unshift(model);
          this.parameters.totalRecords++;

          this.notification.success("notifications.item_added");

        }
        catch (error) {
          this.notification.error(error);
        }

      }
    });
  }

}
