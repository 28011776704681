import { User } from 'models/security/user';
import { TransactionService } from '../../services/talents/transaction-service';
import { Transaction } from 'models/talents/transaction';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject
export class TalentModal {

  private logger: Logger;
  private model: Transaction;
  private validationController: ValidationController;
  private users: User[];

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory,
    private transactionService: TransactionService,
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("TalentModal");

  }

  public async bind(): Promise<any> {   
    await this.transactionService.getUserByCompany().then(users => this.users = users);
  }

  public activate(model: Transaction): void {   
    this.model = model;
    this.configureValidationRules();    
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("talentsAfter").displayName("main.admin.pages.talents.talent").matches(/^[0-9]*$/).required()
      .ensure("userId").displayName("main.admin.pages.talents.user").required()   
      .on(this.model);
  }

  public async submit(userId: number): Promise<void> {  
    this.model.wallet.userId = userId;
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();       
  }  
}
