import { Test } from '../../../../models/test-perfiltic/test';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyCompetenceModal } from '../people-competence/company-competence-modal';
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Person } from "models/test-person/person";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PersonMode } from "models/test-person/personMode";
import { QuestionaryGroup } from "models/test-management/questionary-groups/questionaryGroup";
import { QuestionaryGroupService } from "../../../admin/services/test-management/questionary-group-service";
import { Language } from "models/localizations/language";
import { ReportTemplate } from "models/test-core/report-templates/report-template";
import { ReportTemplateService } from "../../../admin/services/test-management/report-template-service";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { Router } from "aurelia-router";
import { PersonActivationType } from "models/test-person/personActivationType";
import { PersonService } from "main/test/services/people/person-service";
import { Activation } from "models/test-person/activation";
import { Subscription, EventAggregator } from "aurelia-event-aggregator";
import { UserInfoLoadedMessage } from "messages/userInfoLoadedMessage";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { EmailExists } from "./person-email-exists-modal";
import { Report } from 'models/test-reports/reports/report';
import { ReportService } from '../../../test-reports/services/report-service';
import { SessionService } from 'common/services/session-service';
import { Wootric } from 'main/components/wootric';
import { OpenIdProfile } from 'models/security/open-id-profile';
import { WootricComponentName } from "models/wootric/WootricComponentName";
import { NoDiscType } from 'models/questionaries/noDiscType';
import { PersonActivationConfirmationModal } from './person-activation-confirmation-modal';
import { Questionary } from "models/questionaries/questionary";
import { CatalogService } from "../../../services/catalogs/catalog-service";
import { CatalogBase } from "models/catalogs/catalog-base";
import { TestQuestionary } from 'models/test-core/test-questionary';
import { CompanyQuestionaryService } from 'main/admin/services/test-management/company-questionary-service';
import { I18N } from 'aurelia-i18n';
import config from 'config.js';
import { Languages } from 'models/localizations/languages';

interface DisableSetMap {
  [questionary: string]: boolean;
}

@autoinject()
export class PersonActivationCreate {

  private logger: Logger;
  private countries: CatalogBase[] = [];
  private host: string;
  private sessionStorageModel: any = {};

  public companyId: number;

  private state: State;

  private profile: OpenIdProfile;
  private questionaryFilter: string = "";
  private questionaryGroupFilter: string;
  private hasCompetencesFilter: boolean;
  private reportFilter: string;
  private questionaryGroups: QuestionaryGroup[];
  private searchQuestionaryGroup: QuestionaryGroup[];
  private reportTemplates: ReportTemplate[];
  private searchReport: ReportTemplate[];
  private reportTemplate: ReportTemplate = new ReportTemplate();
  private languages: Language[];
  private questionaries: TestQuestionary[] = [];
  private searchQuestionary: TestQuestionary[] = [];
  private isCompetenceActive: boolean = false;
  private signal: string = this.i18n.tr("main.test.pages.people.is_new_test");
  private selectedQuestionaries = {
    qty: 0,
    avg: 0,
    questionaries: "",
    hasCompetences: false,
    hasEngineersTest: false,
  };


  private model: Person = new Person();
  private validationController: ValidationController;

//  private selectedQuestionaryIds: number[] = [];
  private subscription: Subscription;

  private isDisable: boolean = false;
  private isEngineersTests: boolean = false;
  private showDisableParameter: boolean = false;
  private report: Report = new Report();
  private questionaryGroup: QuestionaryGroup;
  private isInvalid: boolean = false;
  private isCareerPath: boolean = false;
  private selectedLanguageId: number = Languages.Spanish;

  constructor(
    private catalogService: CatalogService,
    private router: Router,
    private store: StoreService,
    private reportService: ReportService,
    private personService: PersonService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private questionaryGroupService: QuestionaryGroupService,
    private reportTemplateService: ReportTemplateService,
    private languageService: CoreLanguageService,
    protected ea: EventAggregator,
    private dialogService: DialogService,
    private sessionService: SessionService,
    private wootric: Wootric,
    private companyQuestionaryService: CompanyQuestionaryService,
    private i18n: I18N

  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PersonActivationCreate");

    this.state = this.store.state;

    this.subscription = this.ea.subscribe(UserInfoLoadedMessage, (message: UserInfoLoadedMessage) => this.setUserLanguage.bind(this));

    let backendUrl = config.URLS_BACKEND;

    this.host = backendUrl + "/modules/localization/assets/images/flags/";
  }

  public detached() {
    this.subscription.dispose();
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("firstName").displayName("main.test.pages.people.first_name").required()
      .ensure("lastName").displayName("main.test.pages.people.last_name").required()
      .ensure("email").displayName("main.test.pages.people.email").email().required()
      .when(x => this.model.personMode === PersonMode.Linkedin)
      .ensure("email").displayName("main.test.pages.people.email").email().required()
      .when(x => this.isCareerPath)
      .ensure("activation.languageId").displayName("main.admin.pages.questionaries.language").required()
      .ensure("phone").displayName("main.test.pages.people.phone").required()
      .when(x => this.model.indicativeCountry != 0)
      .ensure("indicativeCountry").displayName("main.test.pages.people.indicative").required()
      .when(x => this.model.phone > '0')
      .on(this.model);

    ValidationRules
      .ensure("reportTemplateId").displayName("main.test_reports.pages.reports.report_template").required()
      .ensure("discType").displayName("main.test_reports.pages.reports.discType.title").required()
      .ensure("zeroOrOnepositionId").displayName("main.test_reports.pages.reports.parameters.ZeroOrOnePosition").maxItems(1)
      .ensure("positionId").displayName("main.test_reports.pages.reports.parameters.Position").required()
      .ensure("teamId").displayName("main.test_reports.pages.reports.parameters.Team").required()
      .ensure("organizationId").displayName("main.test_reports.pages.reports.parameters.Organization").required()
      .ensure("positionIds").displayName("main.test_reports.pages.reports.parameters.Positions").required().minItems(1).maxItems(1000)
      .ensure("compareToPositionId").displayName("main.test_reports.pages.reports.parameters.ComparePosition").required()
      .ensure("compareToTeamId").displayName("main.test_reports.pages.reports.parameters.CompareTeam").required()
      .ensure("compateToOrganizationId").displayName("main.test_reports.pages.reports.parameters.CompareOrganization").required()
      .ensure("compareToPersonId").displayName("main.test_reports.pages.reports.parameters.ComparePerson").required()
      .ensure("personId").displayName("main.test_reports.pages.reports.parameters.Person").required()
      .ensure("personId").displayName("main.test_reports.pages.reports.parameters.Leader").required()
      .ensure("peopleIds").displayName("main.test_reports.pages.reports.parameters.People").required().minItems(1)
      .ensure("oneOrTwoPeopleIds").displayName("main.test_reports.pages.reports.parameters.OneOrTwoPeople").required().minItems(1).maxItems(2)
      .ensure("twoPeopleIds").displayName("main.test_reports.pages.reports.parameters.TwoPeople").required().minItems(2)
      .ensure("threePeopleIds").displayName("main.test_reports.pages.reports.parameters.ThreePeople").required().minItems(1).maxItems(3)
      .ensure("observerIds").displayName("main.test_reports.pages.reports.parameters.Observers").required().minItems(1).maxItems(1000)
      .on(this.report);
  }

  public async activate(params: any): Promise<any> {

    this.sessionStorageModel = this.checkIsThereSessionStageObject();
    this.model.firstName = this.checkUndefined(this.sessionStorageModel, 'firstName');
    this.model.lastName = this.checkUndefined(this.sessionStorageModel, 'lastName');
    this.model.document = this.checkUndefined(this.sessionStorageModel, 'document');
    this.model.email = this.checkUndefined(this.sessionStorageModel, 'email');
    let indicativeCountry = this.checkUndefined(this.sessionStorageModel, 'indicativeCountry');

    if (typeof indicativeCountry === 'number') {
      this.model.indicativeCountry = parseInt(indicativeCountry);
    }
    this.model.phone = this.checkUndefined(this.sessionStorageModel, 'phone');

    this.profile = await this.sessionService.getProfile();
    this.wootric.attached(this.profile, WootricComponentName.personActivationCreate);

    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      this.model.companyId = this.companyId;

      // set new activation
      this.model.activation = new Activation();
      this.model.activation.personActivationType = PersonActivationType.Normal;

      // this.personService.client.currentCompanyId = this.companyId;
      this.questionaryGroupService.client.currentCompanyId = this.companyId;
      this.reportTemplateService.client.currentCompanyId = this.companyId;

      this.questionaryGroups = await this.getQuestionaryGroups();
      this.reportTemplates = await this.getReportTemplates();
      this.countries = await this.catalogService.getCountryAll();
      this.configureValidationRules();
      this.questionaries = await this.companyQuestionaryService.getAll();     
      this.searchQuestionary = this.questionaries;
      this.searchQuestionaryGroup = this.questionaryGroups;
      this.searchReport = this.reportTemplates;

    }
  }

  public setUserLanguage() {
    // this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find(lg => lg.culture === activeCulture);
      if (activeLang) {
        this.model.activation.languageId = activeLang.id;
      }
    }
  }

  private async bind(): Promise<any> {
    try {
      this.languages = await this.languageService.getAll();      
      this.setUserLanguage();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async selectCompanyCompetence(): Promise<void> {
    this.logger.debug("model.companyCompetenceIds")
    return await this.dialogService.open({
      viewModel: CompanyCompetenceModal,
      model: this.model.activation.companyCompetenceIds
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (this.isCompetenceActive && this.model.activation.companyCompetenceIds.length == 0) {
          this.notification.warning("main.test.pages.people.errors.no_competences_selected");
        }
      }
    });
  }
  private async changeBattery(itm) { 
    this.model.activation.reportTemplateId = null;
    this.model.activation.questionaryIds = [];
    this.model.engineersTests = null;
    const qg = this.questionaryGroups.filter(it => it.id === itm);
    this.isDisable = false;

    if (qg.length) {
      const questionaries = qg[0].questionaries;
      const text = this.signal;
      questionaries.forEach(function(it){
        it.isNewTest == true ? it.name = it.name + " " + text : it.name;
      });
      this.selectedQuestionaries = {
        qty: questionaries.length,
        avg: questionaries.length > 0 ? questionaries.map(it => it.averageTime).reduce((a, b) => a + b) : 0,
        questionaries: questionaries.length > 0 ? questionaries.map(it => it.name).reduce((a, b) => a + ", " + b) : "",
        hasCompetences: questionaries.length > 0 ? questionaries.some(it => it.questionaryType == QuestionaryType.Competences) : false,
        hasEngineersTest: questionaries.length > 0 ? questionaries.some(it => it.noDiscType == NoDiscType.EngineersTest) : false,
      };
    
      this.validateEngineersTests(questionaries);
      this.validateQuestionaryCareerPath(questionaries);

    } else {
      this.selectedQuestionaries = {
        qty: 0,
        avg: 0,
        questionaries: "",
        hasCompetences: false,
        hasEngineersTest: false,
      };
     
    }
    this.cleanCompanyCompetences();
    const questionaryGroup = await this.questionaryGroupService.getById(itm);
    await this.isValidActivationVideoInterview(questionaryGroup);
  }

  private async changeQuestionary() {
    this.model.activation.reportTemplateId = null;
    this.model.activation.questionaryGroupId = null;
    let questionariesFilter: TestQuestionary[] = [];
    var testQuestionary = new TestQuestionary();
    this.model.activation.questionaryIds.forEach(element => {
      testQuestionary = new TestQuestionary();      
      testQuestionary = this.questionaries.find(it => it.stringId == element.stringId);
      questionariesFilter.push(testQuestionary)
    });
    this.isDisable = false;
    if (questionariesFilter.length > 0) {      
      this.selectedQuestionaries = {
        qty: questionariesFilter.length,
        avg: questionariesFilter.length > 0 ? questionariesFilter.map(it => it.averageTime).reduce((a, b) => a + b) : 0,
        questionaries: questionariesFilter.length > 0 ? questionariesFilter.map(it => it.name).reduce((a, b) => a + ", " + b) : "",
        hasCompetences: questionariesFilter.length > 0 ? questionariesFilter.some(it => it.questionaryType == QuestionaryType.Competences) : false,
        hasEngineersTest: questionariesFilter.length > 0 ? questionariesFilter.some(it => it.noDiscType == NoDiscType.EngineersTest) : false,
      };           
      if (this.selectedQuestionaries.hasEngineersTest) {
        this.validateEngineersTests(questionariesFilter);

      }
      if (!this.selectedQuestionaries.hasEngineersTest) {
        this.isEngineersTests = false;
        this.model.engineersTests = [];
      }

      this.validateQuestionaryCareerPath(questionariesFilter);
      if (this.selectedQuestionaries.hasCompetences && this.hasCompetencesFilter != true) {
        this.selectCompanyCompetence();
        this.hasCompetencesFilter = true;
      }
      if (!this.selectedQuestionaries.hasCompetences) {
        this.hasCompetencesFilter = false;
      }

    } else {
      this.selectedQuestionaries = {
        qty: 0,
        avg: 0,
        questionaries: "",
        hasCompetences: false,
        hasEngineersTest: false,
      };
     
    }
  }

  private changeReport(itm) {
    this.model.activation.questionaryGroupId = null;
    this.model.activation.questionaryIds = [];
    this.model.engineersTests = null;
    const qg = this.reportTemplates.filter(it => it.id === itm);
    if (qg.length) {
      const questionaries = qg[0].questionaries;
      const text = this.signal;
      questionaries.forEach(function(it){
        it.isNewTest == true ? it.name = it.name + " " + text : it.name;
      });
      this.selectedQuestionaries = {
        qty: questionaries.length,
        avg: questionaries.length > 0 ? questionaries.map(it => it.averageTime).reduce((a, b) => a + b) : 0,
        questionaries: questionaries.length > 0 ? questionaries.map(it => it.name).reduce((a, b) => a + ", " + b) : "",
        hasCompetences: questionaries.length > 0 ? questionaries.some(it => it.questionaryType == QuestionaryType.Competences) : false,
        hasEngineersTest: questionaries.length > 0 ? questionaries.some(it => it.noDiscType == NoDiscType.EngineersTest) : false,
      };
     
      this.report.reportTemplateId = itm;
      this.validateEngineersTests(questionaries);
      // Ticket 5100
      // this.report.activationQuestionaryIds = qg[0].questionaries.map(it => it.id);
      this.changeReportParameter();
      this.validateQuestionaryCareerPath(questionaries);

    } else {
      this.selectedQuestionaries = {
        qty: 0,
        avg: 0,
        questionaries: "",
        hasCompetences: false,
        hasEngineersTest: false,
      };
    }
  }

  private validateQuestionaryCareerPath(questionaries: Questionary[]) {
    this.isCareerPath = false;
    const qCareerPaths = questionaries.filter(q => q.noDiscType == NoDiscType.CareerPath);
    this.isCareerPath = qCareerPaths.length > 0 ? true : false;
  }

  private validateEngineersTests(questionaries: Questionary[]) {
    this.isEngineersTests = false;
    var engineersTests: Test[] = [];
    let existQuestionary: Test[] = [];
    if (this.model.engineersTests != undefined) {
      if (this.model.engineersTests.length > 0)
        engineersTests = this.model.engineersTests;
    }

    var test = new Test();
    questionaries.forEach(function (item: Questionary) {
      if (item.noDiscType == NoDiscType.EngineersTest) {
        if (engineersTests != undefined) {
          if (engineersTests.length > 0)
            existQuestionary = engineersTests.filter(x => x.questionaryId === item.id);

          if (existQuestionary.length == 0) {
            test = new Test();
            test.questionaryId = item.id
            test.questionaryName = item.name
            engineersTests.push(test);
          }
        }

      }
    });

    if (engineersTests.length) {
      this.model.engineersTests = engineersTests;
      this.isEngineersTests = true;
    }
  }

  private async isValidActivationVideoInterview(questionaryGroup: QuestionaryGroup) {
    const hasVideoInterview = questionaryGroup.questionaries.filter(x => x.noDiscType === NoDiscType.VideoInterview);
    if (hasVideoInterview.length > 0) {
      const validate = await this.questionaryGroupService.validateActivationVideoInterview(questionaryGroup.id);
      if (!validate) {
        this.isInvalid = true;
        return this.notification.warning("main.test.pages.people.errors.questionarygroup_videinterview_activation_failed");
      }
      else
        this.isInvalid = false;
    }
    else
      this.isInvalid = false;
  }

  private cleanCompanyCompetences() {
    if (!this.selectedQuestionaries.hasCompetences) {
      this.model.activation.companyCompetenceIds = [];
    }
  }

  private async getQuestionaryGroups(): Promise<QuestionaryGroup[]> {
    return this.questionaryGroupService.getAll();
  }

  private async getReportTemplates(): Promise<ReportTemplate[]> {
    return this.reportTemplateService.getAll();
  }

  private checkIsThereSessionStageObject() {
    return JSON.parse(sessionStorage.getItem('personActivation')) == null
      ? new Object() :
      JSON.parse(sessionStorage.getItem('personActivation'));
  }

  private checkUndefined(obj: Object, prop: string): string {
    return obj[prop] == undefined ? '' : obj[prop];
  }

  public handleChange(target: any) {
    let prop: string = target.id.split('.')[1];
    this.sessionStorageModel[prop] = this.model[prop];
    sessionStorage.setItem(`personActivation`, JSON.stringify(this.sessionStorageModel));
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (!this.model.activation.reportTemplateId && !this.model.activation.questionaryGroupId && !this.model.activation.questionaryIds.length) {
            this.notification.warning("main.test.pages.people.errors.questionarygroup_or_reports_is_missing");
          }
          else if (this.model.activation.invitationExpireDate && this.model.activation.invitationExpireDate < new Date()) {
            this.notification.warning("main.test.pages.people.errors.old_invitation_expire_date");
          }
          // else if (this.selectedQuestionaries.hasCompetences && this.model.activation.companyCompetenceIds.length == 0) {
          //   this.notification.warning("main.test.pages.people.errors.no_competences_selected");
          // }
          else {
            if (this.model.activation.questionaryGroupId != null) {
              this.questionaryGroup = await this.questionaryGroupService.getTalentsById(this.model.activation.questionaryGroupId);
              let hasVideoInterview = this.questionaryGroup.questionaries.filter(x => x.noDiscType === NoDiscType.VideoInterview);

              if (hasVideoInterview.length > 0 && this.questionaryGroup.talentsPrice > 0) {
                return await this.dialogService.open({
                  viewModel: PersonActivationConfirmationModal,
                  model: this.questionaryGroup.talentsPrice
                }).whenClosed(async (response: DialogCloseResult) => {
                  if (!response.wasCancelled) {
                    return await this.save(keepEditing);
                  }
                });
              }
              else {
                return await this.save(keepEditing);
              }
            }
            else {
              return await this.save(keepEditing);
            }
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.personService.client.isRequesting;
  }

  private concatenatePhone() {

    if ((this.model.phone != null && this.model.phone != '') && !isNaN(this.model.indicativeCountry)) {
      let country = this.countries.filter(f => f.id === this.model.indicativeCountry);
      this.model.phone = "(" + country[0].phoneCode + ")" + this.model.phone
    }

  }

  private async save(saveAndContinue: boolean): Promise<any> {
    try {
      this.concatenatePhone();
      if (this.model.email) {
        let emailExists: number = await this.personService.existsEmail(this.model.email);
        if (emailExists) {
          return await this.dialogService.open({
            viewModel: EmailExists,
            model: emailExists
          }).whenClosed(async (response: DialogCloseResult) => {
            if (!response.wasCancelled) {

              this.model = await this.personService.create(this.model);
              if (this.isDisable && this.showDisableParameter) {
                this.saveReport();
              }

              this.notification.success("notifications.item_added");
              if (saveAndContinue) {
                sessionStorage.removeItem('personActivation');
                // this.logger.debug("Save and continue id: ", model.id);
                return await this.router.navigateToRoute("person-child-router", { personId: this.model.id });
              } else {
                sessionStorage.removeItem('personActivation');
                return await this.router.navigateToRoute("people");
              }

            }
          });
        }
        else {
          this.model = await this.personService.create(this.model);
          if (this.isDisable && this.showDisableParameter) {
            this.saveReport();
          }
          this.notification.success("notifications.item_added");
        }
        if (saveAndContinue) {
          sessionStorage.removeItem('personActivation');
          // this.logger.debug("Save and continue id: ", model.id);
          return await this.router.navigateToRoute("person-child-router", { personId: this.model.id });
        } else {
          sessionStorage.removeItem('personActivation');
          return await this.router.navigateToRoute("people");
        }
      }
      else {
        this.model = await this.personService.create(this.model);
        if (this.isDisable && this.showDisableParameter) {
          this.saveReport();
        }
        this.notification.success("notifications.item_added");
        if (saveAndContinue) {
          sessionStorage.removeItem('personActivation');
          // this.logger.debug("Save and continue id: ", model.id);
          return await this.router.navigateToRoute("person-child-router", { personId: this.model.id });
        } else {
          sessionStorage.removeItem('personActivation');
          return await this.router.navigateToRoute("people");
        }
      }
    }
    catch (error) {
      this.notification.error(error.error);
    };
  }

  private changeTag(ban: boolean) {
    this.showDisableParameter = ban;
    if (!ban) {
      this.isDisable = ban;
    }
    this.model.activation.reportTemplateId = null;
    this.model.activation.questionaryGroupId = null;
    this.selectedQuestionaries = {
      qty: 0,
      avg: 0,
      questionaries: "",
      hasCompetences: false,
      hasEngineersTest: false,
    }
    this.model.activation.questionaryIds = [];
    this.model.engineersTests = null;



  }

  private createQuestionaryGroup() {
    // this.dialogController.cancel();
    this.router.navigate(`/company/${this.companyId}/admin/test-management/questionary-groups/questionary-group/create`);
  }

  private OnChangeQuestionary() {
    this.searchQuestionary = [];

    if (!this.selectedLanguageId && !this.questionaryFilter)
    {         
       this.searchQuestionary = this.questionaries;
    }
    else if (this.selectedLanguageId == 0 && this.questionaryFilter.length === 0)
    {  
       this.searchQuestionary = this.questionaries;
    }    
    else if (this.questionaryFilter.length > 0 && this.selectedLanguageId > 0)
    {     
     this.searchQuestionary = this.questionaries.filter(q => q.name.toLowerCase().includes(this.questionaryFilter.toLowerCase()) &&  q.languageId === this.selectedLanguageId);
    }
    else if (this.questionaryFilter.length > 0) {     
      this.searchQuestionary = this.questionaries.filter(q => q.name.toLowerCase().includes(this.questionaryFilter.toLowerCase()));
    }
    else if(this.selectedLanguageId > 0){   
      
      this.searchQuestionary = this.questionaries.filter(q => q.languageId == this.selectedLanguageId);
     }
  }  

  private async OnChangeQuestionaryGroup() {
    if (this.questionaryGroupFilter.length > 0) {
      this.searchQuestionaryGroup = this.questionaryGroups.filter(q => q.name.toLowerCase().includes(this.questionaryGroupFilter.toLowerCase()));
      this.model.activation.questionaryGroupId = this.searchQuestionaryGroup[0].id;
      await this.changeBattery(this.model.activation.questionaryGroupId);

    }

    else if (this.questionaryGroupFilter == null || this.questionaryGroupFilter.length == 0) {
      this.searchQuestionaryGroup = [];
      this.searchQuestionaryGroup = this.questionaryGroups;
      this.model.activation.questionaryGroupId = null;
    }

  }

  private async OnChangeReport() {
    if (this.reportFilter.length > 0) {
      this.searchReport = this.reportTemplates.filter(q => q.name.toLowerCase().includes(this.reportFilter.toLowerCase()));
      this.model.activation.reportTemplateId = this.searchReport[0].id;
      await this.changeReport(this.model.activation.reportTemplateId);
    }
    else if (this.reportFilter == null || this.reportFilter.length == 0) {
      this.searchReport = [];
      this.searchReport = this.reportTemplates
      this.model.activation.reportTemplateId = null;
      await this.changeReport(this.model.activation.reportTemplateId);
    }

  }

  private createReportTemplate() {
    // this.dialogController.cancel();
    this.router.navigate(`/company/${this.companyId}/admin/test-management/report-templates/report-template/create`);
  }

  public async changeReportParameter(): Promise<any> {

    this.logger.debug("changeReport");

    try {
      this.reportTemplate = await this.reportTemplateService.getById(this.report.reportTemplateId);
      this.report.companyId = this.companyId;
      this.report.sheets = this.reportTemplate.sheets;
      this.setParameters(this.report.sheets.map(x => x.id));
    } catch (error) {
      this.notification.error(error);
    }
  }

  private setParameters(sheetIds: number[]) {
    // reset variable
    this.report.parameters = [];

    this.report.sheets.forEach(sheet => {
      const index = sheetIds.findIndex(x => x == sheet.id);
      if (index !== -1) {
        for (let par of sheet.parameters) {
          const index1 = this.report.parameters.findIndex(x => x === par.parameter);
          if (index1 === -1) {
            this.report.parameters.push(par.parameter);
          }
        }
      }
    });

    this.logger.debug("Parameters: ", this.report.parameters);
  }

  private async saveReport(): Promise<any> {
    try {
      this.report.personId = this.model.id;
      this.report.activationId = this.model.activation.id;
      this.report = await this.reportService.create(this.report);
    } catch (error) {
      this.notification.error(error);
    }

  }

  public notificationInfo(message:string){   
    this.notification.info(message);
  }

}
