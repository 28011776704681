import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { AppHttpClient } from "common/services/app-http-client";
import { Permission } from "models/groups/permission";

@autoinject
export class CorePermissionService {

  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger('CorePermissionService');
  }

  public async getAll(): Promise<Array<Permission>> {
    return await this.client.get('corepermission')
      .then((response: Permission[]) => {
        return response;
      });
  }
}
