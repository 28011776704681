import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Archetype } from "models/archetypes/archetype";
import { ArchetypeService } from "../../services/archetypes/archetype-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { archetypeType } from "models/archetypes/archetypeType";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";

interface SearchParams {
  archetypetype: archetypeType
}
@autoinject
export class ArchetypeEdit{

  private logger: Logger;
  private model: Archetype;
  private validationController: ValidationController;

  private archetypes: Archetype[];
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  
  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory,
    private archetypeService: ArchetypeService,
    private notification: Notification) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ArchetypeModal");
  }
  public bind(): void {
    this.archetypeService.getAll().then(archetypes => this.archetypes = archetypes);
  }
  
  public activate(model: Archetype): void {
    this.model = model;
    this.configureValidationRules();
  }
  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.archetypes.name").required()
      .ensure("d").displayName("main.admin.pages.archetypes.d").required().matches(/^[1-9][0-9]?$|^100$/)
      .ensure("i").displayName("main.admin.pages.archetypes.i").required().matches(/^[1-9][0-9]?$|^100$/)
      .ensure("s").displayName("main.admin.pages.archetypes.s").required().matches(/^[1-9][0-9]?$|^100$/)
      .ensure("c").displayName("main.admin.pages.archetypes.c").required().matches(/^[1-9][0-9]?$|^100$/)
            
      .on(this.model);
  }
  public async submit(saveAndContinue: boolean): Promise<void> {
    // this.saveAndContinue = saveAndContinue;
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(saveAndContinue);
        }        
      });
      
  }
  public async save(saveAndContinue: boolean): Promise<any> {
    this.logger.debug("save:", saveAndContinue);
    this.validationController.reset();
    return await this.dialogController.ok(saveAndContinue);
  }
}
