import { CompanyAppHttpClient } from 'common/services/company-app-http-client';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { Observation } from 'models/agreements/observation';
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class ObservationService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("ObservationService");
  }

  public async addendumList(agreementId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`agreementobservation/addendum-list/${agreementId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async publicNoteList(agreementId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`agreementobservation/public-note-list/${agreementId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async privateNoteList(agreementId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`agreementobservation/private-note-list/${agreementId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  // public async getById(id: number): Promise<Observation> {
  //   return await this.client.getById("agreementobservation", id)
  //     .then((response: any) => {
  //       return <Observation>response;
  //     });
  // }

  public async createAddendum(model: Observation): Promise<Observation> {
    return await this.client.create("agreementobservation/addendum", model)
      .then((response: any) => {
        return <Observation>response;
      });
  }

  public async createPublicNote(model: Observation): Promise<Observation> {
    return await this.client.create("agreementobservation/public-note", model)
      .then((response: any) => {
        return <Observation>response;
      });
  }

  public async createPrivateNote(model: Observation): Promise<Observation> {
    return await this.client.create("agreementobservation/private-note", model)
      .then((response: any) => {
        return <Observation>response;
      });
  }

  public async updateAddendum(model: Observation): Promise<void> {
    return await this.client.updateById("agreementobservation/addendum", model.id, null, model)
      .then(() => null);
  }

  public async updatePrivateNote(model: Observation): Promise<void> {
    return await this.client.updateById("agreementobservation/private-note", model.id, null, model)
      .then(() => null);
  }

  public async updatePublicNote(model: Observation): Promise<void> {
    return await this.client.updateById("agreementobservation/public-note", model.id, null, model)
      .then(() => null);
  }  

  public async deleteAddendum(id: number): Promise<void> {
    return await this.client.deleteById("agreementobservation/addendum", id)
      .then(() => null);
  }

  public async deletePublicNote(id: number): Promise<void> {
    return await this.client.deleteById("agreementobservation/public-note", id)
      .then(() => null);
  }

  public async deletePrivateNote(id: number): Promise<void> {
    return await this.client.deleteById("agreementobservation/private-note", id)
      .then(() => null);
  }
}
