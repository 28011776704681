import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Contact } from "models/contacts/contact";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class ContactModal {

  private model: Contact = new Contact();

  private validationController: ValidationController;
  private logger: Logger;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ContactModal");

  }

  async activate(model: Contact): Promise<void> {
    this.logger.debug("activate");
    this.model = model;
    this.configureValidationRules();
  }

  configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("fullName").displayName("main.admin.pages.contacts.full_name").required()
      .ensure("emailAddress").displayName("main.admin.pages.contacts.email").email().required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    this.logger.debug("save:");
    return await this.dialogController.ok();
  }

}
