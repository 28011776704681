import { Notification } from "common/services/notification";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult, DialogController } from "aurelia-dialog";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { TalentPlanService } from "main/admin/services/talents/talent-plan-service";

@autoinject()
export class TalentPlanItemsModal {

  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private talentPlanService: TalentPlanService,
    private dialogController: DialogController,
    private notification: Notification) {

    this.logger = LogManager.getLogger("TalentPlanItemsModal");
  }

  public async activate(): Promise<void> {


  }

  public async bind(): Promise<void> {
    this.logger.info("databind");

    try {

      let response = await this.talentPlanService.getTestPlanItemsByPlanId(this.parameters)

      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;

    }
    catch (error) {
      this.notification.error(error);
    }

  }

  private refresh = async (): Promise<UxDatatableResponse> => {
    return await this.talentPlanService.getTestPlanItemsByPlanId(this.parameters);
  }

}
