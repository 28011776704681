
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class CompanyGroupMasterListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("CompanyGroupMasterListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./company-group-master-list-selector"),
        name: "company-group-master-list-selector",
      },

      // {
      //   route: "company/:companyDetailId",
      //   moduleId: PLATFORM.moduleName("../companies-master/company-master-child-router"),
      //   name: "company-master-child-router",
      // },

      {
        route: "company-group/:companyGroupId",
        moduleId: PLATFORM.moduleName("./company-group-master-child-router"),
        name: "company-group-master-child-router",
      },

      {
        route: "company-group/:companyGroupId/company/:companyDetailId",
        moduleId: PLATFORM.moduleName("../companies-master/company-master-child-router"),
        name: "company-master-child-router",
      },
      
    ]);
  }
}
