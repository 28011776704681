import { ArchetypeLocalization } from 'models/archetypes/archetype-localization';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { ArchetypeLocalizationService } from "main/admin/services/archetypes/archetype-localization-service";

import { LanguageService } from 'main/admin/services/localizations/language-service';
import { ControllerValidateResult, ValidationController } from 'aurelia-validation';
import { PositionLocalizationService } from 'main/test/services/position/position-localization-service';
import { PositionLocalization } from 'models/test-position/positionLocalization';
import { PositionReferenceLocalizationModal } from './position-reference-localization-modal';


@autoinject()
export class PositionReferenceLocalizationList {
  private logger: Logger;

  private params: any = {};
  public companyId: number;
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private languages;

  private isLoaded: boolean = false;

  private refresh = async (): Promise<void | UxDatatableParameters> => {
    this.logger.debug("refreshing");
    try {
      const response = await this.positionlocalizationservice.list(this.parameters);
      return response;
    }
    catch (error) {
      return this.notification.error(error);
    }
  }

  constructor(
    private languageService: LanguageService,
    private validationController: ValidationController,
    private positionlocalizationservice: PositionLocalizationService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("PositionReferenceLocalizationList");

    this.parameters.search = {};

  }

  private async activate(params: any): Promise<void> {
    if (this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async bind(): Promise<any> {
    try {
      this.languages = await this.languageService.getAll();
      if (this.languages.length > 0) {
        if (!isNaN(this.params.companyId)) {

          this.companyId = +this.params.companyId;
          this.positionlocalizationservice.client.currentCompanyId = this.companyId;
        }
       
        this.parameters.search.LanguageId = this.languages[0].id;
        return await this.databind();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async create(item: PositionLocalization): Promise<void> {
    return await this.dialogService.open({
      viewModel: PositionReferenceLocalizationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.logger.debug("response.output", response.output);
        try {
          let model: PositionLocalization = await this.positionlocalizationservice.create(item);

          item.id = model.id;
          // this.parameters.tableData.unshift(model);
          // this.parameters.totalRecords++;
          this.notification.success("notifications.item_added");
          // return await this.databind();
        } catch (error) {
          this.notification.error(error);
        }
      }
    });
  }

  public async edit(item: PositionLocalization): Promise<void> {
    return await this.dialogService.open({
      viewModel: PositionReferenceLocalizationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      this.logger.debug("response.output", response.output);
      if (!response.wasCancelled) {
        try {
          await this.positionlocalizationservice.update(item);

          this.notification.success("main.admin.pages.archetypes.notifications.edited");
          // return await this.refresh();
        } catch (error) {
          this.notification.error(error);
          // return await this.databind();
        }
      }
      // return await this.databind();
    });
  }

  public async submit(item: PositionLocalization): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (item.id != 0) {
          return await this.edit(item);
        }
        return await this.create(item);
      });
  }

}
