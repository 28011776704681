
import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController, DialogService, DialogCloseResult } from "aurelia-dialog";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { ScaleService } from "../../services/scales/scale-service";
import { Scale } from "models/scales/scale";
import { ScaleModal } from "./scale-modal";
import { EducationalLevel } from "models/catalogs/educationalLevel";
import { AgeRange } from "models/catalogs/ageRange";
import { Genre } from "models/catalogs/genre";

import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";


interface SearchParams {
  Genre: Genre,
  EducationalLevel: EducationalLevel,
  AgeRange: AgeRange
}

@autoinject()
export class ScaleList {

  private thematicVariableId: number;

  private logger: Logger;
  private model: Scale = new Scale();
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private scaleService: ScaleService,
    private dialogController: DialogController,
    private dialogService: DialogService,
    private notification: Notification,
    private router: Router) {

    this.logger = LogManager.getLogger("ScaleList");

  }

  private async activate(params: any): Promise<void> {
    this.logger.debug("activate");
    if (!isNaN(params.thematicVariableId)) {
      this.thematicVariableId = +params.thematicVariableId;
      //this.parameters.search = <SearchParams>{ Genre: null, EducationalLevel: null, AgeRange: null };
    }
  }

  private async bind(): Promise<any> {
    return this.databind();
  }

  private async databind(): Promise<any> {
    if (this.thematicVariableId) {
      return await this.scaleService.list(this.thematicVariableId, this.parameters)
        .then((response: UxDatatableResponse) => {
          this.parameters.tableData = response.data;
          this.parameters.totalRecords = response.totalRecords;
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = (): Promise<UxDatatableResponse> => {
    // this.logger.info("refreshing");
    // this.logger.info("refreshing", this.parameters.search.Genre);
    return this.scaleService.list(this.thematicVariableId, this.parameters);
  }

  private async edit(item: Scale): Promise<void> {
    let orginialItem = JSON.parse(JSON.stringify(item));
    return await this.dialogService.open({
      viewModel: ScaleModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        // this.databind();
        return await this.scaleService.update(item)
          .then(() => {
            this.notification.success("notifications.item_added");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        Object.assign(item, orginialItem);
      }
    });
  }

  private async create(): Promise<void> {
    let item: Scale = new Scale();
    item.thematicVariableId = this.thematicVariableId;
    // item.genre = null;
    // item.educationalLevel = null;
    // item.ageRange = null;

    if (this.parameters.search.Genre >= 0) {
      item.genre = <Genre>this.parameters.search.Genre;      
    }

    if (this.parameters.search.EducationalLevel >= 0) {
      item.educationalLevel = <EducationalLevel>this.parameters.search.EducationalLevel;
    }

    if (this.parameters.search.AgeRange >= 0) {
      item.ageRange = <AgeRange>this.parameters.search.AgeRange;
    }

    return await this.dialogService.open({
      viewModel: ScaleModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        // this.databind();
        return await this.scaleService.create(item)
          .then(async (model: Scale) => {
            // item.id = model.id;

            // this.logger.debug("item: ", item.genre);

            this.parameters.tableData.unshift(model);
            this.parameters.totalRecords++;
            this.notification.success("notifications.item_added");
            // return await this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.scaleService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.questionaries.messages.questionary_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }
}
