import { UxDatatableParameters, UxDatatableResponse } from 'common/resources/elements/ux/datatable/ux-datatable';
import { Logger } from 'aurelia-logging';
import { autoinject, LogManager } from 'aurelia-framework';
import { EmailHistoryDetails } from 'models/emails-history/email-history-details';
import { AppHttpClient } from 'common/services/app-http-client';

@autoinject()
export class EmailHistoryService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient,
  ) {
    this.logger = LogManager.getLogger("EmailHistoryService");
  }

  public async list(toEmail: string, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`emailhistory/list/${toEmail}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: string): Promise<EmailHistoryDetails> {
    return await this.client.getById(`emailhistory`, id)
      .then((response: EmailHistoryDetails) => {
        return response;
      });
  }
}
