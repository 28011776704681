import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { CompanySettings } from "models/companies/company-settings";

@autoinject
export class CompanySettingsService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CompanySettingsService");
  }

  public async getById(id: number): Promise<CompanySettings> {
    return await this.client.getById("companysettings", id)
      .then((response: any) => {
        return <CompanySettings>response;
      });
  }

  public async create(model: CompanySettings): Promise<CompanySettings> {
    return await this.client.create("companysettings", model)
      .then((response: any) => {
        return <CompanySettings>response;
      });
  }

  public async update(model: CompanySettings): Promise<void> {
    return await this.client.updateById("companysettings", model.companyId, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("companysettings", id)
      .then(() => null);
  }

}
