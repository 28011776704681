import { ChangeStateAgreementConfirmation } from '../change-state-agreement-confirmation';
import { ObservationService } from '../../../services/agreements/observation-service';
import { NoteModal } from './note-modal';
import { LogManager, bindable } from 'aurelia-framework';
import { Notification } from 'common/services/notification';
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { AgreementService } from '../../../services/agreements/agreement-service';
import { Agreement } from 'models/agreements/agreement';
import { Logger } from 'aurelia-logging';
import { autoinject } from "aurelia-framework";
import { Router, RouteConfig } from 'aurelia-router';
import { Observation } from 'models/agreements/observation';
import { ObservationType } from 'models/agreements/observationType';
import { AgreementMasterService } from 'main/admin/services/agreements/agreement-master-service';
import { AgreementStatus } from 'models/agreements/agreementStatus';
import { PdfService } from 'main/admin/services/pdf/pdf-service';
import * as $ from 'jquery';

@autoinject()
export class AgreementReadonlyMode {

  private logger: Logger;
  private companyId: number;

  private model: Agreement = new Agreement();

  // private disable: boolean = false;

  @bindable
  private params: any = {};

  @bindable
  private isMaster: boolean = false;

  @bindable
  private isAttached: boolean = false;

  constructor(
    private agreementService: AgreementService,
    private agreementMasterService: AgreementMasterService,
    private dialogService: DialogService,
    private observationService: ObservationService,
    private pdfService: PdfService,
    private notification: Notification,
  ) {

    this.logger = LogManager.getLogger("AgreementReadOnlyMode");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // this.logger.debug("activate", params);
    this.params = params;

    // this.logger.debug("baseUrl:", routeConfig.navModel.router.baseUrl);
    this.isMaster = routeConfig.navModel.router.baseUrl.indexOf("companies-master") > 0;
  }

  public async attached(): Promise<any> {
    this.logger.debug("attached", this.params);

  }

  public async bind(): Promise<any> {

    this.logger.debug("bind", this.params);

    if (!isNaN(this.params.companyGroupId)) {
      this.companyId = +this.params.companyGroupId;
    }
    else if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
      this.companyId = null;
    }
    else if (!isNaN(this.params.companyDetailId)) {
      this.companyId = +this.params.companyDetailId;
    }

    if (this.companyId) {

      this.agreementService.client.currentCompanyId = this.companyId;

      if (!isNaN(this.params.agreementId)) {
        try {
          this.model = await this.agreementService.getReadOnlyById(+this.params.agreementId);
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    }
  }

  public async changeStatusToDraft(agreementstatus: string): Promise<any> {
    this.logger.info("changestatusdraft");
    return await this.dialogService.open({
      viewModel: ChangeStateAgreementConfirmation,
      model: agreementstatus
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        // this.model.agreementStatus = newstatus;
        try {
          await this.agreementService.changeStatusToDraft(this.model);
          await this.bind();
          this.notification.success("main.admin.pages.agreements.changestatus.notification");
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    });

  }

  public async changeStatusToReview(agreementstatus: string): Promise<any> {
    this.logger.info("changestatusreview");

    if (this.model.isComplete) {
      return await this.dialogService.open({
        viewModel: ChangeStateAgreementConfirmation,
        model: agreementstatus
      }).whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          // this.model.agreementStatus = newstatus;
          try {
            await this.agreementService.changeStatusToReview(this.model);
            // await this.bind();
            this.model.agreementStatus = AgreementStatus.ManagementReview;

            this.notification.success("main.admin.pages.agreements.changestatus.notification");
          }
          catch (error) {
            this.notification.error(error);
          }
        }
      });

    } else {
      this.notification.warning("main.admin.pages.agreements.changestatus.incompletedata");
    }
  }

  public async changeStatusToForwarded(agreementstatus: string): Promise<any> {
    this.logger.info("changestatusforwarded");

    if (this.model.isComplete) {
      return await this.dialogService.open({
        viewModel: ChangeStateAgreementConfirmation,
        model: agreementstatus
      }).whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          // this.model.agreementStatus = newstatus;
          try {
            await this.agreementService.changeStatusToForwarded(this.model);
            // await this.bind();
            this.model.agreementStatus = AgreementStatus.ForwardedClient;

            this.notification.success("main.admin.pages.agreements.changestatus.notification");
          }
          catch (error) {
            this.notification.error(error);
          }
        }
      });

    } else {
      this.notification.warning("main.admin.pages.agreements.changestatus.incompletedata");
    }
  }

  public async changeStatusToApproved(agreementstatus: string): Promise<any> {
    this.logger.info("changestatusapproved");

    if (this.model.isComplete) {
      return await this.dialogService.open({
        viewModel: ChangeStateAgreementConfirmation,
        model: agreementstatus
      }).whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          // this.model.agreementStatus = newstatus;
          try {
            await this.agreementMasterService.changeStatusToApproved(this.model);
            // await this.bind();
            this.model.agreementStatus = AgreementStatus.Approved;

            this.notification.success("main.admin.pages.agreements.changestatus.notification");
          }
          catch (error) {
            this.notification.error(error);
          }
        }
      });

    } else {
      this.notification.warning("main.admin.pages.agreements.changestatus.incompletedata");
    }
  }

  public async changeStatusToValid(agreementstatus: string): Promise<any> {
    this.logger.info("changestatusvalid");

    if (this.model.isComplete) {

      return await this.dialogService.open({
        viewModel: ChangeStateAgreementConfirmation,
        model: agreementstatus
      }).whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          // this.model.agreementStatus = newstatus;
          try {
            await this.agreementService.changeStatusToValid(this.model)
            // await this.bind();
            this.model.agreementStatus = AgreementStatus.Valid;

            this.notification.success("main.admin.pages.agreements.changestatus.notification");
          }
          catch (error) {
            this.notification.error(error);
          }
        }
      });

    } else {
      this.notification.warning("main.admin.pages.agreements.changestatus.incompletedata");
    }
  }

  public async changeStatusToCancelled(agreementstatus: string): Promise<any> {
    this.logger.info("changestatuscancelled");

    return await this.dialogService.open({
      viewModel: ChangeStateAgreementConfirmation,
      model: agreementstatus
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        //this.model.agreementStatus = newstatus;
        try {
          await this.agreementService.changeStatusToCancelled(this.model);
          // await this.bind();
          this.model.agreementStatus = AgreementStatus.Cancelled;

          this.notification.success("main.admin.pages.agreements.changestatus.notification");
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    });

  }

  private async pdfExport(): Promise<void> {
    let html = $("#agreementContent").html();

    let style = $("head").html();
    try {
      return this.pdfService.print(style+html, this.model.description);
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async requestChanges(): Promise<void> {
    let item: Observation = new Observation();
    item.agreementId = this.model.id;

    return await this.dialogService.open({
      viewModel: NoteModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        try {         

          let note: Observation = await this.observationService.createPublicNote(item);
          item.id = note.id;

          // await this.agreementService.changeStatusToDraft(this.model);
          // await this.bind();
          this.model.agreementStatus = AgreementStatus.InternalDraft;

          this.notification.success("main.admin.pages.agreements.note.notifications.added");
          // this.notification.success("main.admin.pages.agreements.changestatus.notification");
        }
        catch (error) {
          this.notification.error(error);
        }
      }

    });
  }

  public async resendApprove(): Promise<any> {
    if (this.model.isComplete) {
      try {
        await this.agreementService.resendToApprove(this.model);
        // await this.bind();
        this.notification.success("main.admin.pages.agreements.requestapprove.notification");
      }
      catch (error) {
        this.notification.error(error);
      }
    } else {
      this.notification.warning("main.admin.pages.agreements.changestatus.incompletedata");
    }
  }

}
