import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
  ControllerValidateResult
} from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { OtherPlanItem } from 'models/plans/other-plan-item';

@autoinject()
export class OtherPlanItemModal {

  private model: OtherPlanItem = new OtherPlanItem();
  private validationController: ValidationController;
  private logger: Logger;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("TestPlanItemModal");
  }

  private async activate(model: OtherPlanItem): Promise<any> {
    this.logger.debug("model", model);
    this.model = model;
    this.configureValidationRules();
  }

  configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("otherPlanItemType").displayName("main.admin.pages.plans.other_plan_items.other_plan_item_type").required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    // this.validationController.reset();
    return await this.dialogController.ok();
  }

}
