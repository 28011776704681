import { DialogController } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { FileAcceptValidator } from "common/utils/file-accept-validator";
import { Sheet } from "models/test-core/sheets/sheet";
import { SheetLanguage } from "models/test-core/sheets/sheetLanguage";

@autoinject()
export class SheetLanguageModal {

  private model: SheetLanguage = new SheetLanguage();
  private logger: Logger;

  public name: string;
  public description: string;

  constructor(
    private notification: Notification,
    private dialogController: DialogController) {

    this.logger = LogManager.getLogger("SheetLanguageModal");

  }

  async activate(model: Sheet): Promise<void> {
    this.logger.debug("activate");
    this.model = model.sheetLanguages[0];
    this.name = model.name;
    this.description = model.description;
  }


  public setThumbnailImage($event: any): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];

    // this.logger.debug("file", file);

    let fileValidator = FileAcceptValidator.parse("image/*", 1024 * 300);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.model.thumbnailImageFileName = file.name;
        this.model.thumbnailImageUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.test_core.sheets.errors.error_bad_image");

    }
  }

  public setImage($event: any): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];

    // this.logger.debug("file", file);

    let fileValidator = FileAcceptValidator.parse("image/*", 1024 * 500);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.model.imageFileName = file.name;
        this.model.imageUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.test_core.sheets.errors.error_bad_image");

    }
  }
}
