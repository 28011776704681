import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { MessageSummaryReport } from "models/messages/message-summary-report";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";

@autoinject
export class MessageSummaryService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("MessageSummaryService");
  }

  public async get(): Promise<MessageSummaryReport> {
    return await this.client.get("messagesummaryreport")
      .then((response: any) => {
        return <MessageSummaryReport>response;
      });
  }

  public async create(model: MessageSummaryReport): Promise<MessageSummaryReport> {
    return await this.client.create("messagesummaryreport", model)
      .then((response: any) => {
        return <MessageSummaryReport>response;
      });
  }

  public async update(model: MessageSummaryReport): Promise<void> {
    return await this.client.update("messagesummaryreport", null, model)
      .then(() => null);
  }

}
