import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { AuthorizationService } from 'aurelia-permission';
import PredefinedPermissions from "config/predefined-permissions";
import { Router, RouterConfiguration } from "aurelia-router";
import { I18N } from "aurelia-i18n";

@autoinject
export class BulkDownloadListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {

    this.logger = LogManager.getLogger("BulkDownloadListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./bulk-download-list"),
        name: "bulk-downloads",
        //nav: true,
        //title: this.i18n.tr("main.test.pages.bulk_downloads.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestPersonBulkDownloadView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestPersonBulkDownloadView]
          },
         // info: this.i18n.tr("main.admin.pages.test_core.company_competences.info")
        }
      },

      {
        route: "create",
        moduleId: PLATFORM.moduleName("./bulk-download-create"),
        name: "bulk-download-create",
        // nav: true,
        // title: this.i18n.tr("main.test.pages.bulk_download.page_title"),
      },
      
      {
        route: "convert-bulkactivation/:bulkActivationId",
        moduleId: PLATFORM.moduleName("./bulk-activation-converted"),
        name: "convert-bulkactivation",
      },

    ]);
  }
}
