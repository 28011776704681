import { ViewEngineHooks, View } from 'aurelia-framework';
import { MessagePeriod } from './message-period';

export class MessagePeriodViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['MessagePeriod'] = MessagePeriod;

    view.overrideContext['MessagePeriods'] = 
      Object.keys(MessagePeriod)
        .filter((key) => typeof MessagePeriod[key] === 'number');
  }
}
