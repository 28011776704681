import { LogManager, autoinject } from "aurelia-framework";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { ArchetypeLocalization } from "models/archetypes/archetype-localization";
import { AppHttpClient } from "common/services/app-http-client";
import { CatalogBase } from "models/catalogs/catalog-base";

@autoinject()
export class ArchetypeLocalizationService{
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("ArchetypeService");
  }

  public async getAll(): Promise<ArchetypeLocalization[]> {
    return await this.client.get("archetypelocalization")
      .then((response: any) => {
        return <ArchetypeLocalization[]>response;
      });
  }

  public async getById(id: number): Promise<ArchetypeLocalization> {
    return await this.client.getById("archetypelocalization", id)
      .then((response: any) => {
        return <ArchetypeLocalization>response;
      });
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("archetypelocalization/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
 
  public async create(model: ArchetypeLocalization): Promise<ArchetypeLocalization> {
    return await this.client.create("archetypelocalization", model)
      .then((response: ArchetypeLocalization) => {
        return response;
      });
  }

  public async update(model: ArchetypeLocalization): Promise<void> {
    return await this.client.updateById("archetypelocalization", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("archetypelocalization", id)
      .then(() => null);
  }
   
}
