import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Scale } from "models/scales/scale";
import { ScaleService } from "../../services/scales/scale-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";

@autoinject()
export class ScaleModal {

  private model: Scale = new Scale();
  private thematicGroupId: number;

  private validationController: ValidationController;
  private logger: Logger;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private scaleService: ScaleService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ScaleModal");
  }

  private async activate(model: Scale): Promise<void> {
    this.logger.debug("activate");
    this.model = model;
    this.configureValidationRules();
  }

  configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("rawValuesRange").displayName("main.admin.pages.scales.raw_score").required().matches(/^\d+-\d+$/)
      .ensure("equivalentValue").displayName("main.admin.pages.scales.equivalent_score").required().matches(/^[1-9][0-9]?$|^100$/)
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();
  }

}
