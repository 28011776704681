export class ResponseOption {
    id: number;
    order: number;
    option?: string;
    media: string;
    isColumn: boolean;
    questionId: number;
    thumbnailImage: string;
    thumbnailImageUrl: string;
}
