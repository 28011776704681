
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject
export class EditNavegationChildRouter {

  private logger: Logger;

  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("EditNavegationChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: ["","edit"],
        moduleId: PLATFORM.moduleName("./agreement-edit"),
        name: "agreement-edit",
        nav: true,
        title: this.i18n.tr("navigations.basic_info")        
      },

      {
        route: 'agreements-terms-pay',
        moduleId: PLATFORM.moduleName('./agreement-terms-pay-edit'),
        name: 'agreements-terms-pay',
        nav: true,
        title: this.i18n.tr("main.admin.pages.agreements.agreementtermspay.title"),
      },

      {
        route: 'agreements-customer-req',
        moduleId: PLATFORM.moduleName('./agreement-customer-req'),
        name: 'agreements-customer-req',
        nav: true,
        title: this.i18n.tr("main.admin.pages.agreements.agreementcustomerreq.title"),
      },

      {
        route: 'activities-list',
        moduleId: PLATFORM.moduleName('./activity-list'),
        name: 'activities-list',
        nav: true,
        title: this.i18n.tr("main.admin.pages.agreements.activity.workplan"),
      },

      {
        route: 'file-list',
        moduleId: PLATFORM.moduleName('./agreement-file-list'),
        name: 'file-list',
        nav: true,
        title: this.i18n.tr("main.admin.pages.agreements.agreement_file.title"),
      },

      {
        route: 'terms-conditions',
        moduleId: PLATFORM.moduleName('./terms-conditions-edit'),
        name: 'terms-conditions',
        nav: true,
        title: this.i18n.tr("main.admin.pages.agreements.termsandconditions.title"),
      },

      {
        route: "readonly",
        moduleId: PLATFORM.moduleName("../readonly/readonly-child-router"),
        name: "agreement-readonly-mode",
        nav: false,
        title: this.i18n.tr("main.admin.pages.agreements.initialAgreement")
      }
    ]);
    }
}
