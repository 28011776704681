
import { I18N } from 'aurelia-i18n';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration, RouteConfig } from "aurelia-router";
import { PLATFORM } from "aurelia-pal";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { CoreCompanyService } from './services/companies/core-company-service';
import { CompanySettings } from 'models/companies/company-settings';
import { Role } from 'models/security/role';

@autoinject()
export class AdminChildRouter {

  private logger: Logger;

  private router: Router;

  private state: State;

  constructor(
    private store: StoreService,
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("AdminChildRouter");

    this.state = this.store.state;
  }

  public async configureRouter(config: RouterConfiguration, router: Router) {

    let companySettings = this.getCompanySettings();

    // this.logger.debug("companySettings", companySettings);

    this.router = router;

    config.map([
      {
        route: ["", "admin-home"],
        moduleId: PLATFORM.moduleName("./pages/home/home"),
        name: "admin-home",
        nav: true,
        title: this.i18n.tr("main.admin.pages.home.title"),
        settings: {
          icon: "ticon-modulo-admin",
          isHome: true
        }
      },

      {
        route: "groups",
        moduleId: PLATFORM.moduleName("./pages/groups/group-list-child-router"),
        name: "group-list-child-router",
        title: this.i18n.tr("main.admin.pages.groups.title"),
        nav:
          this.authorizationService.isAuthorized(PredefinedPermissions.GroupView)
          && companySettings.hasGroupManagement,
        settings: {
          permission: {
            only: [PredefinedPermissions.GroupView]
          },
          icon: "ticon-grupos-usuarios-internos"
        }
      },

      {
        route: "profiles",
        moduleId: PLATFORM.moduleName("./pages/profiles/profile-list-child-router"),
        name: "profile-list-child-router",
        title: this.i18n.tr("main.admin.pages.profiles.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ProfileView)
          && companySettings.hasProfileManagement,
        settings: {
          permission: {
            only: [PredefinedPermissions.ProfileView]
          },
          icon: "ticon-roles"
        }
      },

      {
        route: "users",
        moduleId: PLATFORM.moduleName("./pages/users/user-list-child-router"),
        name: "user-list-child-router",
        title: this.i18n.tr("main.admin.pages.users.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.UserView)
          && companySettings.hasUserManagement,
        settings: {
          permission: {
            only: [PredefinedPermissions.UserView]
          },
          icon: "ticon-usuarios-internos"
        }
      },

      {
        route: "companies-master",
        moduleId: PLATFORM.moduleName("./pages/company-groups-master/company-group-master-list-child-router"),
        name: "company-group-master-list-child-router",
        title: this.i18n.tr("main.admin.pages.companies_master.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CompanyMasterView),
        settings: {
          permission: {
            only: [PredefinedPermissions.CompanyMasterView]
          },
          icon: "ticon-gestion-org"
        }
      },

      {
        route: "talents",
        moduleId: PLATFORM.moduleName("./pages/talents/talent-list-child-router"),
        name: "talent-list-child-router",
        title: this.i18n.tr("main.admin.pages.talents.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TalentView)
          && companySettings.hasTalentManagement,
        settings: {
          permission: {
            only: [PredefinedPermissions.TalentView]
          },
          icon: "ticon-gestion-talentos"
        }
      },

      {
        route: "test-management",
        moduleId: PLATFORM.moduleName("./pages/test-management/test-management-child-router"),
        name: "test-management-child-router",
        title: this.i18n.tr("main.admin.pages.test_management.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementReportView)
          || this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementQuestionaryGroupView)
          || this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementDiscProfileView)
          || this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementDiscStyleView)
          || this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementQuestionaryConfigurationView)
          || this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementCustomCompanyQuestionaryView)
          || this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementCompetencesView),
        settings: {
          icon: "ticon-modulo-test"
        }
      },     
      {
        route: "messages",
        moduleId: PLATFORM.moduleName("./pages/messages/message-list-child-router"),
        name: "message-list-child-router",
        title: this.i18n.tr("main.admin.pages.messages.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.MessageView)
          && companySettings.hasNotificationManagement,
        settings: {
          permission: {
            only: [PredefinedPermissions.MessageView]
          },
          icon: "ticon-gestion-notif"
        }
      },

      {
        route: "user-api-applications",
        moduleId: PLATFORM.moduleName("./pages/user-api-applications/user-api-application-list-child-router"),
        name: "user-api-application-list-child-router",
        title: this.i18n.tr("main.admin.pages.user_api_applications.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.UserApiTokenView)
          && companySettings.hasUserManagement,
        settings: {
          permission: {
            only: [PredefinedPermissions.UserApiTokenView]
          },
          icon: "ticon-API"
        }
      },
      

      // core functionalities

      {
        route: "test-core",
        moduleId: PLATFORM.moduleName("./pages/test-core/test-core-child-router"),
        name: "test-core-child-router",
        title: this.i18n.tr("main.admin.pages.test_core.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.SheetView),
        settings: {
          icon: "ticon-admin-contenidos"
        }
      },
      {
        route: "inbox",
        moduleId: PLATFORM.moduleName("./pages/test-core/inbox/inbox-by-user/inbox-user-list-child-router"),
        name: "inbox",
        title: this.i18n.tr("main.admin.pages.inbox.title"),
        nav: false,
        settings: {
          icon: "ticon-modulo-test"
        }
      },


      {
        route: "core-groups",
        moduleId: PLATFORM.moduleName("./pages/core-groups/core-group-list-child-router"),
        name: "core-group-list-child-router",
        title: this.i18n.tr("main.admin.pages.core_groups.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CoreGroupView),
        settings: {
          permission: {
            only: [PredefinedPermissions.CoreGroupView]
          },
          icon: "ticon-grupos-usuarios-internos"
        }
      },

      {
        route: "core-users",
        moduleId: PLATFORM.moduleName("./pages/core-users/core-user-list-child-router"),
        name: "core-user-list-child-router",
        title: this.i18n.tr("main.admin.pages.core_users.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CoreUserView),
        settings: {
          permission: {
            only: [PredefinedPermissions.CoreUserView]
          },
          icon: "ticon-usuarios-internos"
        }
      },

      {
        route: "companies",
        moduleId: PLATFORM.moduleName("./pages/company-groups/company-group-franchise-list-child-router"),
        name: "company-group-franchise-list-child-router",
        title: this.i18n.tr("main.admin.pages.company_groups.select_franchise"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.CompanyGroupView),
        settings: {
          permission: {
            only: [PredefinedPermissions.CompanyGroupView]
          },
          icon: "ticon-organizaciones-grupos"
        }
      },

      {
        route: "plans",
        moduleId: PLATFORM.moduleName("./pages/plans/plan-franchise-list-child-router"),
        name: "plan-franchise-list-child-router",
        title: this.i18n.tr("main.admin.pages.plans.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.PlanView),
        settings: {
          permission: {
            only: [PredefinedPermissions.PlanView]
          },
          icon: "ticon-gestion-planes"
        }
      },

      {
        route: "messages-franchises",
        moduleId: PLATFORM.moduleName("./pages/messages/message-franchise-list-child-router"),
        name: "message-franchise-list-child-router",
        title: this.i18n.tr("main.admin.pages.messages.franchise.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.FranchiseView),
        settings: {
          permission: {
            only: [PredefinedPermissions.FranchiseView]
          },
          icon: "ticon-notificaciones-alarmas"
        }
      },

      {
        route: "questionaries",
        moduleId: PLATFORM.moduleName("./pages/questionaries/questionary-list-child-router"),
        name: "questionary-list-child-router",
        title: this.i18n.tr("main.admin.pages.questionaries.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.QuestionaryView),
        settings: {
          permission: {
            only: [PredefinedPermissions.QuestionaryView]
          },
          icon: "ticon-cuestionarios"
        }
      },

      {
        route: "thematic-groups",
        moduleId: PLATFORM.moduleName("./pages/thematic-groups/thematic-group-questionary-list-child-router"),
        name: "thematic-group-questionary-list-child-router",
        title: this.i18n.tr("main.admin.pages.thematic_groups.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.QuestionaryView),
        settings: {
          permission: {
            only: [PredefinedPermissions.QuestionaryView]
          },
          icon: "ticon-gestion-grupos-tema"
        }
      },

      {
        route: "scales",
        moduleId: PLATFORM.moduleName("./pages/scales/scale-questionary-list-child-router"),
        name: "scale-questionary-list-child-router",
        title: this.i18n.tr("main.admin.pages.scales.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ScaleView),
        settings: {
          permission: {
            only: [PredefinedPermissions.ScaleView]
          },
          icon: "ticon-baremos2"
        }
      },
      
      {
        route: "archetypes",
        moduleId: PLATFORM.moduleName("./pages/archetypes/archetype-list-child-router"),
        name: "archetype-list-child-router",
        title: this.i18n.tr("main.admin.pages.archetypes.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ArchetypeView),
        settings: {
          permission: {
            only: [PredefinedPermissions.ArchetypeView]
          },
          icon: "ticon-perfiles-referencia"
        }
      },
      {
        route: "reference-position",
        moduleId: PLATFORM.moduleName("./pages/position-reference/position-reference-list-child-router"),
        name: "position-reference-list-child-router",
        title: this.i18n.tr("main.test.pages.position.position_reference_list.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ArchetypeView),
        settings: {
          permission: {
            only: [PredefinedPermissions.ArchetypeView]
          },
          icon: "ticon-perfiles-referencia"
        }
      },

      {
        route: "educational-levels-by-country",
        moduleId: PLATFORM.moduleName("./pages/educational-levels-by-country/educational-level-country-list-child-router"),
        name: "educational-level-country-list-child-router",
        title: this.i18n.tr("main.admin.pages.educational_levels_country.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.EducationalLevelCountryView),
        settings: {
          permission: {
            only: [PredefinedPermissions.EducationalLevelCountryView]
          },
          icon: "ticon-nivel-educativo2"
        }
      },

      {
        route: "habeas-data-by-country",
        moduleId: PLATFORM.moduleName("./pages/habeas-data-by-country/habeas-data-country-list-child-router"),
        name: "habeas-data-country-list-child-router",
        title: this.i18n.tr("main.admin.pages.habeas_data_country.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.EducationalLevelCountryUpdate),
        settings: {
          permission: {
            only: [PredefinedPermissions.EducationalLevelCountryUpdate]
          },
          icon: "ticon-baremos2"
        }
      },

      {
        route: "languages",
        moduleId: PLATFORM.moduleName("./pages/languages/language-list-child-router"),
        name: "language-list-child-router",
        title: this.i18n.tr("main.admin.pages.languages.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.LanguageView),
        settings: {
          permission: {
            only: [PredefinedPermissions.LanguageView]
          },
          icon: "ticon-localizaciones"
        }
      },

      {
        route: "labels",
        moduleId: PLATFORM.moduleName("./pages/labels/label-list"),
        name: "labels",
        title: this.i18n.tr("main.admin.pages.labels.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.LanguageView),
        settings: {
          permission: {
            only: [PredefinedPermissions.LanguageView]
          },
          icon: "ticon-localizaciones"
        }
      },     
    ]);

  }

  private getCompanySettings(): CompanySettings {

    let settings = new CompanySettings();

    settings.hasGroupManagement = true;
    settings.hasUserManagement = true;
    settings.hasProfileManagement = true;
    settings.hasTalentManagement = true;
    settings.hasNotificationManagement = true;
    settings.hasPreferenceManagement = true;

    if (this.state
      && this.state.userBasicInfo
      && this.state.userBasicInfo.roleId >= Role.Master
      && this.state.currentCompany
      && this.state.currentCompany.companySettings) {

      settings = this.state.currentCompany.companySettings;

    }

    return settings;
  }

}
