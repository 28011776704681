/**
 * Created by istrauss on 11/15/2016.
 */

// import './aurelia-crumbs.css';
// import _clone from 'lodash/clone';
import { EventAggregator } from 'aurelia-event-aggregator';
import { inject, bindable, customElement, LogManager } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { NavigationInstruction } from 'aurelia-router';
import { Subscription } from 'aurelia-event-aggregator';
import { Logger } from 'aurelia-logging';

@inject(Router, EventAggregator)
@customElement("ux-aurelia-crumbs")
export class UxAureliaCrumbsCustomElement {

  private logger: Logger;

  private subscription: Subscription;
  private instructions;

  /**
   * Configuration object
   * @type {{seperator: 'htmlString'}}
   */

  @bindable
  private config = {};

  constructor(
    private router: Router,
    private ea: EventAggregator) {

    this.logger = LogManager.getLogger("UxBreadcrumbsCustomElement");
  }

  attached() {
    this.subscription = this.ea.subscribe('router:navigation:success', this.refresh.bind(this));
    this.refresh();
  }

  detached() {
    this.subscription.dispose();
  }

  /**
   * Refresh the rendered widget
   */
  refresh() {
    // this.logger.debug("refresh");
    const parentInstructions = this.getParentInstructions(this.router.currentInstruction);
    this.instructions = parentInstructions
      .slice(0, parentInstructions.length - 1)
      .concat(this.router.currentInstruction.getAllInstructions())
      .filter(instruction => instruction.config.title);

    // this.logger.debug("instrucctions: ", this.instructions);
  }

  navigateToRoute(instruction: NavigationInstruction) {    
    const params =  Object.assign({}, instruction.params); //_clone(instruction.params); 
    // this.logger.debug("params.childRoute:", params.childRoute);
    delete params.childRoute;
    // this.logger.debug("instruction:", instruction.config.name);
    instruction.router.navigateToRoute(instruction.config.name, params);
  }

  getParentInstructions(instruction) {    
    return instruction.parentInstruction ? this.getParentInstructions(instruction.parentInstruction).concat([instruction]) : [instruction];
  }
}
