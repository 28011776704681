import { Activation } from 'models/test-person/activation';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { TeamPerson } from "models/team/teamPerson";

@autoinject()
export class TeamActivationService {


  constructor(
    public client: CompanyAppHttpClient) {
  }

  public async list(parameters: UxDatatableParameters, teamId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`TeamActivation/list/${teamId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }
  public async create(model: Activation, teamId: number): Promise<Activation> {
    return await this.client.create(`teamactivation/${teamId}`, model)
      .then((response: any) => {
        return <Activation>response;
      });
  }

}
