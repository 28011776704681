import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { TeamPerson } from "models/team/teamPerson";

@autoinject()
export class TeamPersonService {


  constructor(
    public client: CompanyAppHttpClient) {
  }

  public async peopleList(parameters: UxDatatableParameters, teamId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`TeamPerson/people-list/${teamId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async list(parameters: UxDatatableParameters, teamId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`TeamPerson/list/${teamId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number, teamId: number): Promise<TeamPerson> {
    return await this.client.getById(`TeamPerson/${teamId}`, id)
      .then((response: TeamPerson) => {
        return response;
      });
  }

  public async create(model: TeamPerson, teamId: number): Promise<TeamPerson> {
    return await this.client.create(`TeamPerson/${teamId}`, model)
      .then((response: any) => {
        return <TeamPerson>response;
      });
  }

  public async update(model: TeamPerson): Promise<void> {
    return await this.client.updateById(`TeamPerson`, model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`TeamPerson`, id)
      .then(() => null);
  }
}
