import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { ProgrammingLanguage } from "models/test-perfiltic/programmingLanguage";


@autoinject()
export class PerfilticService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("PerfilticService");
  }
  
  public async getAll(): Promise<ProgrammingLanguage[]> {
    return await this.client.get("perfiltic/get-programming-language")
      .then(response => {
        return <ProgrammingLanguage[]>response;
      });
  }

}
