import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { CatalogService } from "../../../services/catalogs/catalog-service";
import { CatalogBase } from "models/catalogs/catalog-base";
import { HabeasDataCountry } from 'models/habeas-data-by-country/habeas-data-country';
import { HabeasDataCountryService } from 'main/admin/services/habeas-data-by-country/habeas-data-country-service';
import { Language } from 'models/localizations/language';
import { LanguageService } from '../../services/localizations/language-service';
import { ValidationController, ValidationControllerFactory, ValidationRules } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class HabeasDataCountryList {
  private logger: Logger;
  private countries: CatalogBase[] = [];
  private language: Language[] = [];
  private model: HabeasDataCountry;
  private validationController: ValidationController;


  constructor(
    private catalogService: CatalogService,
    private languageService: LanguageService,
    private habeasDataCountryService: HabeasDataCountryService,
    private validationControllerFactory: ValidationControllerFactory,
    private notification: Notification) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.logger = LogManager.getLogger("HabeasDataCountryList");

  }

  private async activate(): Promise<void> {
    try {
      this.countries = await this.catalogService.getCountryAll();

      this.language = await this.languageService.getAll();

      this.model = new HabeasDataCountry();
      this.configureValidationRules();


      if (this.countries.length > 0) {
        this.model.countryId = this.countries[0].id;
        this.model.languageId = this.language[0].id;
        return await this.databind();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private configureValidationRules(): void {
    this.logger.debug("model", this.model);
    ValidationRules
      .ensure("habeasData").displayName("main.admin.pages.agreements.description").required()
      .on(this.model);
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    await this.refresh();

  }

  private async cancel() {
    this.notification.warning("main.admin.pages.habeas_data_country.notifications.cancel");
    this.refresh();
  }

  private async refresh (): Promise<any> {
    this.model.habeasData = null;
    this.model = await this.habeasDataCountryService.getByCountryAndLanguage(this.model);
  }

  private async submit(): Promise<any> {
    try {
      await this.habeasDataCountryService.updateOrCreate(this.model);
      this.notification.success("main.admin.pages.habeas_data_country.notifications.edited_or_created");
    } catch (error) {
      this.notification.error(error);
    }
  }
}
