
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject
export class PersonChildRouter {

  private logger: Logger;

  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("PersonChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./person-edit"),
        name: "person-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.people.person.title"),
      },

      {
        route: "resume",
        moduleId: PLATFORM.moduleName("../people-resume/people-resume-edit"),
        name: "people-resume-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.person.person_tabs.tab_resume"),
      },   
     
      {
        route: "videointerview",
       moduleId: PLATFORM.moduleName("../people-videointerview/people-videointerview-child-router"),
        name: "people-videointerview-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.people.people_videointerview_edit.video_interview")      
       },

       {
        route: "emailhistory",
       moduleId: PLATFORM.moduleName("../people-history-email/people-history-email-list"),
        name: "people-history-email-list",
        nav: true,
        title: this.i18n.tr("main.test.pages.people.people_history_email.email_history")      
       },

    ]);
  }
}
