import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ReportTemplate } from "models/test-core/report-templates/report-template";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class ReportTemplatePredictorService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("ReportTemplatePredictorService");
  }

  public async getAll(): Promise<ReportTemplate[]> {
    return await this.client.get("reporttemplate")
      .then((response: ReportTemplate[]) => {
        return response;
      });
  }


  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("reporttemplatepredictor/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<ReportTemplate> {
    //  this.client.post(`categorysheet/list/${reportTemplateId}/${languageId}`, { search })
    return await this.client.getById("reporttemplatepredictor", id)
      .then((response: any) => {
        return <ReportTemplate>response;
      });
  }

  public async create(model: ReportTemplate): Promise<ReportTemplate> {
    return await this.client.create("reporttemplatepredictor", model)
      .then((response: any) => {
        return <ReportTemplate>response;
      });
  }

  public async update(model: ReportTemplate): Promise<void> {
    return await this.client.updateById("reporttemplatepredictor", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("reporttemplatepredictor", id)
      .then(() => null);
    }
  
    public async changeState(languageId: number, hasPredictor: boolean): Promise<void> {
      return await this.client.post(`reporttemplatepredictor/change-state/${languageId}/${hasPredictor}`)
        .then(() => null);
  }

}
