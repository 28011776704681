import { Company } from './company';

export class CompanyGroupMaster {
  id: number;
  franchiseName: string;
  company: Company = new Company();
  companyId: number;
  economicSectorName: string;
  countryName: string;
  regionName: string;
  cityName: string;  
}
