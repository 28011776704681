import { autoinject } from 'aurelia-framework';
import { DialogController } from "aurelia-dialog";

@autoinject()
export class FilteredReminderConfirmationModal {
  private quantity: number;

  constructor(private dialogController: DialogController) {
  }

  public activate(quantity: number) {
    this.quantity = quantity;
  }
}
