
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";

@autoinject
export class PlanFranchiseListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {
    this.logger = LogManager.getLogger("PlanFranchiseListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;    
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./plans-home"),
        name: "plans-home",
        title: this.i18n.tr("main.admin.pages.plans_management.title"),
        settings: {
          isHome: true
        }
      },
             
      {
        route: "plan-franchise",
        moduleId: PLATFORM.moduleName("./plan-franchises"),
        name: "select-franchise",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.PlanView),
        title: this.i18n.tr("main.admin.pages.plans.franchise_plan"),
        settings:{
          permission: {
            only: [PredefinedPermissions.PlanView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.plan_franchise.info")
        }
      },

      {
        route: "plan-selection",
        moduleId: PLATFORM.moduleName("./plan-selection-franchises"),
        name: "plan-select-franchise",        
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.PlanView),
        title: this.i18n.tr("main.test.pages.selection_plans"),
        settings:{
          permission: {
            only: [PredefinedPermissions.PlanView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.plan_selection.info")
        }
      },

      {
        route: "franchise/:franchiseId/is-selection-plan/:isRobotHunter",
        moduleId: PLATFORM.moduleName("./plan-list-child-router"),
        name: "plan-list-child-router",
        nav: false,
        title: this.i18n.tr("main.admin.pages.plans.plans"),
      },
      
    ]);
  }
}
