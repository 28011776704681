import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Result } from "models/test-result/result";

@autoinject()
export class ResultService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("ResultService");
  }

  // public async list ( parameters: UxDatatableParameters ): Promise<UxDatatableResponse> {
  //   return await this.client.post( "Position/list", parameters )
  //     .then( ( response: any ) => {
  //       return <UxDatatableResponse> response;
  //     } );
  // }

  // public async getAll (): Promise<Position[]> {
  //   return await this.client.get( "Position" )
  //     .then( ( response: any ) => {
  //       return <Position[]> response;
  //     } );
  // }

  // public async getById ( id: number ): Promise<Position> {
  //   return await this.client.getById( "Position", id )
  //     .then( ( response: Position ) => {
  //       return response;
  //     } );
  // }

  // public async create ( model: Position ): Promise<Position> {
  //   return await this.client.create( "Position", model )
  //     .then( ( response: any ) => {
  //       return <Position> response;
  //     } );
  // }

  // public async update ( model: Position ): Promise<void> {
  //   return await this.client.updateById( "Position", model.id, null, model )
  //     .then( () => null );
  // }

  public async getAllByPositionId(positionId: number): Promise<Result[]> {
    return await this.client.get(`result/position/${positionId}`)
      .then((response: any) => {
        return <Result[]>response;
      });
  }

  public async createToPosition(model: Result): Promise<Result> {
    return await this.client.create(`result/position/${model.positionId}`, model)
      .then((response: any) => {
        return <Result>response;
      });
  }

  public async getAllByOrganizationId(organizationId: number): Promise<Result[]> {
    return await this.client.get(`result/organization/${organizationId}`)
      .then((response: any) => {
        return <Result[]>response;
      });
  }

  public async createToOrganization(model: Result): Promise<Result> {
    return await this.client.create(`result/organization/${model.organizationId}`, model)
      .then((response: any) => {
        return <Result>response;
      });
  }

  public async getAllByPersonId(personId: number, activationId: number): Promise<Result[]> {
    return await this.client.get(`result/person/${personId}/${activationId}`)
      .then((response: any) => {
        return <Result[]>response;
      });
  }

  public async createToPerson(model: Result): Promise<Result> {
    return await this.client.create(`result/person/${model.personId}/${model.activationId}`, model)
      .then((response: any) => {
        return <Result>response;
      });
  }
}
