export default {
  URLS_BACKEND: process.env.URLS_BACKEND || window.env.URLS_BACKEND,
  URLS_FRONTEND: process.env.URLS_FRONTEND || window.env.URLS_FRONTEND,
  URLS_MICROSITE: process.env.URLS_MICROSITE || window.env.URLS_MICROSITE,
  URLS_REPORTS: process.env.URLS_REPORTS || window.env.URLS_REPORTS,
  URLS_PDF_PRINTER: process.env.URLS_PDF_PRINTER || window.env.URLS_PDF_PRINTER,
  URLS_PREDICTOR: process.env.URLS_PREDICTOR || window.env.URLS_PREDICTOR,
  URLS_ROBOTHUNTER: process.env.URLS_ROBOTHUNTER || window.env.URLS_ROBOTHUNTER,
  KEY_CRYPTO: process.env.KEY_CRYPTO || window.env.KEY_CRYPTO,
  KEY_PLUS: process.env.KEY_PLUS || window.env.KEY_PLUS,
  KEY_SLASH: process.env.KEY_SLASH || window.env.KEY_SLASH,
  KEY_EQUAL: process.env.KEY_EQUAL || window.env.KEY_EQUAL,
}
