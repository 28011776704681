
import { autoinject, LogManager, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";

import {
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
  ControllerValidateResult
} from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { ThematicGroupService } from "../../services/thematic-groups/thematic-group-service";
import { ThematicGroup } from "models/thematic-groups/thematic-group";
import { QuestionService } from "../../services/questionaries/question-service";
import { Question } from "models/questions/question";

interface ItemSetMap {
  [id: number]: boolean;
}

@autoinject()
export class ThematicGroupEdit {

  private logger: Logger;
  private validationController: ValidationController;

  private model: ThematicGroup = new ThematicGroup();

  private questions: Question[];
  private leftQuestions: Question[] = [];
  private rightQuestions: Question[] = [];

  @observable
  private leftSearch: string = "";

  @observable
  private rightSearch: string = "";

  private questionsToInclude: ItemSetMap = {};
  private questionsToExclude: ItemSetMap = {};

  private selectAllLeft: boolean = false;
  private selectAllRight: boolean = false;

  constructor(
    private thematicGroupService: ThematicGroupService,
    private questionService: QuestionService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ThematicGroupEdit");

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.thematic_groups.name").required()
      .on(this.model);
  }

  private async activate(params: any): Promise<void> {
    this.logger.debug("activated with params: ", params);

    try {

      if (!isNaN(params.questionaryId)) {
        this.questions = await this.questionService.getAll(+params.questionaryId);

        if (!isNaN(params.thematicGroupId)) {

          this.model = await this.thematicGroupService.getById(+params.thematicGroupId);
          this.configureValidationRules();
        }
        else {
          this.logger.debug("creating thematic group");
          this.model.questionaryId = +params.questionaryId;
        }
        this.chargeQuestionList();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public chargeQuestionList(): void {
    // if (this.model.questions.length > 0) {
    this.rightQuestions = this.questions.filter(e =>
      this.model.questions.find(x => x === e.id)
    );

    this.leftQuestions = this.questions.filter(e =>
      !(this.model.questions.find(x => x === e.id))
    );

  }

  public filterLeftQuestions() {
    this.logger.debug("filterLeftQuestions this.leftSearch:", this.leftSearch);
    this.leftQuestions = this.questions.filter(e =>
      !(this.model.questions.find(x => x === e.id))
      &&
      (
        (e.formulation && e.formulation.toLowerCase().indexOf(this.leftSearch.toLowerCase()) !== -1)
        ||
        (e.code && e.code.toLowerCase().indexOf(this.leftSearch.toLowerCase()) !== -1)
      )
    );
  }

  public filterRightQuestions() {
    this.logger.debug("filterRightQuestions this.rightSearch:", this.rightSearch);
    this.rightQuestions = this.questions.filter(e =>
      this.model.questions.find(x => x === e.id)
      &&
      (
        (e.formulation && e.formulation.toLowerCase().indexOf(this.rightSearch.toLowerCase()) !== -1)
        ||
        (e.code && e.code.toLowerCase().indexOf(this.rightSearch.toLowerCase()) !== -1)
      )
    );
  }

  public includeSelectedQuestions(): void {
    this.leftQuestions
      .filter(x => this.questionsToInclude[x.id])
      .forEach((question, index) => {
        this.logger.debug("includeSelectedQuestions question.id:", question.id);
        if (this.model.questions.findIndex(x => x === question.id) < 0) {
          this.model.questions.push(question.id);
        }
        this.rightQuestions.push(question);
        this.questionsToInclude[question.id] = false;
        this.leftQuestions.splice(this.leftQuestions.findIndex(x => x.id === question.id), 1);
      });

    this.logger.debug("includeSelectedQuestions this.leftQuestions:", this.leftQuestions);
  }

  public excludeSelectedQuestions(): void {
    this.rightQuestions
      .filter(x => this.questionsToExclude[x.id])
      .forEach((question, index) => {
        this.model.questions.splice(this.model.questions.findIndex(x => x === question.id), 1);
        this.leftQuestions.push(question);
        this.questionsToExclude[question.id] = false;
        this.rightQuestions.splice(this.rightQuestions.findIndex(x => x.id === question.id), 1);
      });
  }

  public selectLeftQuestion(selectedIndex: number): void {
    this.logger.debug("selectedLeftQuestions index:", selectedIndex);
    if (selectedIndex !== -1) {
      let questionId = this.leftQuestions[selectedIndex].id;
      this.questionsToInclude[questionId] = this.questionsToInclude[questionId] === undefined ? true : !this.questionsToInclude[questionId];
      // this.logger.debug("selectedLeftQuestions this.questionsToInclude[questionId]:", this.questionsToInclude[questionId]);
    } else {
      this.leftQuestions.forEach((question, index) => {
        this.questionsToInclude[question.id] = this.selectAllLeft;
      });
    }

  }

  public selectRightQuestion(selectedIndex: number): void {
    this.logger.debug("selectedRightQuestions index:", selectedIndex);
    if (selectedIndex !== -1) {
      let questionId = this.rightQuestions[selectedIndex].id;
      this.questionsToExclude[questionId] = this.questionsToExclude[questionId] === undefined ? true : !this.questionsToExclude[questionId];
      // this.logger.debug("selectedLeftQuestions this.questionsToExclude[questionId]:", this.questionsToExclude[questionId]);
    } else {
      this.rightQuestions.forEach((question, index) => {
        this.questionsToExclude[question.id] = this.selectAllRight;
      });
    }
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.thematicGroupService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    if (!this.model.id) {
      return await this.thematicGroupService.create(this.model)
        .then(async (response: ThematicGroup) => {
          this.model.id = response.id;

          this.notification.success("notifications.item_added");

          if (!keepEditing) {
            return await this.router.navigateToRoute("thematic-groups");
          } else {
            return await this.router.navigateToRoute("thematic-group-child-router", { thematicGroupId: this.model.id });
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.thematicGroupService.update(this.model)
        .then(async (response: ThematicGroup) => {
          // this.model = Object.assign(this.model, response);
          this.notification.success("notifications.item_edited");

          if (!keepEditing) {
            return await this.router.navigateToRoute("thematic-groups");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  // public cancel(): void {
  //   this.logger.info("cancel", this.router);
  //   this.router.parent.navigate(`thematic-group-questionary/${this.model.questionaryId}/thematic-groups`);
  // }

  // public printName(): void {
  //   this.logger.debug("mode.name", this.model.name);
  //   if (!this.model.name) {
  //     this.logger.debug("El nombre está vacío");
  //   } else {
  //     this.logger.debug("El nombre es valido");
  //   }
  // }

}
