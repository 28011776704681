import { TransactionService } from '../../services/talents/transaction-service';
import { autoinject, bindable, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { RouteConfig } from "aurelia-router";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { User } from "models/security/user";
import { Company } from "models/companies/company";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';

interface SearchParams {
  StartDate: Date,
  EndDate: Date,
  UserId: number,
  TransactionId: number
}

@autoinject()
export class TalentTransferList {

  private logger: Logger;
  @bindable
  private params: any = {};
  private users: User[];
  public companyId: number;
  private company: Company;


  @bindable
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private transactionService: TransactionService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("TalentTransferList");

    this.state = this.store.state;
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.transactionService.transferList(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    //this.params = params;

    var today = new Date();
    var startDate = new Date();
    this.parameters.search = <SearchParams>{ StartDate: new Date(startDate.setDate(startDate.getDate() - 30)), EndDate: today };

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async bind(): Promise<any> {

    try {

      if (!isNaN(this.params.companyId)) {

        this.companyId = +this.params.companyId;

        if (this.companyId) {

          this.transactionService.client.currentCompanyId = this.companyId;

          this.company = this.state.companies.find(x => x.id == this.companyId);

          this.users = await this.transactionService.getUserByCompany();

          this.isLoaded = true;
          return await this.databind();
        }
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

}
