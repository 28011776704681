import { EventAggregator } from 'aurelia-event-aggregator';
import { LogManager, autoinject, Disposable } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';

import { State } from './store-model';
import { initialState } from './store-initial-state';
import { Company } from 'models/companies/company';
import { UserBasicInfo } from 'models/security/user-basic-info';
import { CompanyEditedMessage } from 'messages/companyEditedMessage';
import { CompanyDeletedMessage } from 'messages/companyDeletedMessage';
import { CompaniesLoadedMessage } from 'messages/companiesLoadedMessage';
import { UserInfoLoadedMessage } from 'messages/userInfoLoadedMessage';
import { Person } from 'models/test-person/person';
import { PeopleLoadedMessage } from 'messages/peopleLoadedMessage';
import { CurrentCompanyChangeMessage } from 'messages/currentCompanyChangeMessage';

@autoinject()
export class StoreService {

  private logger: Logger;

  public state: State;

  private subscriptions: Disposable[] = [];

  constructor(private ea: EventAggregator) {
    this.logger = LogManager.getLogger('StoreService');
    this.state = initialState;

    // edited company
    this.subscriptions.push(this.ea.subscribe(CompanyEditedMessage, (msg: CompanyEditedMessage) => this.editCompanyState(msg)));

    // deleted company
    this.subscriptions.push(this.ea.subscribe(CompanyDeletedMessage, (msg: CompanyDeletedMessage) => this.deleteCompanyState(msg)));
  }

  public unbind() {
    this.subscriptions.forEach(x => x.dispose());
  }

  public setCompaniesState(companies: Company[]): Promise<State> {
    return new Promise((resolve) => {
      try {

        this.state.companies = companies;

        // this.logger.debug('setCompaniesState', this.state.companies);

        this.ea.publish(new CompaniesLoadedMessage());

        resolve(this.state);

      } catch (error) {
        this.logger.error(`Error setting companies state: ${error}`);
        resolve(this.state);
      }
    });
  }

  public setPeopleState(people: Person[]): Promise<State> {
    return new Promise((resolve) => {
      try {

        this.state.people = people;

        this.ea.publish(new PeopleLoadedMessage());

        resolve(this.state);

      } catch (error) {
        this.logger.error(`Error setting people state: ${error}`);
        resolve(this.state);
      }
    });
  }

  public editCompany(company: Company): Promise<State> {
    return new Promise((resolve) => {
      try {

        this.logger.debug("editing company", company);

        //this.logger.debug("editing company", this.state.companies);

        let index = this.state.companies.map(x => x.id.toString()).indexOf(company.id.toString());

        this.logger.debug("editing company index", index);
        if (index >= 0) {
          this.state.companies.splice(index, 1);
        }

        Array.prototype.push.apply(this.state.companies, [company]);

        // update current company
        if (company.id === this.state.currentCompany.id) {
          this.state.currentCompany = company;
        }

        resolve(this.state);

      } catch (error) {
        this.logger.error(`Error setting company: ${error}`);
        resolve(this.state);
      }
    });
  }

  public deleteCompany(companyId: number): Promise<State> {
    return new Promise((resolve) => {
      try {

        const index = this.state.companies.map(x => x.id.toString()).indexOf(companyId.toString());
        if (index >= 0) {
          this.state.companies.splice(index, 1);
        }

        resolve(this.state);

      } catch (error) {
        this.logger.error(`Error setting company: ${error}`);
        resolve(this.state);
      }
    });
  }

  public setUserBasicInfoState(userBasicInfo: UserBasicInfo): Promise<State> {
    return new Promise((resolve) => {
      try {

        this.state.userBasicInfo = userBasicInfo;

        this.ea.publish(new UserInfoLoadedMessage());

        resolve(this.state);

      } catch (error) {
        this.logger.error(`Error setting user basic info state: ${error}`);
        resolve(this.state);
      }
    });
  }

  public setCurrentCompanyState(company: Company): Promise<State> {
    return new Promise((resolve) => {
      try {

        this.state.currentCompany = company;

        // this.logger.debug("current", this.state.currentCompany);

        // company id event
        if (company) {
          this.ea.publish(new CurrentCompanyChangeMessage(this.state.currentCompany.id));
        } else {
          this.ea.publish(new CurrentCompanyChangeMessage(null));
        }

        resolve(this.state);

      } catch (error) {
        this.logger.error(`Error setting current company state: ${error}`);
        resolve(this.state);
      }
    });
  }

  public setCurrentPersonState(person: Person): Promise<State> {
    return new Promise((resolve) => {
      try {

        this.state.currentPerson = person;

        resolve(this.state);

      } catch (error) {
        this.logger.error(`Error setting current person state: ${error}`);
        resolve(this.state);
      }
    });
  }
  
  public async editCompanyState(msg: CompanyEditedMessage) {
    return await this.editCompany(msg.company);
  }

  public async deleteCompanyState(msg: CompanyDeletedMessage) {
    return await this.deleteCompany(msg.companyId);
  }


}
