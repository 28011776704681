import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";

import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Notification } from "common/services/notification";
import { SecondPasswordService } from "../../../services/groups/second-password-service";
import { SecondPassword } from "models/second-password/second-password";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class SecondPasswordModal {

  private logger: Logger;
  private model: SecondPassword;
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private secondPasswordService: SecondPasswordService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("SecondPasswordModal");

  }

  public activate(): void {
    this.model = new SecondPassword();
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("password").displayName("main.admin.pages.core_groups.second_password.password").required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.validate();
        }
      });
  }

  public async validate(): Promise<any> {
    return await this.secondPasswordService.validate(this.model)
      .then(async (model: boolean) => {
        this.validationController.reset();
        if (model) {
          this.notification.success("main.admin.pages.core_groups.second_password.notifications.success_validate");
        } else {
          this.notification.error("main.admin.pages.core_groups.second_password.notifications.error_validate");
        }
        return await this.dialogController.ok(model);
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
}
