import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableResponse, UxDatatableParameters } from "common/resources/elements/ux/datatable/ux-datatable";
import { Localization } from "models/localizations/localization";

@autoinject()
export class LocalizationService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {
    this.logger = LogManager.getLogger("LocalizationService");
  }
  

  public async list(languageId: number,parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`localization/list/${languageId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  } 

  public async create(model: Localization): Promise<Localization> {
    return await this.client.create("localization", model)
      .then((response: any) => {
        return <Localization>response;
      });
  }


  public async update(model: Localization): Promise<Localization> {
    return await this.client.updateById("localization", model.id, null, model)
      .then(() => null);
  }


  public async getLabelByIdLanguage(idLanguage: number): Promise<UxDatatableResponse> {
    return await this.client.getById("localization/list", idLanguage)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<Localization[]> {
    return await this.client.get("localization")
      .then((response: any) => {
        return <Localization[]>response;
      });
  }
}
