
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import { I18N } from "aurelia-i18n";

@autoinject
export class TalentListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("TalentListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: [""],
        moduleId: PLATFORM.moduleName("./talent-main"),
        nav: true,
        name: "talent-main",     
        title: this.i18n.tr("main.admin.pages.talents.record.title"),   
      },

  /*  {
        route: ":talentId",
        moduleId: PLATFORM.moduleName("./talent-child-router"),
        name: "talent-child-router",        
      },   */  
      
      {
        route: "transfer",
        moduleId: PLATFORM.moduleName("./talent-transfer-list"),
        nav: true,
        name: "talent-transfer-list",        
        title: this.i18n.tr("main.admin.pages.talents.transfer.title"),
      },
      
      {
        route: "balances",
        moduleId: PLATFORM.moduleName("./talent-balances-list"),
        name: "talent-balances-list",
        nav: true,
        title: this.i18n.tr("main.admin.pages.talents.balances.title"),
      },
      {
        route: "consolidated",
        moduleId: PLATFORM.moduleName("./talent-consolidated-list"),
        name: "talent-consolidated-list",
        nav: true,
        title: this.i18n.tr("main.admin.pages.talents.consolidated.title"),
      },
      {
        route: "export",
        moduleId: PLATFORM.moduleName("./bulk-talents-export-list"),
        name: "bulk-talents-export-list",
        nav: true,
        title: this.i18n.tr("main.admin.pages.plans.test_plan_items.export_results_card.title")
      },

      {
        route: "talent-remove",
        moduleId: PLATFORM.moduleName("./talent-remove"),
        name: "talent-remove",
        nav: false,
        title: this.i18n.tr("main.admin.pages.talents.remove.title"),
      },

      {
        route: "talent-transfer",
        moduleId: PLATFORM.moduleName("./talent-transfer"),
        name: "talent-transfer",
        nav: false,
        title: this.i18n.tr("main.admin.pages.talents.transfer.title"),
      }
    ]);
  }
}
