import { SessionService } from 'common/services/session-service';
import { Router } from "aurelia-router";
import { autoinject, LogManager, bindable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Role } from "models/security/role";
import { CoreCompanyService } from "../../services/companies/core-company-service";
import { CompanyGroupMasterService } from "../../services/companies/company-group-master-service";
import $ from 'jquery';


@autoinject()
export class CompanyGroupMasterListSelector {

  private logger: Logger;

  // @bindable
  // private routeName: string;

  // @bindable
  // private companyParam: string;

  private masterFranchises: Company[] = [];
  private franchises: Company[] = [];
  private companies: Company[] = [];

  private masterFranchiseId: number;
  private franchiseId: number;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  // private user: User = null;

  constructor(
    private sessionService: SessionService,
    private coreCompanyService: CoreCompanyService,
    private companyGroupMasterService: CompanyGroupMasterService,
    private notification: Notification,
    private router: Router) {

    this.logger = LogManager.getLogger("CompanyListSelector");
  }

  private async attached(): Promise<any> {
    //arrow
    $('.company-accordion').on('click', function () {
      var button = $(this).find('.btn[data-toggle]');
      var header = $(this).find('.card-header');
      if (header.length && button.length && button.hasClass('collapsed')) {
        header.removeClass('collapsed');
      } else {
        header.addClass('collapsed');
      }
    });

    // this.user = await this.sessionService.getUser();
    // if (this.user) {
    try {
      // const companies = 
      this.companies = await this.coreCompanyService.getAll();
      // if (this.companies.length === 1) {
      //   return await this.router.navigateToRoute("main-child-router", { companyId: this.companies[0].id });
      // }
      this.franchises = this.companies.filter(x => x.roleId === Role.Franchise);
      // this.logger.debug("franchises", this.franchises);
      // has franchises
      if (this.franchises.length > 0) {
        // default franchsie
        this.franchiseId = this.franchises[0].id;
        this.masterFranchises = this.companies.filter(x => x.roleId === Role.MasterFranchise);
        // this.logger.debug("masterFranchises", this.masterFranchises);
        // has master franchsies
        if (this.masterFranchises.length > 0) {
          this.masterFranchiseId = this.masterFranchises[0].id;
        }
        return await this.databind();

      } else {
        const companyGroups = this.companies.filter(x => x.roleId === Role.Master);
        
        if (companyGroups.length > 0) {
          this.franchiseId = companyGroups[0].parentId;
          return await this.databind();
        }
      }
    }
    catch (error) {
      this.notification.error(error);
    }
    // }
  }

  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    if (this.franchiseId) {
      try {
        const response = await this.companyGroupMasterService.list(this.franchiseId, this.parameters);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    // this.logger.info("refreshing");
    try {
      const response = await this.companyGroupMasterService.list(this.franchiseId, this.parameters);
      return response;
    }
    catch (error) {
      return this.notification.error(error);
    }
  }

  private async selectFranshise(franchiseId: number): Promise<any> {
    // this.logger.debug("selectFranshise", franchiseId);
    this.franchiseId = franchiseId;
    return await this.databind();
  }

  // private async select(companyId: number): Promise<any> {
  //   // this.logger.debug("selected", companyId);
  //   switch (this.routeName) {
  //     case 'main-child-router':
  //       return await this.router.navigateToRoute(this.routeName, { companyId: companyId });
  //     case 'company-master-child-router':
  //       return await this.router.navigateToRoute(this.routeName, { companyDetailId: companyId });
  //     default:
  //       break;
  //   }

  // }

}
