import { ResponseOption } from 'models/questions/response-option';
import { Question } from './../../../../../models/questions/question';


import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Questionary } from "models/questionaries/questionary";

import { ItemToQualify } from "models/questionaries/itemToQualify";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { Language } from "models/localizations/language";

import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { CustomCompanyQuestionaryService } from "main/admin/services/questionaries/custom-company-questionary-service";
import { Company } from "models/companies/company";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { NoDiscType } from "models/questionaries/noDiscType";
import { isDebuggerStatement } from "typescript";
import { CustomCompanyQuestionService } from "main/admin/services/questionaries/custom-company-question-service";
import { QuestionUse } from 'models/questions/questionUse';
import { QuestionType } from 'models/questions/questionType';
import { I18N } from "aurelia-i18n";
@autoinject()
export class CustomCompanyQuestionaryEdit {

  private logger: Logger;

  public companyId: number;
  private company: Company;

  private parameters = new UxDatatableParameters();
  private model: Questionary = new Questionary();
  private languages: Language[] = [];
  private responseOptions: ResponseOption[] = [];

  private averageTime: number = 10;
  private order: number = 1;

  private validationController: ValidationController;

  public isDisabled: boolean;
  private state: State;
  public isVideoInterview: boolean = false;
  constructor(
    private store: StoreService,
    private customCompanyQuestionaryService: CustomCompanyQuestionaryService,
    private customCompanyQuestionService: CustomCompanyQuestionService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    private questionWarning: Question,
    private questionExample: Question,
    private questionFarewell: Question,
    public languagesService: CoreLanguageService,
    public questionaryType: QuestionaryType,
    public itemToQualify: ItemToQualify,
    private i18n: I18N,
    private response: ResponseOption
  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CustomCompanyQuestionaryEdit");

    this.state = this.store.state;
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.questionaries.name").required()
      .ensure("noDiscType").displayName("main.admin.pages.questionaries.type").required()
      .ensure("languageId").displayName("main.admin.pages.questionaries.language").required()
      .ensure("itemToQualify").displayName("main.admin.pages.questionaries.item_to_qualify").required()
      .on(this.model);
  }

  private async activate(params: any): Promise<void> {
    try {
      this.isDisabled = true;

      this.languages = await this.languagesService.getAll();
      if (!isNaN(params.companyId)) {
        this.companyId = +params.companyId;
        this.customCompanyQuestionaryService.client.currentCompanyId = this.companyId;
        this.company = this.state.companies.find(x => x.id == this.companyId);

        if (params.isVideoInterview != undefined) {
          this.isVideoInterview = JSON.parse(params.isVideoInterview);
        }       
        this.validateIsVideoInterview(this.isVideoInterview);

        if (!isNaN(params.questionaryId)) {
          this.model = await this.customCompanyQuestionaryService.getById(+params.questionaryId);
        }
        this.configureValidationRules();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    if (this.model.noDiscType == NoDiscType.VideoInterview) {
      this.itemToQualify == ItemToQualify.Person;
    }
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
  public validateIsVideoInterview(isVideoInterview: boolean) {
    if (isVideoInterview) {
      this.model.noDiscType = NoDiscType.VideoInterview;
      this.model.itemToQualify = ItemToQualify.Person;
      this.isDisabled = true;
    }
    else
      this.isDisabled = false;
  }
  public changeItemToQualify() {   
    if (this.model.noDiscType == NoDiscType.General) {
      this.isDisabled = false;
    }
    if (this.model.noDiscType == NoDiscType.VideoInterview || this.model.noDiscType == NoDiscType.Curriculum) {
      this.model.itemToQualify = ItemToQualify.Person;
      this.isDisabled = true;
    }
    if (this.model.noDiscType == NoDiscType.ExpectationsTeam || this.model.noDiscType == NoDiscType.JobProspects) {
      this.model.itemToQualify = ItemToQualify.Team;
      this.isDisabled = true;
    }
    if (this.model.noDiscType == NoDiscType.Performance) {
      this.model.itemToQualify = ItemToQualify.Observed;
      this.isDisabled = true;
    }

  }


  get canSave() {
    return !this.customCompanyQuestionaryService.client.isRequesting;
  }
  public async CodeRandom(): Promise<string> {
    const length: number = 5;
    let result: string = '';
    const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength: number = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  public async addDefaultQuestions(questionaryId: number) {
    this.questionWarning.questionUse = QuestionUse.Warning;
    this.questionWarning.questionType = QuestionType.DescriptiveText;
    this.questionWarning.order = 2;
    this.questionWarning.formulation = this.i18n.tr("main.admin.pages.questionaries.questions.question_warning");
    this.questionWarning.code = await this.CodeRandom();
    this.questionWarning.questionaryId = questionaryId;
    await this.customCompanyQuestionService.create(this.questionWarning);

    this.questionFarewell.questionUse = QuestionUse.Farewell
    this.questionFarewell.questionType = QuestionType.DescriptiveText;
    this.questionFarewell.order = 1;
    this.questionFarewell.formulation = this.i18n.tr("main.admin.pages.questionaries.questions.question_farewell");
    this.questionFarewell.code = await this.CodeRandom();
    this.questionFarewell.questionaryId = questionaryId;
    await this.customCompanyQuestionService.create(this.questionFarewell);

    this.questionExample.questionUse = QuestionUse.Example;
    this.questionExample.questionType = QuestionType.OpenAnswerQuestion;
    this.response.order = 0;
    this.response.option = ",";
    this.response.isColumn = false;
    this.responseOptions = [this.response];
    this.questionExample.responseOptions = this.responseOptions;
    this.questionExample.order = 1;
    this.questionExample.formulation = this.i18n.tr("main.admin.pages.questionaries.questions.question_example");
    this.questionExample.code = await this.CodeRandom();
    this.questionExample.questionaryId = questionaryId;
    await this.customCompanyQuestionService.create(this.questionExample);


  }
  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    try {

      if (!this.model.id) {
        this.model.questionaryType = QuestionaryType.NoDisc;
        this.model.averageTime = this.averageTime;
        this.model.order = this.order;

        let response: Questionary = await this.customCompanyQuestionaryService.create(this.model);      
        if (this.isVideoInterview && keepEditing) {
          this.addDefaultQuestions(response.id);
          return await this.router.navigateToRoute("custom-company-question-create", { questionTypeId: QuestionType.OpenAnswerQuestion, questionaryId: response.id, isVideoInterview: true, order: 1 });
        }
        this.model.id = response.id;

        this.notification.success("main.admin.pages.questionaries.messages.questionary_added");

        if (!keepEditing) {
          return await this.router.navigateToRoute("custom-company-questionary-list");
        } else {
          return await this.router.navigateToRoute("custom-company-questionary-child-router", { questionaryId: this.model.id });
        }

      } else {

        await this.customCompanyQuestionaryService.update(this.model);

        this.notification.success("main.admin.pages.questionaries.messages.questionary_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("custom-company-questionary-list");
        }

      }

    } catch (error) {
      this.notification.error(error);
    }
  }

}
