import { ViewEngineHooks, View } from 'aurelia-framework';
import { ActivationQuestionaryStatus } from './activationQuestionaryStatus';

export class ActivationQuestionaryStatusViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['ActivationQuestionaryStatus'] = ActivationQuestionaryStatus;

    view.overrideContext['ActivationQuestionaryStatuses'] = 
      Object.keys(ActivationQuestionaryStatus)
        .filter((key) => typeof ActivationQuestionaryStatus[key] === 'number');
  }
}
