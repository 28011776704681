import { autoinject } from 'aurelia-framework';

@autoinject()
export class EnvWarning {

  private isStage: boolean = false;
  private isDev: boolean = false;

  constructor(
  ) {

    
  }

  public attached() {
    this.isStage = window.location.href.indexOf("stage") > 0;
    this.isDev = window.location.href.indexOf("localhost") > 0;
  }
}
