import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { GroupModal } from "./group-modal";
import { Group } from "models/groups/group";
import { GroupService } from "../../services/groups/group-service";
import { Notification } from "common/services/notification";

@autoinject()
export class GroupCreateCard {

  private logger: Logger;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private groupService: GroupService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("GroupCreateCard");
  }

  private async create(): Promise<any> {
    let item: Group = new Group();
    return await this.dialogService.open({
      viewModel: GroupModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.logger.debug("response.output", response.output);
        return await this.groupService.create(item)
          .then(async (model: Group) => {

            // item.id = model.id;
            this.notification.success("notifications.item_added");

            if (response.output) {
              return await this.router.navigateToRoute("group-child-router", { groupId: model.id });
            } else {
              return await this.router.navigateToRoute("groups");
            }

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

}
