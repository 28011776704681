import { ResponseOption } from 'models/questions/response-option';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ThematicVariablesService } from "../../../services/thematic-groups/thematic-variables-service";
import { QuestionAssociation } from "models/thematic-groups/question-association";
import { ThematicVariable } from "models/thematic-groups/thematic-variable";
import { Notification } from "common/services/notification";

import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult, Rule } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class ThematicAssociationModal {

  private logger: Logger;
  private saveAndContinue: boolean = false;
  private model: QuestionAssociation;
  private thematicVariables: ThematicVariable[] = [];

  private validationController: ValidationController;
  private responseOptionRules: any;

  constructor(
    private dialogController: DialogController,
    private thematicVariableService: ThematicVariablesService,
    private validationControllerFactory: ValidationControllerFactory,
    private notification: Notification,
  ) {

    this.logger = LogManager.getLogger("ThematicAssociationModal");

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  private async activate(model: QuestionAssociation): Promise<any> {
    // this.model = Object.assign({}, model);
    this.model = model;
    return await this.thematicVariableService.getAll(model.thematicGroupId)
      .then((thematicVariables: ThematicVariable[]) => {
        this.thematicVariables = thematicVariables;
        this.bindValidationRules();
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private bindValidationRules() {
    this.responseOptionRules = ValidationRules
      // .ensure('thematicVariableId')
      .ensure('weight').matches(/^[0-9][0-9]?$|^100$/)
      .rules; // get a ruleset we can apply to objects later

    this.model.responseOptions.forEach(response => {
      this.validationController.addObject(response, this.responseOptionRules);
    });
  }

  private unbindValidationRules() {
    this.model.responseOptions.forEach(response => {
      this.validationController.removeObject(response);
    });
  }

  private async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  private async save(): Promise<any> {
    this.unbindValidationRules();
    return await this.dialogController.ok();
  }

  // private deactivate(){
  //   this.model = null;
  // }
}

// ValidationRules.customRule(
//   'numberProperty',
//   (value, obj) => value === null || value === undefined || !isNaN(value),
//   "errorMessages.matches"
// );
// ValidationRules.customRule(
//   'integerRange',
//   (value, obj, min, max) => value === null || value === undefined
//     || Number.isInteger(value) && value >= min && value <= max,
//   `\${$displayName} must be an integer between \${$config.min} and \${$config.max}.`,
//   (min, max) => ({ min, max })
// );
