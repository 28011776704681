import { WhoCanSeeReport } from './whoCanSeeReport';
import { PersonMode } from "./personMode";
import { QuestionaryWay } from './questionaryWay';
import { ActivationType } from './activationType';
import { Test } from './../test-perfiltic/test';
import { QuestionaryLanguage } from 'models/questionaries/questionaryLanguage';
export class BulkActivation {
  id: number;
  companyId: number;
  name: string;
  personMode: PersonMode;
  tags: string;
  questionaryWay: QuestionaryWay;

  activationType: ActivationType;

  activationId: number;
  observedId: number;
  positionId: number;
  organizationId: number;
  
  whoCanSeeReport: WhoCanSeeReport;
  invitationExpireDate: Date;
  additionalText: string;

  questionaryGroupId: number;
  reportTemplateId: number;

  hasMicroSite?: boolean;
  isConfidentialTest?: boolean;
  isAnonymous?: boolean;

  languageId: number;

  createdOn: Date;
  createdByName: string;
  createdByEmail: string;

  filedata: any;
  fileName: string;

  engineersTests: Test[];
  
  logFileUrl: string;
  isLoaded: boolean;

  companyCompetenceIds: number[] = [];
  questionaryIds: QuestionaryLanguage[] = [];
}
