import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig, RoutableComponentDetermineActivationStrategy, activationStrategy } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { User } from "models/security/user";
import { Group } from "models/groups/group";
import { Company } from "models/companies/company";
import { SessionService } from "common/services/session-service";
import { UserState } from "models/security/userState";
import { Role } from "models/security/role";
import { State } from "common/store/store-model";
import { News } from 'models/test-core/news/news';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
import { FileAcceptValidator } from 'common/utils/file-accept-validator';
import { NewsService } from 'main/admin/services/test-core/news-service';
import { PlanService } from 'main/admin/services/plans/plan-service';
import { Plan } from 'models/plans/plan';
import { AgreementPlanService } from 'main/admin/services/agreements/agreement-plan-service';
@autoinject()
// @connectTo()
export class NewsEdit {

  private logger: Logger;
  private model: News;
  private validationController: ValidationController;

  public state: State;

  private masterFranchises: Company[];
  private franchises: Company[];
  private plansTest: Plan[] = [];
  private plansSelection: Plan[] = [];
  private isDisable: boolean = false;
  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private newsService: NewsService,

    private coreCompanyService: CoreCompanyService,
    private planService: PlanService,
    private agreementPlanService: AgreementPlanService,
    private i18n: I18N,
    private sessionService: SessionService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("Edit");
    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<void> {
    // this.logger.debug("Activated id:", params.coreUserId);
    try {
      this.masterFranchises = await this.coreCompanyService.getByRoleId(Role.MasterFranchise);

      this.franchises = await this.coreCompanyService.getByRoleId(Role.Franchise);
  

      if (!isNaN(params.newsId)) {
        this.model = await this.newsService.getById(+params.newsId);
        this.initHieranchyLevelCompanyIdByRole();
        this.isDisable = true;
      }
      else {
        this.model = new News();
      }
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }

  }
  private reset() {

    this.model.franchise = null;
    this.model.planTest = null;
    this.model.planSelection = null
  }
  private async plansList() {

    this.plansTest = [];
    this.plansSelection = [];
    let listPlansTest = (await this.planService.getAll(false)).filter(x => x.isActive);
    this.model.franchise.forEach(async (item) => {
      this.plansTest = this.plansTest.concat(listPlansTest.filter(x => x.companyId == item));
    });

    let listPlansSelection = (await this.planService.getAll(true)).filter(x => x.isActive);
    this.model.franchise.forEach(async (item) => {
      this.plansSelection = this.plansSelection.concat(listPlansSelection.filter(x => x.companyId == item));
    });


  }
  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.news.name").required()
      .ensure("roleId").displayName("main.admin.pages.users.levels.title").required()
      .ensure("fileName").displayName("main.admin.pages.news.image").required()
      .on(this.model);
  }

  private async submit(saveAndContinue: boolean): Promise<any> {
    let result = await this.validationController.validate();
    if (result.valid) {
      if (!this.model.roleId) {
        this.notification.warning(this.i18n.tr("main.admin.pages.users.errors.role_is_required"));
      }
      else {
        return await this.save(saveAndContinue);
      }
    }

  }


  private async save(saveAndContinue: boolean): Promise<any> {
    if (!this.model.id) {

      return await this.newsService.create(this.model)
        .then(async (model: News) => {
          this.notification.success("notifications.item_added");
          if (!saveAndContinue) {
            return await this.router.navigateToRoute("news-list");
          } else {
            return await this.router.navigateToRoute("news-child-router", { newsId: model.id });
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.newsService.update(this.model)
        .then(async () => {
          this.notification.success("notifications.item_edited");
          if (!saveAndContinue) {
            return await this.router.navigateToRoute("news-list");
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }



  private initHieranchyLevelCompanyIdByRole(): void {
    switch (this.model.roleId) {
      case 1:
      case 2:
        break;
      case 3:
      case 4:
        this.plansList();
        break;
      default:
        break;
    }
  }


  public unSetFile(): void {
    this.model.fileName = null;
    this.model.thumbnailImageUrl = null;
  }
  public determineActivationStrategy(): any {
    return activationStrategy.replace;
  }
  public setFile($event: any, item: News): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];
    let fileValidator = FileAcceptValidator.parse("image/*", 1080 * 566);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64data: any = reader.result;
        item.thumbnailImage = base64data;
        item.fileName = file.name;
        item.thumbnailImageUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.questionaries.questions.errors.error_bad_image");

    }
  }




}
