import { EducationalLevel } from '../catalogs/educationalLevel';

export class EducationalLevelCountry {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  countryId: number;
  educationalLevel: EducationalLevel;
}

