import { autoinject } from 'aurelia-framework';
import { DialogController } from "aurelia-dialog";

@autoinject()
export class DeactivateConfirmation {
  private model: string;

  constructor(private dialogController: DialogController) {
  }

  public activate(model: string) {
    this.model = model;
  }
}
