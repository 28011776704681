import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { RouteConfig } from "aurelia-router";
import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { State } from "common/store/store-model";
import { DeleteConfirmation } from 'main/components/delete-confirmation';
import { ReportTemplateService } from '../../../services/test-management/report-template-service';
import { ReportTemplate } from 'models/test-core/report-templates/report-template';
import { CompanyCompetence } from 'models/test-core/competences/company-competence';
import { CompetenceActivatedModal } from 'main/test/pages/people-competence/competence-activated-modal';

@autoinject()
export class ReportList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private store: StoreService,
    private reportTemplateService: ReportTemplateService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("ReportList");

    this.state = this.store.state;
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('reportTemplateListSearch', search == null ? '' : search);

    return await this.reportTemplateService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // this.logger.debug("activate");
    this.parameters.search.Query = sessionStorage.getItem('reportTemplateListSearch');

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.companyId) {

        this.reportTemplateService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async clearFilters(): Promise<any> {
    this.parameters.search.Query = null;
    await this.databind();
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async delete(item: ReportTemplate): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        return await this.reportTemplateService.delete(item.id)
          .then(() => {

            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("notifications.item_deleted");

          })
          .catch(error => {
            this.notification.error(error);
          });

      }
    });
  }

  private async seeActivatedCompetences(companyCompetence: CompanyCompetence[]): Promise<void> {
    await this.dialogService.open({
      viewModel: CompetenceActivatedModal,
      model: companyCompetence
    })
  }


}
