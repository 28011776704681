import { DialogService } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ServiceRatingService } from "main/admin/services/service-rating/service-rating-service";
import { ServiceRating } from "models/service-rating/service-rating";
@autoinject()
export class UserSettingList {

  private logger: Logger;
  private serviceRating: ServiceRating;

  constructor(
    private router: Router,
    private serviceRatingService: ServiceRatingService) 
    {

    this.logger = LogManager.getLogger("ServiceRatingList");
  }
  public async activate() {
    this.serviceRating = await this.serviceRatingService.getServiceRatingSettings();
  }
}
