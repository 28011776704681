import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Group } from "models/groups/group";
import { CoreGroupService } from "../../services/groups/core-group-service";
import { Notification } from "common/services/notification";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class CoreGroupDeleteModal {

  private logger: Logger;
  private groupToDelete: Group;
  private coreGroupIdToTransferUsers: number;
  private validationController: ValidationController;
  private coreGroups: Group[];

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private coreGroupService: CoreGroupService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("GroupModal");
    this.configureValidationRules();
  }

  private async activate(item: Group): Promise<void> {
    this.groupToDelete = Object.assign(new Group(), item);
    return await this.coreGroupService.getAll()
      .then(coreGroups => this.coreGroups = coreGroups)
      .then(() => {
        // remuevo de la lista de grupos a transferir usuarios el grupo a eliminar
        this.coreGroups.splice(this.coreGroups.map(x => x.id.toString()).indexOf(item.id.toString()), 1);
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("coreGroupIdToTransferUsers").displayName("main.admin.pages.core_groups.core_group_to_transfer").required()
      .on(this);
  }

  public async submit(): Promise<any> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.dialogController.ok(this.coreGroupIdToTransferUsers);
        }
      });
  }
}
