
import { LogManager, autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Logger } from 'aurelia-logging';
import { Router, RouterConfiguration } from 'aurelia-router';
import { AuthorizationService } from 'aurelia-permission';
import { I18N } from 'aurelia-i18n';
import PredefinedPermissions from 'config/predefined-permissions';

@autoinject
export class CompanyMasterChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("CompanyMasterChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map([
      {
        route: '',
        moduleId: PLATFORM.moduleName('./company-master-edit'),
        name: 'company-master-edit',
        nav: true,
        title: this.i18n.tr("main.admin.pages.companies.company"),
      },

      {
        route: 'agreements',
        moduleId: PLATFORM.moduleName('../agreements/agreement-list-child-router'),
        name: 'company-master-agreements',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.ServiceAgreementView),
        title: this.i18n.tr("main.admin.pages.agreements.title"),
      },

      {
        route: 'password-policy',
        moduleId: PLATFORM.moduleName('../companies/company-password-policy-edit'),
        name: 'company-master-password-policy',
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.PasswordPolicyView),
        title: this.i18n.tr("main.admin.pages.companies.password_policy.title"),
      },


    ]);
  }
}
