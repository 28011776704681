import { Report } from '../test-reports/reports/report';
export class BulkDownload {
  id: number;
  companyId: number;
  name: string;
  tags: string;
  languageId: number;
  logFileUrl: string;
  isLoaded: boolean;
  hasExpired: boolean;
  createdOn: Date;
  createdByName: string;
  peopleWithReportIds: number[] = [];
  report: Report;  
}
