import { DialogCloseResult, DialogService } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { UxDatatableCustomAttribute, UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { CoreCompanyService } from "main/admin/services/companies/core-company-service";
import { PlanService } from "main/admin/services/plans/plan-service";
import { NewsService } from "main/admin/services/test-core/news-service";
import { Company } from "models/companies/company";
import { Plan } from "models/plans/plan";
import { News } from "models/test-core/news/news";
import { ChangeStateConfirmation } from "../../../../components/change-state-confirmation";
@autoinject()
export class NewsList {

  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private newsList: News[] = [];

 
  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
     return await this.newsService.list(this.parameters)
       .then(async (response: UxDatatableResponse) => {            
        return response;
       })
       
       .catch(error => {
         this.notification.error(error);
       });
  }

  constructor(
    private router: Router,
    private newsService: NewsService,
    private dialogService: DialogService,
    private notification: Notification,
    private planService: PlanService,
    private coreCompanyService: CoreCompanyService) {

    this.logger = LogManager.getLogger("NewsList");

  }

  public async bind(): Promise<void> {   
    return await this.databind();
  }


  private async databind(): Promise<void> {
    return await this.refresh()
      .then(async (response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords; 
        this.newsList = this.parameters.tableData;
        this.getListPlans();
        this.getListFranchises();
      })      
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async changeState(item: News): Promise<void> {
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.newsService.changeState(item.id)
            .then(() => {           
              this.notification.success("notifications.item_edited");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
        else{
          this.logger.debug("response canceled")
        }
        
      });
  }
  public async getListPlans(){
    try {   
      this.newsList.forEach(x=> {  
        let planTestList: string[] =  [];   
        let planSelectionList: string[] =  [];
        if (x.planTest){
          x.planTest.forEach(async (item: number) =>{
            let plan: Plan = new Plan();
            plan = await this.planService.getById(item);
            planTestList.push(plan.name);
          })
        }          
          x.plansTest = planTestList;   
          
          if (x.planSelection){
            x.planSelection.forEach(async (item: number) =>{
              let plan: Plan = new Plan();
              plan = await this.planService.getById(item);
              planSelectionList.push(plan.name);
            })
          }          
            x.plansSelection = planSelectionList;  
          
      });
    } catch (error) {
      
    }
  }
  public async getListFranchises(){
    try {   
      this.newsList.forEach(x=> {  
        let franchiseMasterList: string[] =  [];   
        let franchiseList: string[] =  [];
        if (x.franchiseMaster){
          x.franchiseMaster.forEach(async (item: number) =>{
            let franchise: Company = new Company();
            franchise = await this.coreCompanyService.getById(item);
            franchiseMasterList.push(franchise.name);
          })
        }          
          x.franchisesMaster = franchiseMasterList;   
          
          if (x.franchise){
            x.franchise.forEach(async (item: number) =>{
              let franchise: Company = new Company();
              franchise = await this.coreCompanyService.getById(item);
              franchiseList.push(franchise.name);
            })
          }          
            x.franchises = franchiseList;  
          
      });
    } catch (error) {
      
    }
  }
}
