import { autoinject, LogManager, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";

import { CoreGroupService } from "../../services/groups/core-group-service";
import { CoreGroupModal } from "./core-group-modal";
import { Group } from "models/groups/group";
import { Notification } from "common/services/notification";
import { CoreGroupDeleteModal } from "./core-group-delete-modal";

import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject
export class CoreGroupList {

  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.coregroupService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private coregroupService: CoreGroupService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("CoreGroupList");

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");
    // return await this.databind();
  }

  private async bind(): Promise<any> {
    this.logger.debug("activate");
    return await this.databind();
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");
    // this.logger.debug("parameters", this.parameters);
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async create(): Promise<any> {
    let item: Group = new Group();
    return await this.dialogService.open({
      viewModel: CoreGroupModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {

      if (!response.wasCancelled) {

        this.logger.debug("response.output", response.output);

        return await this.coregroupService.create(item)
          .then(async (model: Group) => {

            this.logger.debug("item.id: ", model.id);
            item.id = model.id;
            
            if (response.output) {
              // this.logger.debug("Save and continue id: ", model.id);
              return await this.router.navigateToRoute("core-group-child-router", { coreGroupId: model.id });
            } else {      
              this.logger.debug("item: ", item);
              this.parameters.tableData.unshift(item);
              this.parameters.totalRecords++;
              this.notification.success("notifications.item_added");
              // return await this.databind();
            }
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async delete(item: Group): Promise<void> {
    return await this.dialogService.open({
      viewModel: CoreGroupDeleteModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (response.output) {
          let coreGroupIdToTransferUsers: number = <number>response.output;
          this.logger.debug("coreGroupIdToTransferUsers: ", coreGroupIdToTransferUsers);
          return await this.coregroupService.delete(item.id, coreGroupIdToTransferUsers)
            .then(() => {
              let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
              this.parameters.tableData.splice(indexOfEditedModel, 1);
              this.parameters.totalRecords--;
              this.notification.success("notifications.item_deleted");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      }
    });
  }
}
