import { LogManager, autoinject } from "aurelia-framework";
  import { Logger } from "aurelia-logging";
  import { AppHttpClient } from "common/services/app-http-client";

  import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
  import { ThematicGroup } from "models/thematic-groups/thematic-group";
  
  @autoinject()
  export class ThematicGroupService {
    private logger: Logger;
  
    constructor(
      public client: AppHttpClient) {
  
      this.logger = LogManager.getLogger("ThematicGroupsService");
    }
  
    async list(questionaryId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
      return await this.client.post(`thematicgroup/list/${questionaryId}`, parameters)
        .then(response => {
          return <UxDatatableResponse>response;
        });
    }
  
    async getById(id: number): Promise<ThematicGroup> {
      // this.logger.info("id: " +  id); 
      return await this.client.getById("thematicgroup",id)
        .then(response => {
          return <ThematicGroup>response;
        });
    }
  
    async create(model: ThematicGroup): Promise<ThematicGroup> {
      return await this.client.post('thematicgroup',model)
      .then(response => {
        return <ThematicGroup>response;
      });
    }
  
    async update(model: ThematicGroup): Promise<ThematicGroup> {
      return await this.client.updateById('thematicgroup', model.id, null, model)
      .then(response => {
        return <ThematicGroup>response;
      });
    }
  
    async delete(id: number): Promise<void> {
      // this.logger.info("id to delete: " +  id);
      return await this.client.delete('thematicgroup',id)
        .then(() => null);
    }
  }
