import { Competence } from "../competence";
import { DichotomousCouple } from "./dichotomous-couple";

export class CompanyCompetence {
  id: number;
  competenceId: number;
  competenceName: string;
  competenceDescription: string;
  name: string;
  isActive: boolean;
  competence: Competence;
  dichotomousCouples: DichotomousCouple[] = [];
}
