
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { RouteConfig } from "aurelia-router";
import { Notification } from "common/services/notification";
import { UserService } from "../../services/users/user-service";
import { User } from "models/security/user";
import { UserApiApplication } from "models/security/userApiApplication";
import { UserApiApplicationService } from "../../services/users/user-api-application-service";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { PasswordValidationModal } from "../users/password-validation-modal";

@autoinject()
export class UserApiApplicationEdit {

  private logger: Logger;

  private companyId: number;

  private user: User;

  private model: UserApiApplication = new UserApiApplication();

  constructor(
    private notification: Notification,
    private userService: UserService,
    private userApiApplicationService: UserApiApplicationService,
    private dialogService: DialogService, ) {

    this.logger = LogManager.getLogger("UserApiApplicationEdit");

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<void> {

    // this.logger.debug("Activated id:", params.userId);
    try {
      if (!isNaN(params.companyId)) {
        this.companyId = +params.companyId;
        if (!isNaN(params.companyDetailId)) {
          this.companyId = +params.companyDetailId;
        }
        this.userApiApplicationService.client.currentCompanyId = this.companyId;
        if (!isNaN(params.userId)) {
          this.user = await this.userService.getById(+params.userId);
          this.model = await this.userApiApplicationService.getById(+params.userId);
        } else {
          this.logger.debug("creating user api application");
        }
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async validateUser(): Promise<boolean> {
    return await this.dialogService.open({
      viewModel: PasswordValidationModal
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (response.output) {
          let result: boolean = <boolean>response.output;
          this.logger.debug("validate ok: ", result);
          return result;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }

  get canSave() {
    return !this.userApiApplicationService.client.isRequesting;
  }

  private async generate(): Promise<any> {
    this.logger.debug("generating");
    return await this.validateUser()
      .then(async (result: boolean) => {
        if (result) {
          return await this.userApiApplicationService.generate(this.user.id);
        }
        else {
          return;
        }
      })
      .then(async (model: UserApiApplication) => {
        if (model) {
          this.model = model;
          this.notification.success("main.admin.pages.user_api_applications.notifications.generation_success");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

}
