import { Notification } from './../../../../common/services/notification';
import { DialogController } from "aurelia-dialog";
import { Report } from "models/test-reports/reports/report";
import { Logger } from "aurelia-logging";
import { LogManager, autoinject } from "aurelia-framework";
import { CarouselConfig } from "common/resources/elements/ux/carousel/carousel";

@autoinject
export class SelectSheetModal {

  private logger: Logger;

  // static inject = [DialogController];

  private model: Report;

  private selectedSheetIds: number[] = [];

  private options = <CarouselConfig>{
    duration: 600,
    perPage: {
      640: 1,
      768: 2,
      1080: 3
    },
    draggable: true,
    loop: false,
    autoPlay: true,
    autoPlayInterval: 2500
  };


  constructor(
    private dialogController: DialogController,
    private notification: Notification) {

    this.logger = LogManager.getLogger("SelectSheetModal");
  }

  private activate(model: Report) {

    this.model = model;

    if (this.model.sheetIds.length == 0) {
      this.selectedSheetIds = this.model.sheets.map(x => x.id);
    }
    else {
      this.selectedSheetIds = this.model.sheetIds;
    }

  }

  private async select(): Promise<any> {

    if (this.selectedSheetIds.length == 0) {
      this.notification.warning("main.test_reports.pages.reports.errors.no_selected_sheets");
    }
    else {
      this.model.sheetIds = this.selectedSheetIds;
      return await this.dialogController.ok();
    }

  }
}
