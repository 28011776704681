

import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Router, RouteConfig } from "aurelia-router";

import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { ProfileService } from "../../services/profiles/profile-service";
import { Profile } from "models/profiles/profile";
import { Notification } from "common/services/notification";
import { ProfileDeleteModal } from "./profile-delete-modal";
import { Company } from "models/companies/company";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";

@autoinject()
export class ProfileList {

  private logger: Logger;
  private params: any ={};

  public companyId: number;
  private company: Company;

  private state: State;
  
  private isLoaded: boolean = false;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.profileService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private store: StoreService,
    private router: Router,
    private profileService: ProfileService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("ProfileList");

    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {

    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.companyId) {

        this.profileService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded = true;

        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    // this.logger.debug("parameters", this.parameters);
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async edit(id: number): Promise<any> {
    return await this.router.navigateToRoute("profile-child-router", {profileId: id});
  }

  public async delete(item: Profile): Promise<void> {
    return await this.dialogService.open({
      viewModel: ProfileDeleteModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (response.output) {
          let idToTransfer: number = <number>response.output;
          this.logger.debug("profileIdToTransferUsers: ", idToTransfer);
          return await this.profileService.delete(item.id, idToTransfer)
            .then(async () => {
              let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
              this.parameters.tableData.splice(indexOfEditedModel, 1);
              this.parameters.totalRecords--;
              this.notification.success("notifications.item_deleted");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      }
    });
  }
}
