import { CompanySettings } from "./company-settings";

export class Company {
  id: number;
  name: string;
  businessName: string;
  roleId: number;
  parentId: number;
  slug: string;
  isValid: boolean;
  isActive: boolean;
  createdOn: Date;

  companySettings: CompanySettings;
  value?: string;
}
