import * as moment from 'moment-timezone';
import { State } from 'common/store/store-model';
import { autoinject } from 'aurelia-framework';
import { StoreService } from 'common/store/store-service';

@autoinject()
export class DateTimeFormatValueConverter {

  public state: State;

  constructor(
    private store: StoreService,
  ) {
    this.state = this.store.state;
  }

  toView(value) {
    if (value) {

      if (this.state && this.state.userBasicInfo && this.state.userBasicInfo.timezone) {
        return moment(value).tz(this.state.userBasicInfo.timezone).format(this.state.userBasicInfo.dateTimeFormat);
      }
      else if (this.state && this.state.userBasicInfo) {
        return moment(value).format(this.state.userBasicInfo.dateTimeFormat);
      }
      else {
        return moment(value).format('M/D/YYYY h:mm:ss a');
      }

    }
    else {
      return "";
    }

  }
}
