import { Company } from './company';

export class CompanyGroup {
  id: number;
  companyId: number;
  company: Company = new Company();
  economicSectorId: number;
  countryId: number;
  regionId: number;
  cityId: number;
  companies: Company[] = [];
}
