import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Profile } from "models/profiles/profile";
import { Notification } from "common/services/notification";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { ProfileService } from "../../services/profiles/profile-service";

@autoinject()
export class ProfileDeleteModal {

  private logger: Logger;
  private profileToDelete: Profile;
  private profileIdToTransferUsers: number;
  private validationController: ValidationController;
  private profiles: Profile[];

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private profileService: ProfileService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ProfileDeleteModal");
    this.configureValidationRules();
  }

  private async activate(item: Profile): Promise<void> {
    this.profileToDelete = Object.assign(new Profile(), item);
    return await this.profileService.getAll()
      .then(profiles => this.profiles = profiles)
      .then(() => {
        // remuevo de la lista de grupos a transferir usuarios el grupo a eliminar
        this.profiles.splice(this.profiles.map(x => x.id.toString()).indexOf(item.id.toString()), 1);
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("profileIdToTransferUsers").displayName("main.admin.pages.profiles.profile_to_transfer").required()
      .on(this);
  }

  public async submit(): Promise<any> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.dialogController.ok(this.profileIdToTransferUsers);
        }
      });
  }
}


