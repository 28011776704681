
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import { I18N } from "aurelia-i18n";

@autoinject
export class ReportChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("ReportChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./report-edit"),
        name: "report-edit",
        nav: false,
        title: this.i18n.tr("main.test_reports.pages.reports.title"),
      }, 

    ]);
  }
}
