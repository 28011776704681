import { PersonService } from 'main/test/services/people/person-service';
import { Notification } from 'common/services/notification';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Person } from "models/test-person/person";
import { DialogController } from "aurelia-dialog";
import { PersonLoginInfo } from 'models/test-person/person-login-info';
import config from 'config.js';

@autoinject()
export class PersonLoginInfoModal {

  private logger: Logger;

  // private model: Person = new Person();
  private loginInfo: PersonLoginInfo;

  private micrositeUrl: string = null;
  private directLoginUrl: string = null;
  private directLoginUrlElement: HTMLElement;

  constructor(
    private dialogController: DialogController,
    private personService: PersonService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("PersonLoginInfoModal");


    let micrositeUrl = config.URLS_MICROSITE;
    this.micrositeUrl = micrositeUrl;
  }

  public async activate(personId: number): Promise<any> {
    this.logger.debug("activate", personId);

    // this.model = person;

    try {
      this.loginInfo = await this.personService.getLoginInfoById(personId);
    } catch (error) {
      this.notification.error(error);
    }
  }

  private focus() {
    if (this.directLoginUrlElement) {
      this.directLoginUrlElement.focus();
    }
  }


}
