import { ViewEngineHooks, View } from 'aurelia-framework';
import { PersonActivationType } from './personActivationType';

export class PersonActivationViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['PersonActivationType'] = PersonActivationType;

    view.overrideContext['PersonActivationTypes'] = 
      Object.keys(PersonActivationType)
        .filter((key) => typeof PersonActivationType[key] === 'number');
  }
}
