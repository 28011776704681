
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class CoreUserListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("CoreUserListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./core-user-list"),
        name: "core-users",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.core-users.title"),
      },

      {
        route: "core-user/:coreUserId",
        moduleId: PLATFORM.moduleName("./core-user-child-router"),
        name: "core-user-child-router",
        // nav: false,
        // title: this.i18n.tr("main.admin.pages.core-users.core-user"),
      },
    ]);
  }
}
