
import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { HttpClient } from 'aurelia-fetch-client';
import { User } from "oidc-client";
import { OpenIdConnect } from "aurelia-open-id-connect";
import { OpenIdProfile } from 'models/security/open-id-profile';
import config from 'config.js';

@autoinject()
export class SessionService {

  private user: User;
  private profile: OpenIdProfile;
  private logger: Logger;
  private backendUrl: string;

  constructor(
    private openIdConnect: OpenIdConnect,
    private client: HttpClient) {

    this.logger = LogManager.getLogger('SessionService');

    this.backendUrl = config.URLS_BACKEND;

  }

  public async getUser(): Promise<any> {
    if (this.user) {
      if (this.user.expired) {
        // if we have an EXPIRED user in storage,
        // then we can do a silent login.
        this.logger.debug("user expired. login Silently...");
        this.user = null;
        return await this.loginSilent();
        // this.logger.debug("user expired");
        //return await this.openIdConnect.login();
      } else {
        // if we have a non-expired user in storage, 
        // then our app can use it for access.
        return this.user;
      }
    }
    else {
      return await this.openIdConnect.getUser()
        .then(async (user: User) => {
          // this.logger.debug("user", user);
          if (!user) {
            // if we do not have a user in localStorage (or sessionStorage)
            // then we do not have an id_token to use as an id_token_hint;
            // as a result, we cannot do a silent login, so we return; 
            // alternatively, we could call `login` to 
            // automatically redirect to the authorization server:
            this.logger.debug("no user found");
            return await this.openIdConnect.userManager.removeUser().then(async () => {
              return await this.openIdConnect.login();
            });
          }
          else {
            if (user.expired) {
              // if we have an EXPIRED user in storage,
              // then we can do a silent login.
              this.logger.debug("user expired. login Silently...");
              return await this.loginSilent();
              // this.logger.debug("user expired");
              //return await this.openIdConnect.login();
            } else {
              // if we have a non-expired user in storage, 
              // then our app can use it for access.
              // this.logger.debug("user loaded");
              this.user = user;
              return this.user;
            }
          }
        })
        .catch(error => {
          this.logger.error("Error getting user:", error.message);
          throw error;
        });
    }
  }

  public async getProfile(): Promise<any> {
    // retrieve the user from storage
    this.logger.debug("getting profile");
    // this.logger.debug("getProfile", this.user);
    if (this.profile) {
      return this.profile;
    }

    return await this.getUser()
      .then(async () => {
        if (this.user) {
          // configure client
          this.client.configure(x => {
            x.withBaseUrl(`${this.backendUrl}/api/v1/`);
            x.withDefaults({
              headers: {
                'Authorization': `Bearer ${this.user.access_token}`
              }
            });
          });

          return await this.client.fetch('userinfo', { method: 'GET' });
        }
      })
      .then(async (response: Response) => {
        return await response.json();
      })
      .then((profile: OpenIdProfile) => {
        this.profile = profile;
        return this.profile;
      })
      .catch(error => this.logger.error(error));
  }
  
  public async loginSilent(): Promise<any> {
    return await this.openIdConnect.loginSilent()
      .catch(async error => {
        // if this is a timeout error,
        // then use a text editor to increase the silentRequestTimeout value,
        // that we configure in open-id-connect-configuration.ts
        this.logger.error("Error getting user:", error.message);
        return await this.openIdConnect.userManager.removeUser().then(async () => {
          return await this.openIdConnect.login();
        });

      });
  }

  public async logout(): Promise<any> {
    this.logger.debug("logout by 401");
    return await this.openIdConnect.userManager.removeUser().then(async () => {
      return await this.openIdConnect.logout();
    });
  }

}
