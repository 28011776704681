import { ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { Activity } from 'models/agreements/activity';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from 'aurelia-logging';

@autoinject()
export class ActivityModal {
  
  private model: Activity = new Activity;
  private validationController: ValidationController;
  private logger: Logger;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ActivityModal");
  }

 public async activate(model: Activity): Promise<void> {
    this.logger.debug("activate");
    this.model = model;
    this.configureValidationRules();
  }
  
  configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.agreements.activity.name").required()
      .ensure("responsible").displayName("main.admin.pages.agreements.activity.responsible").required()
      .ensure("date").displayName("main.admin.pages.agreements.activity.date").required()
      .ensure("email").displayName("main.admin.pages.agreements.activity.email").required().matches(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i)
      .ensure("Notify").displayName("main.admin.pages.agreements.activity.Notify").required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {          
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();
  }

}
