import { ViewEngineHooks, View } from 'aurelia-framework';
import { OrganizationActivationType } from './organizationActivationType';

export class OrganizationActivationTypeViewEngineHooks implements ViewEngineHooks {

  beforeBind(view: View) {

    view.overrideContext['OrganizationActivationType'] = OrganizationActivationType;

    view.overrideContext['OrganizationActivationTypes'] = 
      Object.keys(OrganizationActivationType)
        .filter((key) => typeof OrganizationActivationType[key] === 'number');
  }
}
