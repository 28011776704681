import { autoinject, bindable, bindingMode, observable, TaskQueue } from 'aurelia-framework';
import { QuestionaryLanguage } from 'models/questionaries/questionaryLanguage';
import { TestQuestionary } from 'models/test-core/test-questionary';
@autoinject()
export class MultiselectThtCustomElement {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) value : QuestionaryLanguage[] = [];
  @bindable({ defaultBindingMode: bindingMode.twoWay }) genericFilter = [];
  @bindable paramsList: TestQuestionary[] = [];

  constructor() {   
  }
  public attached() {    
    
  }  
  private isSelected(questionary: TestQuestionary): boolean { 
    
    const filteredQuestionaries = this.value.filter(x => x.stringId == questionary.stringId); 
    return filteredQuestionaries.length > 0;
   
  }  
  private addRemoveQuestionary(questionary: TestQuestionary)
  {
    const index = this.value.findIndex(x => x.stringId == questionary.stringId);
    if(index !== -1)
    {   
        this.value.splice(index, 1);
    }
    else{
      let questionaryLanguage: QuestionaryLanguage = new QuestionaryLanguage();
      questionaryLanguage.languageId = questionary.languageId;
      questionaryLanguage.questionaryId = questionary.id;
      questionaryLanguage.stringId = questionary.stringId;
      this.value.push(questionaryLanguage);
    }
    
  }   

}
