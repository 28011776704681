
import { Company } from 'models/companies/company';
import { autoinject, bindable, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { Router, RouteConfig } from "aurelia-router";
import { AgreementService } from '../../services/agreements/agreement-service';
import { Agreement } from 'models/agreements/agreement';
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { PdfService } from 'main/admin/services/pdf/pdf-service';
import * as $ from 'jquery';

@autoinject()
export class AgreementList {

  @bindable
  public companyId: number;

  private company: Company;
  private logger: Logger;
  private agreements: Agreement[] = [];
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private params: any = {};

  private state: State;

  private isMaster: boolean = false;

  constructor(
    private store: StoreService,
    private agreementService: AgreementService,
    private pdfService: PdfService,
    private dialogService: DialogService,
    private router: Router,
    private notification: Notification) {
    this.logger = LogManager.getLogger("AgreementList");

    this.state = this.store.state;
  }

  public async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate companyId:", params.companyId);
    this.params = params;

    this.isMaster = routeConfig.navModel.router.baseUrl.indexOf("companies-master") > 0;   
  }

  public async bind(): Promise<any> {
    if (!isNaN(this.params.companyGroupId)) {
      this.companyId = +this.params.companyGroupId;
    }    
    if (this.params.companyDetailId && this.params.companyDetailId === 'create') {     
      this.companyId = null;
    }    
    if (!isNaN(this.params.companyDetailId)) {
      this.companyId = +this.params.companyDetailId;
    }
    if (this.companyId) {
      this.agreementService.client.currentCompanyId = this.companyId;
      this.company = this.state.companies.find(x => x.id == this.companyId);
      return await this.databind();
    }
  }

  public async databind(): Promise<void> {
    this.logger.info("databind");
    return await this.agreementService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = async (): Promise<UxDatatableResponse> => {
    return this.agreementService.list(this.parameters);
  }

  private async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed((response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.agreementService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(id.toString());
            this.parameters.tableData.splice(indexOfEditedModel, 1);

            this.notification.success("main.admin.pages.agreements.notifications.deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async pdfExport(id: number, name: string): Promise<void> {
    let html = $('html').html();
    try {
      let readOnly = await this.agreementService.getReadOnlyById(id);
      return this.pdfService.print(readOnly.agreementBody, name);
    }
    catch (error) {
      this.notification.error(error);
    }
    
  }
}
