
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PasswordPolicyService } from '../../services/companies/password-policy-service';
import { PasswordPolicy } from 'models/companies/password-policy';
import { Company } from "models/companies/company";
import { CoreCompanyService } from "main/admin/services/companies/core-company-service";

@autoinject()
export class CompanyPasswordPolicyEdit {

  private logger: Logger;

  private company: Company;

  private model: PasswordPolicy = new PasswordPolicy();
  private validationController: ValidationController;

  private isMaster: boolean = false;

  constructor(
    private passwordPolicyService: PasswordPolicyService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    private coreCompanyService: CoreCompanyService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CompanyPasswordPolicyEdit");

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("requiredLength").displayName("main.admin.pages.companies.password_policy.requiredLength").matches(/^[0-9]*$/).required()
      .ensure("lockoutMaxFailedAccessAttempts").displayName("main.admin.pages.companies.password_policy.lockoutMaxFailedAccessAttempts").matches(/^[0-9]*$/).required()
      .ensure("passwordChangingPeriod").displayName("main.admin.pages.companies.password_policy.passwordChangingPeriod").required()
      .when(x => this.model.requirePasswordChanging)
      .on(this.model);
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {

    this.isMaster = routeConfig.navModel.router.baseUrl.indexOf("companies-master") > 0;

    if (!isNaN(params.companyDetailId)) {

      try {

        this.company = await this.coreCompanyService.getById(+params.companyDetailId);

        this.model = await this.passwordPolicyService.getById(+params.companyDetailId);
        
        this.configureValidationRules();
      }
      catch (error) {
        this.notification.error(error);
      }
    }

  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.passwordPolicyService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    return await this.passwordPolicyService.update(this.model)
      .then(async () => {
        this.notification.success("main.admin.pages.companies.messages.company_edited");

        if (!keepEditing) {
          if (!this.isMaster) {
            return await this.router.navigateToRoute("company-groups");
          } else {
            return await this.router.navigateToRoute("company-group-master-list-selector");
          }
        }

      })
      .catch((error) => {
        this.notification.error(error);
      });
  }

  public async cancel(){
    if (!this.isMaster) {
      return await this.router.navigateToRoute("company-groups");
    } else {
      return await this.router.navigateToRoute("company-group-master-list-selector");
    }
  }

  public filterLockout(hasChanged: boolean): void {
    if (!hasChanged) {
      this.model.lockoutMaxFailedAccessAttempts = 0;
    } else {
      this.model.lockoutMaxFailedAccessAttempts = 5;
    }
  }

  public filterChangingPeriod(hasChanged: boolean): void {
    if (!hasChanged) {
      this.model.passwordChangingPeriod = null;
    }
  }

}
