import { StoreService } from 'common/store/store-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { GroupService } from "../../services/groups/group-service";
import { GroupModal } from "./group-modal";
import { Group } from "models/groups/group";
import { Notification } from "common/services/notification";
import { GroupDeleteModal } from "./group-delete-modal";
import { Company } from "models/companies/company";
import { State } from "common/store/store-model";

@autoinject()
export class GroupList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;
  private isLoaded: boolean = false;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.groupService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private store: StoreService,
    private router: Router,
    private groupService: GroupService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("GroupList");

    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // this.logger.debug("activate");

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {
    // this.logger.debug("bind");

    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.companyId) {

        this.groupService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded = true;
        
        return await this.databind();
      }
    }
  }

  // public async attached(): Promise<any> {

  //   // this.logger.debug("baseUrl:", this.router.baseUrl);

  //   if (!this.isAttached) {
  //     // subscription
  //     this.subscription = this.ea.subscribe(SelectedCompanyChangeMessage, (message: SelectedCompanyChangeMessage) => {
  //       this.logger.debug("event companySwitcher: ", message.selectedCompanyId);
  //       this.databind();
  //     });
  //   }
  //   else {
  //     if (this.companyId) {
  //       this.groupService.client.selectedCompanyId = this.companyId;
  //       return await this.databind();
  //     }
  //   }

  // }

  // public detached(): void {
  //   if (!this.isAttached) {
  //     this.subscription.dispose();
  //   }
  // }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async create(): Promise<any> {
    let item: Group = new Group();
    return await this.dialogService.open({
      viewModel: GroupModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.logger.debug("response.output", response.output);
        return await this.groupService.create(item)
          .then(async (model: Group) => {
            item.id = model.id;
            if (response.output) {
              this.logger.debug("Save and continue id: ", model.id);
              return await this.router.navigateToRoute("group-child-router", { groupId: model.id });
            } else {
              this.parameters.tableData.unshift(item);
              this.parameters.totalRecords++;
              this.notification.success("notifications.item_added");
              // return await this.databind();
            }

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  // private edit(groupId: number): void {
  //   this.router.navigate(`group/${groupId}`);
  // }

  private async delete(item: Group): Promise<void> {
    return await this.dialogService.open({
      viewModel: GroupDeleteModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (response.output) {
          let groupIdToTransferUsers: number = <number>response.output;
          this.logger.debug("groupIdToTransferUsers: ", groupIdToTransferUsers);
          return await this.groupService.delete(item.id, groupIdToTransferUsers)
            .then(() => {
              let indexOfEditedModel: number = this.parameters.tableData.map(x => x.id.toString()).indexOf(item.id.toString());
              this.parameters.tableData.splice(indexOfEditedModel, 1);
              this.parameters.totalRecords--;
              this.notification.success("notifications.item_deleted");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      }
    });
  }

}
