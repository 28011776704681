import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { BulkDownloadService } from "../../services/people/bulk-download-service";
import { DialogService, DialogCloseResult } from 'aurelia-dialog';

@autoinject()
export class BulkDownloadList {

  private logger: Logger;
  private params: any = {};
  public companyId: number;
  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    let search = this.parameters.search.Query;
    sessionStorage.setItem('bulkDownloadListSearch', search == null ? '' : search);

    return await this.bulkDownloadService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private bulkDownloadService: BulkDownloadService,
    private notification: Notification,
    private dialogService: DialogService) {

    this.logger = LogManager.getLogger("BulkDownloadList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.parameters.search.Query = sessionStorage.getItem('bulkDownloadListSearch');
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;
      this.bulkDownloadService.client.currentCompanyId = this.companyId;

      return await this.databind();

    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private clearFilters() {
    this.parameters.search.Query = this.parameters.search.StartDate = this.parameters.search.EndDate = null;
  }

  private async regenerateBulkDownload(id:number): Promise<any> {
    this.logger.info("Re-generateBulkDownload");
    try {
      await this.bulkDownloadService.regenerateBulkDownload(id);

      this.notification.success("notifications.item_edited");
      return await this.databind();
    }
    catch (error) {
      this.notification.error(error);
    };
  }

}
