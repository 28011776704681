// export class EducationalLevel{
//     items = [
//         {"id":1,"name": "main.admin.pages.scales.educational_level_enum.level_one", "code": "level.one"},
//         {"id":2,"name": "main.admin.pages.scales.educational_level_enum.level_two", "code": "level.two"},
//         {"id":3,"name": "main.admin.pages.scales.educational_level_enum.level_three", "code": "level.three"},
//         {"id":4,"name": "main.admin.pages.scales.educational_level_enum.level_four", "code": "level.four"},
//         {"id":5,"name": "main.admin.pages.scales.educational_level_enum.level_five", "code": "level.five"},
//         {"id":6,"name": "main.admin.pages.scales.educational_level_enum.level_six", "code": "level.six"}
//     ];

//     getNameById(id: number): string{
//         return this.items.find(x => x.id == id)['name'];
//     }

//     getIdByCode(code: string): number{
//         return this.items.find(x => x.code == code)['id'];
//     }
// }

export enum EducationalLevel {
  LevelOne,
  LevelTwo,
  LevelThree,
  LevelFour,
  LevelFive,
  LevelSix
}
