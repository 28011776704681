import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { Notification } from "common/services/notification";
import { FileAcceptValidator } from 'common/utils/file-accept-validator';
import { CompanyEditedMessage } from 'messages/companyEditedMessage';
import { EventAggregator } from 'aurelia-event-aggregator';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { CompanyDetailMasterService } from '../../services/companies/company-detail-master-service';
import { CompanyDetailMaster } from 'models/companies/company-detail-master';
import { Company } from 'models/companies/company';

@autoinject()
export class CompanyMasterEdit {

  private logger: Logger;

  private state: State;
  private franchise: Company;
  private companyGroup: Company;

  private model: CompanyDetailMaster = new CompanyDetailMaster();

  constructor(
    private store: StoreService,
    private companyDetailMasterService: CompanyDetailMasterService,
    private notification: Notification,
    private router: Router,
    private ea: EventAggregator) {


    this.logger = LogManager.getLogger("CompanyMasterEdit");

    this.state = this.store.state;
  }


  private async activate(params: any): Promise<void> {
    this.logger.info("activate");

    if (!isNaN(params.companyDetailId)) {
      try {
        this.model = await this.companyDetailMasterService.getById(+params.companyDetailId);
        this.setFranchiseAndCompanyGroup();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  private setFranchiseAndCompanyGroup() {
    if (this.state.companies.length > 0) {
      this.companyGroup = this.state.companies.find(x => x.id == this.model.company.parentId);
      this.franchise = this.state.companies.find(x => x.id == this.companyGroup.parentId);
    }
  }

  get canSave() {
    return !this.companyDetailMasterService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");

    return await this.companyDetailMasterService.update(this.model)
      .then(async (response: CompanyDetailMaster) => {
        this.model.logoUrl = this.model.logoUrl;

        // trigger company edited event
        // this.logger.debug("updated company", response.company);

        this.ea.publish(new CompanyEditedMessage(response.company));

        this.notification.success("main.admin.pages.companies.messages.company_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("company-group-master-list-selector");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  public setFile($event: any): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];
    let fileValidator = FileAcceptValidator.parse("image/*");
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var base64data: any = reader.result;
        this.model.logo = base64data;
        this.model.logoFileName = file.name;
        this.model.logoUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.companies.errors.error_bad_image");
    }
  }

  public unSetFile(): void {
    this.model.logo = null;
    this.model.logoFileName = null;
    this.model.logoUrl = null;
  }
}
