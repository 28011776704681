import { DialogController } from "aurelia-dialog";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Activation } from "models/test-person/activation";
import { CompanyQuestionaryService } from "main/admin/services/test-management/company-questionary-service";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { ItemToQualify } from "models/questionaries/itemToQualify";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Language } from "models/localizations/language";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { UserInfoLoadedMessage } from "messages/userInfoLoadedMessage";

@autoinject()
export class ObservationEditModal {

  private logger: Logger;

  public companyId: number;

  private state: State;

  private observationQuestionaries: Questionary[] = [];
  private languages: Language[]

  private model: Activation = new Activation();
  private validationController: ValidationController;
  
  private subscription: Subscription;
  

  constructor(
    private dialogController: DialogController,
    private store: StoreService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private companyQuestionaryService: CompanyQuestionaryService,
    protected ea: EventAggregator,
    private languageService: CoreLanguageService) {

      this.validationController = this.validationControllerFactory.createForCurrentScope();
      this.validationController.addRenderer(new BootstrapFormRenderer());
      this.logger = LogManager.getLogger("PeopleQuestionaryActivationModal");
      this.state = this.store.state;
      this.subscription = this.ea.subscribe(UserInfoLoadedMessage, (message: UserInfoLoadedMessage) => this.setUserLanguage.bind(this));  
    }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("questionaryId").displayName("main.test.pages.people.observation.questionaryId").required()
      .on(this.model);
  }

  public async activate(Activation: Activation): Promise<any> {
    this.logger.debug('Activate');

    this.model = Activation;

    this.companyId = this.model.companyId;

    this.companyQuestionaryService.client.currentCompanyId = this.companyId;

    const questionaries = await this.getQuestionaries();
    this.observationQuestionaries = questionaries.filter(itm => (itm.questionaryType != QuestionaryType.Competences &&  itm.itemToQualify === ItemToQualify.Observed));
    this.configureValidationRules();
  }

  private async bind(): Promise<any> {
    try {
      this.languages = await this.languageService.getAll();
      this.setUserLanguage();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find(lg => lg.culture === activeCulture);
      if (activeLang) {
        this.model.languageId = activeLang.id;
      }
    }
  }

  private async getQuestionaries(): Promise<Questionary[]> {
    return await this.companyQuestionaryService.getAll();
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (!this.model.questionaryId) {
            this.notification.warning("main.test.pages.people.errors.questionarygroup_or_reports_is_missing");
          } 
          else {
            return await this.save();
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async save(): Promise<any> {
    return await this.dialogController.ok();
    }
}
