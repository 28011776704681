import { CoreCompanyService } from '../../services/companies/core-company-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { CatalogService } from "../../../services/catalogs/catalog-service";
import { CatalogBase } from "models/catalogs/catalog-base";
import { CatalogChild } from "models/catalogs/catalog-child";
import { CompanyGroupService } from "../../services/companies/company-group-service";
import { CompanyGroup } from "models/companies/company-group";
import { EventAggregator } from "aurelia-event-aggregator";
import { CompanyEditedMessage } from "messages/companyEditedMessage";
import { Company } from 'models/companies/company';


@autoinject()
export class CompanyGroupEdit {

  private logger: Logger;
  private model: CompanyGroup = new CompanyGroup();
  private franchise: Company;
  private validationController: ValidationController;

  private economicSectors: CatalogBase[] = [];
  private countries: CatalogBase[] = [];
  private regions: CatalogChild[] = [];
  private cities: CatalogChild[] = [];

  constructor(
    private catalogService: CatalogService,
    private coreCompanyService: CoreCompanyService,
    private companyGroupService: CompanyGroupService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    private ea: EventAggregator) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CompanyGroupEdit");

  }

  private async activate(params: any): Promise<void> {
    this.logger.info("activate");

    try {

      if (!isNaN(params.franchiseId)) {

        this.economicSectors = await this.catalogService.getEconomicSectorAll();
        this.countries = await this.catalogService.getCountryAll();
        this.franchise = await this.coreCompanyService.getById(+params.franchiseId);

        if (!isNaN(params.companyGroupId)) {
          this.model = await this.companyGroupService.getById(+params.companyGroupId);
          this.regions = await this.catalogService.getRegionsByCountryId(this.model.countryId);
          this.cities = await this.catalogService.getCitiesByRegionId(this.model.regionId);
        }
        else {
          this.model.company.parentId = this.franchise.id;
          this.model.company.createdOn = new Date();          
        }
        this.configureValidationRules();
      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    this.logger.debug("model", this.model.company);
    ValidationRules
      .ensure("name").displayName("main.admin.pages.company_groups.name").required()
      .on(this.model.company)
      .ensure("economicSectorId").displayName("catalogs.economic_sectors.title").required()
      .ensure("countryId").displayName("catalogs.countries.title").required()
      .ensure("regionId").displayName("catalogs.regions.title").required()
      .ensure("cityId").displayName("catalogs.cities.title").required()
      .on(this.model);
  }

  // public cancel(): void {
  //   this.logger.info("cancel");
  //   this.router.parent.navigate(`companies/franchise/${this.model.company.parentId}/company-groups`);
  // }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      });
  }

  get canSave() {
    return !this.companyGroupService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    if (!this.model.id) {
      return await this.companyGroupService.create(this.model)
        .then(async (response: CompanyGroup) => {
          this.model = response;

          // trigger company edited event
          this.ea.publish(new CompanyEditedMessage(this.model.company));

          this.notification.success("main.admin.pages.company_groups.messages.company_group_added");

          if (!keepEditing) {
            return await this.router.navigateToRoute("company-groups");
          } else {
            return await this.router.navigateToRoute("company-group-child-router", { companyGroupId: this.model.companyId });
          }

        }).catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.companyGroupService.update(this.model)
        .then(async () => {
          // trigger company edited event
          this.ea.publish(new CompanyEditedMessage(this.model.company));

          this.notification.success("main.admin.pages.company_groups.messages.company_group_edited");

          if (!keepEditing) {
            return await this.router.navigateToRoute("company-groups");
          }

        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  public async filterRegion(countryId: number): Promise<void> {
    this.model.regionId = null;
    this.model.cityId = null;

    this.regions = await this.catalogService.getRegionsByCountryId(countryId);
  }

  public async filterCity(regionId: number): Promise<void> {
    this.model.cityId = null;

    this.cities = await this.catalogService.getCitiesByRegionId(regionId);
  }

}
