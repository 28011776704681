
import { autoinject, LogManager } from "aurelia-framework";
import { Router } from "aurelia-router";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { PlanService } from "../../../services/plans/plan-service";
import { Plan } from "models/plans/plan";


@autoinject()
export class CrossPlanItemsEdit {

  private logger: Logger;

  private planId: number;
  private plan: Plan = new Plan();

  constructor(
    private planService: PlanService,
    private notification: Notification,
    private router: Router) {

    this.logger = LogManager.getLogger("CrossPlanItemsEdit");
  }

  private async activate(params: any): Promise<void> {
    this.logger.info("activate");

    if (!isNaN(params.planId)) {
      this.planId = +params.planId;
      return await this.planService.getById(this.planId)
        .then((plan: Plan) => {
          this.plan = plan;
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  get canSave() {
    return !this.planService.client.isRequesting;
  }

  public async submit(keepEditing: boolean): Promise<any> {
    this.logger.info("submit");

    return await this.planService.update(this.plan)
      .then(async () => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("plans");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  // public cancel(): void {
  //   this.logger.info("cancel");
  //   this.router.parent.navigate(`plans/franchise/${this.plan.companyId}/plans`);
  // }

  public async changeCrossItemsState(): Promise<void> {
    if (!this.plan.isCrossItemsActive) {
      this.plan.hasHelp = false;
      this.plan.hasTheMethod = false;
      this.plan.hasWebChat = false;
      this.plan.hasMicrosite = false;
    }
    
    return await this.planService.changeCrossItemsState(this.plan.id)
      .then(() => {
        this.notification.success("main.admin.pages.plans.messages.plan_edited");
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

}
