import { bindable, bindingMode, customElement, LogManager, autoinject, observable } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { CurrentCompanyChangeMessage } from 'messages/currentCompanyChangeMessage';
import { StoreService } from 'common/store/store-service';
import { State } from 'common/store/store-model';

import $ from 'jquery';
import "corejs-typeahead";
import * as Bloodhound from 'corejs-typeahead/dist/bloodhound';
import config from 'config.js';

@customElement("company-tags-input")
@autoinject()
export class CompanyTagsInput {

  private logger: Logger;

  private companySwitcherSubscriber: Subscription;

  private backendUrl: string;

  private state: State;

  @observable()
  private companyTags: any;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public value;

  // picker options
  @bindable()
  public options: any;

  @bindable()
  public disabled: boolean = false;

  @observable()
  public currentCompanyId: number;



  constructor(
    private element: Element,
    private store: StoreService,
    private ea: EventAggregator,
    // private sessionService: SessionService
  ) {

    this.logger = LogManager.getLogger("CompanyTagsInput");

    this.state = this.store.state;

    this.companySwitcherSubscriber = this.ea.subscribe(CurrentCompanyChangeMessage, (message: CurrentCompanyChangeMessage) => {
      // this.companylogger.debug("event companySwitcher: ", message.currentCompanyId);
      this.currentCompanyId = message.currentCompanyId;
    });
  }

  public detached() {
    this.companySwitcherSubscriber.dispose();
  }

  private async currentCompanyIdChanged() {
    if (this.currentCompanyId) {
      await this.init();
    }
  }

  public async bind() {
    // this.logger.debug("currentCompanyId", this.currentCompanyId);
    if (this.state && this.state.currentCompany) {
      this.currentCompanyId = this.state.currentCompany.id;
    }
  }

  public async init() {
    //base api
    let apiUrl = config.URLS_BACKEND;
    this.backendUrl = `${apiUrl}/api/v1/company/${this.currentCompanyId}/companytag`;

    this.companyTags = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: 'http://set-by-interceptor',//this.backendUrl,
        prepare: (query, settings) => this.prepareQuery(query, settings),
        rateLimitWait: 500 //ms
      }
    });

    await this.companyTags.initialize();
    // this.logger.debug("companyTags initialized");
  }

  public companyTagsChanged() {
    if (this.companyTags) {
      // this.logger.debug("companyTagsChanged ok");
      this.options = {
        typeaheadjs: {
          name: 'tags',
          async: true,
          limit: 10, // needs to be same as whatever is fetched by api
          display: res => res.name,
          source: this.companyTags.ttAdapter()
        }
      };
    }
  }

  public prepareQuery(query, settings: any) {
    settings.url = this.backendUrl + '?queryString=' + encodeURIComponent(query);
    settings.headers = {
      'Accept': 'application/json',
    }
    return settings;
  }


}
