import { Person } from "models/test-person/person";
import { ActivationQuestionaryStatus } from "models/test-person/activationQuestionaryStatus";

export class OrganizationEvaluator {
  id: number;
  organizationId: number;
  personId: number;
  evaluator: Person = new Person();
  weight: number;

  createdOn: Date;
  createdByName: string;

  activationQuestionaryStatus: ActivationQuestionaryStatus;
  applicationDate: Date;
}
