import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Comment } from "models/test-person/comment";

@autoinject()
export class CommentService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("CommentService");
  }

  public async list(parameters: UxDatatableParameters, personId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`comment/list/${personId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(personId: number): Promise<Comment[]> {
    return await this.client.get(`comment/${personId}`)
      .then((response: any) => {
        return <Comment[]>response;
      });
  }

  public async getById(id: number): Promise<Comment> {
    return await this.client.getById(`comment`, id)
      .then((response: Comment) => {
        return response;
      });
  }

  public async create(model: Comment, personId: number): Promise<Comment> {
    return await this.client.create(`comment/${personId}`, model)
      .then((response: any) => {
        return <Comment>response;
      });
  }

  public async update(model: Comment): Promise<void> {
    return await this.client.updateById(`comment`, model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById(`comment`, id)
      .then(() => null);
  }

}
