import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AppHttpClient } from "common/services/app-http-client";
import { Sheet } from "models/test-core/sheets/sheet";


@autoinject()
export class CoreSheetService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CoreSheetService");
  }

  public async getById(id: number): Promise<Sheet> {
    return await this.client.getById("coresheet", id)
      .then((response: Sheet) => {
        return response;
      });
  }


  public async list(languageId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`coresheet/list/${languageId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  // public async update(model: Sheet): Promise<Sheet> {

  //   let formData = new FormData();

  //   let thumbnailImage = null;
  //   if (model.thumbnailImage) {
  //     thumbnailImage = model.thumbnailImage[0];
  //   }

  //   let image = null;
  //   if (model.image) {
  //     image = model.image[0];
  //   }

  //   formData.append('thumbnailImage', thumbnailImage);
  //   formData.append('image', image);
  //   // formData.append('fullDescription', model.fullDescription);

  //   return await this.client.requestFormData("PUT", `coresheet/${model.id}`, formData) //.updateById("coresheet", model.id, null, model)
  //     .then((response) => { return <Sheet>response; });
  // }

  public async changeState(id: number): Promise<void> {
    return await this.client.post(`coresheet/change-state/${id}`)
      .then(() => null);
  }
}

