import { QuestionaryLanguage } from "models/questionaries/questionaryLanguage";
import { Questionary } from "../../questionaries/questionary";

export class QuestionaryGroup {
  id : number;
  name: string;
  description: string;
  questionaries : Questionary[] = [];
  questionaryIds: QuestionaryLanguage[] = [];
  questionariesLanguageId: number;
  isActive: boolean;
  createdOn: Date;
  createdBy: string;
  companyCompetenceIds: number[] = [];
  talentsPrice: number; 
}
