import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { TagManagment } from "models/tag/tag-managment";

@autoinject()
export class TagsModal {

  private logger: Logger;
  private model: TagManagment;
  private validationController: ValidationController;
  private tagName: string;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.logger = LogManager.getLogger("TagsModal");

  }

  private async activate(model: TagManagment): Promise<any> {
    this.model = model;
    this.tagName = this.model.tagName;
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.labels.name").required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();

  }
}
