import { Router } from "aurelia-router";
import { bindable } from "aurelia-framework";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { QuestionaryService } from "../../services/questionaries/questionary-service";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";

@autoinject()
export class QuestionaryKanban {

  @bindable 
  private routeName: string;

  private logger: Logger;
  private questionaries: Questionary[];

  constructor(
    private questionariesService: QuestionaryService,
    private router: Router,
    private notification: Notification,
    public questionaryType: QuestionaryType) {
    this.logger = LogManager.getLogger("QuestionaryKanban");
  }

  public async bind(): Promise<any> {
    return await this.questionariesService.getAll()
      .then(response => this.questionaries = response)
      .catch(error => {
        this.notification.error(error);
      });
  }

  public async selectQuestionary(questionaryId: number): Promise<any> {
    
    // this.router.navigate(`${this.baseUrl}/${questionaryId}/${this.postUrl}`);
    this.logger.debug("navigating to questionaryId:", questionaryId);
    return await this.router.navigateToRoute(this.routeName, {questionaryId: questionaryId});
  }

}
