// import * as $ from "jquery";
import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { CompanyGroupService } from "../../services/companies/company-group-service";
import { CompanyDetailService } from '../../services/companies/company-detail-service';

import { CompanyGroup } from "models/companies/company-group";
import { Company } from "models/companies/company";
import { ChangeStateConfirmation } from "../../../components/change-state-confirmation";
import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";
import { EventAggregator } from "aurelia-event-aggregator";
import { CompanyEditedMessage } from "messages/companyEditedMessage";
import { CompanyDeletedMessage } from "messages/companyDeletedMessage";

import * as $ from "jquery";

@autoinject()
export class CompanyGroupList {

  private logger: Logger;
  private params: any = {};
  private franchiseId: number;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private isLoaded: boolean = false;

  constructor(
    private companyGroupService: CompanyGroupService,
    private companyService: CompanyDetailService,
    private dialogService: DialogService,
    private notification: Notification,
    private router: Router,
    private ea: EventAggregator) {

    this.logger = LogManager.getLogger("CompanyGroupList");
  }

  private async activate(params: any): Promise<any> {
    // this.logger.info("activate");

    if (this.params.franchiseId != params.franchiseId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  public attached() {
    $('.company-accordion').on('click', function () {
      var button = $(this).find('.btn[data-toggle]');
      var header = $(this).find('.card-header');
      if (header.length && button.length && button.hasClass('collapsed')) {
        header.removeClass('collapsed');
      } else {
        header.addClass('collapsed');
      }
    });
  }

  private async bind(): Promise<any> {
    this.logger.info("bind");
    if (!isNaN(this.params.franchiseId)) {
      this.franchiseId = +this.params.franchiseId;
      this.isLoaded = true;

      return await this.databind();
    }
  }

  public async databind(): Promise<void> {
    return await this.companyGroupService.list(this.franchiseId, this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
        this.logger.debug("total records", this.parameters.totalRecords);
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.info("refreshing");
    try {
      const response = await this.companyGroupService.list(this.franchiseId, this.parameters);
      return response;
    }
    catch (error) {
      return this.notification.error(error);
    }
  }

  private async create(): Promise<any> {
    return await this.router.navigateToRoute("company-group-child-router", { companyGroupId: 'create' });
  }

  private async agreements(childrenId: number, parentId: number): Promise<any> {
    return await this.router.navigate(`company-group/${parentId}/company/${childrenId}/agreements`);
  }

  private async delete(companyGroup: CompanyGroup): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: companyGroup.company.name
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.companyGroupService.delete(companyGroup.id)
            .then(() => {

              // trigger company edited event
              this.ea.publish(new CompanyDeletedMessage(companyGroup.companyId));

              const indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id == companyGroup.id);
              this.parameters.tableData.splice(indexOfEditedModel, 1);
              this.parameters.totalRecords--;

              this.notification.success("notifications.item_deleted");
            })
            .catch(error => {
              this.notification.error(error);
            });
        }
      });
  }

  private async deleteCompany(company: Company): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: company.name
    })
      .whenClosed(async (response: DialogCloseResult) => {
        if (!response.wasCancelled) {
          return await this.companyService.delete(company.id)
            .then(() => {

              // trigger company edited event
              this.ea.publish(new CompanyDeletedMessage(company.id));

              let companyGroup = this.parameters.tableData.find(x => x.companyId == company.parentId);
              const indexOfEditedModel: number = companyGroup.companies.findIndex(x => x.id == company.id);
              companyGroup.companies.splice(indexOfEditedModel, 1);

              this.notification.success('notifications.item_deleted');
            })
            .catch((error: Error) => {
              this.notification.error(error);
            });
        }
      });
  }

  private async changeCompanyState(item: Company): Promise<void> {

    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.companyService.changeState(item.id)
          .then(() => {
            // this.databind();
            // item.isActive = !item.isActive;

            // trigger company edited event
            this.ea.publish(new CompanyEditedMessage(item));

            this.notification.success("main.admin.pages.companies.messages.state_changed");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        item.isActive = !item.isActive;
      }
    });
  }

  private async changeCompanyGroupState(item: CompanyGroup): Promise<void> {

    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.company.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.companyGroupService.changeState(item.companyId)
          .then(() => {
            // this.databind();
            // item.company.isActive = !item.company.isActive;
            item.companies.forEach(x => {
              x.isActive = item.company.isActive;
            });

            // trigger company edited event
            this.ea.publish(new CompanyEditedMessage(item.company));

            this.notification.success("main.admin.pages.company_groups.messages.state_changed");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        item.company.isActive = !item.company.isActive;
      }
    });
  }


}
