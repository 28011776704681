import { User } from '../security/user';
import { Company } from '../companies/company';

export class Wallet {
  id: number;
  company: Company = new Company();
  companyId: number;
  talent: number;
  canDiscountGeneralWallet: boolean;
  observation:  string;
  userId:  number;
  user: User = new User();
  amount: number;
}
