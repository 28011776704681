
import { autoinject, bindable, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { UxDatatableParameters } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { BulkTalentsExport } from "models/talents/bulkTalentsExport";
import { ExportTransactionType } from "models/talents/export-transaction-type";
import { BulkTalentsExportService } from "main/admin/services/talents/bulk-talents-export-service";
import { RouteConfig, Router } from "aurelia-router";
import { TalentExportConfirmation } from "main/components/talent-export-confirmation";


@autoinject()
export class TalentExportCard {

  private logger: Logger;
  public companyId: number;
  @bindable
  private parameters = new UxDatatableParameters();
  @bindable
  private exportTransactionTypeId : number = 0;
  private model : BulkTalentsExport = new BulkTalentsExport();

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private bulkTalentsExportService : BulkTalentsExportService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("TalentExportCard");
  }
  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");
  }

  private async create(totalResults: number): Promise<any> {
    return await this.dialogService.open({
      viewModel: TalentExportConfirmation,
      model: totalResults
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        try
        {
          if (this.parameters.totalRecords > 1000){
            this.notification.warning("main.test.pages.people.errors.total_records");
          }
          else if(this.parameters.totalRecords == 0){
            this.notification.warning("main.talents.pages.export_talents.zero.total_records");
          }
          else
          {
            this.model.exportTransactionTypeId = this.exportTransactionTypeId;
            this.model.transactionTypeId = this.parameters.search.TransactionId;
            if(this.exportTransactionTypeId==(ExportTransactionType.Transfers))
            this.model.transactionTypeId = this.exportTransactionTypeId;
            this.model.startDate = this.parameters.search.StartDate;
            this.model.endDate = this.parameters.search.EndDate;
            this.model.totalResults = this.parameters.totalRecords;
            this.model.userId = this.parameters.search.UserId;
            this.model = await this.bulkTalentsExportService.create(this.model);
            this.notification.success("notifications.item_added");
            return await this.router.navigateToRoute("bulk-talents-export-list");
          }
          
        }
        catch (error) {
          this.notification.error(error);
        }
      }
    });
  }
}
