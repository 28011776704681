import { autoinject, LogManager, observable } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration, RouteConfig } from "aurelia-router";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult } from "aurelia-dialog";

import { LabelService } from "../../services/localizations/label-service";
import { Label } from "models/localizations/label";
import { DeleteConfirmation } from "../../../components/delete-confirmation";
import { Notification } from "common/services/notification";
import { LabelModal } from "./label-modal";
import {
  UxDatatableParameters,
  UxDatatableCustomAttribute,
  UxDatatableResponse
} from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject
export class LabelList {

  private logger: Logger;
  private data: Array<Label> = [];
  private parameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private host: string;


  private refresh = (): Promise<UxDatatableParameters> => {
    this.logger.debug("refreshing");
    return this.labelService.list(this.parameters);
  }

  constructor(
    private router: Router,
    private labelService: LabelService,
    private dialogService: DialogService,
    private notification: Notification) {
    this.logger = LogManager.getLogger("LabelList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");
    // return await this.databind();
  }

  private async bind(): Promise<any> {
    return await this.databind();
  }

  private async databind(): Promise<any> {
    this.logger.debug("bind");
    return await this.labelService.list(this.parameters)
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async create(): Promise<void> {
    let item: Label = new Label();
    return await this.dialogService.open({
      viewModel: LabelModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.labelService.create(item)
          .then(async (label: Label) => {
            item.id = label.id;
            this.parameters.tableData.unshift(item);
            this.parameters.totalRecords++;
            this.notification.success("notifications.item_added");
            // return await this.databind();
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  public async edit(item: Label): Promise<void> {
    return await this.dialogService.open({
      viewModel: LabelModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.labelService.update(item)
          .then(async () => {
            // var name = this.model.name;
            this.notification.success("main.admin.pages.labels.notifications.edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  public async delete(id: number, name: string): Promise<void> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.labelService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.labels.notifications.deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }
}
