import { AuthorizationService } from 'aurelia-permission';

import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class TestCoreChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {
    this.logger = LogManager.getLogger("TestCoreChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./test-core-home"),
        name: "test-core-home",
        title: this.i18n.tr("main.admin.pages.test_core.title"),
        settings: {
          isHome: true
        }
      },
            
      {
        route: "core-company-competences",
        moduleId: PLATFORM.moduleName("./company-competences/company-competence-list-child-router"),
        name: "core-company-competences-list-child-router",
        title: this.i18n.tr("main.admin.pages.test_core.company_competences.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestCoreCompetencesView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestCoreCompetencesView]
          },
          info: this.i18n.tr("main.admin.pages.test_core.company_competences.info")
        }
      },
      
      {
        route: "sheets",
        moduleId: PLATFORM.moduleName("./sheets/sheet-list-child-router"),
        name: "sheet-list-child-router",
        title: this.i18n.tr("main.admin.pages.test_core.sheets.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.SheetView),
        settings: {
          permission: {
            only: [PredefinedPermissions.SheetView]
          },
          info: this.i18n.tr("main.admin.pages.test_core.sheets.info")
        }
      },

      {
        route: "news",
        moduleId: PLATFORM.moduleName("./news/news-list-child-router"),
        name: "news-list-child-router",
        title: this.i18n.tr("main.admin.pages.test_core.news.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestCoreNewsView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestCoreNewsView]
          },
          info: this.i18n.tr("main.admin.pages.test_core.news.info")
        }
      },

      {
        route: "service-rating",
        moduleId: PLATFORM.moduleName("./service-rating/service-rating-list-child-router"),
        name: "service-rating-list-child-router",
        title: this.i18n.tr("main.admin.pages.test_core.service_rating.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestCoreNewsView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestCoreNewsView]
          },
          info: this.i18n.tr("main.admin.pages.test_core.service_rating.info")
        }
      },

      {
        route: "inbox",
        moduleId: PLATFORM.moduleName("./inbox/inbox-list-child-router"),
        name: "inbox-list-child-router",
        title: this.i18n.tr("main.admin.pages.inbox.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestCoreInboxView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestCoreInboxView]
          },
          info: this.i18n.tr("main.admin.pages.inbox.info")
        }
      },



    ]);
  }
}
