import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Sheet } from "models/test-core/sheets/sheet";

@autoinject()
export class CompanySheetService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CompanySheetService");
  }

  public async list(search: string): Promise<Sheet[]> {
    return await this.client.post("companysheet/list", { search })
      .then((response: any) => {
        return <Sheet[]>response;
      });
  }

  public async getAll(): Promise<Sheet[]> {
    return await this.client.get("companysheet")
      .then((response: Sheet[]) => {
        return response;
      });
  }
}
