import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { AgreementFile } from 'models/agreements/agreementFile';
import { CompanyAppHttpClient } from "common/services/company-app-http-client";

@autoinject()
export class AgreementFileService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("AgreementFileService");
  }

  public async getById(id: number): Promise<AgreementFile> {
    return await this.client.getById("agreementfile", id)
      .then((response: AgreementFile) => {
        return response;
      });
  }

  public async list(agreementId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`agreementfile/list/${agreementId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async create(model: AgreementFile): Promise<AgreementFile> {

    let formData = new FormData();

    let file = null;
    if(model.file){
      file = model.file[0];
    }

    formData.append('file', file);
    formData.append('fileName', model.fileName);
    formData.append('agreementId', model.agreementId.toString());

    return await this.client.requestFormData("POST", `agreementfile`, formData)
      .then((response) => { return <AgreementFile>response; });
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("agreementfile", id)
      .then(() => null);
  }
  public async getMediaByAgreementFileId(id: number): Promise<string> {
    return await this.client.get(`agreementFile/get-media-by-agreementFileId/${id}`)
    .then((response: any) => {
      return <string>response;
    });
  }

}

