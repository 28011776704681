import { UserManagerSettings, WebStorageStateStore, Log } from "oidc-client";

const openIdConfig = (authorityUrl: string, hostUrl: string) => {

  const setup = {
    logLevel: Log.INFO,
    // loginRedirectRoute: "/",
    // logoutRedirectRoute: "/",
    // unauthorizedRedirectRoute: "/login",
    
    userManagerSettings: {
      // The number of seconds in advance of access token expiry
      // to raise the access token expiring event.
      accessTokenExpiringNotificationTime: 1000,
      // Your own OpenID Provider or a certified authority
      // from the official list http://openid.net/certification/
      authority: `${authorityUrl}`,//environment.urls.backend,
      automaticSilentRenew: true,

      // interval in milliseconds to check the user's session
      checkSessionInterval: 3600,
      // The client or application ID that the authority issues.
      client_id: "tht-frontend",
      filterProtocolClaims: false,
      loadUserInfo: false,

      post_logout_redirect_uri: `${hostUrl}/signout-oidc`,
      redirect_uri: `${hostUrl}/signin-oidc`,
      response_type: "code",
      // scope: "openid email roles profile permissions company language timezone",
      scope: "openid email basic_info permissions",

      silentRequestTimeout: 10000,
      silent_redirect_uri: `${hostUrl}/signin-oidc`,
      includeIdTokenInSilentRenew: true,

      userStore: new WebStorageStateStore({
        prefix: "oidc",
        store: window.localStorage,
      }),
    } as UserManagerSettings,
  };

  return setup;
}

export default openIdConfig;
