import { Activation } from 'models/test-person/activation';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";


@autoinject()
export class TeamPersonActivationService {

  constructor(
    public client: CompanyAppHttpClient) {
  }

  public async getByPersonId(personId: number): Promise<Array<Activation>> {
    return await this.client.get(`teampersonactivation/${personId}`)
      .then((response: any) => {
        return <Array<Activation>>response;
      });
  }
}
