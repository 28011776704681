import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { Logger } from "aurelia-logging";
import { I18N } from "aurelia-i18n";
import { LogManager, autoinject } from "aurelia-framework";

@autoinject
export class CustomCompanyQuestionChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("CustomCompanyQuestionChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([

      {
        route: "",
        name: "custom-company-question-edit",
        moduleId: PLATFORM.moduleName("./custom-company-question-edit"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.questionaries.questions.question"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.TestManagementCustomCompanyQuestionaryUpdate]
          }
        }
      },
      
    ]);

    this.router = router;
  }

}
