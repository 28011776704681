import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { EducationalLevelCountry } from "models/educational-levels-by-country/educational-level-country";

@autoinject()
export class EducationalLevelCountryService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("EducationalLevelCountryService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`educationallevelcountry/list`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<EducationalLevelCountry[]> {
    return await this.client.get("educationallevelcountry")
      .then((response: any) => {
        return <EducationalLevelCountry[]>response;
      });
  }

  public async getByCountry(countryId: number): Promise<EducationalLevelCountry[]> {
    return await this.client.get(`educationallevelcountry/country/${countryId}`)
      .then((response: any) => {
        return <EducationalLevelCountry[]>response;
      });
  }

  public async update(model: EducationalLevelCountry): Promise<EducationalLevelCountry> {
    return await this.client.updateById('educationallevelcountry', model.id, null, model)
      .then(response => {
        return <EducationalLevelCountry>response;
      });
  }

  public async changeState(model: EducationalLevelCountry): Promise<EducationalLevelCountry> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.post(`educationallevelcountry/change-state/${model.id}`, model)
      .then(response => {
        return <EducationalLevelCountry>response;
      });
  }

}
