
import { LogManager } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";

export class CustomCompanyQuestionaryListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor() {
    this.logger = LogManager.getLogger("CustomCompanyQuestionaryListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./custom-company-questionary-list"),
        name: "custom-company-questionary-list",

      },

      {
        route: "custom-company-questionary/:questionaryId",
        moduleId: PLATFORM.moduleName("./custom-company-questionary-child-router"),
        name: "custom-company-questionary-child-router",

      },
      {
        route: "create/question/:questionTypeId",
        name: "custom-company-question-create",
        moduleId: PLATFORM.moduleName("./custom-company-questions/custom-company-question-child-router"),
        nav: false
        //title: this.i18n.tr("main.admin.pages.questionaries.questions.create"),
       
      },
    ]);
  }
}
