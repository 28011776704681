import { AuthorizationService } from 'aurelia-permission';

import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class TestManagementChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {
    this.logger = LogManager.getLogger("TestManagementChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./test-management-home"),
        name: "test-management-home",
        title: this.i18n.tr("main.admin.pages.test_management.title"),
        settings: {
          isHome: true
        }
      },
                              
      {
        route: "report-templates",
        moduleId: PLATFORM.moduleName("./report-templates/report-template-list-child-router"),
        name: "report-template-list-child-router",
        title: this.i18n.tr("main.admin.pages.test_management.report_templates.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementReportView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestManagementReportView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.report_templates.info")
        }
      },

      {
        route: "questionary-groups",
        moduleId: PLATFORM.moduleName("./questionary-groups/questionary-group-list-child-router"),
        name: "questionary-group-list-child-router",
        title: this.i18n.tr("main.admin.pages.test_management.questionary_groups.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementQuestionaryGroupView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestManagementQuestionaryGroupView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.questionary_groups.info")
        }
      },

      {
        route: "disc-profiles",
        moduleId: PLATFORM.moduleName("./disc-profiles/disc-profile-edit"),
        name: "disc-style-edit",
        title: this.i18n.tr("main.admin.pages.test_management.disc_profiles.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementDiscProfileView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestManagementDiscProfileView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.disc_profiles.info")
        }
      },

      {
        route: "disc-styles",
        moduleId: PLATFORM.moduleName("./disc-styles/disc-style-list"),
        name: "disc-style-list",
        title: this.i18n.tr("main.admin.pages.test_management.disc_styles.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementDiscStyleView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestManagementDiscStyleView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.disc_styles.info")
        }
      },

      {
        route: "questionaries-configuration",
        moduleId: PLATFORM.moduleName("./questionaries-configuration/questionary-configuration-list"),
        name: "questionary-configuration-list",
        title: this.i18n.tr("main.admin.pages.test_management.questionary_configuration.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementQuestionaryConfigurationView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestManagementQuestionaryConfigurationView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.questionary_configuration.info")
        }
      },
      
      {
        route: "custom-company-questionaries",
        moduleId: PLATFORM.moduleName("./custom-company-questionaries/custom-company-questionary-list-child-router"),
        name: "custom-company-questionary-list-child-router",
        title: this.i18n.tr("main.admin.pages.questionaries.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementCustomCompanyQuestionaryView),
        settings: {
          isVideoInterViewTest: false,
          permission: {
            only: [PredefinedPermissions.TestManagementCustomCompanyQuestionaryView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.custom_company_questionary_configuration.info")
          // icon: "ticon-cuestionarios"
        }
      },
      
      {
        route: "custom-company-video-enterview",
        moduleId: PLATFORM.moduleName("./custom-company-questionaries/custom-company-questionary-list-child-router"),
        name: "custom-company-questionary-list-child-router",
        title: this.i18n.tr("main.admin.pages.questionaries_videointerview.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementCustomCompanyQuestionaryView),
        settings: {
          isVideoInterViewTest: true,
          permission: {
            only: [PredefinedPermissions.TestManagementCustomCompanyQuestionaryView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.custom_company_questionary_videointerview_configuration.info")
        }
      },
                  
      {
        route: "company-competences",
        moduleId: PLATFORM.moduleName("./company-competences/company-competence-list"),
        name: "company-competences-list",
        title: this.i18n.tr("main.admin.pages.test_management.company_competences.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementCompetencesView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestManagementCompetencesView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.company_competences.info")
        }
      },
                              
      {
        route: "report-templates-predictor",
        moduleId: PLATFORM.moduleName("./report-templates-predictor/report-template-predictor-list-child-router"),
        name: "report-template-predictor-list-child-router",
        title: this.i18n.tr("main.admin.pages.test_management.report_templates_predictor.title"),
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestManagementReportPredictorView),
        settings: {
          permission: {
            only: [PredefinedPermissions.TestManagementReportPredictorView]
          },
          info: this.i18n.tr("main.admin.pages.test_management.report_templates_predictor.info")
        }
      },


    ]);
  }
}
