import { Person } from 'models/test-person/person';
import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Activation } from "models/test-person/activation";

@autoinject()
export class ActivationService {

  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {
    this.logger = LogManager.getLogger("ActivationService");
  }

  public async list(parameters: UxDatatableParameters, personId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`activation/list/${personId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async listObserved(parameters: UxDatatableParameters, personId: number): Promise<UxDatatableResponse> {
    return await this.client.post(`activation/list-observed/${personId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAllByPersonId(personId: number): Promise<Activation[]> {
    return await this.client.get(`activation/person/${personId}`)
      .then((response: any) => {
        return <Activation[]>response;
      });
  }

  public async getById(id: number): Promise<Activation> {
    return await this.client.getById(`activation`, id)
      .then((response: Activation) => {
        return response;
      });
  }

  public async create(model: Activation, personId: number): Promise<Activation> {
    return await this.client.create(`activation/${personId}`, model)
      .then((response: any) => {
        return <Activation>response;
      });
  }

  public async update(model: Activation): Promise<void> {
    return await this.client.updateById(`activation`, model.id, null, model)
      .then(() => null);
  }

  // public async delete(id: number, personId: number): Promise<void> {
  //   return await this.client.deleteById(`activation/${personId}`, id)
  //     .then(() => null);
  // }

}
