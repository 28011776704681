import { ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from 'aurelia-logging';
import { AgreementFile } from 'models/agreements/agreementFile';
import { FileAcceptValidator } from 'common/utils/file-accept-validator';
import { Notification } from 'common/services/notification';

@autoinject()
export class AgreementFileModal {

  private model: AgreementFile = new AgreementFile();
  private validationController: ValidationController;
  private logger: Logger;

  constructor(
    private notification: Notification,
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ActivityModal");
  }

  public async activate(model: AgreementFile): Promise<void> {
    this.logger.debug("activate");
    this.model = model;
    this.configureValidationRules();
  }

  configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("fileName").displayName("main.admin.pages.agreements.agreement_file.name").required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();
  }

  public setFile($event: any): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];

    // this.logger.debug("file", file);

    let fileValidator = FileAcceptValidator.parse("image/*,application/pdf", 1024 * 20000);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.model.fileName = file.name;
        this.model.fileUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.agreements.agreement_file.bad_file");

    }
  }

}
