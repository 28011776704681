import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogService, DialogCloseResult } from "aurelia-dialog";


import { Notification } from "common/services/notification";

import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

import { RestoreConfirmation } from "../../../components/restore-confirmation";
import { User } from "models/security/user";

import { UserService } from "../../services/users/user-service";
import { MasterUserService } from "../../services/users/master-user-service";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from 'models/companies/company';

@autoinject()
export class UserArchiveList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private company: Company;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private state: State;

  private isLoaded: boolean = false;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.userService.listArchive(this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private store: StoreService,
    private userService: UserService,
    private dialogService: DialogService,
    private notification: Notification,
    //private router: Router,
    private masterUserService: MasterUserService) {

    this.logger = LogManager.getLogger("UserList");

    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }

  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;

      if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
        this.companyId = null;
      }
      else if (!isNaN(this.params.companyDetailId)) {
        this.companyId = +this.params.companyDetailId;
      }

      if (this.companyId) {

        this.userService.client.currentCompanyId = this.companyId;

        this.company = this.state.companies.find(x => x.id == this.companyId);

        this.isLoaded = true;

        return await this.databind();
      }
    }
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async update(item: User): Promise<void> {
    return await this.dialogService.open({
      viewModel: RestoreConfirmation,
      model: item.fullName
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        try {
          await this.userService.userRestore(item);

          let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === item.id);
          this.parameters.tableData.splice(indexOfEditedModel, 1);
          this.parameters.totalRecords--;

          this.notification.success("main.admin.pages.archive.notifications.change_state");
        } catch (error) {
          this.notification.error(error);
        }
      }
    });
  }


}

