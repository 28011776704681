import { ActivationType } from '../../../../models/test-person/activationType';
import { Activation } from 'models/test-person/activation';
import { autoinject, LogManager, Disposable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { Person } from "models/test-person/person";
import { Comment } from "models/test-person/comment";
import { PersonService } from "../../services/people/person-service";
import { Notification } from "common/services/notification";
import { Company } from "models/companies/company";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { PersonActivationType } from "models/test-person/personActivationType";
import { PeopleQuestionaryActivationModal } from '../people-questionary/people-questionary-activation-modal';
import { ActivationService } from 'main/test/services/people/activation-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PersonQuestionaryActivationMessage } from 'main/test/messages/personActivationMessage';
import { PeopleCommentEditModal } from '../people-comment/people-comment-edit-modal';
import { CommentService } from 'main/test/services/people/comment-service';
import { PersonCommentEditMessage } from 'main/test/messages/personCommentEditMessage';
import { PersonLoginInfoModal } from './person-login-info-modal';
import { DemographicData } from 'models/microsite/demographicData';
import { PersonDemographicDataService } from 'main/test/services/people/person-demographic-data-service';
import { PersonDemographicDataEditMessage } from 'main/test/messages/personDemographicDataEditMessage';
import { ObservationEditModal } from 'main/test/pages/observation/observation-edit-modal';
import { Report } from 'models/test-reports/reports/report';
import { ReportService } from '../../../test-reports/services/report-service';

@autoinject()
export class PersonEdit {

  private logger: Logger;

  public companyId: number;
  private company: Company;

  private state: State;
  private person: Person = new Person();
  private demographicData: DemographicData;
  private getdemographicData: boolean = true;

  private activations: Activation[];
  private showHomologations: boolean = false;
  private showObservations: boolean = false;

  private subscriptions: Disposable[] = [];

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private personService: PersonService,
    private reportService: ReportService,
    private personDemographicDataService: PersonDemographicDataService,
    private activationService: ActivationService,
    private commentService: CommentService,
    private dialogService: DialogService,
    private ea: EventAggregator
  ) {

    this.logger = LogManager.getLogger("PersonCreate");

    this.state = this.store.state;

    // event when new activation is created
    this.subscriptions.push(this.ea.subscribe(PersonDemographicDataEditMessage, this.getPersonDemographicData.bind(this, true)));

  }

  public async activate(params: any, routerConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");

    try {
      if (!isNaN(params.companyId)) {
        this.companyId = +params.companyId;

        if (this.companyId) {

          this.personService.client.currentCompanyId = this.companyId;

          if (!isNaN(params.personId)) {

            this.person = await this.personService.getById(+params.personId);

            // routerConfig.title = this.person.fullName;


            await this.getActivations();
            await this.setShowHomologationList();
            await this.setShowObservationList();
          }
        }
      }
    }
    catch (error) {
      this.notification.error(error.error);
    }
  }

  private async getPersonDemographicData(getAgain: boolean = false): Promise<any> {
    if(this.getdemographicData || getAgain){
      this.demographicData = await this.personDemographicDataService.get(this.person.id);
      this.getdemographicData = false;
    }
  }

  private async getActivations() {
    this.activations = await this.activationService.getAllByPersonId(this.person.id);
  }

  private async setShowHomologationList() {
    this.showHomologations = this.activations.filter(x => x.personActivationType == PersonActivationType.Homologation).length > 0;
  }

  private async setShowObservationList() {
    this.showObservations = this.activations.filter(x => x.personActivationType == PersonActivationType.Observed).length > 0;
  }

  private async createActivation(): Promise<any> {
    let item: Activation = new Activation();
    item.companyId = this.companyId;
    item.activationType = ActivationType.Person;
    item.personActivationType = PersonActivationType.Normal;

    return await this.dialogService.open({
      viewModel: PeopleQuestionaryActivationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.logger.debug("response.output", response.output);

        return await this.activationService.create(item, this.person.id)
          .then(async (model: Activation) => {
            item.id = model.id;

            if(response.output){
              let report = new Report();
              report = response.output;
              report.activationId = model.id;
              report.personId = this.person.id;
              report = await this.reportService.create(report);
              }

            this.notification.success("notifications.item_added");            

            // new activation
            this.ea.publish(new PersonQuestionaryActivationMessage());

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }   

  private async createObservation(): Promise<any> {
    let item: Activation = new Activation();
    item.companyId = this.companyId;
    item.activationType = ActivationType.Person;
    item.personActivationType = PersonActivationType.Observed;

    return await this.dialogService.open({
      viewModel: ObservationEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.activationService.create(item, this.person.id)
          .then(async (model: Activation) => {
            item.id = model.id;
            this.notification.success("notifications.item_added");
            // new activation
            this.ea.publish(new PersonQuestionaryActivationMessage());
            return await this.router.navigate(`/company/${this.companyId}/test/people/person-observation/${this.person.id}/observers/${item.id}`);
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }


  private async createComment(): Promise<any> {
    let item: Comment = new Comment();
    item.personId = this.person.id;

    return await this.dialogService.open({
      viewModel: PeopleCommentEditModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        this.logger.debug("response.output", response.output);

        return await this.commentService.create(item, this.person.id)
          .then(async (model: Comment) => {
            item.id = model.id;

            this.notification.success("notifications.item_added");

            // new activation
            this.ea.publish(new PersonCommentEditMessage());

          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  private async sendIndividualReminder(id: number): Promise<boolean> {
    try {
      await this.personService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async viewLoginInfo(personId: number): Promise<any> {
    return await this.dialogService.open({
      viewModel: PersonLoginInfoModal,
      model: personId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.sendIndividualReminder(personId);
      }
    });
  }

  // private async editdemographicData(): Promise<any> {
  //   return await this.dialogService.open({
  //     viewModel: PersonDemographicDataModal,
  //     model: this.demographicData
  //   }).whenClosed(async (response: DialogCloseResult) => {
  //     if (!response.wasCancelled) {
  //       this.logger.debug("response.output", response.output);

  //       await this.personDemographicDataService.update(this.demographicData);
  //       this.notification.success("notifications.item_added");
  //     }
  //   })
  //     .catch(error => {
  //       this.notification.error(error);
  //     });
  // }

  // private async updateTags(): Promise<any> {
  //   try {
  //     await this.personService.updateTags(this.person.id, this.person.tags);
  //     this.notification.success("notifications.item_edited");
  //   }
  //   catch (error) {
  //     this.notification.error(error);
  //   }
  // }

}
