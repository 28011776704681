import config from 'config.js';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { Language } from "models/localizations/language";
import { LanguageService } from "../../services/localizations/language-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { Culture } from "models/localizations/culture";
import { Flag } from "models/localizations/flag";

@autoinject
export class LanguageEdit {

  private logger: Logger;
  private model: Language = new Language();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;
  private cultures: Culture[] = [];
  private flags: Flag[] = [];
  private host: string;

  constructor(
    private router: Router,
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private languageService: LanguageService,
    ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    
    let backendUrl = config.URLS_BACKEND;

    this.host = backendUrl + "/modules/localization/assets/images/flags/";
    this.logger = LogManager.getLogger("LanguageEdit");

  }


  private async activate(params: any): Promise<any> {

    this.logger.debug("Activated id:", params.languageId);
    if (!params.languageId) {
      return;
    }

    await this.languageService.cultures()
      .then((response: Culture[]) => {
        this.cultures = response;
      });

    await this.languageService.flags()
      .then((response: Flag[]) => {
        this.flags = response;
      });

    if (!isNaN(params.languageId)) {
      return await this.languageService.getById(+params.languageId)
        .then((language: Language) => {
          this.model = language;
          this.configureValidationRules();
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
    else {
      this.configureValidationRules();
    }
  }

  private configureValidationRules(): void {
    this.logger.debug("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.languages.name").required()
      .ensure("culture").displayName("main.admin.pages.languages.culture").required()
      .ensure("flagImageFileName").displayName("main.admin.pages.languages.flag").required()
      .on(this.model);
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.logger.debug("submit");
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave(){
    return !this.languageService.client.isRequesting;
  }

  public async save(): Promise<any> {
    this.logger.debug("save");

    if (!this.model.id) {
      return await this.languageService.create(this.model)
        .then(async (model: Language) => {

          this.notification.success("notifications.item_added");

          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("languages");
          } else {
            return await this.router.navigateToRoute("language-child-router", { languageId: model.id });
          }
        })
        .catch(error => {
          this.notification.error(error);
        });
    } else {
      return await this.languageService.update(this.model)
        .then(async (language: Language) => {

          this.notification.success("notifications.item_edited");

          if (!this.saveAndContinue) {
            return await this.router.navigateToRoute("languages");
          } 

        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }
}
