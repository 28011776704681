
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class OrganizationChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("OrganizationChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./organization-edit"),
        name: "organization-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.organizations.organization"),
      },

      {
        route: "evaluators",
        moduleId: PLATFORM.moduleName("../organization-evaluator/organization-evaluator-list-child-router"),
        name: "organization-evaluator-list-child-router",
        nav: true,
        title: this.i18n.tr("main.test.pages.organizations.evaluators"),
      },

      {
        route: "disc",
        moduleId: PLATFORM.moduleName("../organization-disc/organization-disc-edit"),
        name: "organization-disc-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.organizations.disc"),
      },

    ]);
  }
}
