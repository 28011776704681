import { Person } from 'models/test-person/person';
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { PersonService } from 'main/test/services/people/person-service';

@autoinject()
export class EmailExists {
  private id: number;
  private model: Person;
  constructor
  (
    private dialogController: DialogController,
    private personService: PersonService)
   {
    this.id = null;
    
  }
  public async activate(id: number) {    
    this.model = await this.personService.getById(id);
  }
}
