
import { autoinject, LogManager } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";

import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject()
export class MessageChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {

    this.logger = LogManager.getLogger("MessageChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([

      {
        route: "",
        moduleId: PLATFORM.moduleName("./message-edit"),
        name: "message-activations",
        nav: false,
        title: this.i18n.tr("main.admin.pages.messages.activations"),
        // settings: {
        //   permission: {
        //     only: [PredefinedPermissions.MessageUpdate]
        //   },
        //   t: "main.admin.pages.messages.activations"
        // }
      },

    ]);
  }
}
