import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { I18N } from "aurelia-i18n";

import { GroupService } from "../../../services/groups/group-service";
import { PermissionService } from "../../../services/groups/permission-service";
import { Permission } from "models/groups/permission";
import { Group } from "models/groups/group";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from 'models/companies/company';

interface SetNameMap {
  [setName: string]: string;
}

interface DisableSetMap {
  [permission: string]: boolean;
}

@autoinject()
export class PermissionEdit {

  private logger: Logger;

  public companyId: number;
  private company: Company;

  private validationController: ValidationController;

  private groupId: number;
  private group: Group;
  private saveAndContinue: boolean = false;

  private permissions: Permission[] = [];

  private groupPermissionNamesSelected: Array<string> = new Array<string>();

  private disableSets: DisableSetMap = {};

  private setNames: SetNameMap = {};

  private state: State;

  constructor(
    private store: StoreService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private groupService: GroupService,
    private permissionService: PermissionService,
    private i18n: I18N) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PermissionEdit");

    this.state = this.store.state;
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    // this.logger.debug("activate");
    if (!isNaN(params.companyId) && !isNaN(params.groupId)) {
      this.companyId = +params.companyId;

      if (!isNaN(params.companyDetailId)) {
        this.companyId = +params.companyDetailId;
      }

      this.groupService.client.currentCompanyId = +params.companyId;
      this.company = this.state.companies.find(x => x.id == this.companyId);
      this.groupId = +params.groupId;

      try {
        this.group = await this.groupService.getById(this.groupId);
        this.permissions = await this.permissionService.getAll();

        for (let i: number = this.permissions.length - 1; i >= 0; i--) {
          let groupPermissionName: string = this.permissions[i].name;
          this.disableSets[groupPermissionName] = true;
        }

        this.groupPermissionNamesSelected = await this.groupService.getPermissionNamesById(this.groupId);

        for (let i: number = this.groupPermissionNamesSelected.length - 1; i >= 0; i--) {
          let groupPermissionName: string = this.groupPermissionNamesSelected[i];
          this.disableSets[groupPermissionName] = false;
          this.toggleSet(groupPermissionName);
        }
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return await this.save();
  }

  get canSave() {
    return !this.groupService.client.isRequesting;
  }

  public async save(): Promise<any> {
    return await this.groupService.updatePermissionsNamesById(this.groupId, this.groupPermissionNamesSelected)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!this.saveAndContinue) {
          return await this.router.navigateToRoute("groups");
        }

      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private getSubModulesByModule(moduleName: string): string[] {
    this.logger.debug("getSubModulesByModule:", moduleName);
    return this.permissions
      .filter(x => x.name.startsWith(moduleName + "_"))
      .sort((x, y) => x.id - y.id)//order by id
      .map(x => { return x.name.split("_").slice(0, 2).join("_"); })
      .filter((item, i, ar) => ar.indexOf(item) === i); // unique
  }

  private getSetsBySubModule(subModuleName: string): string[] {
    return this.permissions
      .filter(x => x.name.startsWith(subModuleName + "_"))
      .sort((x, y) => x.id - y.id)
      .map(x => { return x.name.split("_").slice(0, 3).join("_"); })
      .filter((item, i, ar) => ar.indexOf(item) === i);
  }

  private getSelectedSetNameBySubModule(subModuleName: string): string {
    let result: string = "";
    let setPermissionNames: string[] = this.groupPermissionNamesSelected.filter(x => x.startsWith(subModuleName + "_"));
    for (var i: number = 0; i < setPermissionNames.length; i++) {
      let permissionNameTr: string = this.i18n.tr("main.admin.pages.permissions." + setPermissionNames[i]);
      result += permissionNameTr;
      if (i < setPermissionNames.length - 1) {
        result += ", ";
      }
    }
    // this.logger.debug("result", result);
    return result;
  }

  private getGroupPermissionsBySet(setName: string): string[] {
    return this.permissions
      .filter(x => x.name.startsWith(setName + "_"))
      .sort((x, y) => x.id - y.id)
      .map(x => x.name);
  }

  private toggleSet(groupPermissionName: string): void {
    let setName: string = groupPermissionName.split("_").slice(0, 3).join("_");

    if (groupPermissionName.endsWith("_view")) {

      let disable: boolean = this.groupPermissionNamesSelected.indexOf(groupPermissionName) < 0;

      let groupPermissionSet: string[] = this.getGroupPermissionsBySet(setName);

      // enable / disable checkboxes del set
      for (let i: number = groupPermissionSet.length - 1; i >= 0; i--) {
        let groupPermissionName2: string = groupPermissionSet[i];
        this.disableSets[groupPermissionName2] = disable;
      }

      // elimino los permisos del set si se encuentra disable
      if (disable) {
        this.disableSets[groupPermissionName] = disable;

        for (let i: number = this.groupPermissionNamesSelected.length - 1; i >= 0; i--) {
          if (this.groupPermissionNamesSelected[i].startsWith(setName + "_")) {
            this.groupPermissionNamesSelected.splice(i, 1);
          }
        }
      }
    }
    // set dropdown name
    this.setNames[setName] = this.getSelectedSetNameBySubModule(setName);

  }
}
