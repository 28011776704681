export enum PasswordChangingPeriod
{
    Monthly = 30,

    Quartely = 90,

    Biannual = 180,

    Annual = 360
}
