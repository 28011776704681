
import { DiscType } from "../../questionaries/discType";
import { Sheet } from "../../test-core/sheets/sheet";
import { Parameter } from "./parameter";
import { Adjustment } from "models/adjustment/adjustment";
import { ReportActivation } from "./reportActivation";
import { ReportQuestionaryTeam } from "./reportQuestionaryTeam";
import { ReportCompetence } from "./reportCompetence";

export class Report {

  id: number;

  companyId: number;

  // requered parameters
  reportTemplateId: number;

  languageId?: number;

  // optional parameters 
  discType?: DiscType;

  // report generated to a person
  personId?: number;

  // report generated to a position
  positionId?: number;

  // report generated to a team
  teamId?: number;

  // report generated to an organization
  organizationId?: number;

  // compare to
  compareToPersonId?: number;

  compareToPositionId?: number;

  compareToTeamId?: number;

  compateToOrganizationId?: number;

  activationId?: number;


  // lists
  sheetIds: number[] = [];

  observerIds: number[] = [];

  peopleIds: number[] = [];

  oneOrTwoPeopleIds: number[] = [];

  twoPeopleIds: number[] = [];

  threePeopleIds: number[] = [];

  positionIds: number[] = [];

  activationQuestionaryIds: number[] = [];

  adjustments: Adjustment[] = [];

  competenceIds: number[] = [];

  parameters: Parameter[] = [];

  sheets: Sheet[] =[];

  activations: ReportActivation[] = [];
  
  questionariesTeam: ReportQuestionaryTeam[] = [];

  competences: ReportCompetence[] = [];
  
  // aux
  reportTemplateName: string;

  createdByName: string;

  createdOn: number;
}
