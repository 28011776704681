

import { View } from 'aurelia-framework';
import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { MessageTemplateService } from "../../services/messages/message-template-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";


import { MessageTemplate } from 'models/messages/message-template';
import { Company } from 'models/companies/company';
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';

@autoinject()
export class MessageMain {

  private logger: Logger;
  private params: any = {};

  private validationController: ValidationController;
  private messagesTemplates: Array<MessageTemplate>;

  public companyId: number;
  private company: Company;
  private state: State;
  private isLoaded: boolean = false;

  constructor(
    private router: Router,
    private store: StoreService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private messageTemplateService: MessageTemplateService) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("MessageMain");

    this.state = this.store.state;
  }

  public async activate(params: any, routeConfig: RouteConfig, view: View): Promise<void> {
    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  private async bind() {
    // this.logger.debug("bind");
    if (!isNaN(this.params.companyId)) {

      this.companyId = +this.params.companyId;
      this.messageTemplateService.client.currentCompanyId = this.companyId;
      this.company = this.state.companies.find(x => x.id == this.companyId);
      this.isLoaded = true;

      try {
        this.messagesTemplates = await this.messageTemplateService.getAll();
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }

  public toSummary(): any {
    return this.router.navigate("summary-reports");
  }
}
