import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Notification } from "common/services/notification";
import { CustomCompanyQuestionaryService } from "../../../services/questionaries/custom-company-questionary-service";
import { Questionary } from "models/questionaries/questionary";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { DeleteConfirmation } from "../../../../components/delete-confirmation";
import { ChangeStateConfirmation } from "../../../../components/change-state-confirmation";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { State } from "common/store/store-model";
import { Company } from "models/companies/company";
import { StoreService } from "common/store/store-service";

@autoinject()
export class CustomCompanyQuestionaryList {

  private logger: Logger;

  private params: any = {};
  public companyId: number;
  public company: Company;

  private parameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  private isLoaded: boolean = false;
  private state: State;
  public isVideoInterviewTest: boolean = false;

  constructor(
    private store: StoreService,
    private customCompanyQuestionaryService: CustomCompanyQuestionaryService,
    private dialogService: DialogService,
    private router: Router,
    private notification: Notification,
    public questionaryType: QuestionaryType) {

    this.logger = LogManager.getLogger("CustomCompanyQuestionaryList");

    this.state = this.store.state;
  }

  private async activate(params: any): Promise<void> {

    // company page
    if (this.params.companyId != params.companyId && this.isLoaded) {
      this.params = params;
      return await this.bind();
    }
    else {
      this.params = params;
    }
  }

  
  private refresh = (): Promise<UxDatatableResponse> => {
    this.isVideoInterviewTest = this.router.parent.currentInstruction.config.settings.isVideoInterViewTest;
    return this.customCompanyQuestionaryService.list(this.parameters,this.isVideoInterviewTest);
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;
      if (this.companyId) {
        this.customCompanyQuestionaryService.client.currentCompanyId = this.companyId;
        this.company = this.state.companies.find(x => x.id == this.companyId);
        this.isLoaded = true;
        return await this.databind();
      }
    }
  }

  private async databind(): Promise<any> {
    this.logger.debug("databind");

    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.logger.debug("refresh", response);
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });

  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  public async delete(id: number, name: string): Promise<any> {
    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.customCompanyQuestionaryService.delete(id)
          .then(() => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === id);
            this.parameters.tableData.splice(indexOfEditedModel, 1);
            this.parameters.totalRecords--;
            this.notification.success("main.admin.pages.questionaries.messages.questionary_deleted");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
    });
  }

  public async changeState(item: Questionary): Promise<void> {
    this.logger.debug("item.isActive", item.isActive);
    return await this.dialogService.open({
      viewModel: ChangeStateConfirmation,
      model: item.name
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.customCompanyQuestionaryService.changeState(item.id)
          .then(() => {
            // item.isActive = !item.isActive;
            this.notification.success("main.admin.pages.educational_levels_country.notifications.activation_changed");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else{
        item.isActive = !item.isActive;
      }
    });
  }

}
