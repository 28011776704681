import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { Group } from "models/groups/group";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

import * as moment from "moment";

@autoinject()
export class CoreGroupService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CoreGroupService");
  }

  public async list(parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post("coregroup/list", parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<Group[]> {
    return await this.client.get("coregroup")
      .then((response: Group[]) => {
        return response;
      });
  }

  public async getById(id: number): Promise<Group> {
    return await this.client.getById("coregroup", id)
      .then((response: Group) => {
        return response;
      });
  }

  public async create(model: Group): Promise<Group> {
    return await this.client.create("coregroup", model)
      .then((response: Group) => {
        return response;
      });
  }

  public async update(model: Group): Promise<void> {
    return await this.client.updateById("coregroup", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number, coreGroupIdToTransferUsers: number): Promise<void> {
    return await this.client.deleteById("coregroup", id, { groupIdToTransferUser: coreGroupIdToTransferUsers} )
      .then(() => null);
  }

  public async getPermissionNamesById(id: number): Promise<Array<string>> {
    return await this.client.get(`coregroup/${id}/core-permission-name`)
      .then((response: Array<string>) => {
        return response;
      });
  }

  public async updatePermissionsNamesById(id: number, permissions: Array<string>): Promise<void> {
    return await this.client.update(`coregroup/${id}/core-permission-name`, null, permissions)
      .then(() => null);
  }

  public async downloadPdfById(id: number, name: string): Promise<void> {
    let filename: string = name + "_" + moment().format() + ".pdf";
    return await this.client.download("GET", `coregroup/print/${id}`, null, filename, "application/pdf", null)
      .then(() => null);
  }

  // public async updatePermissionsById(id: number, permissions: Array<number>): Promise<void> {
  //   return await this.client.update(`core-group/${id}/core-permission`, null, permissions)
  //     .then(() => null);
  // }

  // public async getPermissionsById(id: number): Promise<Array<number>> {
  //   return await this.client.get(`core-group/${id}/core-permission`)
  //     .then((response: any) => {
  //       return <Array<number>>response;
  //     });
  // }


}
