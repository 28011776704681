import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { CatalogBase } from "models/catalogs/catalog-base";
import { CatalogChild } from "models/catalogs/catalog-child";


@autoinject()
export class CatalogService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("CatalogService");
  }


  public async getEconomicSectorAll(): Promise<CatalogBase[]> {
    return await this.client.get("catalog/economicsector")
      .then((response: any) => {
        return <CatalogBase[]>response;
      });
  }

  public async getCountryAll(): Promise<CatalogBase[]> {
    return await this.client.get("catalog/country")
      .then((response: any) => {
        return <CatalogBase[]>response;
      });
  }
  public async getRegionsByCountryId(parentId: number): Promise<CatalogChild[]> {
    return await this.client.get(`catalog/region/${parentId}`)
      .then((response: any) => {
        return <CatalogChild[]>response;
      });
  }

  public async getCitiesByRegionId(parentId: number): Promise<CatalogChild[]> {
    return await this.client.get(`catalog/city/${parentId}`)
      .then((response: any) => {
        return <CatalogChild[]>response;
      });
  }

  public async getCurrencyAll(): Promise<CatalogBase[]> {
    return await this.client.get("catalog/currency")
      .then((response: any) => {
        return <CatalogBase[]>response;
      });
  }

  public async getProfessionAll(): Promise<CatalogBase[]> {
    return await this.client.get("catalog/profession")
      .then((response: any) => {
        return <CatalogBase[]>response;
      });
  }

}
