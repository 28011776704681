import { I18N } from 'aurelia-i18n';
import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";


@autoinject()
export class ChangeStateAgreementConfirmation {
  private agreementstatus: string;


  constructor(private dialogController: DialogController,
    private i18n: I18N) {
    this.agreementstatus = null;
  }

  public activate(agreementstatus: string) {
    this.agreementstatus = this.i18n.tr(agreementstatus);
  }
}
