import { QuestionService } from '../../../admin/services/questionaries/question-service';
import config from 'config.js';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyCompetenceModal } from '../people-competence/company-competence-modal';
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { QuestionaryGroup } from "models/test-management/questionary-groups/questionaryGroup";
import { QuestionaryGroupService } from "../../../admin/services/test-management/questionary-group-service";
import { Language } from "models/localizations/language";
import { ReportTemplate } from "models/test-core/report-templates/report-template";
import { ReportTemplateService } from "../../../admin/services/test-management/report-template-service";
import { State } from "common/store/store-model";
import { StoreService } from "common/store/store-service";
import { Router } from "aurelia-router";
import { BulkActivation } from '../../../../models/test-person/bulkActivation';
import { FileAcceptValidator } from "common/utils/file-accept-validator";
import { BulkActivationService } from "main/test/services/people/bulk-activation-service";
import { UserInfoLoadedMessage } from 'messages/userInfoLoadedMessage';
import { Subscription, EventAggregator } from 'aurelia-event-aggregator';
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';
import { QuestionaryType } from 'models/questionaries/questionaryType';
import { NoDiscType } from 'models/questionaries/noDiscType';
import { PersonActivationConfirmationModal } from '../people/person-activation-confirmation-modal';
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { QuestionUse } from 'models/questions/questionUse';
import { Test } from '../../../../models/test-perfiltic/test';
import { Questionary } from 'models/questionaries/questionary';
import { PersonMode } from "models/test-person/personMode";
import { TestQuestionary } from 'models/test-core/test-questionary';
import { CompanyQuestionaryService } from 'main/admin/services/test-management/company-questionary-service';
import { I18N } from 'aurelia-i18n';
import { Languages } from 'models/localizations/languages';

@autoinject()
export class BulkActivationModal {

  private logger: Logger;

  public companyId: number;
  // private company: Company;

  private state: State;

  private questionaryFilter: string = "";
  private questionaryGroupFilter: string;
  private reportFilter: string;
  private hasCompetencesFilter: boolean;
  private questionaryGroups: QuestionaryGroup[];
  private searchQuestionaryGroup: QuestionaryGroup[];
  private reportTemplates: ReportTemplate[];
  private searchReport: ReportTemplate[];
  private languages: Language[];
  private questionaries: TestQuestionary[] = [];
  private searchQuestionary: TestQuestionary[] = [];
  private isCompetenceActive: boolean = false;
  private selectedQuestionaries = {
    qty: 0,
    avg: 0,
    questionaries: "",
    hasCompetences: false,
    hasEngineersTest: false,
  };

  private model: BulkActivation = new BulkActivation();
  private validationController: ValidationController;

  private csvExampleUrl: string = "";
  private xlsxExampleUrl: string = "";

  private subscription: Subscription;
  private questionaryGroup: QuestionaryGroup;
  private isInvalid: boolean;
  private isEngineersTests: boolean = false;
  private isCareerPath: boolean = false;
  private selectedLanguageId: number = Languages.Spanish;
  private signal: string = this.i18n.tr("main.test.pages.people.is_new_test");
  constructor(
    private router: Router,
    private bulkActivationService: BulkActivationService,
    private store: StoreService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private questionaryGroupService: QuestionaryGroupService,
    private reportTemplateService: ReportTemplateService,
    private languageService: CoreLanguageService,
    protected ea: EventAggregator,
    private dialogService: DialogService,
    private questionService: QuestionService,
    private companyQuestionaryService: CompanyQuestionaryService,
    private i18n: I18N

  ) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("BulkActivationModal");

    this.state = this.store.state;

    let backendUrl = config.URLS_BACKEND;
    this.csvExampleUrl = backendUrl + "/person-content/bulk-activation-example.csv";
    this.xlsxExampleUrl = backendUrl + "/person-content/bulk-activation-example.xlsx";

    this.subscription = this.ea.subscribe(UserInfoLoadedMessage, (message: UserInfoLoadedMessage) => this.setUserLanguage.bind(this));

  }

  public detached() {
    this.subscription.dispose();
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.testperson.pages.masive_activation.list_name").required()
      .ensure("fileName").displayName("main.testperson.pages.masive_activation.field_file").required()
      .ensure("activation.languageId").displayName("main.admin.pages.questionaries.language").required()
      //.ensure("activation.companyCompetenceIds").displayName("main.admin.pages.questionaries.competences").required()
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {
    this.logger.debug("activate");

    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;

      this.model.companyId = this.companyId;

      // this.personService.client.currentCompanyId = this.companyId;
      this.questionaryGroupService.client.currentCompanyId = this.companyId;
      this.reportTemplateService.client.currentCompanyId = this.companyId;

      this.questionaryGroups = await this.getQuestionaryGroups();
      this.reportTemplates = await this.getReportTemplates();
      this.questionaries = await this.companyQuestionaryService.getAll();
      this.configureValidationRules();
      this.searchQuestionary = this.questionaries;
      this.searchQuestionaryGroup = this.questionaryGroups;
      this.searchReport = this.reportTemplates;
    }

  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find(lg => lg.culture === activeCulture);
      if (activeLang) {
        this.model.languageId = activeLang.id;
      }
    }
  }

  private async bind(): Promise<any> {
    // this.logger.debug("bind");
    try {
      this.languages = await this.languageService.getAll();
      this.setUserLanguage();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async selectCompanyCompetence(): Promise<void> {
    this.logger.debug("model.companyCompetenceIds")
    return await this.dialogService.open({
      viewModel: CompanyCompetenceModal,
      model: this.model.companyCompetenceIds
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (this.isCompetenceActive && this.model.companyCompetenceIds.length == 0) {
          this.notification.warning("main.test.pages.people.errors.no_competences_selected");
        }
      }
    });
  }

  private async changeBattery(itm) {
    this.model.reportTemplateId = null;
    this.model.questionaryIds = [];
    this.model.engineersTests = null;
    const qg = this.questionaryGroups.filter(it => it.id === itm);

    if (qg.length) {
      const questionaries = qg[0].questionaries;
      const text = this.signal;
      questionaries.forEach(function(it){
        it.isNewTest == true ? it.name = it.name + " " + text : it.name;
      });
      this.selectedQuestionaries = {
        qty: questionaries.length,
        avg: questionaries.length > 0 ? questionaries.map(it => it.averageTime).reduce((a, b) => a + b) : 0,
        questionaries: questionaries.length > 0 ? questionaries.map(it => it.name).reduce((a, b) => a + ", " + b) : "",
        hasCompetences: questionaries.length > 0 ? questionaries.some(it => it.questionaryType == QuestionaryType.Competences) : false,
        hasEngineersTest: questionaries.length > 0 ? questionaries.some(it => it.noDiscType == NoDiscType.EngineersTest) : false,
      };
      
      this.validateEngineersTests(questionaries);
      this.validateQuestionaryCareerPath(questionaries);
    } else {
      this.selectedQuestionaries = {
        qty: 0,
        avg: 0,
        questionaries: "",
        hasCompetences: false,
        hasEngineersTest: false,

      }   
    }

    this.cleanCompanyCompetences();

    const questionaryGroup = await this.questionaryGroupService.getById(itm);
    await this.isValidActivationVideoInterview(questionaryGroup);
  }
  private async isValidActivationVideoInterview(questionaryGroup: QuestionaryGroup) {
    const hasVideoInterview = questionaryGroup.questionaries.filter(x => x.noDiscType === NoDiscType.VideoInterview);
    if (hasVideoInterview.length > 0) {
      const validate = await this.questionaryGroupService.validateActivationVideoInterview(questionaryGroup.id);
      if (!validate) {
        this.isInvalid = true;
        return this.notification.warning("main.test.pages.people.errors.questionarygroup_videinterview_activation_failed");
      }
      else
        this.isInvalid = false;
    }
    else
      this.isInvalid = false;
  }

  private changeQuestionary() {
    this.model.reportTemplateId = null;
    this.model.questionaryGroupId = null;

    let questionariesFilter: TestQuestionary[] = [];
    var testQuestionary = new TestQuestionary();
    this.model.questionaryIds.forEach(element => {
      testQuestionary = new TestQuestionary();
      testQuestionary = this.questionaries.find(it => it.stringId == element.stringId);
      questionariesFilter.push(testQuestionary)
    });
    //this.isDisable = false;
    if (questionariesFilter.length > 0) {  

      this.selectedQuestionaries = {
        qty: questionariesFilter.length,
        avg: questionariesFilter.length > 0 ? questionariesFilter.map(it => it.averageTime).reduce((a, b) => a + b) : 0,
        questionaries: questionariesFilter.length > 0 ? questionariesFilter.map(it => it.name).reduce((a, b) => a + ", " + b) : "",
        hasCompetences: questionariesFilter.length > 0 ? questionariesFilter.some(it => it.questionaryType == QuestionaryType.Competences) : false,
        hasEngineersTest: questionariesFilter.length > 0 ? questionariesFilter.some(it => it.noDiscType == NoDiscType.EngineersTest) : false,
      };
      
      if (this.selectedQuestionaries.hasEngineersTest) {
        this.validateEngineersTests(questionariesFilter);

      }
      if (!this.selectedQuestionaries.hasEngineersTest) {
        this.isEngineersTests = false;
        this.model.engineersTests = [];
      }
      this.validateQuestionaryCareerPath(questionariesFilter);
      if (this.selectedQuestionaries.hasCompetences && this.hasCompetencesFilter != true) {
        this.selectCompanyCompetence();
        this.hasCompetencesFilter = true;
      }
      if (!this.selectedQuestionaries.hasCompetences) {
        this.hasCompetencesFilter = false;
      }

    } else {
      this.selectedQuestionaries = {
        qty: 0,
        avg: 0,
        questionaries: "",
        hasCompetences: false,
        hasEngineersTest: false,

      };
      
    }
  }

  private changeReport(itm) {
    this.model.questionaryGroupId = null;
    this.model.questionaryIds = [];
    this.model.engineersTests = null;
    const qg = this.reportTemplates.filter(it => it.id === itm);

    if (qg.length) {
      const questionaries = qg[0].questionaries;
      const text = this.signal;
      questionaries.forEach(function(it){
        it.isNewTest == true ? it.name = it.name + " " + text : it.name;
      });
      this.selectedQuestionaries = {
        qty: questionaries.length,
        avg: questionaries.length > 0 ? questionaries.map(it => it.averageTime).reduce((a, b) => a + b) : 0,
        questionaries: questionaries.length > 0 ? questionaries.map(it => it.name).reduce((a, b) => a + ", " + b) : "",
        hasCompetences: questionaries.length > 0 ? questionaries.some(it => it.questionaryType == QuestionaryType.Competences) : false,
        hasEngineersTest: questionaries.length > 0 ? questionaries.some(it => it.noDiscType == NoDiscType.EngineersTest) : false,
      };
      this.validateEngineersTests(questionaries);
      this.validateQuestionaryCareerPath(questionaries);
    } else {
      this.selectedQuestionaries = {
        qty: 0,
        avg: 0,
        questionaries: "",
        hasCompetences: false,
        hasEngineersTest: false,

      }     
    }

    this.cleanCompanyCompetences();
  }

  private validateQuestionaryCareerPath(questionaries: Questionary[]) {
    this.isCareerPath = false;
    const qCareerPaths = questionaries.filter(q => q.noDiscType == NoDiscType.CareerPath);
    this.isCareerPath = qCareerPaths.length > 0 ? true : false;
  }

  private cleanCompanyCompetences() {
    if (!this.selectedQuestionaries.hasCompetences) {
      this.model.companyCompetenceIds = [];
    }
  }

  private async getQuestionaryGroups(): Promise<QuestionaryGroup[]> {
    return this.questionaryGroupService.getAll();
  }

  private async getReportTemplates(): Promise<ReportTemplate[]> {
    return this.reportTemplateService.getAll();
  }

  private changeTag() {
    this.model.reportTemplateId = null; 
    this.model.questionaryGroupId = null;
    this.selectedQuestionaries = {
      qty: 0,
      avg: 0,
      questionaries: "",
      hasCompetences: false,
      hasEngineersTest: false,
    }
    this.model.questionaryIds = [];
    this.model.engineersTests = null;

  }

   private OnChangeQuestionary() {
    this.searchQuestionary = [];
    if (!this.selectedLanguageId && !this.questionaryFilter)
    {        
       this.searchQuestionary = this.questionaries;
    }
    else if (this.selectedLanguageId == 0 && this.questionaryFilter.length === 0)
    { 
       this.searchQuestionary = this.questionaries;
    }    
    else if (this.questionaryFilter.length > 0 && this.selectedLanguageId > 0)
    {     
     this.searchQuestionary = this.questionaries.filter(q => q.name.toLowerCase().includes(this.questionaryFilter.toLowerCase()) &&  q.languageId === this.selectedLanguageId);
    }
    else if (this.questionaryFilter.length > 0) {     
      this.searchQuestionary = this.questionaries.filter(q => q.name.toLowerCase().includes(this.questionaryFilter.toLowerCase()));
    }
    else if(this.selectedLanguageId > 0){         
      this.searchQuestionary = this.questionaries.filter(q => q.languageId == this.selectedLanguageId);
     }
    }


  private async OnChangeQuestionaryGroup() {
    if (this.questionaryGroupFilter.length > 0) {
      this.searchQuestionaryGroup = this.questionaryGroups.filter(q => q.name.toLowerCase().includes(this.questionaryGroupFilter.toLowerCase()));
      this.model.questionaryGroupId = this.searchQuestionaryGroup[0].id;
      await this.changeBattery(this.model.questionaryGroupId);
    }
    else if (this.questionaryGroupFilter == null || this.questionaryGroupFilter.length == 0) {
      this.searchQuestionaryGroup = [];
      this.searchQuestionaryGroup = this.questionaryGroups;
      this.model.questionaryGroupId = null;
    }

  }

  private async OnChangeReport() {
    if (this.reportFilter.length > 0) {
      this.searchReport = this.reportTemplates.filter(q => q.name.toLowerCase().includes(this.reportFilter.toLowerCase()));
      this.model.reportTemplateId = this.searchReport[0].id;
      await this.changeReport(this.model.reportTemplateId);
    }
    else if (this.reportFilter == null || this.reportFilter.length == 0) {
      this.searchReport = [];
      this.searchReport = this.reportTemplates
      this.model.reportTemplateId = null;
      await this.changeReport(this.model.reportTemplateId);
    }

  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.isCareerPath && this.model.personMode === PersonMode.Direct) {
            this.notification.warning("main.test.pages.people.person_mode.career_path");
          }
          else if (!this.model.filedata) {
            this.notification.warning("main.test.pages.people.errors.file_is_missing")
          }
          else if (!this.model.reportTemplateId && !this.model.questionaryGroupId && !this.model.questionaryIds.length) {
            this.notification.warning("main.test.pages.people.errors.questionarygroup_or_reports_is_missing");
          }
          else if (this.model.invitationExpireDate && this.model.invitationExpireDate < new Date()) {
            this.notification.warning("main.test.pages.people.errors.old_invitation_expire_date");
          }
          else {
            if (this.model.questionaryGroupId != null) {
              this.questionaryGroup = await this.questionaryGroupService.getTalentsById(this.model.questionaryGroupId);
              let hasVideoInterview = this.questionaryGroup.questionaries.filter(x => x.noDiscType === NoDiscType.VideoInterview);

              if (hasVideoInterview.length > 0 && this.questionaryGroup.talentsPrice > 0) {
                return await this.dialogService.open({
                  viewModel: PersonActivationConfirmationModal,
                  model: this.questionaryGroup.talentsPrice
                }).whenClosed(async (response: DialogCloseResult) => {
                  if (!response.wasCancelled) {
                    return await this.save();
                  }
                });
              }
              else {
                return await this.save();
              }
            }
            else {
              return await this.save();
            }
          }
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.bulkActivationService.client.isRequesting;
  }

  private async save(): Promise<any> {
    try {

      this.model = await this.bulkActivationService.create(this.model);

      this.notification.success("notifications.item_added");

      // new activation
      return await this.router.navigateToRoute("bulk-activations");
    }
    catch (error) {
      this.notification.error(error);
    };
  }

  private createQuestionaryGroup() {
    // this.dialogController.cancel();
    this.router.navigate(`/company/${this.companyId}/admin/test-management/questionary-groups/questionary-group/create`);
  }

  private createReportTemplate() {
    // this.dialogController.cancel();
    this.router.navigate(`/company/${this.companyId}/admin/test-management/report-templates/report-template/create`);
  }

  public setFile($event: any): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];

    let fileValidator = FileAcceptValidator.parse("csv, xls, xlsx", 1024 * 500);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.model.fileName = file.name;

      };
    }
    else {
      this.notification.error("main.test.pages.person.bulk_activation.errors.error_bad_file");

    }
  }

  private validateEngineersTests(questionaries: Questionary[]) {
    this.isEngineersTests = false;
    var engineersTests: Test[] = [];
    let existQuestionary: Test[] = [];
    if (this.model.engineersTests != undefined) {
      if (this.model.engineersTests.length > 0)
        engineersTests = this.model.engineersTests;
    }
    var test = new Test();
    questionaries.forEach(function (item: Questionary) {
      if (item.noDiscType == NoDiscType.EngineersTest) {
        if (engineersTests != undefined) {
          if (engineersTests.length > 0)
            existQuestionary = engineersTests.filter(x => x.questionaryId === item.id);

          if (existQuestionary.length == 0) {
            test = new Test();
            test.questionaryId = item.id
            test.questionaryName = item.name
            engineersTests.push(test);
          }
        }

      }
    });

    if (engineersTests.length) {
      this.model.engineersTests = engineersTests;
      this.isEngineersTests = true;
    }
  }
  public notificationInfo(message:string){
    this.notification.info(message);
  }
}
