import { autoinject, LogManager, observable } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import { DialogService, DialogCloseResult, DialogCancelableOperationResult } from "aurelia-dialog";
import { ThematicAssociationService } from "../../../services/thematic-groups/thematic-association-service";
import { ThematicAssociationModal } from "./thematic-association-modal";
import { Notification } from "common/services/notification";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { QuestionAssociation } from "models/thematic-groups/question-association";

@autoinject()
export class ThematicAssociationList {

  private logger: Logger;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;
  private thematicGroupId: number;

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.thematicAssociationService.list(this.thematicGroupId, this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  constructor(
    private router: Router,
    private thematicAssociationService: ThematicAssociationService,
    private dialogService: DialogService,
    private notification: Notification) {

    this.logger = LogManager.getLogger("ThematicAssociationList");
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private async activate(params: any): Promise<void> {
    this.logger.info("activate");
    if (!isNaN(params.thematicGroupId)) {
      this.thematicGroupId = +params.thematicGroupId;
      // return await this.databind();
    }
  }

  private async bind(): Promise<any> {
    if (this.thematicGroupId) {
      return await this.refresh()
        .then((response: UxDatatableResponse) => {
          this.parameters.tableData = response.data;
          this.parameters.totalRecords = response.totalRecords;
        })
        .catch(error => {
          this.notification.error(error);
        });
    }
  }

  public async edit(item: QuestionAssociation): Promise<any> {
    let orginialItem = JSON.parse(JSON.stringify(item));
    return await this.dialogService.open({
      viewModel: ThematicAssociationModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.thematicAssociationService.update(item)
          .then((questionAssociation: QuestionAssociation) => {
            let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === item.id);
            this.parameters.tableData.splice(indexOfEditedModel, 1, questionAssociation);
            // this.parameters.totalRecords--;
            this.notification.success("notifications.item_edited");
          })
          .catch(error => {
            this.notification.error(error);
          });
      }
      else {
        this.logger.debug("edition cancelled");
        // let indexOfEditedModel: number = this.parameters.tableData.findIndex(x => x.id === item.id);
        // this.parameters.tableData.splice(indexOfEditedModel, 1, orginialItem);
        Object.assign(item, orginialItem);
      }
    });
  }
}
