import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { OrganizationEvaluator } from "models/test-organization/organizationEvaluator";
import { DialogController } from "aurelia-dialog";

@autoinject()
export class OrganizationEvaluatorEditModal {

  private logger: Logger;

  private model: OrganizationEvaluator = new OrganizationEvaluator();

  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("OrganizationEvaluatorEdit");
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("weight").displayName("main.test.pages.organizations.organization_evaluator_edit.weight").required()
      .on(this.model);

    ValidationRules
      .ensure("firstName").displayName("main.test.pages.organizations.organization_evaluator_edit.firstName").required()
      .ensure("lastName").displayName("main.test.pages.organizations.organization_evaluator_edit.lastName").required()
      .ensure("email").displayName("main.test.pagesorganizations.organization_evaluator_edit.email").email()
      .on(this.model.evaluator);
  }
  public async activate(model: OrganizationEvaluator): Promise<any> {
    this.model = model;
    this.configureValidationRules();

  }

  public async submit(): Promise<void> {
    // this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          this.save();
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async save(): Promise<any> {
    this.dialogController.ok();
  }
}
