import { Aurelia } from 'aurelia-framework';
import { I18N, TCustomAttribute } from 'aurelia-i18n';
import * as XHR from 'i18next-xhr-backend';
import { ValidationMessageProvider } from 'aurelia-validation';
import { Expression } from 'aurelia-binding';

import { HttpClient } from 'aurelia-fetch-client';
// import environment from './environment';

const i18nConfig = async (aurelia: Aurelia, instance: I18N, backendUrl: string, production?: boolean): Promise<any> => {

  const setup = async (instance: I18N): Promise<any> => {
    let aliases: Array<string> = ["t", "i18n"];
    let namespaces: Array<string> = ["translation"];

    // add aliases for 't' attribute
    TCustomAttribute.configureAliases(aliases);

    // register backend plugin
    // instance.i18next.use(Backend.with(aurelia.loader));
    instance.i18next.use(XHR);

    // adapt options to your needs (see http://i18next.com/docs/options/)
    // make sure to return the promise of the setup method, in order to guarantee proper loading
    return instance.setup({
      backend: { // <-- configure backend settings
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: '{{lng}}', // <-- XHR settings for where to get the files from
        // parse data after it has been fetched
        // in example use https://www.npmjs.com/package/json5
        // here it removes the letter a from the json (bad idea)
        parse: (data) => data,
        // define a custom xhr function
        // can be used to support XDomainRequest in IE 8 and 9
        ajax: (culture, options, callback, data)  => {
          try {
            // let waitForLocale = require('bundle-loader!../locales/' + url + '.json');
            // waitForLocale((locale) => {
            //   callback(locale, {
            //     status: '200'
            //   });
            // })
        
            let client = new HttpClient();
            // configure client
            client.configure(x => {
              x.withBaseUrl(`${backendUrl}/api/v1/`);
            });
        
            return client.fetch(`localization/get-resources/${culture}/1`, { method: 'GET' })
              .then((response: Response) => {
                return response.json()
              })
              .then((locale) => {
                callback(locale, { status: '200' });
              });
            ;
        
          } catch (e) {
            callback(null, {
              status: '404'
            });
          }
        }
        
      },
      attributes: aliases,
      ns: namespaces,
      fallbackNS: namespaces,
      defaultNS: 'translation',
      lng: 'es-CO',
      fallbackLng: 'es-CO',
      debug: !production
    });
  };

  //validation configuration for i18n
  ValidationMessageProvider.prototype.getMessage = function (key: string): Expression {
    const i18n: I18N = aurelia.container.get(I18N);
    const translation: string = i18n.tr(`errorMessages.${key}`);
    return this.parser.parse(translation);
  };

  ValidationMessageProvider.prototype.getDisplayName = function (propertyName: string, displayName: string): string {
    const i18n: I18N = aurelia.container.get(I18N);
    return i18n.tr(displayName);
  };

  return setup(instance);
};


export default i18nConfig;
