import { ViewEngineHooks, View } from 'aurelia-framework';
import { TransactionType } from './transaction-type';

export class TransactionTypeViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['TransactionType'] = TransactionType;

    view.overrideContext['TransactionTypes'] = 
      Object.keys(TransactionType)
        .filter((key) => typeof TransactionType[key] === 'number');
  }
}
