export function areEqual(obj1, obj2) {
  return Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && (obj1[key] === obj2[key]));
};

export function addSlash(resource: string) {
  let hasSlash = resource.slice(-1) === '/';
  if (hasSlash) {
    return resource;
  } else {
    return resource += "/";
  }

}


/* Retorna deep copy del objeto @from */
export function fnClone(from: any): any {
  var prop, clone;

  // determina si @from es un valor primitivo o una función
  if (from == null || typeof from != "object") return from;

  // determina si @from es una instancia de alguno de los siguientes prototipos
  if (from.constructor == Date || from.constructor == RegExp || from.constructor == Function ||
    from.constructor == String || from.constructor == Number || from.constructor == Boolean) {
    return new from.constructor(from);
  }

  // si el constructor del objeto no es ninguno de los anteriores
  if (from.constructor != Object && from.constructor != Array) return from;

  // itera recursivamente las propiedades del objeto
  clone = new from.constructor();
  for (prop in from) {
    //no se recomienda arguments.callee
    //clone[prop] = arguments.callee(from[prop]);
    clone[prop] = this.fnClone(from[prop]);
  }
  return clone;
}

export function calculateAge(dob: Date) { 
  var diff_ms = Date.now() - dob.getTime();
  var age_dt = new Date(diff_ms); 

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}
