
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class CoreUserChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N
  ) {

    this.logger = LogManager.getLogger("CoreGroupChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      // {
      //   route: "create",
      //   moduleId: PLATFORM.moduleName("./core-user-edit"),
      //   name: "core-user-create",
      //   nav: false,
      //   title: this.i18n.tr("main.admin.pages.core_users.create_card.title"),
      //   settings: {
      //     permission: {
      //       only: [PredefinedPermissions.CoreUserCreate]
      //     }
      //   }
      // },

      {
        route: "",
        moduleId: PLATFORM.moduleName("./core-user-edit"),
        name: "core-user-edit",
        nav: true,
        title: this.i18n.tr("main.admin.pages.core_users.core_user"),
        settings: {
          permission: {
            only: [PredefinedPermissions.CoreUserView]
          }
        }
      },
    ]);
  }
}
