import { Test } from './../test-perfiltic/test';

import { PersonMode } from "./personMode";
import { Disc } from '../questionaries/disc';
import { PersonActivationType } from "./personActivationType";
import { Activation } from "./activation";


export class Person {
  id: number;
  companyId: number;
  companyName: string;
  pictureUrl: string;
  firstName: string;
  lastName: string;
  fullName: string;
  initials: string;
  userName: string;
  password: string;
  email: string;
  phone: string;
  document: string;
  personMode: PersonMode;
  firstPersonActivationType: PersonActivationType;
  tags: string;
  areTermsAccepted: boolean;
  hasMicroSite: boolean;
  indicativeCountry: number;
  

  highestDisc: Disc;
  stamp: string;
  lastDiscApplicationDate: Date;

  createdOn: Date;
  createdByName: string;
  createdByEmail: string;

  activateIfExists?: boolean;

  activation: Activation;

  // isApplicant?: boolean;
  // is migrated
  hasResume?: boolean;
  isTheTalentSystem?: boolean;

  // VideoInterview
  hasVideoInterview?: boolean;
  engineersTests: Test[] = [];
}
