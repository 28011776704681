import { EmailHistoryService } from '../../services/people/email-history-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { PersonService } from "main/test/services/people/person-service";
import { Person } from "models/test-person/person";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { EmailHistory } from "models/emails-history/email-history";
import { Notification } from "common/services/notification";
import { DialogService, DialogCloseResult } from 'aurelia-dialog';
import { PeopleHistoryEmailDetailsModal } from './people-history-email-details-modal';

@autoinject()
export class PeopleHistoryEmailList{
  private logger: Logger;
  public companyId: number;
  private personId: number;
  private person: Person;
  private params: any = {};
  public model: EmailHistory = new EmailHistory;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  private uxDatatable: UxDatatableCustomAttribute;

  constructor(
    private personService: PersonService,
    private notification: Notification, 
    private dialogService: DialogService,
    private historyEmailService: EmailHistoryService,   
  ) {    
    this.logger = LogManager.getLogger("PeopleHistoryEmailList");
  }

  public async activate(params: any): Promise<any> {

    if (!isNaN(params.companyId)) {
      this.companyId = +params.companyId;
      this.personService.client.currentCompanyId = this.companyId;

      if (!isNaN(params.personId)) {
        this.personId = params.personId;
        this.person = await this.getPerson();

        this.databind();
      }

    }
  }

  private async getPerson(): Promise<Person> {
    return this.personService.getById(this.personId);
  }

  private refresh = async (): Promise<void | UxDatatableResponse> => {
    this.logger.debug("refreshing");
    return await this.historyEmailService.list(this.person.email, this.parameters)
      .then((response: UxDatatableResponse) => {
        return response;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async databind(): Promise<void> {
    this.logger.debug("databind");
    return await this.refresh()
      .then((response: UxDatatableResponse) => {
        this.parameters.tableData = response.data;
        this.parameters.totalRecords = response.totalRecords;
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  private async getMore(scrollContext: any): Promise<void> {
    return await this.uxDatatable.getMoreItems(scrollContext);
  }

  private clearFilters() {
    this.parameters.search.StartDate = this.parameters.search.EndDate = null;
  }

  private async viewDetailsMessages(msgId: string): Promise<any> {
    return await this.dialogService.open({
      viewModel: PeopleHistoryEmailDetailsModal,
      model: msgId      
    }).whenClosed(async (response: DialogCloseResult) => {});
  }

}
