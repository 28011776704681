import { noView } from 'aurelia-templating';
import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import nprogress from 'nprogress';

@noView()
@autoinject
export class UxLoadingIndicator {
  
  constructor(
    private ea: EventAggregator) {

    ea.subscribe('router:navigation:processing', this.start);
    ea.subscribe('router:navigation:complete', this.done);

    // ea.subscribe('app:http_client:requesting', this.start);
    // ea.subscribe('app:http_client:request_complete', this.done);
  }

  start() {
    nprogress.start();
    nprogress.inc();
  }

  done() {
    nprogress.done();
  }
}
