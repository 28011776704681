
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router } from "aurelia-router";
import { ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { I18N } from "aurelia-i18n";
import { DialogService, DialogCloseResult } from "aurelia-dialog";

import { CoreGroupService } from "../../../services/groups/core-group-service";
import { CorePermissionService } from "../../../services/groups/core-permission-service";
import { Permission } from "models/groups/permission";
import { Group } from "models/groups/group";
import { Notification } from "common/services/notification";
import { SecondPasswordModal } from "./second-password-modal";

interface SetNameMap {
  [setName: string]: string;
}

interface DisableSetMap {
  [permission: string]: boolean;
}

@autoinject
export class CorePermissionEdit {

  private logger: Logger;
  private validationController: ValidationController;

  private groupId: number;
  private group: Group;
  private saveAndContinue: boolean = false;
  private isLoaded: boolean = false;

  private permissions: Array<Permission> = new Array<Permission>();

  private groupPermissionNamesSelected: Array<string> = new Array<string>();

  private disableSets: DisableSetMap = {};

  private setNames: SetNameMap = {};

  constructor(
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private coreGroupService: CoreGroupService,
    private corePermissionService: CorePermissionService,
    private dialogService: DialogService,
    private i18n: I18N) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("CorePermissionEdit");
  }

  private async activate(params: any): Promise<any> {
    this.isLoaded = false;

    this.logger.debug("Activated id:", params.coreGroupId);
    this.groupId = +params.coreGroupId;

    if (!this.groupId) {
      return await this.router.navigateToRoute("core-groups");
    }

    try {
      this.group = await this.coreGroupService.getById(this.groupId);

      this.permissions = await this.corePermissionService.getAll();
      for (let i: number = this.permissions.length - 1; i >= 0; i--) {
        let groupPermissionName: string = this.permissions[i].name;
        this.disableSets[groupPermissionName] = true;
      }

      this.groupPermissionNamesSelected = await this.coreGroupService.getPermissionNamesById(this.groupId);

      for (let i: number = this.groupPermissionNamesSelected.length - 1; i >= 0; i--) {
        let groupPermissionName: string = this.groupPermissionNamesSelected[i];
        this.disableSets[groupPermissionName] = false;
        this.toggleSet(groupPermissionName);
      }
      this.isLoaded = true;

    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return await this.save();
  }

  get canSave() {
    return !this.coreGroupService.client.isRequesting;
  }

  public async save(): Promise<any> {
    return await this.coreGroupService.updatePermissionsNamesById(this.groupId, this.groupPermissionNamesSelected)
      .then(async () => {
        this.notification.success("notifications.item_edited");

        if (!this.saveAndContinue) {
          return await this.router.navigateToRoute("core-groups");
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  public async generatePdf(): Promise<void> {
    return await this.coreGroupService.downloadPdfById(this.group.id, this.group.name)
      .then(() => null)
      .catch(error => {
        this.notification.error(error);
      });
  }

  private getSubModulesByModule(moduleName: string): string[] {
    return this.permissions.filter(x => x.name.startsWith(moduleName + "_"))
      .sort((x, y) => x.id - y.id)
      .map(x => { return x.name.split("_").slice(0, 2).join("_"); })
      .filter((item, i, ar) => { return ar.indexOf(item) === i; });
  }

  private getSetsBySubModule(subModuleName: string): string[] {
    return this.permissions.filter(x => x.name.startsWith(subModuleName + "_"))
      .sort((x, y) => x.id - y.id)
      .map(x => { return x.name.split("_").slice(0, 3).join("_"); })
      .filter((item, i, ar) => { return ar.indexOf(item) === i; });
  }

  private getSelectedSetNameBySubModule(subModuleName: string): string {
    let result: string = "";
    let setPermissionNames: string[] = this.groupPermissionNamesSelected.filter(x => x.startsWith(subModuleName + "_"));
    for (var i: number = 0; i < setPermissionNames.length; i++) {
      let permissionNameTr: string = this.i18n.tr("main.admin.pages.permissions." + setPermissionNames[i]);
      result += permissionNameTr;
      if (i < setPermissionNames.length - 1) {
        result += ", ";
      }
    }
    // this.logger.debug("result", result);
    return result;
  }

  private getGroupPermissionsBySet(setName: string): string[] {
    return this.permissions.filter(x => x.name.startsWith(setName + "_"))
      .sort((x, y) => x.id - y.id)
      .map(x => x.name);
  }

  private async toggleSet(groupPermissionName: string): Promise<void> {
    let setName: string = groupPermissionName.split("_").slice(0, 3).join("_");

    if (groupPermissionName.endsWith("_view")) {

      let disable: boolean = this.groupPermissionNamesSelected.indexOf(groupPermissionName) < 0;

      let groupPermissionSet: string[] = this.getGroupPermissionsBySet(setName);

      // enable / disable checkboxes del set
      for (let i: number = groupPermissionSet.length - 1; i >= 0; i--) {
        let groupPermissionName2: string = groupPermissionSet[i];
        this.disableSets[groupPermissionName2] = disable;
      }

      // elimino los permisos del set si se encuentra disable
      if (disable) {
        this.disableSets[groupPermissionName] = disable;

        for (let i: number = this.groupPermissionNamesSelected.length - 1; i >= 0; i--) {
          if (this.groupPermissionNamesSelected[i].startsWith(setName + "_")) {
            this.groupPermissionNamesSelected.splice(i, 1);
          }
        }
      } else if (this.isLoaded) {
        let permission: Permission = this.permissions.filter(x => x.name === groupPermissionName)[0];

        if (permission
          && permission.isRestricted) {

          this.logger.debug("toggleSet restricted permission:", permission);

          return await this.validateSecondPassword()
            .then((result: boolean) => {
              if (result) {
                this.disableSets[groupPermissionName] = disable;
                // set dropdown name
                this.setNames[setName] = this.getSelectedSetNameBySubModule(setName);
              } else {
                this.groupPermissionNamesSelected.splice(this.groupPermissionNamesSelected.indexOf(groupPermissionName), 1);

                for (let i: number = groupPermissionSet.length - 1; i >= 0; i--) {
                  let groupPermissionName2: string = groupPermissionSet[i];
                  this.disableSets[groupPermissionName2] = true;
                }
              }

            });
        }
      }
    }

    // set dropdown name
    this.setNames[setName] = this.getSelectedSetNameBySubModule(setName);
  }

  private async validateSecondPassword(): Promise<boolean> {
    return await this.dialogService.open({
      viewModel: SecondPasswordModal
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        if (response.output) {
          let result: boolean = <boolean>response.output;
          this.logger.debug("validate ok: ", result);
          return result;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }
}
