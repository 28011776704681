import { LogManager, autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { BusinessCompany } from 'models/franchises/businessCompany';

@autoinject()
export class BusinessCompanyService {
  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger('BusinessCompanyService');
  }

  // public async list(companyId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
  //   return await this.client.post(`businesscompany/list/${companyId}`, parameters)
  //     .then(response => {
  //       return <UxDatatableResponse>response;
  //     });
  // }

  public async getAll(franchiseId: number): Promise<BusinessCompany[]> {
    return await this.client.get(`businesscompany/get-all/${franchiseId}`)
      .then((response: BusinessCompany[]) => {
        return response;
      })
  }

  // public async getById(id: number): Promise<Contact> {
  //   return await this.client.getById("contact", id)
  //     .then(response => {
  //       return <Contact>response;
  //     });
  // }

  // public async create(model: Contact): Promise<Contact> {
  //   return await this.client.create(`contact/${model.companyId}`, model)
  //     .then(response => {
  //       return <Contact>response;
  //     });
  // }

  // public async update(model: Contact): Promise<Contact> {
  //   return await this.client.updateById('contact', model.id, null, model)
  //     .then(response => {
  //       return <Contact>response;
  //     });
  // }

  // public async delete(id: number): Promise<void> {
  //   return await this.client.deleteById('contact', id)
  //     .then(() => null);
  // }

}
