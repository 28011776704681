
import { autoinject } from "aurelia-dependency-injection";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";
import { I18N } from "aurelia-i18n";
import { LogManager } from "aurelia-framework";
import PredefinedPermissions from 'config/predefined-permissions';

@autoinject()
export class ThematicGroupChildRouter {
  
  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("ThematicGroupChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([
      {
        route: "",
        name: "thematic-group-edit",
        moduleId: PLATFORM.moduleName("./thematic-group-edit"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.thematic_groups.thematic_group"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.QuestionaryView]
          }
        }
      },

      {
        route: "thematic-variables",
        name: "thematic-variable-list",
        moduleId: PLATFORM.moduleName("./thematic-variables/thematic-variable-list"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.thematic_groups.thematic_variables.title"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.QuestionaryView]
          }
        }
      },
      
      {
        route: "thematic-associations",
        name: "thematic-association-list",
        moduleId: PLATFORM.moduleName("./thematic-associations/thematic-association-list"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.thematic_groups.thematic_associations.title"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.QuestionaryView]
          }
        }
      }
    ]);

    this.router = router;
  }

}
