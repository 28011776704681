import { NoDiscType } from 'models/questionaries/noDiscType';
import { ProgrammingLanguage } from 'models/test-perfiltic/programmingLanguage';
import { Router } from "aurelia-router";
import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { QuestionaryService } from "../../services/questionaries/questionary-service";
import { Questionary } from "models/questionaries/questionary";

import { ItemToQualify } from "models/questionaries/itemToQualify";
import { QuestionaryType } from "models/questionaries/questionaryType";
import { Language } from "models/localizations/language";

import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";
import { PerfilticService } from 'main/admin/services/perfiltic/perfiltic-service';

@autoinject()
export class QuestionaryEdit {

  private logger: Logger;

  private parameters = new UxDatatableParameters();
  private model: Questionary = new Questionary();
  private languages: Language[] = [];
  private programmingLanguages: ProgrammingLanguage[] = [];
  public isDisabled: boolean;

  private validationController: ValidationController;

  constructor(
    private questionariesService: QuestionaryService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
    public languagesService: CoreLanguageService,
    public questionaryType: QuestionaryType,
    public itemToQualify: ItemToQualify,
    public perfilticservice: PerfilticService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("QuestionaryEdit");

  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.questionaries.name").required()
      .ensure("questionaryType").displayName("main.admin.pages.questionaries.type").required()
      .ensure("itemToQualify").displayName("main.admin.pages.questionaries.item_to_qualify").required()
      .ensure("organizationType").displayName("main.admin.pages.questionaries.organization_type").required()      
      .ensure("languageId").displayName("main.admin.pages.questionaries.language").required()
      .ensure("programmingLanguageId").displayName("main.admin.pages.questionaries.programmingLanguageId").required()
      .ensure("averageTime").displayName("main.admin.pages.questionaries.average_time").required().matches(/^[0-9]*$/)
      .ensure("order").displayName("main.admin.pages.questionaries.order").matches(/^[0-9]*$/)
      .on(this.model);
  }

  private async activate(params: any): Promise<void> {
    // this.logger.info("activate");
    try {
      this.languages = await this.languagesService.getAll();    
      if (!isNaN(params.questionaryId)) {
        this.model = await this.questionariesService.getById(+params.questionaryId);
        if(this.model.noDiscType === NoDiscType.EngineersTest )
        {
          this.programmingLanguages = await this.perfilticservice.getAll();
        }
      }
      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public async submit(keepEditing: boolean): Promise<void> {
    this.logger.info("submit");

    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save(keepEditing);
        }
      })
      .catch(error => {
        this.notification.error(error);
      });
  }

  get canSave() {
    return !this.questionariesService.client.isRequesting;
  }

  private async changeNoDiscType(itm) {
    if(itm === NoDiscType.EngineersTest){
        if(this.programmingLanguages.length == 0){
        this.programmingLanguages = await this.perfilticservice.getAll();
      }
    }  
    else {
      this.model.programmingLanguageId = 0;
    }
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    try {

      if (!this.model.id) {
        let response: Questionary = await this.questionariesService.create(this.model);

        this.model.id = response.id;

        this.notification.success("main.admin.pages.questionaries.messages.questionary_added");

        if (!keepEditing) {
          return await this.router.navigateToRoute("questionaries");
        } else {
          return await this.router.navigateToRoute("questionary-child-router", { questionaryId: this.model.id });
        }

      } else {
        await this.questionariesService.update(this.model);

        this.notification.success("main.admin.pages.questionaries.messages.questionary_edited");

        if (!keepEditing) {
          return await this.router.navigateToRoute("questionaries");
        }

      }

    } catch (error) {
      this.notification.error(error);
    }
  }

  public changeItemToQualify() {       
    if (this.model.noDiscType == NoDiscType.Performance
      || this.model.noDiscType == NoDiscType.Potential) {
      this.model.itemToQualify = ItemToQualify.Observed;
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }

  }

}
