import { I18N } from 'aurelia-i18n';
import { autoinject, LogManager } from "aurelia-framework";

import { Logger } from "aurelia-logging";

import { Router, RouterConfiguration, RouteConfig } from "aurelia-router";
import { PLATFORM } from "aurelia-pal";

import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject()
export class TestReportChildRouter {

  private logger: Logger;

  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService) {

    this.logger = LogManager.getLogger("TestReportChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router) {

    config.map([
      {
        route: ["", "report-home"],
        moduleId: PLATFORM.moduleName("./pages/home/home"),
        name: "test-report-home",
        nav: true,
        title: this.i18n.tr("main.test_reports.pages.home.title"),
        settings: {
          icon: "ticon-modulo-report",
          isHome: true
        }
      },

     {
        route: "reports",
        moduleId: PLATFORM.moduleName("./pages/reports/report-list-child-router"),
        name: "report-list-child-router",
        nav: false,
        title: this.i18n.tr("main.test_reports.pages.reports.title")
      },
/*
      {
        route: "positions",
        moduleId: PLATFORM.moduleName("./pages/position/position-list-child-router"),
        name: "position-list-child-router",
        nav: this.authorizationService.isAuthorized(PredefinedPermissions.TestPositionView),
        title: this.i18n.tr("main.test.pages.position.position.menu_option"),
        settings: {
          icon: "ticon-persona"
        }
      },*/

    ]);

    this.router = router;
  }

}
