import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";

import { ThematicVariable } from "models/thematic-groups/thematic-variable";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class ThematicVariablesService {

  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("ThematicVariablesService");
  }

  public async getAll(thematicGroupId: number): Promise<ThematicVariable[]> {
    return await this.client.get(`thematicvariable/thematicgroup/${thematicGroupId}`)
      .then((response: ThematicVariable[]) => {
        return response;
      });
  }

  public async list(thematicGroupId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`thematicvariable/list/${thematicGroupId}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<ThematicVariable> {
    // this.logger.info("id: " +  id); 
    return await this.client.getById('thematicvariable', id)
      .then(response => {
        return <ThematicVariable>response;
      });
  }

  public async create(model: ThematicVariable): Promise<ThematicVariable> {
    return await this.client.post('thematicvariable', model)
      .then(response => {
        return <ThematicVariable>response;
      });
  }

  public async update(model: ThematicVariable): Promise<ThematicVariable> {
    return await this.client.updateById('thematicvariable', model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.delete('thematicvariable', id)
      .then(() => null);
  }

}
