import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class PositionReferenceChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N
  ) {

    this.logger = LogManager.getLogger("PositionReferenceChildRouter");

  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./position-reference-edit"),
        name: "position-reference-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.positions.position"),
      },

      {
        route: "disc",
        moduleId: PLATFORM.moduleName("../position-reference-disc/position-reference-disc-edit"),
        name: "position-reference-disc-edit",
        nav: true,
        title: this.i18n.tr("main.test.pages.position.position_tabs.tab_disc"),
      },

      // {
      //   route: "evaluators",
      //   moduleId: PLATFORM.moduleName("../position-evaluator/position-evaluator-list-child-router"),
      //   name: "position-evaluator-list-child-router",
      //   nav: true,
      //   title: this.i18n.tr("main.test.pages.position.position_tabs.tab_evaluators"),
      // },

      // {
      //   route: "disc",
      //   moduleId: PLATFORM.moduleName("../position-disc/position-disc-edit"),
      //   name: "position-disc-edit",
      //   nav: true,
      //   title: this.i18n.tr("main.test.pages.position.position_tabs.tab_disc"),
      // },

    ]);
  }
}
