import {
  FrameworkConfiguration,
  PLATFORM
} from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([

    PLATFORM.moduleName('./env-warning'),

    PLATFORM.moduleName('./disc-avatar.html'),
    PLATFORM.moduleName('./company-tags-input'),
  ]);
}
