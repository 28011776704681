import { DialogController } from 'aurelia-dialog';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { PositionCompetence } from "models/test-position/positionCompetence";
import { PositionCompetenceService } from "main/test/services/position/position-competence-service";
import { CompanyCompetence } from "../../../../models/test-core/competences/company-competence";
import { DichotomousCouple } from "../../../../models/test-core/competences/dichotomous-couple";
import { CompanyCompetenceService } from 'main/admin/services/test-management/company-competence-service';
import { Tests } from 'models/test-person/tests';
import { CoreCompanyService } from 'main/admin/services/companies/core-company-service';
@autoinject()
export class PositionCompetenceEditModal {

  private logger: Logger;
  public companyId: number;

  private positionCompetenceId: number;
  private selectedCompetenceName: string;
  private dichotomousCouples: DichotomousCouple[] = [];

  private model: PositionCompetence = new PositionCompetence();
  private validationController: ValidationController;

  private competences: CompanyCompetence[] = [];
  private questionaryId: number;
  private competenceDescription: string;
  private directCompetenceValue: number = 0;

  constructor(
    private router: Router,
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private positionCompetenceService: PositionCompetenceService,
    private companyCompetenceService: CompanyCompetenceService,
    private coreCompanyService: CoreCompanyService
  ) {
    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PositionCompetenceEditModal");
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("companyCompetenceId").displayName("main.test.pages.position.position_competence_edit.competence").required()
      .ensure("complementaryName").displayName("main.test.pages.position.position_competence_edit.complementary_name").required()
      .on(this.model);
  }

  public async activate(params: any): Promise<any> {

    this.positionCompetenceId = params.id;
    this.companyId = params.companyId;

    this.logger.debug("activate", this.positionCompetenceId);
    // this.positionCompetenceService.client.currentCompanyId = this.companyId;

    if (this.positionCompetenceId) {
      this.model = await this.getItem();      
      this.directCompetenceValue = this.model.directCompetenceValue;
      this.competences = await this.getCompetences();
      this.fillCompetenceData();
      this.configureValidationRules();
    }
    else {
      
      this.model.positionId = +params.positionId;
      this.competences = await this.getCompetences();
      this.fillCompetenceData();
      this.configureValidationRules();
    }
  }

  // private onChangeVal(itm) {
  //   if (itm.value < 60) return 60;
  //   if (itm.value > 100) return 100;
  //   return itm.value;
  // }

  private fillCompetenceData() {
    const { companyCompetenceId: competenceId, dichotomousCouples } = this.model;
    this.changeSelectedCompetence(competenceId);
    dichotomousCouples.forEach(ittm => {
      let dc = this.dichotomousCouples.find(itm => itm.id === ittm.id);
      if (dc) {
        dc.value = ittm.value;
      }

    });
  }

  private async getItem(): Promise<PositionCompetence> {
    return await this.positionCompetenceService.getById(this.positionCompetenceId)
  }

  private async getCompetences(): Promise<CompanyCompetence[]> {
    // return await this.positionCompetenceService.getAllCompetences();
    let settings = await this.coreCompanyService.getSettingsById(this.companyId);
    this.questionaryId = settings.competencesQuestionaryId;

    if (this.questionaryId == 0)
      this.questionaryId = Tests['Competences'];
    return await this.companyCompetenceService.getAll(this.questionaryId);
  }

  private changeSelectedCompetence(companyCompetenceId: number) {

    if (!companyCompetenceId) return;
    let competence = this.competences.find(cpt => cpt.id == companyCompetenceId);

    // this.logger.debug("companyCompetence", competence);

    this.selectedCompetenceName = competence.name || competence.competenceName || '';
    this.dichotomousCouples = [...competence.dichotomousCouples];
    this.competenceDescription = competence.competenceDescription;

    // this.logger.debug("dichotomousCouples", this.dichotomousCouples);
  }

  public async submit(): Promise<void> {
    this.logger.info("submit");
    this.model.dichotomousCouples = this.dichotomousCouples;    
    if (this.questionaryId != Tests['Competences'])
      this.model.directCompetenceValue = this.directCompetenceValue;

    let result = await this.validationController.validate();
    if (result.valid) {
      this.save();
    }
  }

  private async save(): Promise<any> {
    try {
      if (!this.model.id) {
        await this.positionCompetenceService.create(this.model, this.model.positionId);
        this.notification.success("notifications.item_added");
      }
      else {
        await this.positionCompetenceService.update(this.model);
        this.notification.success("notifications.item_edited");
      }
      this.dialogController.ok();
    }
    catch (error) {
      this.notification.error(error.error);
    }
  }
}
