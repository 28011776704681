
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import { I18N } from "aurelia-i18n";

@autoinject
export class UserApiApplicationListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("UserApiApplicationListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./user-list"),
        name: "user-api-application-users",
      },

      {
        route: "user-api-application/:userId",
        moduleId: PLATFORM.moduleName("./user-api-application-child-router"),
        name: "user-api-application-child-router",
      },
    ]);
  }
}
