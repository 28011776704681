import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Label } from "models/localizations/label";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class LabelModal {

  private logger: Logger;
  private model: Label;
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
    this.logger = LogManager.getLogger("LabelModal");

  }

  private async activate(model: Label): Promise<any> {
    this.model = model;
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.labels.name").required()
      .ensure("referenceLanguage").displayName("main.admin.pages.labels.referenceLanguage").required()
      .ensure("maxSize").displayName("main.admin.pages.labels.maxSize").matches(/^[0-9]*$/).required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  public async save(): Promise<any> {
    return await this.dialogController.ok();

  }
}
