import { ObserverRole } from './observerRole';
import { Person } from './person';
import { ActivationQuestionaryStatus } from './activationQuestionaryStatus';

export class PersonObserver {
  id: number;
  observerRole: ObserverRole;
  activationId: number;
  observedId: number;
  observer: Person = new Person();
  weight: number;
  createdOn: Date;
  createdByName: string;
  activationQuestionaryStatus: ActivationQuestionaryStatus;
  applicationDate: Date;
}
