import { autoinject, LogManager, NewInstance } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { ThematicVariable } from "models/thematic-groups/thematic-variable";
import { ThematicVariablesService } from "../../../services/thematic-groups/thematic-variables-service";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";

@autoinject()
export class ThematicVariableModal {

  private model: ThematicVariable = new ThematicVariable();
  private validationController: ValidationController;
  private logger: Logger;

  constructor(
    private dialogController: DialogController,
    private validationControllerFactory: ValidationControllerFactory) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ThematicVariableModal");

  }

  private async activate(model: ThematicVariable): Promise<void> {

    this.logger.debug("Activated with model:", model);
    this.model = model;
    this.configureValidationRules();
  }

  private configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("name").displayName("main.admin.pages.thematic_groups.thematic_variables.name").required()
      .on(this.model);
  }

  private async submit(): Promise<void> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  private async save(): Promise<any> {
    return await this.dialogController.ok();
  }

}
