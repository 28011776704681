
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Logger } from "aurelia-logging";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { I18N } from "aurelia-i18n";

@autoinject
export class UserListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N) {
    this.logger = LogManager.getLogger("UserListChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./master-user-list"),
        name: "master-users",
        // nav: true,
        // title: this.i18n.tr("main.admin.pages.users.title"),
      },

      {
        route: ":masterUserId",
        moduleId: PLATFORM.moduleName("./master-user-child-router"),
        name: "master-user-child-router",
        // nav: false,
        // title: this.i18n.tr("main.admin.pages.users.user"),
      },
    ]);
  }
}
