import { inject, newInstance, LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { PasswordPolicy } from "models/companies/password-policy";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject
export class PasswordPolicyService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("PasswordPolicyService");
  }


  public async getById(id: number): Promise<PasswordPolicy> {
    return await this.client.getById("passwordpolicy", id)
      .then((response: any) => {
        return <PasswordPolicy>response;
      });
  }

  public async create(model: PasswordPolicy): Promise<PasswordPolicy> {
    return await this.client.create("passwordpolicy", model)
      .then((response: any) => {
        return <PasswordPolicy>response;
      });
  }

  public async update(model: PasswordPolicy): Promise<void> {
    return await this.client.updateById("passwordpolicy", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("passwordpolicy", id)
      .then(() => null);
  }

}
