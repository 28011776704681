import * as FileSaver from 'file-saver';
import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { HttpClient } from 'aurelia-fetch-client';
import config from 'config.js';

@autoinject
export class PdfService {
  private logger: Logger;

  constructor() {
    this.logger = LogManager.getLogger('PdfService');
  }

  public async print(html: string, fullName?: string): Promise<any> {
    this.logger.debug("print");

    let pdfPrinterUrl = config.URLS_PDF_PRINTER;

    let client: HttpClient = new HttpClient();

    client.configure(x => {
      x.withBaseUrl(`${pdfPrinterUrl}/api/`);
    });
    return await client.fetch(`pdf`, { method: 'POST', body: html })
      .then((response: Response) =>  response.blob())
      .then((blob: Blob) => FileSaver.saveAs(blob, fullName || 'download.pdf'))
      .then(() => true)
      .catch(error => {
        this.logger.debug(error);
      });

  }

  public printById(id: number, filename?: string): Promise<any> {
    this.logger.debug("printById");

    let reportsUrl = config.URLS_REPORTS;

    let reportClient: HttpClient = new HttpClient();

    reportClient.configure(x => {
      x.withBaseUrl(`${reportsUrl}`);
    });

    const url = "/test-reports/" + id.toString();

    let pdfPrinterClient: HttpClient = new HttpClient();

    pdfPrinterClient.configure(x => {
      x.withBaseUrl(`${reportsUrl}/api/`);
    });

    return reportClient.fetch(url, { method: 'GET' })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.text();
        } else {
          this.logger.debug("response", response);
        }

      })
      .then((body: string) => {
        this.logger.debug("body", body);
        return pdfPrinterClient.fetch(`pdf`, { method: 'POST', body: body });
      })
      .then((response: Response) => {
        this.logger.debug("response", response);
        return response.blob().catch(() => null);
      })
      .then((blob: Blob) => {
        var file = new File([blob], filename || 'download.pdf', { type: 'application/pdf' });
        FileSaver.saveAs(file);
      })
      .catch(error => {
        this.logger.debug(error);
      });

  }
}
