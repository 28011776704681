
import { LogManager, autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import { Logger } from "aurelia-logging";

import { I18N } from "aurelia-i18n";
import { AuthorizationService } from "aurelia-permission";
import PredefinedPermissions from "config/predefined-permissions";

@autoinject
export class PositionListChildRouter {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
    private authorizationService: AuthorizationService
  ) {

    this.logger = LogManager.getLogger("PositionListChildRouter");
  }

  public configureRouter(config: RouterConfiguration, router: Router): void {
    this.router = router;
    config.map([
      {
        route: "",
        moduleId: PLATFORM.moduleName("./position-list"),
        nav: true,
        title: this.i18n.tr("main.test.pages.position.position_tabs.tab_disc"),
        name: "positions",
      },
      {
        route: "position-reference",
        moduleId: PLATFORM.moduleName("../position-reference/position-reference-list"),
        name: "position-reference",
        nav: true,
        title: this.i18n.tr("main.test.pages.position.position_reference_list.title"),
      },
      {
        route: "position/:positionId",
        moduleId: PLATFORM.moduleName("./position-child-router"),
        name: "position-child-router",
      },

    ]);
  }
}
