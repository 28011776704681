import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogController } from "aurelia-dialog";

import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";

import { Notification } from "common/services/notification";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { PasswordValidation } from "models/security/password-validation";
import { UserService } from "../../services/users/user-service";

@autoinject()
export class PasswordValidationModal {

  private logger: Logger;
  private model: PasswordValidation = new PasswordValidation();
  private validationController: ValidationController;

  constructor(
    private dialogController: DialogController,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private userService: UserService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("PasswordValidationModal");
    this.configureValidationRules();
  }

  public activate(): void {
    this.logger.debug("activate");
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("password").displayName("main.admin.pages.users.user_validation.password").required()
      .on(this.model);
  }

  public async submit(): Promise<void> {
    return await this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.validate();
        }
      });
  }

  get canSave() {
    return !this.userService.client.isRequesting;
  }


  public async validate(): Promise<any> {
    return await this.userService.validate(this.model)
      .then(async (model: boolean) => {
        if (model) {
          this.notification.success("main.admin.pages.users.user_validation.notifications.success");
        } else {
          this.notification.error("main.admin.pages.users.user_validation.notifications.error");
        }
        return await this.dialogController.ok(model);
      })
      .catch(error => {
        this.notification.error(error);
      });
  }
}
