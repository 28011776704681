import { ViewEngineHooks, View } from 'aurelia-framework';
import { Difficulty } from './difficulty';

export class DifficultyViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['Difficulty'] = Difficulty;

    view.overrideContext['Difficulties'] = 
      Object.keys(Difficulty)
        .filter((key) => typeof Difficulty[key] === 'number');
  }
}
