import { PersonService } from 'main/test/services/people/person-service';
import { TeamService } from '../../services/team/team-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { TeamActivationService } from "main/test/services/team/team-activation-service";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Team } from 'models/team/team';

@autoinject()
export class TeamActivationList {

  private logger: Logger;
  private params: any = {};

  public companyId: number;
  private teamId: number;


  private peopleParameters: UxDatatableParameters = new UxDatatableParameters();
  private peopleUxDatatable: UxDatatableCustomAttribute;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  // private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.teamActivationService.list(this.parameters, this.teamId);
  }
  private modelTeam: Team = new Team();


  constructor(
    private router: Router,
    private personService: PersonService,
    private teamService: TeamService,
    private teamActivationService: TeamActivationService,
    private dialogService: DialogService,
    private notification: Notification

  ) {

    this.logger = LogManager.getLogger("TeamActivationList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
    
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;
      // this.teamService.client.currentCompanyId = this.companyId;
      this.teamActivationService.client.currentCompanyId = this.companyId;

      if (!isNaN(this.params.teamId)) {
        this.teamId = this.params.teamId;
        this.modelTeam = await this.teamService.getById(this.teamId);

        await this.databind();

      }
    }
  }

  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    try {
      let response: UxDatatableResponse = await this.refresh();
      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;
    }
    catch (error) {
      this.notification.error(error);
    }
  }



  private async getMorePeople(scrollContext: any): Promise<void> {
    return await this.peopleUxDatatable.getMoreItems(scrollContext);
  }


}
