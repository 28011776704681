import { ViewEngineHooks, View } from 'aurelia-framework';
import { ExportTransactionType } from './export-transaction-type';

export class TransactionTypeViewEngineHooks implements ViewEngineHooks {
  
  beforeBind(view: View) {

    view.overrideContext['ExportTransactionType'] = ExportTransactionType;

    view.overrideContext['ExportTransactionTypes'] = 
      Object.keys(ExportTransactionType)
        .filter((key) => typeof ExportTransactionType[key] === 'number');
  }
}
