import { PersonService } from 'main/test/services/people/person-service';
import { TeamService } from '../../services/team/team-service';
import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { UxDatatableParameters, UxDatatableCustomAttribute, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { Notification } from "common/services/notification";
import { TeamEvaluatorService } from "main/test/services/team/team-evaluator-service";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Activation } from 'models/test-person/activation';
import { TeamActivationService } from 'main/test/services/team/team-activation-service';
import { ActivationService } from 'main/test/services/people/activation-service';
import { PersonLoginInfoModal } from '../people/person-login-info-modal';

@autoinject()
export class TeamEvaluatorList {

  private logger: Logger;
  private params: any = {};
  private questionaryName: string;
  public companyId: number;
  private teamId: number;
  private activationId: number;

  private peopleParameters: UxDatatableParameters = new UxDatatableParameters();
  private peopleUxDatatable: UxDatatableCustomAttribute;

  private parameters: UxDatatableParameters = new UxDatatableParameters();
  // private uxDatatable: UxDatatableCustomAttribute;

  private refresh = async (): Promise<UxDatatableResponse> => {
    // this.logger.debug("refreshing");
    return await this.teamEvaluatorService.list(this.parameters, this.activationId);
  }
  private modelActivation: Activation = new Activation();
  constructor(
    private router: Router,
    private personService: PersonService,
    private teamService: TeamService,
    private teamEvaluatorService: TeamEvaluatorService,
    private dialogService: DialogService,
    private notification: Notification,
    private teamActivation: ActivationService
  ) {

    this.logger = LogManager.getLogger("TeamActivationList");
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.params = params;
  }

  private async bind(): Promise<any> {
    if (!isNaN(this.params.companyId)) {
      this.companyId = +this.params.companyId;

      if (!isNaN(this.params.teamId)) {
        this.teamId = this.params.teamId;
        this.activationId = this.params.activationId;
        this.teamService.client.currentCompanyId = this.companyId;
        this.modelActivation = await this.teamActivation.getById(this.activationId);
        this.teamEvaluatorService.client.currentCompanyId = this.companyId;
        this.questionaryName = this.modelActivation.questionaryName;
        await this.databind();

      }
    }
  }

  private async databind(): Promise<void> {
    // this.logger.debug("databind");
    try {
      let response: UxDatatableResponse = await this.refresh();
      this.parameters.tableData = response.data;
      this.parameters.totalRecords = response.totalRecords;
    }
    catch (error) {
      this.notification.error(error);
    }
  }



  private async getMorePeople(scrollContext: any): Promise<void> {
    return await this.peopleUxDatatable.getMoreItems(scrollContext);
  }
  private async sendIndividualReminder(id: number): Promise<boolean> {
    try {
      await this.personService.sendReminder(id);
      this.notification.success("main.test.pages.person.send_reminder");
      return true;
    }
    catch (error) {
      this.notification.error(error);
    }
  }
  private async viewLoginInfo(personId: number): Promise<any> {
    return await this.dialogService.open({
      viewModel: PersonLoginInfoModal,
      model: personId
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {
        return await this.sendIndividualReminder(personId);
      }
    });
  }

}
