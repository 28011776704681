import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { CompanyAppHttpClient } from "common/services/company-app-http-client";
import { Questionary } from "models/questionaries/questionary";

import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class CustomCompanyQuestionaryService {
  private logger: Logger;

  constructor(
    public client: CompanyAppHttpClient) {

    this.logger = LogManager.getLogger("CustomCompanyQuestionariesService");
  }

  public async list(parameters: UxDatatableParameters, isVideoInterview: boolean): Promise<UxDatatableResponse> {
    return await this.client.post(`customcompanyquestionary/list/${isVideoInterview}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

   public async getAll(): Promise<Questionary[]> {
     return await this.client.get("customcompanyquestionary")
       .then(response => {
         return <Questionary[]>response;
      });
   }

  public async getById(id: number): Promise<Questionary> {
    return await this.client.getById("customcompanyquestionary", id)
      .then(response => {
        return <Questionary>response;
      });
  }

  public async create(model: Questionary): Promise<Questionary> {
    return await this.client.create("customcompanyquestionary", model)
      .then(response => {
        return <Questionary>response;
      });
  }

  public async update(model: Questionary): Promise<Questionary> {
    return await this.client.updateById("customcompanyquestionary", model.id, null, model)
      .then(response => {
        return <Questionary>response;
      });
  }

  public async delete(id: number): Promise<void> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.deleteById("customcompanyquestionary", id)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.post(`customcompanyquestionary/change-state/${id}`)
      .then(() => null);
  }

}
