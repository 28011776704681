import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

@autoinject()
export class BulkDownloadConfirmationModal {
  private talentsPrice: number;
  constructor(private dialogController: DialogController) {
  }
  public async activate(talentsPrice: number) {   
    this.talentsPrice = talentsPrice;     
  }
}
