import { AgreementStatus } from 'models/agreements/agreementStatus';
import { DialogCloseResult } from 'aurelia-dialog';
import { QuotaModal } from './quota-modal';
import { CatalogBase } from 'models/catalogs/catalog-base';
import { Quota } from 'models/agreements/quota';
import { Router } from 'aurelia-router';
import { ValidationRules, ControllerValidateResult } from 'aurelia-validation';
import { AgreementTermsOfPayService } from '../../../services/agreements/agreement-terms-pay-service';
import { AgreementService } from '../../../services/agreements/agreement-service';
import { BootstrapFormRenderer } from 'common/services/bootstrap-form-renderer';
import { ValidationControllerFactory } from 'aurelia-validation';
import { DialogService } from 'aurelia-dialog';
import { autoinject, LogManager } from "aurelia-framework";
import { ValidationController } from 'aurelia-validation';
import { Logger } from 'aurelia-logging';
import { Notification } from 'common/services/notification';
import { ContactService } from '../../../services/contacts/contact-service';
import { CatalogService } from '../../../../services/catalogs/catalog-service';
import { ContactModal } from '../../contacts/contact-modal';
import { Contact } from 'models/contacts/contact';
import { AgreementTermsOfPay } from 'models/agreements/agreementTermsOfPay';
import { Agreement } from 'models/agreements/agreement';
import { DeleteConfirmation } from '../../../../components/delete-confirmation';

@autoinject()
export class AgreementTermsOfPayEdit {
  private logger: Logger;

  private agreement: Agreement;
  private agreementId: number;
  private companyId: number;

  private model: AgreementTermsOfPay = new AgreementTermsOfPay();
  private validationController: ValidationController;

  private contacts: Contact[] = [];
  private currencies: CatalogBase[] = [];
  private invoicePerson: Contact;

  private percentageTotal: number = 0;
  private subtotaltermspay: number = 0;
  private taxtotaltermspay: number = 0
  private alltotaltermspay: number = 0;

  private disable: boolean = false;

  private params: any = {};

  constructor(
    private catalogService: CatalogService,
    private contactService: ContactService,
    private agreementService: AgreementService,
    private agreementTermPayService: AgreementTermsOfPayService,
    private dialogService: DialogService,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router,
  ) {

    this.logger = LogManager.getLogger("AgreementTermsOfPayEdit");

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

  }

  public async activate(params: any): Promise<void> {
    this.logger.info("activate");
    this.params = params;
  }

  public async bind(): Promise<any> {

    if (!isNaN(this.params.agreementId)) {
      this.agreementId = this.params.agreementId;

      try {
        this.currencies = await this.catalogService.getCurrencyAll();

        if (!isNaN(this.params.companyGroupId)) {
          this.companyId = +this.params.companyGroupId;
        }
        
        if (this.params.companyDetailId && this.params.companyDetailId === 'create') {
          this.companyId = null;
        }
        else if (!isNaN(this.params.companyDetailId)) {
          this.companyId = +this.params.companyDetailId;
        }

        if (this.agreementId && this.companyId) {
          this.agreementService.client.currentCompanyId = this.companyId;

          this.contacts = await this.contactService.getall(this.companyId);

          this.agreement = await this.agreementService.getById(+this.params.agreementId);

          if (this.agreement.agreementStatus !== AgreementStatus.InternalDraft) {
            this.disable = true;
          }


          this.model = await this.agreementTermPayService.getById(+this.params.agreementId);

          if (this.model.id === 0) {
            this.model.agreement = this.agreement;
            this.model.agreementId = this.params.agreementId;
          } else {
            this.legalContactChange();
            this.calculateTotals();
          }

          this.configureValidationRules();
        }
      }
      catch (error) {
        this.notification.error(error);
      }
    }
  }


  private configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    // this.logger.debug("model", this.model);
    ValidationRules
      .ensure("purchaseValue").displayName("main.admin.pages.agreements.agreementtermspay.purchasevalue").required().matches(/^0*[1-9]\d*$/)//.satisfies(obj => obj > 0)
      .ensure("currencyTypeId").displayName("main.admin.pages.agreements.agreementtermspay.currencyType").required().matches(/^0*[1-9]\d*$/)//.satisfies(obj => obj > 0)
      .ensure("invoicePersonId").displayName("main.admin.pages.agreements.agreementtermspay.invoicePerson").required().matches(/^0*[1-9]\d*$/)//.satisfies(obj => obj > 0)
      .ensure("quotas").displayName("main.admin.pages.agreements.agreementtermspay.quota_create").required().minItems(1)
      .on(this.model);
  }

  private legalContactChange() {
    this.invoicePerson = this.contacts.find(x => x.id === this.model.invoicePersonId);
  }

  public async submit(keepEditing: boolean): Promise<any> {
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.validateTotalAndQuotasValues()) {
            return await this.save(keepEditing);
          }
          else {
            this.notification.warning("main.admin.pages.agreements.errors.error_totalAgreement_NotEquals");
          }
        }
      });

  }

  get canSave() {
    return !this.agreementTermPayService.client.isRequesting;
  }

  public async save(keepEditing: boolean): Promise<any> {
    this.logger.info("save");
    // this.logger.debug("ModelCreate: ", this.model);

    try {
      if (!this.model.id) {

        this.model = await this.agreementTermPayService.create(this.model)

        this.notification.success("notifications.item_edited");

        if (!keepEditing) {
          return await this.router.parent.navigateToRoute("readonly-child-router", { agreementId: this.agreementId });
        }
        // else {
        //   return await this.router.navigateToRoute("agreements-terms-pay");
        // }


      }
      else {
        await this.agreementTermPayService.update(this.model);

        this.notification.success("notifications.item_edited");

        if (!keepEditing) {
          return await this.router.parent.navigateToRoute("readonly-child-router", { agreementId: this.agreementId });
        }
        // else {
        //   return await this.router.navigateToRoute("agreements-terms-pay");
        // }


      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  private async createContact(): Promise<void> {
    this.logger.info("createContact");
    let item: Contact = new Contact();
    item.companyId = this.agreement.companyId;

    return await this.dialogService.open({
      viewModel: ContactModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        try {
          item = await this.contactService.create(item);
          this.contacts.unshift(item);

          this.notification.success("notifications.item_added");
        }
        catch (error) {
          this.notification.error(error);
        }

      }
    });
  }

  private async createQuota(): Promise<void> {
    this.logger.info("createQuota");

    let item: Quota = new Quota();
    item.AgreementTermsOfPayId = this.model.id;

    return await this.dialogService.open({
      viewModel: QuotaModal,
      model: item
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        this.model.quotas.unshift(item);

        this.calculateTotals();
        // this.notification.success("main.admin.pages.agreements.agreementtermspay.notifications.quota_added");
      }
    }).catch(error => {
      this.notification.error(error);
    });
  }

  private async updateQuota(editQuota: Quota, index: number): Promise<void> {
    this.logger.info("updateQuota");

    let originalQuota = JSON.parse(JSON.stringify(editQuota)); // fnClone(this.model.quotas);

    return await this.dialogService.open({
      viewModel: QuotaModal,
      model: editQuota
    }).whenClosed(async (response: DialogCloseResult) => {
      if (response.wasCancelled) {
        this.model.quotas.splice(index, 1, originalQuota);
      }

      this.calculateTotals();

    }).catch(error => {
      this.notification.error(error);
    });
  }

  private async removeQuota(deleteQuota: Quota): Promise<any> {
    this.logger.info("updateateQuota");

    return await this.dialogService.open({
      viewModel: DeleteConfirmation,
      model: +deleteQuota.subtotal + +deleteQuota.tax
    }).whenClosed((response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        var pos = this.model.quotas.indexOf(deleteQuota);
        this.model.quotas.splice(pos, 1);

        this.calculateTotals();

      }
    });

  }

  private calculateTotals(): void {
    // var arrayQuotas = this.model.quotas;
    let tempSubtotal = 0;
    let tempTax = 0;
    let tempAlltotal = 0;
    let tempAllPercentage = 0;

    this.model.quotas.forEach(function (quota, index, array) {
      tempAllPercentage += +quota.percentage;
      tempSubtotal += +quota.subtotal;
      tempTax += +quota.tax;
      tempAlltotal += +quota.subtotal + +quota.tax;//+quota.total;
    });

    this.percentageTotal = tempAllPercentage;
    this.subtotaltermspay = tempSubtotal;
    this.taxtotaltermspay = tempTax;
    this.alltotaltermspay = tempAlltotal;

  }

  private validateTotalAndQuotasValues(): boolean {
    return this.model.purchaseValue == this.alltotaltermspay && this.percentageTotal == 100;
  }

  private getTotal(item1, item2) {
    return +item1 + +item2;
  }

}
