import { OrganizationActivationType } from "./organizationActivationType";
import { OrganizationType } from "../questionaries/organizationType";
import { Disc } from "models/questionaries/disc";

export class Organization {
  id: number;
  companyId: number;
  name: string;
  tags: string;

  organizationActivationType: OrganizationActivationType;
  organizationType: OrganizationType;
  questionaryId: number;

  highestDisc: Disc;
  stamp: string;
  
  evaluatorsQuantity: number;
  finalizedEvaluatorsQuantity: number;
  
  createdOn: Date;
  createdByName: string;
}
