import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";

import { AppHttpClient } from "common/services/app-http-client";
import { CompanyGroup } from "models/companies/company-group";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class CompanyGroupService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient) {

    this.logger = LogManager.getLogger("CompanyGroupService");
  }

  public async list(franchiseId: number,parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`companygroup/list/${franchiseId}`, parameters)
      .then((response: any) => {
        return <UxDatatableResponse>response;
      });
  }

  public async getAll(): Promise<CompanyGroup[]> {
    return await this.client.get("companygroup")
      .then((response: any) => {
        return <CompanyGroup[]>response;
      });
  }

  public async getById(id: number): Promise<CompanyGroup> {
    return await this.client.getById("companygroup", id)
      .then((response: any) => {
        return <CompanyGroup>response;
      });
  }

  public async create(model: CompanyGroup): Promise<CompanyGroup> {
    return await this.client.create("companygroup", model)
      .then((response: any) => {
        return <CompanyGroup>response;
      });
  }

  public async update(model: CompanyGroup): Promise<void> {
    return await this.client.updateById("companygroup", model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.deleteById("companygroup", id)
      .then(() => null);
  }

  public async changeState(id: number): Promise<void> {    
    return await this.client.post(`companygroup/change-state/${id}`)
      .then(() => null);
  }

}
