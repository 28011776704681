import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { Question } from "models/questions/question";
import { CompanyCompetence } from "models/test-core/competences/company-competence";

@autoinject()
export class DichotomousCoupleListModal {

  private model: CompanyCompetence = new CompanyCompetence();

  constructor(
    private dialogController: DialogController) {
  }

  public activate(model: CompanyCompetence): void {
    this.model = model;
  }

}
