import { LogManager, autoinject } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { AppHttpClient } from "common/services/app-http-client";
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";
import { TestPlanItem } from "models/plans/test-plan-item";

@autoinject()
export class TestPlanItemsService {

  private logger: Logger;

  constructor(
    private client: AppHttpClient) {

    this.logger = LogManager.getLogger("PlanTestModuleService");
  }

  public async list(planId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`testplanitem/list/${planId}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<TestPlanItem> {
    return await this.client.getById('testplanitem', id)
      .then(response => {
        return <TestPlanItem>response;
      });
  }

  public async create(model: TestPlanItem): Promise<TestPlanItem> {
    return await this.client.post('testplanitem', model)
      .then(response => {
        return <TestPlanItem>response;
      });
  }

  public async update(model: TestPlanItem): Promise<TestPlanItem> {
    return await this.client.updateById('testplanitem', model.id, null, model)
      .then(() => null);
  }

  public async delete(id: number): Promise<void> {
    return await this.client.delete('testplanitem', id)
      .then(() => null);
  }

}
