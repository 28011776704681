import { PersonDemographicDataService } from 'main/test/services/people/person-demographic-data-service';
import { autoinject, bindable, LogManager, bindingMode } from "aurelia-framework";
import { DialogService, DialogCloseResult } from "aurelia-dialog";
import { Logger } from "aurelia-logging";
import { Notification } from "common/services/notification";
import { DemographicData } from "models/microsite/demographicData";
import { PersonDemographicDataModal } from "./person-demographic-modal";
import { State } from 'common/store/store-model';
import { StoreService } from 'common/store/store-service';
import { PersonDemographicDataEditMessage } from 'main/test/messages/personDemographicDataEditMessage';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PersonQuestionaryActivationMessage } from 'main/test/messages/personActivationMessage';
import { Person } from 'models/test-person/person';

@autoinject()
export class PersonDemographicData {
  private logger: Logger;

  @bindable()
  public person: Person;

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public demographicData: DemographicData;

  private state: State;

  constructor(
    private store: StoreService,
    private dialogService: DialogService,
    private notification: Notification,
    private personDemographicDataService: PersonDemographicDataService,
    private ea: EventAggregator,
  ) {

    this.state = this.store.state;

    this.logger = LogManager.getLogger("PersonCreate");
  }

  private async edit(): Promise<any> {
    return await this.dialogService.open({
      viewModel: PersonDemographicDataModal,
      model: this.demographicData
    }).whenClosed(async (response: DialogCloseResult) => {
      if (!response.wasCancelled) {

        await this.personDemographicDataService.update(this.demographicData);

        // new activation
        this.ea.publish(new PersonDemographicDataEditMessage());

        // update questionaries list
        this.ea.publish(new PersonQuestionaryActivationMessage());

        this.notification.success("notifications.item_edited");
      }
    })
      .catch(error => {
        this.notification.error(error);
      });
  }

}
