import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { Router, RouteConfig } from "aurelia-router";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { State } from "common/store/store-model";
import { StoreService } from 'common/store/store-service';
import { Company } from "models/companies/company";
import { ReportTemplateService } from "../../../services/test-management/report-template-service";
import { ReportTemplate } from "models/test-core/report-templates/report-template";
import { CoreLanguageService } from "main/admin/services/localizations/core-language-service";

@autoinject()
export class ReportPredictorEdit {

  private logger: Logger;

  public companyId: number;
  private company: Company;

  public languageId;

  private languages;

  private model: ReportTemplate = new ReportTemplate();
  private saveAndContinue: boolean = false;
  private validationController: ValidationController;

  private state: State;

  constructor(
    private store: StoreService,
    private languageService: CoreLanguageService,
    private router: Router,
    private notification: Notification,
    private validationControllerFactory: ValidationControllerFactory,
    private reportTemplateService: ReportTemplateService) {

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());

    this.logger = LogManager.getLogger("ReportPredictorEdit");

    this.state = this.store.state;

  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");

    if (!isNaN(params.companyId)) {

      this.companyId = +params.companyId;

      this.languages = await this.languageService.getAll();
      this.setUserLanguage();

      this.reportTemplateService.client.currentCompanyId = this.companyId;

      this.company = this.state.companies.find(x => x.id == this.companyId);

      if (!isNaN(params.reportTemplateId)) {
        try {
          this.model = await this.reportTemplateService.getById(+params.reportTemplateId);
        }
        catch (error) {
          this.notification.error(error);
        }
      }
      this.configureValidationRules();
    }
  }

  private configureValidationRules(): void {
    ValidationRules
      .ensure("name").displayName("main.admin.pages.test_management.questionary_groups.name").required()
      .ensure("itemToQualify").displayName("main.admin.pages.questionaries.item_to_qualify").required()
      .ensure("languageId").displayName("main.admin.pages.questionaries.item_to_qualify").required()
      .on(this.model);
  }

  public setUserLanguage() {
    this.logger.debug("setUserLanguage");
    if (this.state && this.state.userBasicInfo) {
      let activeCulture = this.state.userBasicInfo.culture;
      let activeLang = this.languages.find((lg: { culture: string; }) => lg.culture === activeCulture);
      if (activeLang) {
        this.languageId = activeLang.id;
        this.model.languageId = this.languageId;
      }
    }
  }

  private async submit(saveAndContinue: boolean): Promise<void> {
    this.saveAndContinue = saveAndContinue;
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          return await this.save();
        }
      });
  }

  get canSave() {
    return !this.reportTemplateService.client.isRequesting;
  }

  private async save(): Promise<any> {
    try {
      if (!this.model.id) {
        this.model = await this.reportTemplateService.create(this.model);
        this.notification.success("notifications.item_added");

        if (!this.saveAndContinue) {
          return await this.router.navigateToRoute("report-templates-predictor");
        } else {
          return await this.router.navigateToRoute("report-template-predictor-child-router", { reportTemplateId: this.model.id });
        }

      } else {
        await this.reportTemplateService.update(this.model);

        this.notification.success("notifications.item_edited");

        if (!this.saveAndContinue) {
          return await this.router.navigateToRoute("report-templates-predictor");
        }

      }
    }
    catch (error) {
      this.notification.error(error);
    }
  }

}
