import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration } from "aurelia-router";
import PredefinedPermissions from "config/predefined-permissions";
import { Logger } from "aurelia-logging";
import { I18N } from "aurelia-i18n";
import { LogManager, autoinject } from "aurelia-framework";

@autoinject
export class CustomCompanyQuestionaryChild {

  private logger: Logger;
  private router: Router;

  constructor(
    private i18n: I18N,
  ) {

    this.logger = LogManager.getLogger("GroupChildRouter");
  }

  configureRouter(config: RouterConfiguration, router: Router): void {
    config.map([

      {
        route: "",
        name: "custom-company-questionary-edit",
        moduleId: PLATFORM.moduleName("./custom-company-questionary-edit"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.questionaries.questionary"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.TestManagementCustomCompanyQuestionaryUpdate]
          }
        }
      },

      {
        route: "custom-company-questions",
        name: "custom-company-question-list-child-router",
        moduleId: PLATFORM.moduleName("./custom-company-questions/custom-company-question-list-child-router"),
        nav: true,
        title: this.i18n.tr("main.admin.pages.questionaries.questions.title"),
        settings: {
          Permission: {
            only: [PredefinedPermissions.TestManagementCustomCompanyQuestionaryUpdate]
          }
        }
      },

    ]);

    this.router = router;
  }

}
