import { StoreService } from 'common/store/store-service';
import { bindable, customElement, autoinject, Disposable } from 'aurelia-framework';
import * as $ from 'jquery';
import { State } from 'common/store/store-model';
import { CompaniesLoadedMessage } from 'messages/companiesLoadedMessage';
import { EventAggregator } from 'aurelia-event-aggregator';

@customElement('side-bar')
@autoinject()
export class SideBar {

  @bindable()
  public navigation: any;

  @bindable()
  public title: string;

  private state: State;

  private showReturnHome: boolean = false;

  private subscriptions: Disposable[] = [];

  constructor(
    private store: StoreService,
    private ea: EventAggregator,
  ) {
    this.state = this.store.state;

    this.showReturnHome = this.state && this.state.companies.length > 1;
    
    this.subscriptions.push(this.ea.subscribe(CompaniesLoadedMessage, this.updateShowReturnHome.bind(this)));
    
  }

  public attached() {

    var pushable = $('#main-content, #main-footer');
    var sidebar = $('#navbarToggleSidebar');
    var sidebarPin = $('#fixSideBar');
    var sidebarToggler = $('.navbar-toggler');
    var sidebarLink = $('.js-sidebar-link');

    let init = () => {
      $('.navbar-brand').addClass('hide');
      pushable.addClass('content-sidebar--closed');
      sidebarToggler.addClass('show');
    };

    let pinned = () => {
      sidebarPin.click(() => {
        pushable.toggleClass('content-sidebar--open');
        sidebar.toggleClass('pinned');
      });
    }

    $(document).ready(() => {
      init();

      // triggers
      pinned();
    });
  }

  private updateShowReturnHome(){
    this.showReturnHome = this.state && this.state.companies.length > 1;
  }
}
