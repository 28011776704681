import { Activation } from "./activation";
import { Person } from "./person";
import { Questionary } from "../questionaries/questionary";
import { ActivationQuestionaryStatus } from "./activationQuestionaryStatus";

export class ActivationQuestionary {
  id: number;
  activationId: number;
  activation: Activation;
  personId: number;
  person: Person;
  questionaryId: number;
  questionary: Questionary;
  questionaryName: string;
  applicationDate: Date;
  perfilticURL: string;
  
  createdOn: Date;
  createdByName: string;
  
  activationQuestionaryStatus: ActivationQuestionaryStatus;
  canRequalify: boolean;
}
